import { startOfMonth, endOfMonth } from './helpers';

export const cacheState = {
  page: 1,
  // pageSize: 10,
  startTime: startOfMonth(),
  endTime: endOfMonth(),
  filters: {}, // 筛选项数据
  newArrangementData: {
    interviewMode: 2, // 1:自动邀约, 2:手动邀约
    autoSwitch: 1, // 1:开启自动规划, 2:关闭自动规划
    range: [], // 拉去事件数据的开始时间和结束时间 [startTime, endTime]
  },
  rawArrangementData: {},
  allArrangementData: [],
  scheduledinterviewListData: {}, // 安排邀约时拉取的事件数据
  allPositionsData: [],
  allProcessesData: [],
  allApplicationData: [],
  someParticipantData: [],
  msgSelected: [],
  selectedCandidates: [], // 用于给候选人发送提醒
  selectedEventId: null,
  selectedAnchorEl: null,
  isEventPopperOpen: false,
  isEditPeriodToken: '',
  isAddingNewPeriod: false,
  selectedPeriodTokens: [], // 编辑自动邀约时勾选的场次
  canceledPeriodTokens: [], // 本地记录已取消邀约的场次
  hasClickedNextButton: false,
  hasSelectedDateAtCalendar: false,
  hasEditedNewArrangement: false,
  conflictCheckData: {}, // 场次冲突数据
};

const defaultState = {
  count: 0,
  tabIndex: 0,
  allPositionProcessData: [], // 筛选用，拉取的全部职位和流程数据
  allCreatorsData: [],
  allParticipantData: [],
  interviewListData: {},
  isCanceledList: false,
  ...cacheState,
};

export default defaultState;
