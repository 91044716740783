import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import styles from './CandidateModalSkeleton.module.css';

const CandidateModalSkeleton = ({ showStepper }) => (
  <Box className={styles.wrapper}>
    <Box className={styles.top}>
      <Box display="flex" alignItems="center">
        <Skeleton className={styles.resetTransform} variant="circle" width={48} height={48} animation="wave" />
        <Box ml="12px">
          <Skeleton className={styles.resetTransform} width={120} height={28} animation="wave" />
        </Box>
      </Box>
    </Box>
    <Box className={styles.bottom}>
      <Box className={styles.contentLeft}>
        <Box className={styles.contentLeftHead} display="flex" alignItems="center" height={48}>
          <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
          <Box ml={2}>
            <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
          </Box>
          <Box ml={2}>
            <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
          </Box>
        </Box>
        <Box className={styles.contentLeftDetail}>
          <Box className={styles.contentLeftDetailBox} mb={3}>
            <Box mb="12px">
              <Skeleton className={styles.resetTransform} width={70} height={8} animation="wave" />
            </Box>
            <Box mb="12px">
              <Skeleton className={styles.resetTransform} width={320} height={8} animation="wave" />
            </Box>
            <Skeleton className={styles.resetTransform} width={240} height={8} animation="wave" />
          </Box>
          <Box className={styles.contentLeftDetailBox} mb={3}>
            <Box mb="12px">
              <Skeleton className={styles.resetTransform} width={70} height={8} animation="wave" />
            </Box>
            <Box mb="12px">
              <Skeleton className={styles.resetTransform} width={320} height={8} animation="wave" />
            </Box>
            <Skeleton className={styles.resetTransform} width={240} height={8} animation="wave" />
          </Box>
        </Box>
      </Box>
      <Box className={clsx([styles.contentRight, !showStepper && styles.noStepper])}>
        {showStepper ? (
          <>
            <Box className={styles.contentRightHead} display="flex" alignItems="center" height={48}>
              <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
            </Box>
            <Box className={styles.contentRightDetail} display="flex">
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box mb={1}>
                  <Skeleton
                    className={styles.resetTransform}
                    variant="circle"
                    width={16}
                    height={16}
                    animation="wave"
                  />
                </Box>
                <Box mb={1}>
                  <Skeleton className={styles.resetTransform} width={2} height={234} animation="wave" />
                </Box>
                <Box mb={1}>
                  <Skeleton
                    className={styles.resetTransform}
                    variant="circle"
                    width={16}
                    height={16}
                    animation="wave"
                  />
                </Box>
                <Box mb={1}>
                  <Skeleton className={styles.resetTransform} width={2} height={24} animation="wave" />
                </Box>
                <Box mb={1}>
                  <Skeleton
                    className={styles.resetTransform}
                    variant="circle"
                    width={16}
                    height={16}
                    animation="wave"
                  />
                </Box>
                <Box mb={1}>
                  <Skeleton className={styles.resetTransform} width={2} height={24} animation="wave" />
                </Box>
                <Skeleton
                  className={styles.resetTransform}
                  variant="circle"
                  width={16}
                  height={16}
                  animation="wave"
                />
              </Box>
              <Box width="100%" ml={1}>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
                  <Skeleton className={styles.resetTransform} width={81} height={18} animation="wave" />
                </Box>
                <Box mb={2}>
                  <Skeleton className={styles.resetTransform} width="100%" height={96} animation="wave" />
                </Box>
                <Box mb={2}>
                  <Skeleton className={styles.resetTransform} width="100%" height={32} animation="wave" />
                </Box>
                <Box display="flex" justifyContent="space-between" mb="36px">
                  <Skeleton className={styles.resetTransform} width="40%" height={32} animation="wave" />
                  <Skeleton className={styles.resetTransform} width="40%" height={32} animation="wave" />
                </Box>
                <Box mb="34px">
                  <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
                </Box>
                <Box mb="34px">
                  <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
                </Box>
                <Box>
                  <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box className={styles.noStepperContent} />
        )}
      </Box>
    </Box>
  </Box>
);

CandidateModalSkeleton.propTypes = {
  showStepper: PropTypes.bool,
};

CandidateModalSkeleton.defaultProps = {
  showStepper: true,
};

export default CandidateModalSkeleton;
