import * as ACTIONS from '../constants/ActionTypes';
import { PAGE_SIZE_PRE, START_PAGE, POSITION_PAGE_SIZE_PRE } from '../../utils/constants';

const initialState = {
  processesCount: 0,
  processes: [],
  selected: [],
  filter: {
    searchText: '',
    orderBy: 'status',
    asc: true,
    page: START_PAGE,
    pageSize: POSITION_PAGE_SIZE_PRE,
  },
  processStats: [],
  currentProcessToken: '',
  loadTime: 0,
  applicationEvaluationTpls: [],
};

export default function processes(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.ADD_SESSION:
      return {
        processesCount: state.processesCount,
        processes: [
          ...state.processes,
          {
            // id: state.reduce((maxId, process) => Math.max(process.id, maxId), -1) + 1,
            token: action.token,
            status: action.status,
            name: action.name,
            location: {
              area: action.location.area,
              city: action.location.city,
              detail: action.location.detail,
              province: action.location.province,
            },
            createUserName: action.createUserName,
            createdAt: action.createdAt,
            candidateCount: action.candidateCount,
          },
        ],
        selected: [...state.selected],
        filter: state.filter,
      };
    case ACTIONS.ADD_SESSIONS:
      let st = initialState;
      for (let i = 0; i < action.processes.length; i += 1) {
        const process = action.processes[i];
        st = {
          processesCount: action.processesCount,
          processes: [...st.processes, process],
          selected: [...state.selected],
          filter: state.filter,
        };
      }
      return st;
    case ACTIONS.EDIT_APPLICATION_SESSION:
      return {
        processesCount: state.processesCount,
        processes: state.map((process) => (process.id === action.id
          ? {
            ...process,
            token: action.token,
            name: action.name,
            status: action.status,
            createUserName: action.createUserName,
            createdAt: action.createdAt,
          }
          : process)),
        selected: [...state.selected],
        filter: state.filter,
      };
    case ACTIONS.CLEAR_LOCAL_SESSION:
      return initialState;
    case ACTIONS.GET_APPLICATION_SESSIONS:
      return state;
    case ACTIONS.ADD_SELECTED_PROCESS:
      return {
        processesCount: state.processesCount,
        processes: [...state.processes],
        selected: [...state.selected, action.token],
        filter: state.filter,
      };
    case ACTIONS.CLEAR_SELECTED_PROCESS:
      return {
        processesCount: state.processesCount,
        processes: [...state.processes],
        selected: [],
        filter: state.filter,
      };
    case ACTIONS.REMOVE_SELECTED_PROCESS:
      return {
        processesCount: state.processesCount,
        processes: [...state.processes],
        selected: state.selected.filter((token) => token !== action.token),
        filter: state.filter,
      };
    case ACTIONS.SELECTED_ALL_PROCESS:
      return {
        processesCount: state.processesCount,
        processes: [...state.processes],
        selected: state.processes.map((process) => process.token),
        filter: state.filter,
      };
    case ACTIONS.UPDATE_PROCESS_FILTER:
      return {
        processesCount: state.processesCount,
        processes: [...state.processes],
        selected: [...state.selected],
        filter: action.filter,
      };
    case ACTIONS.CLEAR_PROCESS_FILTER:
      return {
        processesCount: state.processesCount,
        processes: [...state.processes],
        selected: [...state.selected],
        filter: {
          searchText: '',
          orderBy: 'status',
          asc: true,
          page: START_PAGE,
          pageSize: PAGE_SIZE_PRE,
        },
      };
    case ACTIONS.UPDATE_PROCESS_STATS:
      return {
        ...state,
        processStats: action.payload,
      };
    case ACTIONS.UPDATE_APP_EVALUATION_TPLS:
      return {
        ...state,
        applicationEvaluationTpls: action.payload,
      };
    case ACTIONS.UPDATE_CURRENT_PROCESS_TOKEN:
      return {
        ...state,
        currentProcessToken: action.payload,
      };
    case ACTIONS.UPDATE_CURRENT_POSITION_TOKEN:
      return {
        ...state,
        currentPosition: action.payload,
      };
    case ACTIONS.RELOAD_PROCESSES:
      return {
        ...state,
        loadTime: (state.loadTime || 0) + 1,
      };
    default:
      return state;
  }
}
