import { createActions } from 'redux-actions';

export const {
  toggleInterviewModal,
  openInterviewModal,
  closeInterviewModal,
  toggleArrangementModal,
  openArrangementModal,
  closeArrangementModal,
  setStateToInterviewArrangement,
} = createActions(
  'TOGGLE_INTERVIEW_MODAL',
  'OPEN_INTERVIEW_MODAL',
  'CLOSE_INTERVIEW_MODAL',
  'TOGGLE_ARRANGEMENT_MODAL',
  'OPEN_ARRANGEMENT_MODAL',
  'CLOSE_ARRANGEMENT_MODAL',
  'SET_STATE_TO_INTERVIEW_ARRANGEMENT',
);

export default {};
