import Typography from '@components/Avocado/Typography';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from '@utils/moment';
import * as CONST_FUNCTION from '@utilsold/ConstFunction';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

const InfoDegreePhoneDate = ({
  degree, mobile, createdAt, email,
}) => {
  const classes = useStyles();

  if (createdAt == null) {
    return <Skeleton width={200} />;
  }

  return (
    <div className={classes.basicInfoLine}>
      {degree != null && degree !== 0 && (
        <Box display="flex" alignItems="center" color="text.secondary">
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <path id="a" d="M0 0h18v18H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
              <mask id="b" fill="#fff">
                <use xlinkHref="#a" />
              </mask>
              <g mask="url(#b)">
                <path d="M15.132 6.281L8.699 3.414 2.386 6.516l6.78 3.817 5.966-4.052z" stroke="#9B9A9A" />
                <path
                  d="M14.5 7v6.437a.4.4 0 01-.172.329c-1.67 1.157-3.444 1.736-5.322 1.736-1.988 0-3.765-.578-5.331-1.735a.4.4 0 01-.162-.322l-.001-5.903L5.274 8h-.762v5.135l.038.026c1.227.832 2.589 1.275 4.104 1.334l.352.007c1.448 0 2.824-.386 4.146-1.169l.348-.217V8h-.893l1.893-.46-.001-.54h.001z"
                  fill="#9B9A9A"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </svg>
          <Typography variant="body2" ml={0.5}>
            {CONST_FUNCTION.GetDegreeDesc(degree)}
          </Typography>
        </Box>
      )}
      {mobile && (
        <Box display="flex" alignItems="center" color="text.secondary">
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <g fill="#9B9A9A" fillRule="nonzero">
              <path d="M12.5 2.75a1 1 0 011 1v10.5a1 1 0 01-1 1h-7a1 1 0 01-1-1V3.75a1 1 0 011-1h7zm0 1h-7v10.5h7V3.75z" />
              <path d="M10.5 11.75a.5.5 0 010 1h-3a.5.5 0 110-1h3z" />
            </g>
          </svg>
          <Typography variant="body2" ml={0.5}>
            {mobile}
          </Typography>
        </Box>
      )}
      {email && (
        <Box display="flex" alignItems="center" color="text.secondary">
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.25 4a1 1 0 011 1v8a1 1 0 01-1 1H3.75a1 1 0 01-1-1V5a1 1 0 011-1h10.5zm0 1.606l-5 2.89a.5.5 0 01-.42.038l-.08-.037-5-2.874V13h10.5V5.606zM13.3 5H4.673l4.326 2.485L13.3 5z"
              fill="#9B9A9A"
              fillRule="nonzero"
            />
          </svg>
          <Typography variant="body2" ml={0.5}>
            {email}
          </Typography>
        </Box>
      )}
      {createdAt && (
        <Box display="flex" alignItems="center" color="text.secondary">
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <g fill="#9B9A9A" fillRule="nonzero">
              <path d="M9 2.75a6.25 6.25 0 110 12.5 6.25 6.25 0 010-12.5zm0 1a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5z" />
              <path d="M9 5.664a.5.5 0 01.492.41l.008.09-.001 2.628 1.326 1.331a.5.5 0 01.057.638l-.058.07a.5.5 0 01-.638.056l-.069-.058-1.471-1.476a.5.5 0 01-.14-.274L8.5 9V6.164a.5.5 0 01.5-.5z" />
            </g>
          </svg>
          <Typography variant="body2" ml={0.5}>
            {moment(parseInt(createdAt * 1000, 10)).format('YYYY年MM月DD日')}
          </Typography>
        </Box>
      )}
    </div>
  );
};

InfoDegreePhoneDate.propTypes = {
  degree: PropTypes.number,
  mobile: PropTypes.string,
  createdAt: PropTypes.string,
  email: PropTypes.string,
};

InfoDegreePhoneDate.defaultProps = {
  degree: null,
  mobile: null,
  createdAt: null,
  email: null,
};

export default InfoDegreePhoneDate;
