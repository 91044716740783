import Typography from '@components/Avocado/Typography';
import {
  Box,
  makeStyles,
  Step as MuiStep,
  StepContent as MuiStepContent,
  StepLabel as MuiStepLabel,
  withStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCandidateState } from '../context';
import ActionButtonsAndDialogs from './ActionButtonsAndDialogs';
import StepDatetime from './StepDatetime';
import StepIcon from './StepIcon';
import StepTitle from './StepTitle';

const useStyles = makeStyles(() => ({
  noBorder: {
    borderColor: 'transparent',
  },
}));

const Step = withStyles()(MuiStep);

const StepLabel = withStyles({
  iconContainer: {
    paddingRight: '4px',
  },
})(MuiStepLabel);

const StepContent = withStyles({
  root: {
    marginTop: '4px',
    marginLeft: '11px',
    paddingLeft: '16px',
    paddingRight: 0,
  },
})(MuiStepContent);

function PositionStep({ item, index, ...props }) {
  const classes = useStyles();
  const {
    processToken, processName, arrivalTime, layinLog, departureLog,
  } = item;
  const applicationData = useCandidateState((state) => state.applicationData);
  const { currentProcessToken } = applicationData;
  const applicationLogsDataLength = useCandidateState(
    (state) => state.applicationLogsData.filter(({ stage }) => stage === 2).length,
  );

  const isDone = arrivalTime !== 0;
  const isActive = processToken === currentProcessToken;
  const isLastStep = index === applicationLogsDataLength - 1;
  const hasLayinLog = !isEmpty(layinLog) && layinLog.createdAt !== 0;
  const hasDepartureLog = !isEmpty(departureLog) && !!departureLog.info;
  const hasAnyLogs = hasDepartureLog || hasLayinLog;

  return (
    <Step expanded>
      <StepLabel
        icon={(
          <StepIcon isDone={isDone} isActive={isActive}>
            {index + 1}
          </StepIcon>
        )}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <StepTitle isDone={isDone} isActive={isActive}>
            {processName}
          </StepTitle>
          {arrivalTime > 0 && <StepDatetime>{moment(arrivalTime * 1000).format('MM月DD日 HH:mm')}</StepDatetime>}
        </Box>
      </StepLabel>
      <StepContent classes={isLastStep ? { root: classes.noBorder } : {}} {...props}>
        {hasDepartureLog && (
          <Typography mt={2} variant="body2" color="text.primary">
            离职原因: {departureLog.info}
          </Typography>
        )}
        {hasLayinLog && (
          <Box style={{ transform: 'translateY(-8px)' }}>
            <Typography variant="caption" color="text.secondary">
              已于{moment(layinLog.createdAt * 1000).format('MM月DD日 HH:mm')}加入人才储备
            </Typography>
            {!!layinLog.info && (
              <Typography mt={1} variant="body2" color="text.primary">
                加入人才储备原因: {layinLog.info}
              </Typography>
            )}
          </Box>
        )}
        <Box mt={2}>
          <ActionButtonsAndDialogs index={index} stage={2} isActive={isActive} />
        </Box>
      </StepContent>
      {(!hasAnyLogs || isActive) && !isLastStep && (
        <div
          style={{
            minHeight: '8px',
            marginLeft: '11px',
            marginBottom: '4px',
            borderLeft: '2px solid rgba(0, 0, 0, 0.08)',
          }}
        />
      )}
    </Step>
  );
}

PositionStep.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};

export default PositionStep;
