import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const Notification = ({ open, onCancel, onSend }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      onExiting={() => {
        setPhoneNumber('');
      }}
    >
      <DialogTitle id="alert-dialog-title">发给自己</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            display="flex"
            height="60px"
            alignItems="center"
            justifyContent="center"
            style={{
              width: 51,
              height: 51,
              color: '#767676',
              fontSize: 22,
              borderRadius: 4,
              border: ' 1px solid #e6e6e6',
              marginRight: 16,
            }}
          >
            <Typography>+86</Typography>
          </Box>
          <TextField
            color="primary"
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => {
              const { value } = e.target;
              setPhoneNumber(value);
            }}
            style={{ width: '460px', margin: '20px 0px' }}
            placeholder="输入手机号"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>取消</Button>
        <Button
          disabled={!/^1[0-9]{10}$/.test(phoneNumber)}
          color="primary"
          variant="contained"
          onClick={() => {
            onSend(phoneNumber);
          }}
        >
          发送
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Notification.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSend: PropTypes.func,
};

Notification.defaultProps = {
  open: false,
  onCancel: () => {},
  onSend: () => {},
};

export default memo(Notification);
