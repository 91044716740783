import { Box, Typography } from '@material-ui/core';

const AtMessageListEmpty = () => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <img src="//public-static-assets.oss-cn-beijing.aliyuncs.com/img/im_empty.png" alt="" width="240px" />
    <Box mt={2}>
      <Typography variant="h6">暂无沟通记录</Typography>
    </Box>
  </Box>
);

export default AtMessageListEmpty;
