import { departmentSwitch } from '@api/User';
import { ExpandIcon } from '@avocadoui/icons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { setPostFilterUseDefault } from '@redux/process/action';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCandidateState } from '../context';
import AIResultFull from './AIResultFull';
import AIResultRefresh from './AIResultRefresh';
import styles from './CandidateResult.module.css';
import formatScore from './formatScore';
import TheBalls from './TheBalls';

function AIResult({ onClose }) {
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const [isOpen, setIsOpen] = useState(false);
  const { applicationData = {} } = useCandidateState();
  const { aiReportResult = {} } = applicationData;
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedDepartmentTokens = useSelector((state) => state.account.userInfo.selectedDepartmentTokens);

  useEffect(() => {
    if (isOpen) setIsOpen(false);
  }, [applicationToken]);

  if (isEmpty(aiReportResult) || aiReportResult.status === 0 || aiReportResult.score === -1) {
    return null;
  }

  function handleGoToList(e) {
    e.stopPropagation();
    // 前往招聘流程-所属职位和流程
    const { positionToken, positionName, currentProcess } = applicationData;
    const { token: processToken } = currentProcess;

    const params = new URLSearchParams(history.location.search);
    params.set('position', positionToken);
    params.set('process', processToken);
    params.delete('candidate');
    params.delete('application');
    history.replace({
      pathname: '/dashboard/processReview',
      search: params.toString(),
      state: { order: 'aiReport' },
    });

    dispatch(
      setPostFilterUseDefault({
        positionToken,
        positionName,
        processToken,
        orderBy: 'aiReport',
        asc: false,
      }),
    );

    onClose({ shouldRefreshData: false, shouldResetRoute: false });
  }

  // 总排行
  // 原始职位-不切分部
  // 影子职位-切分部-切到原始职位所属分部
  function handleGoToList1(e) {
    e.stopPropagation();
    // 前往招聘流程-所属分部的职位和流程
    const { referDepartmentToken, referPositionToken, currentProcess } = applicationData;
    const { token: processToken } = currentProcess;

    const isOriginal = selectedDepartmentTokens.indexOf(referDepartmentToken) > -1;
    if (isOriginal) {
      const params = new URLSearchParams(history.location.search);
      params.set('position', referPositionToken);
      params.set('process', processToken);
      params.delete('candidate');
      params.delete('application');
      history.replace({
        pathname: '/dashboard/processReview',
        search: params.toString(),
        state: { order: 'aiReport' },
      });

      dispatch(
        setPostFilterUseDefault({
          positionToken: referPositionToken,
          processToken,
          orderBy: 'aiReport',
          asc: false,
        }),
      );

      onClose({ shouldRefreshData: false, shouldResetRoute: false });
    } else {
      const params = new URLSearchParams(history.location.search);
      params.set('position', referPositionToken);
      params.set('process', processToken);
      params.delete('candidate');
      params.delete('application');
      history.replace({
        pathname: '/dashboard/processReview',
        search: params.toString(),
        state: { order: 'aiReport' },
      });

      departmentSwitch({ tokens: [referDepartmentToken] }).then((result) => {
        if (result.data.code === 0) {
          history.go(0);
        }
      });
    }
  }

  // 所属分部排行
  // 原始职位-切分部-切到影子职位所属分部
  // 影子职位-不切分部
  function handleGoToList2(e) {
    e.stopPropagation();
    // 前往招聘流程-所属分部的职位和流程
    const {
      departmentToken, referDepartmentToken, positionToken, currentProcess,
    } = applicationData;
    const { token: processToken } = currentProcess;

    const isOriginal = selectedDepartmentTokens.indexOf(referDepartmentToken) > -1;
    if (isOriginal) {
      const params = new URLSearchParams(history.location.search);
      params.set('position', positionToken);
      params.set('process', processToken);
      params.delete('candidate');
      params.delete('application');
      history.replace({
        pathname: '/dashboard/processReview',
        search: params.toString(),
        state: { order: 'aiReport' },
      });

      departmentSwitch({ tokens: [departmentToken] }).then((result) => {
        if (result.data.code === 0) {
          history.go(0);
        }
      });
    } else {
      const params = new URLSearchParams(history.location.search);
      params.set('position', positionToken);
      params.set('process', processToken);
      params.delete('candidate');
      params.delete('application');
      history.replace({
        pathname: '/dashboard/processReview',
        search: params.toString(),
        state: { order: 'aiReport' },
      });

      dispatch(
        setPostFilterUseDefault({
          positionToken,
          processToken,
          orderBy: 'aiReport',
          asc: false,
        }),
      );

      onClose({ shouldRefreshData: false, shouldResetRoute: false });
    }
  }

  function handleGoToSetting() {
    const { positionToken } = applicationData;
    history.push(`/dashboard/position/${positionToken}?open=weight`);
    onClose({ shouldRefreshData: false, shouldResetRoute: false });
  }

  return (
    <div className={`${styles.ai_wrapper} ${isOpen ? styles.open : styles.closed}`}>
      <TheBalls show={!isOpen} />
      <div role="button" className={styles.card} onClick={() => setIsOpen(true)}>
        <div className={styles.left}>
          <div className={styles.scoreWrapper}>
            {aiReportResult.status === 1 && (
              <span style={{ fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.26)' }}>候选人未作答</span>
            )}
            {aiReportResult.status === 2 && <span style={{ fontSize: '16px', fontWeight: 500 }}>计算中...</span>}
            {aiReportResult.status === 3 && (
              <>
                <span className={styles.score}>{formatScore(aiReportResult.score)}</span>
                <span className={styles.fen}>分</span>
              </>
            )}
            {aiReportResult.recommend && (
              <span className={styles.recommend}>
                <svg width="38" height="38" xmlns="http://www.w3.org/2000/svg">
                  <g opacity=".596">
                    <g opacity=".403" transform="rotate(28 15.995 22.51)" fill="none" fillRule="evenodd">
                      <path
                        d="M33.958 12.034C31.496 5.025 24.82 0 16.97 0 9.139 0 2.476 5.001 0 11.984m.888 14.11C3.85 31.97 9.94 36 16.97 36c7.072 0 13.192-4.078 16.135-10.012"
                        stroke="#4BB051"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M26.215 12.037A10.991 10.991 0 0016.97 7a10.99 10.99 0 00-9.207 4.979m1.71 14.07A10.961 10.961 0 0016.97 29c2.91 0 5.557-1.13 7.525-2.977"
                        stroke="#4BB051"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <text fontFamily="PingFangSC-Medium, PingFang SC" fontSize="10" fontWeight="400" fill="#4BB051">
                        <tspan x="6.97" y="22">
                          推荐
                        </tspan>
                      </text>
                      <circle fill="#4BB051" cx="3.97" cy="18" r="1" />
                      <circle fill="#4BB051" cx="29.97" cy="18" r="1" />
                      <g fill="#4BB051">
                        <path d="M16.97 33.5l-1.176.618.225-1.309-.951-.927 1.314-.191.588-1.191.588 1.191 1.314.191-.951.927.225 1.309zM29.016 27.754l-.26 1.303-.876-.998-1.319.155.679-1.141-.556-1.206 1.296.292.975-.901.122 1.323 1.159.649zM23.765 31.931l-.786 1.071-.372-1.275-1.261-.416 1.097-.748.007-1.328 1.05.813 1.265-.405-.448 1.25.775 1.078zM5.272 28.169l-1.238-.482 1.135-.69.076-1.325 1.007.866 1.284-.337-.513 1.225.718 1.117-1.324-.11-.84 1.029zM10.42 32.048l-1.327.063.756-1.091-.47-1.243 1.272.383 1.036-.831.03 1.328 1.11.728-1.254.438-.35 1.282z" />
                        <path d="M10.42 32.048l-1.327.063.756-1.091-.47-1.243 1.272.383 1.036-.831.03 1.328 1.11.728-1.254.438-.35 1.282z" />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            )}
          </div>
          {aiReportResult.status === 3 && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
              <AIResultRefresh />
              <div role="button" className={styles.goToSetting} onClick={handleGoToSetting}>
                得分设置
              </div>
            </div>
          )}
        </div>
        {aiReportResult.status === 3 && (
          <div className={styles.right}>
            {!isEmpty(aiReportResult.globalRank) && isEmpty(aiReportResult.rank) && (
              <div role="button" className={styles.rank} onClick={handleGoToList}>
                <div>总排行</div>
                <div className={styles.rankNumber}>
                  {aiReportResult.globalRank.index}/{aiReportResult.globalRank.total}
                </div>
                <ChevronRightIcon className={styles.icon} />
              </div>
            )}
            {!isEmpty(aiReportResult.globalRank) && !isEmpty(aiReportResult.rank) && (
              <>
                <div role="button" className={styles.rank} onClick={handleGoToList1}>
                  <div>总排行</div>
                  <div className={styles.rankNumber}>
                    {aiReportResult.globalRank.index}/{aiReportResult.globalRank.total}
                  </div>
                  <ChevronRightIcon className={styles.icon} />
                </div>
                <div role="button" className={styles.rank} onClick={handleGoToList2}>
                  <div>所属分部排行</div>
                  <div className={styles.rankNumber} style={{ fontSize: '16px' }}>
                    {aiReportResult.rank.index}/{aiReportResult.rank.total}
                  </div>
                  <ChevronRightIcon className={styles.icon} />
                </div>
              </>
            )}
            {isEmpty(aiReportResult.globalRank) && !isEmpty(aiReportResult.rank) && (
              <div role="button" className={styles.rank} onClick={handleGoToList2}>
                <div>所属分部排行</div>
                <div className={styles.rankNumber} style={{ fontSize: '16px' }}>
                  {aiReportResult.rank.index}/{aiReportResult.rank.total}
                </div>
                <ChevronRightIcon className={styles.icon} />
              </div>
            )}
          </div>
        )}
      </div>
      {isOpen ? (
        <>
          <div className={styles.line} />
          <AIResultFull result={aiReportResult} />
        </>
      ) : (
        <div role="button" className={styles.more} onClick={() => setIsOpen(true)}>
          <ExpandIcon fontSize="small" />
        </div>
      )}
    </div>
  );
}

AIResult.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AIResult;
