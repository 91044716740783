import { Box } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import { useCandidateState } from '../context';
import EvaluationCard from './EvaluationCard';
import ExportButton from './ExportButton';

function EvaluationList() {
  const candidateState = useCandidateState();
  const { applicationEvaluationData = {} } = candidateState;
  const { list = [] } = applicationEvaluationData;

  if (isEmpty(list)) {
    return null;
  }

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="flex-end">
          <ExportButton />
        </Box>
        {list.map((card) => (
          <EvaluationCard
            key={card.tplToken}
            card={card}
          />
        ))}
      </Box>
    </>
  );
}

export default memo(EvaluationList);
