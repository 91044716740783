import { WarningIcon } from '@avocadoui/icons';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  forwardRef,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useImperativeHandle,
  memo,
} from 'react';
import ReactMarkdown from 'react-markdown';
import { useImmer } from 'use-immer';
import styles from './WrittenTabCardQnAList.module.css';

function TransparentButton({ children, onClick, disabled }) {
  return (
    <button type="button" className={styles.transparentBtn} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
}

TransparentButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

function QnASwitch({
  selectedOrder, handleSwitch, disabledSwitchPrev, disabledSwitchNext,
}) {
  return (
    <Box className={styles.switchBtns}>
      <Box>
        <TransparentButton onClick={() => handleSwitch(selectedOrder, -1)} disabled={disabledSwitchPrev}>
          上一题
        </TransparentButton>
        <TransparentButton onClick={() => handleSwitch(selectedOrder, 1)} disabled={disabledSwitchNext}>
          下一题
        </TransparentButton>
      </Box>
    </Box>
  );
}

QnASwitch.propTypes = {
  selectedOrder: PropTypes.number.isRequired,
  handleSwitch: PropTypes.func.isRequired,
  disabledSwitchPrev: PropTypes.bool.isRequired,
  disabledSwitchNext: PropTypes.bool.isRequired,
};

function formatStatusText(status, skipped, text = '') {
  let value = text;
  if (status === 1) {
    value = '视频处理中，请稍候';
  }
  if (skipped) {
    value = '候选人未作答';
  }
  return value;
}

function QnAVideoStatus({ status, skipped }) {
  if (status === 2 && !skipped) return null;
  const text = formatStatusText(status, skipped);
  return (
    <Box className={styles.videoStatus}>
      <Box>
        <WarningIcon style={{ fontSize: 36 }} />
      </Box>
      <Box>
        <Typography variant="body2" style={{ color: '#ffffff' }}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
}

QnAVideoStatus.propTypes = {
  status: PropTypes.number.isRequired,
  skipped: PropTypes.bool.isRequired,
};

function QnAVideo({ src, autoPlay, videoRef }) {
  return <video src={src} className={styles.videoPlayer} controls="controls" autoPlay={autoPlay} ref={videoRef} />;
}

QnAVideo.propTypes = {
  src: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool.isRequired,
  videoRef: PropTypes.shape({ current: PropTypes.objectOf(PropTypes.any) }).isRequired,
};

function QnAItem({ item, selectedOrder, handleSelect }) {
  const {
    status, skipped, order, desc, media,
  } = item;
  const isActive = order === selectedOrder;
  const text = formatStatusText(status, skipped, media.text);

  return (
    <Box className={clsx(styles.item, { [styles.active]: isActive })} onClick={() => handleSelect(order)}>
      <Box display="flex" className={clsx({ [styles.activeTitle]: isActive })}>
        <Box width="20px">
          <Typography variant="body2" color="textPrimary" className={clsx({ [styles.activeTitle]: isActive })}>
            {order}.
          </Typography>
        </Box>
        {desc && (
          <Box>
            <ReactMarkdown>{desc}</ReactMarkdown>
          </Box>
        )}
      </Box>
      <Box mt={1.5}>
        <Box className={styles.answerTextBox} p={1} minHeight="34px">
          <Typography variant="caption" color="textSecondary">
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

QnAItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedOrder: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

const initialState = { selectedItem: {}, autoPlay: false };

const QnAList = forwardRef(({ list = [], videoWrapperRef }, ref) => {
  const [state, setState] = useImmer(initialState);
  const { selectedItem, autoPlay } = state;
  const videoRef = useRef();

  useEffect(() => {
    // 当未选择题目时，自动选择第一题
    if (list.length > 0 && isEmpty(selectedItem)) {
      const item = list[0];
      setState((draft) => {
        draft.selectedItem = item;
      });
    }
  }, [list, selectedItem]);

  const [disabledSwitchPrev, disabledSwitchNext] = useMemo(() => {
    if (list.length === 0) return [false, false];
    const index = list.findIndex((p) => p.order === selectedItem.order);
    if (index === -1) return [false, false];
    const prev = index === 0;
    const next = index === list.length - 1;
    return [prev, next];
  }, [list, selectedItem.order]);

  const handlePauseVideo = () => {
    if (videoRef.current && videoRef.current.pause) {
      videoRef.current.pause();
    }
  };

  const handleSelect = useCallback(
    (order) => {
      const index = list.findIndex((p) => p.order === order);
      if (index === -1) return;
      handlePauseVideo();
      setState((draft) => {
        draft.selectedItem = list[index];
        draft.autoPlay = true;
      });
    },
    [list],
  );

  const handleSwitch = useCallback(
    (order, offset) => {
      const index = list.findIndex((p) => p.order === order);
      if (index === -1) return;
      handlePauseVideo();
      setState((draft) => {
        draft.selectedItem = list[index + offset];
        draft.autoPlay = true;
      });
    },
    [list],
  );

  useImperativeHandle(ref, () => ({
    handlePauseVideo,
  }));

  return (
    <Box>
      <Box mx={2} mt={2} borderTop="1px dotted rgba(0, 0, 0, 0.08)" />
      <Box className={styles.sticky}>
        <Box>
          <Typography variant="body2" color="textSecondary">
            主观题展示
          </Typography>
        </Box>
        <Box mt={1.5} position="relative" borderRadius="4px" boxShadow="0px 4px 8px 0px rgb(0 0 0 / 16%)">
          <Box className={styles.videoRatio} ref={videoWrapperRef}>
            {selectedItem.status === 2 && (
              <QnAVideo src={selectedItem.media.sourceUrl} autoPlay={autoPlay} videoRef={videoRef} />
            )}
            <QnAVideoStatus status={selectedItem.status} skipped={selectedItem.skipped} />
            <QnASwitch
              selectedOrder={selectedItem.order}
              handleSwitch={handleSwitch}
              disabledSwitchPrev={disabledSwitchPrev}
              disabledSwitchNext={disabledSwitchNext}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        {list.map((item) => (
          <QnAItem item={item} selectedOrder={selectedItem.order} handleSelect={handleSelect} />
        ))}
      </Box>
    </Box>
  );
});

QnAList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  videoWrapperRef: PropTypes.shape({ current: PropTypes.objectOf(PropTypes.any) }).isRequired,
};

export default memo(QnAList);
