import { handleActions } from 'redux-actions';

import {
  toggleInterviewModal,
  openInterviewModal,
  closeInterviewModal,
  toggleArrangementModal,
  openArrangementModal,
  closeArrangementModal,
  setStateToInterviewArrangement,
} from './action';

export const namespace = 'interviewArrangement';

export const defaultState = {
  entryType: 1, // 1:右上角图标, 2:招聘流程列表的邀约安排, 3:来自弹窗内部的编辑, 4:来自弹窗外部的编辑
  interviewModalIsOpen: false,
  arrangementModalIsOpen: false,
  candidateModalIsOpen: false,
  positionToken: '',
  processToken: '',
  applicationTokens: [],
  candidateTokenForCandidateModal: '',
  applicationTokenForCandidateModal: '',
  periodToken: '',
  isRearranging: false,
  applicationPeriodTokenFromRedux: '',
};

export const interviewArrangementReducer = handleActions(
  {
    [toggleInterviewModal]: (state) => ({ ...state, interviewModalIsOpen: !state.interviewModalIsOpen }),
    [openInterviewModal]: (state) => {
      const { interviewModalIsOpen, candidateModalIsOpen } = state;
      if (interviewModalIsOpen && candidateModalIsOpen) {
        return { ...state, candidateModalIsOpen: false };
      }
      return { ...state, interviewModalIsOpen: true };
    },
    [closeInterviewModal]: (state) => ({ ...state, interviewModalIsOpen: false }),
    [toggleArrangementModal]: (state) => ({ ...state, arrangementModalIsOpen: !state.arrangementModalIsOpen }),
    [openArrangementModal]: (state, action) => {
      const {
        entryType = 1,
        positionToken = '',
        processToken = '',
        applicationTokens = [],
        periodToken,
        interviewToken,
        interviewMode,
        isRearranging = false,
        applicationPeriodToken = '',
      } = action.payload;
      return {
        ...state,
        entryType,
        interviewModalIsOpen: true,
        arrangementModalIsOpen: true,
        positionToken,
        processToken,
        applicationTokens,
        periodToken,
        interviewToken,
        interviewMode,
        isRearranging,
        applicationPeriodToken,
      };
    },
    [closeArrangementModal]: (state) => {
      const interviewModalIsOpen = state.entryType !== 2 && state.entryType !== 4;
      return {
        ...state,
        interviewModalIsOpen,
        arrangementModalIsOpen: false,
        positionToken: '',
        processToken: '',
        applicationTokens: [],
        periodToken: '',
        interviewToken: '',
        interviewMode: '',
      };
    },
    [setStateToInterviewArrangement]: (state, action) => {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    },
  },
  defaultState,
);
