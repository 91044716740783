import {
  addTagToCandidate, removeTagFromCandidate,
} from '@api/Candidate';
import { getNearTags } from '@api/Tag';
import { AddIcon, RecordIcon } from '@avocadoui/icons';
import Avatar from '@components/Avatar';
import Typography from '@components/Avocado/Typography';
import CandidateFormTag from '@components/CandidateTag/CandidateTag';
import CandidateTag from '@components/Tag/CandidateTag';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import Skeleton from '@material-ui/lab/Skeleton';
import * as CONST_FUNCTION from '@utilsold/ConstFunction';
import clsx from 'clsx';
import { motion, useTransform } from 'framer-motion';
import { isEmpty } from 'lodash';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useCandidateDispatch, useCandidateState } from '../context';
import useDataLoader from '../useDataLoader';
import CandidateApplicationSelector from './CandidateApplicationSelector';
import CandidateApplicationWithoutSelector from './CandidateApplicationWithoutSelector';
import CandidateInfoEditMode from './CandidateInfoEditMode';
import DeleteIconWrapper from './DeleteIconWrapper';
import InfoDegreePhoneDate from './InfoDegreePhoneDate';
import useStyles from './useStyles';

const CandidateInfo = (props) => {
  const classes = useStyles();

  const {
    onClose, rpaCandidate, opacity0, opacity1, translateX, contentRef, hasScrolled, shouldDisabledBtn,
  } = props;

  const disabledClose = useSelector(({ candidateModal }) => candidateModal.disabledClose);
  const hideCandidateApplicationSelector = useSelector(
    ({ candidateModal }) => candidateModal.hideCandidateApplicationSelector,
  );

  const tagListRef = useRef();
  const tagListHeight = useMemo(() => tagListRef?.current?.clientHeight || 0, [tagListRef?.current?.clientHeight]);

  const userInfoRef = useRef();
  const userInfoWidth = useMemo(() => userInfoRef?.current?.clientWidth + 60 || 0, [userInfoRef?.current?.clientWidth]);

  const offsetY = useTransform(opacity0, [1, 0], [0, -116 - tagListHeight]);
  const offsetTopY = useTransform(opacity0, [1, 0], [0, 108 + tagListHeight]);
  const offsetTopNewY = useTransform(opacity0, [1, 0], [0, 108 + 28 + 16 + tagListHeight]);
  const offsetTopBackgroundY = useTransform(opacity0, [1, 0], [0, 58]);
  const { loadCandidate } = useDataLoader();

  const state = useCandidateState();
  const dispatch = useCandidateDispatch();

  const {
    candidateData,
    applicationRecommendRewardRecordsData,
    candidateApplicationData,
    isShowRecord,
  } = state;
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);

  const notAssigned = candidateApplicationData.flows == null
    || (candidateApplicationData.flows && candidateApplicationData.flows.length === 0);

  const [nearTags, setNearTags] = useState([]);
  const [tagName, setTagName] = useState('');

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (shouldDisabledBtn && isEdit) {
      setIsEdit(false);
    }
  }, [shouldDisabledBtn, isEdit]);

  function getNearTagsData(name) {
    getNearTags(name).then((result) => {
      setNearTags(result.data.data);
    });
  }

  function handleDeleteTag(event, name) {
    const data = {
      tags: [name],
    };
    removeTagFromCandidate(candidateToken, data).then(() => {
      loadCandidate(candidateToken);
    });
  }

  function directDeleteTag(delTagName) {
    const data = {
      tags: [delTagName],
    };

    removeTagFromCandidate(candidateToken, data).then(() => {
      loadCandidate(candidateToken);
      getNearTagsData();
    });
  }

  function sureAddTag(event) {
    const data = {
      tags: [tagName],
    };

    if (event.keyCode === 13) {
      if (!tagName.trim()) {
        return;
      }
      addTagToCandidate(candidateData.token, data).then(() => {
        loadCandidate(candidateData.token);
      });
    }
  }

  function handleChangeTag(event) {
    getNearTagsData(event.target.value);
    setTagName(event.target.value);
  }

  function directAddTag(name) {
    if (!name) return;

    const data = {
      tags: [name],
    };
    addTagToCandidate(candidateData.token, data).then(() => {
      loadCandidate(candidateData.token);
      getNearTagsData();
    });
  }

  const {
    name, sex, age, degree, mobile, email, createdAt, avatar,
  } = candidateData;
  const isReferral = applicationRecommendRewardRecordsData.total > 0;
  const isLoading = isEmpty(candidateData);

  if (isEdit) {
    return <CandidateInfoEditMode shouldDisabledBtn={shouldDisabledBtn} />;
  }

  return (
    <motion.div
      style={{ padding: ' 18px 18px  20px 16px', y: offsetY }}
      className={clsx(classes.wrapper, { [classes.sticky]: hasScrolled })}
    >
      <motion.div
        style={{
          position: 'fixed',
          height: 128 + tagListHeight - 14,
          right: 0,
          justifyContent: 'space-between',
          top: 0,
          y: offsetTopBackgroundY,
          opacity: opacity1,
          display: 'flex',
          width: '70vw',
          maxWidth: '1024px',
          zIndex: 0,
          backgroundColor: 'white',
        }}
      >
        <Box style={{ backgroundColor: 'white' }} />
      </motion.div>
      <div className={classes.basicInfoWrapper}>
        <div className={classes.basicInfo}>
          <motion.div style={{ opacity: opacity0 }} className={classes.basicAvatar}>
            <Avatar
              style={{ width: '48px', height: '48px' }}
              src={avatar}
              sex={sex}
              icon={isReferral ? 'referral' : ''}
              alt="头像"
            />
          </motion.div>
          <motion.div
            initial={{ x: 0, y: 0 }}
            style={{ x: translateX, y: offsetTopY }}
            className={classes.basicInfoLineWrapper}
          >
            <div className={classes.basicInfoLine}>
              <Box
                ref={userInfoRef}
                className={classes.basicInfoLine}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Typography variant="h6" className={classes.infoName}>
                  {isLoading ? <Skeleton width={50} /> : name}
                </Typography>
                {sex !== 0 && (
                  <Box display="flex" alignItems="center">
                    {CONST_FUNCTION.GetSexIcon(sex)}
                  </Box>
                )}
                {age !== 0 && age != null && (
                  <Box display="flex" alignItems="center" ml={1}>
                    <Typography variant="body2" color="text.secondary">
                      {age}岁
                    </Typography>
                  </Box>
                )}
              </Box>
              {!isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    height: '30px',
                  }}
                >
                  <motion.div
                    style={
                      hasScrolled
                        ? { position: 'absolute', opacity: opacity0, marginLeft: 4 }
                        : {
                          position: 'absolute',
                          opacity: opacity0,
                          zIndex: 1,
                          marginLeft: 4,
                        }
                    }
                  >
                    {hideCandidateApplicationSelector ? (
                      <CandidateApplicationWithoutSelector />
                    ) : (
                      <CandidateApplicationSelector />
                    )}
                  </motion.div>
                </div>
              ) : (
                <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
                  <Skeleton width={200} />
                </div>
              )}
            </div>
            <motion.div style={{ opacity: opacity0 }}>
              <InfoDegreePhoneDate degree={degree} mobile={mobile} createdAt={createdAt} email={email} />
            </motion.div>
          </motion.div>
        </div>

        <motion.div
          style={{
            position: 'absolute',
            left: `${userInfoWidth - 10}px`,
            justifyContent: 'space-between',
            top: -30,
            y: offsetTopNewY,
            opacity: opacity1,
            display: 'flex',
            width: `calc(70vw - ${userInfoWidth + 8}px)`,
            maxWidth: `calc(1024px - ${userInfoWidth + 8}px)`,
            height: '30px',
            zIndex: 4,
            backgroundColor: 'white',
          }}
        >
          <InfoDegreePhoneDate degree={degree} mobile={mobile} createdAt={createdAt} email={email} />

          {!disabledClose && (
            <Box position="relative" zIndex={1000} mt={0.5}>
              <IconButton onClick={onClose}>
                <CloseIcon style={{ color: 'rgba(0,0,0,0.56)' }} />
              </IconButton>
            </Box>
          )}
        </motion.div>
        <div>
          <div className={classes.actionButtons}>
            {!notAssigned && (
              <motion.div style={{ opacity: opacity0 }}>
                <IconButton
                  onClick={() => {
                    dispatch({ type: 'CANDIDATE_RESUME_RECORD', payload: !isShowRecord });
                  }}
                >
                  <RecordIcon htmlColor={isShowRecord ? '#4CAF50' : 'rgba(0,0,0,0.86)'} />
                </IconButton>
              </motion.div>
            )}
            <motion.div style={{ opacity: opacity0 }}>
              <IconButton
                disabled={shouldDisabledBtn}
                onClick={() => {
                  contentRef.current.scrollTop = 0;
                  dispatch({ type: 'CANDIDATE_RESUME_EDITING', payload: !state.isResumeEditing });
                }}
              >
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                  <g fill="rgba(0,0,0,0.86)" fillRule="evenodd">
                    <g transform="rotate(45 2.879 23.778)">
                      <path d="M.2 3h1.6c.11 0 .2.09.2.2V17l-.985 1L0 17V3.2c0-.11.09-.2.2-.2z" />
                      <rect width="2" height="2" rx=".2" />
                    </g>
                    <path d="M10.973 16.35h7.8c.11 0 .2.089.2.2v1.6a.2.2 0 01-.2.2H9l1.973-2z" />
                  </g>
                </svg>
              </IconButton>
            </motion.div>
            <motion.div style={{ opacity: opacity0 }}>
              <DeleteIconWrapper
                candidateName={name}
                candidateToken={candidateToken}
                onClose={onClose}
                shouldDisabledBtn={shouldDisabledBtn}
              />
            </motion.div>
            <motion.div style={{ opacity: opacity0 }}>
              {!disabledClose && (
                <Box position="relative" zIndex={1000}>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </motion.div>
          </div>
        </div>
      </div>
      <motion.div
        style={{
          opacity: opacity0,
        }}
        className={classes.tags}
      >
        <ul className={classes.tagList} ref={tagListRef}>
          {!!candidateData.tags
            && candidateData.tags.map((value) => (
              <li key={value.token} className={classes.tagItem}>
                <CandidateTag
                  size="medium"
                  name={value.name}
                  type={value.type}
                  sentiment={value.sentiment}
                  deletable
                  onDelete={(event) => handleDeleteTag(event, value.name)}
                />
              </li>
            ))}
          <li className={classes.tagItem}>
            {!rpaCandidate && (
              <PopupState variant="popover" popupId="tags-popup-popover">
                {(popupState) => (
                  <>
                    <Button
                      {...bindTrigger(popupState)}
                      startIcon={<AddIcon className={classes.addIcon} />}
                      variant="outlined"
                      size="small"
                    >
                      添加标签
                    </Button>

                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: -4,
                        horizontal: 'left',
                      }}
                    >
                      <CandidateFormTag
                        sureAddTag={sureAddTag}
                        directDeleteTag={directDeleteTag}
                        handleChangeTag={handleChangeTag}
                        directAddTag={directAddTag}
                        selectedTags={candidateData.tags}
                        nearTags={nearTags}
                        getNearTagsData={getNearTagsData}
                      />
                    </Popover>
                  </>
                )}
              </PopupState>
            )}
          </li>
        </ul>
      </motion.div>
    </motion.div>
  );
};

CandidateInfo.propTypes = {
  onClose: PropTypes.func,
  rpaCandidate: PropTypes.bool,
  padding: PropTypes.string,
  opacity0: PropTypes.string,
  opacity1: PropTypes.string,
  translateX: PropTypes.string,
  translateY: PropTypes.string,
  contentRef: PropTypes.shape({ current: PropTypes.element }).isRequired,
  hasScrolled: PropTypes.bool,
  shouldDisabledBtn: PropTypes.bool,
  disabledClose: PropTypes.bool,
  applicationTokenFromOutside: PropTypes.string,
};

CandidateInfo.defaultProps = {
  onClose: () => {},
  rpaCandidate: false,
  padding: '',
  opacity0: '',
  opacity1: '',
  translateX: '',
  translateY: '',
  hasScrolled: false,
  shouldDisabledBtn: false,
  disabledClose: false,
  applicationTokenFromOutside: '',
};

export default CandidateInfo;
