/* eslint-disable react/destructuring-assignment */
import Typography from '@components/Avocado/Typography';
import { EmptyInterviewsIcon } from '@components/Icon';
import { TableSortLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiPagination from '@material-ui/lab/Pagination';
import { openCandidateModal } from '@redux/candidateModal/action';
import * as interviewActions from '@redux/interviewArrangement/action';
import useGlobalToast from '@utils/GlobalToast';
import moment from '@utils/moment';
import { useActions } from '@utils/useActions';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  useRef, useMemo, Fragment, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { GreenTableRow } from '../../v1/components/units/components/colorTableRow';
import Avatar from './Avatar';
import { INTERVIEW_STATUS, INTERVIEW_TYPES_OBJECT } from './const';
import { useInterviewDispatch, useInterviewState } from './context';
import { CancelInterviewDialog, SendNotificationToCandidatesDialog } from './Dialog';
import styles from './index.module.css';

const CELL_MAX_WIDTH = '200px';

const useStyles = makeStyles((theme) => ({
  tableBodyFont: {
    fontFamily: theme.fontFamily,
    fontWeight: 400,
    minHeight: '66px',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    verticalAlign: 'top',
  },
  lastCellAndActionIcon: {
    position: 'relative',
    width: '300px', // lzl: 随着按钮的增多而加大宽度
  },
  tableCellActions: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '5%',
    backgroundColor: '#f4f4f4',
    boxShadow: '-10px 0px 10px 1px #f4f4f4',
  },
}));

function EmptyDataBox() {
  return (
    <Box
      padding="50px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      color="#C3C3C3"
      style={{ backgroundColor: '#ffffff' }}
    >
      <Box style={{ width: '70px' }}>
        <EmptyInterviewsIcon />
      </Box>
      <Box fontSize="16px" mt="16px">
        暂无数据
      </Box>
    </Box>
  );
}

function TableCellComponent({ row, onOpenPopper }) {
  const classes = useStyles();
  const isFinished = [2, 4].indexOf(row.values.status.status) > -1;

  return (
    <GreenTableRow {...row.getRowProps()} onClick={onOpenPopper} style={isFinished ? { opacity: 0.5 } : {}}>
      {row.cells.map((cell) => (
        <TableCell
          className={cell.column.id === 'admin' ? classes.lastCellAndActionIcon : classes.tableBodyFont}
          {...cell.getCellProps()}
        >
          {cell.render('Cell')}
        </TableCell>
      ))}
    </GreenTableRow>
  );
}

TableCellComponent.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  onOpenPopper: PropTypes.func.isRequired,
};

function TableRowComponent({ row }) {
  const dispatch = useInterviewDispatch();
  const interviewListData = useInterviewState((state) => state.interviewListData);
  const { list = [] } = interviewListData;
  const dispatchRedux = useDispatch();

  const handleOpenPopper = (event) => {
    const item = list.find((v) => v.periodToken === row.id) || {};
    const hasExactOneCandidate = item.candidates?.list?.length === 1;
    if (hasExactOneCandidate) {
      if (item.candidates?.list[0]?.candidate?.token && item.candidates?.list[0]?.applicationToken) {
        const candidateToken = item.candidates.list[0].candidate.token;
        const applicationToken = item.candidates?.list[0].applicationToken;
        const processTokenFromInterviewArrangement = item.process.token;
        dispatchRedux(openCandidateModal({
          candidateToken, applicationToken, processTokenFromInterviewArrangement, hideSwitchButton: true,
        }));
      }
    } else {
      dispatch({
        type: 'OPEN_EVENT_POPPER',
        payload: { selectedEventId: row.id, selectedAnchorEl: event.currentTarget },
      });
    }
  };

  return <TableCellComponent row={row} onOpenPopper={handleOpenPopper} />;
}

TableRowComponent.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired,
};

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setSortBy,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: { pageIndex: 0, pageSize: 10 },
      autoResetPage: false,
      autoResetSortBy: false,
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
  );

  const dispatch = useInterviewDispatch();
  const filters = useInterviewState((state) => state.filters);
  const { sort, order } = filters;

  useEffect(() => {
    gotoPage(0);
  }, [filters]);

  useEffect(() => {
    dispatch({ type: 'CHANGE_PAGE', payload: pageIndex });
  }, [pageIndex]);

  const handleChangePage = (_, value) => {
    console.log('handleChangePage:', value);
    gotoPage(value - 1);
    if (document.querySelector('.fc') && document.querySelector('.fc').scrollIntoView) {
      document.querySelector('.fc').scrollIntoView();
    }
  };

  // sortBy = [{ id: 'when', desc: true }] // 1st click
  // sortBy = [{ id: 'when', desc: false }] // 2nd click
  // sortBy = [] // 3rd click
  const hasRendered = useRef(false);
  useEffect(() => {
    if (hasRendered.current) {
      if (isEmpty(sortBy)) {
        dispatch({ type: 'SET_FILTER', payload: { key: 'sort', value: 0 } });
        dispatch({ type: 'SET_FILTER', payload: { key: 'order', value: 0 } });
      } else if (sortBy[0]?.id === 'when') {
        const value = sortBy[0]?.desc ? 2 : 1;
        dispatch({ type: 'SET_FILTER', payload: { key: 'sort', value: 2 } });
        dispatch({ type: 'SET_FILTER', payload: { key: 'order', value } });
      }
    } else {
      hasRendered.current = true;
    }
  }, [sortBy]);

  useEffect(() => {
    if (hasRendered.current) {
      if (sort !== 2) {
        setSortBy([{ id: 'undefined', desc: undefined }]);
      }
    } else {
      hasRendered.current = true;
    }
  }, [sort]);

  return (
    <>
      <Paper variant="outlined">
        <MuiTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const active = sort === 2;
                  const direction = order === 1 ? 'asc' : 'desc';
                  return (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      title={column.canSort ? '排序' : ''}
                    >
                      {column.canSort ? (
                        <TableSortLabel active={active} direction={direction}>
                          {column.render('Header')}
                        </TableSortLabel>
                      ) : (
                        column.render('Header')
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  <TableRowComponent row={row} />
                </Fragment>
              );
            })}
          </TableBody>
        </MuiTable>
        {page.length === 0 && <EmptyDataBox />}
      </Paper>
      {pageCount !== 0 && (
        <Box display="flex" justifyContent="center" p="24px">
          <MuiPagination
            page={pageIndex + 1}
            count={pageCount}
            size="small"
            variant="text"
            color="primary"
            onChange={handleChangePage}
          />
        </Box>
      )}
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

function formatData(v) {
  return {
    id: v.periodToken,
    status: {
      status: v.status,
      type: v.type,
    },
    what: {
      title: v.name,
      processName: v.process.name,
      positionName: v.position.name,
    },
    when: {
      startTime: v.startTime,
      deadline: v.deadline,
      duration: v.duration,
      address: v.address,
      interviewMode: v.interviewMode,
    },
    who: {
      candidates: v.candidates,
      interviewMode: v.interviewMode,
      numLimit: v.numLimit,
      status: v.status,
    },
    where: {
      type: v.type,
      process: v.process.name,
      address: v.address,
    },
    admin: v,
  };
}

function TypeChip({ value, status }) {
  let style = {};

  if (value === 2) {
    style = { color: '#6BC6C6', backgroundColor: '#E1F4F4' };
  } else if (value === 1) {
    style = { color: '#4B84FF', backgroundColor: '#EDF2FF' };
  } else {
    style = { color: '#834DEA', backgroundColor: '#EDE3FF' };
  }

  if (status === 2 || status === 4) {
    style = { color: '#9b9b9b', backgroundColor: '#f0f0f0' };
  }

  return (
    <Box
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      padding="2px 6px"
      fontSize="12px"
      lineHeight="16px"
      borderRadius="10px"
      minWidth="60px"
      style={style}
    >
      <Box>{INTERVIEW_TYPES_OBJECT[value]}</Box>
    </Box>
  );
}

TypeChip.propTypes = {
  value: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
};

export function StatusChip({ value }) {
  let chipStyle = {};
  let dotStyle = {};

  if (value === 1) {
    chipStyle = { color: '#4bb051', backgroundColor: '#e4f0eb' };
    dotStyle = { backgroundColor: '#4bb051' };
  } else if (value === 3) {
    chipStyle = { color: '#ffbd2b', backgroundColor: 'rgba(255, 189, 43, 0.1)' };
    dotStyle = { backgroundColor: '#ffbd2b' };
  } else {
    chipStyle = { color: '#9b9b9b', backgroundColor: '#f0f0f0' };
    dotStyle = { backgroundColor: '#9b9b9b' };
  }

  return (
    <Box
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      padding="2px 6px"
      fontSize="12px"
      lineHeight="16px"
      borderRadius="10px"
      minWidth="60px"
      style={chipStyle}
    >
      <Box width="4px" height="4px" borderRadius="50%" style={dotStyle} />
      <Box ml="4px">{INTERVIEW_STATUS[value]}</Box>
    </Box>
  );
}

StatusChip.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
};

function StatusCell({ value }) {
  const { status, type } = value;
  return (
    <Box>
      <Box>
        <TypeChip value={type} status={status} />
      </Box>
      <Box mt="4px">
        <StatusChip value={status} />
      </Box>
    </Box>
  );
}

StatusCell.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
};

function WhatCell({ value }) {
  const { title, processName, positionName } = value;

  return (
    <Box maxWidth={CELL_MAX_WIDTH} className={styles.truncate}>
      <Box style={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.56)' }}>{title}</Box>
      <Box style={{
        fontSize: '12px', color: '#7C7C7C', display: 'flex', alignItems: 'center', marginTop: '4px',
      }}
      >
        <Box maxWidth="95px" className={styles.truncate}>
          {processName}
        </Box>
        <Box
          style={{
            display: 'inline-block',
            margin: '0px 4px',
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            backgroundColor: '#D8D8D8',
          }}
        />
        <Box maxWidth="95px" className={styles.truncate}>
          {positionName}
        </Box>
      </Box>
    </Box>
  );
}

WhatCell.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
};

function ProcessBar({ total = 1, current = 0 }) {
  const fullWidth = 120;
  const width = (fullWidth / total) * current;
  return (
    <Box position="relative" width={fullWidth} height="12px" borderRadius="6px" overflow="hidden">
      <Box
        position="absolute"
        top="0px"
        right="0px"
        bottom="0px"
        left="0px"
        style={{ backgroundColor: 'rgba(75, 176, 81, 0.1)' }}
      />
      <Box position="absolute" width={width} height="100%" style={{ backgroundColor: '#4BB051' }} />
    </Box>
  );
}

ProcessBar.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
};

function WhoCell({ value }) {
  const {
    candidates, interviewMode, numLimit,
  } = value;
  const { total, list = [] } = candidates;

  // 进度条的显示与 status 无关
  // 1:自动邀约:显示进度条
  // 2:手动邀约:不显示进度条

  const MAX_AVATAR_LENGTH = 5;
  if (interviewMode === 2) {
    if (list.length === 0) {
      return null;
    }
    return (
      <Box>
        {total > 0 && (
          <Box display="flex" alignItems="center">
            {list.slice(0, MAX_AVATAR_LENGTH).map((p) => (
              <Box key={p.applicationToken} mr={1}>
                <Avatar
                  src={p.candidate.avatar}
                  name={p.candidate.name}
                  style={{ width: 24, height: 24, fontSize: '14px' }}
                />
              </Box>
            ))}
            {total > MAX_AVATAR_LENGTH && (
              <Typography variant="caption" color="text.secondary">
                +{total - MAX_AVATAR_LENGTH}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  }

  const successCandidateNum = list.filter((p) => [2, 3].indexOf(p.status) > -1).length;

  return (
    <Box maxWidth={CELL_MAX_WIDTH}>
      <Box fontSize="12px" color="#7C7C7C">
        {successCandidateNum}/{numLimit} 已报名
      </Box>
      <Box mt={1}>
        <ProcessBar total={numLimit} current={successCandidateNum} />
      </Box>
      {total > 0 && (
        <Box display="flex" alignItems="center" mt={1}>
          {list.slice(0, MAX_AVATAR_LENGTH).map((p) => (
            <Box key={p.applicationToken} mr={1}>
              <Avatar
                src={p.candidate.avatar}
                name={p.candidate.name}
                style={{ width: 24, height: 24, fontSize: '14px' }}
              />
            </Box>
          ))}
          {total > MAX_AVATAR_LENGTH && (
            <Typography variant="caption" color="text.secondary">
              +{total - MAX_AVATAR_LENGTH}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

WhoCell.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
};

function WhenCell({ value }) {
  const {
    startTime, duration, deadline, address, interviewMode,
  } = value;

  return (
    <Box>
      <Box fontWeight="bold" color="rgba(0,0,0,0.56)">
        {moment(parseInt(startTime * 1000, 10)).format('YYYY-MM-DD HH:mm')} 开始时间
      </Box>
      {interviewMode === 1 && (
        <Box mt="4px" fontSize="12px" color="#7c7c7c">
          {moment(parseInt(deadline * 1000, 10)).format('YYYY-MM-DD HH:mm')} 报名截止
        </Box>
      )}
      <Box mt="4px" fontSize="12px" color="#7c7c7c">
        {duration / 60}分钟 时长
      </Box>
      <Box mt="4px" fontWeight="regular" color="rgba(0,0,0,0.56)">
        {address}
      </Box>
    </Box>
  );
}

WhenCell.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
};

function AdminCell({ value }) {
  const {
    status,
    candidates,
    interviewToken,
    periodToken,
    createUser,
    interviewers,
    interviewMode,
    createTime,
    canNotifyInterviewer,
    canNotifyCandidate,
  } = value;
  const { list = [] } = candidates;
  const [sendModalIsOpen, setSendModalIsOpen] = useState(false);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const dispatch = useInterviewDispatch();
  const { openArrangementModal } = useActions(interviewActions);
  const currentAccountInfo = useSelector((state) => state.account?.userInfo?.user);
  const { role, token: currentAccountToken } = currentAccountInfo;
  const isHidden = role === 5; // 面试官账号
  const isFinished = [2, 4].indexOf(status) > -1 || isHidden;
  const GlobalToast = useGlobalToast();

  useEffect(() => {
    if (sendModalIsOpen) {
      const data = list.map((p) => ({
        applicationToken: p.applicationToken,
        canSendNotification: p.canSendNotification,
        status: p.status,
        candidateToken: p.candidate.token,
        name: p.candidate.name,
        mobile: p.candidate.mobile,
      }));
      dispatch({ type: 'SET_INITIAL_SELECTED_CANDIDATES', payload: data });
    } else {
      dispatch({ type: 'RESET_SELECTED_CANDIDATES' });
    }
  }, [sendModalIsOpen, JSON.stringify(list)]);

  function handleClose() {
    if (sendModalIsOpen) setSendModalIsOpen(false);
    if (cancelModalIsOpen) setCancelModalIsOpen(false);
  }

  function handleEdit() {
    // 检查登陆者是否为创建者本人
    if (createUser.token === currentAccountToken) {
      // 可以成功进行编辑
      openArrangementModal({
        entryType: 3, periodToken, interviewToken, interviewMode,
      });
    } else {
      GlobalToast.warning(`只能创建者${createUser.name}操作`);
    }
  }

  function handleCancel() {
    // 检查登陆者是否为创建者本人
    if (createUser.token === currentAccountToken) {
      // 可以成功进行编辑
      setCancelModalIsOpen(true);
    } else {
      GlobalToast.warning(`只能创建者${createUser.name}操作`);
    }
  }

  const MAX_AVATAR_LENGTH = 4;

  return (
    <>
      <Box className={isFinished ? '' : 'tableCellTime'} style={{ justifyContent: 'flex-start', alignItems: 'start' }}>
        <Box color="#4A4A4A">
          <Box display="flex">
            <Box>
              <Typography variant="caption" color="text.secondary">
                负责人：
              </Typography>
            </Box>
            <Box>
              <Avatar
                src={createUser.avatar}
                name={createUser.name}
                style={{ width: 24, height: 24, fontSize: '14px' }}
              />
            </Box>
          </Box>

          {interviewers.total > 0 && (
            <Box display="flex" alignItems="center" mt={1}>
              <Box>
                <Typography variant="caption" color="text.secondary">
                  企业参与成员：
                </Typography>
              </Box>
              {interviewers.list.slice(0, MAX_AVATAR_LENGTH).map((p) => (
                <Box mr={1}>
                  <Avatar src={p.avatar} name={p.name} style={{ width: 24, height: 24, fontSize: '14px' }} />
                </Box>
              ))}
              {interviewers.total > MAX_AVATAR_LENGTH && (
                <Typography variant="caption" color="text.secondary">
                  +{interviewers.total - MAX_AVATAR_LENGTH}
                </Typography>
              )}
            </Box>
          )}

          <Box display="flex" mt={1}>
            <Box>
              <Typography variant="caption" color="text.secondary">
                创建时间：
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="text.secondary">
                {moment(createTime * 1000).format('YYYY/MM/DD')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {!isFinished && (
        <>
          <Box className="tableCellActions" style={{ justifyContent: 'flex-start' }}>
            <Box>
              <Button
                variant="outlined"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setSendModalIsOpen(true);
                }}
                disabled={isFinished || (!canNotifyInterviewer && !canNotifyCandidate)}
              >
                发送提醒
              </Button>
            </Box>
            <Box ml="8px">
              <Button
                variant="outlined"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit();
                }}
                disabled={isFinished}
              >
                编辑
              </Button>
            </Box>
            <Box ml="8px">
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancel();
                }}
                disabled={isFinished}
              >
                取消邀约
              </Button>
            </Box>
          </Box>
          <Box onClick={(e) => e.stopPropagation()}>
            <SendNotificationToCandidatesDialog
              open={sendModalIsOpen}
              onClose={handleClose}
              periodToken={periodToken}
              interviewToken={interviewToken}
            />
          </Box>
          <Box onClick={(e) => e.stopPropagation()}>
            <CancelInterviewDialog
              open={cancelModalIsOpen}
              onClose={handleClose}
              interviewToken={interviewToken}
              periodToken={periodToken}
            />
          </Box>
        </>
      )}
    </>
  );
}

AdminCell.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default function Wrapper() {
  const interviewListData = useInterviewState((state) => state.interviewListData);
  const { list = [] } = interviewListData;

  const columns = useMemo(
    () => [
      {
        Header: '状态',
        accessor: 'status',
        disableSortBy: true,
        Cell: (data) => {
          const { value } = data.cell;
          return <StatusCell value={value} />;
        },
      },
      {
        Header: '岗位信息',
        accessor: 'what',
        disableSortBy: true,
        Cell: (data) => {
          const { value } = data.cell;
          return <WhatCell value={value} />;
        },
      },
      {
        Header: '时间地点',
        accessor: 'when',
        disableSortBy: false,
        sortDescFirst: true,
        Cell: (data) => {
          const { value } = data.cell;
          return <WhenCell value={value} />;
        },
      },
      {
        Header: '受邀人',
        accessor: 'who',
        disableSortBy: true,
        Cell: (data) => {
          const { value } = data.cell;
          return <WhoCell value={value} />;
        },
      },
      {
        Header: '企业参与信息',
        accessor: 'admin',
        disableSortBy: true,
        Cell: (data) => {
          const { value } = data.cell;
          return <AdminCell value={value} />;
        },
      },
    ],
    [],
  );

  const events = useMemo(() => list.map(formatData),
    [JSON.stringify(list)]);

  return (
    <>
      <Table columns={columns} data={events} />
    </>
  );
}
