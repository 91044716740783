import { Box } from '@material-ui/core';
import { memo } from 'react';
import { useCandidateState } from '../context';
import { EvaluationProvider } from './context';
import EvaluationDetail from './EvaluationDetail';
import EvaluationList from './EvaluationList';
import useEffectIsEditingWithType from './hooks/useEffectIsEditingWithType';
import useEffectReloadEvaluationList from './hooks/useEffectReloadEvaluationList';

function CandidateEvaluation() {
  const { tabName } = useCandidateState();

  useEffectReloadEvaluationList();
  useEffectIsEditingWithType();

  return (
    <Box p={2}>
      <div style={{ display: tabName !== 'evaluation' ? 'none' : 'block' }}>
        <EvaluationList />
      </div>
      <EvaluationDetail />
    </Box>
  );
}
const MemoCandidateEvaluation = memo(CandidateEvaluation);

function CandidateEvaluationWrapper() {
  return (
    <EvaluationProvider>
      <MemoCandidateEvaluation />
    </EvaluationProvider>
  );
}

export default memo(CandidateEvaluationWrapper);
