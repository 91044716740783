import * as API from '@api/Resume';
import { Box, Button } from '@material-ui/core';
import { useCandidateState } from '@module/CandidateModal/context';
import { CANDIDATE_RESUME_UPLOAD_FILE_TYPE } from '@utils/constants';
import useGlobalToast from '@utils/GlobalToast';
import isEmpty from 'lodash/isEmpty';
import { useContext, useRef } from 'react';
import {
  defaultEducation,
  defaultExperience,
  defaultLanguage,
  ResumeContext,
  ResumeDispatchContext,
} from './ResumeContext';

const UPLOAD_RESUME_STATUS_DEFAULT = 0;
const UPLOAD_RESUME_STATUS_UPLOADING = 1;
const UPLOAD_RESUME_STATUS_PARSERING = 2;
const FILE_SIZE_LIMIT = 2;

const Resume = () => {
  const GlobalToast = useGlobalToast();
  const fileInputRef = useRef();

  const { candidateData = {} } = useCandidateState();

  const { candidate = {}, resumeURL: resume = '' } = useContext(ResumeContext);
  const dispatch = useContext(ResumeDispatchContext);
  const uploadResume = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const updateResume = (uploadResumeInfo, resumeURL) => {
    const educations = isEmpty(uploadResumeInfo.educations) ? [defaultEducation] : uploadResumeInfo.educations;
    const experience = isEmpty(uploadResumeInfo.works) ? [defaultExperience] : uploadResumeInfo.works;
    const languages = isEmpty(uploadResumeInfo.languages) ? [defaultLanguage] : uploadResumeInfo.languages;
    const candidateIntention = {
      currentSalary: uploadResumeInfo.currentSalary,
      exceptedCity: uploadResumeInfo.exceptedCity,
      exceptedSalaryFrom: uploadResumeInfo.exceptedSalaryFrom,
      exceptedSalaryTo: uploadResumeInfo.exceptedSalaryTo,
      introduction: uploadResumeInfo.introduction,
    };
    const candidateBasic = {
      name: uploadResumeInfo.name,
      age: uploadResumeInfo.age,
      sex: uploadResumeInfo.sex,
      email: uploadResumeInfo.email,
      mobile: uploadResumeInfo.mobile,
      degree: uploadResumeInfo.degree,
    };

    dispatch({
      type: 'initial',
      payload: {
        candidate,
        resumeURL,
        editCandidate: {
          candidateBasic,
          candidateIntention,
          experience,
          educations,
          languages,
          attachResume: candidateData.attachResume,
        },
        uploadStatus: 0,
      },
    });
  };

  const handleUploadResume = () => {
    const files = fileInputRef.current.files || [];
    if (files.length > 0) {
      const file = files[0];
      if (file.size && file.size < FILE_SIZE_LIMIT * 1024 * 1024) {
        fileInputRef.current.value = '';
        dispatch({ type: 'updateUploadStatus', payload: UPLOAD_RESUME_STATUS_UPLOADING });
        const formData = new FormData();
        formData.append('file', file);
        API.uploadResume(candidate.token, formData, () => {})
          .then((v) => {
            if (v.data.code === 0) {
              GlobalToast.success('简历上传成功');
              dispatch({ type: 'updateUploadStatus', payload: UPLOAD_RESUME_STATUS_PARSERING });
              API.parseResume(candidate.token, { fileName: v.data.data.fileName })
                .then((vv) => {
                  if (vv.data.code === 0) {
                    updateResume(vv.data.data.list, v.data.data.resumeUrl);
                  } else {
                    dispatch({ type: 'updateUploadStatus', payload: UPLOAD_RESUME_STATUS_DEFAULT });
                    GlobalToast.error(vv.data.message);
                  }
                })
                .catch(() => {
                  dispatch({ type: 'updateUploadStatus', payload: UPLOAD_RESUME_STATUS_DEFAULT });
                });
            } else {
              dispatch({ type: 'updateUploadStatus', payload: UPLOAD_RESUME_STATUS_DEFAULT });
              GlobalToast.error(v.data.message);
            }
          })
          .catch(() => {
            GlobalToast.error('简历上传失败');
            dispatch({ type: 'updateUploadStatus', payload: UPLOAD_RESUME_STATUS_DEFAULT });
          });
      } else {
        fileInputRef.current.value = '';
        GlobalToast.warning(`大小不超过${FILE_SIZE_LIMIT}MB，支持PNG, JPEG, PDF, PPT, Word, Excel, TXT`);
      }
    }
  };

  return (
    <Box width={268} ml={2} flexGrow={0} flexShrink={0}>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept={CANDIDATE_RESUME_UPLOAD_FILE_TYPE}
        onChange={handleUploadResume}
        multiple={false}
      />
      <Button fullWidth variant="outlined" disableElevation disableRipple onClick={uploadResume} size="large">
        {candidateData?.resumeInfo || resume !== '' ? '重新上传简历' : '上传简历'}
      </Button>
      {/* <Button fullWidth variant="contained" disableElevation disableRipple style={{ marginTop: 16 }}>
        邀请候选人更新简历
      </Button> */}
    </Box>
  );
};

Resume.propTypes = {};

Resume.defaultProps = {};

export default Resume;
