import { getApplication, getApplicationFlow, getApplicationLogs } from '@api/Application';
import { getAssignablePositions, getCandidate, getCandidateApplications } from '@api/Candidate';
import { getEvaluationList } from '@api/Evaluation';
import { getFormWithAnswer } from '@api/Form';
import { interviewListByApplicationAndProcess } from '@api/Interview';
import { getRecommendRewardRecords } from '@api/Referral';
import { setApplicationToken, setCandidateToken } from '@redux/candidateModal/action';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useCandidateDispatch } from './context';

export default function useDataLoader() {
  const dispatchRedux = useDispatch();
  const dispatch = useCandidateDispatch();
  const candidateTokenFromRedux = useSelector(({ candidateModal }) => candidateModal.candidateToken);

  // 获取面试者基本信息
  function loadCandidate(candidateToken, needlogs = false) {
    dispatch({ type: 'SET_CANDIDATE_DATA_LOADING', payload: true });
    getCandidate(candidateToken, needlogs).then((result) => {
      if (result.data.code === 0) {
        // console.log('DataLoader getCandidate:', result.data.data);
        dispatch({ type: 'SET_CANDIDATE_DATA', payload: result.data.data });
        dispatch({ type: 'SET_CANDIDATE_DATA_LOADING', payload: false });
      }
    });
  }
  // 获取面试者应聘职位信息
  function loadCandidateApplication(candidateToken, applicationToken) {
    // candidateApplicationData
    getCandidateApplications(candidateToken, { applicationToken }).then((result) => {
      if (result.data.code === 0) {
        // console.log('DataLoader getCandidateApplications:', result.data.data);
        dispatch({ type: 'SET_CANDIDATE_APPLICATION_DATA', payload: result.data.data });

        if (!applicationToken) {
          const token = result.data.data.flows
            && result.data.data.flows[0] && result.data.data.flows[0].applicationToken;
          if (token) dispatchRedux(setApplicationToken(token));
        }
      }
    });
  }
  // 获取面试者面试信息
  function loadApplication(applicationToken) {
    // applicationData
    // applicationFlowData
    // applicationRecommendRewardRecordsData
    const source = axios.CancelToken.source();
    const cancelToken = source.token;

    getApplication(applicationToken).then((result) => {
      if (result.data.code === -4001) {
        dispatch({ type: 'SET_HAS_NO_PERMISSION' });
      }

      if (result.data.code === 0) {
        // console.log('DataLoader getApplication:', result.data.data);
        dispatch({ type: 'SET_APPLICATION_DATA', payload: result.data.data });

        if (!candidateTokenFromRedux) {
          const token = result.data.data.candidateToken;
          if (token) dispatchRedux(setCandidateToken(token));
        }
        result.data?.data?.processResult?.forEach((value) => {
          if (value.type === 2) {
            // 获取面试者问答结果
            getApplicationFlow(value.token).then((result2) => {
              if (result2.data.code === 0) {
                dispatch({ type: 'SET_APPLICATION_FLOW_DATA', payload: result2.data.data });
              }
            });
          } else if (value.type === 3) {
            // 获取面试者的资料收集结果
            // console.log('axios started:', source.token);
            getFormWithAnswer(value.token, cancelToken).then((result2) => {
              if (result2.data.code === 0) {
                // console.log('DataLoader getFormWithAnswer:', result2.data.data);
                dispatch({
                  type: 'ADD_TO_APPLICATION_FORM_DATA',
                  payload: {
                    token: value.token,
                    title: value.name,
                    data: result2.data.data,
                  },
                });
              }
            });
          } else if (value.type === 5) {
            // 获取面试者的面试安排结果
            const processToken = value.token;
            interviewListByApplicationAndProcess(applicationToken, processToken, cancelToken).then((result2) => {
              if (result2.data.code === 0) {
                // console.log('DataLoader interviewListByApplicationAndProcess:', result2.data.data);
                dispatch({
                  type: 'ADD_TO_APPLICATION_INTERVIEW_DATA',
                  payload: {
                    token: value.token,
                    title: value.name,
                    data: result2.data.data,
                  },
                });
              }
            });
          }
        });

        if (result.data.data.hasEvaluation) {
          getEvaluationList(applicationToken).then((result2) => {
            dispatch({ type: 'SET_APPLICATION_EVALUATION_DATA', payload: result2.data.data });
          });
        }
      }
    });

    getRecommendRewardRecords(applicationToken).then((result) => {
      if (result.data.code === 0) {
        // console.log('DataLoader getRecommendRewardRecords:', result.data.data);
        dispatch({
          type: 'SET_APPLICATION_RECOMMEND_REWARD_RECORDS_DATA',
          payload: result.data.data,
        });
      }
    });

    getApplicationLogs(applicationToken).then((result) => {
      if (result.data.code === 0) {
        // console.log('DataLoader getApplicationLogs:', result.data.data);
        dispatch({
          type: 'SET_APPLICATION_LOGS_DATA',
          payload: result.data.data.list,
        });
      }
    });

    return source;
  }

  // 获取面试者可被分配的职位
  function loadCandidateAssignablePositions(candidateToken) {
    dispatch({ type: 'SET_CANDIDATE_ASSIGNABLE_POSITIONS_DATA_LOADING', payload: true });
    getAssignablePositions(candidateToken).then((result) => {
      if (result.data.code === 0) {
        // console.log('DataLoader getAssignablePositions:', result.data.data.positions);
        dispatch({ type: 'SET_CANDIDATE_ASSIGNABLE_POSITIONS_DATA_LOADING', payload: false });
        dispatch({ type: 'SET_CANDIDATE_ASSIGNABLE_POSITIONS_DATA', payload: result.data.data.positions });
      }
    });
  }

  function resetApplication() {
    dispatch({ type: 'SET_APPLICATION_FLOW_DATA', payload: [] });
    dispatch({ type: 'SET_APPLICATION_FORM_DATA', payload: {} });
  }

  function resetToDefaultState() {
    dispatch({ type: 'RESET_TO_DEFAULT_STATE' });
  }

  return {
    loadCandidate,
    loadCandidateApplication,
    loadApplication,
    loadCandidateAssignablePositions,
    resetApplication,
    resetToDefaultState,
  };
}
