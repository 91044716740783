import { AddIcon } from '@avocadoui/icons';
import {
  Box, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const ListboxComponent = forwardRef((props, ref) => {
  const { children, onCreateClick, ...other } = props;
  return (
    <Box ref={ref} {...other} style={{ position: 'relative' }}>
      {children}
      <Box height={52} />
      <Box height={48} position="fixed" color="#4CAF50" bottom={4} display="flex" alignItems="center" width style={{ background: 'white', borderRadius: 4, borderTop: '1px solid rgba(0, 0, 0, 0.08)' }}>
        <ListItem button onClick={onCreateClick}>
          <ListItemIcon><AddIcon htmlColor="#4CAF50" /></ListItemIcon>
          <ListItemText primary="添加外部协作者" />
        </ListItem>
      </Box>
    </Box>
  );
});

ListboxComponent.propTypes = {
  onCreateClick: PropTypes.func,
  children: PropTypes.node,
};

ListboxComponent.defaultProps = {
  onCreateClick: null,
  children: null,
};

export default ListboxComponent;
