import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as BlobIcon } from '../../assets/img/icons/notify/email/Blob.svg';
import { ReactComponent as UnderlineIcon } from '../../assets/img/icons/notify/email/Underline.svg';
import { ReactComponent as CenterIcon } from '../../assets/img/icons/notify/email/Center.svg';
import { ReactComponent as OlIcon } from '../../assets/img/icons/notify/email/Ol.svg';
import { ReactComponent as UlIcon } from '../../assets/img/icons/notify/email/Ul.svg';
import { ReactComponent as RightIcon } from '../../assets/img/icons/notify/email/Right.svg';
import { ReactComponent as LeftIcon } from '../../assets/img/icons/notify/email/Left.svg';
import ELEditorToolbarColor, { colorStyleMap } from './ELEditorToolbarColor';
import ELEditorToolbarBackgroundColor, { backgroundColorStyleMap } from './ELEditorToolbarBackgroundColor';
import ELEditorToolbarFontSize, { fontSizeStyleMap } from './ELEditorToolbarFontSize';
import ELEditorToolbarLink from './ELEditorToolbarLink';
import ELEditorToolbarImage from './ELEditorToolbarImage';

const ELEditorToolbar = (props) => {
  const {
    editorState,
    toggleColor,
    togglebackgroundColor,
    toggleFontSize,
    toggleBlockType,
    toggleInlineStyle,
    confirmMedia,
    confirmLink,
    removeLink,
    onAlignment,
  } = props;
  // const currentStyle = editorState.getCurrentInlineStyle();
  // console.log('BOLD', currentStyle.has('BOLD'));
  // console.log('BOLD', currentStyle.has('BOLD'));
  // console.log('UNDERLINE', currentStyle.has('UNDERLINE'));
  // console.log('unordered-list-item', currentStyle.has('unordered-list-item'));
  // console.log('ordered-list-item', currentStyle.has('ordered-list-item'));
  return (
    <div
      className="RichEditor-controls"
      style={{ display: 'flex', flexGrow: 0, flexShrink: 0 }}
      aria-label="editor-toolbar"
      role="button"
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <Tooltip title="字体加粗" arrow>
        <IconButton
          onClick={() => {
            toggleInlineStyle('BOLD');
          }}
        >
          <BlobIcon className="MuiSvgIcon-root" />
        </IconButton>
      </Tooltip>
      <Tooltip title="下划线" arrow>
        <IconButton
          onClick={() => {
            toggleInlineStyle('UNDERLINE');
          }}
        >
          <UnderlineIcon />
        </IconButton>
      </Tooltip>
      <ELEditorToolbarColor toggleInlineStyle={toggleColor} editorState={editorState} />
      <ELEditorToolbarFontSize toggleInlineStyle={toggleFontSize} editorState={editorState} />
      <IconButton
        onClick={() => {
          toggleBlockType('ordered-list-item');
        }}
      >
        <OlIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          toggleBlockType('unordered-list-item');
        }}
      >
        <UlIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          onAlignment('left');
        }}
      >
        <LeftIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          onAlignment('center');
        }}
      >
        <CenterIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          onAlignment('right');
        }}
      >
        <RightIcon />
      </IconButton>
      <ELEditorToolbarLink confirmLink={confirmLink} editorState={editorState} removeLink={removeLink} />
      <ELEditorToolbarBackgroundColor toggleInlineStyle={togglebackgroundColor} editorState={editorState} />
      <ELEditorToolbarImage confirmMedia={confirmMedia} />
    </div>
  );
};

ELEditorToolbar.propTypes = {
  editorState: PropTypes.objectOf(PropTypes.any),
  toggleColor: PropTypes.func,
  togglebackgroundColor: PropTypes.func,
  toggleFontSize: PropTypes.func,
  toggleBlockType: PropTypes.func,
  toggleInlineStyle: PropTypes.func,
  confirmMedia: PropTypes.func,
  confirmLink: PropTypes.func,
  removeLink: PropTypes.func,
  onAlignment: PropTypes.func,
};

ELEditorToolbar.defaultProps = {
  editorState: {},
  toggleColor: () => {},
  togglebackgroundColor: () => {},
  toggleFontSize: () => {},
  toggleBlockType: () => {},
  toggleInlineStyle: () => {},
  confirmMedia: () => {},
  confirmLink: () => {},
  removeLink: () => {},
  onAlignment: () => {},
};

const customColorStyleMap = {
  GreenSpan: {
    color: '#C3C3C3',
    display: 'inline-block',
    backgroundColor: '#F7F7F7',
    padding: '0px 4px',
    margin: '2px 0px 2px 5px',
    borderRadius: 4,
  },
  RedSpan: {
    color: 'rgba(255, 108, 108, 1)',
    display: 'inline-block',
    backgroundColor: 'rgba(255, 108, 108, 0.1)',
    padding: '0px 4px',
    margin: '2px 0px 2px 5px',
    borderRadius: 4,
    fontSize: 14,
  },
};

const customStyleMap = {
  ...colorStyleMap, ...fontSizeStyleMap, ...backgroundColorStyleMap, ...customColorStyleMap,
};
export { customStyleMap };
export default ELEditorToolbar;
