import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { useEffect, useState } from 'react';
import { useCandidateDispatch, useCandidateState } from '../context';
import { EmptyStatus } from '../Placeholder';
import QnAItem from '../QnAItem';
import styles from './CandidateResult.module.css';

function QnAResult() {
  const { applicationFlowData = {}, hideAISteps } = useCandidateState();
  const dispatch = useCandidateDispatch();
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (applicationFlowData.steps) {
      setSteps((applicationFlowData.steps && applicationFlowData.steps.filter((step) => step.status >= 3)) || []);
    } else {
      setSteps([]);
    }
  }, [applicationFlowData]);

  useEffect(() => {
    if (hideAISteps) {
      const noAISteps = applicationFlowData.steps
        && applicationFlowData.steps.filter((step) => step.aiType !== 2 && step.status >= 3);
      setSteps(noAISteps || []);
    } else {
      setSteps((applicationFlowData.steps && applicationFlowData.steps.filter((step) => step.status >= 3)) || []);
    }
  }, [hideAISteps]);

  function toggleSteps() {
    dispatch({ type: 'TOGGLE_HIDE_AI_STEPS' });
    dispatch({ type: 'SET_APPLICATION_FLOW_STEP_INDEX', payload: 0 });
  }

  return (
    <div>
      <div>
        <div className={styles.toggleButton}>
          <div>
            <Checkbox color="primary" size="small" checked={hideAISteps} onChange={toggleSteps} />
          </div>
          <div role="button" onClick={toggleSteps} className={styles.label}>
            只看主观题
          </div>
          <Tooltip
            title="主观题是指那些能更好地考查面试者具体情况或综合能力的试题，没有标准答案，需要面试官评判。"
            arrow
          >
            <InfoIcon size="small" style={{ marginLeft: '8px', fontSize: '16px', color: 'rgba(0,0,0,0.26)' }} />
          </Tooltip>
        </div>
      </div>
      {steps.length !== 0 ? (
        <div>
          {steps.map((s, index) => (
            <QnAItem key={s.token} item={s} index={index} />
          ))}
        </div>
      ) : (
        <div className={styles.emptyWrapper}>
          <EmptyStatus style={{ width: '100px', marginBottom: '10px' }} />
          <div>{hideAISteps ? '暂无主观题' : '暂无题目'}</div>
        </div>
      )}
    </div>
  );
}

export default QnAResult;
