import { InterviewProvider } from '@components/InterviewArrangement/context';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import AtMessageList from '@module/Message/ChatList/Components/AtMessageList';
import { useState } from 'react';

const Index = () => {
  const [value, setValue] = useState('2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <TabList
        onChange={handleChange}
        aria-label="inbox tabs"
        indicatorColor="primary"
        style={{ width: 360 }}
        TabIndicatorProps={{ style: { width: 160 } }}
      >
        <Tab label="提到我" value="2" />
      </TabList>
      <TabPanel value="2" style={{ padding: 0, width: 360, minHeight: 520 }}>
        <InterviewProvider>
          <AtMessageList handleOpenCandidateDialog={() => {}} />
        </InterviewProvider>
      </TabPanel>
    </TabContext>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
