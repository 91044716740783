import { handleActions } from 'redux-actions';
import { closeSnackbar, enqueueSnackbar, removeSnackbar } from './action';

export const namespace = 'toast';

export const defaultState = {
  notifications: [],
};

export const toastReducer = handleActions(
  {
    [enqueueSnackbar]: (state, action) => {
      const { key, notification } = action.payload;
      console.log(action);
      return ({
        ...state,
        notifications: [
          ...state.notifications,
          {
            key,
            ...notification,
          },
        ],
      });
    },
    [closeSnackbar]: (state, action) => {
      const { key, dismissAll } = action.payload;
      return ({
        ...state,
        notifications: state.notifications.map((notification) => (
          (dismissAll || notification.key === key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )),
      });
    },
    [removeSnackbar]: (state, action) => {
      const { key } = action.payload;
      return ({
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== key,
        ),
      });
    },
  },
  defaultState,
);
