import { getApplicationLogs } from '@api/Application';
import Comment from '@components/Comment';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useCandidateDispatch } from '../context';

export default function CommentWrapper({ applicationProcessToken, setShowActionButtons }) {
  const dispatch = useCandidateDispatch();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);

  const handleUpdateCommentList = useCallback(() => {
    getApplicationLogs(applicationToken).then((result) => {
      if (result.data.code === 0) {
        dispatch({
          type: 'SET_APPLICATION_LOGS_DATA',
          payload: result.data.data.list,
        });
      }
    });
  }, [applicationToken]);

  return (
    <Comment
      applicationToken={applicationToken}
      applicationProcessToken={applicationProcessToken}
      onUpdateCommentList={handleUpdateCommentList}
      setShowActionButtons={setShowActionButtons}
    />
  );
}

CommentWrapper.propTypes = {
  applicationProcessToken: PropTypes.string.isRequired,
  setShowActionButtons: PropTypes.func.isRequired,
};
