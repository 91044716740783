import { createActions } from 'redux-actions';

export const {
  openCandidateModal,
  closeCandidateModal,
  destroyCandidateModal,
  setCandidateToken,
  setApplicationToken,
  setCandidateAndApplicationToken,
  resetCandidateAndApplicationToken,
} = createActions(
  {},
  'OPEN_CANDIDATE_MODAL',
  'CLOSE_CANDIDATE_MODAL',
  'DESTROY_CANDIDATE_MODAL',
  'SET_CANDIDATE_TOKEN',
  'SET_APPLICATION_TOKEN',
  'SET_CANDIDATE_AND_APPLICATION_TOKEN',
  'RESET_CANDIDATE_AND_APPLICATION_TOKEN',
);
