import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  searchList: {
    position: 'absolute',
    left: 0,
    bottom: -268,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 0px',
    width: '100%',
    height: 266,
    boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.16)',
    zIndex: 999,
  },
  candidateList: {
    width: '100%',
    height: '258px',
    marginBlockStart: '0em',
    marginBlockEnd: '0em',
    paddingInlineStart: '0',
    margin: 0,
    padding: 0,
    overflow: 'scroll',
  },
  candidateItem: {
    listStyle: 'none',
    width: '100%',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0.04)',
      outline: 'none',
    },
  },
  candidateName: {
    marginLeft: theme.spacing(2),
    fontSize: 14,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: theme.spacing(21),
  },
  candidateContact: {
    position: 'absolute',
    right: theme.spacing(2),
    fontSize: '0.875rem',
    color: 'rgba(0,0,0,0.6)',
  },
}));

export default useStyles;
