import Box from '@material-ui/core/Box';
import { Route } from 'react-router-dom';
import loadable from 'react-loadable';
import Header from './Header';
import Container from './Container';
import withInterviewAuth from '../../hoc/withInterviewAuth';
import Common from './Common';

const ProfileLoadable = loadable({
  loader: () => import('../../views/setting/Profile/Profile'),
  loading: () => null,
});
const InterviewLoadable = loadable({
  loader: () => import('./Interview'),
  loading: () => null,
});
const Layout = () => (
  <Box>
    <Header />
    <Container>
      <Route exact path="/layout/profile" component={ProfileLoadable} />
      <Route exact path="/layout/interview" component={InterviewLoadable} />
      <Common />
    </Container>
  </Box>
);

Layout.propTypes = {};

Layout.defaultProps = {};

export default withInterviewAuth(Layout);
