import { TextField } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { zhCN } from 'date-fns/locale';
import PropTypes from 'prop-types';

const ResumeDatePicker = ({
  name, value, label, onChange,
}) => (
  <LocalizationProvider dateAdapter={DateFnsAdapter} locale={zhCN}>
    <DatePicker
      fullWidth
      autoOk
      error={false}
      helperText=""
      variant="inline"
      name={name}
      inputVariant="outlined"
      label={label}
      size="small"
      value={value}
      renderInput={(props) => <TextField fullWidth variant="outlined" {...props} helperText="" size="small" />}
      onChange={(date) => {
        onChange({ target: { name, value: date } });
      }}
    />
  </LocalizationProvider>
);
ResumeDatePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

ResumeDatePicker.defaultProps = {
  name: ' date',
  value: '',
  label: '日期',
  onChange: null,
};

export default ResumeDatePicker;
