/* eslint-disable react/prop-types */
import * as API from '@api/Candidate';
import Typography from '@components/Avocado/Typography';
import ELEmailManualEditor from '@components/ELEditor/ELEmailManualEditor';
import Spin from '@components/Spin';
import { Button, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Popover from '@material-ui/core/Popover';
import withStyles from '@material-ui/core/styles/withStyles';
import Alert from '@material-ui/lab/Alert';
import { transitionServerToEditorState } from '@utils/DraftUtils';
import { MESSAGES_TEMPLATE_STATUS } from '@utils/Template/SMSMessageStatus';
import {
  convertFromRaw, convertToRaw, Editor, EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import { Component } from 'react';

const useStyles = {
  checkDiv: {
    lineHeight: '35px',
    margin: '0px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: -12,
  },
  checkbox: {
    marginRight: '8px',
  },
  content: {
    width: '100%',
    minHeight: 161,
    border: 'solid 1px #E6E6E6',
    borderRadius: 4,
    margin: '0px',
    padding: '16px 8px',
  },
  warring: {
    height: '37px',
    color: '#F5C441',
    fontSize: '12px',
    margin: '-16px -16px 0px',
    lineHeight: '12px',
    backgroundColor: 'rgba(245, 196, 65, 0.1)',
    borderRadius: '2px 2px 0px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    height: '37px',
    color: '#FF0000',
    fontSize: '14px',
    margin: '-16px -16px 0px',
    lineHeight: '12px',
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    borderRadius: '2px 2px 0px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

class TempleteFill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorStateEmail: EditorState.createEmpty(),
      editorStateWechat: EditorState.createEmpty(),
      editorStateSms: EditorState.createEmpty(),
      // notifyMsg: '',
      notifyMsgSms: '',
      notifyMsgWechat: '',
      notifySubjectEmail: '',
      notifyAttachmentsEmail: [],
      notifyNeedFeedbackEmail: 0,
      notifyMsgEmail: '',
      notifyMailToAddress: '',
      tempNotifyMailToAddress: '',
      isSendWechat: false,
      isSendSms: false,
      isSendEmail: false,
      canSend: true,
      candidateEmailError: '',
    };
  }

  onChangeWechat = (editorStateWechat) => {
    const msg = this.convertToString(editorStateWechat);
    this.setState({
      editorStateWechat,
      notifyMsgWechat: msg,
    });
  };

  onChangeSms = (editorStateSms) => {
    const msg = this.convertToString(editorStateSms);
    this.setState({
      editorStateSms,
      notifyMsgSms: msg,
    });
  };

  onChangeEmail = (editorStateEmail) => {
    const msg = draftToHtml(convertToRaw(editorStateEmail.getCurrentContent()));
    this.setState({
      editorStateEmail,
      notifyMsgEmail: msg,
    });
  };

  componentDidMount = () => {
    const { templateRich, richSms, richEmail } = this.props;
    if (templateRich) {
      this.convertToEditWechat();
    }
    if (richSms) {
      this.convertToEditSms();
    }
    if (richEmail) {
      this.convertToEditEmail();
    }
  };

  convertToString = (editorState) => {
    const { blocks } = convertToRaw(editorState.getCurrentContent());
    let text = '';
    blocks.forEach((line) => {
      text += `${line.text}\n`;
    });
    return text;
  };

  convertToEditEmail = () => {
    const {
      richEmail, variablesEmail, emailSubject, mailToAddress, emailTemplate,
    } = this.props;
    if (richEmail && richEmail !== '') {
      const content = JSON.parse(richEmail);
      const contentState = convertFromRaw(content);
      const es = EditorState.createWithContent(contentState);

      const replaceContentState = transitionServerToEditorState(es, variablesEmail);
      const replaceEditorState = EditorState.createWithContent(replaceContentState);
      const msg = draftToHtml(convertToRaw(replaceContentState));

      this.setState({
        isSendEmail: true,
        editorStateEmail: replaceEditorState,
        notifyMsgEmail: msg,
        notifyAttachmentsEmail: emailTemplate.emailAttachments,
        notifyNeedFeedbackEmail: emailTemplate.emailFeedbackSwitch,
        notifySubjectEmail: emailSubject,
        notifyMailToAddress: mailToAddress,
        tempNotifyMailToAddress: mailToAddress,
      });
    }
  };

  convertToEditWechat = () => {
    const { templateRich, variables } = this.props;
    if (templateRich && templateRich !== '') {
      const content = JSON.parse(templateRich);
      const contentState = convertFromRaw(content);
      const es = EditorState.createWithContent(contentState);

      const replaceContentState = transitionServerToEditorState(es, variables);
      const replaceEditorState = EditorState.createWithContent(replaceContentState);

      const msg = this.convertToString(replaceEditorState);
      this.setState({
        isSendWechat: true,
        editorStateWechat: replaceEditorState,
        notifyMsgWechat: msg,
      });
    }
  };

  convertToEditSms = () => {
    const { richSms, variablesSms } = this.props;
    if (richSms && richSms !== '') {
      const content = JSON.parse(richSms);
      const contentState = convertFromRaw(content);
      const es = EditorState.createWithContent(contentState);
      const replaceContentState = transitionServerToEditorState(es, variablesSms);
      const replaceEditorState = EditorState.createWithContent(replaceContentState);

      const msg = this.convertToString(replaceEditorState);
      this.setState({
        isSendSms: true,
        editorStateSms: replaceEditorState,
        notifyMsgSms: msg,
      });
    }
  };

  render() {
    const {
      editorStateEmail,
      editorStateWechat,
      editorStateSms,
      notifyMsgWechat,
      notifyMsgSms,
      notifyMsgEmail,
      notifySubjectEmail,
      notifyAttachmentsEmail,
      notifyNeedFeedbackEmail,
      notifyMailToAddress,
      tempNotifyMailToAddress,
      isSendWechat,
      isSendSms,
      isSendEmail,
      canSend,
      candidateEmailError,
    } = this.state;
    const {
      classes,
      open,
      handleClose,
      handleConfirmNotifyMsg,
      auditStatus,
      templateSms,
      template,
      templateEmail,
      needFeedbackHidden,
      // mailToAddress,
      candidateTokens,
      templateLoading,
    } = this.props;
    const customColorStyleMap = {
      GreenSpan: {
        color: '#4caf50',
        display: 'inline-block',
        backgroundColor: '#e8f5e9',
        padding: '0px 4px',
        margin: '2px 0px 2px 5px',
        borderRadius: 4,
        fontSize: 14,
      },
      RedSpan: {
        color: 'rgba(255, 108, 108, 1)',
        display: 'inline-block',
        backgroundColor: 'rgba(255, 108, 108, 0.1)',
        padding: '0px 4px',
        margin: '2px 0px 2px 5px',
        borderRadius: 4,
        fontSize: 14,
      },
    };
    const severity = () => {
      if (auditStatus === 3) return 'error';
      return auditStatus === 1 ? 'warning' : 'success';
    };

    // EAD79F 候选人详情页-右侧Stepper-流程间移动候选人-弹出手动模板消息编辑页面（需要设置手动发送的模板消息）
    return (
      <Dialog
        scroll="paper"
        rapidpositioning="EAD79F"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 630,
          },
        }}
        maxWidth={false}
      >
        <DialogTitle id="form-dialog-title">模板消息</DialogTitle>
        <DialogContent>
          {templateLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
              <Spin />
            </Box>
          ) : (
            <>
              {templateSms && templateSms.length > 0 && (
                <>
                  <div
                    role="button"
                    className={classes.checkDiv}
                    onClick={() => {
                      this.setState({
                        isSendSms: !isSendSms,
                        canSend: (() => {
                          if (!isSendSms) {
                            return notifyMsgSms.length > 0;
                          }
                          if (isSendWechat) {
                            return notifyMsgWechat.length > 0;
                          }
                          if (isSendEmail) {
                            return (
                              notifySubjectEmail.length > 0
                              && notifyMsgEmail.replace(/<(.|\n)*?>/g, '').trim().length > 0
                            );
                          }
                          return false;
                        })(),
                      });
                    }}
                  >
                    <Checkbox checked={isSendSms} color="primary" />
                    短信通知
                  </div>
                  {isSendSms && (
                    <Box className={classes.content} style={{ paddingTop: 0 }}>
                      {/* 1审核中 2 审核通过 3 审核拒绝 */}
                      <Alert severity={severity()}>{MESSAGES_TEMPLATE_STATUS[severity()]}</Alert>

                      <Editor
                        customStyleMap={customColorStyleMap}
                        editorState={editorStateSms}
                        onChange={this.onChangeSms}
                      />
                    </Box>
                  )}
                </>
              )}
              {template && template.length > 0 && (
                <>
                  <div
                    role="button"
                    className={classes.checkDiv}
                    onClick={() => {
                      this.setState({
                        isSendWechat: !isSendWechat,
                        canSend: (() => {
                          if (isSendSms) {
                            return notifyMsgSms.length > 0;
                          }
                          if (!isSendWechat) {
                            return notifyMsgWechat.length > 0;
                          }
                          if (isSendEmail) {
                            return (
                              notifySubjectEmail.length > 0
                              && notifyMsgEmail.replace(/<(.|\n)*?>/g, '').trim().length > 0
                            );
                          }
                          return false;
                        })(),
                      });
                    }}
                  >
                    <Checkbox checked={isSendWechat} color="primary" />
                    小程序通知
                  </div>
                  {isSendWechat && (
                    <Box className={classes.content}>
                      <Editor
                        customStyleMap={customColorStyleMap}
                        editorState={editorStateWechat}
                        onChange={this.onChangeWechat}
                      />
                    </Box>
                  )}
                </>
              )}
              {templateEmail && templateEmail.length > 0 && (
                <>
                  <div className={classes.checkDiv}>
                    <Box
                      display="flex"
                      alignItems="center"
                      mr={2}
                      onClick={() => {
                        this.setState({
                          isSendEmail: !isSendEmail,
                          canSend: (() => {
                            if (isSendSms) {
                              return notifyMsgSms.length > 0;
                            }
                            if (isSendWechat) {
                              return notifyMsgWechat.length > 0;
                            }
                            if (!isSendEmail) {
                              return (
                                notifySubjectEmail.length > 0
                                && notifyMsgEmail.replace(/<(.|\n)*?>/g, '').trim().length > 0
                              );
                            }
                            return false;
                          })(),
                        });
                      }}
                    >
                      {/* {isSendEmail && notifyMailToAddress.length > 0 ? (
                  <CheckboxChecked className={classes.checkbox} />
                ) : (
                  <Checkbox
                    className={classes.checkbox}
                    disabled={notifyMailToAddress.length === 0 || candidateTokens.length > 1}
                  />
                  )} */}
                      <Checkbox
                        disabled={notifyMailToAddress.length === 0}
                        checked={isSendEmail && notifyMailToAddress.length > 0}
                        color="primary"
                      />
                      邮件通知
                    </Box>

                    {notifyMailToAddress && candidateTokens.length === 1 && (
                      <Typography variant="caption" color="text.secondary" style={{ marginRight: 8 }}>
                        发送至 {notifyMailToAddress}
                      </Typography>
                    )}
                    {candidateTokens.length === 1 && (
                      <PopupState variant="popover">
                        {(popupState) => (
                          <>
                            <Typography
                              {...bindTrigger(popupState)}
                              variant="caption"
                              color="text.link"
                              style={{ cursor: 'pointer' }}
                            >
                              {notifyMailToAddress ? '修改' : '点击新增邮箱'}
                            </Typography>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <Box width="280px" display="flex" flexDirection="column" pt={3} pl={3} pr={2} pb={1.5}>
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  size="small"
                                  error={candidateEmailError && candidateEmailError.length > 0}
                                  helperText={candidateEmailError}
                                  value={tempNotifyMailToAddress}
                                  onChange={(e) => {
                                    this.setState({ tempNotifyMailToAddress: e.target.value.trim() });
                                  }}
                                />
                                <Box width="100%" display="flex" justifyContent="flex-end" mt={1.5}>
                                  <Button
                                    size="medium"
                                    onClick={() => {
                                      this.setState({
                                        candidateEmailError: '',
                                      });
                                      popupState.close();
                                    }}
                                  >
                                    取消
                                  </Button>
                                  <Box mr={1} />
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => {
                                      if (
                                        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tempNotifyMailToAddress)
                                        || tempNotifyMailToAddress.length === 0
                                      ) {
                                        API.patchCandidate(candidateTokens[0], {
                                          Field: 'email',
                                          Value: tempNotifyMailToAddress,
                                        })
                                          .then((v) => {
                                            if (v.data.code === 0) {
                                              this.setState({
                                                notifyMailToAddress: tempNotifyMailToAddress,
                                                candidateEmailError: '',
                                              });
                                              popupState.close();
                                            } else {
                                              this.setState({ candidateEmailError: v.data.message });
                                            }
                                          })
                                          .catch(console.log);
                                      } else {
                                        this.setState({ candidateEmailError: '邮箱格式不正确' });
                                      }
                                    }}
                                  >
                                    确定
                                  </Button>
                                </Box>
                              </Box>
                            </Popover>
                          </>
                        )}
                      </PopupState>
                    )}
                  </div>
                  {/* <Box mr={3} ml={3}>
              <OutlinedInputLimited
                maxLength={50}
                placeholder="请添加邮件主题(必填）"
                value={notifySubjectEmail}
                error={emailSubjectError && emailSubjectError.length > 0}
                helperText={emailSubjectError}
                onChange={(e) => {
                  this.setState({
                    notifySubjectEmail: e.target.value.trim(),
                    canSend: (() => {
                      if (isSendEmail) {
                        return e.target.value.trim().length;
                      }
                      return isSendSms || isSendWechat;
                    })(),
                  });
                }}
              />
            </Box> */}

                  {/* <DialogContent className={classes.content}> */}
                  {isSendEmail && notifyMailToAddress.length > 0 && (
                    <Box>
                      <ELEmailManualEditor
                        customStyleMap={customColorStyleMap}
                        // 模板信息
                        // defaultTemplate={emailTemplate.rich}
                        // template={emailTemplate}
                        // 是否反馈
                        needFeedback={notifyNeedFeedbackEmail}
                        needFeedbackHidden={needFeedbackHidden}
                        onNeedFeedbackChange={(value) => {
                          this.setState({ notifyNeedFeedbackEmail: value });
                        }}
                        // 附件
                        attachments={notifyAttachmentsEmail}
                        onAttachmentsChange={(attachments) => {
                          this.setState({ notifyAttachmentsEmail: attachments });
                        }}
                        // 邮件主题相关
                        subject={notifySubjectEmail}
                        onSubjectChange={(value) => {
                          this.setState({ notifySubjectEmail: value });
                        }}
                        // 编辑器状态
                        editorState={editorStateEmail}
                        setEditorState={(v) => {
                          this.setState({ editorStateEmail: v });
                          this.onChangeEmail(v);
                        }}
                        // onChange={this.onChangeEmail}
                      />
                    </Box>
                  )}
                  {/* </DialogContent> */}
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions style={{
          position: 'sticky', bottom: 0, left: 0, right: 0, zIndex: 999,
        }}
        >
          <Button onClick={handleClose}>不发送</Button>
          <Button
            variant="contained"
            disabled={!canSend}
            onClick={() => {
              const wechatMessage = isSendWechat ? notifyMsgWechat : '';
              const smsMessage = isSendSms ? notifyMsgSms : '';
              const emailMessage = isSendEmail && notifyMailToAddress.length > 0 ? notifyMsgEmail : '';
              const emailMessageSubject = isSendEmail && notifyMailToAddress.length > 0 ? notifySubjectEmail : '';
              const attachments = (isSendEmail && notifyMailToAddress.length > 0 && notifyAttachmentsEmail) || [];
              handleConfirmNotifyMsg(
                wechatMessage,
                smsMessage,
                emailMessage,
                emailMessageSubject,
                attachments,
                notifyNeedFeedbackEmail,
              );
            }}
            color="primary"
          >
            发送
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

TempleteFill.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  templateRich: PropTypes.string.isRequired,
  variables: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmNotifyMsg: PropTypes.func.isRequired,
};

export default withStyles(useStyles)(TempleteFill);
