import axios from './Api';

export const getCodeInterviews = (token) => {
  if (!token) return Promise.reject();
  return axios.request({
    url: `/applicationProcess/${token}/codeInterviews`,
    method: 'GET',
  });
};

export const createCodeInterivews = (applicationTokens, planTime) => axios.request({
  url: '/vc1/codeInterview',
  method: 'POST',
  data: { applicationTokens, planTime },
});

export const editCodeInterivew = (token, data) => axios.request({
  url: `/vc1/codeInterview/${token}`,
  method: 'PUT',
  data,
});

export const deleteCodeInterivews = (tokens) => axios.request({
  url: '/vc1/codeInterview',
  method: 'POST',
  data: { tokens },
});

export const addBatchCodeInterivews = (data) => axios.request({
  url: '/codeInterview/batch',
  method: 'POST',
  data,
});
