import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AIResultCardItem from './AIResultCardItem';
import styles from './CandidateResult.module.css';

function AIResultCard({ item = {} }) {
  const [open, setOpen] = useState(false);
  const {
    name,
    type,
    scores: { weight },
  } = item;

  return (
    <div className={styles.aiResultCard}>
      <div className={styles.header}>
        <div className={styles.flex}>
          <div className={styles.titleLine} />
          <div className={styles.title}>{type === 2 ? '视频面试' : name}相关得分</div>
          <div className={styles.titleInfo}>
            <Tooltip
              title={(
                <div>
                  <div>得分 = 原分值 × 单题权重 × 模块权重</div>
                  <div>如需手动调整，请在职位详情页设置</div>
                </div>
              )}
              arrow
            >
              <InfoIcon style={{ fontSize: '14px' }} />
            </Tooltip>
          </div>
        </div>
        <div role="button" className={styles.collapse} onClick={() => setOpen((o) => !o)}>
          {open ? <ExpandLessIcon style={{ fontSize: '18px' }} /> : <ExpandMoreIcon style={{ fontSize: '18px' }} />}
          <div style={{ marginLeft: '4px' }}>{open ? '收起' : '展开'}</div>
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {item.steps?.map((s, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <AIResultCardItem key={i} item={s} moduleWeight={weight} />
        ))}
      </Collapse>
    </div>
  );
}

AIResultCard.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AIResultCard;
