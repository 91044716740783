import RosePie from '@components/Charts/RosePie';
import RosePieComputing from '@components/Charts/RosePieComputing';
import PropTypes from 'prop-types';
import AIResultCard from './AIResultCard';
import formatScore from './formatScore';
import ScoreBigBall from './ScoreBigBall';
import ScoreLegend from './ScoreLegend';

function formatLowScore(num) {
  if (num < 8) {
    return 8;
  }
  return num;
}

function reducerPie(acc, cur, index) {
  const {
    type,
    steps,
    scores: { weight: processWeight },
  } = cur;
  const hasFoundTypeTwo = acc.some((v) => v[0] === 2);
  let processIndex = index;
  if (hasFoundTypeTwo) processIndex -= 1;
  steps.forEach(({ name, scores }, i) => {
    const { originScore, weight, finalScore } = scores;
    acc.push([
      type,
      `${i + 1}. ${name}`,
      formatScore(originScore),
      formatScore(weight) * formatScore(processWeight),
      formatScore(finalScore),
      formatLowScore(parseInt(finalScore, 10)),
      processIndex,
    ]);
  });
  return acc;
}

function reducerPieComputing(acc, cur, index) {
  const {
    type,
    scores: { weight },
  } = cur;
  const hasFoundTypeTwo = acc.some((v) => v[0] === 2);
  let processIndex = index;
  if (hasFoundTypeTwo) processIndex -= 1;
  acc.push([type, formatScore(weight), processIndex]);
  return acc;
}

function AIResultFull({ result = {} }) {
  const { status, score, processList } = result;
  let allIsComputing = true;
  processList.forEach((p) => {
    p.steps.forEach((q) => {
      if (q.status === 3) {
        allIsComputing = false;
      }
    });
  });
  const source = status === 2 && allIsComputing
    ? processList.filter((p) => p.status !== 0).reduce(reducerPieComputing, [])
    : processList.filter((p) => p.status !== 0).reduce(reducerPie, []);
  let processIndex = -1;

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            position: 'relative',
            height: '220px',
            flex: 1,
          }}
        >
          {status === 1 && (
            <div
              style={{
                backgroundColor: '#fbfbfb',
                width: '220px',
                height: '220px',
                borderRadius: '50%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                }}
              />
            </div>
          )}
          <ScoreBigBall score={score} status={status} />
          {status === 2 && allIsComputing ? <RosePieComputing source={source} /> : null}
          {status === 2 && !allIsComputing ? <RosePie source={source} /> : null}
          {status === 3 ? <RosePie source={source} /> : null}
        </div>
        <div style={{ flex: 1 }}>
          {processList.map((p, i) => {
            if (p.type !== 2) processIndex += 1;
            // eslint-disable-next-line react/no-array-index-key
            return <ScoreLegend key={i} item={p} processIndex={processIndex} />;
          })}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {processList.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <AIResultCard key={i} item={item} />
        ))}
      </div>
    </div>
  );
}

AIResultFull.propTypes = {
  result: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AIResultFull;
