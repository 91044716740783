/* eslint-disable no-plusplus */
import { upload } from '@api/Common';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import * as UpdateDashboardSwitchActions from '@reduxold/componentSwitch';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  useCallback, useRef,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CANCELED_PROCESS_APPLICATION_STATE } from '../const';
import { useCandidateDispatch, useCandidateState } from '../context';
import styles from './CandidateAttachments.module.css';

const FILE_SIZE_LIMIT = 50; // 单位为MB

function handleUploadProgress(dispatch, progress, folderIndex, fileIndex, fileData) {
  dispatch({
    type: 'SET_UPLOADING_ATTACHMENT',
    payload: {
      folderIndex,
      fileIndex,
      file: {
        ...fileData,
        loaded: progress.loaded,
        total: progress.total,
      },
    },
  });
}

function AttachmentFileInput({ files, folderIndex, updateDashboardSwitchActions }) {
  const isEditing = false;
  const dispatch = useCandidateDispatch();
  const isCanceled = useCandidateState((state) => CANCELED_PROCESS_APPLICATION_STATE
    .indexOf(state.applicationData.applicationState) > -1);
  const inputRef = useRef();
  const onUploadClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  return (
    <Box>
      <Button variant="outlined" onClick={onUploadClick} disabled={isCanceled}>
        添加附件
      </Button>
      <input
        ref={inputRef}
        type="file"
        multiple
        disabled={isEditing}
        className={clsx({
          [styles.input]: true,
          [styles.disabled]: isEditing,
        })}
        onChange={(e) => {
          if (e.target.files.length > 10 - files.length) {
            updateDashboardSwitchActions.updateSnackbarErrorMessageSwitch('文件数量已达10个上限');
            updateDashboardSwitchActions.updateSnackbarOpenSwitch(true);
            return;
          }
          for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            if (file.size && file.size > FILE_SIZE_LIMIT * 1024 * 1024) {
              updateDashboardSwitchActions.updateSnackbarErrorMessageSwitch('文件过大，请上传50M以内的文件');
              updateDashboardSwitchActions.updateSnackbarOpenSwitch(true);
              return;
            }
          }
          for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            const formData = new FormData();
            formData.append('files', file);
            const fileIndex = files.length + i;
            const fileData = {
              name: file.name,
              length: file.size,
            };
            upload(formData, (progress) => {
              handleUploadProgress(dispatch, progress, folderIndex, fileIndex, fileData);
            }).then((result) => {
              if (result?.data?.code === 0) {
                const { url: uploadedUrl } = result.data.data[0];
                dispatch({
                  type: 'SET_UPLOADING_ATTACHMENT',
                  payload: {
                    folderIndex,
                    fileIndex,
                    file: {
                      ...fileData,
                      uploadedUrl,
                    },
                  },
                });
              } else {
                dispatch({
                  type: 'SET_UPLOADING_ATTACHMENT',
                  payload: {
                    folderIndex,
                    fileIndex,
                    file: fileData,
                  },
                });
              }
            });
          }
          // eslint-disable-next-line no-param-reassign
          e.target.value = null;
        }}
      />
    </Box>
  );
}

AttachmentFileInput.propTypes = {
  files: PropTypes.objectOf(PropTypes.any).isRequired,
  folderIndex: PropTypes.number.isRequired,
  updateDashboardSwitchActions: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    updateDashboardSwitchActions: bindActionCreators(UpdateDashboardSwitchActions, dispatch),
  }),
)(AttachmentFileInput);
