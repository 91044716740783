import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'rgba(0,0,0,0.56)',
  },
  contentRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const ELDialog = ({
  classes, open, onClose, title, content, actions, height,
}) => (
  <Dialog
    aria-labelledby="department-delete-dialog-title"
    className={classes.root}
    PaperProps={{
      style: {
        width: '588px',
        height: height || 'auto',
      },
    }}
    open={open}
  >
    <DialogTitle>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {title}
        </Box>
        {onClose && (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </DialogTitle>
    <DialogContent>{content}</DialogContent>
    {actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
);

ELDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.element.isRequired,
  actions: PropTypes.element,
  height: PropTypes.number,
};

ELDialog.defaultProps = {
  actions: null,
  open: false,
  height: 200,
};

export default withStyles(styles)(ELDialog);
