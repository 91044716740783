export const CANDIDATE_MIX_AGE = 16;
export const CANDIDATE_DEFAULT_MIN_AGE = 18;
export const CANDIDATE_MAX_AGE = 60;
export const CANDIDATE_DEFAULT_MAX_AGE = CANDIDATE_MAX_AGE;

export const START_PAGE = 1;
export const PAGE_SIZE_PRE = 50;
export const POSITION_PAGE_SIZE_PRE = 15;

export const ROLE_ENTERPRISE_SUPER_ADMIN = 1;
export const ROLE_ENTERPRISE_ADMIN = 2;
export const ROLE_ENTERPRISE_MEMBER = 3;
// 布尔类型服务端数据互转
export const TRANSFORM_VALUE_BOOLEAN = (val) => val === 1;
export const TRANSFORM_BOOLEAN_VALUE = (val) => (val ? 1 : 2);

// 是否AI测试题
export const IS_TEST_TYPE_AI = (val) => val === 2;
export const IS_TEST_TYPE_EXTRA_AI = (pattern) => pattern.type === 1
  && pattern.aiAbilities
  && pattern.aiAbilities.length > 0;

export const FILTER_PROCESS_STATE = [
  { key: 1, name: '未安排' },
  { key: 2, name: '进行中' },
  { key: 3, name: '已完成' },
];

export const ATTACHMENT_TYPE_TITLES = ['附件', '链接'];

export const FILTER_INVITE_STATE = [
  {
    name: '未接受',
    key: 1,
  },
  {
    name: '已接受',
    key: 2,
  },
  {
    name: '已拒接',
    key: 3,
  },
];
export const FILTER_EMAIL_INVITE_STATE = [
  {
    name: '已接受',
    key: 1,
  },
  {
    name: '已拒绝',
    key: 2,
  },
  {
    name: '其它',
    key: 3,
  },
];
export const FILTER_EXAM_WAIRTTEN = [
  {
    name: '未安排',
    key: -1,
  },
  {
    name: '已安排',
    key: 1,
  },
  {
    name: '待评分',
    key: 2,
  },
  {
    name: '已完成',
    key: 3,
  },
  {
    name: '已放弃',
    key: 4,
  },
  // {
  //   name: '已失效',
  //   key: 99,
  // },
];

export const FILTER_CODE_INTERVIEW = [
  {
    name: '未安排',
    key: -1,
  },
  {
    name: '已安排',
    key: 1,
  },
  {
    name: '已完成',
    key: 2,
  },
];

export const FILTER_GENDERS = [
  { key: 1, name: '男' },
  { key: 2, name: '女' },
];

export const FILTER_DEGREES = [
  { key: -1, name: '暂无学历' },
  { key: 1, name: '初中以下' },
  { key: 2, name: '初中' },
  { key: 3, name: '高中' },
  { key: 4, name: '职高' },
  { key: 5, name: '中专' },
  { key: 6, name: '大专' },
  { key: 7, name: '本科' },
  { key: 8, name: '硕士' },
  { key: 9, name: '博士' },
  { key: 99, name: '其他学历' },
];

export const FILTER_SOURCES = [
  { key: 1, name: 'HR录入' },
  { key: 2, name: '斗米' },
  { key: 3, name: '机构' },
  { key: 4, name: '分享' },
  { key: 5, name: '邀请码' },
  { key: 6, name: '智联招聘' },
  { key: 7, name: 'Boss直聘' },
  { key: 8, name: '58同城' },
  { key: 9, name: '前程无忧' },
  { key: 10, name: '实习僧' },
  { key: 13, name: '直播' },
];

export const GetSourceDesc = (source) => {
  switch (source) {
    case 1:
      return 'HR录入';
    case 2:
      return '斗米';
    case 3:
      return '机构';
    case 4:
      return '分享';
    case 5:
      return '邀请码';
    case 6:
      return '智联招聘';
    case 7:
      return 'Boss直聘';
    case 8:
      return '58同城';
    case 9:
      return '前程无忧';
    case 10:
      return '实习僧';
    case 11:
      return '附件简历';
    case 12:
      return 'Moka';
    case 13:
      return '直播';
    default:
      return '';
  }
};

export const FILTER_RECOMMEND_STATE = [{ key: 1, name: '推荐' }];

export const RANDOM_COLOR = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#1E88E5',
  '#0288D1',
  '#0097A7',
  '#009688',
  '#43A047',
  '#E65100',
  '#795548',
  '#757575',
];

export const CANDIDATE_IMPORT_TEMPLATE_URL = process.env.REACT_APP_ENV === 'prod'
  ? '//public-static-assets.oss-cn-beijing.aliyuncs.com/hr/%E5%80%99%E9%80%89%E4%BA%BA%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.csv'
  : 'https://panohire-frontend-test.oss-cn-beijing.aliyuncs.com/hr/%E5%80%99%E9%80%89%E4%BA%BA%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.csv';
export const STAFF_IMPORT_TEMPLATE_URL = '//public-static-assets.oss-cn-beijing.aliyuncs.com/hr/%E5%91%98%E5%B7%A5%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.csv';
export const CANDIDATE_IMPORT_TEMPLATE_NAME = '候选人导入模板.csv';
export const CANDIDATE_RESUME_UPLOAD_FILE_TYPE = '.doc,.docx,.pdf,.PDF,.DOC,.DOCX,.png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.html,.HTML';

export const SYSTEM_PROCESS_INTERVIEWING = 'wON8ZwUCg4YpUPR7SQ9jQ79L7T'; // 待面试
export const SYSTEM_PROCESS_WAIT_AUDIT = 'jeA9idIleSjtFVPSk5ErQ79L7U'; // 待审核
export const SYSTEM_PROCESS_PASSED = 'U4A0YXcShXv0DlLxxg58Q79L7V'; // 已通过
export const SYSTEM_PROCESS_REJECTED = 'DhjxBr1CFM3PnmbO3PeoQ79L7W'; // 已淘汰
// 游戏类型测试题
export const TEST_TYPE_GAME_COLLECTION = [7, 8, 9, 10];

export const TEST_TYPE_READONLY = [2, 3, 4, 7, 8, 9, 10];

export const USER_ROLES = {
  1: '管理员',
  2: '企业账号',
  3: '普通账号',
  4: '站长账号',
  5: '面试官账号',
};

export const DegreeObject = {
  '-1': '暂无学历',
  0: '不限',
  1: '初中以下',
  2: '初中',
  3: '高中',
  4: '职高',
  5: '中专',
  6: '大专',
  7: '本科',
  8: '硕士',
  9: '博士',
  99: '其他学历',
};
