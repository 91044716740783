import * as Api from '@api/ApplicationShare';
import { AddIcon, CloseIcon, PeopleIcon } from '@avocadoui/icons';
import UserTitleChip from '@components/UserTitleChip/UserTitleChip';
import {
  Avatar, Box,
  Button,
  IconButton, List, ListItemAvatar,
  ListItemSecondaryAction, ListItemText, Paper,
  withStyles,
} from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import PaperHeader from './PaperHeader';

const ListItem = withStyles(() => ({
  container: {
    '&:hover .MuiListItemSecondaryAction-root': {
      visibility: 'visible',
    },
    '& .MuiListItemSecondaryAction-root': {
      visibility: 'hidden',
    },
  },
}))(MuiListItem);

const CAN_DELETE_TYPES = [2, 3, 4, 5, 11];

const ManagementCollaborator = ({ token, onBackClick, onCreateClick }) => {
  const [collaborator, setCollaborator] = useState([]);

  const reloadData = useCallback(() => {
    Api.applicationCollaborator(token).then((v) => {
      if (v.data.code === 0) {
        setCollaborator(v.data.data.list);
      }
    });
  }, [token]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  const handleCollaboratorDelete = useCallback(
    (roleType, mobile) => {
      Api.deleteCollaborator(token, { roleType, mobile })
        .then((v) => {
          if (v.data.code === 0) {
            reloadData();
          }
        })
        .catch(console.log);
    },
    [token, reloadData],
  );

  return (
    <Paper style={{ width: 410 }}>
      <PaperHeader title="管理协作者" onBackClick={onBackClick} />
      <Box style={{ maxHeight: 400, overflowY: 'scroll' }}>
        <List>
          {collaborator.map((v) => (
            <ListItem button>
              <ListItemAvatar>
                {v.type === 2 ? (
                  <Avatar>
                    <PeopleIcon />
                  </Avatar>
                ) : (
                  <Avatar src={v.avatar} />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={(
                  <Box display="flex" alignItems="center">
                    {v.name}
                    <Box mr={1} />
                    <UserTitleChip role={v.role} />
                  </Box>
                )}
                secondary={v.mobile}
              />
              {CAN_DELETE_TYPES.indexOf(v.role) !== -1 && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleCollaboratorDelete(v.role, v.mobile)}>
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
      <Box height={56} display="flex" justifyContent="space-between" alignItems="center" pl={2} pr={2}>
        <Button onClick={onCreateClick} color="primary" startIcon={<AddIcon />}>
          添加协作者
        </Button>
        <Box display="flex">
          {/* <Button>取消</Button>
          <Box mr={1} /> */}
          <Button variant="contained" color="primary" onClick={onBackClick}>
            完成
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

ManagementCollaborator.propTypes = {
  token: PropTypes.string.isRequired,
  onBackClick: PropTypes.func,
  onCreateClick: PropTypes.func,
};

ManagementCollaborator.defaultProps = {
  onBackClick: null,
  onCreateClick: null,
};

export default ManagementCollaborator;
