import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidatesWithApplication } from '../../../api/Candidate';
import { setIsSearching, setSearchStat } from '../../../store/modules/search/action';
import { addCandidates, clearSelectedCandidate, updateCandidatesFilter } from '../../../v1/actions/candidates';

function useEffectSearchFetch() {
  const dispatch = useDispatch();
  const chipList = useSelector(({ search }) => search.chipList);
  const isAdvancedMode = useSelector(({ search }) => search.isAdvancedMode);
  const filter = useSelector(({ candidates }) => candidates.filter);
  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    if (JSON.stringify(chipList) !== JSON.stringify(filter.search || [])) {
      const newFilter = hasSearchedOnce.current ? { ...filter, search: chipList } : { search: chipList };
      if (hasSearchedOnce.current) dispatch(updateCandidatesFilter(newFilter));

      setTimeout(() => {
        dispatch(setIsSearching(true));
        fetchCandidatesWithApplication(newFilter).then((result) => {
          if (result.data.code === 0) {
            const {
              total, list, searchStat, mobileSearchStat, nameSearchStat,
            } = result.data.data;
            dispatch(addCandidates(list, total));
            dispatch(setSearchStat({ searchStat, mobileSearchStat, nameSearchStat }));
            hasSearchedOnce.current = true;
          }
          dispatch(setIsSearching(false));
        });
      }, 0);

      dispatch(clearSelectedCandidate());
    }
  }, [chipList, filter]);

  useEffect(() => {
    if (!isAdvancedMode) {
      hasSearchedOnce.current = false;
    }
  }, [isAdvancedMode]);
}

export default useEffectSearchFetch;
