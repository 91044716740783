import axios from './Api';

export const getCollage = () => axios.request({
  url: 'https://alicdn.fbmms.cn/dicts/college.json',
  method: 'GET',
});

export const getSchool = (data) => axios.request({
  url: '/form/school',
  method: 'POST',
  data,
});

export default {};
