import { searchCollaborator } from '@api/ApplicationShare';
import Avatar from '@components/Avocado/Avatar';
import UserTitleChip from '@components/UserTitleChip/UserTitleChip';
import {
  Box,
  Chip,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import {
  useState, useMemo, forwardRef, useCallback, useEffect,
} from 'react';
import ListboxComponent from './ListboxComponent';
import ListItem from './ListItem';
import useStyles from './MemberAutoComplate.style';

const MemberAutoComplate = ({
  token, onChange, value, onCreateClick,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  // const loading = open && options.length === 0;

  const fetch = useMemo(
    () => throttle((searchText, callback) => {
      searchCollaborator(token, { searchText }).then((v) => {
        if (v.data.code === 0) {
          callback(v.data?.data?.list);
        }
      });
    }, 200),
    [],
  );

  const handleInputChange = useCallback((e) => {
    const val = e.target.value;
    setInputValue(val);
  }, []);

  useEffect(() => {
    let active = true;
    fetch(inputValue, (data) => {
      if (active) {
        setOptions(data);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    if (!open) {
      setInputValue('');
    }
  }, [open]);

  return (
    <Autocomplete
      filterOptions={(x) => x}
      classes={{ option: classes.option }}
      noOptionsText="无选项"
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      ListboxComponent={forwardRef((props, ref) => (
        <ListboxComponent {...props} onCreateClick={onCreateClick} ref={ref} />
      ))}
      value={value}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <Chip icon={<Box width={24} height={24} display="flex" alignItems="center" justifyContent="center"><Avatar user={option} size="small" /></Box>} label={option.name} {...getTagProps({ index })} variant="outlined" color="primary" />
      ))}
      onChange={onChange}
      onInputChange={handleInputChange}
      getOptionSelected={(option, val) => option.name === val.name}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <ListItem>
          <ListItemAvatar>
            <Avatar user={option} />
          </ListItemAvatar>
          <ListItemText
            primary={(
              <Box display="flex" alignItems="center">
                {option.name} <Box mr={1} />
                <UserTitleChip role={option.role} />
              </Box>
            )}
            secondary={option.mobile}
          />
          <ListItemSecondaryAction>{option.hasAdd && '已邀请'}</ListItemSecondaryAction>
        </ListItem>
      )}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          placeholder="可输入用户名、手机号搜索"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
MemberAutoComplate.propTypes = {
  token: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onCreateClick: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.object),
};

MemberAutoComplate.defaultProps = {
  onChange: PropTypes.func,
  onCreateClick: null,
  value: [],
};

export default MemberAutoComplate;
