/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import moment from '@utils/moment';
import produce from 'immer';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { nanoid } from 'nanoid';
import { MAX_ALLOWED_DURATION } from './const';
import defaultState, { cacheState } from './defaultState';

function sortPeriods(periods) {
  return [...periods].sort((a, b) => a.startTime - b.startTime);
}

function mergePeriods(periods) {
  const result = [];
  periods.forEach((period) => {
    const { length } = result;
    if (result.length !== 0 && result[length - 1].endTime === period.startTime) {
      result[length - 1] = { ...result[length - 1], endTime: period.endTime };
    } else {
      result.push({ ...period, startTime: period.startTime, endTime: period.endTime });
    }
  });
  return result;
}

function unionPeriods(oldPeriods, newPeriod) {
  const result = [];
  const newPeriods = sortPeriods([...oldPeriods, newPeriod]);
  newPeriods.forEach((period) => {
    const { length } = result;
    const isStartTimeOverflow = result.length !== 0
      && result[length - 1].startTime < period.startTime
      && result[length - 1].endTime > period.startTime;
    if (isStartTimeOverflow) {
      const minStartTime = Math.min(
        period.startTime,
        period.endTime,
        result[length - 1].startTime,
        result[length - 1].endTime,
      );
      const maxEndTime = Math.max(
        period.startTime,
        period.endTime,
        result[length - 1].startTime,
        result[length - 1].endTime,
      );
      result[length - 1] = { ...result[length - 1], startTime: minStartTime, endTime: maxEndTime };
    } else {
      result.push({ ...period, startTime: period.startTime, endTime: period.endTime });
    }
  });
  return result;
}

function checkPeriods(periods, duration) {
  return periods.some((period) => period.startTime + duration > period.endTime);
}

function slicePeriods(periods, duration) {
  const result = [];
  const startTimeArr = [];
  periods.forEach((period) => {
    const { startTime, endTime } = period;
    const moreThanDuration = endTime - startTime >= duration;
    if (moreThanDuration) {
      let startTimeTmp = startTime;
      while (startTimeTmp + duration <= endTime) {
        startTimeArr.push({ ...period, startTime: startTimeTmp });
        startTimeTmp += duration;
      }
    }
  });
  startTimeArr.forEach((period) => {
    const event = {
      ...period,
      startTime: period.startTime,
      endTime: period.startTime + duration,
    };
    result.push(event);
  });
  return result;
}

function formatPeriods(periods, state) {
  return periods.map((period) => ({
    ...period,
    id: period.id || nanoid(),
    numLimit: state.newArrangementData.numLimit,
    isClient: true,
  }));
}

function filterNoTimePeriods(periods) {
  return periods.filter(({ startTime, endTime }) => startTime && endTime);
}

const reducer = (state, action) => produce(state, (draft) => {
  switch (action.type) {
    case 'RESET_TO_DEFAULT_STATE': {
      return { ...defaultState, count: state.count + 1 };
    }
    case 'REFRESH_DATA': {
      return { ...state, count: state.count + 1 };
    }
    case 'CLEAN_CACHE_AND_REFRESH_DATA': {
      return { ...state, ...cacheState, count: state.count + 1 };
    }
    case 'SET_STATE': {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    case 'SET_NEW_ARRANGEMENT_DATA': {
      const { key, value } = action.payload;
      const finalValue = value;
      // if (value === '') {
      //   finalValue = value;
      // } else if (
      //   Object.prototype.toString.call(value) === '[object Array]' ||
      //   Object.prototype.toString.call(value) === '[object Object]'
      // ) {
      //   finalValue = value;
      // } else if (typeof Number(value) === 'number' && !Number.isNaN(Number(value))) {
      //   finalValue = Number(value);
      // } else {
      //   finalValue = value;
      // }

      draft.newArrangementData[key] = finalValue;

      const periods = isEmpty(state.newArrangementData.periods)
        ? [{ id: nanoid() }]
        : state.newArrangementData.periods;

      if (key === 'positionToken') {
        if (state.newArrangementData.processToken) {
          draft.newArrangementData.processToken = '';
        }
        if (!isEmpty(state.newArrangementData.selectedApplication)) {
          draft.newArrangementData.selectedApplication = [];
        }
        if (!isEmpty(state.newArrangementData.selectedParticipant)) {
          draft.newArrangementData.selectedParticipant = [];
        }
      }

      if (key === 'processToken') {
        if (!isEmpty(state.newArrangementData.selectedApplication)) {
          draft.newArrangementData.selectedApplication = [];
        }
      }

      if (key === 'interviewMode') {
        // 从招聘流程列表安排邀约，更新缓存中的邀约模式
        draft.newArrangementData.interviewMode = finalValue;
      }

      if (key === 'numLimit') {
        if (typeof finalValue === 'number' && finalValue <= 0) break; // 只允许正整数
        if (finalValue !== 1) draft.newArrangementData.autoSwitch = 1;
        if (isEmpty(state.newArrangementData.periods)) break;
        if (finalValue !== 1 && state.newArrangementData.duration) {
          const sortedPeriods = sortPeriods(state.newArrangementData.periods);
          const mergedPeriods = mergePeriods(sortedPeriods);
          const isCheckFailed = checkPeriods(mergedPeriods, state.newArrangementData.duration);
          if (isCheckFailed) draft.newArrangementData.localCheckedCode = 1;
          const slicedPeriods = slicePeriods(mergedPeriods, state.newArrangementData.duration);
          const formatedPeriods = formatPeriods(slicedPeriods, state);
          draft.newArrangementData.periods = formatedPeriods.map((v) => ({
            ...v,
            numLimit: finalValue,
          }));
        } else {
          draft.newArrangementData.periods = periods.map((v) => ({
            ...v,
            numLimit: finalValue,
          }));
        }
      }

      if (key === 'duration') {
        const isEditAuto = !isEmpty(state.allArrangementData);
        if (state.newArrangementData.interviewMode === 1 && !isEditAuto) {
          if (state.newArrangementData.autoSwitch === 1) {
            if (!isEmpty(state.newArrangementData.periods)) {
              const sortedPeriods = sortPeriods(state.newArrangementData.periods);
              const mergedPeriods = mergePeriods(sortedPeriods);
              const isCheckFailed = checkPeriods(mergedPeriods, finalValue);
              if (isCheckFailed) draft.newArrangementData.localCheckedCode = 1;
              const slicedPeriods = slicePeriods(mergedPeriods, finalValue);
              const formatedPeriods = formatPeriods(slicedPeriods, state);
              draft.newArrangementData.periods = formatedPeriods;
            }
          }
        }
        if (
          state.newArrangementData.interviewMode === 2
            || (isEditAuto && state.newArrangementData.autoSwitch === 1)
        ) {
          if (state.newArrangementData.startTime) {
            const startTime = state.newArrangementData.startTime / 1000;
            draft.newArrangementData.periods = periods.map((v) => ({
              ...v,
              startTime,
              endTime: startTime + finalValue,
            }));
          }
        }
      }

      if (key === 'type' && value === 2 && state.newArrangementData.interviewMode === 2) {
        draft.newArrangementData.subType = 1;
      }

      if (key === 'addressUrl' || key === 'name') {
        draft.newArrangementData[key] = `${value}`;
      }

      if (key === 'addressObj') {
        draft.newArrangementData.addressObj = value;
        if (value.location) {
          draft.newArrangementData.latitude = value.location.lat;
          draft.newArrangementData.longitude = value.location.lng;
        }
      }

      if (key === 'startTime') {
        const startTime = +moment(value).seconds(0).milliseconds(0).format('x');
        draft.newArrangementData.startTime = startTime;
        if (state.newArrangementData.duration && state.newArrangementData.autoSwitch === 1) {
          if (isEmpty(state.newArrangementData.periods)) {
            draft.newArrangementData.periods = [
              {
                id: nanoid(),
              },
            ];
          }
          draft.newArrangementData.periods[0].startTime = startTime / 1000;
          draft.newArrangementData.periods[0].endTime = startTime / 1000 + state.newArrangementData.duration;
        }
      }

      // 无需修改 periods 下的 remark
      // 提交时再把 periods 中的空缺 remark 补充上
      // if (key === 'remark') {
      //   draft.newArrangementData.periods = periods;
      //   draft.newArrangementData.periods[0].remark = `${value}`;
      // }

      break;
    }
    case 'SET_NEW_ARRANGEMENT_DATA_UNIQ': {
      const { key, value } = action.payload;
      const tokens = value.map((p) => p.token);
      const diffTokens = _.filter(tokens, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
      const finalTokens = tokens.filter((p) => diffTokens.indexOf(p) === -1);
      const finalValues = value.filter((p) => finalTokens.indexOf(p.token) > -1);
      draft.newArrangementData[key] = finalValues;
      break;
    }
    case 'SET_ARRANGEMENT_PERIOD_DATA': {
      const { index, value } = action.payload;
      if (draft.newArrangementData.periods == null) draft.newArrangementData.periods = [];
      draft.newArrangementData.periods[index] = {
        ...draft.newArrangementData.periods[index],
        startTime: value,
        endTime: value + state.newArrangementData.duration,
        numLimit: state.newArrangementData.numLimit,
      };
      break;
    }
    case 'ADD_ARRANGEMENT_PERIODS_DATA': {
      // const { startTime, endTime } = action.payload;
      // 重置ID
      // draft.newArrangementData.periodIds = [];
      if (draft.newArrangementData.periods == null) draft.newArrangementData.periods = [];
      draft.newArrangementData.backupPeriods = state.newArrangementData.periods || [];
      draft.newArrangementData.popoverItems = [action.payload];
      // 自动切割情况
      if (state.newArrangementData.duration != null && state.newArrangementData.autoSwitch === 1) {
        const sortedPeriods = sortPeriods(state.newArrangementData.periods);
        const mergedPeriods = mergePeriods(sortedPeriods);
        const unionedPeriods = unionPeriods(mergedPeriods, action.payload);
        const isCheckFailed = checkPeriods(unionedPeriods, state.newArrangementData.duration);
        if (isCheckFailed) draft.newArrangementData.localCheckedCode = 1;
        const slicedPeriods = slicePeriods(unionedPeriods, state.newArrangementData.duration);
        const formatedPeriods = formatPeriods(slicedPeriods, state);
        const filteredNoTimePeriods = filterNoTimePeriods(formatedPeriods);
        draft.newArrangementData.periods = filteredNoTimePeriods;
        break;

        // const { duration } = state.newArrangementData;
        // const moreThanDuration = endTime - startTime >= duration;
        // if (moreThanDuration && state.newArrangementData.interviewMode) {
        //   let startTimeTmp = startTime;
        //   const startTimeArr = [];
        //   const periodIds = [];
        //   const events = [];
        //   while (startTimeTmp + duration <= endTime) {
        //     startTimeArr.push(startTimeTmp);
        //     startTimeTmp += duration;
        //   }
        //   startTimeArr.forEach((start) => {
        //     const id = nanoid();
        //     periodIds.push(id);
        //     const event = {
        //       id,
        //       startTime: start,
        //       endTime: start + duration,
        //       numLimit: state.newArrangementData.numLimit,
        //       isClient: true,
        //     };
        //     draft.newArrangementData.periods.push(event);
        //     events.push(event);
        //   });
        //   draft.newArrangementData.periodIds = periodIds;
        //   draft.newArrangementData.popoverItems = events;
        //   break;
        // }
      }
      // 正常情况
      const sortedPeriods = sortPeriods(state.newArrangementData.periods);
      const mergedPeriods = mergePeriods(sortedPeriods);
      const unionedPeriods = unionPeriods(mergedPeriods, action.payload);
      const formatedPeriods = formatPeriods(unionedPeriods, state);
      const filteredNoTimePeriods = filterNoTimePeriods(formatedPeriods);
      draft.newArrangementData.periods = filteredNoTimePeriods;
      break;

      // const id = nanoid();
      // draft.newArrangementData.periodIds = [id];
      // let event = {
      //   id,
      //   startTime,
      //   endTime,
      //   numLimit: state.newArrangementData.numLimit,
      //   isClient: true,
      // };
      // if (state.newArrangementData.duration != null && state.newArrangementData.autoSwitch === 1) {
      //   event = {
      //     ...event,
      //     endTime: startTime + state.newArrangementData.duration,
      //     isClient: true,
      //   };
      // }
      // draft.newArrangementData.periods.push(event);
      // draft.newArrangementData.popoverItems = [event];
      // break;
    }
    case 'UPDATE_ARRANGEMENT_PERIODS_DATA_WITH_ID': {
      const { id, startTime, endTime } = action.payload;
      const newPeriod = { startTime, endTime };
      if (moment(startTime * 1000).get('date') !== moment(endTime * 1000).get('date')) break;
      if (state.newArrangementData.periods == null) state.newArrangementData.periods = [];
      const index = state.newArrangementData.periods.findIndex((v) => v.id === id);
      if (index !== -1) {
        const removedPeriods = [...state.newArrangementData.periods];
        removedPeriods.splice(index, 1);
        if (state.newArrangementData.duration != null && state.newArrangementData.autoSwitch === 1) {
          const sortedPeriods = sortPeriods(removedPeriods);
          const mergedPeriods = mergePeriods(sortedPeriods);
          const unionedPeriods = unionPeriods(mergedPeriods, newPeriod);
          const isCheckFailed = checkPeriods(unionedPeriods, state.newArrangementData.duration);
          if (isCheckFailed) draft.newArrangementData.localCheckedCode = 1;
          const slicedPeriods = slicePeriods(unionedPeriods, state.newArrangementData.duration);
          const formatedPeriods = formatPeriods(slicedPeriods, state);
          draft.newArrangementData.periods = formatedPeriods;
          break;
        }
        // 正常情况
        const sortedPeriods = sortPeriods(removedPeriods);
        const mergedPeriods = mergePeriods(sortedPeriods);
        const unionedPeriods = unionPeriods(mergedPeriods, newPeriod);
        const formatedPeriods = formatPeriods(unionedPeriods, state);
        draft.newArrangementData.periods = formatedPeriods;
        break;
        // draft.newArrangementData.periods[index] = {
        //   ...draft.newArrangementData.periods[index],
        //   startTime,
        //   endTime,
        // };
      }
      break;
    }
    case 'DELETE_ARRANGEMENT_PERIODS_DATA_WITH_IDS': {
      if (draft.newArrangementData.periods == null) draft.newArrangementData.periods = [];
      const ids = action.payload;
      ids.forEach((id) => {
        const index = draft.newArrangementData.periods.findIndex((v) => v.id === id);
        if (index !== -1) draft.newArrangementData.periods.splice(index, 1);
      });
      break;
    }
    case 'SET_ARRANGEMENT_PERIOD_IDS_DATA': {
      const ids = action.payload;
      draft.newArrangementData.periodIds = ids;
      break;
    }
    case 'SET_ARRANGEMENT_POPOVER_DATA': {
      const id = action.payload;
      const data = state.newArrangementData.periods.find((p) => p.id === id);
      if (!isEmpty(data)) {
        draft.newArrangementData.popoverItems = [data];
      }
      break;
    }
    case 'SET_ARRANGEMENT_REMARK_DATA': {
      const remark = action.payload;
      if (draft.newArrangementData.periods == null) draft.newArrangementData.periods = [];
      state.newArrangementData.periodIds.forEach((id) => {
        const index = state.newArrangementData.periods.findIndex((p) => p.id === id);
        if (index > -1) {
          draft.newArrangementData.periods[index].remark = remark;
        }
      });
      draft.newArrangementData.periodIds = [];
      break;
    }
    case 'REMOVE_ARRANGEMENT_PERIOD_DATA': {
      const index = action.payload;
      if (draft.newArrangementData.periods == null) draft.newArrangementData.periods = [];
      draft.newArrangementData.periods.splice(index, 1);
      break;
    }
    case 'SET_ARRANGEMENT_MSG_SELECTED_DATA': {
      const value = action.payload;
      const isArray = Object.prototype.toString.call(value) === '[object Array]';
      if (isArray) {
        draft.newArrangementData.msgSelected = value;
        draft.msgSelected = value;
      } else {
        if (state.newArrangementData.msgSelected == null) state.newArrangementData.msgSelected = [];
        if (draft.newArrangementData.msgSelected == null) draft.newArrangementData.msgSelected = [];
        const index = state.newArrangementData.msgSelected.indexOf(value);
        if (index > -1) {
          draft.newArrangementData.msgSelected.splice(index, 1);
        } else {
          draft.newArrangementData.msgSelected.push(value);
        }
      }
      break;
    }
    // 用于给候选人发送提醒
    case 'SET_INITIAL_SELECTED_CANDIDATES': {
      draft.selectedCandidates = action.payload;
      break;
    }
    case 'SET_SELECTED_CANDIDATES': {
      const applicationToken = action.payload;
      const idx = state.selectedCandidates.map((v) => v.applicationToken).indexOf(applicationToken);
      if (idx > -1) {
        draft.selectedCandidates.splice(idx, 1);
      } else {
        draft.selectedCandidates.push(applicationToken);
      }
      break;
    }
    case 'RESET_SELECTED_CANDIDATES': {
      draft.selectedCandidates = [];
      break;
    }
    case 'RESET_POSITION_TOKEN': {
      draft.newArrangementData.positionToken = '';
      draft.newArrangementData.processToken = '';
      break;
    }
    case 'RESET_PROCESS_TOKEN': {
      draft.newArrangementData.processToken = '';
      break;
    }
    case 'OPEN_EVENT_POPPER': {
      const { selectedEventId, selectedAnchorEl } = action.payload;
      draft.selectedEventId = selectedEventId;
      draft.selectedAnchorEl = selectedAnchorEl;
      draft.isEventPopperOpen = true;
      break;
    }
    case 'CLOSE_EVENT_POPPER': {
      draft.selectedEventId = null;
      draft.selectedAnchorEl = null;
      draft.isEventPopperOpen = false;
      break;
    }
    case 'SET_TAB': {
      switch (action.payload) {
        case 'CALENDAR': {
          draft.tabIndex = 0;
          break;
        }
        case 'LIST': {
          draft.tabIndex = 1;
          break;
        }
        default: {
          draft.tabIndex = 0;
          break;
        }
      }
      break;
    }
    case 'TOGGLE_AUTO_INTERVIEW_SWITCH': {
      if (state.newArrangementData.interviewMode === 2) {
        draft.newArrangementData.interviewMode = 1;
      } else {
        draft.newArrangementData.interviewMode = 2;
      }
      draft.newArrangementData.periods = [];
      draft.newArrangementData.selectedApplication = [];
      break;
    }
    case 'SET_NEW_ARRANGEMENT_DATA_START_TIME': {
      const oldDate = new Date(state.newArrangementData.startTime);
      const hours = oldDate.getHours();
      const minutes = oldDate.getMinutes();
      const startTime = +moment(action.payload).hours(hours).minutes(minutes).seconds(0)
        .milliseconds(0)
        .format('x');
      draft.newArrangementData.startTime = startTime;
      if (!state.newArrangementData.duration) break;

      if (isEmpty(state.newArrangementData.periods)) {
        const id = state.newArrangementData.periodToken || nanoid();
        draft.newArrangementData.periods = [
          {
            id,
          },
        ];
      }
      draft.newArrangementData.periods[0].startTime = startTime / 1000;
      draft.newArrangementData.periods[0].endTime = startTime / 1000 + state.newArrangementData.duration;
      break;
    }
    case 'SET_NEW_ARRANGEMENT_DATA_FROM_CALENDAR': {
      const data = action.payload;
      if (isEmpty(state.newArrangementData.periods)) {
        draft.newArrangementData.periods = [
          {
            id: nanoid(),
          },
        ];
      }
      if (data.interviewToken) {
        draft.newArrangementData.interviewToken = data.interviewToken;
      }
      if (data.periodToken) {
        draft.newArrangementData.periods[0].id = data.periodToken;
        draft.newArrangementData.periods[0].isClient = true;
        draft.newArrangementData.periodToken = data.periodToken;
      }
      if (data.interviewMode) {
        draft.newArrangementData.interviewMode = data.interviewMode;
      }
      if (data.name) {
        draft.newArrangementData.name = data.name;
      }
      if (data.position.token) {
        draft.newArrangementData.positionToken = data.position.token;
      }
      if (data.process.token) {
        draft.newArrangementData.processToken = data.process.token;
      }
      if (data.candidates?.list && data.candidates?.total > 0) {
        const selectedApplication = data.candidates.list.map((c) => ({
          token: c.applicationToken,
          nameWithMobile: `${c.candidate.name} ${c.candidate.mobile}`,
          name: c.candidate.name,
          mobile: c.candidate.mobile,
          avatar: c.candidate.avatar,
        }));
        draft.newArrangementData.selectedApplication = selectedApplication;
        // 编辑自动邀约时，限定只能在已有的候选人列表中做更改
        if (data.interviewMode === 1) {
          draft.allApplicationData = selectedApplication;
        }
      }
      if (data.startTime) {
        draft.newArrangementData.startTime = data.startTime * 1000;
        draft.newArrangementData.periods[0].startTime = data.startTime;
      }
      if (data.endTime) {
        draft.newArrangementData.periods[0].endTime = data.endTime;
      }
      if (data.deadline) {
        draft.newArrangementData.deadline = data.startTime - data.deadline;
      }
      if (data.duration) {
        draft.newArrangementData.duration = data.duration;
      }
      if (data.type) {
        draft.newArrangementData.type = data.type;
      }
      if (data.subType) {
        draft.newArrangementData.subType = data.subType;
      }
      if (data.address) {
        if (data.type === 2) {
          // 视频面试
          draft.newArrangementData.addressUrl = data.address;
        } else {
          // 线下面试、培训
          if (isEmpty(draft.newArrangementData.addressObj)) draft.newArrangementData.addressObj = {};
          draft.newArrangementData.addressObj.title = data.address;
        }
      }
      if (data.latitude && data.longitude) {
        draft.newArrangementData.latitude = data.latitude;
        draft.newArrangementData.longitude = data.longitude;
        if (isEmpty(draft.newArrangementData.addressObj)) draft.newArrangementData.addressObj = {};
        if (isEmpty(draft.newArrangementData.addressObj.location)) draft.newArrangementData.addressObj.location = {};
        draft.newArrangementData.addressObj.location.lat = data.latitude;
        draft.newArrangementData.addressObj.location.lng = data.longitude;
      }
      if (data.interviewers?.list && data.interviewers?.total > 0) {
        draft.newArrangementData.selectedParticipant = data.interviewers.list.map((c) => ({
          token: c.token,
          name: c.name,
          avatar: c.avatar,
        }));
      }
      if (data.numLimit) {
        draft.newArrangementData.numLimit = data.numLimit;
        draft.newArrangementData.periods[0].numLimit = data.numLimit;
      }
      if (data.autoSwitch) {
        draft.newArrangementData.autoSwitch = data.autoSwitch;
      }
      if (data.remark) {
        draft.newArrangementData.remark = data.remark;
        draft.newArrangementData.periods[0].remark = data.remark;
      }
      break;
    }
    // 切换自动规划可约时段
    case 'SET_AUTO_SPLIT_PERIODS': {
      draft.newArrangementData.autoSwitch = action.payload;
      if (action.payload === 2) {
        draft.newArrangementData.numLimit = 1;
      }

      if (!isEmpty(state.newArrangementData.periods)) {
        if (action.payload && state.newArrangementData.duration) {
          const sortedPeriods = sortPeriods(state.newArrangementData.periods);
          const mergedPeriods = mergePeriods(sortedPeriods);
          const isCheckFailed = checkPeriods(mergedPeriods, state.newArrangementData.duration);
          if (isCheckFailed) draft.newArrangementData.localCheckedCode = 1;
          const slicedPeriods = slicePeriods(mergedPeriods, state.newArrangementData.duration);
          const formatedPeriods = formatPeriods(slicedPeriods, state);
          draft.newArrangementData.periods = formatedPeriods;
        }
        if (!action.payload) {
          const sortedPeriods = sortPeriods(state.newArrangementData.periods);
          const mergedPeriods = mergePeriods(sortedPeriods);
          const formatedPeriods = formatPeriods(mergedPeriods, state);
          draft.newArrangementData.periods = formatedPeriods;
        }
      }
      break;
    }
    case 'SET_RANGE_OF_EVENTS': {
      // console.log('SET_RANGE_OF_EVENTS:', action.payload);
      const [startTime, endTime] = action.payload;
      draft.startTime = startTime;
      draft.endTime = endTime;
      break;
    }
    case 'SET_RANGE_OF_EVENTS_FOR_NEW_ARRANGEMENT': {
      // console.log('SET_RANGE_OF_EVENTS_FOR_NEW_ARRANGEMENT:', action.payload);
      draft.newArrangementData.range = action.payload;
      break;
    }
    case 'SET_ADDRESS_FOR_NEW_ARRANGEMENT': {
      if (state.newArrangementData.interviewToken) break;
      // if (!isEmpty(action.payload) && isEmpty(state.newArrangementData.addressObj)) {
      if (!isEmpty(action.payload)) {
        const { location, longitude, latitude } = action.payload;
        const {
          province, city, extra, detail,
        } = location;
        const address = `${province}${city}${detail}${extra}`;
        if (isEmpty(draft.newArrangementData.addressObj)) draft.newArrangementData.addressObj = {};
        draft.newArrangementData.addressObj.title = address;
        draft.newArrangementData.latitude = latitude;
        draft.newArrangementData.longitude = longitude;
        if (isEmpty(draft.newArrangementData.addressObj.location)) draft.newArrangementData.addressObj.location = {};
        draft.newArrangementData.addressObj.location.lat = latitude;
        draft.newArrangementData.addressObj.location.lng = longitude;
      } else {
        delete draft.newArrangementData.addressObj;
        delete draft.newArrangementData.latitude;
        delete draft.newArrangementData.longitude;
      }
      break;
    }
    case 'SET_PERIOD_FOR_MANUAL': {
      const { startTime, endTime } = action.payload;
      draft.newArrangementData.startTime = startTime * 1000;
      let duration = endTime - startTime;
      if (duration > MAX_ALLOWED_DURATION) duration = MAX_ALLOWED_DURATION;
      draft.newArrangementData.duration = duration;
      if (isEmpty(draft.newArrangementData.periods)) draft.newArrangementData.periods = [{}];
      const oldId = state.newArrangementData?.periods && state.newArrangementData?.periods[0]?.id;
      draft.newArrangementData.periods[0].id = oldId || nanoid();
      draft.newArrangementData.periods[0].startTime = startTime;
      draft.newArrangementData.periods[0].endTime = startTime + duration;
      draft.newArrangementData.periods[0].isClient = true;
      break;
    }
    case 'SET_PERIOD_FOR_EDIT_AUTO': {
      const { startTime, endTime } = action.payload;
      draft.newArrangementData.startTime = startTime * 1000;
      const oldId = state.newArrangementData?.periods && state.newArrangementData?.periods[0]?.id;
      if (isEmpty(draft.newArrangementData.periods)) draft.newArrangementData.periods = [{}];
      draft.newArrangementData.periods[0].id = oldId || nanoid();
      draft.newArrangementData.periods[0].isClient = true;
      if (state.rawArrangementData.autoSwitch === 1) {
        let duration = endTime - startTime;
        if (duration > MAX_ALLOWED_DURATION) duration = MAX_ALLOWED_DURATION;
        draft.newArrangementData.duration = duration;
        draft.newArrangementData.periods[0].startTime = startTime;
        draft.newArrangementData.periods[0].endTime = startTime + duration;
      } else {
        draft.newArrangementData.endTime = endTime * 1000;
        draft.newArrangementData.periods[0].id = oldId || nanoid();
        draft.newArrangementData.periods[0].startTime = startTime;
        draft.newArrangementData.periods[0].endTime = endTime;
      }
      break;
    }
    case 'CLEAN_RANGE': {
      draft.newArrangementData.range = [];
      break;
    }
    case 'RESET_DURATION': {
      delete draft.newArrangementData.duration;
      draft.newArrangementData.periods.forEach((p) => {
        if (p.endTime) delete p.endTime;
      });
      if (state.newArrangementData.interviewMode === 2) {
        if (state.newArrangementData.periods && state.newArrangementData.periods[0]?.id) {
          draft.newArrangementData.periods = [{ id: state.newArrangementData.periods[0].id }];
        } else {
          draft.newArrangementData.periods = [];
        }
      }
      break;
    }
    case 'RESET_LOCAL_CHECKED_CODE': {
      draft.newArrangementData.localCheckedCode = 0;
      break;
    }
    case 'RECOVER_TO_BACKUP_PERIODS': {
      draft.newArrangementData.periods = state.newArrangementData.backupPeriods;
      draft.newArrangementData.backupPeriods = [];
      break;
    }
    case 'SET_NEW_ARRANGEMENT_DATA_POSITION_PROCESS': {
      const { positionToken, processToken } = action.payload;
      if (positionToken) {
        draft.newArrangementData.positionToken = positionToken;
      } else {
        delete draft.newArrangementData.positionToken;
      }
      if (processToken) {
        draft.newArrangementData.processToken = processToken;
      } else {
        delete draft.newArrangementData.processToken;
      }
      if (!isEmpty(state.newArrangementData.selectedApplication)) {
        draft.newArrangementData.selectedApplication = [];
      }
      if (!isEmpty(state.newArrangementData.selectedParticipant)) {
        draft.newArrangementData.selectedParticipant = [];
      }
      break;
    }
    case 'SET_ALL_ARRANGEMENT_DATA_FROM_CALENDAR': {
      const { list = [], customInterview } = action.payload;
      const rawCustomInterview = { ...customInterview };
      if (rawCustomInterview.deadlineDuration) {
        rawCustomInterview.deadline = rawCustomInterview.deadlineDuration;
        delete rawCustomInterview.deadlineDuration;
      }
      draft.rawArrangementData = rawCustomInterview;
      draft.allArrangementData = list.map((data) => {
        const result = {};
        result.periods = [
          {
            id: nanoid(),
          },
        ];
        if (data.interviewToken) {
          result.interviewToken = data.interviewToken;
        }
        if (data.periodToken) {
          result.periods[0].id = data.periodToken;
          result.periods[0].isClient = true;
          result.periodToken = data.periodToken;
        }
        if (data.interviewMode) {
          result.interviewMode = data.interviewMode;
        }
        if (data.name) {
          result.name = data.name;
        }
        if (data.position.token) {
          result.positionToken = data.position.token;
        }
        if (data.process.token) {
          result.processToken = data.process.token;
        }
        if (data.candidates?.list && data.candidates?.total > 0) {
          result.selectedApplication = data.candidates.list.map((c) => ({
            token: c.applicationToken,
            nameWithMobile: `${c.candidate.name} ${c.candidate.mobile}`,
            name: c.candidate.name,
            mobile: c.candidate.mobile,
            avatar: c.candidate.avatar,
          }));
        }
        if (data.startTime) {
          result.startTime = data.startTime * 1000;
          result.periods[0].startTime = data.startTime;
        }
        if (data.endTime) {
          result.endTime = data.endTime * 1000;
          result.periods[0].endTime = data.endTime;
        }
        if (data.deadline) {
          result.deadline = data.startTime - data.deadline;
        }
        if (data.duration) {
          result.duration = data.duration;
        }
        if (data.type) {
          result.type = data.type;
        }
        if (data.address) {
          if (data.type === 2) {
            // 视频面试
            result.addressUrl = data.address;
          } else {
            // 线下面试、培训
            if (isEmpty(result.addressObj)) result.addressObj = {};
            result.addressObj.title = data.address;
          }
        }
        if (data.latitude && data.longitude) {
          result.latitude = data.latitude;
          result.longitude = data.longitude;
          if (isEmpty(result.addressObj)) result.addressObj = {};
          if (isEmpty(result.addressObj.location)) result.addressObj.location = {};
          result.addressObj.location.lat = data.latitude;
          result.addressObj.location.lng = data.longitude;
        }
        if (data.interviewers?.list && data.interviewers?.total > 0) {
          result.selectedParticipant = data.interviewers.list.map((c) => ({
            token: c.token,
            name: c.name,
            avatar: c.avatar,
          }));
        }
        if (data.numLimit) {
          result.numLimit = data.numLimit;
          result.periods[0].numLimit = data.numLimit;
        }
        if (data.autoSwitch) {
          result.autoSwitch = data.autoSwitch;
        }
        if (data.remark) {
          result.remark = data.remark;
          result.periods[0].remark = data.remark;
        }
        return result;
      });
      break;
    }
    case 'SET_ONE_OF_ALL_ARRANGEMENT_TO_NEW_ARRANGEMENT': {
      const { periodToken } = action.payload;
      const result = state.allArrangementData.find((p) => p.periodToken === periodToken);
      if (!isEmpty(result)) {
        draft.newArrangementData = result;
        draft.allApplicationData = result.selectedApplication;
      }
      break;
    }
    case 'SET_NEW_ARRANGEMENT_TO_ALL_ARRANGEMENT': {
      const { periodToken } = state.newArrangementData;
      const index = state.allArrangementData.findIndex((p) => p.periodToken === periodToken);
      if (index !== -1) {
        draft.allArrangementData[index] = state.newArrangementData;
      }
      draft.newArrangementData = {
        interviewMode: state.newArrangementData.interviewMode,
        range: state.newArrangementData.range,
      };
      break;
    }
    case 'ADD_PERIOD_TO_ALL_AND_NEW_ARRANGEMENT': {
      const data = state.rawArrangementData;
      const latestPeriod = state.allArrangementData[state.allArrangementData.length - 1];
      const idFromPeriods = state.newArrangementData?.periods && state.newArrangementData.periods[0]?.id;
      const periodToken = idFromPeriods || nanoid();
      draft.newArrangementData.periodToken = periodToken;
      draft.newArrangementData.periodId = periodToken;
      if (isEmpty(state.newArrangementData.periods)) {
        draft.newArrangementData.periods = [
          {
            id: periodToken,
            isClient: true,
          },
        ];
      }
      if (data.interviewToken) {
        draft.newArrangementData.interviewToken = data.interviewToken;
      }
      if (data.interviewMode) {
        draft.newArrangementData.interviewMode = data.interviewMode;
      }
      if (data.name) {
        draft.newArrangementData.name = data.name;
      }
      if (data.position.token) {
        draft.newArrangementData.positionToken = data.position.token;
      }
      if (data.process.token) {
        draft.newArrangementData.processToken = data.process.token;
      }
      if (data.deadline) {
        draft.newArrangementData.deadline = data.deadline;
      }
      if (data.duration) {
        if (action.payload.from === 'form') {
          draft.newArrangementData.duration = data.duration;
          const oldDate = new Date();
          const hours = oldDate.getHours();
          const minutes = oldDate.getMinutes();
          const now = +moment(action.payload).hours(hours).minutes(minutes).seconds(0)
            .milliseconds(0)
            .format('x');
          draft.newArrangementData.periods[0].startTime = now / 1000;
          draft.newArrangementData.periods[0].endTime = now / 1000 + data.duration;
        }
        if (action.payload.from === 'calendar') {
          if (state.rawArrangementData.autoSwitch === 1) {
            // 无需处理
          }
          if (state.rawArrangementData.autoSwitch === 2) {
            draft.newArrangementData.duration = data.duration;
          }
        }
      }
      if (data.type) {
        draft.newArrangementData.type = data.type;
      }
      if (data.subType) {
        draft.newArrangementData.subType = data.subType;
      }
      if (data.address) {
        if (data.type === 2) {
          // 视频面试
          draft.newArrangementData.addressUrl = data.address;
        } else {
          // 线下面试、培训
          if (isEmpty(draft.newArrangementData.addressObj)) draft.newArrangementData.addressObj = {};
          draft.newArrangementData.addressObj.title = data.address;
        }
      }
      if (data.latitude && data.longitude) {
        draft.newArrangementData.latitude = data.latitude;
        draft.newArrangementData.longitude = data.longitude;
        if (isEmpty(draft.newArrangementData.addressObj)) draft.newArrangementData.addressObj = {};
        if (isEmpty(draft.newArrangementData.addressObj.location)) draft.newArrangementData.addressObj.location = {};
        draft.newArrangementData.addressObj.location.lat = data.latitude;
        draft.newArrangementData.addressObj.location.lng = data.longitude;
      }
      if (!isEmpty(latestPeriod.selectedParticipant)) {
        draft.newArrangementData.selectedParticipant = latestPeriod.selectedParticipant;
      }
      if (data.numLimit) {
        draft.newArrangementData.numLimit = data.numLimit;
        draft.newArrangementData.periods[0].numLimit = data.numLimit;
      }
      if (data.autoSwitch) {
        draft.newArrangementData.autoSwitch = data.autoSwitch;
      }
      if (latestPeriod.remark) {
        draft.newArrangementData.remark = latestPeriod.remark;
        draft.newArrangementData.periods[0].remark = latestPeriod.remark;
      }
      break;
    }
    case 'ADD_NEW_ARRANGEMENT_TO_ALL_ARRANGEMENT': {
      draft.allArrangementData.push(state.newArrangementData);
      draft.newArrangementData = {
        interviewMode: state.newArrangementData.interviewMode,
      };
      break;
    }
    case 'RESET_NEW_ARRANGEMENT': {
      if (state.newArrangementData.interviewMode) {
        draft.newArrangementData = {
          ...defaultState.newArrangementData,
          interviewMode: state.newArrangementData.interviewMode,
          candidateTemplate: state.newArrangementData.candidateTemplate,
          interviewTemplate: state.newArrangementData.interviewTemplate,
        };
      } else {
        draft.newArrangementData = defaultState.newArrangementData;
      }
      break;
    }
    case 'CANCEL_PERIODS_FROM_ALL_ARRANGEMENT': {
      const periodTokens = action.payload;
      periodTokens.forEach((periodToken) => {
        const index = draft.allArrangementData.findIndex((p) => p.periodToken === periodToken);
        if (index !== -1) draft.allArrangementData[index].status = 2;
      });
      // const canceledRemotePeriodTokens = periodTokens.filter((periodToken) => {
      //   const item = draft.allArrangementData.find((p) => p.periodToken === periodToken) || {};
      //   return !item.periodId;
      // });
      draft.canceledPeriodTokens = _.uniq([...state.canceledPeriodTokens, ...periodTokens]);
      draft.selectedPeriodTokens = [];
      break;
    }
    case 'TOGGLE_SELECTED_PERIOD_TOKENS': {
      const { periodToken, checked } = action.payload;
      if (checked) {
        draft.selectedPeriodTokens.push(periodToken);
      } else {
        const index = state.selectedPeriodTokens.findIndex((t) => t === periodToken);
        draft.selectedPeriodTokens.splice(index, 1);
      }
      break;
    }
    case 'HAS_EDITED_NEW_ARRANGEMENT_DATA': {
      draft.hasEditedNewArrangement = true;
      break;
    }
    case 'SET_FILTER': {
      const { key, value } = action.payload;
      draft.filters[key] = value;
      break;
    }
    case 'SET_NEW_ARRANGEMENT_DATA_FOR_REARRANGING': {
      const data = action.payload;
      draft.newArrangementData.interviewMode = 2;
      if (isEmpty(state.newArrangementData.periods)) {
        draft.newArrangementData.periods = [
          {
            id: nanoid(),
          },
        ];
      }
      if (data.name) {
        draft.newArrangementData.name = data.name;
      }
      if (data.position.token) {
        draft.newArrangementData.positionToken = data.position.token;
      }
      if (data.process.token) {
        draft.newArrangementData.processToken = data.process.token;
      }
      if (data.startTime) {
        draft.newArrangementData.startTime = data.startTime * 1000;
        draft.newArrangementData.periods[0].startTime = data.startTime;
      }
      if (data.endTime) {
        draft.newArrangementData.periods[0].endTime = data.endTime;
      }
      if (data.deadline) {
        draft.newArrangementData.deadline = data.startTime - data.deadline;
      }
      if (data.duration) {
        draft.newArrangementData.duration = data.duration;
      }
      if (data.type) {
        draft.newArrangementData.type = data.type;
      }
      if (data.subType) {
        draft.newArrangementData.subType = data.subType;
      }
      if (data.address) {
        if (data.type === 2) {
          // 视频面试
          draft.newArrangementData.addressUrl = data.address;
        } else {
          // 线下面试、培训
          if (isEmpty(draft.newArrangementData.addressObj)) draft.newArrangementData.addressObj = {};
          draft.newArrangementData.addressObj.title = data.address;
        }
      }
      if (data.latitude && data.longitude) {
        draft.newArrangementData.latitude = data.latitude;
        draft.newArrangementData.longitude = data.longitude;
        if (isEmpty(draft.newArrangementData.addressObj)) draft.newArrangementData.addressObj = {};
        if (isEmpty(draft.newArrangementData.addressObj.location)) draft.newArrangementData.addressObj.location = {};
        draft.newArrangementData.addressObj.location.lat = data.latitude;
        draft.newArrangementData.addressObj.location.lng = data.longitude;
      }
      if (data.interviewers?.list && data.interviewers?.total > 0) {
        draft.newArrangementData.selectedParticipant = data.interviewers.list.map((c) => ({
          token: c.token,
          name: c.name,
          avatar: c.avatar,
        }));
      }
      if (data.numLimit) {
        draft.newArrangementData.numLimit = data.numLimit;
        draft.newArrangementData.periods[0].numLimit = data.numLimit;
      }
      if (data.autoSwitch) {
        draft.newArrangementData.autoSwitch = data.autoSwitch;
      }
      if (data.remark) {
        draft.newArrangementData.remark = data.remark;
        draft.newArrangementData.periods[0].remark = data.remark;
      }
      break;
    }
    case 'CHANGE_PAGE': {
      draft.page = action.payload;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default reducer;
