import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useCandidateState } from '../context';
import styles from './CandidateFormWithAnswer.module.css';
import EditFormItemResultDialog from './EditFormItemResultDialog';
import FormItem from './FormItem';

const CandidateFormWithAnswer = ({ formToken }) => {
  const { applicationFormData } = useCandidateState();
  let formData = (applicationFormData[formToken] && applicationFormData[formToken].data) || {};
  formData = Object.prototype.toString.call(formData) === '[object Array]' ? { answers: formData } : formData;
  const isEnabledScore = formData?.answers?.some((item) => item.isScoreOpen === 1);

  return (
    <>
      <div className={styles.wrapper}>
        {isEnabledScore && (
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="caption" color="textSecondary">
              总得分：{Math.floor(formData.score)}分
            </Typography>

            <Box ml="8px" />
            <Typography variant="caption" color="textSecondary">
              满分：{Math.floor(formData.total)}分{' '}
            </Typography>
          </Box>
        )}
        {formData?.answers?.map((item) => (
          <FormItem
            key={item.formItemToken}
            item={item}
            formToken={formToken}
            isEnabledScore={item.isScoreOpen === 1}
          />
        ))}
      </div>

      <EditFormItemResultDialog formToken={formToken} />
    </>
  );
};

CandidateFormWithAnswer.propTypes = {
  formToken: PropTypes.string.isRequired,
};

export default CandidateFormWithAnswer;
