/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { nanoid } from 'nanoid';
import defaultState from './defaultState';

function createAttachmentInfo(index) {
  return {
    token: nanoid(),
    group: `分组${index}`,
    files: [],
  };
}

const reducer = (state, action) => produce(state, (draft) => {
  switch (action.type) {
    case 'RESET_TO_DEFAULT_STATE': {
      return { ...defaultState, count: state.count + 1 };
    }
    case 'REFRESH_DATA': {
      return { ...state, count: state.count + 1 };
    }
    case 'SET_CANDIDATE_DATA': {
      return { ...state, candidateData: action.payload };
    }
    case 'SET_CANDIDATE_DATA_LOADING': {
      return { ...state, candidateDataLoading: action.payload };
    }
    case 'SET_APPLICATION_FLOW_DATA': {
      return { ...state, applicationFlowData: action.payload };
    }
    case 'SET_CANDIDATE_TOKEN': {
      return { ...state, candidateToken: action.payload };
    }
    case 'SET_CANDIDATE_APPLICATION_DATA': {
      return { ...state, candidateApplicationData: action.payload };
    }
    case 'SET_APPLICATION_TOKEN': {
      return { ...state, applicationToken: action.payload };
    }
    case 'INIT_APPLICATION_FLOW_STEP': {
      return { ...state, applicationFlowStepIndex: 0 };
    }
    case 'SET_APPLICATION_FLOW_STEP_INDEX': {
      return { ...state, applicationFlowStepIndex: action.payload, autoPlay: true };
    }
    case 'SET_TAB_NAME': {
      return { ...state, tabName: action.payload };
    }
    case 'SET_APPLICATION_FORM_DATA': {
      return { ...state, applicationFormData: action.payload };
    }
    case 'SET_CANDIDATE_ASSIGNABLE_POSITIONS_DATA': {
      return { ...state, candidateAssignablePositionsData: action.payload };
    }
    case 'SET_CANDIDATE_ASSIGNABLE_POSITIONS_DATA_LOADING': {
      return { ...state, candidateAssignablePositionsDataLoading: action.payload };
    }
    case 'SET_IS_SHOW_ASSIGNABLE_POSITIONS_LIST': {
      return { ...state, isShowAssignablePositionsList: action.payload };
    }
    case 'TOGGLE_HIDE_AI_STEPS': {
      return { ...state, hideAISteps: !state.hideAISteps };
    }
    case 'RESET_HIDE_AI_STEPS': {
      return { ...state, hideAISteps: false };
    }
    case 'ADD_TO_APPLICATION_FORM_DATA': {
      return {
        ...state,
        applicationFormData: {
          ...state.applicationFormData,
          [action.payload.token]: {
            title: action.payload.title,
            data: action.payload.data,
          },
        },
      };
    }
    case 'SAVE_TO_APPLICATION_FORM_DATA': {
      const { token, data } = action.payload;
      if (draft.applicationFormData[token]) {
        draft.applicationFormData[token].data = data;
      }
      break;
    }
    case 'SET_APPLICATION_DATA': {
      return { ...state, applicationData: action.payload };
    }
    case 'SET_NEXT_BUTTON': {
      return { ...state, isNextButton: action.payload };
    }
    case 'SKIP_CURRENT_STEP': {
      if (state.isNextButton) {
        const steps = (state.applicationFlowData && state.applicationFlowData.steps) || [];
        if (state.applicationFlowStepIndex < steps.length - 1) {
          return { ...state, applicationFlowStepIndex: state.applicationFlowStepIndex + 1 };
        }
      } else if (state.applicationFlowStepIndex > 0) {
        return { ...state, applicationFlowStepIndex: state.applicationFlowStepIndex - 1 };
      }
      return { ...state };
    }
    case 'SET_CAN_SEND_INVITATION': {
      return {
        ...state,
        applicationData: {
          ...state.applicationData,
          canSendInvitation: action.payload,
        },
      };
    }
    case 'SET_IS_RESUME_EDITING': {
      return { ...state, isResumeEditing: action.payload };
    }
    case 'SET_IS_EDITING': {
      return {
        ...state, isEditing: action.payload, editingType: 0, editingTitle: '',
      };
    }
    case 'SET_IS_EDITING_WITH_TYPE': {
      const { isEditing, editingType, editingTitle } = action.payload;
      return {
        ...state, isEditing, editingType, editingTitle,
      };
    }
    case 'SET_DEFAULT_EXTRA_INFO': {
      draft.applicationData.attachmentInfo = action.payload;
      break;
    }
    case 'ADD_ATTACHMENT_FOLDER': {
      if (draft.applicationData.attachmentInfo == null) {
        draft.applicationData.attachmentInfo = [];
      }
      const { attachmentInfo = [] } = state.applicationData;
      draft.applicationData.attachmentInfo.push(createAttachmentInfo(attachmentInfo.length + 1));
      break;
    }
    case 'SET_ATTACHMENT_FOLDER_NAME': {
      const { index, name } = action.payload;
      draft.applicationData.attachmentInfo[index].group = name;
      break;
    }
    case 'DELETE_ATTACHMENT_ITEM': {
      const { folderIndex, itemIndex } = action.payload;
      draft.applicationData.attachmentInfo[folderIndex].files.splice(itemIndex, 1);
      break;
    }
    case 'SET_UPLOADING_ATTACHMENT': {
      const { folderIndex, fileIndex, file } = action.payload;
      draft.applicationData.attachmentInfo[folderIndex].files[fileIndex] = file;
      break;
    }
    case 'DELETE_ATTACHMENT_FOLDER': {
      draft.applicationData.attachmentInfo.splice(action.payload, 1);
      break;
    }
    case 'SET_APPLICATION_RECOMMEND_REWARD_RECORDS_DATA': {
      return { ...state, applicationRecommendRewardRecordsData: action.payload };
    }
    case 'SET_APPLICATION_LOGS_DATA': {
      return { ...state, applicationLogsData: action.payload };
    }
    case 'CLICKED_SEND_BUTTON': {
      draft.clickedSendButton += 1;
      break;
    }
    case 'RESET_SEND_BUTTON': {
      draft.clickedSendButton = 0;
      break;
    }
    case 'ADD_TO_APPLICATION_INTERVIEW_DATA': {
      return {
        ...state,
        applicationInterviewArrangementData: {
          ...state.applicationInterviewArrangementData,
          [action.payload.token]: {
            title: action.payload.title,
            data: action.payload.data,
          },
        },
      };
    }
    case 'CANDIDATE_RESUME_EDITING': {
      return {
        ...state,
        isResumeEditing: action.payload,
      };
    }
    case 'CANDIDATE_RESUME_EDITED': {
      return {
        ...state,
        isResumeEdited: action.payload,
      };
    }
    case 'CANDIDATE_RESUME_RECORD': {
      return {
        ...state,
        isShowRecord: action.payload,
      };
    }
    case 'SET_APPLICATION_AI_REPORT': {
      draft.applicationData.aiReportResult = {
        ...action.payload,
        versions: state?.applicationData?.aiReportResult?.versions,
      };
      break;
    }
    case 'SET_MINE_EVALUATION_DATA': {
      draft.mineEvaluationData = action.payload;
      break;
    }
    case 'SET_APPLICATION_EVALUATION_DATA': {
      draft.applicationEvaluationData = action.payload;
      break;
    }
    case 'SET_ALERT_DIALOG': {
      draft.alertDialog = action.payload;
      break;
    }
    case 'SET_IS_TABS_LOADING': {
      draft.isTabsLoading = action.payload;
      break;
    }
    case 'SET_HAS_NO_PERMISSION': {
      draft.hasPermission = false;
      break;
    }
    case 'OPEN_EDIT_FORM_ITEM_RESULT_DIALOG': {
      draft.isEditFormItemResultDialogOpen = true;
      const { formTypeId, formAnswer } = action.payload;
      if (formAnswer) {
        draft.itemDataForEditFormItemResultDialog = action.payload;
      } else if (formTypeId === 1) {
        draft.itemDataForEditFormItemResultDialog = {
          ...action.payload,
          formAnswer: { files: [] },
        };
      } else if ([2, 7].indexOf(formTypeId) > -1) {
        draft.itemDataForEditFormItemResultDialog = {
          ...action.payload,
          formAnswer: { answer: '' },
        };
      } else if (formTypeId === 3) {
        draft.itemDataForEditFormItemResultDialog = {
          ...action.payload,
          formAnswer: { choiceOptions: [] },
        };
      } else if (formTypeId === 4) {
        draft.itemDataForEditFormItemResultDialog = {
          ...action.payload,
          formAnswer: { dateTime: new Date((new Date()).toISOString().substr(0, 10)).getTime() / 1000 },
        };
      } else if (formTypeId === 8) {
        draft.itemDataForEditFormItemResultDialog = {
          ...action.payload,
          formAnswer: { answer: action.payload.formItemOption?.alternatives?.map(() => '') },
        };
      }
      break;
    }
    case 'CLOSE_EDIT_FORM_ITEM_RESULT_DIALOG': {
      draft.isEditFormItemResultDialogOpen = false;
      break;
    }
    case 'SET_RESULT_FOR_EDIT_FORM_ITEM_RESULT_DIALOG': {
      if (draft.itemDataForEditFormItemResultDialog?.formTypeId === 1) {
        const idx = draft.itemDataForEditFormItemResultDialog.formAnswer
          .files.findIndex((o) => o === action.payload);
        if (idx > -1) {
          draft.itemDataForEditFormItemResultDialog.formAnswer.files.splice(idx, 1);
        } else {
          draft.itemDataForEditFormItemResultDialog.formAnswer.files.push(action.payload);
        }
      }
      if (draft.itemDataForEditFormItemResultDialog?.formTypeId === 2) {
        draft.itemDataForEditFormItemResultDialog.formAnswer.answer = action.payload;
      }
      if (draft.itemDataForEditFormItemResultDialog?.formTypeId === 3) {
        if (draft.itemDataForEditFormItemResultDialog?.formItemOption?.allowMultiple) {
          const [name, checked] = action.payload;
          if (checked) {
            draft.itemDataForEditFormItemResultDialog.formAnswer.choiceOptions.push(name);
          } else {
            const idx = draft.itemDataForEditFormItemResultDialog.formAnswer.choiceOptions.findIndex((o) => o === name);
            draft.itemDataForEditFormItemResultDialog.formAnswer.choiceOptions.splice(idx, 1);
          }
        } else {
          draft.itemDataForEditFormItemResultDialog.formAnswer.choiceOptions = [action.payload];
        }
      }
      if (draft.itemDataForEditFormItemResultDialog?.formTypeId === 4) {
        if (action.payload) {
          const dateTime = new Date(action.payload).getTime() / 1000;
          if (dateTime && !Number.isNaN(dateTime)) {
            draft.itemDataForEditFormItemResultDialog.formAnswer.dateTime = dateTime;
          }
        }
      }
      if (draft.itemDataForEditFormItemResultDialog?.formTypeId === 7) {
        draft.itemDataForEditFormItemResultDialog.formAnswer.answer = action.payload;
      }
      if (draft.itemDataForEditFormItemResultDialog?.formTypeId === 8) {
        const [idx, value] = action.payload;
        draft.itemDataForEditFormItemResultDialog.formAnswer.answer[idx] = value;
      }
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default reducer;
