import * as OperatingRecordAPI from '@api/OperatingRecord';
import {
  AddIcon,
  DateIcon,
  DeleteIcon,
  EditIcon,
  ExpandIcon,
  NotificationIcon,
  ReviewCircleIcon,
  ShareIcon,
} from '@avocadoui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useCandidateState } from '@module/CandidateModal/context';
import useGlobalToast from '@utils/GlobalToast';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import Empty from './Empty';

const MAX_SIZE = 50;

const StyledAccordion = withStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}))(Accordion);

const StyledAccordionSummary = withStyles(() => ({
  root: {
    padding: 0,
    minHeight: 24,
    '&$expanded': {
      minHeight: 24,
    },
  },
  expanded: {},
  content: {
    margin: 0,
    '& .expandedIcon': {
      transform: 'rotate(0deg)',
    },
    '& .nomarlText': {
      display: 'none',
    },
    '& .expandedText': {
      display: 'block',
    },
    '&$expanded': {
      margin: '0px !important',
      '& .expandedText': {
        display: 'none',
      },
      '& .nomarlText': {
        display: 'block',
      },
      '& .expandedIcon': {
        transform: 'rotate(180deg)',
      },
    },
  },
  expandIcon: {
    padding: 0,
  },
}))(AccordionSummary);

const StyledAccordionDetails = withStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    whiteSpace: 'pre-line',
    color: 'rgba(0, 0, 0, 0.56)',
    borderRadius: 4,
    padding: 12,
    marginTop: 8,
    wordBreak: 'break-all',
  },
}))(AccordionDetails);

const StyledIconWrapper = withStyles(() => ({
  root: {
    width: 24,
    height: 24,
    background: 'linear-gradient(270deg, #4BB051 0%, #71D376 100%)',
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))(Box);

const StyledStepContent = withStyles(() => ({
  root: {
    marginTop: 8,
    marginLeft: 11,
    borderLeft: '2px solid rgba(0, 0, 0, 0.08)',
    borderRadius: 1,
  },
}))(StepContent);

const StyledStepConnector = withStyles(() => ({
  root: {
    marginLeft: 11,
  },
  lineVertical: {
    borderLeft: '2px solid rgba(0, 0, 0, 0.08)',
    minHeight: 24,
  },
}))(StepConnector);

const useStyles = makeStyles(() => ({
  icon: {
    width: '20px',
    height: '20px',
    color: '#ffffff',
  },
}));

const renderIcon = (opType, iconClass) => {
  let Component;

  // 1 新增 2 删除 3 修改 4 浏览 5 审核 6 分享 7 安排面试 8 消息提醒 9 导出
  switch (opType) {
    case 1:
      Component = AddIcon;
      break;
    case 2:
      Component = DeleteIcon;
      break;
    case 3:
      Component = EditIcon;
      break;
    case 5:
      Component = ReviewCircleIcon;
      break;
    case 6:
      Component = ShareIcon;
      break;
    case 7:
      Component = DateIcon;
      break;
    case 8:
      Component = NotificationIcon;
      break;
    default:
      Component = ReviewCircleIcon;
      break;
  }

  return (
    <StyledIconWrapper>
      <Component className={clsx('MuiSvgIcon-root', iconClass)} />
    </StyledIconWrapper>
  );
};

const RenderStepContent = ({ logItem }) => {
  if (logItem.info && logItem.detail) {
    return (
      <Box mb={1}>
        <StyledAccordion TransitionProps={{ unmountOnExit: true }}>
          <StyledAccordionSummary
            // expandIcon={<ExpandMoreIcon style={{ color: '#576B95' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            iconButtonProps={{
              endIcon: <Icon>send</Icon>,
            }}
          >
            <Box display="flex" justifyContent="space-between" flexGrow={1}>
              <Typography variant="body2">{logItem.info}</Typography>
              <Box display="flex" color="#576B95" alignItems="center" minWidth={48}>
                <ExpandMoreIcon className="expandedIcon" />
                <Typography color="inherit" className="nomarlText" variant="caption">
                  收起
                </Typography>
                <Typography color="inherit" className="expandedText" variant="caption">
                  展开
                </Typography>
              </Box>
            </Box>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Typography variant="body2">{logItem.detail}</Typography>
          </StyledAccordionDetails>
        </StyledAccordion>
      </Box>
    );
  }

  if (logItem.info) {
    return (
      <Typography variant="body2" style={{ marginBottom: '8px' }}>
        {logItem.info}
      </Typography>
    );
  }

  return null;
};

RenderStepContent.propTypes = {
  logItem: PropTypes.objectOf(PropTypes.any).isRequired,
};

const OperatingRecord = () => {
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const { applicationData, candidateData, applicationLogsData } = useCandidateState();
  const { canSendInvitation, interviewers, positionToken } = applicationData;
  const { tags } = candidateData;
  const GlobalToast = useGlobalToast();
  const classes = useStyles();

  const [logs, setLogs] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const getLogs = useCallback(() => {
    OperatingRecordAPI.getCandidateOpLogs(candidateToken, { positionToken })
      .then((resp) => {
        const { data, code } = resp.data;
        const { list } = data;

        if (code === 0) {
          setLogs(list);
        } else {
          GlobalToast.warning('获取操作日志失败');
        }
      })
      .catch(() => {
        GlobalToast.warning('获取操作日志失败');
      });
  }, [candidateToken, canSendInvitation, tags, interviewers, applicationLogsData]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  return (
    <Box width="100%" height="100%">
      {logs && logs.length > 0 ? (
        <Stepper orientation="vertical" connector={<StyledStepConnector />}>
          {(showMore ? logs : logs.slice(0, MAX_SIZE)).map((log) => (
            <Step key={log.token} active>
              <StepLabel StepIconComponent={() => renderIcon(log.opType, classes.icon)}>
                <Typography variant="body1">
                  {log.opTime ? moment(log.opTime * 1000).format('YYYY/MM/DD HH:mm:ss') : '-'}
                </Typography>
              </StepLabel>
              <StyledStepContent>
                <RenderStepContent logItem={log} />
                <Typography variant="caption" color="textSecondary">
                  由 {log.opUser.name} {log.opUser.account} 操作
                </Typography>
              </StyledStepContent>
            </Step>
          ))}
          {logs.length > MAX_SIZE && !showMore && (
            <Step active>
              <StepLabel
                style={{ cursor: 'pointer' }}
                StepIconComponent={() => (
                  <ExpandIcon
                    className="MuiSvgIcon-root"
                    style={{
                      color: '#576B95',
                    }}
                  />
                )}
                onClick={() => setShowMore(true)}
              >
                <Typography
                  style={{
                    color: '#576B95',
                  }}
                  variant="body2"
                >
                  展开其他{logs.length - MAX_SIZE}项
                </Typography>
              </StepLabel>
            </Step>
          )}
        </Stepper>
      ) : (
        <Box my="20%">
          <Empty emptyTitle="暂无操作日志" />
        </Box>
      )}
    </Box>
  );
};

OperatingRecord.propTypes = {};

OperatingRecord.defaultProps = {};

export default memo(OperatingRecord);
