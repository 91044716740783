import axios from './Api';

export const newInterview = (data) => axios.request({
  url: '/customInterview',
  method: 'POST',
  data,
});

export const editInterview = (interviewToken, data) => axios.request({
  url: `/customInterview/${interviewToken}`,
  method: 'PUT',
  data,
});

export const interviewList = (data) => axios.request({
  url: '/customInterviews',
  method: 'POST',
  data,
});

export const sendInterviewNotificationToCandidates = (data) => axios.request({
  url: '/customInterview/notifyTemplate/candidate/send',
  method: 'POST',
  data,
});

export const sendInterviewNotificationToInterviewer = (data) => axios.request({
  url: '/customInterview/notifyTemplate/interviewer/send',
  method: 'POST',
  data,
});

export const sendInterviewNotificationToSingleCandidate = (candidateToken, data) => axios.request({
  url: `/customInterview/notifyTemplate/${candidateToken}/send`,
  method: 'POST',
  data,
});
export const sendInterviewCandidateWithInterviewer = (data) => axios.request({
  url: '/customInterview/notifyTemplate/candidateWithInterviewer/send',
  method: 'POST',
  data,
});

export const cancelInterview = (data) => axios.request({
  url: '/customInterview/cancel',
  method: 'POST',
  data,
});

export const interviewListByApplicationAndProcess = (applicationToken, processToken, cancelToken) => axios.request({
  url: `/customInterview/${applicationToken}/${processToken}`,
  method: 'GET',
  cancelToken,
});

export const applicationListByPositionAndProcess = (positionToken, processToken) => axios.request({
  url: `/vc1/customInterview/position/${positionToken}/process/${processToken}/applications`,
  method: 'GET',
});

export const interviewNotifyTemplate = (token, mode) => axios.request({
  url: '/customInterview/notifyTemplate',
  method: 'GET',
  params: { token, mode },
});

export const interviewNotifyTemplateVariables = () => axios.request({
  url: '/customInterview/notifyTemplate/variables',
  method: 'GET',
});

export const getCandidateNotifyTemplate = (data) => axios.request({
  url: '/customInterview/notifyTemplate/candidate',
  method: 'POST',
  data,
});

export const getCandidateWithInterviewer = (data) => axios.request({
  url: '/customInterview/notifyTemplate/candidateWithInterviewer',
  method: 'POST',
  data,
});

export const checkInterview = (data) => axios.request({
  url: '/customInterview/check',
  method: 'POST',
  data,
});

export const scheduledInterviewList = (data) => axios.request({
  url: '/customInterview/interviewer/schedule',
  method: 'POST',
  data,
});

export const getInterviewCheckInQrCode = () => axios.request({
  url: '/customInterview/checkIn/qrCode',
  method: 'GET',
});

export const getPositionProcessList = () => axios.request({
  url: '/vc1/customInterview/positions',
  method: 'GET',
});

export const getHasInterviewProcess = () => axios.request({
  url: '/customInterview/schedule/enter',
  method: 'GET',
});

export const getInterviewPeriod = (periodToken, params) => axios.request({
  url: `/customInterview/period/${periodToken}`,
  method: 'GET',
  params,
});

export const checkReferDepartment = (data) => axios.request({
  url: '/customInterview/check/referDepartment',
  method: 'POST',
  data,
});

export const getInterviewAllPeriods = (interviewToken, params) => axios.request({
  url: `/customInterview/${interviewToken}`,
  method: 'GET',
  params,
});

export const deleteInterviewRecord = (data) => axios.request({
  url: '/customInterview/record',
  method: 'DELETE',
  data,
});

export const getInterviewSubtitle = (roomToken, interviewUserToken) => axios.request({
  url: `/rtcInterview/${roomToken}/${interviewUserToken}/subtitle`,
  method: 'GET',
});

export default {};
