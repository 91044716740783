import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import { DegreeObject } from '../../v1/constants/ConstFunction';

const useStyles = makeStyles({
  inputMarginDense: {
    paddingTop: 10,
    paddingBottom: 10,
  },
});

const EducationSelect = ({
  name, value, label, onChange,
}) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      select
      variant="outlined"
      label={label}
      size="small"
      name={name}
      value={value}
      onChange={onChange}
      InputProps={{
        className: { inputMarginDense: classes.inputMarginDense },
      }}
    >
      {Object.keys(DegreeObject)
        .filter((v) => v > 0)
        .map((v) => (
          <MenuItem key={v} value={v}>
            {DegreeObject[v]}
          </MenuItem>
        ))}
    </TextField>
  );
};

EducationSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

EducationSelect.defaultProps = {
  name: 'education',
  value: '',
  label: '学历',
  onChange: () => {},
};

export default EducationSelect;
