import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

const ELButton = withStyles(() => ({
  root: {
    '&:disabled': {
      color: 'rgba(75, 176, 81, 0.4)',
    },
  },
  textSecondary: {
    '&:disabled': {
      color: 'rgba(255,108,108, 0.4)',
    },
  },
  label: {
    fontSize: '16px',
  },
}))(Button);

ELButton.propTypes = {};

ELButton.defaultProps = {};

export default ELButton;
