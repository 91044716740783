import styled from 'styled-components';

const StyledInterviews = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('//public-static-assets.oss-cn-beijing.aliyuncs.com/img/sharebk.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  .loginTitle {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.75rem;
  }
  .title {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .errorTips {
    width: 100%;
    height: 2rem;
    display: flex;
    align-items: center;
  }
  .interViewerInputWrap {
    width: 24.5rem;
    min-height: 18.125rem;
  }
  .inputWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
    .phoneTextField {
      // width: 20rem;
      height: 3.5rem;
      // margin-left: 1.125rem;
      margin-top: 1.125rem;
    }
    .phoneCodeTextField {
      // width: 20rem;
      height: 3.5rem;
      // margin-left: 1.125rem;
      margin-top: 1.125rem;
    }
    .sendTips {
      width: 20rem;
      height: 3.5rem;
      position: relative;
      > span {
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.6);
        position: absolute;
        bottom: 0.75rem;
      }
      .sendButton {
        position: absolute;
        bottom: 0.25rem;
        font-size: 0.75rem;
      }
    }
    .getPhoneCode {
      width: 20rem;
      height: 2.6875rem;
      /* margin-top:3.75rem; */
    }
  }
`;

export default StyledInterviews;
