import { DeleteIcon, DownloadIcon } from '@avocadoui/icons';
import FileIcon from '@components/Icon/FileIcon';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ATTACHMENT_TYPE_TITLES } from '@utils/constants';
import filesize from 'filesize';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useCandidateDispatch } from '../context';
import styles from './CandidateAttachments.module.css';

const IconButton = withStyles({
  root: {
    padding: '6px',
    color: 'rgba(0, 0, 0, 0.56)',
  },
  sizeSmall: {
    padding: '5px',

    '& .MuiSvgIcon-root': {
      width: '14px',
      height: '14px',
    },
  },
})(MuiIconButton);

const ErrorLinearProgress = withStyles({
  barColorPrimary: {
    backgroundColor: '#fbdbcb',
  },
})(LinearProgress);

function getFileType(name) {
  const splittedName = name.split('.');
  const lastItem = splittedName[splittedName.length - 1];
  return lastItem.toLowerCase();
}

function canPreview(name) {
  const type = getFileType(name);
  const types = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'html'];

  return types.indexOf(type) > -1;
}

function formatPreviewLink(name, uploadedUrl) {
  const type = getFileType(name);
  const types = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

  if (types.indexOf(type) > -1) {
    return `https://view.officeapps.live.com/op/view.aspx?src=${uploadedUrl}`;
  }
  return uploadedUrl;
}

export function AttachmentItem({ item = {}, itemIndex, folderIndex }) {
  const {
    name, length, uploadedUrl, loaded, total, type,
  } = item || {};

  const dispatch = useCandidateDispatch();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!uploadedUrl && !loaded && !total) {
      setHasError(true);
    }
  }, [uploadedUrl, loaded, total]);

  function renderProgress() {
    if (hasError) {
      return <ErrorLinearProgress variant="determinate" />;
    }
    if (loaded === total) {
      return <LinearProgress />;
    }
    return <LinearProgress variant="determinate" value={Math.floor((loaded / total) * 100)} />;
  }

  return (
    <div className={styles.item}>
      <div className={styles.left}>
        <div className={styles.icon} style={{ marginTop: 4 }}>
          <FileIcon type={type === 1 ? 'html' : getFileType(name)} />
        </div>
        {type === 1 && (
          <Box color="text.link" ml={1.5}>
            <a href={uploadedUrl} target="_blank" rel="noreferrer" style={{ color: 'inherit' }}>
              {uploadedUrl}
            </a>
          </Box>
        )}
        {type !== 1 && (
          <div className={styles.meta}>
            <Typography variant="body2">{name}</Typography>
            <div className={total || hasError ? styles.progress : `${styles.progress} ${styles.invisible}`}>
              {renderProgress()}
            </div>
            {hasError && <div className={styles.error}>上传失败！</div>}
            {!hasError && total && (
              <Typography variant="caption" color="textSecondary">
                {filesize(loaded)} / {filesize(total)}
              </Typography>
            )}
            {!hasError && !total && (
              <Typography variant="caption" color="textSecondary">
                {filesize(length)}
              </Typography>
            )}
          </div>
        )}
      </div>
      <div className={styles.right}>
        {uploadedUrl && type !== 1 && (
          <Link color="inherit" href={`${uploadedUrl}?response-content-type=application%2Foctet-stream`}>
            <IconButton>
              <DownloadIcon htmlColor="rgba(0,0,0,0.56)" />
            </IconButton>
          </Link>
        )}
        {uploadedUrl && canPreview(name) && (
          <IconButton onClick={() => window.open(formatPreviewLink(name, uploadedUrl), '_blank')}>
            <VisibilityIcon htmlColor="rgba(0,0,0,0.56)" />
          </IconButton>
        )}
        {(uploadedUrl || hasError) && (
          <PopupState variant="popover" popupId="delete-comment-popup-popover">
            {(popupState) => (
              <>
                <IconButton {...bindTrigger(popupState)}>
                  <DeleteIcon />
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Paper style={{ width: 300, padding: '24px 16px 12px 16px' }}>
                    <Typography
                      variant="body1"
                      style={{
                        color: 'rgba(0, 0, 0, 0.56)',
                      }}
                    >
                      确认删除该{ATTACHMENT_TYPE_TITLES[type || 0]}吗？
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" mt="36px">
                      <Button onClick={popupState.close}>取消</Button>
                      <Box mr={1} />
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          popupState.close();

                          dispatch({ type: 'DELETE_ATTACHMENT_ITEM', payload: { folderIndex, itemIndex } });
                        }}
                      >
                        删除
                      </Button>
                    </Box>
                  </Paper>
                </Popover>
              </>
            )}
          </PopupState>
        )}
      </div>
    </div>
  );
}

AttachmentItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  itemIndex: PropTypes.number.isRequired,
  folderIndex: PropTypes.number.isRequired,
};

export default AttachmentItem;
