import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { getEntityRange, getSelectionEntity, getSelectionText } from 'draftjs-utils';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/notify/email/Delete.svg';
import { ReactComponent as LinkIcon } from '../../assets/img/icons/notify/email/Link.svg';

const ELEditorToolbarLink = (props) => {
  const { confirmLink, editorState, removeLink } = props;

  const [currentLink, setCurrentLink] = useState('');

  const getCurrentValues = () => {
    const currentEntity = getSelectionEntity(editorState);
    const contentState = editorState.getCurrentContent();
    const currentValues = {};
    if (currentEntity && contentState.getEntity(currentEntity).get('type') === 'LINK') {
      currentValues.link = {};
      const entityRange = currentEntity && getEntityRange(editorState, currentEntity);
      currentValues.link.target = currentEntity && contentState.getEntity(currentEntity).get('data').url;
      currentValues.link.targetOption = currentEntity && contentState.getEntity(currentEntity).get('data').targetOption;
      currentValues.link.title = entityRange && entityRange.text;
    }
    currentValues.selectionText = getSelectionText(editorState);
    return currentValues;
  };

  useEffect(() => {
    const { link } = getCurrentValues();
    if (link) {
      setCurrentLink(link.target);
    } else {
      setCurrentLink('');
    }
  }, [editorState.getSelection()]);
  const { link } = getCurrentValues();
  return (
    <Box>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            <IconButton {...bindTrigger(popupState)}>
              <LinkIcon className="MuiSvgIcon-root" />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              variant="menu"
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <Box display="flex" width={264} height={60} justifyContent="center" alignItems="center" pl={1.5} pr={1.5}>
                <TextField
                  variant="outlined"
                  placeholder="请输入链接,按回车保存"
                  margin="dense"
                  fullWidth
                  value={currentLink}
                  onChange={(e) => {
                    setCurrentLink(e.target.value.trim());
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      confirmLink(currentLink);
                      setCurrentLink('');
                      popupState.close();
                    }
                  }}
                />
                {link && link.target && (
                  <IconButton
                    onClick={() => {
                      setCurrentLink('');
                      removeLink();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Popover>
          </>
        )}
      </PopupState>
    </Box>
  );
};

ELEditorToolbarLink.propTypes = {
  editorState: PropTypes.objectOf(PropTypes.any).isRequired,
  confirmLink: PropTypes.objectOf(PropTypes.any),
  removeLink: PropTypes.func,
};

ELEditorToolbarLink.defaultProps = {
  confirmLink: {},
  removeLink: null,
};

export default ELEditorToolbarLink;
