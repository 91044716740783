import { KeyboardArrowDownIcon } from '@avocadoui/icons';
import {
  Accordion, AccordionDetails, AccordionSummary, makeStyles, withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const StyledAccordion = withStyles({
  root: {
    border: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
    '&.Mui-disabled': {
      backgroundColor: 'white',
    },
    '&.Mui-disabled > .MuiButtonBase-root.Mui-disabled': {
      pointerEvents: 'unset',
    },
  },
})(Accordion);

const StyledAccordionSummary = withStyles({
  root: {
    position: 'sticky',
    top: '56px',
    // zIndex: 0,
    backgroundColor: '#FAFAFA',
    border: '1px solid #ededed',
    borderRight: 0,
    '&.Mui-expanded': {
      minHeight: '48px',
    },
    '&.Mui-disabled': {
      opacity: 'unset',
    },
    '&.Mui-disabled > .MuiAccordionSummary-expandIcon': {
      display: 'none',
    },
    '&.Mui-focused': {
      backgroundColor: '#FAFAFA',
    },
  },
  content: {
    margin: '0px !important',
  },
})(AccordionSummary);

const StyledAccordionDetails = withStyles({
  root: {
    padding: '16px',
    border: '1px solid #ededed',
    borderTop: 0,
    borderRight: 0,
  },
})(AccordionDetails);

const useStyles = makeStyles({
  zIndexOff: {
    zIndex: 0,
  },
  zIndexOn: {
    zIndex: 1,
  },
});
export default function Panel({
  header, body, disabled, defaultExpanded, hasScrolled = false,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);

  function handleChange(_, isExpanded) {
    if (disabled) return;
    setExpanded(isExpanded);
  }

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <StyledAccordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={handleChange}
      square
      elevation={0}
      disabled={disabled}
    >
      <StyledAccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        classes={{ root: hasScrolled ? classes.zIndexOn : classes.zIndexOff }}
      >
        {header}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{body}</StyledAccordionDetails>
    </StyledAccordion>
  );
}

Panel.propTypes = {
  header: PropTypes.element.isRequired,
  body: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  defaultExpanded: PropTypes.bool.isRequired,
  hasScrolled: PropTypes.bool.isRequired,
};

Panel.defaultProps = {
  disabled: false,
};
