import { isEmpty } from 'lodash';

export function setUrlQuery(location, queries = {}) {
  const query = new URLSearchParams(location.search);
  if (!isEmpty(queries)) {
    Object.keys(queries).forEach((key) => {
      query.set(key, queries[key]);
    });
  }
  return query;
}

export function getUrlQuery(location, name) {
  const query = new URLSearchParams(location.search);
  if (query.has(name)) return query.get(name);
  return '';
}
