import Typography from '@components/Avocado/Typography';
import {
  Box,
  makeStyles,
  Step as MuiStep,
  StepContent as MuiStepContent,
  StepLabel as MuiStepLabel,
  withStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useCandidateState } from '../context';
import ActionButtonsAndDialogs from './ActionButtonsAndDialogs';
import CommentWrapper from './CommentWrapper';
import LogItem from './LogItem';
import StepDatetime from './StepDatetime';
import StepIcon from './StepIcon';
import StepTitle from './StepTitle';

const useStyles = makeStyles(() => ({
  noBorder: {
    borderColor: 'transparent',
  },
}));

const Step = withStyles()(MuiStep);

const StepLabel = withStyles({
  iconContainer: {
    paddingRight: '4px',
  },
})(MuiStepLabel);

const StepContent = withStyles({
  root: {
    marginTop: '4px',
    marginLeft: '11px',
    paddingLeft: '16px',
    paddingRight: 0,
  },
})(MuiStepContent);

function ProcessStep({ item, index, ...props }) {
  const classes = useStyles();
  const {
    processToken,
    processName,
    applicationProcessToken,
    arrivalTime,
    logs = [],
    rejected,
    rejectLog,
    layinLog,
  } = item;
  const applicationData = useCandidateState((state) => state.applicationData);
  const { restoreProcess = {}, applicationState } = applicationData;
  const { token: currentProcessToken } = restoreProcess;
  const applicationLogsDataLength = useCandidateState(
    (state) => state.applicationLogsData.filter(({ stage }) => stage === 1).length,
  );
  const [showActionButtons, setShowActionButtons] = useState(true);

  const isDone = arrivalTime !== 0;
  const isActive = processToken === currentProcessToken;
  const hasLogs = !isEmpty(logs);
  const hasRejectLog = !isEmpty(rejectLog) && rejectLog.createdAt !== 0;
  const hasLayinLog = !isEmpty(layinLog) && layinLog.createdAt !== 0;
  const hasAnyLogs = hasLogs || hasRejectLog || hasLayinLog;
  const isFailed = rejected;
  const isLastStep = index === applicationLogsDataLength - 1;
  const isCanceled = applicationState === 2;
  const isLayined = [3, 10].indexOf(applicationState) > -1;
  const showComments = isActive && !isCanceled && !isLayined;

  return (
    <Step expanded>
      <StepLabel
        icon={(
          <Box width height display="flex" alignItems="center" justifyContent="center">
            <StepIcon isDone={isDone} isFailed={isFailed} isActive={isActive} isCanceled={isCanceled}>
              {index + 1}
            </StepIcon>
          </Box>
        )}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <StepTitle isDone={isDone} isFailed={isFailed} isActive={isActive} isCanceled={isCanceled}>
            {processName}
          </StepTitle>
          {arrivalTime > 0 && <StepDatetime>{moment(arrivalTime * 1000).format('MM月DD日 HH:mm')}</StepDatetime>}
        </Box>
      </StepLabel>
      <StepContent classes={isLastStep ? { root: classes.noBorder } : {}} {...props}>
        {isFailed && hasRejectLog && (
          <Box style={{ transform: 'translateY(-8px)' }}>
            <Typography variant="caption" color="text.secondary">
              淘汰于{moment(rejectLog.createdAt * 1000).format('MM月DD日 HH:mm')}
            </Typography>
            {!!rejectLog.info && (
              <Typography mt={1} variant="body2" color="text.primary">
                淘汰原因: {rejectLog.info}
              </Typography>
            )}
          </Box>
        )}
        {hasLayinLog && (
          <Box style={{ transform: 'translateY(-8px)' }}>
            <Typography variant="caption" color="text.secondary">
              已于{moment(layinLog.createdAt * 1000).format('MM月DD日 HH:mm')}加入人才储备
            </Typography>
            {!!layinLog.info && (
              <Typography mt={1} variant="body2" color="text.primary">
                加入人才储备原因: {layinLog.info}
              </Typography>
            )}
          </Box>
        )}
        {hasLogs && logs.map((log) => <LogItem key={log.token} item={log} />)}
        {showComments && (
          <CommentWrapper
            applicationProcessToken={applicationProcessToken}
            showActionButtons={showActionButtons}
            setShowActionButtons={setShowActionButtons}
          />
        )}
        <Box mt={2}>
          <ActionButtonsAndDialogs
            index={index}
            isActive={isActive && showActionButtons}
            isFailed={isFailed}
            isCanceled={isCanceled}
          />
        </Box>
      </StepContent>
      {(!hasAnyLogs || isActive || isDone) && !isLastStep && (
        <div
          style={{
            minHeight: '8px',
            marginLeft: '11px',
            marginBottom: '4px',
            borderLeft: '2px solid rgba(0, 0, 0, 0.08)',
          }}
        />
      )}
    </Step>
  );
}

ProcessStep.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};

export default ProcessStep;
