import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { useCandidateState } from '@module/CandidateModal/context';
import { useState, useContext, useEffect } from 'react';
import { ResumeContext } from './ResumeContext';
import ResumeOriginal from './ResumeOriginal';
import styles from './ResumeRightLayout.module.css';
import ResumeStandard from './ResumeStandard';

const ReusmeTab = withStyles((theme) => ({
  root: {
    minHeight: 48,
    padding: '6px 6px',
    minWidth: 84,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  textColorInherit: {
    opacity: 1,
    color: theme.palette.text.disabled,
    fontWeight: 400,
    '&$selected': {
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
    '&$disabled': {
      color: theme.palette.text.disabled,
    },
  },
  selected: {},
}))(Tab);

const ResumeTabs = withStyles(() => ({
  indicator: {
    backgroundColor: 'transparent',
  },
}))(TabList);

const ResumeLayout = () => {
  const [value, setValue] = useState('2');
  const { candidateData = {} } = useCandidateState();
  const { uploadStatus = 0, resumeURL = '' } = useContext(ResumeContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (uploadStatus) setValue('1');
  }, [uploadStatus]);

  return (
    <Box width={564} style={{ backgroundColor: 'white' }} flexGrow={1} flexShrink={0}>
      <TabContext value={value}>
        <Box className={styles.tabBar}>
          {!candidateData?.resumeInfo && resumeURL === '' && (
            <ResumeTabs onChange={handleChange}>
              <ReusmeTab label="标准简历" value="2" disableRipple />
            </ResumeTabs>
          )}
          {(!!candidateData?.resumeInfo || resumeURL !== '') && (
            <ResumeTabs onChange={handleChange}>
              <ReusmeTab label="原始简历" value="1" disableRipple />
              <ReusmeTab label="标准简历" value="2" disableRipple />
            </ResumeTabs>
          )}
        </Box>
        <TabPanel value="1" style={{ position: 'relative' }}>
          <ResumeOriginal />
        </TabPanel>
        <TabPanel value="2">
          <ResumeStandard />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

ResumeLayout.propTypes = {};

ResumeLayout.defaultProps = {};

export default ResumeLayout;
