import { makeStyles, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { zhCN } from 'date-fns/locale';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  adornedEnd: {
    paddingRight: 8,
  },
}));
const GenderSelect = ({
  name, value, label, onChange, size, minDate, keyboardInput,
}) => {
  const classes = useStyles();

  const props = {
    ampm: false,
    ampmInClock: false,
    showToolbar: false,
    allowKeyboardControl: false,
    disableMaskedInput: true,
    format: 'yyyy/MM/dd HH:mm',
    onError: () => {},
    inputProps: {
      classes: { adornedEnd: classes.adornedEnd },
      endAdornment: (
        <InputAdornment position="end">
          <IconButton>
            <EventIcon />
          </IconButton>
        </InputAdornment>
      ),
    },
    name,
    minDate: minDate || new Date(),
    label,
    size,
    value,
    onChange,
  };

  if (keyboardInput) {
    return (
      <LocalizationProvider dateAdapter={DateFnsAdapter} locale={zhCN}>
        <DateTimePicker
          {...props}
          renderInput={(p) => <TextField variant="outlined" fullWidth {...p} helperText="" />}
        />
      </LocalizationProvider>
    );
  }

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={zhCN}>
      <DateTimePicker
        {...props}
        error={false}
        renderInput={(p) => <TextField variant="outlined" fullWidth {...p} helperText="" />}
      />
    </LocalizationProvider>
  );
};
GenderSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  keyboardInput: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
};

GenderSelect.defaultProps = {
  name: 'time',
  value: '',
  label: '笔试时间',
  size: 'medium',
  onChange: () => {},
  minDate: null,
  keyboardInput: false,
};

export default GenderSelect;
