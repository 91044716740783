import * as API from '@api/Exams';
import { AddIcon } from '@avocadoui/icons';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PAGE_SIZE_PRE, START_PAGE } from '@utils/constants';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"]': {
    },
  },
  listbox: {
    maxHeight: 272,
  },
}));

const WaittenMultiplePaperList = ({ onChange, marginTop, value }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [papers, setPapers] = useState([]);
  const [currentPaper, setCurrentPaper] = useState([]);
  const [consoleLink, setConsoleLink] = useState(START_PAGE);
  const loading = open;

  useEffect(() => {
    if (value) setCurrentPaper(value);
  }, [value]);

  const loadPaper = (page) => {
    API.getExamPaper(page, PAGE_SIZE_PRE)
      .then((v) => {
        if (v.data.code === 0) {
          setConsoleLink(v.data.data.consoleLink);
          const allPapers = [...papers, ...v.data.data.list];
          setPapers(allPapers);
        }
      })
      .catch(console.log);
  };

  const reloadData = () => {
    API.getExamPaper(START_PAGE, PAGE_SIZE_PRE)
      .then((v) => {
        if (v.data.code === 0) {
          setConsoleLink(v.data.data.consoleLink);
          setPapers([{ token: '0', name: '添加考卷' }, ...v.data.data.list]);
          if (v.data.data.list.length === 50) {
            loadPaper(2);
          }
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (papers.length > 0) setCurrentPaper((state) => state.map((vv) => papers.find((vvv) => vvv.token === vv.token)));
  }, [papers]);

  useEffect(() => {
    if (loading) {
      reloadData();
    }
  }, [loading]);

  return (
    <Autocomplete
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      classes={{ inputRoot: classes.inputRoot, paper: classes.paper, listbox: classes.listbox }}
      onChange={(e, v) => {
        if (v.some((vv) => vv.token === '0')) {
          window.open(consoleLink);
        } else {
          onChange(e, v);
          setCurrentPaper(v);
        }
      }}
      fullWidth
      size="medium"
      options={papers}
      value={currentPaper}
      noOptionsText="无结果"
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      style={{ marginTop }}
      getOptionSelected={(option, val) => option.token === val.token}
      renderTags={(val, getTagProps) => val.map((option, index) => (
        <Chip color="primary" variant="outlined" label={option.name} {...getTagProps({ index })} />
      ))}
      renderOption={(option, { selected }) => (
        <Box
          height="36px"
          width="100%"
          overflow="hidden"
          display="flex"
          alignItems="center"
          style={{ lineHeight: '36px', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {option.token === '0' && (
            <Box width={40} height={30} display="flex" justifyContent="center" alignItems="center">
              <AddIcon />
            </Box>
          )}
          {option.token !== '0' && <Checkbox checked={selected} color="primary" />}
          {option.name}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="笔试考卷，多选时将会随机分配考卷" variant="outlined" />}
    />
  );
};

WaittenMultiplePaperList.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  marginTop: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium']),
};

WaittenMultiplePaperList.defaultProps = {
  name: 'time',
  value: '',
  label: '笔试时间',
  size: 'medium',
  marginTop: 24,
  onChange: () => {},
};

export default WaittenMultiplePaperList;
