import { useEffect } from 'react';
import { useCandidateDispatch } from '../../context';
import { useEvaluationState } from '../context';

export default function useEffectIsEditingWithType() {
  const candidateDispatch = useCandidateDispatch();

  const isEdited = useEvaluationState((state) => state.isEdited);
  const selectedEvaluationDetail = useEvaluationState((state) => state.selectedEvaluationDetail);
  const selectedTemplateDetail = useEvaluationState((state) => state.selectedTemplateDetail);
  const { name: detailName } = selectedEvaluationDetail;
  const { name } = selectedTemplateDetail;
  const editingTitle = name || detailName;

  useEffect(() => {
    candidateDispatch({
      type: 'SET_IS_EDITING_WITH_TYPE',
      payload: { isEditing: isEdited, editingType: 1, editingTitle },
    });
  }, [isEdited, editingTitle]);
}
