import * as types from '../constants/ActionTypes';

export function addReviewCandidate(flowToken, candidateName, candidateStatus) {
  return {
    type: types.ADD_REVIEW_CANDIDATE,
    flowToken,
    candidateName,
    candidateStatus,
  };
}
export function addReviewCandidates(reviewList, reviewListCount, showTableHeaders) {
  return {
    type: types.ADD_REVIEW_CANDIDATES,
    reviewList,
    reviewListCount,
    showTableHeaders,
  };
}
export function dropReviewCandidate(flowToken) {
  return { type: types.DROP_REVIEW_CANDIDATE, flowToken };
}
export function clearReviewCandidate() {
  return { type: types.CLEAR_REVIEW_CANDIDATE };
}
export function updateReviewCandidateSelected(tokens) {
  return { type: types.UPDATE_REVIEW_CANDIDATE_SELECTED, tokens };
}
export function addReviewCandidateSelected(flowToken) {
  return { type: types.ADD_REVIEW_CANDIDATE_SELECTED, flowToken };
}
export function dropReviewCandidateSelected(flowToken) {
  return { type: types.DROP_REVIEW_CANDIDATE_SELECTED, flowToken };
}
export function selectedAllReviewCandidateSelected() {
  return { type: types.SELECTED_ALL_REVIEW_CANDIDATE_SELECTED };
}
export function clearReviewCandidateSelected() {
  return { type: types.CLEAR_REVIEW_CANDIDATE_SELECTED };
}
export function updateReviewListFilter(filter) {
  return { type: types.UPDATE_REVIEW_LIST_FILTER, filter };
}
export function disableReviewCandidateOutbound(applicationToken) {
  return { type: types.DISABLE_REVIEW_CANDIDATE_OUTBOUND, applicationToken };
}
