import { ListItem, withStyles } from '@material-ui/core';

const RoleSwitchListItem = withStyles(() => ({
  root: {
    borderRadius: 4,
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  button: {
    '&:hover': {
      backgroundColor: '#E8F5E9;',
      borderRadius: 4,
      border: '1px solid #4CAF50',
    },
  },
}))(ListItem);

export default RoleSwitchListItem;
