import produce from 'immer';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useReducer } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

const defaultState = {
  count: 0,
  selectedTemplateToken: '',
  selectedTemplateDetail: {},
  selectedEvaluationToken: '',
  selectedEvaluationDetail: {},
  editMode: 0, // 0:浏览, 1:创建, 2:编辑
  isEdited: false,
  formData: {},
  feedbackData: {},
  visibleUsers: [],
  loaded: false,
};

// eslint-disable-next-line consistent-return
const reducer = (state, action) => produce(state, (draft) => {
  switch (action.type) {
    case 'RESET_TO_DEFAULT_STATE': {
      return { ...defaultState, count: state.count + 1 };
    }
    case 'REFRESH_DATA': {
      return { ...state, count: state.count + 1 };
    }
    case 'SET_STATE': {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    case 'SET_SELECTED_EVALUATION_TOKEN': {
      if (state.selectedEvaluationToken === action.payload) break;
      return { ...defaultState, selectedEvaluationToken: action.payload };
    }
    case 'SET_SELECTED_TEMPLATE_TOKEN': {
      if (state.selectedTemplateToken === action.payload) {
        return state;
      }
      return { ...defaultState, selectedTemplateToken: action.payload, editMode: 1 };
    }
    case 'SET_DETAIL_FORM_ITEMS': {
      const { index, value } = action.payload;
      if (state.editMode === 1) {
        draft.selectedTemplateDetail.formItems[index].formAnswer = { answer: value };
      }
      if (state.editMode === 2) {
        draft.selectedEvaluationDetail.formItems[index].formAnswer = { answer: value };
      }
      draft.isEdited = true;
      break;
    }
    case 'BACK_TO_LIST_AND_REFRESH_DATA': {
      return { ...defaultState, count: state.count + 1 };
    }
    case 'RESET_TEPLATE_INPUT_DATA': {
      return {
        ...defaultState,
        isEdited: state.isEdited,
        editMode: state.editMode,
        selectedTemplateToken: state.selectedTemplateToken,
        selectedTemplateDetail: { ...state.selectedTemplateDetail, visibleSwitch: 2, visibleUsers: [] },
        formData: {},
      };
    }
    case 'GO_TO_CREATING': {
      draft.editMode = 1;
      break;
    }
    case 'GO_TO_EDITING': {
      draft.editMode = 2;
      break;
    }
    case 'GO_TO_VIEWING': {
      draft.editMode = 0;
      draft.isEdited = false;
      break;
    }
    case 'GO_TO_VIEWING_AND_REFRESH_DATA': {
      draft.editMode = 0;
      draft.isEdited = false;
      draft.count = state.count + 1;
      break;
    }
    case 'SET_TEMPLATE_DETAIL_VISIBLE_SWITCH': {
      if (state.editMode === 1) {
        draft.selectedTemplateDetail.visibleSwitch = action.payload;
      }
      if (state.editMode === 2) {
        draft.selectedEvaluationDetail.visibleSwitch = action.payload;
      }
      draft.isEdited = true;
      break;
    }
    case 'SET_TEMPLATE_DETAIL_VISIBLE_USERS': {
      if (state.editMode === 1) {
        draft.selectedTemplateDetail.visibleUsers = action.payload;
      }
      if (state.editMode === 2) {
        draft.selectedEvaluationDetail.visibleUsers = action.payload;
      }
      draft.isEdited = true;
      break;
    }
    case 'CANCEL_TEMPLATE_EDIT': {
      return { ...state, count: state.count + 1 };
    }
    case 'SET_TEMPLATE_FORM_DATA_QUESTION': {
      const { token, value } = action.payload;
      const { formData } = state;
      const tmp = formData[token] || {};
      draft.formData[token] = { ...tmp, type: 'question', value };
      draft.isEdited = true;
      break;
    }
    case 'SET_TEMPLATE_FORM_DATA_QUESTION_SCORE': {
      const { token, score } = action.payload;
      const { formData } = state;
      const tmp = formData[token] || {};
      if (score !== 0 && !score) {
        if (tmp.score !== 0 && tmp.score) {
          delete draft.formData[token].score;
        }
      } else {
        draft.formData[token] = { ...tmp, type: 'question', score: Number(score) };
      }
      draft.isEdited = true;
      break;
    }
    case 'SET_TEMPLATE_FORM_DATA_RADIO': {
      const { token, value } = action.payload;
      const { selectedTemplateDetail, selectedEvaluationDetail } = state;
      const detail = isEmpty(selectedTemplateDetail) ? selectedEvaluationDetail : selectedTemplateDetail;
      const item = detail.formItems.find((v) => v.formItemToken === token);
      const alternative = item.formItemOption.alternatives.find((v) => v.key === value);
      const { score } = alternative;
      draft.formData[token] = { type: 'radio', value: [value], score };
      draft.isEdited = true;
      break;
    }
    case 'SET_TEMPLATE_FORM_DATA_CHECKBOX': {
      const { token, name, checked } = action.payload;
      const { selectedTemplateDetail, selectedEvaluationDetail, formData } = state;
      const detail = isEmpty(selectedTemplateDetail) ? selectedEvaluationDetail : selectedTemplateDetail;
      const tmp = (formData[token] || {}).value || [];
      const value = checked ? [...tmp, name] : tmp.filter((v) => v !== name);
      const item = detail.formItems.find((v) => v.formItemToken === token);
      const score = value.reduce((acc, cur) => {
        const alt = item.formItemOption.alternatives.find((v) => v.key === cur);
        return acc + alt.score;
      }, 0);
      draft.formData[token] = { type: 'checkbox', value, score };
      draft.isEdited = true;
      break;
    }
    case 'SET_TEMPLATE_FORM_DATA_FEEDBACK': {
      const { key, value } = action.payload;
      draft.feedbackData[key] = value;
      draft.isEdited = true;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

const StateContext = createContext();
const DispatchContext = createContext();

function EvaluationProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

EvaluationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultSelector = (state) => state;

function useEvaluationState(selector = defaultSelector) {
  const context = useContextSelector(StateContext, selector);
  if (context === undefined) {
    throw new Error('useEvaluationState must be used within a EvaluationProvider');
  }
  return context;
}

function useEvaluationDispatch(selector = defaultSelector) {
  const context = useContextSelector(DispatchContext, selector);
  if (context === undefined) {
    throw new Error('useEvaluationDispatch must be used within a EvaluationProvider');
  }
  return context;
}

export { EvaluationProvider, useEvaluationState, useEvaluationDispatch };
