import UserAvatar from '@components/Avocado/Avatar';
import {
  Box, ListItem, ListItemAvatar, ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const MentionsItem = (props) => {
  const {
    mention, theme, searchValue, isFocused, ...parentProps
  } = props;
  return (
    <Box {...parentProps}>
      <ListItem dense>
        <ListItemAvatar>
          <UserAvatar user={mention} size="small" />
        </ListItemAvatar>
        <ListItemText primary={mention.name} secondary={mention.title} />
      </ListItem>
    </Box>
  );
};

MentionsItem.propTypes = {
  mention: PropTypes.objectOf(PropTypes.any).isRequired,
  theme: PropTypes.objectOf(PropTypes.any).isRequired,
  searchValue: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
};
export default MentionsItem;
