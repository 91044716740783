import { createActions } from 'redux-actions';

export const {
  getPostFilterAvailable,
  setPostFilterAvailable,
  setPostFilterSelect,
  getPostFilterSelect,
  setPostFilterUse,
  setPostFilterReloadUse,
  setPostFilterTagsUse,
  setPostFilterProcess,
  setPostFilterProcessUse,
  setPostFilterUseDefault,
  showPostFilterUseResult,
  changeFilterConditionShowState,
  setPostFilterCandidateExtra,
  setPostFilterCandidateExtraUse,
  setPostFilterCandidateResumeExtra,
  setPostFilterCandidateResumeExtraUse,
  setFirstLoadingState,
  setLoadingState,
  setBlankState,
  setCachedReviewCandidate,
  setShowError,
  setShowInterviewFeedbackFilter,
} = createActions(
  {},
  'GET_POST_FILTER_AVAILABLE',
  'SET_POST_FILTER_AVAILABLE',
  'SET_POST_FILTER_SELECT',
  'GET_POST_FILTER_SELECT',
  'SET_POST_FILTER_USE',
  'SET_POST_FILTER_RELOAD_USE',
  'SET_POST_FILTER_TAGS_USE',
  'SET_POST_FILTER_PROCESS',
  'SET_POST_FILTER_PROCESS_USE',
  'SET_POST_FILTER_USE_DEFAULT',
  'SHOW_POST_FILTER_USE_RESULT',
  'CHANGE_FILTER_CONDITION_SHOW_STATE',
  'SET_POST_FILTER_CANDIDATE_EXTRA',
  'SET_POST_FILTER_CANDIDATE_EXTRA_USE',
  'SET_POST_FILTER_CANDIDATE_RESUME_EXTRA',
  'SET_POST_FILTER_CANDIDATE_RESUME_EXTRA_USE',
  'SET_FIRST_LOADING_STATE',
  'SET_LOADING_STATE',
  'SET_BLANK_STATE',
  'SET_CACHED_REVIEW_CANDIDATE',
  'SET_SHOW_ERROR',
  'SET_SHOW_INTERVIEW_FEEDBACK_FILTER',
);

export default {};
