import { editCandidate } from '@api/Candidate';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import useGlobalToast from '@utils/GlobalToast';
import moment from '@utils/moment';
import * as CONST_FUNCTION from '@utilsold/ConstFunction';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { useCandidateDispatch, useCandidateState } from '../context';
import useDataLoader from '../useDataLoader';
import styles from './CandidateInfo.module.css';
import useStyles from './useStyles';

const CandidateInfoEditMode = () => {
  const classes = useStyles();

  const { loadCandidate } = useDataLoader();

  const state = useCandidateState();
  const dispatch = useCandidateDispatch();

  const { candidateData, isResumeEditing: isEditing } = state;
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const [errorName, setErrorName] = useState(false);
  const [errorMobile, setErrorMobile] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState({});

  const GlobalToast = useGlobalToast();

  useEffect(() => {
    const {
      name, sex, birthday, age, degree, mobile, email,
    } = candidateData;
    setForm({
      name,
      sex,
      birthday,
      age,
      degree,
      mobile,
      email,
    });
    setErrorName(false);
    setErrorMobile(false);
  }, [candidateData, isEdit]);

  function handleChangeEditForm(event) {
    if (!isEditing) dispatch({ type: 'SET_IS_RESUME_EDITING', payload: true });

    if (event.target.name === 'name') {
      const isEmptyValue = event.target.value.trim() === '';
      setErrorName(isEmptyValue);
    }
    if (event.target.name === 'mobile') {
      const isEmptyValue = event.target.value.trim() === '';
      setErrorMobile(isEmptyValue);
    }

    if (event.target.name === 'age') {
      const age = Number(event.target.value) || '';
      const year = moment().format('YYYY') - age;
      const birthday = year + form.birthday.slice(4);

      setForm({
        ...form,
        age,
        birthday,
      });
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
    }
  }

  function handleSubmitEditForm() {
    editCandidate(candidateToken, form).then((result) => {
      if (result.data.code === 0) {
        loadCandidate(candidateToken);
        setIsEdit(false);
        if (isEditing) dispatch({ type: 'SET_IS_RESUME_EDITING', payload: false });
        GlobalToast.success('保存成功');
      } else {
        GlobalToast.error(result.data.message || '保存失败');
      }
    });
  }

  return (
    <div className={styles.editFormWrapper}>
      <Prompt when message="您还未保存当前页面的修改，确定离开吗？" />
      <form className={classes.form}>
        <TextField
          color="primary"
          variant="outlined"
          label="姓名"
          name="name"
          value={form.name}
          onChange={handleChangeEditForm}
          inputProps={{
            maxLength: '15',
          }}
          style={{ width: '12ch' }}
          error={errorName}
          helperText={errorName && '必填项'}
        />
        <TextField
          color="primary"
          variant="outlined"
          label="性别"
          name="sex"
          select
          value={form.sex}
          onChange={handleChangeEditForm}
          style={{ width: '10ch' }}
        >
          {[
            { value: 1, name: '男' },
            { value: 2, name: '女' },
          ].map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          color="primary"
          variant="outlined"
          label="年龄"
          name="age"
          value={form.age}
          onChange={handleChangeEditForm}
          style={{ width: '8ch' }}
        />
        <TextField
          color="primary"
          variant="outlined"
          label="学历"
          name="degree"
          select
          value={form.degree}
          onChange={handleChangeEditForm}
          style={{ width: '14ch' }}
        >
          {Object.keys(CONST_FUNCTION.DegreeObject)
            .sort()
            .filter((i) => ['-1', '0', '99'].indexOf(i) === -1)
            .map((index) => (
              <MenuItem key={index} value={+index}>
                {CONST_FUNCTION.DegreeObject[index]}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          color="primary"
          variant="outlined"
          label="联系方式"
          name="mobile"
          value={form.mobile}
          onChange={handleChangeEditForm}
          style={{ width: '20ch' }}
          error={errorMobile}
          helperText={errorMobile && '必填项'}
        />
        <TextField
          color="primary"
          variant="outlined"
          label="邮箱"
          name="email"
          value={form.email}
          onChange={handleChangeEditForm}
          style={{ width: '20ch' }}
        />
      </form>
      <div className={styles.editFormButton}>
        <Button color="primary" variant="contained" onClick={handleSubmitEditForm} disabled={errorName || errorMobile}>
          保存
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setIsEdit(false);
            if (isEditing) dispatch({ type: 'SET_IS_RESUME_EDITING', payload: false });
          }}
        >
          取消
        </Button>
      </div>
    </div>
  );
};

export default CandidateInfoEditMode;
