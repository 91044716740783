import { exportEvaluation } from '@api/Evaluation';
import { DownloadIcon } from '@avocadoui/icons';
import { Button } from '@material-ui/core';
import useGlobalToast from '@utils/GlobalToast';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { useCandidateState } from '../context';

export default function ExportButton() {
  const GlobalToast = useGlobalToast();
  const candidateState = useCandidateState();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);

  async function handleExport() {
    try {
      const result = await exportEvaluation(applicationToken);
      const filename = `面试评价_${candidateState.candidateData.name}_${candidateState.applicationData.positionName}.xlsx`;
      saveAs(result.data, filename);
      GlobalToast.success('导出成功');
    } catch (error) {
      GlobalToast.error('导出失败');
    }
  }

  return (
    <Button variant="outlined" color="primary" startIcon={<DownloadIcon />} onClick={handleExport}>
      导出面试评价
    </Button>
  );
}
