// eslint-disable-next-line import/prefer-default-export
export function insertFeedbackToFormItems(formItems) {
  const newFormItems = [...formItems];
  const orders = formItems.map((v) => v.formOrder);
  let insertIndex = formItems.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < orders.length; i++) {
    if (orders[i] !== i + 1) {
      insertIndex = i;
      break;
    }
  }
  newFormItems.splice(insertIndex, 0, {
    formItemToken: 'feedbackToken', formTypeId: 999, formTypeKey: '面试反馈', isNecessary: true,
  });
  return newFormItems;
}

export const setEvaluation = (evaluationID, data) => {
  localStorage.setItem(`evaluation-${evaluationID}`, JSON.stringify(data));
  return true;
};

export const getEvaluation = (evaluationID) => JSON.parse(localStorage.getItem(`evaluation-${evaluationID}`));

export const removeEvaluation = (evaluationID) => {
  localStorage.removeItem(`evaluation-${evaluationID}`);
  return true;
};
