/* eslint-disable */
import API_URL from '@config/conf';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import http from '../../../v1/utils/http/http';
import {
  clearCommentFormInfo,
  createCommentForm,

  deleteCommentForm, fetchCommentFormInfo, fetchCommentFormList,









  fetchPositionDetail, fetchPositionList, setCommentFormInfo, setCommentFormList, setCommentFormListSelectedIndex,











  setPositionDetail, setPositionList, updateCommentForm
} from './action';


function* queryFetchCommentForm(data) {
  const { payload } = data;
  if (payload.token === '') {
    yield put(setCommentFormInfo({}));
    return;
  }

  const { token } = payload;
  try {
    const result = yield http.get({ url: `${API_URL}applicationEvaluation/tpl/${token}` });

    const positionTokens = result.data.data.positions.map((item) => item.token);
    const info = {
      name: payload.name,
      positionTokens,
      formItems: result.data.data.formItems,
    };
    yield put(setCommentFormInfo(info));
  } catch (error) {
    console.log(error, '评论模板信息获取失败！');
  }
}

function* queryFetchCommentFormList() {
  try {
    const result = yield http.get({ url: `${API_URL}applicationEvaluation/tpls` });
    yield put(setCommentFormList(result.data));
    const state = yield select();
    const index = state.commentForm.commentFormListSelectedIndex;
    const template = state.commentForm.commentFormList[index];
    const { token, name } = template;

    if (token.length > 10) {
      yield call(queryFetchCommentForm, { payload: { token, name } });
    }
  } catch (error) {
    console.log(error, '评论模板列表获取失败！');
  }
}

function* queryClearCommentFormInfo() {
  const clear = {
    name: '',
    positionTokens: [],
    formItems: [],
  };
  yield put(setCommentFormInfo(clear));
}

function* queryCreateCommentForm(param) {
  const CommentForm = param.payload;
  try {
    const data = {
      name: CommentForm.name,
      formItems: CommentForm.formItems,
      positionTokens: CommentForm.positionTokens,
    };

    const result = yield http.post({ url: `${API_URL}applicationEvaluation/tpl`, data });
    yield call(queryFetchCommentFormList);
    yield call(queryPositionList);
  } catch (error) {
    console.log(error, '评论模板创建失败！');
  }
}

function* queryUpdateCommentForm(param) {
  const { commentFormInfo, token } = param.payload;
  try {
    const result = yield http.post({ url: `${API_URL}applicationEvaluation/tpl/${token}`, data: commentFormInfo });
    yield call(queryFetchCommentFormList);
    yield call(queryPositionList);
    yield call(queryFetchCommentForm, { payload: { token, name: commentFormInfo.name } });
  } catch (error) {
    console.log(error, '评论模板修改失败！');
  }
}

function* queryDeleteCommentForm(param) {
  const tokens = param.payload;
  try {
    const data = { tokens: [tokens] };

    const result = yield http.delete({ url: `${API_URL}applicationEvaluation/tpl`, data });
    yield call(queryFetchCommentFormList);
    yield call(queryPositionList);
    yield put(setCommentFormListSelectedIndex(0));
    if (result.data.code !== 0) {
      yield put({ type: 'SHOW_GLOBAL_TOAST', value: { message: result.data.message, severity: 'warning' } });
    }
  } catch (error) {
    console.log(error, '评论模板修改失败！');
  }
}

function* queryPositionDetail(param) {
  const token = param;
  try {
    const result = yield http.get({ url: `${API_URL}xxxx?${'token'}` });
    yield put(setPositionDetail({ token, data: result.data }));
  } catch (error) {
    console.log(error);
  }
}

function* queryPositionList() {
  try {
    const result = yield http.get({ url: `${API_URL}applicationEvaluation/tpl/positions` });
    //   let result = {data: {data: {list: []}}};
    //   result.data = {
    //     "code": 0,
    //     "msg": "",
    //     "data": {
    //         "list": [
    //             {
    //                 "token": "1232442423",
    //                 "name": "岗位1",
    //                 "hasTpl": true
    //             },
    //             {
    //                 "token": "324234324",
    //                 "name": "岗位2",
    //                 "hasTpl": false
    //             }
    //         ]
    //     }
    // }
    if (result.data) {
      yield put(setPositionList(result.data.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchCommentForm() {
  yield takeEvery(fetchCommentFormList, queryFetchCommentFormList);
  yield takeEvery(fetchCommentFormInfo, queryFetchCommentForm);
  yield takeEvery(clearCommentFormInfo, queryClearCommentFormInfo);
  yield takeEvery(createCommentForm, queryCreateCommentForm);
  yield takeEvery(updateCommentForm, queryUpdateCommentForm);
  yield takeEvery(deleteCommentForm, queryDeleteCommentForm);
  yield takeEvery(fetchPositionList, queryPositionList);
  yield takeEvery(fetchPositionDetail, queryPositionDetail);
}

export default [fork(watchCommentForm)];
