import axios from './Api';

export const getRoles = (data) => axios.request({
  url: '/admin/roles',
  method: 'POST',
  data,
});

export const addRole = (name, desc) => {
  if (!name) return Promise.reject();
  return axios.request({
    url: '/admin/role',
    method: 'POST',
    data: {
      name,
      desc,
    },
  });
};

export const editRole = (token, data) => {
  if (!token) return Promise.reject();
  return axios.request({
    url: `/admin/role/${token}`,
    method: 'POST',
    data,
  });
};

export const deleteRole = (tokens) => {
  if (tokens.length === 0) return Promise.reject();
  return axios.request({
    url: '/admin/role/delete',
    method: 'POST',
    data: {
      tokens,
    },
  });
};

export const addRoleMembers = (userTokens) => {
  if (!userTokens) return Promise.reject();
  return axios.request({
    url: '/admin/role/{$roleToken}/addUsers',
    method: 'POST',
    data: {
      userTokens,
    },
  });
};
export const resetRoleMembers = (roleToken, userTokens) => {
  if (!userTokens || !roleToken) return Promise.reject();
  return axios.request({
    url: `/admin/role/${roleToken}/resetUsers`,
    method: 'POST',
    data: {
      userTokens,
    },
  });
};
export const setRolePermissions = (userTokens) => {
  if (!userTokens) return Promise.reject();
  return axios.request({
    url: '/admin/role/{$roleToken}/permissions',
    method: 'POST',
    data: {
      userTokens,
    },
  });
};

export const resetRolePermissions = (roleToken, data) => {
  if (!roleToken) return Promise.reject();
  return axios.request({
    url: `/admin/role/${roleToken}/resetPermissions`,
    method: 'POST',
    data: {
      positions: data,
    },
  });
};

export const getRolePermissions = (roleToken) => {
  if (!roleToken) return Promise.reject();
  return axios.request({
    url: `/admin/role/${roleToken}`,
    method: 'GET',
  });
};
export const getAdminUsers = (roleToken) => {
  if (!roleToken) return Promise.reject();
  return axios.request({
    url: '/admin/role/users/assign',
    method: 'POST',
    data: { roleToken },
  });
};
export const getAdminUsersAt = () => axios.request({
  url: '/admin/users/at',
  method: 'GET',
});
