import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import VideoPlayback from './VideoPlayback';
import VideoSubtitle from './VideoSubtitle';

function VideoSection({ videoUrl, roomToken, interviewUserToken }) {
  const videoPlaybackRef = useRef();
  const [selectedVideoId, setSelectedVideoId] = useState(0);

  if (videoUrl && videoUrl.length > 0) {
    return (
      <>
        <VideoPlayback
          videoUrl={videoUrl}
          videoPlaybackRef={videoPlaybackRef}
          selectedVideoId={selectedVideoId}
          setSelectedVideoId={setSelectedVideoId}
        />
        {roomToken && interviewUserToken && (
          <VideoSubtitle
            roomToken={roomToken}
            interviewUserToken={interviewUserToken}
            videoPlaybackRef={videoPlaybackRef}
            selectedVideoId={selectedVideoId}
            setSelectedVideoId={setSelectedVideoId}
          />
        )}
      </>
    );
  }

  return null;
}

VideoSection.propTypes = {
  videoUrl: PropTypes.arrayOf(PropTypes.string).isRequired,
  roomToken: PropTypes.string.isRequired,
  interviewUserToken: PropTypes.string.isRequired,
};

export default VideoSection;
