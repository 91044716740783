import axios from './Api';

export const getCommonTags = () => axios.request({
  url: '/tag/commonTags',
  method: 'GET',
});

export const getAISetting = () => axios.request({
  url: '/tag/aiSetting',
  method: 'GET',
});

export const postAISetting = (data) => axios.request({
  url: '/tag/aiSetting',
  method: 'POST',
  data,
});

export const getNearTags = (name) => axios.request({
  url: `/tags?category=2&name=${name || ''}`,
  method: 'GET',
});
