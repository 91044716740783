export const INTERVIEW_DURATIONS = [
  {
    token: `${900}`,
    name: '15分钟',
  },
  {
    token: `${900 * 2}`,
    name: '30分钟',
  },
  {
    token: `${900 * 3}`,
    name: '45分钟',
  },
  {
    token: `${900 * 4}`,
    name: '60分钟',
  },
  {
    token: `${900 * 5}`,
    name: '75分钟',
  },
  {
    token: `${900 * 6}`,
    name: '90分钟',
  },
  {
    token: `${900 * 7}`,
    name: '105分钟',
  },
  {
    token: `${900 * 8}`,
    name: '120分钟',
  },
  {
    token: `${900 * 9}`,
    name: '135分钟',
  },
  {
    token: `${900 * 10}`,
    name: '150分钟',
  },
  {
    token: `${900 * 11}`,
    name: '165分钟',
  },
  {
    token: `${900 * 12}`,
    name: '180分钟',
  },
  {
    token: `${900 * 13}`,
    name: '195分钟',
  },
  {
    token: `${900 * 14}`,
    name: '210分钟',
  },
  {
    token: `${900 * 15}`,
    name: '225分钟',
  },
  {
    token: `${900 * 16}`,
    name: '240分钟',
  },
];

export const INTERVIEW_DEADLINES = [
  {
    token: 60 * 60 * 24,
    name: '开始前24小时',
  },
  {
    token: 60 * 60 * 12,
    name: '开始前12小时',
  },
  {
    token: 60 * 60 * 6,
    name: '开始前6小时',
  },
  {
    token: 60 * 60 * 3,
    name: '开始前3小时',
  },
  {
    token: 60 * 60 * 2,
    name: '开始前2小时',
  },
  {
    token: 60 * 60 * 1,
    name: '开始前1小时',
  },
  {
    token: 60 * 30,
    name: '开始前半小时',
  },
];

export const INTERVIEW_TYPES = [
  {
    token: 1,
    name: '线下面试',
  },
  {
    token: 2,
    name: '实时视频',
  },
  {
    token: 3,
    name: '培训',
  },
];
export const INTERVIEW_TYPES_OBJECT = {
  1: '线下面试',
  2: '实时视频',
  3: '培训',
};
export const INTERVIEW_TYPES_ARRAY = [1, 2, 3];

export const INTERVIEW_TYPEMODE = [
  {
    token: 1,
    name: '方便面实时视频',
  },
  {
    token: 2,
    name: '自定义',
  },
];
export const INTERVIEW_TYPEMODE_OBJECT = {
  1: '方便面实时视频',
  2: '自定义',
};
export const INTERVIEW_TYPEMODE_ARRAY = [1, 2];

export const INTERVIEW_STATUS = {
  3: '未开始',
  1: '进行中',
  2: '已取消',
  4: '已结束',
};
export const INTERVIEW_STATUS_ARRAY = [3, 1, 2, 4];

export const INTERVIEW_APPLICATION_STATUS = {
  '-1': '未安排',
  1: '未接受',
  2: '已接受',
  3: '已签到',
  4: '已取消',
  5: '已拒绝',
  6: '已结束',
  7: '申请修改时间',
};

export const INTERVIEW_HR_STATUS = {
  1: '初始化',
  2: 'HR取消', // 已取消
  3: '超过4小时未接受', // 已拒绝
};

export const EVENT_THEMES = {
  1: {
    backgroundColor: '#EDF2FF',
    borderColor: '#4B84FF',
    textColor: '#4B84FF',
    backgroundColor2: '#e9eefe',
  },
  2: {
    backgroundColor: '#E1F4F4',
    borderColor: '#6BC6C6',
    textColor: '#6BC6C6',
    backgroundColor2: '#EFFFFF',
  },
  3: {
    backgroundColor: '#EDE3FF',
    borderColor: '#834DEA',
    textColor: '#834DEA',
    backgroundColor2: '#F2ECFF',
  },
};

export const MAX_ALLOWED_DURATION = 240 * 60;

export const LOCAL_CHECKED_MESSAGES = {
  1: '已清除不满足所选时长的时间段',
};

export const INTERVIEW_NAME_MAX_LENGTH = 15;
export const REMARK_MAX_LENGTH = 7;
