import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getSelectionCustomInlineStyle } from 'draftjs-utils';
import isEmpty from 'lodash';
import { ReactComponent as FontSizeIcon } from '../../assets/img/icons/notify/email/FontSize.svg';

const FONT_SIZES = [
  { label: '小', style: 'small', fontSize: '12' },
  { label: '标准', style: 'normal', fontSize: '14' },
  { label: '大', style: 'large', fontSize: '16' },
  { label: '较大', style: 'extraLarge', fontSize: '18' },
  { label: '特大', style: 'largest', fontSize: '22' },
];

const ELEditorToolbarFontSize = (props) => {
  const { toggleInlineStyle, editorState } = props;
  const [currentFontSize, setCurrentFontSize] = useState('14');
  const currentFontSizeStyle = !isEmpty(editorState) && getSelectionCustomInlineStyle(editorState, ['FONTSIZE']).FONTSIZE;

  useEffect(() => {
    const fontSize = (currentFontSizeStyle && currentFontSizeStyle.replace('fontsize-', '')) || '14';
    if (fontSize) {
      setCurrentFontSize(fontSize);
    }
  }, [currentFontSizeStyle]);
  return (
    <Box>
      <PopupState variant="popover" disableAutoFocus>
        {(popupState) => (
          <>
            <IconButton {...bindTrigger(popupState)}>
              <FontSizeIcon className="MuiSvgIcon-root" />
            </IconButton>
            <Menu
              {...bindMenu(popupState)}
              variant="menu"
              getContentAnchorEl={null}
              autoFocus={false}
              MenuListProps={{
                disableAutoFocus: true,
                disableAutoFocusItem: true,
              }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              {FONT_SIZES.map((v) => (
                <MenuItem
                  disableAutoFocus
                  selected={v.fontSize === currentFontSize}
                  onClick={() => {
                    toggleInlineStyle(v.fontSize);
                  }}
                  style={{ fontSize: v.fontSize }}
                >
                  {v.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </PopupState>
    </Box>
  );
};

const fontSizeStyleMap = {
  small: {
    fontSize: '12px',
  },
  normal: {
    fontSize: '14px',
  },
  large: {
    fontSize: '16px',
  },
  extraLarge: {
    fontSize: '18px',
  },
  largest: {
    fontSize: '22px',
  },
};

ELEditorToolbarFontSize.propTypes = {
  editorState: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleInlineStyle: PropTypes.objectOf(PropTypes.any),
};

ELEditorToolbarFontSize.defaultProps = {
  toggleInlineStyle: {},
};

export { fontSizeStyleMap };
export default ELEditorToolbarFontSize;
