import { Box, Typography } from '@material-ui/core';
import { useCandidateState } from '@module/CandidateModal/context';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DegreeObject } from '../../v1/constants/ConstFunction';
import ResumeSectoinBox from './ResumeSectoinBox';

const ResumeStandardPreview = ({ hidden, children }) => {
  const { candidateData: candidate = {} } = useCandidateState();
  if (isEmpty(candidate)) {
    return <></>;
  }
  return (
    <Box style={{ backgroundColor: 'white' }} width="100%" height="100%" hidden={hidden} pb={2}>
      {children}
      {candidate?.candidateResume?.recentCompany && (
        <ResumeSectoinBox title="个人信息" mt={2}>
          <Typography variant="caption" component="p" style={{ marginTop: 12 }}>
            最近公司
          </Typography>
          <Typography variant="caption" component="p" color="textSecondary">
            {candidate.candidateResume.recentCompany}
          </Typography>
        </ResumeSectoinBox>
      )}
      {(!!candidate?.candidateResume?.exceptedCity
        || !!candidate?.candidateResume?.currentSalary
        || !!candidate?.candidateResume?.exceptedSalaryFrom
        || !!candidate?.candidateResume?.exceptedSalaryTo) && (
        <ResumeSectoinBox title="求职意向" mt={2}>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={1.5} alignItems="center">
            {!!candidate?.candidateResume?.currentSalary && (
              <Box flexGrow={1}>
                <Typography variant="caption" component="p">
                  当前薪资
                </Typography>
                <Typography variant="caption" component="p" color="textSecondary">
                  {candidate.candidateResume.currentSalary}
                </Typography>
              </Box>
            )}
            {(!!candidate?.candidateResume?.exceptedSalaryFrom || !!candidate?.candidateResume?.exceptedSalaryTo) && (
              <Box flexGrow={1}>
                <Typography variant="caption" component="p">
                  期望薪资
                </Typography>
                <Typography variant="caption" component="p" color="textSecondary">
                  {!!candidate.candidateResume.exceptedSalaryFrom && candidate.candidateResume.exceptedSalaryFrom}
                  {!!candidate.candidateResume.exceptedSalaryFrom
                    && !!candidate.candidateResume.exceptedSalaryTo
                    && ' - '}
                  {!!candidate.candidateResume.exceptedSalaryTo && candidate.candidateResume.exceptedSalaryTo}
                </Typography>
              </Box>
            )}
            {!!candidate?.candidateResume?.exceptedCity && (
              <Box flexGrow={1}>
                <Typography variant="caption" component="p">
                  期望城市
                </Typography>
                <Typography variant="caption" component="p" color="textSecondary">
                  {candidate.candidateResume.exceptedCity}
                </Typography>
              </Box>
            )}
          </Box>
        </ResumeSectoinBox>
      )}
      {candidate.workExperience.length > 0 && (
        <ResumeSectoinBox title="工作经历" mt={2}>
          {candidate.workExperience.map((v) => (
            <Box mt={1.5}>
              <Typography variant="body2" style={{ fontWeight: 500 }}>
                {v.position}
              </Typography>
              <Typography variant="caption">{v.company}</Typography>
              {v.startTime > 0 && v.endTime > 0 && (
                <Typography variant="caption" color="textSecondary" component="p">
                  {moment(parseInt(v.startTime * 1000, 10)).format('YYYY年MM月')}
                  {v.employStatus ? '至今' : ` - ${moment(parseInt(v.endTime * 1000, 10)).format('YYYY年MM月')}`}
                </Typography>
              )}
              <Typography variant="caption" component="p" style={{ marginTop: 4, whiteSpace: 'pre-line' }}>
                {v.workContent}
              </Typography>
            </Box>
          ))}
        </ResumeSectoinBox>
      )}
      {candidate.education.length > 0 && (
        <ResumeSectoinBox title="教育背景" mt={2}>
          {candidate.education.map((v) => (
            <Box mt={1.5}>
              <Typography variant="body2" style={{ fontWeight: 500 }}>
                {v.schoolName}
              </Typography>
              <Typography variant="caption" component="p">
                {v.major}
                {v.major && !!v.degree && v.degree !== '0' ? ',' : ''}
                {!!v.degree && v.degree !== '0' && `${DegreeObject[v.degree]}`}
              </Typography>
              {v.startTime > 0 && v.endTime > 0 && (
                <Typography variant="caption" component="p" color="textSecondary">
                  {`${moment(parseInt(v.startTime * 1000, 10)).format('YYYY年MM月')} - ${moment(
                    parseInt(v.endTime * 1000, 10),
                  ).format('YYYY年MM月')}`}
                </Typography>
              )}
            </Box>
          ))}
        </ResumeSectoinBox>
      )}
      {candidate.languageAbility.length > 0 && (
        <ResumeSectoinBox title="语言能力" mt={1.5}>
          <Box mt={1.5}>
            <Typography variant="caption" component="p">
              语言类型
            </Typography>
            <Typography variant="caption" component="p" color="textSecondary">
              {candidate.languageAbility.map((v) => v.name).join(',')}
            </Typography>
          </Box>
        </ResumeSectoinBox>
      )}
      {candidate?.candidateResume?.introduction && (
        <ResumeSectoinBox title=" 自我描述" mt={2}>
          <Typography variant="caption" component="p" style={{ marginTop: 12 }}>
            自我描述
          </Typography>
          <Typography variant="caption" component="p" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
            {candidate.candidateResume.introduction}
          </Typography>
        </ResumeSectoinBox>
      )}
    </Box>
  );
};

ResumeStandardPreview.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.element,
};

ResumeStandardPreview.defaultProps = {
  hidden: false,
  children: null,
};

export default ResumeStandardPreview;
