import AgoraRtmProvider from '@utils/AgoraRtmProvider';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KickoutDialog from './KickoutDialog';

const KickoutNotify = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch({ type: 'FETCH_AT_MESSAGE_LIST' });
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleConfrim = useCallback(() => {
    localStorage.removeItem('id_token');
    dispatch({
      type: 'USER_LOGOUT',
    });
    history.push('/login');
  }, []);

  return (
    <AgoraRtmProvider>
      {(open) => <KickoutDialog open={open} onConfrim={handleConfrim} />}
    </AgoraRtmProvider>
  );
};

export default KickoutNotify;
