import PropTypes from 'prop-types';
import InterviewerUI from '../../v1/interviewerLogin/interviewerUI';

function InterviewerLogin({ history, match, location }) {
  const { from } = location.state || { from: { pathname: '/' } };

  const params = new URLSearchParams(location.search);

  const toCandidateDesc = () => {
    history.replace(from);
  };

  return (
    <InterviewerUI
      positionToken={match.params.interviewToken.replace(/\?.*$/, '')}
      toCandidateDesc={toCandidateDesc}
      loginType={params.has('type') ? Number.parseInt(params.get('type'), 10) : 2}
    />
  );
}

InterviewerLogin.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      interviewToken: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default InterviewerLogin;
