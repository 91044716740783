import Typography from '@components/Avocado/Typography';
import PropTypes from 'prop-types';

function StepTitle({
  children, isDone = false, isActive = false, isFailed = false, isCanceled = false,
}) {
  if (isFailed) {
    const color = isCanceled ? 'text.disabled' : 'error.main';
    return (
      <Typography variant="body2" color={color}>
        {children}
      </Typography>
    );
  }

  if (isDone || isActive) {
    const color = isCanceled ? 'text.disabled' : 'primary.main';
    return (
      <Typography variant="body2" color={color}>
        {children}
      </Typography>
    );
  }

  return (
    <Typography variant="body2" color="text.disabled">
      {children}
    </Typography>
  );
}

StepTitle.propTypes = {
  children: PropTypes.node.isRequired,
  isDone: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool,
  isCanceled: PropTypes.bool,
};

StepTitle.defaultProps = {
  isFailed: false,
  isCanceled: false,
};

export default StepTitle;
