import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './CandidateFormWithAnswer.module.css';

function ItemHeader({ children, isNecessary = false, item = {} }) {
  return (
    <div className={isNecessary ? `${styles.header} ${styles.isNecessary}` : styles.header}>
      {children}
      {item.isHiddenForCandidate && (
        <Typography component="span" display="inline" variant="body2" color="textSecondary" style={{ marginLeft: 4, color: 'rgba(0,0,0,0.26)' }}>
          候选人不可见
        </Typography>
      )}
    </div>
  );
}

ItemHeader.propTypes = {
  children: PropTypes.node.isRequired,
  isNecessary: PropTypes.bool.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ItemHeader;
