import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import panoTheme from './theme';

export default function PanoTheme({ children }) {
  return (
    <MuiThemeProvider theme={panoTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

PanoTheme.propTypes = {
  children: PropTypes.node.isRequired,
};
