import { useCandidateState } from '../context';
import styles from './CandidateResult.module.css';

function NotFinishInterview() {
  const { applicationFlowData } = useCandidateState();
  if (applicationFlowData && applicationFlowData.status === 3) {
    return <div className={styles.notFinish}>该面试者尚未回答完所有题目，建议给候选人发送提醒</div>;
  }
  return null;
}

export default NotFinishInterview;
