import * as ACTIONS from '../constants/ActionTypes';

const resumestate = {
  initialResume: {
    isShowResumeCard: false,
    isOpenCard: false,
    resumeArray: [],
    onChangeInput: null,
    updateCandidate: null,
  },
};

export default function resumeState(state = resumestate, action) {
  switch (action.type) {
    case ACTIONS.RESUME_TYPE:
      return {
        initialResume: {
          ...state.initialResume,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
