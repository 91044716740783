import { useEffect, useCallback } from 'react';

export default (isPrenvent) => {
  const preventReload = useCallback((event) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.returnValue = '您还未保存当前页面的修改，确定离开吗？';
  }, []);

  useEffect(() => {
    if (isPrenvent) {
      window.addEventListener('beforeunload', preventReload);
    }
    return () => {
      if (isPrenvent) {
        window.removeEventListener('beforeunload', preventReload);
      }
    };
  }, [isPrenvent, preventReload]);
};
