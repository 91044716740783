import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export default () => {
  const { enqueueSnackbar } = useSnackbar();

  const error = useCallback((message) => {
    enqueueSnackbar(message, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, []);
  const success = useCallback((message) => {
    enqueueSnackbar(message, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, []);
  const info = useCallback((message) => {
    enqueueSnackbar(message, {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, []);
  const warning = useCallback((message) => {
    enqueueSnackbar(message, {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, []);

  return {
    info, success, warning, error,
  };
};
