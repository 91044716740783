import * as CONST_FUNCTION from '@utilsold/ConstFunction';
import PropTypes from 'prop-types';
import { useCandidateState } from '../context';
import AttachmentResume from './AttachmentResume';
import styles from './CandidateResume.module.css';
import FoundNoResume from './FoundNoResume';
import ResumeTools from './ResumeTools';

function getPositionUrl(resumeInfo) {
  const url = resumeInfo?.content;
  if (!url) {
    return '';
  }
  const prefix = url.slice(-4);
  if (prefix === 'docx' || prefix === '.doc' || prefix === '.DOCX' || prefix === '.DOC') {
    return `https://view.officeapps.live.com/op/view.aspx?src=${url}`;
  }
  if (prefix === '.pdf' || prefix === '.PDF') {
    return url;
  }
  return url;
}

function CandidateOriginalResume({ hidden, resumeURL, hidenResumeReplace = false }) {
  const { candidateData = {} } = useCandidateState();
  if (hidden) return <></>;
  if (resumeURL) {
    return (
      <div className={styles.resume}>
        <iframe className={styles.iframe} title="简历" src={getPositionUrl({ content: resumeURL })} />
        <ResumeTools hidenResumeReplace={hidenResumeReplace} />
      </div>
    );
  }
  if (!candidateData.resumeInfo) {
    return <FoundNoResume />;
  }

  if (candidateData.resumeInfo && candidateData.resumeInfo.type === 2) {
    const { content } = candidateData.resumeInfo;
    const educations = content.educations.filter((edu) => !(edu.school === '' && edu.major === ''));
    const works = content.works.filter(
      (work) => !(work.position === '' && work.company === '' && work.workContent === ''),
    );
    return (
      <>
        <AttachmentResume />
        <div className={styles.miniResumeWrap}>
          <div className={styles.content}>
            <div className={styles.item}>
              <div className={styles.title}>基本信息</div>
              <div className={styles.onewrap}>
                <div className={styles.one}>
                  <div className={styles.exwrap}>
                    <div className={styles.extitle}>姓名</div>
                    <div className={styles.exname}>{candidateData.name}</div>
                  </div>
                  <div className={styles.exwrap}>
                    <div className={styles.extitle}>性别</div>
                    <div className={styles.exname}>{CONST_FUNCTION.GetSexDesc(candidateData.sex)}</div>
                  </div>
                </div>
                <div className={styles.one}>
                  <div className={styles.exwrap}>
                    <div className={styles.extitle}>出生年月</div>
                    <div className={styles.exname}>
                      {candidateData.birthday !== '' ? `${candidateData.birthday.replace('-', '年')}月` : ''}
                    </div>
                  </div>
                  <div className={`${styles.exwrap} ${styles.active}`}>
                    <div className={styles.extitle}>手机号</div>
                    <div className={styles.exname}>{candidateData.mobile}</div>
                  </div>
                </div>
                <div className={styles.imgwrap}>
                  <img src={content.photos[0] ? content.photos[0].url : ''} alt="" className={styles.img} />
                </div>
              </div>
              <div className={styles.fill} />
            </div>

            <div className={styles.item}>
              <div className={styles.title}>{educations.length > 0 ? '教育经历' : ''}</div>
              {educations.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className={styles.li}>
                  <div className={styles.edu}>{item.school}</div>
                  <div className={styles.detail}>
                    <div className={`${styles.type} ${styles.common}`}>{item.major}</div>
                    {item.degree !== 0 && (
                      <div className={`${styles.degree} ${styles.common}`}>
                        {CONST_FUNCTION.GetDegreeDesc(item.degree)}
                      </div>
                    )}
                    <div className={`${styles.time} ${styles.common}`}>
                      {CONST_FUNCTION.timestampToDateNoTimeAndDay(item.startTime)} ~{' '}
                      {CONST_FUNCTION.timestampToDateNoTimeAndDay(item.endTime)}
                    </div>
                  </div>
                </div>
              ))}
              <div className={styles.fill} />
            </div>
            {works.length > 0 && (
              <div className={styles.item}>
                <div className={styles.title}>工作经历</div>
                {works.map((item) => (
                  <div className={styles.li}>
                    <div className={styles.edu}>{item.position}</div>
                    <div className={styles.detail}>
                      <div className={`${styles.type} ${styles.common}`}>{item.company}</div>
                      <div className={`${styles.degree} ${styles.common}`}>
                        {CONST_FUNCTION.timestampToDateNoTimeAndDay(item.startTime)} ~{' '}
                        {CONST_FUNCTION.timestampToDateNoTimeAndDay(item.endTime)}
                      </div>
                    </div>
                    <div className={styles.word}>{item.workContent}</div>
                  </div>
                ))}
                <div className={styles.fill} />
              </div>
            )}

            {content.complement !== '' && (
              <div className={styles.item}>
                <div className={styles.title}>加分项</div>

                <div className={styles.li}>
                  <div className={styles.word}>{content.complement}</div>
                </div>

                <div className={styles.fill} />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  const img = candidateData?.resumeInfo?.img;
  if (img) {
    return (
      <div className={styles.resume}>
        <img className={styles.img} alt="简历" src={img} />
        <ResumeTools hidenResumeReplace={hidenResumeReplace} />
      </div>
    );
  }
  return (
    <div className={styles.resume}>
      <iframe className={styles.iframe} title="简历" src={getPositionUrl(candidateData.resumeInfo)} />
      <ResumeTools hidenResumeReplace={hidenResumeReplace} />
    </div>
  );
}

CandidateOriginalResume.propTypes = {
  hidden: PropTypes.bool,
  resumeURL: PropTypes.string,
  hidenResumeReplace: PropTypes.bool,
};
CandidateOriginalResume.defaultProps = {
  hidden: false,
  resumeURL: '',
  hidenResumeReplace: false,
};

export default CandidateOriginalResume;
