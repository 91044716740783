import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import { getSelectionCustomInlineStyle } from 'draftjs-utils';
import { ReactComponent as BackgroundColorIcon } from '../../assets/img/icons/notify/email/BackgroundColor.svg';

const COLORS = [
  { label: 'backgroundColor1', style: '#000000' },
  { label: 'backgroundColor2', style: '#E4352E' },
  { label: 'backgroundColor3', style: '#FF9900' },
  { label: 'backgroundColor4', style: '#FFD900' },
  { label: 'backgroundColor5', style: '#0F8024' },
  { label: 'backgroundColor6', style: '#39D9F0' },
  { label: 'backgroundColor7', style: '#308FFA' },
  { label: 'backgroundColor8', style: '#AA17D0' },

  { label: 'backgroundColor9', style: '#F5F5F5' },
  { label: 'backgroundColor10', style: '#FCDBD6' },
  { label: 'backgroundColor11', style: '#FDEAD0' },
  { label: 'backgroundColor12', style: '#FEF0CE' },
  { label: 'backgroundColor13', style: '#D4E9D5' },
  { label: 'backgroundColor14', style: '#DEF3F3' },
  { label: 'backgroundColor15', style: '#CEDFEF' },
  { label: 'backgroundColor16', style: '#DFDBEB' },

  { label: 'backgroundColor17', style: '#D0D0D0' },
  { label: 'backgroundColor18', style: '#EE837C' },
  { label: 'backgroundColor19', style: '#F8C387' },
  { label: 'backgroundColor20', style: '#FFDA5C' },
  { label: 'backgroundColor21', style: '#9ABD9C' },
  { label: 'backgroundColor22', style: '#82CCD1' },
  { label: 'backgroundColor23', style: '#8AB0CE' },
  { label: 'backgroundColor24', style: '#9389B1' },

  { label: 'backgroundColor25', style: '#999896' },
  { label: 'backgroundColor26', style: '#D41427' },
  { label: 'backgroundColor27', style: '#CE760D' },
  { label: 'backgroundColor28', style: '#8D644A' },
  { label: 'backgroundColor29', style: '#557B5C' },
  { label: 'backgroundColor30', style: '#03A2B0' },
  { label: 'backgroundColor31', style: '#3677A6' },
  { label: 'backgroundColor32', style: '#765C82' },

  { label: 'backgroundColor33', style: '#666564' },
  { label: 'backgroundColor34', style: '#A91914' },
  { label: 'backgroundColor35', style: '#874602' },
  { label: 'backgroundColor36', style: '#553725' },
  { label: 'backgroundColor37', style: '#00562E' },
  { label: 'backgroundColor38', style: '#00767A' },
  { label: 'backgroundColor39', style: '#184E77' },
  { label: 'backgroundColor40', style: '#530D6F' },
];

const ELEditorToolbarColor = (props) => {
  const { toggleInlineStyle, editorState } = props;
  const [currentColor, setCurrentColor] = useState('rgba(0,0,0,0.56)');
  const currentColorStyle = getSelectionCustomInlineStyle(editorState, ['BGCOLOR']).BGCOLOR;
  useEffect(() => {
    const cColor = (currentColorStyle && currentColorStyle.replace('bgcolor-', '')) || 'rgba(0,0,0,0.56)';
    if (cColor) {
      setCurrentColor(cColor);
    }
  }, [currentColorStyle]);
  return (
    <Box>
      <PopupState variant="popover" disableAutoFocus>
        {(popupState) => (
          <>
            <IconButton {...bindTrigger(popupState)}>
              <BackgroundColorIcon className="MuiSvgIcon-root" style={{ color: currentColor }} />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              // getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <Box display="flex" flexWrap="wrap" width={176} height={115} p={1.5}>
                {COLORS.map((type) => (
                  <Box
                    style={{ background: type.style, cursor: 'pointer' }}
                    onClick={() => {
                      if (currentColor === type.style) {
                        toggleInlineStyle('#FFFFFF');
                        setCurrentColor('#FFFFFF');
                      } else {
                        toggleInlineStyle(type.style);
                        setCurrentColor(type.style);
                      }
                    }}
                    width="15px"
                    height="15px"
                    mr={0.5}
                    mt={0.5}
                    flexShrink={0}
                  />
                ))}
              </Box>
            </Popover>
          </>
        )}
      </PopupState>
    </Box>
  );
};

const backgroundColorStyleMap = {
  backgroundColor1: {
    backgroundColor: '#000000',
  },
  backgroundColor2: {
    backgroundColor: '#E4352E',
  },
  backgroundColor3: {
    backgroundColor: '#FF9900',
  },
  backgroundColor4: {
    backgroundColor: '#FFD900',
  },
  backgroundColor5: {
    backgroundColor: '#0F8024',
  },
  backgroundColor6: {
    backgroundColor: '#39D9F0',
  },
  backgroundColor7: {
    backgroundColor: '#308FFA',
  },
  backgroundColor8: {
    backgroundColor: '#AA17D0',
  },
  backgroundColor9: {
    backgroundColor: '#F5F5F5',
  },
  backgroundColor10: {
    backgroundColor: '#FCDBD6',
  },
  backgroundColor11: {
    backgroundColor: '#FDEAD0',
  },
  backgroundColor12: {
    backgroundColor: '#FEF0CE',
  },
  backgroundColor13: {
    backgroundColor: '#D4E9D5',
  },
  backgroundColor14: {
    backgroundColor: '#DEF3F3',
  },
  backgroundColor15: {
    backgroundColor: '#CEDFEF',
  },
  backgroundColor16: {
    backgroundColor: '#DFDBEB',
  },
  backgroundColor17: {
    backgroundColor: '#D0D0D0',
  },
  backgroundColor18: {
    backgroundColor: '#EE837C',
  },
  backgroundColor19: {
    backgroundColor: '#F8C387',
  },
  backgroundColor20: {
    backgroundColor: '#FFDA5C',
  },
  backgroundColor21: {
    backgroundColor: '#9ABD9C',
  },
  backgroundColor22: {
    backgroundColor: '#82CCD1',
  },
  backgroundColor23: {
    backgroundColor: '#8AB0CE',
  },
  backgroundColor24: {
    backgroundColor: '#9389B1',
  },
  backgroundColor25: {
    backgroundColor: '#999896',
  },
  backgroundColor26: {
    backgroundColor: '#D41427',
  },
  backgroundColor27: {
    backgroundColor: '#CE760D',
  },
  backgroundColor28: {
    backgroundColor: '#8D644A',
  },
  backgroundColor29: {
    backgroundColor: '#557B5C',
  },
  backgroundColor30: {
    backgroundColor: '#03A2B0',
  },
  backgroundColor31: {
    backgroundColor: '#3677A6',
  },
  backgroundColor32: {
    backgroundColor: '#765C82',
  },
  backgroundColor33: {
    backgroundColor: '#666564',
  },
  backgroundColor34: {
    backgroundColor: '#A91914',
  },
  backgroundColor35: {
    backgroundColor: '#874602',
  },
  backgroundColor36: {
    backgroundColor: '#553725',
  },
  backgroundColor37: {
    backgroundColor: '#00562E',
  },
  backgroundColor38: {
    backgroundColor: '#00767A',
  },
  backgroundColor39: {
    backgroundColor: '#184E77',
  },
  backgroundColor40: {
    backgroundColor: '#530D6F',
  },
};

ELEditorToolbarColor.propTypes = {
  editorState: PropTypes.objectOf(PropTypes.any),
  toggleInlineStyle: PropTypes.objectOf(PropTypes.any),
};

ELEditorToolbarColor.defaultProps = {
  editorState: {},
  toggleInlineStyle: {},
};

export { backgroundColorStyleMap };

export default ELEditorToolbarColor;
