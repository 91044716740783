/* eslint-disable */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './styles.css';

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

function getLinkComponent(config) {
  const { showOpenOptionOnHover, onlyStyle = false } = config;
  return class Link extends Component {
    static propTypes = {
      entityKey: PropTypes.string.isRequired,
      children: PropTypes.array,
      contentState: PropTypes.object,
    };

    state = {
      showPopOver: false,
    };

    openLink = () => {
      const { entityKey, contentState } = this.props;
      const { url } = contentState.getEntity(entityKey).getData();
      const linkTab = window.open(url, 'blank'); // eslint-disable-line no-undef
      // linkTab can be null when the window failed to open.
      if (linkTab) {
        linkTab.focus();
      }
    };

    toggleShowPopOver = () => {
      const showPopOver = !this.state.showPopOver;
      this.setState({
        showPopOver,
      });
    };

    render() {
      const { children, entityKey, contentState } = this.props;
      const { url, targetOption } = contentState.getEntity(entityKey).getData();
      const { showPopOver } = this.state;
      return (
        <span
          className="rdw-link-decorator-wrapper"
          onMouseEnter={this.toggleShowPopOver}
          onMouseLeave={this.toggleShowPopOver}
        >
          {!onlyStyle && (
            <a href={url} target={targetOption} style={{ cursor: 'pointer' }} onClick={this.openLink}>
              {children}
            </a>
          )}
          {onlyStyle && (
            <span target={targetOption} style={{ color: '#551a8b', textDecoration: 'underline' }}>
              {children}
            </span>
          )}
        </span>
      );
    }
  };
}

export default (config) => ({
  strategy: findLinkEntities,
  component: getLinkComponent(config),
});
