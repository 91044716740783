import {
  fork, put, select, takeLatest,
} from 'redux-saga/effects';
import { getRoles } from '../../../api/Admin';
import { initRoleModule, setRoles, setRolesPage } from './action';

function* getApiRoles() {
  const state = yield select();
  const { filters = { page: 1, pageSize: 30 } } = state.role;
  const result = yield getRoles(filters);
  if (result.data.code === 0) {
    yield put(setRoles({ roles: result.data?.data?.list, total: result.data?.data?.total }));
  }
}

function* watch() {
  yield takeLatest(initRoleModule, getApiRoles);
  yield takeLatest(setRolesPage, getApiRoles);
}

export default [fork(watch)];
