const RosePieColors = [
  ['rgba(78, 163, 253, 0.2)', 'rgba(78, 163, 253, 0.4)', 'rgba(78, 163, 253, 0.6)', 'rgba(78, 163, 253, 1)'],
  ['rgba(82, 206, 220, 0.2)', 'rgba(82, 206, 220, 0.4)', 'rgba(82, 206, 220, 0.6)', 'rgba(82, 206, 220, 1)'],
  ['rgba(196, 134, 252, 0.2)', 'rgba(196, 134, 252, 0.4)', 'rgba(196, 134, 252, 0.6)', 'rgba(196, 134, 252, 1)'],
  ['rgba(139, 151, 252, 0.2)', 'rgba(139, 151, 252, 0.4)', 'rgba(139, 151, 252, 0.6)', 'rgba(139, 151, 252, 1)'],
  ['rgba(255, 149, 239, 0.2)', 'rgba(255, 149, 239, 0.4)', 'rgba(255, 149, 239, 0.6)', 'rgba(255, 149, 239, 1)'],
  ['rgba(255, 181, 129, 0.2)', 'rgba(255, 181, 129, 0.4)', 'rgba(255, 181, 129, 0.6)', 'rgba(255, 181, 129, 1)'],
  ['rgba(253, 210, 87, 0.2)', 'rgba(253, 210, 87, 0.4)', 'rgba(253, 210, 87, 0.6)', 'rgba(253, 210, 87, 1)'],
  ['rgba(63, 214, 245, 0.2)', 'rgba(63, 214, 245, 0.4)', 'rgba(63, 214, 245, 0.6)', 'rgba(63, 214, 245, 1)'],
  ['rgba(160, 115, 255, 0.2)', 'rgba(160, 115, 255, 0.4)', 'rgba(160, 115, 255, 0.6)', 'rgba(160, 115, 255, 1)'],
];

export default RosePieColors;
