import { getInterviewPeriod } from '@api/Interview';
import { postAtMessageRead } from '@api/Messages';
import PeriodItemPopper from '@components/InterviewArrangement/PeriodItemPopper';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';

const AtMessagePeriod = ({
  periodToken, anchorEl, message, onShowCandidateDialog, onCancel,
}) => {
  const [item, setItem] = useState({});
  useEffect(() => {
    if (periodToken) {
      getInterviewPeriod(periodToken)
        .then((v) => {
          if (v.data.code === 0) {
            setItem(v.data.data);
          }
        })
        .catch(console.log);
    }
  }, [periodToken]);

  useEffect(() => {
    if (message.periodToken && !message.isRead) {
      const data = { tokens: [message.token] };
      postAtMessageRead(data);
    }
  }, [message]);

  const shouldOpenCandidateDialog = message.eventType === 3 && message.applicationToken;
  const theApplicationToken = message.applicationToken;

  useEffect(() => {
    if (!isEmpty(item)) {
      const candidateToken = item.candidates.list[0] && item.candidates.list[0].candidate?.token;
      if (shouldOpenCandidateDialog) {
        onShowCandidateDialog(candidateToken, theApplicationToken, item.process.token);
      } else if (item.candidates.total === 1) {
        onShowCandidateDialog(candidateToken, item.candidates.list[0].applicationToken, item.process.token);
      }
    }
  }, [item, shouldOpenCandidateDialog, theApplicationToken]);
  if (isEmpty(item)) return <></>;
  return <PeriodItemPopper open anchorEl={anchorEl} handleClose={onCancel} item={item} entryType={4} />;
};

AtMessagePeriod.propTypes = {
  periodToken: PropTypes.string.isRequired,
  onShowCandidateDialog: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  anchorEl: PropTypes.element.isRequired,
  message: PropTypes.objectOf.isRequired,
};

export default memo(AtMessagePeriod);
