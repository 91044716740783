import * as types from '../constants/ActionTypes';

export function updateMenuSwitch(value) {
  return { type: types.UPDATE_MENU_SWITCH, value };
}
export function updateAddGroupSwitch(value) {
  return { type: types.UPDATE_ADD_GROUP_SWITCH, value };
}
export function updateFormCreateSwitch(value) {
  // console.log('updateFormCreateSwitch');
  return { type: types.UPDATE_FORM_CREATE_SWITCH, value };
}
export function updateAddCandidateSwitch(value) {
  return { type: types.UPDATE_ADD_CANDIDATE_SWITCH, value };
}
export function updateImportCandidateSwitch(value) {
  return { type: types.UPDATE_IMPORT_CANDIDATE_SWITCH, value };
}
export function updateImportPositionSwitch(value) {
  return { type: types.UPDATE_IMPORT_POSITION_SWITCH, value };
}
export function updateImportStaffSwitch(value) {
  return { type: types.UPDATE_IMPORT_STAFF_SWITCH, value };
}
export function updateExportCandidateSwitch(value) {
  return { type: types.UPDATE_EXPORT_CANDIDATE_SWITCH, value };
}
export function updateAssignDepartmentSwitch(value) {
  return { type: types.UPDATE_ASSIGN_DEPARTMENT_SWITCH, value };
}
export function updateAddPatternSwitch(value) {
  return { type: types.UPDATE_ADD_PATTERN_SWITCH, value };
}
export function updateEditPatternSwitch(value) {
  return { type: types.UPDATE_EDIT_PATTERN_SWITCH, value };
}
export function updateAddProcessSwitch(value) {
  return { type: types.UPDATE_ADD_PROCESS_SWITCH, value };
}
export function updateAddCandidateToProcessSwitch(value) {
  return { type: types.UPDATE_ADD_CANDIDATE_TO_PROCESS_SWITCH, value };
}
export function updateEditCandidateSwitch(value) {
  return { type: types.UPDATE_EDIT_CANDIDATE_SWITCH, value };
}
export function updateCandidateInfoSwitch(value) {
  return { type: types.UPDATE_CANDIDATE_INFO_SWITCH, value };
}
export function updateCandidateInfoToken(value) {
  return { type: types.UPDATE_CANDIDATE_INFO_TOKEN, value };
}
export function updateAddHrDialogSwitch(value) {
  return { type: types.ADD_HR_SWITCH, value };
}
export function updateEditHrInfoDialogSwitch(value) {
  return { type: types.EDIT_HR_INFO_SWITCH, value };
}
export function updateMainPageIndex(value) {
  return { type: types.UPDATE_MAIN_PAGE_INDEX, value };
}
export function updateMainPageMenuStatus(value) {
  return { type: types.UPDATE_MAIN_PAGE_MENU_STATUS, value };
}
export function updateWorkbenchTitle(value) {
  return { type: types.UPDATE_WORKBENCH_TITLE, value };
}
export function updateWorkbenchToken(value) {
  return { type: types.UPDATE_WORKBENCH_TOKEN, value };
}
export function updateHeadSearchPlaceholder(value) {
  return { type: types.UPDATE_HEAD_SEARCH_PLACEHOLDER, value };
}
export function updateBtnAddCandidateSwitch(value) {
  return { type: types.UPDATE_BTN_ADD_CANDIDATE_SWITCH, value };
}
export function updateBtnAddGroupSwitch(value) {
  return { type: types.UPDATE_BTN_ADD_GROUP_SWITCH, value };
}
export function updateBtnDropCandidateSwitch(value) {
  return { type: types.UPDATE_BTN_DROP_CANDIDATE_SWITCH, value };
}
export function updateBtnDropGroupSwitch(value) {
  return { type: types.UPDATE_BTN_DROP_GROUP_SWITCH, value };
}
export function updateBtnSendInterviewSwitch(value) {
  return { type: types.UPDATE_BTN_SEND_INTERVIEW_SWITCH, value };
}
export function updateGlobalAlertSwitch(value) {
  return { type: types.UPDATE_GLOBAL_ALERT_SWITCH, value };
}
export function updateSnackbarOpenSwitch(value) {
  return { type: types.UPDATE_SNACKBAR_OPEN_SWITCH, value };
}
export function updateSnackbarErrorMessageSwitch(value) {
  return { type: types.UPDATE_SNACKBAR_ERROR_MESSAGE, value };
}
export function updateRejectReasonDialogSwitch(value) {
  return { type: types.UPDATE_REJECT_REASON_DIALOG_SWITCH, value };
}
export function updateDepartmentArray(value) {
  return { type: types.UPDATE_DEPARTMENT_ARRAY, value };
}
export function updateSelectedDepartmentArray(value) {
  return { type: types.UPDATE_SELECTED_DEPARTMENT_ARRAY, value };
}

export function updateEnterprise(value) {
  return { type: types.UPDATE_ENTERPRISE, value };
}

export function updateUser(value) {
  return { type: types.UPDATE_USER, value };
}

export function changeCandidateCount(value) {
  return { type: types.CHANGE_CANDIDATECOUNT, value };
}

export function changeKickout(value) {
  return { type: types.CHANGE_KICKOUT, value };
}

export function updateSetTabIndex(value) {
  return { type: types.UPDATA_SETTABINDEX, value };
}

export function updateAddTagToCandidate(value) {
  return { type: types.UPDATE_ADD_TAG_TO_CANDIDATE_SWITCH, value };
}

export function showGlobalToast(value) {
  return { type: types.SHOW_GLOBAL_TOAST, value };
}

export function toggleTemplateFillDialog(value) {
  return { type: types.TOGGLE_TEMPLATE_FILL_DIALOG, value };
}

export function openGlobalCandidateModal(value) {
  return { type: types.OPEN_GLOCAL_CANDIDATE_MODAL, value };
}

export function closeGlobalCandidateModal() {
  return { type: types.CLOSE_GLOBAL_CANDIDATE_MODAL };
}
