import { Avatar } from '@material-ui/core';
import { RANDOM_COLOR } from '@utils/constants';
import PropTypes from 'prop-types';

export default function AvatarComponent({
  src, name = '', style, ...rest
}) {
  if (src) {
    return <Avatar alt={name} src={src} style={style} {...rest} />;
  }

  return (
    <Avatar
      style={{ ...style, backgroundColor: RANDOM_COLOR[name.charCodeAt(0) % name.length], color: '#ffffff' }}
      {...rest}
    >
      {name.slice(0, 1)}
    </Avatar>
  );
}

AvatarComponent.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired,
};
