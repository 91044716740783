import axios from './Api';

export const uploadResume = (candidateToken, formData) => axios.request({
  url: `/candidate/resume/${candidateToken}/upload`,
  method: 'POST',
  data: formData,
});

export const editResume = (candidateToken, data) => axios.request({
  url: `/candidate/resume/${candidateToken}/update`,
  method: 'PUT',
  data,
});

export const parseResume = (candidateToken, formData) => axios.request({
  url: `/candidate/resume/${candidateToken}/import`,
  method: 'POST',
  data: formData,
});

export const getMajor = (searchText) => axios.request({
  url: '/candidate/education/major/list',
  method: 'GET',
  params: { searchText },
});

/**
 * @description 模板检查
 * @author Jeakin
 * @date 2021-04-13
 * @param {*} data  模板数据和分部数据
 * @returns {Promise} Promise object
 */
export const importTemplateCheck = (data) => axios.request({
  url: '/candidate/import/check',
  method: 'POST',
  data,
});

/**
 * @description 模板导入
 * @author Jeakin
 * @date 2021-04-13
 * @param {*} data  模板数据和分部数据
 * @returns {Promise} Promise object
 */
export const importTemplate = (data) => axios.request({
  url: '/candidate/import',
  method: 'POST',
  data,
});

/**
 * @description 模板导入覆盖重复所有
 * @author Jeakin
 * @date 2021-04-13
 * @param {*} data  模板数据和分部数据
 * @returns {Promise} Promise object
 */
export const importTemplateCover = (data) => axios.request({
  url: '/candidate/import/cover',
  method: 'POST',
  data,
});

/**
 * @description 模板导入覆盖重复单个
 * @author Jeakin
 * @date 2021-04-13
 * @param {*} data  模板数据和分部数据
 * @returns {Promise} Promise object
 */
export const importTemplateCoverOne = (data) => axios.request({
  url: '/candidate/import/coverOne',
  method: 'POST',
  data,
});

/**
 * @description 获取模板列表
 * @author Jeakin
 * @date 2021-04-13
 * @param {*} departmentToken 分部 token
 * @returns {Promise} Promise object
 */
export const getResumeTemplates = (departmentToken) => axios.request({
  url: '/candidate/importTemplates',
  method: 'POST',
  data: { departmentToken },
});
/**
 * @description 创建自定义模板
 * @author Jeakin
 * @date 13/04/2021
 * @param {*} departmentToken
 * @param {*} data
 * @returns {Promise} Promise object
 */
export const createResumeTemplates = (departmentToken, data) => axios.request({
  url: '/candidate/importTemplate',
  method: 'POST',
  data: { departmentToken, formItems: data },
});
/**
 * @description 编辑模板详情
 * @author Jeakin
 * @date 13/04/2021
 * @param {*} token
 * @param {*} data
 * @returns {Promise} Promise object
 */
export const editResumeTemplate = (token, data) => axios.request({
  url: `/candidate/importTemplate/${token}`,
  method: 'POST',
  data,
});
/**
 * @description 删除模板
 * @author Jeakin
 * @date 13/04/2021
 * @param {*} departmentToken
 * @returns {Promise} Promise object
 */
export const deleteResumeTemplate = (departmentToken) => axios.request({
  url: '/candidate/importTemplate/remove',
  method: 'POST',
  data: { departmentToken },
});
/**
 * @description 获得模板详情
 * @author Jeakin
 * @date 13/04/2021
 * @param {*} token
 * @returns {Promise} Promise object
 */
export const getResumeTemplate = (token) => axios.request({
  url: `/candidate/importTemplate/${token}`,
  method: 'GET',
});
/**
 * @description 获取模板预览
 * @author Jeakin
 * @date 14/04/2021
 * @param {*} data
 */
export const getPreviewimportTemplate = (data) => axios.request({
  url: '/candidate/importTemplate/preview',
  method: 'POST',
  responseType: 'blob',
  data: { formItems: data },
});

/**
 * @description 下载模板
 * @author Jeakin
 * @date 14/04/2021
 * @param {*} token
 */
export const downloadImportTemplate = (token) => axios.request({
  url: `/candidate/importTemplate/${token}/download`,
  method: 'GET',
  responseType: 'blob',
});

/**
 * @description 获得默认模板
 * @author Jeakin
 * @date 14/04/2021
 */
export const getDefaultImportTemplate = () => axios.request({
  url: '/candidate/importTemplate/defaultItems',
  method: 'GET',
});

export default { uploadResume, editResume, parseResume };
