import { CancelIcon, SearchIcon } from '@avocadoui/icons';
import {
  Box, ClickAwayListener, IconButton, InputBase as MuiInputBase, Paper, withStyles,
} from '@material-ui/core';
import {
  addToChipList,
  cancelSearch,
  changeInput,
  inputClickAway,
  removeIndexFromChipList,
  removeTailFromChipList, setIsFocused,
} from '@redux/search/action';
import useGlobalToast from '@utils/GlobalToast';
import cx from 'clsx';
import { isEmpty } from 'lodash';
import {
  useState, useRef, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './GlobalSearch.style';
import useEffectSearchFetch from './hooks/useEffectSearchFetch';
import useEffectSearchRoute from './hooks/useEffectSearchRoute';
import KeywordChip from './KeywordChip';
import ResultMiniList from './ResultMiniList';

const MAX_LENGTH_CHIP_LIST_IF_BLUR = 5;
export const MAX_LENGTH_CHIP_LIST = 400;

const InputBase = withStyles(() => ({
  input: {
    padding: 0,
  },
}))(MuiInputBase);

function GlobalSearch() {
  const GlobalToast = useGlobalToast();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFocused = useSelector(({ search }) => search.isFocused);
  const inputValue = useSelector(({ search }) => search.inputValue);
  const chipList = useSelector(({ search }) => search.chipList);
  const notMatchedChipList = useSelector(({ search }) => search.searchStat?.notMatchDetail || []);
  const showList = useSelector(({ search }) => search.showList);
  const showCancelIcon = useSelector(({ search }) => search.showCancelIcon);
  const isAdvancedMode = useSelector(({ search }) => search.isAdvancedMode);

  const [typingValue, setTypingValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const searchBoxChipsRef = useRef();
  const searchInputRef = useRef();
  const searchListRef = useRef();

  useEffectSearchRoute();
  useEffectSearchFetch();

  const scrollToInputBottom = useCallback(() => {
    setTimeout(() => {
      if (searchBoxChipsRef.current != null) {
        searchBoxChipsRef.current.scrollTop = searchBoxChipsRef.current.scrollHeight;
      }
    }, 0);
  }, []);

  const toastIfChipListMoreThanMaxLength = useCallback(
    (arr) => {
      const isChipListMoreThanMax = [...chipList, ...arr].length > MAX_LENGTH_CHIP_LIST;
      if (isChipListMoreThanMax) {
        GlobalToast.warning(`每次最多搜索${MAX_LENGTH_CHIP_LIST}个，超出部分未搜索`);
      }
    },
    [chipList],
  );

  const handleFocusInput = useCallback(() => {
    dispatch(setIsFocused(true));
    scrollToInputBottom();
  }, []);

  const handleClickAway = useCallback(() => {
    dispatch(inputClickAway());
  }, []);

  const handleClose = useCallback(() => {
    dispatch(cancelSearch());
  }, []);

  const handleAddToChipList = useCallback(
    (arr) => {
      toastIfChipListMoreThanMaxLength(arr);
      dispatch(addToChipList(arr));
      scrollToInputBottom();
    },
    [chipList],
  );

  const handleKeyPress = useCallback(
    (e) => {
      const v = e.target.value || '';
      // 32: 空格键, 13: 回车键
      if ([32, 13].indexOf(e.charCode) !== -1 && v.trim()) {
        handleAddToChipList([v]);
        e.preventDefault();
      }
    },
    [handleAddToChipList],
  );

  const handleKeyDown = useCallback((e) => {
    // 8: 删除键、后退键
    if ([8].indexOf(e.keyCode) !== -1 && isEmpty(e.target.value)) {
      dispatch(removeTailFromChipList());
      e.preventDefault();
    }
    // 40: 下箭头
    if (e.keyCode === 40) {
      if (searchListRef.current) searchListRef.current.focus();
      e.preventDefault();
    }
  }, []);

  const handleChange = useCallback(
    (e) => {
      if (isTyping) {
        setTypingValue(e.target.value);
      } else {
        setTypingValue('');
        dispatch(changeInput(e.target.value));
      }
    },
    [isTyping],
  );

  const handlePaste = useCallback(
    (e) => {
      e.preventDefault();
      const raw = e.clipboardData.getData('Text');
      // via https://stackoverflow.com/questions/9849754/how-can-i-replace-newlines-line-breaks-with-spaces-in-javascript
      // eslint-disable-next-line no-control-regex
      const arr = raw.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, ' ').split(' ');
      handleAddToChipList(arr);
    },
    [handleAddToChipList],
  );

  const handleDeleteChip = useCallback((idx) => {
    dispatch(removeIndexFromChipList(idx));
  }, []);

  const handleCompositionStart = useCallback(() => {
    setIsTyping(true);
  }, []);

  const handleCompositionEnd = useCallback((e) => {
    setIsTyping(false);
    handleChange(e);
  }, []);

  const showTruncate = !isFocused && chipList.length > MAX_LENGTH_CHIP_LIST_IF_BLUR;
  const truncatedChipLength = chipList.length - MAX_LENGTH_CHIP_LIST_IF_BLUR;
  const chipLength = isFocused ? chipList.length : MAX_LENGTH_CHIP_LIST_IF_BLUR;
  const searchInputValue = typingValue || inputValue;

  const isChipListMoreThanMaxLength = useMemo(() => isFocused
    && chipList.length === MAX_LENGTH_CHIP_LIST, [isFocused, chipList]);

  const placeholder = useMemo(() => {
    let val = '搜索候选人';
    if (isFocused) val = '姓名或手机号，多个用“空格”区分';
    if (isChipListMoreThanMaxLength) val = `已达到上限${MAX_LENGTH_CHIP_LIST}个`;
    return val;
  }, [isFocused, chipList, isChipListMoreThanMaxLength]);

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper
          // className={isFocused ? classes.searchFocusRoot : classes.root}
          className={cx(
            classes.searchBoxCommon,
            isFocused ? classes.searchBoxFocused : classes.searchBoxUnfocused,
            isAdvancedMode && classes.searchBoxExpanded,
          )}
          autoComplete="off"
          elevation={0}
        >
          <SearchIcon style={{ color: 'rgba(0,0,0,0.26)', margin: '6px' }} />
          <Box
            className={cx(
              classes.searchBoxChipsCommon,
              isFocused ? classes.searchBoxChipsFocused : classes.searchBoxChipsUnfocused,
            )}
            ref={searchBoxChipsRef}
          >
            {chipList
              && chipList.length > 0
              && chipList.slice(0, chipLength).map((chip, idx) => {
                const isNotMatched = notMatchedChipList.indexOf(chip) > -1;
                return (
                  <KeywordChip
                    index={`${chip}-${idx}`}
                    text={chip}
                    idx={idx}
                    isNotMatched={isNotMatched}
                    handleDeleteChip={handleDeleteChip}
                  />
                );
              })}
            {showTruncate && (
              <Box m={0.5} display="flex" alignItems="center">
                +{truncatedChipLength}
              </Box>
            )}
            <InputBase
              inputRef={searchInputRef}
              className={classes.searchBoxInput}
              value={searchInputValue}
              placeholder={placeholder}
              onFocus={handleFocusInput}
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onPaste={handlePaste}
              onClick={() => {}}
              onCompositionStart={handleCompositionStart}
              onCompositionEnd={handleCompositionEnd}
              inputProps={{ autoComplete: 'off' }}
              disabled={isChipListMoreThanMaxLength}
            />
          </Box>
          {showCancelIcon ? (
            <IconButton onClick={handleClose} style={{ height: '36px' }}>
              <CancelIcon style={{ color: 'rgba(0,0,0,0.26)' }} />
            </IconButton>
          ) : null}
          {showList && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <ResultMiniList searchListRef={searchListRef} searchInputRef={searchInputRef} />
            </ClickAwayListener>
          )}
        </Paper>
      </ClickAwayListener>
    </>
  );
}

export default GlobalSearch;
