import ToastProvider from '@components/Toast';
import ErrorBoundary from '@utils/ErrorBoundary';
import { ConfirmProvider } from 'material-ui-confirm';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Toast from './components/Toast/Toast';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
// // 1、引入Store
import configureStore, { rootSaga, sagaMiddleware } from './store/indexStore';
import ThemeProvider from './theme';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

const Root = () => {
  // set store to root for global props
  const store = configureStore({});
  return (
    <ThemeProvider>
      <ErrorBoundary>
        <Provider store={store}>
          <ToastProvider>
            <Toast />
            <ConfirmProvider>
              <Routes />
            </ConfirmProvider>
          </ToastProvider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
console.log('%c Welcome to-> 方便面面试', 'background:#fff;color:#66bb6a;');

ReactDOM.render(<Root />, document.getElementById('root'));

sagaMiddleware.run(rootSaga);

serviceWorker.unregister();
