import {
  fork, put, select, takeLatest,
} from 'redux-saga/effects';
import { getTalentPositions } from '../../../api/ApplicationSession';
import { getCandidateStates } from '../../../api/Candidate';
import Api from '../../../api/CandidatesWithApplication';
import { enqueueSnackbar } from '../toast/action';
import {
  deleteTalent, deleteTalents, getTalents, initTalent, reloadDataTalent, setTalents,
} from './action';

function* getApiTalents() {
  const { leftNavState = 0, filters } = yield select((state) => state.talent);

  /** 解决人才库岗位下全部不包含未安排 */
  const applicationExcludeWait = leftNavState === 1 && filters.state === '' && filters.positionToken === '';

  const positionEx = {
    positionStatistics: {
      exist: {
        dimission: 0,
        impel: 0,
        layin: 0,
        onboard: 0,
        recall: 0,
        reject: 0,
        wait: 0,
      },
      candidateTotal: 0,
    },
  };
  if (filters.positionToken) {
    const positionCandidateStates = yield getCandidateStates({
      positionToken: filters.positionToken,
    });
    positionEx.positionStatistics = positionCandidateStates.data.data;
  }

  const candidateStates = yield getCandidateStates();
  const positions = yield getTalentPositions();
  const result = yield Api.getCandidates({ ...filters, applicationExcludeWait });

  if (result.data.code === 0) {
    const { list: talents, total } = result.data.data;
    yield put(
      setTalents({
        talents,
        total,
        statistics: candidateStates.data.data,
        positions: positions.data.data.list,
        ...positionEx,
      }),
    );
  }
}

function* deleteApiTalents() {
  const selectedTalents = yield select((state) => state.talent.selectedTalents);
  const result = yield Api.vc1DeleteCandidates(selectedTalents);
  if (result.data.code === 0) {
    yield getApiTalents();
  } else {
    yield put(
      enqueueSnackbar({
        message: result.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
}
function* deleteApiTalent() {
  const selectedTalent = yield select((state) => state.talent.selectedTalent);
  const result = yield Api.vc1DeleteCandidates([selectedTalent]);
  if (result.data.code === 0) {
    yield getApiTalents();
  } else {
    yield put(
      enqueueSnackbar({
        message: result.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
}

function* watch() {
  yield takeLatest(initTalent, getApiTalents);
  yield takeLatest(reloadDataTalent, getApiTalents);
  yield takeLatest(getTalents, getApiTalents);
  yield takeLatest(deleteTalents, deleteApiTalents);
  yield takeLatest(deleteTalent, deleteApiTalent);
}

export default [fork(watch)];
