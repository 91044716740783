/* eslint-disable */
import API_URL from '@config/conf';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { API } from '../../../v1/api';
import http from '../../../v1/utils/http/http';
import {
  clearTemplateInfo,
  createTemplate,

  deleteTemplate,

  fetchProcessesDetail, fetchProcessesList, fetchTemplateInfo, fetchTemplateList, fetchTemplateVariableList,














  postSendSelfTestSms, setProcessesDetail, setProcessesList, setTemplateInfo, setTemplateList, setTemplateListSelectedIndex, setTemplateVariableList,







  updateTemplate
} from './action';


function* queryFetchTemplateVariableList() {
  try {
    const result = yield http.get({ url: `${API_URL}inbox/notifyTemplate/variables` });
    yield put(setTemplateVariableList(result.data));
  } catch (error) {
    console.log(error, '模板系统变量获取失败！');
  }
}

function* queryFetchTemplate(token) {
  if (token.payload === '') {
    yield put(setTemplateInfo({}));
    return;
  }

  try {
    const result = yield http.get({ url: `${API_URL}inbox/notifyTemplate2?token=${token.payload}` });
    // 服务器协议同一个字段用了不同变量
    // 这里需要把rich转换成templateRich
    // 不然后面处理起来很麻烦
    const detail = result.data.data;
    const info = {
      ...detail,
      messageTemplate: {
        ...detail.messageTemplate,
        templateRich:
          detail.messageTemplate && detail.messageTemplate.rich && detail.messageTemplate.rich !== ''
            ? detail.messageTemplate.rich
            : '',
      },
      smsTemplate: {
        ...detail.smsTemplate,
        templateRich:
          detail.smsTemplate && detail.smsTemplate.rich && detail.smsTemplate.rich !== ''
            ? detail.smsTemplate.rich
            : '',
      },
      emailTemplate: {
        ...detail.emailTemplate,
        templateRich:
          detail.emailTemplate && detail.emailTemplate.rich && detail.emailTemplate.rich !== ''
            ? detail.emailTemplate.rich
            : '',
      },
    };
    yield put(setTemplateInfo(info));
  } catch (error) {
    console.log(error, '模板信息获取失败！');
  }
}

function* queryFetchTemplateList() {
  try {
    const result = yield http.get({ url: `${API_URL}inbox/notifyTemplate2/list` });
    yield put(setTemplateList(result.data));
    if (result.data.data) {
      const state = yield select();
      const index = state.setting.templateListSelectedIndex;
      const template = state.setting.templateList[index];
      const { token } = template;
      if (token.length > 10) {
        // console.log(`queryFetchTemplateList:${token}`);
        yield call(queryFetchTemplate, { payload: token });
      }
    }
  } catch (error) {
    console.log(error, '模板列表获取失败！');
  }
}

function* queryClearTemplateInfo() {
  const clear = {
    variables: [],
    processes: [],
    autoSend: true,
    messageTemplate: {
      name: '新增模板',
      template: '',
      // rich: '',
      templateRich: '',
      variables: [],
    },
    smsTemplate: {
      name: '新增模板',
      template: '',
      // rich: '',
      templateRich: '',
      variables: [],
    },
    emailTemplate: {
      name: '新增模板',
      template: '',
      // rich: '',
      templateRich: '',
      variables: [],
      attachments: [],
      feedbackSwitch: 2,
    },
  };
  yield put(setTemplateInfo(clear));
}

function* queryCreateTemplate(param) {
  const {
    name,
    messageTemplate,
    smsTemplate,
    emailTemplate,
    autoSend,
    processes,
    activeWechat,
    activeSms,
    activeEmail,
  } = param.payload;
  try {
    const message = {
      template: messageTemplate.template && messageTemplate.template !== '' ? messageTemplate.template : '',
      templateRich:
        messageTemplate.templateRich && messageTemplate.templateRich !== '' ? messageTemplate.templateRich : '',
      variables:
        messageTemplate.variables && messageTemplate.variables.length > 0
          ? messageTemplate.variables.map((item) => item.token)
          : [],
      switchStatus: activeWechat ? 1 : 2,
    };
    const sms = {
      template: smsTemplate.template && smsTemplate.template !== '' ? smsTemplate.template : '',
      templateRich: smsTemplate.templateRich && smsTemplate.templateRich !== '' ? smsTemplate.templateRich : '',
      variables:
        smsTemplate.variables && smsTemplate.variables.length > 0
          ? smsTemplate.variables.map((item) => item.token)
          : [],
      switchStatus: activeSms ? 1 : 2,
    };

    const mail = {
      name: emailTemplate.name && emailTemplate.name !== '' ? emailTemplate.name : '',
      template: emailTemplate.template && emailTemplate.template !== '' ? emailTemplate.template : '',
      templateRich: emailTemplate.templateRich && emailTemplate.templateRich !== '' ? emailTemplate.templateRich : '',
      variables:
        emailTemplate.variables && emailTemplate.variables.length > 0
          ? emailTemplate.variables.map((item) => item.token)
          : [],
      attachments: emailTemplate.attachments && emailTemplate.attachments.length > 0 ? emailTemplate.attachments : [],
      feedbackSwitch: emailTemplate.feedbackSwitch,
      switchStatus: activeEmail ? 1 : 2,
    };
    const data = {
      name,
      messageTemplate: message,
      smsTemplate: sms,
      emailTemplate: mail,
      autoSend,
      processes: processes.map((item) => {
        return { positionToken: item.positionToken, processToken: item.token };
      }),
    };

    const result = yield http.post({ url: `${API_URL}inbox/notifyTemplate2`, data });

    if (result.data && result.data.code !== 0) {
      console.log('模板创建失败！');
      yield put({ type: 'SHOW_GLOBAL_TOAST', value: { message: result.data.message, severity: 'warning' } });
    } else {
      yield call(queryFetchTemplateList);
      yield call(queryProcessesList);
    }
  } catch (error) {
    console.log(error, '模板创建失败！');
  }
}

function* queryUpdateTemplate(param) {
  const {
    name,
    messageTemplate,
    smsTemplate,
    emailTemplate,
    autoSend,
    processes,
    activeWechat,
    activeSms,
    activeEmail,
  } = param.payload;
  try {
    const message = {
      token: messageTemplate.token && messageTemplate.token !== '' ? messageTemplate.token : '',
      template: messageTemplate.template && messageTemplate.template !== '' ? messageTemplate.template : '',
      templateRich:
        messageTemplate.templateRich && messageTemplate.templateRich !== '' ? messageTemplate.templateRich : '',
      variables:
        messageTemplate.variables && messageTemplate.variables.length > 0
          ? messageTemplate.variables.map((item) => item.token)
          : [],
      switchStatus: activeWechat ? 1 : 2,
    };
    const sms = {
      token: smsTemplate.token && smsTemplate.token !== '' ? smsTemplate.token : '',
      template: smsTemplate.template && smsTemplate.template !== '' ? smsTemplate.template : '',
      templateRich: smsTemplate.templateRich && smsTemplate.templateRich !== '' ? smsTemplate.templateRich : '',
      variables:
        smsTemplate.variables && smsTemplate.variables.length > 0
          ? smsTemplate.variables.map((item) => item.token)
          : [],
      switchStatus: activeSms ? 1 : 2,
    };

    const mail = {
      name: emailTemplate.name && emailTemplate.name !== '' ? emailTemplate.name : '',
      token: emailTemplate.token && emailTemplate.token !== '' ? emailTemplate.token : '',
      template: emailTemplate.template && emailTemplate.template !== '' ? emailTemplate.template : '',
      templateRich: emailTemplate.templateRich && emailTemplate.templateRich !== '' ? emailTemplate.templateRich : '',
      variables:
        emailTemplate.variables && emailTemplate.variables.length > 0
          ? emailTemplate.variables.map((item) => item.token)
          : [],
      attachments: emailTemplate.attachments && emailTemplate.attachments.length > 0 ? emailTemplate.attachments : [],
      feedbackSwitch: emailTemplate.feedbackSwitch,
      switchStatus: activeEmail ? 1 : 2,
    };
    const data = {
      name,
      messageTemplate: message,
      smsTemplate: sms,
      emailTemplate: mail,
      autoSend,
      processes: processes.map((item) => {
        return { positionToken: item.positionToken, processToken: item.token };
      }),
    };
    const state = yield select();
    const index = state.setting.templateListSelectedIndex;
    const template = state.setting.templateList[index];
    const { token } = template;
    const result = yield http.patch({ url: `${API_URL}inbox/notifyTemplate2/${token}`, data });
    yield call(queryFetchTemplateList);
    yield call(queryProcessesList);
  } catch (error) {
    console.log(error, '模板修改失败！');
  }
}

function* queryDeleteTemplate(param) {
  const token = param.payload;
  try {
    const data = {};
    const result = yield http.delete({ url: `${API_URL}inbox/notifyTemplate2/${token}`, data });
    yield call(queryFetchTemplateList);
    yield put(setTemplateListSelectedIndex(0));

    const state = yield select();
    const index = state.setting.templateListSelectedIndex;
    const template = state.setting.templateList[index];
    const firstToken = template.token;
    yield call(queryFetchTemplate, { payload: firstToken });
    yield call(queryProcessesList);
  } catch (error) {
    console.log(error, '模板修改失败！');
  }
}

function* queryProcessesDetail(param) {
  const token = param;
  try {
    const result = yield http.get({ url: `${API_URL + API.getApplicationSessionGET.replace('#{token}', token)}` });
    // const parseResult = JSON.parse(result);
    yield put(setProcessesDetail({ token, data: result.data }));
  } catch (error) {
    console.log(error);
  }
}

function* queryProcessesListOld() {
  try {
    const result = yield http.get({ url: `${API_URL + API.getApplicationSessionsGET}` });
    // const parseResult = JSON.parse(result);
    if (result.data) {
      yield put(setProcessesList(result.data.data));

      const processes = result.data.data.list;
      for (let i = 0; i < processes.length; i += 1) {
        yield call(queryProcessesDetail, processes[i].token);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* querySendSelfTestSms(info) {
  const { payload } = info;
  try {
    const data = {
      mobile: payload.phoneNumber,
      template: payload.template,
      variables: payload.variables,
    };
    const result = yield http.post({ url: `${API_URL}inbox/notifyTemplate2/smsTest`, data });
    if (result.data) {
    }
  } catch (error) {
    console.log(error);
  }
}

function* queryProcessesList() {
  try {
    const result = yield http.get({ url: `${API_URL}inbox/notifyTemplate/positions` });
    if (result.data) {
      yield put(setProcessesList(result.data.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchSetting() {
  yield takeEvery(fetchTemplateVariableList, queryFetchTemplateVariableList);
  yield takeEvery(fetchTemplateList, queryFetchTemplateList);
  yield takeEvery(fetchTemplateInfo, queryFetchTemplate);
  yield takeEvery(clearTemplateInfo, queryClearTemplateInfo);
  yield takeEvery(createTemplate, queryCreateTemplate);
  yield takeEvery(updateTemplate, queryUpdateTemplate);
  yield takeEvery(deleteTemplate, queryDeleteTemplate);
  yield takeEvery(fetchProcessesList, queryProcessesList);
  yield takeEvery(fetchProcessesDetail, queryProcessesDetail);
  yield takeEvery(postSendSelfTestSms, querySendSelfTestSms);
}

export default [fork(watchSetting)];
