import { getInboxMemssageList } from '@api/Inbox';
import API_URL from '@config/conf';
import { call } from 'file-loader';
import { fork, put, takeEvery } from 'redux-saga/effects';
import http from '../../../v1/utils/http/http';
import {
  deleteCommentMessage,
  fetchAtList,
  fetchAtMessageList,
  sendCommentMessage,
  setAtList,
  setAtMessageList,
  setAtMessageReaded,
  setInboxMessageList,
} from './action';

function* queryFetchAtList(param) {
  const { keyword, applicationToken } = param.payload;

  try {
    const result = yield http.get({
      url: `${API_URL}admin/users/at?s=${keyword}&applicationToken=${applicationToken}`,
    });
    if (result.data.code === 0) {
      yield put(setAtList(result.data.data.list));
    }
  } catch (error) {
    console.log(error, '@人员列表获取失败！');
  }
}

function* queryFetchAtMessageList() {
  const data = { pageSize: 500, page: 1 };
  try {
    const result = yield http.post({ url: `${API_URL}application/comment/at/list`, data });
    yield put(setAtMessageList(result.data.data));
  } catch (error) {
    console.log(error, '@消息列表获取失败!');
  }
}

function* querySetAtMessageReaded(param) {
  const data = { token: param.payload };
  try {
    yield http.post({ url: `${API_URL}application/comment/at/read`, data });
    yield call(queryFetchAtMessageList);
  } catch (error) {
    console.log(error, '@消息已读状态标记失败!');
  }
}

function* querySendCommentMessage(param) {
  const data = {
    applicationProcessToken: param.payload.applicationProcessToken,
    comment: param.payload.comment,
  };
  try {
    yield http.post({ url: `${API_URL}vc1/application/comment`, data });
  } catch (error) {
    console.log(error, '@消息发送失败!');
  }
}

function* queryDeleteCommentMessage(param) {
  const commentToken = param.payload;
  try {
    yield http.delete({ url: `${API_URL}application/comment/${commentToken}` });
  } catch (error) {
    console.log(error, '@评论删除失败!');
  }
}

function* queryInboxMemssageList() {
  try {
    const data = { pageSize: 500, page: 1 };
    const result = yield getInboxMemssageList(data);
    yield put(setInboxMessageList(result.data.data));
  } catch (error) {
    console.log(error, '@获取聊天内容失败');
  }
}

function* watchAtMessage() {
  yield takeEvery(fetchAtList, queryFetchAtList);
  yield takeEvery(fetchAtMessageList, queryFetchAtMessageList);
  yield takeEvery(fetchAtMessageList, queryInboxMemssageList);
  yield takeEvery(setAtMessageReaded, querySetAtMessageReaded);
  yield takeEvery(sendCommentMessage, querySendCommentMessage);
  yield takeEvery(deleteCommentMessage, queryDeleteCommentMessage);
}

export default [fork(watchAtMessage)];
