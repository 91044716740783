import PropTypes from 'prop-types';
import { useReducer } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import defaultState from './defaultState';
import reducer from './reducer';

const StateContext = createContext();
const DispatchContext = createContext();

function InterviewProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

InterviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useInterviewState(selector = (state) => state) {
  const context = useContextSelector(StateContext, selector);
  if (context === undefined) {
    throw new Error('useInterviewState must be used within a InterviewProvider');
  }
  return context;
}

function useInterviewDispatch(selector = (state) => state) {
  const context = useContextSelector(DispatchContext, selector);
  if (context === undefined) {
    throw new Error('useInterviewDispatch must be used within a InterviewProvider');
  }
  return context;
}

export { InterviewProvider, useInterviewState, useInterviewDispatch };
