import {
  CheckIcon, ErrorIcon, InfoIcon, WarningIcon,
} from '@avocadoui/icons';
import Snackbar from '@components/Snackbar/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

export const ELAlert = withStyles(() => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.86)',
    margin: 0,
    padding: '5px 36px 5px 16px',
    boxShadow:
      '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
    alignItems: 'center',
  },
  icon: {
    marginRight: 16,
  },
}))(Alert);

const iconDict = {
  success: <CheckIcon style={{ fontSize: 24, color: '#00D99D' }} />,
  warning: <WarningIcon style={{ fontSize: 24, color: '#F5C441' }} />,
  error: <ErrorIcon style={{ fontSize: 24, color: '#FF6C6C' }} />,
  info: <InfoIcon style={{ fontSize: 24, color: '#3DA0F5' }} />,
};

const ELSnackbar = ({
  open, onClose, title, severity,
}) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={open}
    autoHideDuration={3000}
    onClose={onClose}
  >
    <ELAlert icon={iconDict[severity]} severity={severity} variant="filled">
      {title}
    </ELAlert>
  </Snackbar>
);

ELSnackbar.propTypes = {
  title: PropTypes.string,
  severity: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

ELSnackbar.defaultProps = {
  title: '',
  severity: 'success',
  open: true,
  onClose: null,
};

export default ELSnackbar;
