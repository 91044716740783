/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as InterviewAPI from '@api/Interview';
import Avatar from '@components/Avocado/Avatar';
import Typography from '@components/Avocado/Typography';
import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  useState, useRef, useEffect, useCallback,
} from 'react';

const useStyles = makeStyles({
  subtitle: {
    '&:hover': {
      backgroundColor: '#f9faf9',
      cursor: 'pointer',
    },
  },
});

function formatMs(ms) {
  const seconds = Math.round(ms / 1000);
  const h = Math.floor((seconds / 60 / 60) % 60).toString().padStart(2, '0');
  const m = Math.floor((seconds / 60) % 60).toString().padStart(2, '0');
  const s = (seconds % 60).toString().padStart(2, '0');
  if (h === '00') {
    return `${m}:${s}`;
  }
  return `${h}:${m}:${s}`;
}

const Subtitle = ({
  id, text, isHighlight, beginTime, onClick, name, userType,
}) => {
  const classes = useStyles();
  const baseStyle = { cursor: 'pointer' };

  return (
    <Box display="flex" p={2} className={classes.subtitle} onClick={() => onClick(id, beginTime)}>
      <Box>
        <Avatar user={{ name }} />
      </Box>
      <Box ml={1}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="text.secondary">
            {name}({userType === 1 ? '企业成员' : '候选人'})
          </Typography>
          <Typography variant="caption" color="text.disabled" ml={1}>
            {formatMs(beginTime)}
          </Typography>
        </Box>
        <Box>
          <Typography
            display="inline"
            variant="body2"
            color="text.primary"
            style={isHighlight ? { ...baseStyle, backgroundColor: '#4CAF50', color: 'white' } : baseStyle}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

Subtitle.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  userType: PropTypes.number.isRequired,
  isHighlight: PropTypes.bool.isRequired,
  beginTime: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const itemsLength = 20;

// const SUBS_LIST = [
//   {
//     indexId: 0,
//     text: '第一个第一句',
//     beginTime: 1000,
//     endTime: 2000,
//   },
//   {
//     indexId: 0,
//     text: '第一个第二句',
//     beginTime: 2000,
//     endTime: 4000,
//   },
//   {
//     indexId: 0,
//     text: '第一个第三句',
//     beginTime: 4000,
//     endTime: 8000,
//   },
//   {
//     indexId: 1,
//     text: '第二个第一句',
//     beginTime: 1000,
//     endTime: 4000,
//   },
//   {
//     indexId: 1,
//     text: '第二个第二句',
//     beginTime: 4000,
//     endTime: 8000,
//   },
//   {
//     indexId: 1,
//     text: '第二个第三句',
//     beginTime: 8000,
//     endTime: 12000,
//   },
// ];

const VideoSubtitle = ({
  roomToken, interviewUserToken, videoPlaybackRef, selectedVideoId, setSelectedVideoId,
}) => {
  const [subs, setSubs] = useState();
  const timestamp = (videoPlaybackRef.current?.currentTime || 0) * 1000;
  // eslint-disable-next-line no-unused-vars
  const [_, setUpdate] = useState(1);
  const [open, setOpen] = useState(true);
  const [showItemsLength, setShowItemsLength] = useState(itemsLength);
  const boxRef = useRef();
  const canLoadMore = useRef(true);

  useEffect(() => {
    InterviewAPI.getInterviewSubtitle(roomToken, interviewUserToken).then((result) => {
      if (result.data.code === 0) {
        setSubs(result.data.data.list);
      }
    });
    // setSubs(SUBS_LIST);
  }, [roomToken, interviewUserToken]);

  useEffect(() => {
    const id = setInterval(() => {
      if (!videoPlaybackRef.current?.paused) {
        setUpdate((t) => t + 1);
      }
    }, 500);

    return () => clearInterval(id);
  }, []);

  const scroll = () => {
    if (showItemsLength >= subs.length) return;
    if (!canLoadMore.current) return;
    const dom = boxRef.current;
    if (dom.scrollTop > dom.scrollHeight - dom.offsetHeight - 300) {
      canLoadMore.current = false;
      setShowItemsLength((l) => l + itemsLength);
      setTimeout(() => {
        canLoadMore.current = true;
      }, 0);
    }
  };

  const handlePlay = useCallback((id, t) => {
    setSelectedVideoId(id);

    if (selectedVideoId === id) {
    // eslint-disable-next-line no-param-reassign
      videoPlaybackRef.current.currentTime = t / 1000;
      videoPlaybackRef.current.play();
    } else {
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        videoPlaybackRef.current.currentTime = t / 1000;
        videoPlaybackRef.current.play();
      }, 1000);
    }
  }, [selectedVideoId]);

  if (isEmpty(subs)) {
    return null;
  }

  return (
    <>
      <Box m={2}>
        <Link component="button" variant="body2" onClick={() => setOpen((o) => !o)}>
          {open ? '收起' : '展开'}文字记录
        </Link>
      </Box>
      <div
        ref={boxRef}
        onScroll={scroll}
        style={open ? { maxHeight: '400px', overflowY: 'auto' } : { display: 'none' }}
      >
        {subs.slice(0, showItemsLength).map((sub) => {
          const {
            indexId, text, name, userType, beginTime, endTime,
          } = sub;
          const isHighlight = indexId === selectedVideoId && timestamp >= beginTime && timestamp <= endTime;
          return (
            <Subtitle
              key={`${indexId}-${beginTime}-${endTime}`}
              id={indexId}
              text={text}
              name={name}
              userType={userType}
              beginTime={beginTime}
              isHighlight={isHighlight}
              onClick={handlePlay}
            />
          );
        })}
      </div>
    </>
  );
};

VideoSubtitle.propTypes = {
  roomToken: PropTypes.string.isRequired,
  interviewUserToken: PropTypes.string.isRequired,
  videoPlaybackRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  selectedVideoId: PropTypes.number.isRequired,
  setSelectedVideoId: PropTypes.func.isRequired,
};

export default VideoSubtitle;
