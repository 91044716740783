import { EmptyInterviewsIcon } from '@components/Icon';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import * as interviewActions from '@redux/interviewArrangement/action';
import { useActions } from '@utils/useActions';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CANCELED_PROCESS_APPLICATION_STATE } from '../const';
import { useCandidateDispatch, useCandidateState } from '../context';
import InterviewArrangmentItem from './InterviewArrangmentItem';

function CandidateInterviewArrangement({ processToken }) {
  const { openArrangementModal } = useActions(interviewActions);
  const { applicationInterviewArrangementData, applicationData } = useCandidateState();
  const { positionToken, applicationToken } = applicationData;
  const interviewData = (applicationInterviewArrangementData[processToken]
    && applicationInterviewArrangementData[processToken].data) || {};
  const { list, canInterview } = interviewData;
  const hasNoData = isEmpty(list);
  const dispatch = useCandidateDispatch();
  const { arrangementModalIsOpen } = useSelector(({ interviewArrangement }) => interviewArrangement);
  const [hasOpenedArrangmentModal, setHasOpenedArrangmentModal] = useState(false);
  const role = useSelector((state) => state.account?.userInfo?.user?.role);
  const isNewInterviewButtonHidden = role === 5; // 面试官账号
  const isCanceled = useCandidateState(
    (state) => CANCELED_PROCESS_APPLICATION_STATE.indexOf(state.applicationData.applicationState) > -1,
  );

  useEffect(() => {
    if (hasOpenedArrangmentModal && !arrangementModalIsOpen) {
      dispatch({ type: 'REFRESH_DATA' });
      setHasOpenedArrangmentModal(false);
    }
  }, [hasOpenedArrangmentModal, arrangementModalIsOpen]);

  const showOpenArrangementBtn = !isCanceled && canInterview && !isNewInterviewButtonHidden;

  if (hasNoData) {
    return (
      <Box
        key="empty"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        marginTop="20%"
      >
        <Box width="100px" color="#C3C3C3">
          <EmptyInterviewsIcon />
        </Box>
        <Box mt="24px" color="#C3C3C3">
          当前暂无邀约
        </Box>
        <Box mt="16px" display="flex" justifyContent="center">
          {showOpenArrangementBtn && (
            <Button
              variant="contained"
              color="primary"
              startIcon={(
                <Box display="flex" alignItems="center" width="18px">
                  <AddIcon />
                </Box>
              )}
              onClick={() => {
                openArrangementModal({
                  entryType: 2,
                  positionToken,
                  processToken,
                  applicationTokens: [applicationToken],
                });
                setHasOpenedArrangmentModal(true);
              }}
              style={{ marginRight: '16px', padding: '4px 32px', maxHeight: '34px' }}
            >
              添加邀约安排
            </Button>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box mt="16px" display="flex" justifyContent="center">
        {showOpenArrangementBtn && (
          <Button
            variant="contained"
            color="primary"
            startIcon={(
              <Box display="flex" alignItems="center" width="18px">
                <AddIcon />
              </Box>
            )}
            onClick={() => {
              openArrangementModal({
                entryType: 2,
                positionToken,
                processToken,
                applicationTokens: [applicationToken],
              });
              setHasOpenedArrangmentModal(true);
            }}
            style={{ padding: '4px 32px', maxHeight: '34px' }}
          >
            添加邀约安排
          </Button>
        )}
      </Box>
      {list.map((item) => (
        <InterviewArrangmentItem
          key={item.periodToken}
          item={item}
          setHasOpenedArrangmentModal={setHasOpenedArrangmentModal}
          processToken={processToken}
        />
      ))}
    </Box>
  );
}

CandidateInterviewArrangement.propTypes = {
  processToken: PropTypes.string,
};

CandidateInterviewArrangement.defaultProps = {
  processToken: '',
};

export default CandidateInterviewArrangement;
