/* eslint-disable react/prop-types */
import { postNotificationCustomMessage, postNotificationMessage } from '@api/Notification';
import TemplateFill from '@components/Notification/TemplateFill';
import { Box } from '@material-ui/core';
import useGlobalToast from '@utils/GlobalToast';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import CandidateAssignablePositions from './CandidateAssignablePositions';
// import EvaluationItemDetail from './CandidateEvaluation/EvaluationItemDetail';
import styles from './CandidateMain.module.css';
import CandidateRecord from './CandidateRecord';
import CandidateSidebar from './CandidateSidebar/CandidateSidebar';
import CandidateTabs from './CandidateTabs/CandidateTabs';
import { useCandidateState } from './context';
import HasNoPermission from './HasNoPermission';

const TemplateFillDialog = ({ templateFillData, handleClose, handleSendCustomMessage }) => {
  const {
    templateFillOpen,
    template,
    templateRich,
    variables,
    templateSms,
    richSms,
    variablesSms,
    templateEmail,
    richEmail,
    variablesEmail,
    emailSubject,
    mailToAddress,
    emailTemplate,
    auditStatus,
    candidateTokens,
    needFeedbackHidden,
  } = templateFillData;

  if (templateFillOpen) {
    return (
      <TemplateFill
        emailTemplate={emailTemplate}
        open={templateFillOpen}
        template={template}
        templateRich={templateRich}
        variables={variables}
        templateSms={templateSms}
        richSms={richSms}
        variablesSms={variablesSms}
        templateEmail={templateEmail}
        richEmail={richEmail}
        variablesEmail={variablesEmail}
        emailSubject={emailSubject}
        auditStatus={auditStatus}
        mailToAddress={mailToAddress}
        candidateTokens={candidateTokens}
        needFeedbackHidden={needFeedbackHidden}
        handleConfirmNotifyMsg={handleSendCustomMessage}
        handleClose={handleClose}
      />
    );
  }

  return null;
};

function CandidateMain({ onClose, hasScrolled }) {
  const GlobalToast = useGlobalToast();
  const {
    candidateApplicationData, isShowRecord, hasPermission, isShowAssignablePositionsList,
  } = useCandidateState();
  const [sendApplicationTokens, setSendApplicationTokens] = useState([]);
  const [templateFillData, setTemplateFillData] = useState({ templateFillOpen: false });

  const handleSendMessage = useCallback((applicationTokens) => {
    // 记住 applicationTokens 以备 handleSendCustomMessage 调用
    setSendApplicationTokens(applicationTokens);
    const data = { applicationTokens };
    postNotificationMessage(data)
      .then((result) => {
        if (result.data.code === 0) {
          // 0:没有模板, 1:有模板发送成功, 2:需要用户填写数据
          const { status } = result.data.data;
          if (status === 1) {
            GlobalToast.success('发送提醒成功');
          } else if (status === 2) {
            const res = result.data.data;
            // 设置全局变量 templateFillOpen 为 true, 从而渲染 TemplateFill 组件
            // GlobalToast.warning('需要用户填写数据');
            setTemplateFillData({
              templateFillOpen: true,
              template: res.messageTemplate.template,
              templateRich: res.messageTemplate.rich,
              variables: res.messageTemplate.variables,
              templateSms: res.smsTemplate.template,
              richSms: res.smsTemplate.rich,
              variablesSms: res.smsTemplate.variables,
              templateEmail: res.emailTemplate.template,
              richEmail: res.emailTemplate.rich,
              variablesEmail: res.emailTemplate.variables,
              emailSubject: res.emailTemplate.emailSubject,
              mailToAddress: res.emailTemplate.mailToAddress,
              emailTemplate: res.emailTemplate,
              candidateTokens: res.candidateTokens,
              auditStatus: res.smsTemplate.auditStatus, // 1审核中 2 审核通过 3 审核拒绝
              needFeedbackHidden: !res.hasMailFeedback,
            });
          } else {
            // GlobalToast.warning('未设置提醒模板');
          }
        } else {
          GlobalToast.error('发送提醒失败');
        }
      })
      .catch(() => {
        GlobalToast.error('发送提醒失败');
      });
  }, []);

  const handleCloseTemplateFillDialog = useCallback(() => {
    setTemplateFillData({ templateFillOpen: false });
  }, []);

  const handleSendCustomMessage = useCallback(
    (msg, msgSms, emailTemplate, emailSubject, attachments, feedbackSwitch) => {
      const data = {
        applicationTokens: sendApplicationTokens,
        messageTemplate: msg,
        smsTemplate: msgSms,
        emailTemplate: {
          template: emailTemplate,
          subject: emailSubject,
          attachments,
          feedbackSwitch,
        },
      };

      postNotificationCustomMessage(data)
        .then((result) => {
          if (result.data.code === 0) {
            GlobalToast.success('发送提醒成功');
          } else {
            GlobalToast.error('发送提醒失败');
          }
        })
        .catch(() => {
          GlobalToast.error('发送提醒失败');
        })
        .finally(() => {
          setSendApplicationTokens([]);
          // 设置全局变量 templateFillOpen 为 false, 从而移除 TemplateFill 组件
          setTemplateFillData({ templateFillOpen: false });
        });
    },
    [sendApplicationTokens],
  );

  if (!hasPermission) {
    if (isShowRecord) {
      return <CandidateRecord hasScrolled={hasScrolled} />;
    }

    if (isShowAssignablePositionsList) {
      return (
        <div style={{
          flex: '1', overflowY: 'auto', backgroundColor: 'white', borderTop: '1px solid rgb(249, 250, 249)',
        }}
        >
          <CandidateAssignablePositions />
        </div>
      );
    }

    return <HasNoPermission />;
  }

  if (
    candidateApplicationData.flows == null
    || (candidateApplicationData.flows && candidateApplicationData.flows.length === 0)
  ) {
    return (
      <div className={styles.contentMain}>
        <div className={styles.contentLeft}>
          <CandidateTabs onClose={onClose} hasScrolled={hasScrolled} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.contentMain}>
      <div className={styles.contentLeft}>
        <Box hidden={!isShowRecord} className={styles.contentLeft}>
          <CandidateRecord hasScrolled={hasScrolled} />
        </Box>
        <Box hidden={isShowRecord} className={styles.contentLeft}>
          <CandidateTabs onClose={onClose} hasScrolled={hasScrolled} checkTemplateMsg={handleSendMessage} />
        </Box>
      </div>
      <div className={styles.contentRight}>
        <CandidateSidebar hasScrolled={hasScrolled} />
        {/* <EvaluationItemDetail /> */}
      </div>
      <TemplateFillDialog
        templateFillData={templateFillData}
        handleClose={handleCloseTemplateFillDialog}
        handleSendCustomMessage={handleSendCustomMessage}
      />
    </div>
  );
}

CandidateMain.propTypes = {
  onClose: PropTypes.func,
  hasScrolled: PropTypes.bool,
};

CandidateMain.defaultProps = {
  onClose: () => {},
  hasScrolled: false,
};

export default CandidateMain;
