import { handleActions } from 'redux-actions';

import {
  setTemplateVariableList,
  setTemplateList,
  setTemplateListSelectedIndex,
  setTemplateNeedSave,
  setTemplateInfo,
  setTemplateEmailInfo,
  setProcessesList,
} from './action';

export const namespace = 'setting';

export const defaultState = {
  templateVariableList: [],
  templateList: [],
  templateListSelectedIndex: 0,
  templateNeedSave: false,
  templateInfo: {
    name: '新增模板',
    emailTemplate: {},
    messageTemplate: {},
    smsTemplate: {},
    autoSend: false,
    variables: [],
    processes: [],
    activeWechat: false,
    activeSms: false,
  },
  processesList: [],
};

export const settingReducer = handleActions(
  {
    [setTemplateVariableList]: (state, action) => {
      const { data } = action.payload;
      return { ...state, templateVariableList: data };
    },
    [setTemplateList]: (state, action) => {
      const { data } = action.payload;
      return { ...state, templateList: data.list, templateNeedSave: false };
    },
    [setTemplateListSelectedIndex]: (state, action) => {
      const index = action.payload;
      return { ...state, templateListSelectedIndex: index };
    },
    [setTemplateNeedSave]: (state, action) => ({ ...state, templateNeedSave: action.payload }),
    [setTemplateInfo]: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        templateInfo: payload || {
          name: '新增模板',
          emailTemplate: {},
          messageTemplate: {},
          smsTemplate: {},
          autoSend: false,
          variables: [],
          processes: [],
          activeWechat: false,
          activeSms: false,
        },
        templateNeedSave: false,
      };
    },
    [setTemplateEmailInfo]: (state, action) => {
      const { emailTemplate } = action.payload;
      return { ...state, templateInfo: { ...state.templateInfo, emailTemplate: { ...emailTemplate } } };
    },
    [setProcessesList]: (state, action) => {
      const { list } = action.payload;
      return { ...state, processesList: list };
    },
    // [setProcessesDetail]: (state, action) => {
    //   const { token, data } = action.payload;
    //   const temp = state.processesDetail;
    //   temp[token] = data.data;
    //   return { ...state, processesDetail: { ...temp } };
    // },
  },
  defaultState,
);
