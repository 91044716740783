import axios from './Api';

export const getPositions = (params) => axios.request({
  url: 'applicationSessions',
  method: 'GET',
  params,
});

export const importFromLinks = (data) => axios.request({
  url: '/applicationSession/importFromLinks',
  method: 'POST',
  data,
});

export const editFromLink = (data) => axios.request({
  url: '/applicationSession/updateFromLink',
  method: 'POST',
  data,
});

export const refreshPositionCode = (token, data) => axios.request({
  url: `applicationSession/${token}/code`,
  method: 'POST',
  data,
});

export const assignPositionToDepartments = (positionToken, data) => axios.request({
  url: `/applicationSession/${positionToken}/assignDepartments`,
  method: 'POST',
  data,
});

export const clonePositionToDepartment = (data) => axios.request({
  url: '/applicationSession/clone',
  method: 'POST',
  data,
});

export const addCandidateToPosition = (positionToken, data) => axios.request({
  url: `/applicationSession/${positionToken}/candidates`,
  method: 'POST',
  data,
});

export const uploadAuth = () => axios.request({
  url: '/applicationSession/uploadAuth',
  method: 'POST',
});

export const postPositonRecommend = (data) => axios.request({
  url: 'applicationSession/recommend/switch',
  method: 'POST',
  data,
});
export const getTreatments = () => axios.request({
  url: '/tag/treatments',
  method: 'GET',
});

export const editPositionProcess = (token, processTokens) => axios.request({
  url: `/vc1/applicationSession/${token}/processes`,
  method: 'POST',
  data: { processTokens },
});

export const delPositions = (tokens) => axios.request({
  url: '/applicationSession',
  method: 'DELETE',
  data: { tokens },
});

export const delPositionProcess = (token, processToken) => axios.request({
  url: `/applicationSession/${token}/process/${processToken}`,
  method: 'DELETE',
});

export const editPositionProcessTitle = (data) => axios.request({
  url: '/applicationSession/processCustom',
  method: 'POST',
  data,
});
/**
 * 岗位规则替换
 *
 * @param {*} token
 * @param {*} ruleToken
 * @returns
 */
export const postPositonRecommendRule = (token, ruleToken) => axios.request({
  url: `applicationSession/recommend/${token}/rule`,
  method: 'POST',
  data: { ruleToken },
});
export const openPositions = (tokens) => axios.request({
  url: '/applicationSession/open',
  method: 'PUT',
  data: { tokens },
});

export const closePositions = (tokens) => axios.request({
  url: '/applicationSession/close',
  method: 'PUT',
  data: { tokens },
});

export const getPositionCollection = (tokens) => axios.request({
  url: '/positions/collection',
  method: 'POST',
  data: { tokens },
});

export const getPositionFrequentlyUsed = () => axios.request({
  url: '/vc1/applicationSessions/frequentlyUsed',
  method: 'GET',
});

export const editPositionProcessWeight = (positionToken, data) => axios.request({
  url: `/applicationSession/${positionToken}/weight`,
  method: 'POST',
  data,
});

export const getPositionBackgroundImages = () => axios.request({
  url: '/position/background/images',
  method: 'GET',
});

export const getPositionCoordinate = (positionToken) => axios.request({
  url: `/applicationSession/${positionToken}/geom`,
  method: 'POST',
});
