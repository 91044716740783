import cloneDeep from 'lodash/cloneDeep';
import { handleActions } from 'redux-actions';
import {
  deleteTalent,
  deleteTalents, getTalents,
  hiddenConfirmDialog, hiddenDrawerFilter, initTalent,
  setLeftNavState, setSelectedTalents, setTalents,
  setTalentStateSelected,
  showConfirmDialog, showDrawerFilter,
} from './action';

export const namespace = 'talent';

export const defaultState = {
  talentStateSelected: 1,
  leftNavState: 0,
  isFirstLoading: true,
  loading: true,
  total: 0,
  confirmDialog: {
    open: false,
    title: '删除候选人',
    desc: '确认从系统中永久删除',
    confirmTitile: '删除',
    severity: 'warning',
    onConfirm: null,
    onCancel: null,
  },
  showDrawerFilter: false,
  filters: {
    ageFrom: 0,
    ageTo: 0,
    applyStatus: 0,
    applyTimeEnd: '',
    applyTimeStart: '',
    asc: false,
    degree: 0,
    orderBy: '',
    page: 1,
    pageSize: 50,
    searchText: '',
    sex: 0,
    source: 0,
    state: '',
    positionToken: '',
  },
  selectedTalents: [],
  selectedTalent: '',
  talents: [],
  positions: [],
  positionStatistics: {
    exist: {
      dimission: 0,
      impel: 0,
      layin: 0,
      onboard: 0,
      recall: 0,
      reject: 0,
      wait: 0,
    },
    candidateTotal: 0,
  },
  statistics: {
    exist: {
      dimission: 0,
      impel: 0,
      layin: 0,
      onboard: 0,
      recall: 0,
      reject: 0,
      wait: 0,
    },
    candidateTotal: 0,
  },
};

export const talentReducer = handleActions(
  {
    [initTalent]: () => ({ ...cloneDeep(defaultState), loading: true }),
    [getTalents]: (state, action) => {
      const { filters = {}, isShowLaoding = true } = action?.payload;
      return { ...state, loading: isShowLaoding, filters };
    },
    [setTalents]: (state, action) => {
      const {
        total, talents, statistics, positions, positionStatistics,
      } = action.payload;
      return {
        ...state,
        total,
        talents,
        statistics,
        positions,
        positionStatistics,
        loading: false,
        isFirstLoading: false,
        selectedTalents: [],
        selectedTalent: '',
      };
    },
    [deleteTalent]: (state, action) => {
      const selectedTalent = action.payload;
      return ({
        ...state,
        selectedTalent,
        confirmDialog: {
          ...state.confirmDialog,
          open: false,
          onConfirm: null,
          onCancel: null,
        },
      });
    },
    [deleteTalents]: (state) => ({
      ...state,
      confirmDialog: {
        ...state.confirmDialog,
        open: false,
        onConfirm: null,
        onCancel: null,
      },
    }),
    [showDrawerFilter]: (state) => ({ ...state, showDrawerFilter: true }),
    [hiddenDrawerFilter]: (state) => ({ ...state, showDrawerFilter: false }),
    [setSelectedTalents]: (state, action) => {
      const { selectedTalents } = action.payload;
      return { ...state, selectedTalents };
    },
    [showConfirmDialog]: (state, action) => {
      const confirmDialog = action.payload;
      return { ...state, confirmDialog };
    },
    [hiddenConfirmDialog]: (state) => ({
      ...state,
      confirmDialog: {
        ...state.confirmDialog,
        open: false,
        onConfirm: null,
        onCancel: null,
      },
    }),
    [setTalentStateSelected]: (state, action) => {
      const { talentStateSelected } = action.payload;
      return { ...state, talentStateSelected };
    },
    [setLeftNavState]: (state, action) => {
      const leftNavState = action.payload;
      return { ...state, leftNavState };
    },
  },
  cloneDeep(defaultState),
);
