import * as API from '@api/Resume';
import {
  Avatar, Box, Button, TextField, withStyles,
} from '@material-ui/core';
import { useCandidateDispatch } from '@module/CandidateModal/context';
import useGlobalToast from '@utils/GlobalToast';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { useContext, useEffect, useRef } from 'react';
import EducationSelect from './EducationSelect';
import GenderSelect from './GenderSelect';
import { ResumeContext, ResumeDispatchContext } from './ResumeContext';

let defaultEditCandidate;
const LimitedWidthTextField = withStyles(() => ({}))(TextField);

const ResumeHeader = () => {
  const { candidate = {}, editCandidate = {}, resumeURL = '' } = useContext(ResumeContext);
  const dispatch = useContext(ResumeDispatchContext);
  const GlobalToast = useGlobalToast();
  const dialogDispatch = useCandidateDispatch();
  const firstLoaded = useRef(false);

  const {
    candidateBasic = {},
    experience = [],
    educations = [],
    languages = [],
    candidateIntention = {},
    attachResume = {},
  } = editCandidate;
  const onChangeBaseic = (key, value) => {
    candidateBasic[key] = value;
    dispatch({ type: 'changeCandidateBasic', payload: { ...candidateBasic } });
  };
  const handleSaveResume = () => {
    const data = {
      ...candidateBasic,
      ...candidateIntention,
    };

    if (/^1\d+$/.test(candidateBasic.mobile)) {
      if (resumeURL) data.resume = resumeURL;
      API.editResume(candidate.token, {
        data,
        educations,
        works: experience,
        languages: languages.filter((v) => v.name.trim()),
        attachResume,
      })
        .then((v) => {
          if (v.data.code === 0) {
            GlobalToast.success('保存成功');
            dialogDispatch({ type: 'REFRESH_DATA' });
            dialogDispatch({ type: 'CANDIDATE_RESUME_EDITING', payload: false });
          } else {
            GlobalToast.error(v.data.message);
          }
        })
        .catch(() => {
          GlobalToast.error('保存失败');
        });
    } else {
      GlobalToast.warning('请输入正确的手机号');
    }
  };

  useEffect(() => {
    if (!firstLoaded.current) {
      firstLoaded.current = true;
      defaultEditCandidate = cloneDeep(editCandidate);
      dialogDispatch({ type: 'CANDIDATE_RESUME_EDITED', payload: false });
    } else {
      const hasChanged = !isEqual(defaultEditCandidate, editCandidate);

      dialogDispatch({ type: 'CANDIDATE_RESUME_EDITED', payload: hasChanged });
    }
  }, [editCandidate]);

  if (!isEmpty(editCandidate)) {
    return (
      <Box display="flex" height={136} style={{ backgroundColor: 'white' }}>
        <Box width={80} pl={2} pr={2} pt={3}>
          <Avatar sizes="24px" style={{ width: 48, height: 48 }} />
        </Box>

        <Box
          display="flex"
          flexDirection="colum"
          flexWrap="wrap"
          justifyContent="space-between"
          width={452}
          pt={3}
          pb={3}
          flexShrink={0}
          flexGrow={1}
        >
          <Box display="flex" width="100%">
            <Box width="calc(33.33% - 16px)">
              <LimitedWidthTextField
                fullWidth
                variant="outlined"
                label="姓名"
                size="small"
                margin="none"
                name="name"
                value={candidateBasic.name}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeBaseic(name, value);
                }}
                error={isEmpty(candidateBasic.name)}
              />
            </Box>
            <Box ml={3} mr={3} width="calc(33.33% - 16px)">
              <GenderSelect
                variant="outlined"
                label="性别"
                size="small"
                name="sex"
                margin="none"
                value={candidateBasic.sex}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeBaseic(name, parseInt(value, 10));
                }}
              />
            </Box>
            <Box width="calc(33.33% - 16px)">
              <LimitedWidthTextField
                fullWidth
                name="age"
                variant="outlined"
                label="年龄"
                size="small"
                margin="none"
                value={candidateBasic.age || ''}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeBaseic(name, parseInt(value.replace(/\D/g, ''), 10) || 0);
                }}
              />
            </Box>
          </Box>
          <Box display="flex" mt={2} width="100%">
            <Box width="calc(33.33% - 16px)">
              <EducationSelect
                name="degree"
                value={candidateBasic.degree}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeBaseic(name, parseInt(value, 10));
                }}
              />
            </Box>
            <Box ml={3} mr={3} width="calc(33.33% - 16px)">
              <LimitedWidthTextField
                fullWidth
                name="mobile"
                variant="outlined"
                label="手机号"
                size="small"
                value={candidateBasic.mobile}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeBaseic(name, value);
                }}
                error={isEmpty(candidateBasic.mobile)}
              />
            </Box>
            <Box width="calc(33.33% - 16px)">
              <LimitedWidthTextField
                fullWidth
                name="email"
                variant="outlined"
                label="邮箱"
                size="small"
                value={candidateBasic.email}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeBaseic(name, value);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box mt={3} display="flex" justifyContent="flex-end" width={268} flexShrink={0} flexGrow={0}>
          <Box mr={2}>
            <Button
              color="primary"
              variant="contained"
              style={{ marginRight: 8 }}
              disableElevation
              onClick={handleSaveResume}
              disabled={isEmpty(candidateBasic.name) || isEmpty(candidateBasic.mobile)}
            >
              保存
            </Button>
            <Button
              onClick={() => {
                dialogDispatch({ type: 'CANDIDATE_RESUME_EDITING', payload: false });
              }}
            >
              取消
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  return <Box display="flex" height={136} style={{ backgroundColor: 'white' }} />;
};

ResumeHeader.propTypes = {};

ResumeHeader.defaultProps = {};

export default ResumeHeader;
