import * as ACTIONS from '../constants/ActionTypes';

const initialState = {
  hrList: [
    //     {
    //  id: 0,
    //  token: '',
    //  email: '',
    //  mobile: '',
    //  name: '',
    //  role: 1,
    //  createdAt: ''
    // }
  ],
  selected: [],
};

export default function hrList(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.ADD_HR:
      return {
        hrList: [...state,
          {
            id: state.reduce((maxId, hr) => Math.max(hr.id, maxId), -1) + 1,
            token: action.token,
            email: action.email,
            mobile: action.mobile,
            name: action.name,
            role: action.role,
            createdAt: action.createdAt,
          }],
        selected: [...(state.selected)],
      };
    case ACTIONS.ADD_HR_LIST:
      let st = initialState;
      for (let i = 0; i < action.hrList.length; i += 1) {
        const hr = action.hrList[i];
        st = {
          hrList: [...(st.hrList), {
            id: st.hrList.reduce((maxId, hr) => Math.max(hr.id, maxId), -1) + 1,
            token: hr.token,
            email: hr.email,
            mobile: hr.mobile,
            name: hr.name,
            role: hr.role,
            createdAt: hr.createdAt,
          }],
          selected: [...(state.selected)],
        };
      }
      return st;
    case ACTIONS.EDIT_HR_INFO:
      return {
        hrList: state.hrList.map((hr) => (hr.id === action.id
          ? ({
            ...hr,
            token: action.token,
            name: action.name,
            status: action.status,
            createUserName: action.createUserName,
            createdAt: action.createdAt,
          }) : hr)),
        selected: [...(state.selected)],
      };
    case ACTIONS.CLEAR_HR_LIST:
      return {
        hrList: [],
        selected: [],
      };
    case ACTIONS.ADD_SELECTED_HR:
      return {
        hrList: [...(state.hrList)],
        selected: [...(state.selected), action.token],
      };
    case ACTIONS.CLEAR_SELECTED_HR_LIST:
      return {
        hrList: [...(state.hrList)],
        selected: [],
      };
    case ACTIONS.SELECTED_ALL_HR_LIST:
      return {
        hrList: [...(state.hrList)],
        selected: state.hrList.map((hr) => hr.token),
      };
    case ACTIONS.REMOVE_SELECTED_HR:
      return {
        hrList: [...(state.hrList)],
        selected: state.selected.filter((token) => token !== action.token),
      };
    case ACTIONS.GET_HR_LIST:
    default:
      return state;
  }
}
