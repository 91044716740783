import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

export const GreenButton = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    border: 'solid 1px #4caf50',
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#5AB55D',
    },
    '&:disabled': {
      backgroundColor: '#E4E4E4',
      borderColor: '#eeeeee',
    },
  },
}))(Button);

export const RedButton = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    border: 'solid 1px #e8210d',
    backgroundColor: '#e8210d',
    '&:hover': {
      backgroundColor: '#e8210d',
    },
    '&:disabled': {
      backgroundColor: '#E4E4E4',
      borderColor: '#eeeeee',
    },
  },
}))(Button);

export const AlartButton = withStyles((theme) => ({
  root: {
    color: '#d00',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    // backgroundColor: '#ffffff',
    '&:hover': {
      // backgroundColor: '#fff',
    },
    '&:disabled': {
      color: '#9E9E9E',
    },
  },
}))(Button);

export const WhiteButton = withStyles((theme) => ({
  root: {
    color: '#2E7D32',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#F8FBF8',
    },
    '&:disabled': {
      color: '#9E9E9E',
    },
  },
}))(Button);

export const GreyButton = withStyles((theme) => ({
  root: {
    color: '#333333',
    border: 'solid 1px #ededed',
    backgroundColor: '#ededed',

    '&:hover': {
      backgroundColor: '#F8FBF8',
    },
    '&:disabled': {
      color: '#9E9E9E',
    },
  },
}))(Button);

export const TransparentGreyButton = withStyles(() => ({
  root: {
    color: 'rgba(0,0,0,0.56)',
    '&:hover': {
      backgroundColor: '#F8FBF8',
    },
  },
}))(Button);

export const TransparentButton = withStyles(() => ({
  root: {
    color: '#2e7d32',
    '&:hover': {
      backgroundColor: '#F8FBF8',
    },
  },
}))(Button);

export const TransparentRedButton = withStyles(() => ({
  root: {
    color: '#F44336',
    '&:hover': {
      backgroundColor: '#F8FBF8',
    },
  },
}))(Button);

export const TransparentBlueButton = withStyles(() => ({
  root: {
    color: '#609beb',
    '&:hover': {
      backgroundColor: '#F8FBF8',
    },
  },
}))(Button);

export const EmptyButton = withStyles(() => ({
  root: {
    color: '#2e7d32',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#F8FBF8',
    },
  },
}))(Button);
