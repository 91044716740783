import { isString } from 'lodash';
import { handleActions } from 'redux-actions';
import { PAGE_SIZE_PRE, START_PAGE } from '../../../utils/constants';
import {
  changeFilterConditionShowState,
  getPostFilterAvailable,
  getPostFilterSelect,
  setBlankState,
  setCachedReviewCandidate,
  setFirstLoadingState,
  setLoadingState,
  setPostFilterAvailable,
  setPostFilterCandidateExtra,
  setPostFilterCandidateExtraUse,
  setPostFilterCandidateResumeExtra,
  setPostFilterCandidateResumeExtraUse,
  setPostFilterProcess,
  setPostFilterProcessUse,
  setPostFilterReloadUse,
  setPostFilterSelect,
  setPostFilterTagsUse,
  setPostFilterUse,
  setPostFilterUseDefault,
  setShowError,
  setShowInterviewFeedbackFilter,
  showPostFilterUseResult,
} from './action';

function transformMap(process) {
  const filtersProcess = [];
  const key = Object.keys(process);
  key.map((v) => {
    filtersProcess.push({
      token: v,
      filters: Object.keys(process[v]).reduce((p, c) => {
        const e = { ...process[v][c] };
        if (e.value !== '') {
          if (e.type === 2) {
            e.value = { pleased: e.value };
          } else if (e.type === 3) {
            e.value = { options: e.value, operation: 'OR' };
          } else if (e.type === 5) {
            e.value = {
              from: (e.value.from instanceof Date && e.value.from.setHours(0, 0, 0, 0) / 1000) || 0,
              to: (e.value.to instanceof Date && e.value.to.setHours(23, 59, 0, 0) / 1000) || 0,
            };
          }
          // BUGFIX 修复提交服务端类型问题
          // if (e.value.from !== '') {
          //   e.value.from = Number(e.value.from);
          // }
          // if (e.value.to !== '') {
          //   e.value.to = Number(e.value.to);
          // }
          p.push(e);
        }
        return p;
      }, []),
    });
    return v;
  });
  console.log(filtersProcess);
  return filtersProcess;
}

export const namespace = 'process';

export const defaultState = {
  filters: {
    page: START_PAGE,
    pageSize: PAGE_SIZE_PRE,
    positionToken: '',
    processToken: '',
    processCustomFilters: {
      feedback: 0,
      processStatus: 0,
      examStatus: 0,
      codeInterviewStatus: 0,
      customInterviewStatus: 0,
    },
    customInterview: { status: 0 },
    candidate: {
      sex: 0,
      degree: 0,
      ageFrom: 0,
      ageTo: 0,
      source: 0,
      schools: [],
      invite: 0,
    },
    arrivalTime: {},
    tags: [],
    processes: [],
    // aiScoreFilter: [],
    candidateExtra: {},
    candidateResumeExtra: {},
  },
  filterTags: [],
  filterProcesses: {},
  filterCandidateExtra: {},
  filterCandidateResumeExtra: {},
  isShowFilterItem: false,
  isShowFilterCondition: false,
  isFirstLoading: false,
  isLoading: false,
  isBlank: false,
  notShowLoading: false,
  cachedReviewCandidate: {},
  showError: false,
  showInterviewFeedbackFilter: false,
};

export const processReducer = handleActions(
  {
    [getPostFilterAvailable]: (state, action) => {
      const { data } = action.payload;
      return { ...state, data };
    },
    [setPostFilterAvailable]: (state, action) => {
      const { data } = action.payload;
      return { ...state, data };
    },
    [setPostFilterSelect]: (state, action) => {
      const { data } = action.payload;
      return { ...state, data };
    },
    [getPostFilterSelect]: (state, action) => {
      const { data } = action.payload;
      return { ...state, data };
    },
    [setPostFilterUse]: (state, action) => {
      const filters = action.payload;
      return { ...state, filters, notShowLoading: false };
    },
    [setPostFilterReloadUse]: (state, action) => ({ ...state, notShowLoading: action.payload || false }),
    [setPostFilterTagsUse]: (state, action) => {
      const { filters } = state;
      const filterTags = action.payload;
      filters.tags = filterTags.reduce((ts, t) => {
        ts.push(t.name);
        return ts;
      }, []);
      return {
        ...state,
        filters,
        filterTags,
        notShowLoading: false,
      };
    },
    [setPostFilterProcess]: (state, action) => {
      const { filters } = state;
      const filterProcesses = action.payload;
      filters.processes = transformMap(filterProcesses);
      return { ...state, filters, filterProcesses };
    },
    [setPostFilterProcessUse]: (state, action) => {
      const { filters } = state;
      const filterProcesses = action.payload;
      filters.processes = transformMap(filterProcesses);
      return {
        ...state,
        filters,
        filterProcesses,
        notShowLoading: false,
      };
    },
    [setPostFilterUseDefault]: (state, action) => {
      const filters = action.payload;
      const defaultFilters = {
        page: START_PAGE,
        pageSize: PAGE_SIZE_PRE,
        positionToken: '',
        processToken: '',
        candidate: {
          sex: 0,
          degree: 0,
          ageFrom: 0,
          ageTo: 0,
          source: 0,
          schools: [],
        },
        arrivalTime: {},
        tags: [],
        processes: [],
        // aiScoreFilter: [],
        customInterview: { status: 0 },
        processCustomFilters: {
          feedback: 0,
          processStatus: 0,
          examStatus: 0,
          codeInterviewStatus: 0,
          customInterviewStatus: 0,
        },
        candidateExtra: {},
        candidateResumeExtra: {},
        isFirstLoading: false,
        isLoading: false,
        isBlank: false,
        notShowLoading: false,
        cachedReviewCandidate: {},
        showError: false,
        showInterviewFeedbackFilter: false,
      };
      defaultState.filters = { ...defaultFilters, ...filters };
      defaultState.isShowFilterCondition = false;
      defaultState.filterTags = [];
      defaultState.filterProcesses = {};
      defaultState.filterCandidateExtra = {};
      defaultState.filterCandidateResumeExtra = {};
      return defaultState;
    },
    [changeFilterConditionShowState]: (state, action) => {
      const { isShowFilterCondition, isShowFilterItem } = action.payload;
      return { ...state, isShowFilterCondition, isShowFilterItem };
    },
    [showPostFilterUseResult]: (state, action) => {
      const { data } = action.payload;
      return {
        reviewListCount: data.total,
        reviewList: [...data.list],
        selected: [...state.selected],
        filter: state.filter,
      };
    },
    [setPostFilterCandidateExtra]: (state, action) => {
      const { filters } = state;
      const filterCandidateExtra = action.payload;
      filters.candidateExtra = {};
      filters.candidateExtra.filters = filterCandidateExtra.filters.map(({ token, value }) => ({ token, value }));
      return { ...state, filters, filterCandidateExtra };
    },
    [setPostFilterCandidateExtraUse]: (state, action) => {
      const { filters } = state;
      const filterCandidateExtra = action.payload;
      filters.candidateExtra = {};
      filters.candidateExtra.filters = filterCandidateExtra.filters.map(({ token, value, type }) => {
        if (type === 5) {
          return {
            token,
            value: {
              from:
                value.from instanceof Date
                  ? value.from.setHours(0, 0, 0, 0) / 1000 || 0
                  : (isString(value.from) && new Date(value.from).setHours(0, 0, 0, 0) / 1000) || 0,
              to:
                value.to instanceof Date
                  ? value.to.setHours(23, 59, 0, 0) / 1000 || 0
                  : (isString(value.to) && new Date(value.to).setHours(23, 59, 0, 0) / 1000) || 0,
            },
          };
        }
        if (type === 3) {
          return {
            token,
            value: {
              options: value,
            },
          };
        }
        return { token, value };
      });
      return { ...state, filters, filterCandidateExtra };
    },
    [setPostFilterCandidateResumeExtra]: (state, action) => {
      const { filters } = state;
      const filterCandidateResumeExtra = action.payload;
      filters.candidateExtra = {};
      filters.candidateExtra.filters = filterCandidateResumeExtra.filters.map(({ token, value, type }) => ({
        token,
        value,
        type,
      }));
      return {
        ...state,
        filters,
        filterCandidateResumeExtra,
        notShowLoading: false,
      };
    },
    [setPostFilterCandidateResumeExtraUse]: (state, action) => {
      const { filters } = state;

      const filterCandidateResumeExtra = action.payload;
      filters.filterCandidateResumeExtra = {};
      filters.candidateResumeExtra.filters = filterCandidateResumeExtra.filters.map(({ token, value, type }) => ({
        token,
        value,
        type,
      }));
      return {
        ...state,
        filters,
        filterCandidateResumeExtra,
        notShowLoading: false,
      };
    },
    [setFirstLoadingState]: (state, action) => {
      const isFirstLoading = action.payload;

      return { ...state, isFirstLoading };
    },
    [setLoadingState]: (state, action) => {
      const isLoading = action.payload;

      return { ...state, isLoading };
    },
    [setBlankState]: (state, action) => {
      const isBlank = action.payload;

      return { ...state, isBlank };
    },
    [setCachedReviewCandidate]: (state, action) => {
      const { key, list, total } = action.payload;
      const newState = { ...state };

      newState.cachedReviewCandidate[key] = {
        list,
        total,
      };

      return { ...state, ...newState };
    },
    [setShowError]: (state, action) => ({ ...state, showError: action.payload }),
    [setShowInterviewFeedbackFilter]: (state, action) => ({ ...state, showInterviewFeedbackFilter: action.payload }),
  },
  defaultState,
);
