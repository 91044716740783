import * as ACTIONS from '../constants/ActionTypes';
import { START_PAGE, PAGE_SIZE_PRE } from '../../utils/constants';

const initialState = {
  reviewListCount: 0,
  refreshCount: 0,
  reviewList: [],
  showTableHeaders: [],
  selected: [],
  filter: {
    processToken: 'wON8ZwUCg4YpUPR7SQ9jQ79L7T',
    orderBy: '',
    asc: '',
    searchText: '',
    sex: '',
    ageFrom: 0,
    ageTo: 0,
    degree: '',
    source: '',
    applyTimeStart: '',
    applyTimeEnd: '',
    applyStatus: '',
    page: START_PAGE,
    pageSize: PAGE_SIZE_PRE,
    // aiScoreFilter: [],
  },
};

export default function reviewList(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.ADD_REVIEW_CANDIDATES: {
      return {
        ...state,
        reviewListCount: action.reviewListCount,
        reviewList: [...action.reviewList],
        showTableHeaders: action.showTableHeaders ? [...action.showTableHeaders] : state.showTableHeaders,
      };
    }
    case ACTIONS.DROP_REVIEW_CANDIDATE:
      return {
        ...state,
        reviewList: state.reviewList.filter((candidate) => candidate.flowToken !== action.flowToken),
      };
    case ACTIONS.CLEAR_REVIEW_CANDIDATE:
      return {
        ...state,
        reviewListCount: 0,
        reviewList: [],
        selected: [],
      };
    case ACTIONS.UPDATE_REVIEW_CANDIDATE_SELECTED:
      return {
        ...state,
        selected: action.tokens,
      };
    case ACTIONS.ADD_REVIEW_CANDIDATE_SELECTED:
      return {
        ...state,
        selected: [...state.selected, action.flowToken],
      };
    case ACTIONS.DROP_REVIEW_CANDIDATE_SELECTED:
      return {
        ...state,
        selected: state.selected.filter((flowToken) => flowToken !== action.flowToken),
      };
    case ACTIONS.SELECTED_ALL_REVIEW_CANDIDATE_SELECTED:
      return {
        ...state,
        selected: state.reviewList.map((flow) => flow.token),
      };
    case ACTIONS.CLEAR_REVIEW_CANDIDATE_SELECTED:
      return {
        ...state,
        selected: [],
        refreshCount: state.refreshCount + 1,
      };
    case ACTIONS.UPDATE_REVIEW_LIST_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case ACTIONS.DISABLE_REVIEW_CANDIDATE_OUTBOUND:
      return {
        ...state,
        reviewList: [
          ...state.reviewList.map((review) => {
            if (review.token === action.applicationToken) {
              return {
                ...review,
                callStatusCode: 1, // lzl: 正在通话
              };
            }
            return review;
          }),
        ],
      };
    default:
      return state;
  }
}
