import { fork, put, takeLatest } from 'redux-saga/effects';
import * as API from '../../../api/User';
import { fetchUserInfo, setLogin, setUserSelectedDepartment } from './action';

function* getUserInfo() {
  try {
    const result = yield API.getCurrentUserInfo();
    if (result.data.code === 0) {
      yield put(setLogin(result.data.data));
    }
  } catch (error) {
    console.log(error, ' info load error');
  }
}

function* watch() {
  yield takeLatest(fetchUserInfo, getUserInfo);
  yield takeLatest(setUserSelectedDepartment, getUserInfo);
}

export default [fork(watch)];
