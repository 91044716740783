/* eslint-disable func-names */
import axios from 'axios';

export default {
  get: ({ url }) => axios({
    url,
    method: 'GET',
    headers: {
      Authorization: `Basic ${localStorage.getItem('id_token')}`,
      'X-Application-Token': localStorage.getItem('x_application_token'),
    },
  }).then((res) => res),
  post: ({ url, data, ...configs }) => {
    axios.interceptors.response.use(
      (response) =>
        // 对响应数据做点什么
        response,
      (error) =>
        // 对响应错误做点什么
        Promise.reject(error.response || error.message)
      ,
    );
    return axios({
      ...configs,
      url,
      method: 'POST',
      data,
      headers: {
        Authorization: `Basic ${localStorage.getItem('id_token')}`,
        'X-Application-Token': localStorage.getItem('x_application_token'),
      },
    })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  delete: ({ url, data }) => axios({
    url,
    method: 'DELETE',
    data,
    headers: {
      Authorization: `Basic ${localStorage.getItem('id_token')}`,
      'X-Application-Token': localStorage.getItem('x_application_token'),
    },
  }).then((res) => res),
  put: ({ url, data }) => axios({
    url,
    method: 'PUT',
    data,
    headers: {
      Authorization: `Basic ${localStorage.getItem('id_token')}`,
      'X-Application-Token': localStorage.getItem('x_application_token'),
    },
  }).then((res) => res),
  patch: ({ url, data }) => axios({
    url,
    method: 'PATCH',
    data,
    headers: {
      Authorization: `Basic ${localStorage.getItem('id_token')}`,
      'X-Application-Token': localStorage.getItem('x_application_token'),
    },
  }).then((res) => res),
  filesPut: ({
    url, data, callback, cancelCallBack,
  }) => axios({
    url,
    method: 'PUT',
    data,
    timeout: 30000,
    headers: {
      Authorization: `Basic ${localStorage.getItem('id_token')}`,
      'X-Application-Token': localStorage.getItem('x_application_token'),
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      // console.log(progressEvent)//progressEvent中包含上传信息
      callback(progressEvent);
    },
    cancelToken: new axios.CancelToken((c) => {
      // executor 函数接收一个 cancel 函数作为参数
      cancelCallBack(c);
    }),
  })
    .then((res) => res)
    .catch((res) => {
      console.log(res);
      return res;
    }),
};
