import * as API from '@api/Interview';
import * as APINotification from '@api/Notification';
import { Checkbox } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import * as DraftUtils from '@utils/DraftUtils';
import { NOTIFICATION_TYPE_EMAIL, NOTIFICATION_TYPE_SMS, NOTIFICATION_TYPE_WECHAT } from '@utils/NotificationUtil';
import {
  convertFromRaw, convertToRaw, Editor, EditorState, Modifier, SelectionState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Immutable from 'immutable';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {
  memo, useCallback, useEffect, useState,
} from 'react';
import { ReactComponent as MailSelfIcon } from '../../assets/img/icons/notify/email/Email.svg';
import { ReactComponent as SmsSelfIcon } from '../../assets/img/icons/notify/email/Sms.svg';
import InterviewersSelect from '../InterviewArrangement/InterviewersSelect';
import Spin from '../Spin';
import ELEmailEditor from './ELEmailEditor';
import EmailSendDialog from './EmailSendDialog';
import SMSSendDialog from './SMSSendDialog';

const { OrderedSet } = Immutable;
const customColorStyleMap = {
  GreenSpan: {
    color: '#4caf50',
    display: 'inline-block',
    backgroundColor: '#e8f5e9',
    padding: '4px 8px 4px 8px',
    margin: 2,
    borderRadius: 4,
    fontSize: 14,
  },
  RedSpan: {
    color: 'rgba(255, 108, 108, 1)',
    display: 'inline-block',
    backgroundColor: 'rgba(255, 108, 108, 0.1)',
    padding: '0px 4px',
    margin: '2px 0px 2px 5px',
    borderRadius: 4,
    fontSize: 14,
  },
};
function getEntities(editorState, entityType = null) {
  const content = editorState.getCurrentContent();
  const entities = [];
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());

          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              blockText: block.text,
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      },
    );
  });
  return entities;
}
// 编辑器模板变量替换
const convertToString = (editorState, templateVariableList, type = 'PlainText') => {
  const contentJson = convertToRaw(editorState.getCurrentContent());
  const serilizeContent = JSON.stringify(contentJson);

  const metions = getEntities(editorState, 'metion');
  let variables = [];
  let replaceContentState = editorState.getCurrentContent();
  const delta = {};
  metions.forEach((v) => {
    if (delta[v.blockKey] === undefined) delta[v.blockKey] = 0;
    const templateName = v.blockText.substr(v.start, v.end - v.start).trim();
    const serverTemplateName = templateVariableList.find((vv) => vv.variableName === templateName);
    if (serverTemplateName) {
      const selectionState = SelectionState.createEmpty(v.blockKey);
      const updatedSelection = selectionState.merge({
        anchorOffset: v.start + delta[v.blockKey],
        focusOffset: delta[v.blockKey] + v.end,
      });
      const replaceText = `{{.${serverTemplateName.variableDesc}}}`;
      replaceContentState = Modifier.replaceText(
        replaceContentState,
        updatedSelection,
        replaceText,
        OrderedSet(['GreenSpan']),
        v.entityKey,
      );
      delta[v.blockKey] += replaceText.length - (v.end - v.start);
      variables = variables.concat({ token: serverTemplateName.token, name: serverTemplateName.variableName });
    }
  });
  variables = variables.flat() || [];
  return {
    template:
      type === 'PlainText' ? replaceContentState.getPlainText() : draftToHtml(convertToRaw(replaceContentState)),
    variables: variables.map((v) => v.token),
    templateRich: serilizeContent,
  };
};

const sendSelfEmail = (mail, template, emailSubject, attachments, variables) => {
  const data = {
    emailTo: mail,
    template,
    subject: emailSubject,
    attachments,
    variables: variables.map((item) => item.token),
  };
  return APINotification.postNotificationMailTest(data);
};

const sendSelfSms = (mobile, template, variables) => {
  const data = {
    mobile,
    template,
    variables,
  };
  return APINotification.postNotificationSmsTest(data);
};

const NotificatIoninterview = ({
  periodToken,
  onInterviewerChange,
  onInterviewTemplateChange,
  onCandidateTemplateChange,
  onAttachmentChange,
  interviewHidden,
  setHasActiveCandidateNotification,
  setHasActiveInterviewerNotification,
  setHasSelectedInterviewers,
}) => {
  const [variables] = useState([]);
  const [activeSms, setActiveSms] = useState(true);
  const [activeWetchat, setActiveWetchat] = useState(true);
  const [activeEmail, setActiveEmail] = useState(true);
  const [activeEnterpriseSms, setActiveEnterpriseSms] = useState(true);

  const [notifyTemplate, setNotifyTemplate] = useState({});
  const [editorStateSms, setEditorStateSms] = useState(EditorState.createEmpty());
  const [editorStateWechat, setEditorStateWechat] = useState(EditorState.createEmpty());
  const [emailSubject, setEmailSubject] = useState('');
  const [editorStateEmail, setEditorStateEmail] = useState(EditorState.createEmpty());
  const [emailAttachments, setEmailAttachments] = useState([]);
  const [editorStateEnterpriseSms, setEditorStateEnterpriseSms] = useState(EditorState.createEmpty());

  const [interviewers, setInterviewers] = useState([]);
  const [activeEnterpriseEmail, setActiveEnterpriseEmail] = useState(true);
  const [enterpriseEmailSubject, setEnterpriseEmailSubject] = useState('');
  const [enterpriseEmailAttachments, setEnterpriseEmailAttachments] = useState([]);
  const [editorStateEnterpriseEmail, setEditorStateEnterpriseEmail] = useState(EditorState.createEmpty());

  const [isShowSendSmsDialog, setShowSendSmsDialog] = useState(false);
  const [isShowSendEmailDialog, setShowSendEmailDialog] = useState(false);
  const [isShowSendEnterpriseSmsDialog, setShowSendEnterpriseSmsDialog] = useState(false);
  const [isShowSendEnterpriseEmailDialog, setShowSendEnterpriseEmailDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setHasActiveCandidateNotification(activeSms || activeWetchat || activeEmail);
  }, [activeSms, activeWetchat, activeEmail]);

  useEffect(() => {
    setHasActiveInterviewerNotification(activeEnterpriseSms || activeEnterpriseEmail);
  }, [activeEnterpriseSms, activeEnterpriseEmail]);

  useEffect(() => {
    setHasSelectedInterviewers(!isEmpty(interviewers));
  }, [interviewers]);

  useEffect(() => {
    const templateInfo = convertToString(editorStateSms, variables);
    // console.log(templateInfo);
    templateInfo.switchStatus = activeSms ? 1 : 2;
    onCandidateTemplateChange(NOTIFICATION_TYPE_SMS, activeSms ? templateInfo.template : '');
  }, [editorStateSms, activeSms]);

  useEffect(() => {
    const templateInfo = convertToString(editorStateWechat, variables);
    templateInfo.switchStatus = activeWetchat ? 1 : 2;
    onCandidateTemplateChange(NOTIFICATION_TYPE_WECHAT, activeWetchat ? templateInfo.template : '');
  }, [editorStateWechat, activeWetchat]);

  useEffect(() => {
    const templateInfo = convertToString(editorStateEmail, variables, 'HTML');
    templateInfo.switchStatus = activeEmail ? 1 : 2;
    templateInfo.subject = emailSubject;
    onCandidateTemplateChange(NOTIFICATION_TYPE_EMAIL, activeEmail ? templateInfo : {});
  }, [editorStateEmail, activeEmail, emailSubject]);

  useEffect(() => {
    const templateInfo = convertToString(
      editorStateEnterpriseSms,
      variables.filter((v) => v.variableDesc !== 'examLink'),
    );
    templateInfo.switchStatus = activeEnterpriseSms ? 1 : 2;
    onInterviewTemplateChange(NOTIFICATION_TYPE_SMS, activeEnterpriseSms ? templateInfo.template : '');
  }, [editorStateEnterpriseSms, activeEnterpriseSms]);

  useEffect(() => {
    const templateInfo = convertToString(editorStateEnterpriseEmail, variables, 'HTML');
    templateInfo.switchStatus = activeEnterpriseEmail ? 1 : 2;
    templateInfo.subject = enterpriseEmailSubject;
    templateInfo.attachments = enterpriseEmailAttachments;
    onInterviewTemplateChange(NOTIFICATION_TYPE_EMAIL, activeEnterpriseEmail ? templateInfo : {});
  }, [editorStateEnterpriseEmail, activeEnterpriseEmail, enterpriseEmailSubject, enterpriseEmailAttachments]);

  const initTemplate = useCallback((template = '{}') => {
    const contentState = isEmpty(template)
      ? EditorState.createEmpty().getCurrentContent()
      : convertFromRaw(JSON.parse(template));
    return contentState;
  }, []);

  useEffect(() => {
    setLoading(true);
    API.getCandidateWithInterviewer({ periodToken })
      .then((v) => {
        if (v.data.code === 0) {
          setNotifyTemplate(v.data.data);
          const { messageTemplate, emailTemplate, smsTemplate } = v.data.data.candidateTemplate;

          const {
            interviewers: defaultInterviewers = [],
            emailTemplate: interviewEmailTemplate = {},
            smsTemplate: interviewSmsTemplate = {},
          } = v.data.data.interviewerTemplate;

          setInterviewers(defaultInterviewers);

          if (!isEmpty(smsTemplate)) {
            setEditorStateSms(
              EditorState.createWithContent(
                DraftUtils.transitionServerToEditorState(
                  EditorState.createWithContent(initTemplate(smsTemplate.rich)),
                  smsTemplate.variables,
                ),
              ),
            );
            setActiveSms(smsTemplate?.switchStatus === 1);
          }
          if (!isEmpty(messageTemplate)) {
            setEditorStateWechat(
              EditorState.createWithContent(
                DraftUtils.transitionServerToEditorState(
                  EditorState.createWithContent(initTemplate(messageTemplate?.rich)),
                  messageTemplate.variables,
                ),
              ),
            );
            setActiveWetchat(messageTemplate?.switchStatus === 1);
          }
          if (!isEmpty(emailTemplate)) {
            setEditorStateEmail(EditorState.createWithContent(initTemplate(emailTemplate?.rich)));
            setEditorStateEmail(
              EditorState.createWithContent(
                DraftUtils.transitionServerToEditorState(
                  EditorState.createWithContent(initTemplate(emailTemplate?.rich)),
                  emailTemplate.variables,
                ),
              ),
            );
            setEmailAttachments(emailTemplate.emailAttachments);
            setEnterpriseEmailSubject(emailTemplate.emailSubject);
            setActiveEmail(emailTemplate.switchStatus === 1);
          }
          if (!isEmpty(interviewSmsTemplate)) {
            setEditorStateEnterpriseSms(
              EditorState.createWithContent(
                DraftUtils.transitionServerToEditorState(
                  EditorState.createWithContent(initTemplate(interviewSmsTemplate?.rich)),
                  interviewSmsTemplate.variables,
                ),
              ),
            );
            setActiveEnterpriseSms(interviewSmsTemplate?.switchStatus === 1);
          }
          if (!isEmpty(interviewEmailTemplate)) {
            setEditorStateEnterpriseEmail(
              EditorState.createWithContent(
                DraftUtils.transitionServerToEditorState(
                  EditorState.createWithContent(initTemplate(interviewEmailTemplate?.rich)),
                  interviewEmailTemplate.variables,
                ),
              ),
            );
            setActiveEnterpriseEmail(interviewEmailTemplate?.switchStatus === 1);
            setEnterpriseEmailAttachments(interviewEmailTemplate.emailAttachments);
            setEmailSubject(interviewEmailTemplate.emailSubject);
          }
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleRemoveInertviewer = useCallback(
    (token) => {
      setInterviewers((state) => {
        const tempInterviewers = state.filter((v) => v.token !== token);
        return tempInterviewers;
      });
    },
    [onInterviewerChange],
  );

  useEffect(() => {
    onInterviewerChange(interviewers);
  }, [onInterviewerChange, interviewers]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spin />
      </Box>
    );
  }

  return (
    <Box flexGrow={1}>
      <Box>
        {/* {!interviewHidden && <Typography>通知候选人</Typography>} */}
        <Box display="flex" justifyContent="space-between" pb={0.375}>
          <FormControlLabel
            control={(
              <Checkbox
                name="activeWetchat"
                color="primary"
                checked={activeWetchat}
                onChange={(e, value) => {
                  setActiveWetchat(value);
                }}
              />
            )}
            label="发送微信通知候选人"
          />
        </Box>
        {activeWetchat && (
          <Box style={{ border: '1px solid #E6E6E6' }} height="412px" px={1.5} overflow="auto" mb={3}>
            <Box overflow="auto" flexGrow={1}>
              <Editor
                customStyleMap={customColorStyleMap}
                editorState={editorStateWechat}
                onChange={setEditorStateWechat}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Box display="flex" justifyContent="space-between" pt={1.125} pb={0.375}>
          <FormControlLabel
            control={(
              <Checkbox
                name="activeSms"
                color="primary"
                checked={activeSms}
                onChange={(e, value) => {
                  setActiveSms(value);
                }}
              />
            )}
            label="发送短信通知候选人"
          />
          <Box
            display="flex"
            style={{
              fontSize: '14px', color: '#576B95FF', cursor: 'pointer', height: 37, fontWeight: 500,
            }}
            alignItems="center"
            onClick={() => {
              setShowSendSmsDialog(true);
            }}
          >
            <SmsSelfIcon className="MuiSvgIcon-root" style={{ width: 12, height: 12, marginRight: 2 }} /> 发给自己
          </Box>
        </Box>

        {activeSms && (
          <Box style={{ border: '1px solid #E6E6E6' }} height="412px" mb={3}>
            {notifyTemplate?.candidateTemplate?.smsTemplate?.auditStatus !== 3 && (
              <Alert severity="warning">
                因运营商要求，自定义短信内容需要审核，该过程需要15-30分钟，通过审核后才可发送短信成功
              </Alert>
            )}
            {notifyTemplate?.candidateTemplate?.smsTemplate?.auditStatus === 3 && (
              <Alert severity="error">经运营商审核，该短信含敏感内容，请修改后重试</Alert>
            )}
            <Box flexGrow={1} px={1.5} overflow="auto">
              <Box height="320px" overflow="auto">
                <Editor
                  customStyleMap={customColorStyleMap}
                  editorState={editorStateSms}
                  onChange={setEditorStateSms}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Box display="flex" justifyContent="space-between" pt={1.125} pb={0.375}>
          <FormControlLabel
            control={(
              <Checkbox
                name="activeEmail"
                color="primary"
                checked={activeEmail}
                onChange={(e, value) => {
                  setActiveEmail(value);
                }}
              />
            )}
            label="发送邮件通知候选人"
          />
          <Box
            display="flex"
            style={{
              fontSize: '14px', fontWeight: '500', color: '#576B95FF', cursor: 'pointer',
            }}
            alignItems="center"
            onClick={() => {
              setShowSendEmailDialog(true);
            }}
          >
            <MailSelfIcon className="MuiSvgIcon-root" style={{ width: 12, height: 12, marginRight: 2 }} /> 发给自己
          </Box>
        </Box>
        {activeEmail && (
          <Box mb={3}>
            <ELEmailEditor
              attachments={emailAttachments}
              onAttachmentsChange={(attachments) => {
                onAttachmentChange(attachments);
                setEmailAttachments(attachments);
              }}
              customStyleMap={customColorStyleMap}
              editorState={editorStateEmail}
              onSubjectChange={(v) => {
                setEmailSubject(v);
              }}
              subject={emailSubject}
              onEditorStateChange={(editorState) => {
                setEditorStateEmail(editorState);
              }}
            />
          </Box>
        )}
      </Box>
      {!interviewHidden && (
        <Box mt={2}>
          <InterviewersSelect interviewers={interviewers} handleRemoveInertviewer={handleRemoveInertviewer} />
          <Box display="flex" justifyContent="space-between" pt={1.125} pb={0.375}>
            <FormControlLabel
              control={(
                <Checkbox
                  name="activeEnterpriseSms"
                  color="primary"
                  checked={activeEnterpriseSms}
                  onChange={(e, value) => {
                    setActiveEnterpriseSms(value);
                  }}
                />
              )}
              label="发送短信通知企业参与人员"
            />
            <Box
              display="flex"
              style={{
                fontSize: '14px', color: '#576B95FF', cursor: 'pointer', fontWeight: 500,
              }}
              alignItems="center"
              onClick={() => {
                setShowSendEnterpriseSmsDialog(true);
              }}
            >
              <SmsSelfIcon className="MuiSvgIcon-root" style={{ width: 12, height: 12, marginRight: 2 }} /> 发给自己
            </Box>
          </Box>
          {activeEnterpriseSms && (
            <Box style={{ border: '1px solid #E6E6E6' }} height="412px" display="flex" flexDirection="column">
              {notifyTemplate?.interviewTemplate?.smsTemplate?.auditStatus !== 3 && (
                <Alert severity="warning">
                  因运营商要求，自定义短信内容需要审核，该过程需要15-30分钟，通过审核后才可发送短信成功
                </Alert>
              )}
              {notifyTemplate?.interviewTemplate?.smsTemplate?.auditStatus === 3 && (
                <Alert severity="error">经运营商审核，该短信含敏感内容，请修改后重试</Alert>
              )}
              <Box flexGrow={1} px={1.5}>
                <Box height="100%">
                  <Editor
                    customStyleMap={customColorStyleMap}
                    editorState={editorStateEnterpriseSms}
                    onChange={setEditorStateEnterpriseSms}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {!interviewHidden && (
        <Box>
          <Box display="flex" justifyContent="space-between" pt={1.125} pb={0.375}>
            <FormControlLabel
              control={(
                <Checkbox
                  name="activeEmail"
                  color="primary"
                  checked={activeEnterpriseEmail}
                  onChange={(e, value) => {
                    setActiveEnterpriseEmail(value);
                  }}
                />
              )}
              label="发送邮件通知企业参与人员"
            />
            <Box
              display="flex"
              style={{
                fontSize: '14px', color: '#576B95', cursor: 'pointer', fontWeight: 500,
              }}
              alignItems="center"
              onClick={() => {
                setShowSendEnterpriseEmailDialog(true);
              }}
            >
              <MailSelfIcon className="MuiSvgIcon-root" style={{ width: 12, height: 12, marginRight: 2 }} /> 发给自己
            </Box>
          </Box>
          {activeEnterpriseEmail && (
            <Box>
              <ELEmailEditor
                attachments={enterpriseEmailAttachments}
                onAttachmentsChange={(attachments) => {
                  onAttachmentChange(attachments);
                  setEnterpriseEmailAttachments(attachments);
                }}
                customStyleMap={customColorStyleMap}
                editorState={editorStateEnterpriseEmail}
                onSubjectChange={(v) => {
                  setEnterpriseEmailSubject(v);
                }}
                subject={enterpriseEmailSubject}
                onEditorStateChange={(editorState) => {
                  setEditorStateEnterpriseEmail(editorState);
                }}
              />
            </Box>
          )}
        </Box>
      )}
      <SMSSendDialog
        open={isShowSendSmsDialog}
        onCancel={() => {
          setShowSendSmsDialog(false);
        }}
        onSend={(mobile) => {
          const templateInfo = convertToString(
            editorStateSms,
            variables.filter((v) => v.variableDesc !== 'examLink'),
          );
          sendSelfSms(mobile, templateInfo.template, templateInfo.variables)
            .then((v) => {
              if (v.data.code === 0) {
                setShowSendSmsDialog(false);
              }
            })
            .catch(console.log);
        }}
      />
      <SMSSendDialog
        open={isShowSendEnterpriseSmsDialog}
        onCancel={() => {
          setShowSendEnterpriseSmsDialog(false);
        }}
        onSend={(mobile) => {
          const templateInfo = convertToString(
            editorStateEnterpriseSms,
            variables.filter((v) => v.variableDesc !== 'examLink'),
          );
          sendSelfSms(mobile, templateInfo.template, templateInfo.variables)
            .then((v) => {
              if (v.data.code === 0) {
                setShowSendEnterpriseSmsDialog(false);
              }
            })
            .catch(console.log);
        }}
      />
      <EmailSendDialog
        open={isShowSendEmailDialog}
        onCancel={() => {
          setShowSendEmailDialog(false);
        }}
        onSend={(email) => {
          const templateInfo = convertToString(
            editorStateEmail,
            variables.filter((v) => v.variableDesc !== 'examLink'),
            'HTML',
          );
          sendSelfEmail(email, templateInfo.template, emailSubject, emailAttachments, templateInfo.variables)
            .then((v) => {
              if (v.data.code === 0) {
                setShowSendEmailDialog(false);
              }
            })
            .catch(console.log);
        }}
      />
      <EmailSendDialog
        open={isShowSendEnterpriseEmailDialog}
        onCancel={() => {
          setShowSendEnterpriseEmailDialog(false);
        }}
        onSend={(email) => {
          const templateInfo = convertToString(
            editorStateEnterpriseEmail,
            variables.filter((v) => v.variableDesc !== 'examLink'),
            'HTML',
          );
          sendSelfEmail(
            email,
            templateInfo.template,
            enterpriseEmailSubject,
            enterpriseEmailAttachments,
            templateInfo.variables,
          )
            .then((v) => {
              if (v.data.code === 0) {
                setShowSendEnterpriseEmailDialog(false);
              }
            })
            .catch(console.log);
        }}
      />
    </Box>
  );
};

NotificatIoninterview.propTypes = {
  periodToken: PropTypes.string,
  onInterviewerChange: PropTypes.func,
  onAttachmentChange: PropTypes.func,
  onCandidateTemplateChange: PropTypes.func,
  onInterviewTemplateChange: PropTypes.func,
  interviewHidden: PropTypes.bool,
  setHasActiveCandidateNotification: PropTypes.func,
  setHasActiveInterviewerNotification: PropTypes.func,
  setHasSelectedInterviewers: PropTypes.func,
};

NotificatIoninterview.defaultProps = {
  interviewHidden: false,
  periodToken: '',
  onInterviewerChange: null,
  onAttachmentChange: () => {},
  onCandidateTemplateChange: () => {},
  onInterviewTemplateChange: () => {},
  setHasActiveCandidateNotification: () => {},
  setHasActiveInterviewerNotification: () => {},
  setHasSelectedInterviewers: () => {},
};

export default memo(NotificatIoninterview);
