import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCandidateDispatch, useCandidateState } from '../context';
import { getUrlQuery } from '../utils';
import AITabs from './AITabs';
import styles from './CandidateTabs.module.css';
import CandidateTabsSkeleton from './CandidateTabsSkeleton';
import TabSwitcher from './TabSwitcher';

const CandidateTabs = ({ onClose, hasScrolled, checkTemplateMsg }) => {
  const {
    tabName,
    candidateApplicationData,
    applicationFlowData,
    isShowAssignablePositionsList,
    applicationData = {},
    applicationRecommendRewardRecordsData,
  } = useCandidateState();
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const processType = useSelector(({ candidateModal }) => candidateModal.processType);
  const dispatch = useCandidateDispatch();
  const [finalTabs, setTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const processTokenFromUrl = getUrlQuery(location, 'process');
  const { processResult = [], restoreProcess = {} } = applicationData; // currentProcess => restoreProcess 智能人才库
  const { token: currentProcessToken, type: currentProcessType } = restoreProcess;
  const processTokenFromInterviewArrangement = useSelector(
    ({ candidateModal }) => candidateModal.processTokenFromInterviewArrangement,
  );
  const processTokenForTab = currentProcessToken || processTokenFromUrl;

  useEffect(() => {
    const tabs = [];
    // 未分配面试
    const notAssigned = !applicationToken
      && (candidateApplicationData.flows == null
        || (candidateApplicationData.flows && candidateApplicationData.flows.length === 0));
    if (notAssigned) {
      tabs.push({ name: 'no_position', title: '暂无面试' });
    }
    // 未开始面试
    const hasVideoSteps = applicationFlowData.steps && applicationFlowData.steps.length > 0;
    const hasAIResults = applicationData.aiReportResult && applicationData.aiReportResult.status !== 0;
    const hasStarted = hasVideoSteps || hasAIResults;
    if (!notAssigned && !hasStarted) {
      tabs.push({ name: 'no_interview', title: '待面试' });
    }
    // 分配职位列表
    if (isShowAssignablePositionsList) {
      tabs.push({ name: 'position', title: '面试' });
    }
    if (!notAssigned && hasStarted && !isShowAssignablePositionsList) {
      tabs.push({ name: 'result', title: 'AI测评报告' });
    }
    tabs.push({ name: 'resume', title: '简历' });
    if (!isEmpty(applicationData.processResult)) {
      applicationData.processResult
        .filter((v) => [3, 5, 6, 7].indexOf(v.type) > -1)
        .forEach((v) => {
          if (v.type === 3) {
            tabs.push({ name: `form::${v.token}`, title: v.name });
          } else if (v.type === 5) {
            tabs.push({ name: `interview::${v.token}`, title: v.name });
          } else if (v.type === 6) {
            tabs.push({ name: `codeReview::${v.token}`, title: v.name });
          } else if (v.type === 7) {
            tabs.push({ name: `exam::${v.token}`, title: v.name });
          }
        });
    }
    if (applicationData.hasEvaluation) {
      tabs.push({ name: 'evaluation', title: '面试评价' });
    }
    if (!notAssigned) {
      tabs.push({ name: 'attachments', title: '附加信息' });
    }
    if (applicationRecommendRewardRecordsData.total > 0) {
      tabs.push({ name: 'referrals', title: '推荐记录' });
    }
    setTabs(tabs);
  }, [candidateApplicationData, applicationData, applicationFlowData, applicationRecommendRewardRecordsData]);

  const processResultToken = useMemo(() => {
    if (isEmpty(processResult)) return undefined;
    const findProcess = processResult.find((p) => p.processToken === processTokenForTab) || {};
    return findProcess.token;
  }, [processResult, processTokenForTab]);

  const processResultTokenFromInterviewArrangement = useMemo(() => {
    if (isEmpty(processResult) && !processTokenFromInterviewArrangement) return undefined;
    const findProcess = processResult.find((p) => p.processToken === processTokenFromInterviewArrangement) || {};
    return findProcess.token;
  }, [processResult, processTokenFromInterviewArrangement]);

  const hasAIResultsScore = useMemo(() => {
    if (isEmpty(applicationData)) return false;
    return applicationData.aiReportResult && applicationData.aiReportResult.status === 3;
  }, [applicationData]);

  const firstTabName = useMemo(() => finalTabs[0] && finalTabs[0].name, [JSON.stringify(finalTabs)]);

  useEffect(() => {
    // 通过 processToken 直接定位到对应的 Tab 栏
    let name;
    if (
      [
        'wON8ZwUCg4YpUPR7SQ9jQ79L7T',
        'jeA9idIleSjtFVPSk5ErQ79L7U',
        'U4A0YXcShXv0DlLxxg58Q79L7V',
        'UCIkPVNu57gO4fl9ykv0Q956X9',
        'VjaL28zeAZQTBDSDTijZQ956XY',
        'DhjxBr1CFM3PnmbO3PeoQ79L7W',
      ].includes(processTokenForTab)
    ) {
      if (hasAIResultsScore) {
        name = 'result';
      } else {
        name = 'resume';
      }
    } else {
      switch (processType || currentProcessType) {
        // 自定义
        case 1: {
          if (hasAIResultsScore) {
            name = 'result';
          } else {
            name = 'resume';
          }
          break;
        }
        // 待面试
        case 2: {
          if (hasAIResultsScore) {
            name = 'result';
          } else {
            name = 'resume';
          }
          break;
        }
        // 资料收集
        case 3: {
          name = `form::${processResultToken}`;
          break;
        }
        // 简历收集
        case 4: {
          name = 'resume';
          break;
        }
        // 邀约
        case 5: {
          name = `interview::${processResultToken}`;
          break;
        }
        // 代码面试
        case 6: {
          name = `codeReview::${processResultToken}`;
          break;
        }
        // 代码笔试
        case 7: {
          name = `exam::${processResultToken}`;
          break;
        }
        default: {
          name = firstTabName;
          break;
        }
      }
    }

    if (processResultTokenFromInterviewArrangement) {
      name = `interview::${processResultTokenFromInterviewArrangement}`;
    }

    dispatch({ type: 'SET_TAB_NAME', payload: name });
  }, [
    candidateToken,
    processTokenForTab,
    currentProcessToken,
    currentProcessType,
    processResultToken,
    hasAIResultsScore,
    firstTabName,
    processResultTokenFromInterviewArrangement,
    processType,
  ]);

  useEffect(() => {
    if (candidateApplicationData?.flows?.length > 0) {
      setTimeout(() => {
        setIsLoading(false);
        dispatch({ type: 'SET_IS_TABS_LOADING', payload: false });
      }, 200);
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <CandidateTabsSkeleton />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.tabs, { [styles.sticky]: hasScrolled })}>
        {finalTabs.map((t) => {
          if (t.name === 'result') {
            return (
              <AITabs
                key={t.name}
                title={t.title}
                className={t.name === tabName ? styles.active : undefined}
                onClick={() => {
                  dispatch({ type: 'SET_TAB_NAME', payload: t.name });
                }}
              />
            );
          }
          return (
            <div
              role="button"
              key={t.name}
              className={t.name === tabName ? styles.active : undefined}
              onClick={() => dispatch({ type: 'SET_TAB_NAME', payload: t.name })}
            >
              {t.title}
            </div>
          );
        })}
      </div>
      <div className={styles.content}>
        <TabSwitcher tabName={tabName} onClose={onClose} checkTemplateMsg={checkTemplateMsg} />
      </div>
    </div>
  );
};

CandidateTabs.propTypes = {
  onClose: PropTypes.func,
  hasScrolled: PropTypes.bool,
  checkTemplateMsg: PropTypes.func,
};

CandidateTabs.defaultProps = {
  onClose: () => {},
  hasScrolled: false,
  checkTemplateMsg: () => {},
};

export default CandidateTabs;
