import PropTypes from 'prop-types';
import AIResult from './AIResult';
import styles from './CandidateResult.module.css';
import NotFinishInterview from './NotFinishInterview';
import QnAResult from './QnAResult';

export default function CandidateResult({ onClose }) {
  return (
    <div className={styles.wrapper}>
      <NotFinishInterview />
      <AIResult onClose={onClose} />
      <QnAResult />
    </div>
  );
}

CandidateResult.propTypes = {
  onClose: PropTypes.func.isRequired,
};
