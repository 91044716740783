import axios from './Api';

export const getOpLogs = (data) => axios.request({
  url: '/admin/opLogs',
  method: 'POST',
  data,
});

export const getCandidateOpLogs = (candidateToken, data) => axios.request({
  url: `/candidate/${candidateToken}/opLogs`,
  method: 'POST',
  data,
});

export const getDepartmentUsers = () => axios.request({
  url: '/admin/users/at',
  method: 'GET',
});
