import produce from 'immer';
import { handleActions as raHandleActions } from 'redux-actions';

// eslint-disable-next-line import/prefer-default-export
export const handleActions = (actions, state) => raHandleActions(
  Object.keys(actions).reduce((acc, key) => {
    // eslint-disable-next-line no-param-reassign
    acc[key] = produce(actions[key]);
    return acc;
  }, {}),
  state,
);
