import { createActions } from 'redux-actions';

export const {
  setCurrentPosition,
  setCurrentPositionProcessStat,
  setProcessStatLoading,
  setCurrentPositionProcessStatFirstLoading,
  setFrequentlyUsed,
  setFrequentlyUsedFirstLoading,
  setCurrentDepartmentPositionCount,
  setCurrentDepartmentCandidateCount,
  setIsLoadingPositions,
  setIsFirstLoadingPositions,
} = createActions(
  'SET_CURRENT_POSITION',
  'SET_CURRENT_POSITION_PROCESS_STAT',
  'SET_PROCESS_STAT_LOADING',
  'SET_CURRENT_POSITION_PROCESS_STAT_FIRST_LOADING',
  'SET_FREQUENTLY_USED',
  'SET_FREQUENTLY_USED_FIRST_LOADING',
  'SET_CURRENT_DEPARTMENT_POSITION_COUNT',
  'SET_CURRENT_DEPARTMENT_CANDIDATE_COUNT',
  'SET_IS_LOADING_POSITIONS',
  'SET_IS_FIRST_LOADING_POSITIONS',
);

export default {};
