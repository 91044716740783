import { handleActions } from 'redux-actions';
import {
  setProcessManage, setShowDepartmentConfirmDialog,
  setShowDepartmentDialog,
  setShowDepartmentSwitchToDialog,
  setShowDepartmentToDialog,
  setTemplateDateNotRemind,
  setUserSelectedDepartments,
} from './action';

export const namespace = 'common';

export const defaultState = {
  isSwitch: 'false',
  to: {},
  showDepartmentDialog: false,
  userSelectedDepartments: [],
  onConfirm: null,
  templateDateNotRemind: localStorage.getItem('TEMPLATE_DATE_NOT_REMIND') || '2',
  processManage: {
    open: false,
    type: -1,
    onNeedReload: null,
    onCancel: null,
    onConfrim: null,
    index: -1,
    token: '',
    title: '',
    relationToken: '',
  },
};

export const commonReducer = handleActions(
  {
    [setUserSelectedDepartments]: (state, action) => {
      const userSelectedDepartments = action.payload;
      return {
        ...state,
        userSelectedDepartments,
        showDepartmentDialog: false,
        to: {},
        isSwitch: false,
      };
    },
    [setShowDepartmentDialog]: (state, action) => {
      const showDepartmentDialog = action.payload;
      return {
        ...state,
        showDepartmentDialog,
        to: {},
        isSwitch: false,
      };
    },
    [setShowDepartmentConfirmDialog]: (state, action) => {
      const { showDepartmentDialog, onConfirm } = action.payload;
      return {
        ...state,
        showDepartmentDialog,
        onConfirm,
        to: {},
        isSwitch: false,
      };
    },
    [setShowDepartmentToDialog]: (state, action) => {
      const { showDepartmentDialog, to } = action.payload;
      return {
        ...state,
        showDepartmentDialog,
        to,
        isSwitch: false,
      };
    },
    [setShowDepartmentSwitchToDialog]: (state, action) => {
      const { showDepartmentDialog, to } = action.payload;
      return {
        ...state,
        showDepartmentDialog,
        to,
        isSwitch: true,
      };
    },
    [setTemplateDateNotRemind]: (state, action) => {
      const notRemind = action.payload;
      return { ...state, templateDateNotRemind: notRemind };
    },
    [setProcessManage]: (state, action) => {
      const processManage = action.payload;
      return { ...state, processManage };
    },
  },
  defaultState,
);
