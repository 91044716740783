import { Paper } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import { useCandidateState } from '../context';
import { useEvaluationState } from './context';
import EvaluationItemDetail from './EvaluationItemDetail';
import TemplateItemDetail from './TemplateItemDetail';

function EvaluationDetail() {
  const candidateState = useCandidateState();
  const { applicationEvaluationData = {} } = candidateState;
  const { list = [] } = applicationEvaluationData;
  const selectedEvaluationToken = useEvaluationState((state) => state.selectedEvaluationToken);
  const selectedTemplateToken = useEvaluationState((state) => state.selectedTemplateToken);

  if (isEmpty(list)) {
    return null;
  }

  return (
    <>
      {(selectedTemplateToken || selectedEvaluationToken) && (
        <Paper
          elevation={4}
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            zIndex: 9999,
            background: 'white',
            width: 326,
            minWidth: 326,
            maxWidth: 350,
            height: '100%',
            overflow: 'scroll',
          }}
        >

          {selectedTemplateToken && <TemplateItemDetail />}
          {selectedEvaluationToken && <EvaluationItemDetail />}
        </Paper>
      )}
    </>
  );
}

export default memo(EvaluationDetail);
