import { withStyles } from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';

const ListItem = withStyles({
  container: {
    width: '100%',
  },
})(MuiListItem);

ListItem.propTypes = {
  children: PropTypes.node,
};

ListItem.defaultProps = {
  children: null,
};

export default ListItem;
