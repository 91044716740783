import { withStyles } from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';

const ListItem = withStyles((theme) => ({
  root: {
    '&$selected': {
      backgroundColor: 'white',
    },
  },
  selected: {
    color: theme.palette.primary.main,

  },
}))(MuiListItem);
export default ListItem;
