import PropTypes from 'prop-types';
import styles from './CandidateResult.module.css';

function TheBalls({ show }) {
  return (
    <>
      {show && (
        <>
          <div className={styles.ball1}>
            <svg width="62" height="62" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient x1="145.809%" y1="-18.673%" x2="16.107%" y2="83.908%" id="ball-1">
                  <stop stopColor="#B9FFBE" offset="0%" />
                  <stop stopColor="#EDFFEE" offset="100%" />
                </linearGradient>
              </defs>
              <circle cx="60" cy="60" r="31" transform="translate(-29 -29)" fill="url(#ball-1)" fillRule="evenodd" />
            </svg>
          </div>
          <div className={styles.ball2}>
            <svg width="20" height="10" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient x1="50%" y1="-275.398%" x2="50%" y2="43.028%" id="ball-2">
                  <stop stopColor="#8FFF96" offset="0%" />
                  <stop stopColor="#EDFFEE" offset="100%" />
                </linearGradient>
              </defs>
              <circle
                transform="matrix(-1 0 0 1 83 -50)"
                cx="73"
                cy="60"
                r="10"
                fill="url(#ball-2)"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </>
      )}
      <div className={styles.ball3}>
        <svg width="62" height="62" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="145.809%" y1="-18.673%" x2="16.107%" y2="83.908%" id="ball-3">
              <stop stopColor="#B9FFBE" offset="0%" />
              <stop stopColor="#EDFFEE" offset="100%" />
            </linearGradient>
          </defs>
          <circle cx="60" cy="60" r="31" transform="translate(-29 -29)" fill="url(#ball-3)" fillRule="evenodd" />
        </svg>
      </div>
    </>
  );
}

TheBalls.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default TheBalls;
