import * as API from '@api/Exams';
import { AddIcon } from '@avocadoui/icons';
import { Button, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CANCELED_PROCESS_APPLICATION_STATE } from '@module/CandidateModal/const';
import { useCandidateState } from '@module/CandidateModal/context';
import useGlobalToast from '@utils/GlobalToast';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePickerDialog from './DatePickerDialog';
import WrittenCreate from './WrittenCreate';
import WrittenTabCard from './WrittenTabCard';

const GrayBorderButton = withStyles(() => ({
  root: {
    border: ' 1px solid #EDEDED',
  },
}))(Button);

const WrittenTab = ({ applicationProcessToken, checkTemplateMsg }) => {
  const GlobalToast = useGlobalToast();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  // const isCanceled = useCandidateState((state) => state.applicationData.applicationState === 2);
  const isCanceled = useCandidateState((state) => CANCELED_PROCESS_APPLICATION_STATE
    .indexOf(state.applicationData.applicationState) > -1);

  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHistory, setIsHistory] = useState(true);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showEditDateDialog, setShowEditDateDialog] = useState(false);
  const [currentExam, setCurrentExam] = useState({});

  const reloadData = () => {
    API.getExams(applicationProcessToken)
      .then((v) => {
        if (v.data.code === 0) {
          setIsHistory(v.data.data.isHistory);
          setExams(v.data.data.list || []);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const createxam = (data) => {
    API.createExam({ ...data, applicationProcessToken })
      .then((v) => {
        if (v.data.code === 0) {
          reloadData();
          checkTemplateMsg([applicationToken]);
        } else {
          GlobalToast.error('创建失败');
        }
      })
      .catch(console.log);
  };

  const editExam = (token, data) => {
    API.editExam(token, data)
      .then((v) => {
        if (v.data.code === 0) {
          reloadData();
        }
      })
      .catch(console.log);
  };

  const deleteExam = (token) => {
    API.deleteExam([token])
      .then((v) => {
        if (v.data.code === 0) {
          reloadData();
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (applicationProcessToken) {
      setLoading(true);
      reloadData();
    }
  }, [applicationProcessToken]);

  return (
    <Box width="100%" height="100%">
      {!loading && isEmpty(exams) && (
        <Box
          display="flex"
          flexGrow={1}
          height="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <img src="//webcdn.fbmms.cn/img/waitExam.png" alt="" />
          <Typography variant="body1" style={{ marginTop: 28, fontWeight: 500 }}>
            候选人暂无代码笔试安排
          </Typography>
          {!isHistory && (
            <Typography variant="body2" color="textSecondary">
              请尝试发起代码笔试为候选人安排笔试吧
            </Typography>
          )}
          {!isHistory && (
            <Button
              variant="outlined"
              size="large"
              color="primary"
              style={{ marginTop: 16 }}
              onClick={() => {
                setShowCreateDialog(true);
              }}
              disabled={isCanceled}
            >
              发起代码笔试
            </Button>
          )}
        </Box>
      )}
      {!isEmpty(exams) && (
        <Box display="flex" height="100%" alignItems="center" flexDirection="column" width="100%">
          <Box
            width="100%"
            height="64px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flexShrink={0}
            px={2}
          >
            {!isHistory && (
              <GrayBorderButton
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  setShowCreateDialog(true);
                }}
                disabled={isCanceled}
              >
                发起在线代码笔试
              </GrayBorderButton>
            )}
          </Box>

          {exams.map((v) => (
            <WrittenTabCard
              item={v}
              key={v.token}
              onInviterChange={(interviewerName) => {
                editExam(v.token, { interviewerName });
              }}
              onDateChange={() => {
                setCurrentExam(v);
                setShowEditDateDialog(true);
              }}
              onDelete={(token) => {
                deleteExam(token);
              }}
              onNeedReload={reloadData}
            />
          ))}
        </Box>
      )}
      {showCreateDialog && (
        <WrittenCreate
          open={showCreateDialog}
          onCancel={() => {
            setShowCreateDialog(false);
          }}
          onConfirm={(data) => {
            createxam({ ...data, paperToken: data.paperTokens[0] });
            setShowCreateDialog(false);
          }}
        />
      )}
      {showEditDateDialog && (
        <DatePickerDialog
          title="修改代码笔试时间"
          value={new Date(currentExam.planTime * 1000)}
          item={currentExam}
          open={showEditDateDialog}
          onCancel={() => {
            setShowEditDateDialog(false);
          }}
          onConfirm={(planTime) => {
            editExam(currentExam.token, { planTime });
            setShowEditDateDialog(false);
          }}
        />
      )}
    </Box>
  );
};

WrittenTab.propTypes = {
  applicationProcessToken: PropTypes.string.isRequired,
  checkTemplateMsg: PropTypes.func.isRequired,
};

WrittenTab.defaultProps = {};

export default WrittenTab;
