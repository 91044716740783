import { TextField } from '@material-ui/core';
import { LocalizationProvider, MobileDatePicker } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { zhCN } from 'date-fns/locale';
import PropTypes from 'prop-types';

const GenderSelect = ({
  name, value, label, onChange,
}) => (
  <LocalizationProvider dateAdapter={DateFnsAdapter} locale={zhCN}>
    <MobileDatePicker
      disableCloseOnSelect={false}
      showToolbar={false}
      error={false}
      views={['year', 'month']}
      inputFormat="yyyy/MM"
      mask="__/____"
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      okText="确定"
      cancelText="取消"
      onError={() => { }}
      renderInput={(props) => <TextField margin="dense" fullWidth variant="outlined" {...props} helperText="" />}
    />
  </LocalizationProvider>
);
GenderSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

GenderSelect.defaultProps = {
  name: 'sex',
  value: '',
  label: '日期',
  onChange: () => {},
};

export default GenderSelect;
