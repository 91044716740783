import axios from 'axios';

export default {
  get: ({ url }) => axios({
    url,
    method: 'GET',
    headers: {
      Authorization: `Basic ${localStorage.getItem('interViewer_token')}`,
      'X-Application-Token': `${localStorage.getItem('x_application_token')}`,
    },
  }).then((res) => res),
  post: ({ url, data }) => axios({
    url,
    method: 'POST',
    data,
    headers: {
      Authorization: `Basic ${localStorage.getItem('interViewer_token')}`,
      'X-Application-Token': `${localStorage.getItem('x_application_token')}`,
    },
  }).then((res) => res),
  loginpost: ({ url, data }) => axios({
    url,
    method: 'POST',
    data,
  }).then((res) => res),
  delete: ({ url, data }) => axios({
    url,
    method: 'DELETE',
    data,
    headers: {
      Authorization: `Basic ${localStorage.getItem('interViewer_token')}`,
      'X-Application-Token': `${localStorage.getItem('x_application_token')}`,
    },
  }).then((res) => res),
  put: ({ url, data }) => axios({
    url,
    method: 'PUT',
    data,
    headers: {
      Authorization: `Basic ${localStorage.getItem('interViewer_token')}`,
      'X-Application-Token': `${localStorage.getItem('x_application_token')}`,
    },
  }).then((res) => res),
  patch: ({ url, data }) => axios({
    url,
    method: 'PATCH',
    data,
    headers: {
      Authorization: `Basic ${localStorage.getItem('interViewer_token')}`,
      'X-Application-Token': `${localStorage.getItem('x_application_token')}`,
    },
  }).then((res) => res),
};
