import { createActions } from 'redux-actions';

export const {
  initTalent,
  getTalents,
  setTalents,
  deleteTalent,
  deleteTalents,
  showDrawerFilter,
  hiddenDrawerFilter,
  setTalentStateSelected,
  setSelectedTalents,
  showConfirmDialog,
  hiddenConfirmDialog,
  setLeftNavState,
  reloadDataTalent,
} = createActions(
  'INIT_TALENT',
  'GET_TALENTS',
  'SET_TALENTS',
  'DELETE_TALENT',
  'DELETE_TALENTS',
  'SHOW_DRAWER_FILTER',
  'HIDDEN_DRAWER_FILTER',
  'SET_TALENT_STATE_SELECTED',
  'SET_SELECTED_TALENTS',
  'SHOW_CONFIRM_DIALOG',
  'HIDDEN_CONFIRM_DIALOG',
  'SET_LEFT_NAV_STATE',
  'RELOAD_DATA_TALENT',
);

export default {};
