import { getApplicationLogs } from '@api/Application';
import { deleteComment } from '@api/Process';
import Typography from '@components/Avocado/Typography';
import {
  Badge,
  Box,
  Button,

  Paper,
  Popover,

  Tooltip as MuiTooltip,
  withStyles,
} from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useCandidateDispatch } from '../context';

const Tooltip = withStyles(() => ({
  tooltip: {
    marginBottom: 2,
    marginRight: 0,
  },
}))(MuiTooltip);

const GreenBadge = withStyles(() => ({
  badge: {
    right: 2,
    top: 2,
    width: 4,
    minWidth: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: '#4caf50',
    border: '1px solid transparent',
    zIndex: 0,
  },
}))(Badge);

const GreyBadge = withStyles(() => ({
  badge: {
    right: 2,
    top: 2,
    width: 4,
    minWidth: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    border: '1px solid transparent',
    zIndex: 0,
  },
}))(Badge);

function LogItem({ item }) {
  const {
    token, event, username, createdAt, info, readStates, userToken,
  } = item;

  const dispatch = useCandidateDispatch();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const currentUserToken = useSelector(({ account }) => account?.userInfo?.user?.token);

  function handleGetApplicationLogs(_applicationToken) {
    getApplicationLogs(_applicationToken).then((result) => {
      if (result.data.code === 0) {
        dispatch({
          type: 'SET_APPLICATION_LOGS_DATA',
          payload: result.data.data.list,
        });
      }
    });
  }

  const handleDeleteComment = (_logToken) => {
    deleteComment(_logToken).then((result) => {
      if (result.data.code === 0) {
        handleGetApplicationLogs(applicationToken);
      }
    });
  };

  if (event === 1) {
    let source = info;
    const reg = /\{\{.*?\}\}/g;
    const wordTokens = source.split(reg);
    const atTokens = [];
    let result = reg.exec(source);
    while (result !== null) {
      const name = result[0];
      atTokens.push(name);
      source = source.replace(item, `{{${name.substr(2, name.length - 4)}}}`);
      result = reg.exec(source);
    }

    return (
      <Box mb={1} p={1} style={{ backgroundColor: '#f4f4f4', borderRadius: '4px' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="caption" color="text.primary" fontWeight="fontWeightMedium">
            {username}
          </Typography>
          {currentUserToken === userToken && (
            <PopupState>
              {(popupState) => (
                <>
                  <Typography
                    variant="caption"
                    color="text.link"
                    style={{ cursor: 'pointer' }}
                    {...bindTrigger(popupState)}
                  >
                    删除
                  </Typography>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Paper style={{ width: '300px', padding: '16px' }}>
                      <Typography variant="body1" color="text.secondary">
                        确认删除此评论吗？删除后@相关通知将不再展示
                      </Typography>
                      <Box display="flex" justifyContent="flex-end" mt="36px">
                        <Button onClick={popupState.close}>取消</Button>
                        <Box mr={1} />
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            popupState.close();
                            handleDeleteComment(token);
                          }}
                        >
                          删除
                        </Button>
                      </Box>
                    </Paper>
                  </Popover>
                </>
              )}
            </PopupState>
          )}
        </Box>
        <Box>
          <Typography variant="caption" color="text.secondary">
            {moment(parseInt(createdAt * 1000, 10)).format('MM月DD日 HH:mm')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="text.primary">
            {wordTokens.map((word, wordIndex) => (
              <>
                <span style={{ whiteSpace: 'pre-wrap' }}>{word}</span>
                {atTokens[wordIndex] ? (
                  <Tooltip title={readStates[wordIndex] ? '已读' : '未读'} placement="top-center">
                    {readStates[wordIndex] ? (
                      <GreenBadge color="primary" variant="dot">
                        <span
                          style={{
                            padding: '0px 4px',
                            color: '#3371ff',
                            cursor: 'pointer',
                            whiteSpace: 'pre',
                          }}
                        >
                          {`@${atTokens[wordIndex].substr(2, atTokens[wordIndex].length - 4)}`}
                        </span>
                      </GreenBadge>
                    ) : (
                      <GreyBadge color="primary" variant="dot">
                        <span
                          style={{
                            padding: '0px 4px',
                            color: '#3371ff',
                            cursor: 'pointer',
                            whiteSpace: 'pre',
                          }}
                        >
                          {`@${atTokens[wordIndex].substr(2, atTokens[wordIndex].length - 4)}`}
                        </span>
                      </GreyBadge>
                    )}
                  </Tooltip>
                ) : (
                  ''
                )}
              </>
            ))}
          </Typography>
        </Box>
      </Box>
    );
  }

  if (event === 9) {
    return (
      <Box mb={1}>
        <Box>
          <Typography variant="caption" color="text.primary">
            {username}
            {info}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="text.secondary">
            {moment(parseInt(createdAt * 1000, 10)).format('MM月DD日 HH:mm')}
          </Typography>
        </Box>
      </Box>
    );
  }

  return null;
}

LogItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default LogItem;
