import axios from './Api';

export const getMineEvaluations = (applicationToken) => axios.request({
  url: `/applicationEvaluations/mine?applicationToken=${applicationToken}`,
  method: 'GET',
});
// 职位面试评价模板列表
export const EvaluationTemplateList = (positionToken) => axios.request({
  url: `/applicationEvaluation/tpls?positionToken=${positionToken}`,
  method: 'GET',
});
export const getEvaluationTemplateList = () => axios.request({
  url: '/applicationEvaluation/tpls',
  method: 'GET',
});

export const getEvaluationTemplate = (tplToken) => axios.request({
  url: `/applicationEvaluation/tpl/${tplToken}`,
  method: 'GET',
});

export const editEvaluationTemplate = (tplToken, data) => axios.request({
  url: `/applicationEvaluation/tpl/${tplToken}`,
  method: 'POST',
  data,
});

export const postEvaluationTemplate = (data) => axios.request({
  url: '/applicationEvaluation/tpl',
  method: 'POST',
  data,
});

export const deleteEvaluationTemplate = (data) => axios.request({
  url: '/applicationEvaluation/tpl',
  method: 'DELETE',
  data,
});

export const getEvaluationDetail = (evaluationtoken) => axios.request({
  url: `/applicationEvaluation/${evaluationtoken}`,
  method: 'GET',
});

export const newEvaluationDetail = (data) => axios.request({
  url: '/applicationEvaluation',
  method: 'POST',
  data,
});

export const updateEvaluationDetail = (token, data) => axios.request({
  url: `/vc1/applicationEvaluation/${token}`,
  method: 'POST',
  data,
});

export const deleteEvaluationDetail = (data) => axios.request({
  url: '/applicationEvaluation',
  method: 'DELETE',
  data,
});

export const getEvaluations = (applicationToken) => axios.request({
  url: `/applicationEvaluations?applicationToken=${applicationToken}`,
  method: 'GET',
});

export const getEvaluationList = (applicationToken) => axios.request({
  url: `/application/${applicationToken}/applicationEvaluation/list`,
  method: 'GET',
});

export const exportEvaluation = (applicationToken) => axios.request({
  url: `/application/${applicationToken}/applicationEvaluation/export`,
  method: 'GET',
  responseType: 'blob',
});

export const getJobEvaluation = (data) => axios.request({
  url: '/applicationEvaluation/positions/processes',
  method: 'POST',
  data,
});

export const getEvaluationTplPositions = () => axios.request({
  url: '/applicationEvaluation/tpl/positions',
  method: 'GET',
});
