import axios from './Api';

export const getFormWithAnswer = (formToken, cancelToken) => axios.request({
  url: `/form/getWithAnswer?token=${formToken}`,
  method: 'GET',
  cancelToken,
});

export const submitFormWithScore = (data) => axios.request({
  url: '/vc1/form/submit',
  method: 'POST',
  data,
});

export const getFormItemRelation = (data) => axios.request({
  url: '/form/relation',
  method: 'POST',
  data,
});
