import axios from './Api';

export const postNotificationMessage = (data) => axios.request({
  url: '/notification/message',
  method: 'POST',
  data,
});

export const postNotificationCustomMessage = (data) => axios.request({
  url: '/notification/customMessage',
  method: 'POST',
  data,
});

export const postNotificationMailTest = (data) => axios.request({
  url: '/inbox/notifyTemplate2/emailTest',
  method: 'POST',
  data,
});

export const postNotificationSmsTest = (data) => axios.request({
  url: '/inbox/notifyTemplate2/smsTest',
  method: 'POST',
  data,
});

export const postInterviewNotificationMailTest = (data) => axios.request({
  url: '/customInterview/notifyTemplate/emailTest',
  method: 'POST',
  data,
});
// 发送面试评价提醒
export const postInterviewEvaluationRemind = (data) => axios.request({
  url: '/notification/applicationEvaluation',
  method: 'POST',
  data,
});

// 发送单个提醒
export const postInterviewOneEvaluationRemind = (data) => axios.request({
  url: '/notification/applicationEvaluation/one',
  method: 'POST',
  data,
});

// 判断是否有提醒列表
export const RemindShow = (data) => axios.request({
  url: '/applicationEvaluation/notification/application/check',
  method: 'POST',
  data,
});

// 全部提醒
export const RemindAll = (data) => axios.request({
  url: '/applicationEvaluation/notificationAll',
  method: 'POST',
  data,
});
// 列表单个提醒
export const RemindOneEvaluation = (data) => axios.request({
  url: '/applicationEvaluation/notificationOne',
  method: 'POST',
  data,
});
