import { CancelIcon, CheckCircleIcon } from '@avocadoui/icons';
import {
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';

function StepIcon({
  children, isDone = false, isActive = false, isFailed = false, isCanceled = false,
}) {
  if (isFailed) {
    const color = isCanceled ? 'disabled' : 'error';
    return <CancelIcon color={color} viewBox="2 2  20 20" />;
  }

  if (isDone && !isActive) {
    const color = isCanceled ? 'disabled' : 'primary';
    return <CheckCircleIcon color={color} />;
  }

  return (
    <Box
      style={{
        width: '20px',
        height: '20px',
        margin: '2px',
        borderRadius: '50%',
        backgroundColor: isActive && !isCanceled ? '#4CAF50' : 'rgba(0,0,0,0.26)',
        color: 'white',
        fontSize: '12px',
        fontWeight: 900,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
}

StepIcon.propTypes = {
  children: PropTypes.node.isRequired,
  isDone: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool,
  isCanceled: PropTypes.bool,
};

StepIcon.defaultProps = {
  isFailed: false,
  isCanceled: false,
};

export default StepIcon;
