import { useMemo } from 'react';

export default function useMemoFormAnswers(formData) {
  return useMemo(
    () => Object.keys(formData)
      .map((token) => {
        const item = formData[token];

        if (item.type === 'question') {
          return {
            formItemToken: token,
            formAnswer:
                item.score >= 0
                  ? {
                    answer: item.value,
                    score: item.score,
                  }
                  : {
                    answer: item.value,
                  },
          };
        }

        if (item.type === 'radio' || item.type === 'checkbox') {
          return {
            formItemToken: token,
            formAnswer: {
              choiceOptions: item.value,
            },
          };
        }

        return null;
      })
      .filter((v) => !!v),
    [formData],
  );
}
