/**
 * 输入文件名返回后缀
 * Function
 * input QueryString
 * @return {string}
 */
export function getSuffix(name) {
  let str = name;
  const index = str.lastIndexOf('.');
  str = str.substring(index + 1, str.length);
  return str;
}

export default {};
