export const GET_CANDIDATE = 'GET_CANDIDATE';
export const ADD_CANDIDATE = 'ADD_CANDIDATE';
export const ADD_CANDIDATES = 'ADD_CANDIDATES';
export const EDIT_CANDIDATE = 'EDIT_CANDIDATE';
export const DELETE_CANDIDATE = 'DELETE_CANDIDATE';
export const CLEAR_CANDIDATES = 'CLEAR_CANDIDATES';
export const GET_CANDIDATES = 'GET_CANDIDATES';
export const ADD_SELECTED_CANDIDATE = 'ADD_SELECTED_CANDIDATE';
export const CLEAR_SELECTED_CANDIDATE = 'CLEAR_SELECTED_CANDIDATE';
export const REMOVE_SELECTED_CANDIDATE = 'REMOVE_SELECTED_CANDIDATE';
export const SELECTED_ALL_CANDIDATE = 'SELECTED_ALL_CANDIDATE';
export const UPDATE_SELECTED_CANDIDATE = 'UPDATE_SELECTED_CANDIDATE';
export const UPDATE_CANDIDATES_FILTER = 'UPDATE_CANDIDATES_FILTER';
export const CLEAR_CANDIDATES_FILTER = 'CLEAR_CANDIDATES_FILTER';
export const ADD_CANDIDATE_GROUP = 'ADD_CANDIDATE_GROUP';
export const EDIT_CANDIDATE_GROUP = 'EDIT_CANDIDATE_GROUP';
export const GET_CANDIDATE_GROUP = 'GET_CANDIDATE_GROUP';
export const DELETE_CANDIDATE_GROUP = 'DELETE_CANDIDATE_GROUP';
export const ADD_HR = 'ADD_HR';
export const ADD_HR_LIST = 'ADD_HR_LIST';
export const EDIT_HR_INFO = 'EDIT_HR_INFO';
export const CLEAR_HR_LIST = 'CLEAR_HR_LIST';
export const ADD_SELECTED_HR = 'ADD_SELECTED_HR';
export const CLEAR_SELECTED_HR_LIST = 'CLEAR_SELECTED_HR_LIST';
export const REMOVE_SELECTED_HR = 'REMOVE_SELECTED_HR';
export const SELECTED_ALL_HR_LIST = 'SELECTED_ALL_HR_LIST';
export const GET_HR_LIST = 'GET_HR_LIST';
export const ADD_PATTERN = 'ADD_PATTERN';
export const GET_PATTERNS_COMMON = 'GET_PATTERNS_COMMON';
export const GET_PATTERNS_CUSTOM = 'GET_PATTERNS_CUSTOM';
export const GET_PATTERN = 'GET_PATTERN';
export const EDIT_PATTERN = 'EDIT_PATTERN';
export const DELETE_PATTERN = 'DELETE_PATTERN';
export const ADD_SESSION = 'ADD_SESSION';
export const ADD_SESSIONS = 'ADD_SESSIONS';
export const EDIT_APPLICATION_SESSION = 'EDIT_APPLICATION_SESSION';
export const CLEAR_LOCAL_SESSION = 'CLEAR_LOCAL_SESSION';
export const GET_APPLICATION_SESSIONS = 'GET_APPLICATION_SESSIONS';
export const ADD_SELECTED_PROCESS = 'ADD_SELECTED_PROCESS';
export const CLEAR_SELECTED_PROCESS = 'CLEAR_SELECTED_PROCESS';
export const REMOVE_SELECTED_PROCESS = 'REMOVE_SELECTED_PROCESS';
export const SELECTED_ALL_PROCESS = 'SELECTED_ALL_PROCESS';
export const UPDATE_PROCESS_FILTER = 'UPDATE_PROCESS_FILTER';
export const CLEAR_PROCESS_FILTER = 'CLEAR_PROCESS_FILTER';
export const GET_APPLICATION_SESSION = 'GET_APPLICATION_SESSION';
export const GET_PATTERN_TAGS = 'GET_PATTERN_TAGS';
export const ADD_TO_APPLICATION_SESSION = 'ADD_TO_APPLICATION_SESSION';
export const DELETE_FROM_APPLICATION_SESSION = 'DELETE_FROM_APPLICATION_SESSION';
export const POST_APPLICATION_SESSION = 'POST_APPLICATION_SESSION';
export const GET_CANDIDATE_OF_APPLICATION = 'GET_CANDIDATE_OF_APPLICATION';
export const GET_APPLICATION_FLOW = 'GET_APPLICATION_FLOW';
export const GET_APPLICATION_LOGS = 'GET_APPLICATION_LOGS';
export const ADD_APPLICATION_FLOW_COMMENT = 'ADD_APPLICATION_FLOW_COMMENT';
export const EDIT_APPLICATION_FLOW_COMMENT = 'EDIT_APPLICATION_FLOW_COMMENT';
export const EDIT_FLOW_STEP_STATUS = 'EDIT_FLOW_STEP_STATUS';
export const EDIT_FLOW_STATUS = 'EDIT_FLOW_STATUS';
export const ADD_REVIEW_CANDIDATE = 'ADD_REVIEW_CANDIDATE';
export const ADD_REVIEW_CANDIDATES = 'ADD_REVIEW_CANDIDATES';
export const DROP_REVIEW_CANDIDATE = 'DROP_REVIEW_CANDIDATE';
export const CLEAR_REVIEW_CANDIDATE = 'CLEAR_REVIEW_CANDIDATE';
export const UPDATE_REVIEW_CANDIDATE_SELECTED = 'UPDATE_REVIEW_CANDIDATE_SELECTED';
export const ADD_REVIEW_CANDIDATE_SELECTED = 'ADD_REVIEW_CANDIDATE_SELECTED';
export const DROP_REVIEW_CANDIDATE_SELECTED = 'DROP_REVIEW_CANDIDATE_SELECTED';
export const SELECTED_ALL_REVIEW_CANDIDATE_SELECTED = 'SELECTED_ALL_REVIEW_CANDIDATE_SELECTED';
export const CLEAR_REVIEW_CANDIDATE_SELECTED = 'CLEAR_REVIEW_CANDIDATE_SELECTED';
export const UPDATE_REVIEW_LIST_FILTER = 'UPDATE_REVIEW_LIST_FILTER';
export const DISABLE_REVIEW_CANDIDATE_OUTBOUND = 'DISABLE_REVIEW_CANDIDATE_OUTBOUND';
export const UPDATE_PROCESS_STATS = 'UPDATE_PROCESS_STATS';
export const UPDATE_APP_EVALUATION_TPLS = 'UPDATE_APP_EVALUATION_TPLS';
export const UPDATE_CURRENT_PROCESS_TOKEN = 'UPDATE_CURRENT_PROCESS_TOKEN';
export const RELOAD_PROCESSES = 'RELOAD_PROCESSES';

// DASHBOARD_SWITCH
export const UPDATE_MENU_SWITCH = 'UPDATE_MENU_SWITCH';
export const UPDATE_ADD_CANDIDATE_SWITCH = 'UPDATE_ADD_CANDIDATE_SWITCH';
export const UPDATE_IMPORT_CANDIDATE_SWITCH = 'UPDATE_IMPORT_CANDIDATE_SWITCH';
export const UPDATE_IMPORT_POSITION_SWITCH = 'UPDATE_IMPORT_POSITION_SWITCH';
export const UPDATE_IMPORT_STAFF_SWITCH = 'UPDATE_IMPORT_STAFF_SWITCH';
export const UPDATE_EXPORT_CANDIDATE_SWITCH = 'UPDATE_EXPORT_CANDIDATE_SWITCH';
export const UPDATE_ASSIGN_DEPARTMENT_SWITCH = 'UPDATE_ASSIGN_DEPARTMENT_SWITCH';
export const UPDATE_ADD_GROUP_SWITCH = 'UPDATE_ADD_GROUP_SWITCH';
export const UPDATE_FORM_CREATE_SWITCH = 'UPDATE_FORM_CREATE_SWITCH';
export const UPDATE_ADD_PATTERN_SWITCH = 'UPDATE_ADD_PATTERN_SWITCH';
export const UPDATE_EDIT_PATTERN_SWITCH = 'UPDATE_EDIT_PATTERN_SWITCH';
export const UPDATE_ADD_PROCESS_SWITCH = 'UPDATE_ADD_PROCESS_SWITCH';
export const UPDATE_ADD_CANDIDATE_TO_PROCESS_SWITCH = 'UPDATE_ADD_CANDIDATE_TO_PROCESS_SWITCH';
export const UPDATE_EDIT_CANDIDATE_SWITCH = 'UPDATE_EDIT_CANDIDATE_SWITCH';
export const UPDATE_CANDIDATE_INFO_SWITCH = 'UPDATE_CANDIDATE_INFO_SWITCH';
export const UPDATE_CANDIDATE_INFO_TOKEN = 'UPDATE_CANDIDATE_INFO_TOKEN';
export const ADD_HR_SWITCH = 'ADD_HR_SWITCH';
export const EDIT_HR_INFO_SWITCH = 'EDIT_HR_INFO_SWITCH';
export const UPDATE_MAIN_PAGE_INDEX = 'UPDATE_MAIN_PAGE_INDEX';
export const UPDATE_MAIN_PAGE_MENU_STATUS = 'UPDATE_MAIN_PAGE_MENU_STATUS';
export const UPDATE_WORKBENCH_TITLE = 'UPDATE_WORKBENCH_TITLE';
export const UPDATE_WORKBENCH_TOKEN = 'UPDATE_WORKBENCH_TOKEN';
export const UPDATE_HEAD_SEARCH_PLACEHOLDER = 'UPDATE_HEAD_SEARCH_PLACEHOLDER';
export const UPDATE_BTN_ADD_CANDIDATE_SWITCH = 'UPDATE_BTN_ADD_CANDIDATE_SWITCH';
export const UPDATE_BTN_ADD_GROUP_SWITCH = 'UPDATE_BTN_ADD_GROUP_SWITCH';
export const UPDATE_BTN_DROP_CANDIDATE_SWITCH = 'UPDATE_BTN_DROP_CANDIDATE_SWITCH';
export const UPDATE_BTN_DROP_GROUP_SWITCH = 'UPDATE_BTN_DROP_GROUP_SWITCH';
export const UPDATE_BTN_SEND_INTERVIEW_SWITCH = 'UPDATE_BTN_SEND_INTERVIEW_SWITCH';
export const UPDATE_GLOBAL_ALERT_SWITCH = 'UPDATE_GLOBAL_ALERT_SWITCH';
export const UPDATE_SNACKBAR_OPEN_SWITCH = 'UPDATE_SNACKBAR_OPEN_SWITCH';
export const UPDATE_SNACKBAR_ERROR_MESSAGE = 'UPDATE_SNACKBAR_ERROR_MESSAGE';
export const UPDATE_DEPARTMENT_ARRAY = 'UPDATE_DEPARTMENT_ARRAY';
export const UPDATE_SELECTED_DEPARTMENT_ARRAY = 'UPDATE_SELECTED_DEPARTMENT_ARRAY';
export const UPDATE_ENTERPRISE = 'UPDATE_ENTERPRISE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_ADD_TAG_TO_CANDIDATE_SWITCH = 'UPDATE_ADD_TAG_TO_CANDIDATE_SWITCH';
export const UPDATE_REJECT_REASON_DIALOG_SWITCH = 'UPDATE_REJECT_REASON_DIALOG_SWITCH';

// Statistical
export const UPDATE_CANDIDATES_STATISTICAL = 'UPDATE_CANDIDATES_STATISTICAL';
export const UPDATE_CANDIDATES_STATISTICAL_FIRST_LOADING = 'UPDATE_CANDIDATES_STATISTICAL_FIRST_LOADING';
export const UPDATE_PROCESS_STATISTICAL = 'UPDATE_PROCESS_STATISTICAL';
export const UPDATE_REVIEWLIST_STATISTICAL = 'UPDATE_REVIEWLIST_STATISTICAL';

export const ADD_TODO = 'ADD_TODO';
export const DELETE_TODO = 'DELETE_TODO';
export const EDIT_TODO = 'EDIT_TODO';
export const COMPLETE_TODO = 'COMPLETE_TODO';
export const COMPLETE_ALL = 'COMPLETE_ALL';
export const CLEAR_COMPLETED = 'CLEAR_COMPLETED';

export const RESUME_TYPE = 'RESUME_TUPE';

// header分部变量
export const CHECKED_NAME = 'CHECKED_NAME';

// 给招聘流程编辑加一件斗篷cloak
export const CHANGE_ISHOWCLOAK = 'CHANGE_ISHOWCLOAK';

// 改变消息总数定时器
export const CHANGE_KICKOUT = 'CHANGE_KICKOUT';

// 改变下线提醒定时器
export const CHANGE_CANDIDATECOUNT = 'CHANGE_CANDIDATECOUNT';

// 记录设置索引值
export const UPDATA_SETTABINDEX = 'UPDATA_SETTABINDEX';
// 现实全局提示框
export const SHOW_GLOBAL_TOAST = 'SHOW_GLOBAL_TOAST';

export const UPDATE_CURRENT_POSITION_TOKEN = 'UPDATE_CURRENT_POSITION_TOKEN';

export const TOGGLE_TEMPLATE_FILL_DIALOG = 'TOGGLE_TEMPLATE_FILL_DIALOG';
export const OPEN_GLOCAL_CANDIDATE_MODAL = 'OPEN_GLOCAL_CANDIDATE_MODAL';
export const CLOSE_GLOBAL_CANDIDATE_MODAL = 'CLOSE_GLOBAL_CANDIDATE_MODAL';
