import axios from './Api';

export const getCandidate = (candidateToken, needlogs = false) => axios.request({
  url: `/candidate/${candidateToken}`,
  method: 'GET',
  params: { needlogs },
});

export const getCandidateApplications = (candidateToken, params) => axios.request({
  url: `/vc1/candidate/${candidateToken}/applications`,
  method: 'GET',
  params,
});

export const addTagToCandidates = (data) => axios.request({
  url: '/candidate/appendTags',
  method: 'POST',
  data,
});

export const addTagToCandidate = (candidateToken, data) => axios.request({
  url: `/candidate/${candidateToken}/tags`,
  method: 'POST',
  data,
});

export const removeTagFromCandidate = (candidateToken, data) => axios.request({
  url: `/candidate/${candidateToken}/tags`,
  method: 'DELETE',
  data,
});

export const editCandidate = (candidateToken, data) => axios.request({
  url: `/candidate/${candidateToken}`,
  method: 'PUT',
  data,
});

export const getAssignablePositions = (candidateToken) => axios.request({
  url: `/candidate/${candidateToken}/assignablePositions`,
  method: 'GET',
});

export const removeCandidates = (data) => axios.request({
  url: '/candidate',
  method: 'DELETE',
  data,
});

export const patchCandidate = (candidateToken, data) => axios.request({
  url: `/candidate/${candidateToken}`,
  method: 'PATCH',
  data,
});

export const getCandidateFilters = () => axios.request({
  url: '/candidate/filters',
  method: 'GET',
});

export const setCandidateCustomFilters = (data) => axios.request({
  url: '/candidate/customFilters',
  method: 'POST',
  data,
});

export const getCandidateCustomFilters = () => axios.request({
  url: '/candidate/customFilters',
  method: 'GET',
});

export const getCandidates = (params) => axios.request({
  url: '/candidates',
  method: 'GET',
  params,
});

export const getCandidateStates = (data) => axios.request({
  url: '/candidate/states',
  method: 'POST',
  data,
});
export const getCandidatesWithApplication = (params) => axios.request({
  url: '/candidatesWithApplication',
  method: 'GET',
  params,
});

export const fetchCandidatesWithApplication = (data) => axios.request({
  url: '/candidatesWithApplication',
  method: 'POST',
  data,
});

export const fetchVc1CandidatesWithApplication = (data) => axios.request({
  url: 'vc1/candidatesWithApplication',
  method: 'POST',
  data,
});

// 导出人才请求
export const exportTalentRequest = (data) => axios.request({
  url: '/candidate/csv/export',
  method: 'POST',
  data,
});

// 提交需要导出的信息
export const SubmitNeedInformation = (taskId, data) => axios.request({
  url: `/candidate/csv/export?taskId=${taskId}`,
  method: 'PATCH',
  data,
});

// 导出人才

export const exportTalent = (taskId) => axios.request({
  url: `/candidate/csv/export?taskId=${taskId}`,
  method: 'GET',
});

export const coverPosition = (data) => axios.request({
  url: '/candidate/import/cover/position',
  method: 'POST',
  data,
});
