import Spin from '@components/Spin';
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useCandidateDispatch, useCandidateState } from '../context';

function FoundNoPosition() {
  const history = useHistory();
  const dispatch = useCandidateDispatch();
  const { candidateAssignablePositionsData = [], candidateAssignablePositionsDataLoading } = useCandidateState();
  function handleGoToPostion() {
    dispatch({ type: 'SET_IS_SHOW_ASSIGNABLE_POSITIONS_LIST', payload: true });
    dispatch({ type: 'SET_TAB_NAME', payload: 'position' });
  }

  function handleGoToAddPostion() {
    history.push('/dashboard/processesList');
  }

  if (candidateAssignablePositionsDataLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spin />
      </Box>
    );
  }

  if (candidateAssignablePositionsData.length === 0) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" marginTop="72px">
        <Box>
          <img
            alt="添加职位 - 引导图"
            src="//public-static-assets.oss-cn-beijing.aliyuncs.com/img/how_to_add_position.png"
            style={{ maxWidth: '537px' }}
          />
        </Box>
        <Box mt="24px">
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            您目前暂无职位可安排
          </Typography>
        </Box>
        <Box mt="2px">
          <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
            您可以在职位管理页面添加职位
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
            后续可在此处给候选人安排相应的职位
          </Typography>
        </Box>
        <Box mt="16px">
          <Button size="large" color="primary" variant="contained" onClick={handleGoToAddPostion}>
            添加职位
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" marginTop="72px">
      <Box>
        <img
          alt="分配职位 - 引导图"
          src="//public-static-assets.oss-cn-beijing.aliyuncs.com/img/how_to_assign_position.png"
          style={{ maxWidth: '537px' }}
        />
      </Box>
      <Box mt="24px">
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          分配职位
        </Typography>
      </Box>
      <Box mt="2px">
        <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
          您可以给候选人分配相应的职位
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
          后续可在招聘流程中进行查看和管理
        </Typography>
      </Box>
      <Box mt="16px">
        <Button size="large" color="primary" variant="contained" onClick={handleGoToPostion}>
          分配职位
        </Button>
      </Box>
    </Box>
  );
}

export default FoundNoPosition;
