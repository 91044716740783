import {
  Box, TextField, Typography, withStyles,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import uniqueId from 'lodash/uniqueId';
import { useContext } from 'react';
import CollegeSelect from './CollegeSelect';
import DatePicker from './DatePicker';
import EducationSelect from './EducationSelect';
import MajorSelect from './FilterForm/MajorSelect';
import ResumeAttachEdit from './ResumeAttachEdit';
import {
  defaultEducation, defaultExperience,
  defaultLanguage, ResumeContext,
  ResumeDispatchContext,
} from './ResumeContext';
import ResumeSectoinBox from './ResumeSectoinBox';
import styles from './ResumeStandard.module.css';

const TEXTAREA_MAX_LENGTH = 5000;
const TEXTFIELD_MAX_LENGTH = 50;

const LimitedWidthTextField = withStyles(() => ({}))(TextField);

const FullWidthTextField = withStyles(() => ({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-inputMultiline': {
      marginBottom: 20,
    },
  },
}))(TextField);

const ResumeStandard = () => {
  const { editCandidate = {} } = useContext(ResumeContext);
  const dispatch = useContext(ResumeDispatchContext);

  const {
    experience = [], educations = [], languages = [], candidateIntention = {},
  } = editCandidate;

  const onChangeExperience = (key, value, index) => {
    experience[index][key] = value;
    dispatch({ type: 'changeExperience', payload: [...experience] });
  };

  const addExperience = () => {
    dispatch({
      type: 'changeExperience',
      payload: [{ ...defaultExperience, uniqueId: uniqueId('Experience') }, ...experience],
    });
  };
  const deleteExperience = (index) => {
    dispatch({ type: 'changeExperience', payload: [...experience.filter((_, i) => i !== index)] });
  };

  const addEducations = () => {
    dispatch({
      type: 'changeEducations',
      payload: [{ ...defaultEducation, uniqueId: uniqueId('Education') }, ...educations],
    });
  };

  const deleteEducations = (index) => {
    dispatch({ type: 'changeEducations', payload: [...educations.filter((_, i) => i !== index)] });
  };

  const onChangeEducation = (key, value, index) => {
    educations[index][key] = value;
    console.log(key, value, index);
    dispatch({ type: 'changeEducations', payload: [...educations] });
  };

  const addLanguages = () => {
    dispatch({
      type: 'changeLanguages',
      payload: [...languages, { ...defaultLanguage, uniqueId: uniqueId('Language') }],
    });
  };

  const deleteLanguages = (index) => {
    dispatch({ type: 'changeLanguages', payload: [...languages.filter((_, i) => i !== index)] });
  };

  const onChangeLanguage = (key, value, index) => {
    languages[index][key] = value;
    dispatch({ type: 'changeLanguages', payload: [...languages] });
  };
  const onChangeIntention = (key, value) => {
    candidateIntention[key] = value;
    dispatch({ type: 'changeCandidateIntention', payload: { ...candidateIntention } });
  };

  return (
    <Box style={{ backgroundColor: 'white' }} width={516}>
      <ResumeAttachEdit />
      <ResumeSectoinBox title="个人信息">
        <FullWidthTextField
          label="最近公司"
          size="small"
          variant="outlined"
          style={{ marginTop: 24, marginBottom: 24 }}
          name="recentCompany"
          InputProps={{
            inputProps: {
              maxLength: TEXTFIELD_MAX_LENGTH,
            },
          }}
          value={!isEmpty(candidateIntention?.recentCompany) ? candidateIntention?.recentCompany : ''}
          onChange={(e) => {
            const { name, value } = e.target;
            onChangeIntention(name, value);
          }}
        />
      </ResumeSectoinBox>
      <ResumeSectoinBox title="求职意向">
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
            <Box flexGrow={1}>
              <LimitedWidthTextField
                fullWidth
                type="number"
                variant="outlined"
                label="当前薪资"
                size="small"
                margin="none"
                name="currentSalary"
                InputProps={{
                  inputProps: {
                    maxLength: TEXTFIELD_MAX_LENGTH,
                  },
                }}
                value={(!isEmpty(candidateIntention) && candidateIntention.currentSalary) || ''}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeIntention(name, Number.parseInt(value, 10));
                }}
              />
            </Box>

            <Box ml={3} flexGrow={1}>
              <LimitedWidthTextField
                fullWidth
                variant="outlined"
                label="期望城市"
                size="small"
                margin="none"
                name="exceptedCity"
                InputProps={{
                  inputProps: {
                    maxLength: TEXTFIELD_MAX_LENGTH,
                  },
                }}
                value={(!isEmpty(candidateIntention) && candidateIntention.exceptedCity) || ''}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeIntention(name, value);
                }}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
            <Box flexGrow={1}>
              <LimitedWidthTextField
                fullWidth
                // type="number"
                variant="outlined"
                label="期望最低薪资"
                size="small"
                margin="none"
                name="exceptedSalaryFrom"
                InputProps={{
                  inputProps: {
                    maxLength: TEXTFIELD_MAX_LENGTH,
                  },
                }}
                value={(!isEmpty(candidateIntention) && candidateIntention.exceptedSalaryFrom) || ''}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeIntention(name, Number.parseInt(value.replace(/\D+/g, ''), 10));
                }}
              />
            </Box>
            <Box ml={3} flexGrow={1}>
              <LimitedWidthTextField
                fullWidth
                variant="outlined"
                label="期望最高薪资"
                size="small"
                margin="none"
                name="exceptedSalaryTo"
                InputProps={{
                  inputProps: {
                    maxLength: TEXTFIELD_MAX_LENGTH,
                  },
                }}
                value={(!isEmpty(candidateIntention) && candidateIntention.exceptedSalaryTo) || ''}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChangeIntention(name, Number.parseInt(value.replace(/\D+/g, ''), 10));
                }}
              />
            </Box>
          </Box>
        </Box>
      </ResumeSectoinBox>
      <ResumeSectoinBox
        title="工作经历"
        rightAction={(
          <Typography
            variant="body2"
            onClick={() => {
              addExperience();
            }}
            style={{
              color: '#576B95',
              cursor: 'pointer',
            }}
          >
            + 添加工作经历
          </Typography>
        )}
      >
        {editCandidate.experience
          && editCandidate.experience.map((v, i) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              display="flex"
              flexDirection="column"
              alignItems="center"
              p={2}
              mt={2}
              pt={0}
              className={styles.card}
            >
              <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                <FormControlLabel
                  onChange={(e, vv) => {
                    const { name } = e.target;
                    onChangeExperience(name, vv ? 1 : 0, i);
                  }}
                  checked={v.employStatus}
                  control={<Checkbox name="employStatus" color="primary" />}
                  label={<Typography variant="body2">至今依然在此工作</Typography>}
                />
                {editCandidate.experience.length !== 1 && (
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      deleteExperience(i);
                    }}
                  >
                    删除本条
                  </Typography>
                )}
              </Box>
              <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" flexGrow={1}>
                <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
                  <Box flexGrow={1} width={229}>
                    <DatePicker
                      name="startTime"
                      variant="outlined"
                      label="入职时间"
                      size="small"
                      value={v.startTime ? new Date(v.startTime * 1000) : null}
                      onChange={(date) => {
                        onChangeExperience('startTime', Number.parseInt(date.getTime() / 1000, 10), i);
                      }}
                    />
                  </Box>
                  <Box ml={3} flexGrow={1} width={229}>
                    {!v.employStatus && (
                      <DatePicker
                        name="endTime"
                        variant="outlined"
                        label="离职时间"
                        size="small"
                        value={v.endTime ? new Date(v.endTime * 1000) : null}
                        onChange={(date) => {
                          onChangeExperience('endTime', Number.parseInt(date.getTime() / 1000, 10), i);
                        }}
                      />
                    )}
                    {!!v.employStatus && <Box width="230px" height="38zhuapx" />}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" width="100%" mt={2} mb={2}>
                  <Box flexGrow={1}>
                    <TextField
                      fullWidth
                      name="company"
                      variant="outlined"
                      label="公司名称"
                      size="small"
                      InputProps={{
                        inputProps: {
                          maxLength: TEXTFIELD_MAX_LENGTH,
                        },
                      }}
                      value={v.company}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        onChangeExperience(name, value, i);
                      }}
                    />
                  </Box>
                  <Box ml={3} flexGrow={1}>
                    <TextField
                      fullWidth
                      name="position"
                      variant="outlined"
                      label="职位名称"
                      size="small"
                      value={v.position}
                      InputProps={{
                        inputProps: {
                          maxLength: TEXTFIELD_MAX_LENGTH,
                        },
                      }}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        onChangeExperience(name, value, i);
                      }}
                    />
                  </Box>
                </Box>
                <FullWidthTextField
                  name="workContent"
                  variant="outlined"
                  label="工作内容"
                  size="small"
                  multiline
                  rows={8}
                  value={v.workContent}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    onChangeExperience(name, value, i);
                  }}
                  InputProps={{
                    inputProps: {
                      maxLength: TEXTAREA_MAX_LENGTH,
                    },
                    endAdornment: (
                      <Typography
                        style={{ position: 'absolute', bottom: 8, right: 12 }}
                        variant="caption"
                        color="textSecondary"
                      >
                        {v?.workContent?.length || 0}/{TEXTAREA_MAX_LENGTH}
                      </Typography>
                    ),
                  }}
                />
              </Box>
            </Box>
          ))}
      </ResumeSectoinBox>
      <ResumeSectoinBox
        title="教育背景"
        rightAction={(
          <Typography
            variant="body2"
            style={{
              color: '#576B95',
              cursor: 'pointer',
            }}
            onClick={() => {
              addEducations();
            }}
          >
            + 添加教育背景
          </Typography>
        )}
      >
        {educations.map((v, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={i} elevation="0" className={styles.card} p={2} mt={2}>
            <Box display="flex" justifyContent="flex-end">
              {educations.length > 1 && (
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    deleteEducations(i);
                  }}
                >
                  删除本条
                </Typography>
              )}
            </Box>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" mt={2}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box flexGrow={1}>
                  <DatePicker
                    name="startTime"
                    variant="outlined"
                    label="入学时间"
                    size="small"
                    value={v.startTime ? new Date(v.startTime * 1000) : null}
                    onChange={(date) => {
                      onChangeEducation('startTime', Number.parseInt(date.getTime() / 1000, 10), i);
                    }}
                  />
                </Box>
                <Box ml={3} flexGrow={1}>
                  <DatePicker
                    name="endTime"
                    variant="outlined"
                    label="毕业时间"
                    size="small"
                    value={v.endTime ? new Date(v.endTime * 1000) : null}
                    onChange={(date) => {
                      onChangeEducation('endTime', Number.parseInt(date.getTime() / 1000, 10), i);
                    }}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%" mt={2} mb={2}>
                <Box width="calc(50% - 12px)">
                  <CollegeSelect
                    name="schoolName"
                    value={v.schoolName}
                    variant="outlined"
                    label="学校名称"
                    size="small"
                    InputProps={{
                      inputProps: {
                        maxLength: TEXTFIELD_MAX_LENGTH,
                      },
                    }}
                    onChange={(name, value) => {
                      onChangeEducation(name, value, i);
                    }}
                  />
                </Box>
                <Box width="calc(50% - 12px)">
                  <MajorSelect
                    fullWidth
                    name="major"
                    value={v.major}
                    variant="outlined"
                    label="专业名称"
                    ize="small"
                    InputProps={{
                      inputProps: {
                        maxLength: TEXTFIELD_MAX_LENGTH,
                      },
                    }}
                    onChange={(name, value) => {
                      onChangeEducation(name, value, i);
                    }}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box width="calc(50% - 12px)">
                  <EducationSelect
                    name="degree"
                    value={isNumber(v?.degree) ? v?.degree?.toFixed() : v?.degree}
                    label="学历"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      onChangeEducation(name, value, i);
                    }}
                  />
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </Box>
        ))}
      </ResumeSectoinBox>
      <ResumeSectoinBox
        title="语言能力"
        rightAction={(
          <Typography
            variant="body2"
            style={{
              color: '#576B95',
              cursor: 'pointer',
            }}
            onClick={() => {
              addLanguages();
            }}
          >
            + 添加语言类型
          </Typography>
        )}
      >
        {languages.map((v, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={i} display="flex" mt={2} alignItems="center">
            <TextField
              InputProps={{
                inputProps: {
                  maxLength: TEXTFIELD_MAX_LENGTH,
                },
              }}
              name="name"
              variant="outlined"
              label={`语言类型${i + 1}`}
              value={v.name}
              size="small"
              fullWidth
              onChange={(e) => {
                const { name, value } = e.target;
                onChangeLanguage(name, value, i);
              }}
            />
            {languages.length > 1 && (
              <Typography
                variant="body2"
                color="secondary"
                style={{ flexShrink: 0, marginLeft: 16, cursor: 'pointer' }}
                onClick={() => {
                  deleteLanguages(i);
                }}
              >
                删除本条
              </Typography>
            )}
          </Box>
        ))}
      </ResumeSectoinBox>
      <ResumeSectoinBox title="自我描述">
        <FullWidthTextField
          name="introduction"
          value={(!isEmpty(candidateIntention) && candidateIntention.introduction) || ''}
          variant="outlined"
          label=""
          placeholder="请输入文字"
          size="small"
          multiline
          rows={4}
          style={{ marginTop: 16 }}
          onChange={(e) => {
            const { name, value } = e.target;
            onChangeIntention(name, value);
          }}
          InputProps={{
            inputProps: {
              maxLength: TEXTAREA_MAX_LENGTH,
            },
            endAdornment: (
              <Typography
                style={{ position: 'absolute', bottom: 8, right: 12 }}
                variant="caption"
                color="textSecondary"
              >
                {candidateIntention?.introduction?.length || 0}/{TEXTAREA_MAX_LENGTH}
              </Typography>
            ),
          }}
        />
      </ResumeSectoinBox>
    </Box>
  );
};

ResumeStandard.propTypes = {};

ResumeStandard.defaultProps = {};

export default ResumeStandard;
