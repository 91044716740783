import { useMemo } from 'react';
import { isEmpty } from 'lodash';

export default function useMemoShouldDisabledSubmit({
  formItems,
  formData,
  feedbackData,
  hasFeedback,
  visibleSwitch,
  visibleUsers,
}) {
  return useMemo(() => {
    if (isEmpty(formItems)) return true;

    if (hasFeedback) {
      if (!feedbackData.level || !feedbackData.stars) {
        return true;
      }
    }

    const hasAllFilled = formItems.every((v) => {
      if (v.isNecessary) {
        const item = formData[v.formItemToken] || {};

        if (isEmpty(item)) {
          return false;
        }

        if (item.type === 'radio' || item.type === 'checkbox') {
          const value = item.value || [];
          if (value.length === 0) {
            return false;
          }
        }

        if (item.type === 'question') {
          const value = (item.value || '').trim();
          if (value.length === 0) {
            return false;
          }
          if (v.isScoreOpen === 1 && !(item.score >= 0)) {
            return false;
          }
        }
      }
      return true;
    });

    if (!hasAllFilled) return true;

    if (visibleSwitch === 1 && visibleUsers.length === 0) return true;

    return false;
  }, [formItems, formData, feedbackData, hasFeedback, visibleSwitch, visibleUsers]);
}
