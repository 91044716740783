import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const Container = ({ children }) => <Box mt={8}>{children}</Box>;

Container.propTypes = {
  children: PropTypes.node,
};

Container.defaultProps = {
  children: null,
};

export default Container;
