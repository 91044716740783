import PropTypes from 'prop-types';
import Chart from './Chart';

const options = {
  grid: {
    containLabel: true, top: 16, bottom: 16, left: 16,
  },
  tooltip: {
    formatter: (params) => {
      const { data } = params;
      return `<div>
                <div>${data[0]}</div>
                <div>
                  得分: ${data[3]} · 原分值${data[1]}，权重${data[2]}%
                </div>
              </div>`;
    },
  },
  xAxis: { min: 0, max: 100, axisLine: { show: false } },
  yAxis: {
    type: 'category',
    axisLine: { show: false },
    axisLabel: {
      show: true,
      formatter: '{x|{value}}',
      rich: { x: { color: '#a4a4a4' } },
    },
  },
  visualMap: [
    {
      show: false,
      orient: 'horizontal',
      min: 0,
      max: 100,
      dimension: 3,
      inRange: {
        color: ['rgba(60, 202, 120, 0.1)', 'rgba(60, 202, 120, 1)'],
      },
    },
  ],
  series: [
    {
      type: 'bar',
      dimensions: ['title', 'origin', 'weight', 'final'],
      encode: {
        x: 'origin',
        y: 'title',
      },
      label: {
        show: true,
        position: 'right',
        formatter: '{@origin}',
        fontWeight: 'bold',
        fontSize: 14,
        fontFamily: 'DIN Alternate Bold',
        color: '#4bb051',
      },
    },
  ],
  // dataset: {
  //   source: [['普通话得分', 90, 0.8, 72]],
  // },
};

function Bar({ source = [] }) {
  return <Chart options={{ ...options, dataset: { source } }} />;
}

Bar.propTypes = {
  source: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Bar;
