import { removeCandidates } from '@api/Candidate';
import { DeleteIcon } from '@avocadoui/icons';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import * as CandidatesActions from '@reduxold/candidates';
import * as UpdateDashboardSwitchActions from '@reduxold/componentSwitch';
import useGlobalToast from '@utils/GlobalToast';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as API from '../../../v1/api';

const DeleteIconWrapper = ({
  candidateName, candidateToken, candidatesActions, candidates, onClose, shouldDisabledBtn,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const GlobalToast = useGlobalToast();

  function deleteCandidate() {
    const data = { tokens: [candidateToken] };
    setIsOpen(false);
    removeCandidates(data)
      .then((result) => {
        if (result.data.code === 0) {
          API.getCandidates(candidatesActions, candidates.filter);
          onClose();
          GlobalToast.success('删除成功');
        } else if (result.data.code === -2008) {
          GlobalToast.warning(result.data.message);
        } else {
          GlobalToast.error('删除失败');
        }
      })
      .catch(() => {
        GlobalToast.error('删除失败');
      });
  }

  return (
    <div style={{ position: 'relative' }}>
      <IconButton disabled={shouldDisabledBtn} onClick={() => setIsOpen(true)}>
        <DeleteIcon />
      </IconButton>
      {isOpen && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={isOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">删除候选人</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              确认将 {candidateName} 从系统中永久删除吗？该操作无法被撤销。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpen(false)}>取消</Button>
            <Button variant="contained" color="secondary" onClick={deleteCandidate} autoFocus>
              删除
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

DeleteIconWrapper.propTypes = {
  candidateName: PropTypes.string,
  candidateToken: PropTypes.string,
  candidatesActions: PropTypes.objectOf(PropTypes.any),
  candidates: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
  shouldDisabledBtn: PropTypes.bool,
};

DeleteIconWrapper.defaultProps = {
  candidateName: null,
  candidateToken: null,
  candidatesActions: {},
  candidates: {},
  onClose: () => {},
  shouldDisabledBtn: false,
};

export default connect(
  (state) => ({
    candidates: state.candidates,
  }),
  (dispatch) => ({
    updateDashboardSwitchActions: bindActionCreators(UpdateDashboardSwitchActions, dispatch),
    candidatesActions: bindActionCreators(CandidatesActions, dispatch),
  }),
)(DeleteIconWrapper);
