import { handleActions } from '../../../utils/redux-actions';
import {
  closeCandidateModal,
  destroyCandidateModal, openCandidateModal, resetCandidateAndApplicationToken, setApplicationToken,
  setCandidateAndApplicationToken, setCandidateToken,
} from './action';

export const namespace = 'candidateModal';

export const defaultState = {
  isNull: true,
  isOpen: false,
  candidateToken: '',
  applicationToken: '',
  showBackdrop: true,
  hideSwitchButton: false,
  hideCandidateApplicationSelector: false,
  disabledClose: false,
  processTokenFromInterviewArrangement: '',
  processType: 0,
};

export const reducer = handleActions(
  {
    [openCandidateModal]: (draft, action) => {
      const {
        candidateToken,
        applicationToken,
        showBackdrop,
        hideSwitchButton,
        hideCandidateApplicationSelector,
        disabledClose,
        processTokenFromInterviewArrangement,
        processType,
      } = action.payload;
      draft.isNull = false;
      draft.isOpen = true;
      if (candidateToken !== undefined) draft.candidateToken = candidateToken;
      if (applicationToken !== undefined) draft.applicationToken = applicationToken;
      if (showBackdrop !== undefined) draft.showBackdrop = showBackdrop;
      if (hideSwitchButton !== undefined) draft.hideSwitchButton = hideSwitchButton;
      if (hideCandidateApplicationSelector !== undefined) {
        draft.hideCandidateApplicationSelector = hideCandidateApplicationSelector;
      }
      if (disabledClose !== undefined) draft.disabledClose = disabledClose;
      if (processTokenFromInterviewArrangement !== undefined) {
        draft.processTokenFromInterviewArrangement = processTokenFromInterviewArrangement;
      }
      if (processType !== undefined) draft.processType = processType;
    },
    [closeCandidateModal]: (draft) => {
      draft.isOpen = false;
    },
    [destroyCandidateModal]: () => defaultState,
    [setCandidateToken]: (draft, action) => {
      draft.candidateToken = action.payload;
    },
    [setApplicationToken]: (draft, action) => {
      draft.applicationToken = action.payload;
    },
    [setCandidateAndApplicationToken]: (draft, action) => {
      const { candidateToken, applicationToken } = action.payload;
      if (candidateToken !== undefined) draft.candidateToken = candidateToken;
      if (applicationToken !== undefined) draft.applicationToken = applicationToken;
    },
    [resetCandidateAndApplicationToken]: (draft) => {
      draft.candidateToken = '';
      draft.applicationToken = '';
    },
  },
  defaultState,
);
