import { handleActions } from 'redux-actions';

import {
  setCommentFormList,
  setCommentFormListSelectedIndex,
  setCommentFormNeedSave,
  setCommentFormInfo,
  setPositionList,
} from './action';

export const namespace = 'commentForm';

export const defaultState = {
  commentFormList: [],
  commentFormListSelectedIndex: 0,
  commentFormNeedSave: false,
  commentFormInfo: {},
  positionList: [],
};

export const commentFormReducer = handleActions(
  {
    [setCommentFormList]: (state, action) => {
      const { data } = action.payload;
      return { ...state, commentFormList: data.list, commentFormNeedSave: false };
    },
    [setCommentFormListSelectedIndex]: (state, action) => {
      const index = action.payload;
      return { ...state, commentFormListSelectedIndex: index };
    },
    [setCommentFormNeedSave]: (state, action) => ({ ...state, commentFormNeedSave: action.payload }),
    [setCommentFormInfo]: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        commentFormInfo: payload,
        commentFormNeedSave: false,
      };
    },
    [setPositionList]: (state, action) => {
      const { list } = action.payload;
      return { ...state, positionList: list };
    },
  },
  defaultState,
);
