import * as ACTIONS from '../constants/ActionTypes';

const initialState = {
  process: {
    processingCount: '',
    todayIncreaseCandidateCount: '',
  },
  reviewList: {
    unCompleteCount: '',
    waitingAuditCount: '',
    passCount: '',
    failedCount: '',
  },
  isShowCloak: false,
};

export default function processStatistical(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_PROCESS_STATISTICAL:
      return {
        process: action.data,
        reviewList: state.reviewList,
      };
    case ACTIONS.UPDATE_REVIEWLIST_STATISTICAL:
      return {
        process: state.process,
        reviewList: action.data,
      };
    case ACTIONS.CHANGE_ISHOWCLOAK:
      return {
        process: state.process,
        reviewList: state.reviewList,
        isShowCloak: action.isShowCloak,
      };
    default:
      return state;
  }
}
