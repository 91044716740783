import MuiChip from '@material-ui/core/Chip';
import { styled } from '@material-ui/core/styles';
import { compose, spacing, style } from '@material-ui/system';

const customSize = style({
  cssProperty: false,
  prop: 'customSize',
  themeKey: 'overrides.MuiChip',
});

const Chip = styled(MuiChip)(compose(spacing, customSize));

export default Chip;
