import axios from './Api';

export const getInboxHistoryMessage = (data) => axios.request({
  url: '/inbox/messages/candidate',
  method: 'POST',
  data,
});

export const getInboxMemssageList = (data) => axios.request({
  url: '/inbox/messages/recent',
  method: 'POST',
  data,
});

export const postInboxMessageSend = (data) => axios.request({
  url: '/inbox/message/send',
  method: 'POST',
  data,
});

export const getInboxMessageList = (data) => axios.request({
  url: '/inbox/messages/list',
  method: 'POST',
  data,
});

export const getInboxMessageNotify = () => axios.request({
  url: '/inbox/messages/notify',
  method: 'GET',
});
