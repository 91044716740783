import axios from './Api';

export const getCandidates = (data) => axios.request({
  url: '/vc1/candidatesWithApplication',
  method: 'POST',
  data,
});
export const deleteCandidates = (tokens) => axios.request({
  url: '/candidate',
  method: 'DELETE',
  data: { tokens },
});
export const vc1DeleteCandidates = (tokens) => axios.request({
  url: '/vc1/candidate/delete',
  method: 'POST',
  data: { tokens },
});

export default { getCandidates, deleteCandidates, vc1DeleteCandidates };
