import { handleActions } from 'redux-actions';
import {
  reloadTemporaryDrawer, setAtList, setAtMessageList, setInboxMessageList,
} from './action';

export const namespace = 'atmessage';

export const defaultState = {
  users: [],
  messageList: [],
  messageListUnReadCount: 0,
  inboxMessages: [],
  inboxMessagesUnReadCount: 0,
  reloadTemporaryDrawerCount: 0,
};

export const atmessageReducer = handleActions(
  {
    [setAtList]: (state, action) => {
      const data = action.payload;
      return { ...state, users: data };
    },
    [setAtMessageList]: (state, action) => {
      const data = action.payload;
      return { ...state, messageList: data.list, messageListUnReadCount: data.unread || 0 };
    },
    [setInboxMessageList]: (state, action) => {
      const data = action.payload;
      return { ...state, inboxMessages: data.list, inboxMessagesUnReadCount: data.unread || 0 };
    },
    [reloadTemporaryDrawer]: (state) => ({
      ...state,
      reloadTemporaryDrawerCount: state.reloadTemporaryDrawerCount + 1,
    }),
  },
  defaultState,
);
