import { AddIcon } from '@avocadoui/icons';
import Typography from '@components/Avocado/Typography';
import CustomPrompt from '@components/CustomPrompt/CustomPrompt';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { setCandidateAndApplicationToken } from '@redux/candidateModal/action';
import moment from '@utils/moment';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCandidateDispatch, useCandidateState } from '../context';
import styles from './CandidateInfo.module.css';

function CandidateApplicationSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [applicationTokenTemp, setapplicationTokenTemp] = useState('');
  const [candidateTokenTemp, setCandidateTokenTemp] = useState('');

  const {
    candidateApplicationData, isEditing = false, editingType, editingTitle,
  } = useCandidateState();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const dispatch = useCandidateDispatch();
  const dispatchRedux = useDispatch();

  function handleChangeApplication(applicationTokenTmp, candidateTokenTmp) {
    if (!isEditing) {
      dispatch({ type: 'RESET_TO_DEFAULT_STATE' });
      dispatchRedux(
        setCandidateAndApplicationToken({ candidateToken: candidateTokenTmp, applicationToken: applicationTokenTmp }),
      );
      setTimeout(() => setIsOpen(false), 0);
    } else {
      setapplicationTokenTemp(applicationTokenTmp);
      setCandidateTokenTemp(candidateTokenTmp);
      setShowPrompt(true);
    }
  }

  function handleClickAssignPosition() {
    if (!isEditing) {
      dispatch({ type: 'CANDIDATE_RESUME_RECORD', payload: false });

      setTimeout(() => {
        setIsOpen(false);
        dispatch({ type: 'SET_IS_SHOW_ASSIGNABLE_POSITIONS_LIST', payload: true });
        dispatch({ type: 'SET_TAB_NAME', payload: 'position' });
      }, 0);
    } else {
      setapplicationTokenTemp('');
      setCandidateTokenTemp('');
      setShowPrompt(true);
    }
  }

  if (candidateApplicationData && candidateApplicationData.flows && candidateApplicationData.flows.length > 0) {
    const flow = candidateApplicationData.flows.find((v) => v.applicationToken === applicationToken) || {};
    const showAssignBtn = candidateApplicationData.canAssignPosition || false;
    return (
      <div
        role="button"
        className={clsx(styles.selectorButton, styles.pointer)}
        style={{ top: 1 }}
        onClick={() => setIsOpen(true)}
      >
        <div className={styles.positionName}>
          <div className={styles.text}>{flow.positionName}</div>
          {flow.startTime && (
            <div className={styles.startTime}>{moment(parseInt(flow.startTime * 1000, 10)).format('YYYY.MM.DD')}</div>
          )}
        </div>
        <Box color="text.secondary" display="flex" justifyContent="center" alignItems="center">
          {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Box>
        {isOpen && (
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <Paper elevation={2} className={styles.applicationList}>
              <div className={styles.ul}>
                {showAssignBtn && (
                  <div
                    role="button"
                    className={clsx(styles.li, styles.assignPosition)}
                    onClick={handleClickAssignPosition}
                  >
                    <Box display="flex" alignItems="center">
                      <AddIcon />
                      <Box ml={1.5}>分配新面试</Box>
                    </Box>
                  </div>
                )}
                {candidateApplicationData.flows.map((item) => (
                  <div
                    role="button"
                    className={clsx(styles.li, styles.positionList)}
                    key={item.applicationToken}
                    onClick={() => handleChangeApplication(item.applicationToken, candidateToken)}
                  >
                    <Box>
                      <Typography variant="body2" color="text.primary">
                        {item.positionName}
                      </Typography>
                      {item.stateCn && (
                        <Typography variant="caption" color={item.state === 6 ? 'secondary.main' : 'text.secondary'}>
                          {item.stateCn || '已淘汰'}
                        </Typography>
                      )}
                    </Box>
                    <div className={styles.startTime}>
                      {moment(parseInt(item.startTime * 1000, 10)).format('YYYY.MM.DD')}
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
          </ClickAwayListener>
        )}
        {showPrompt && (
          <CustomPrompt
            message={
              editingType === 1
                ? `您还未保存面试评价表 【${editingTitle}】，确定离开吗？`
                : '您还未保存当前修改，确定离开吗？'
            }
            cleanUp={(ok) => {
              if (ok) {
                if (!candidateTokenTemp && !applicationTokenTemp) {
                  // dispatch({ type: 'SET_IS_EDITING', payload: false });
                  dispatch({ type: 'CANDIDATE_RESUME_RECORD', payload: false });

                  setTimeout(() => {
                    setIsOpen(false);
                    dispatch({ type: 'SET_IS_SHOW_ASSIGNABLE_POSITIONS_LIST', payload: true });
                    dispatch({ type: 'SET_TAB_NAME', payload: 'position' });
                  }, 100);
                } else {
                  dispatch({ type: 'RESET_TO_DEFAULT_STATE' });
                  dispatchRedux(
                    setCandidateAndApplicationToken({
                      candidateToken: candidateTokenTemp,
                      applicationToken: applicationTokenTemp,
                    }),
                  );
                  setTimeout(() => setIsOpen(false), 0);
                }
              }
              setShowPrompt(false);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={styles.selectorButton}
      style={{ backgroundColor: 'rgba(0,0,0,0.04)', color: 'rgba(0,0,0,0.26)', border: '1px solid rgba(0,0,0,0.04)' }}
    >
      <div className={styles.positionName}>
        <div>未分配</div>
      </div>
    </div>
  );
}

export default CandidateApplicationSelector;
