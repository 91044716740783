import { applicationCollaborator } from '@api/ApplicationShare';
import { KeyboardArrowRightIcon, LinkIcon, PeopleIcon } from '@avocadoui/icons';
import Avatar from '@components/Avocado/Avatar';
import Typography from '@components/Avocado/Typography';
import { Box, Paper, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import IconButton from './IconButton';
import MemberAutoComplate from './MemberAutoComplate';
import PaperHeader from './PaperHeader';

const ShareIndex = ({
  token,
  // onWechatShareClick,
  onCopyLinkClick,
  onCollaviratorSelected,
  onCollaviratorManagementClick,
  onCreateClick,
}) => {
  const [collaborator, setCollaborator] = useState([]);
  useEffect(() => {
    applicationCollaborator(token)
      .then((v) => {
        if (v.data.code === 0) {
          setCollaborator(v.data.data.list);
        }
      })
      .catch(console.log);
  }, [token]);

  return (
    <Paper style={{ width: 410 }}>
      <PaperHeader
        title={(
          <Box display="flex" justifyContent="space-between" width>
            分享
            <Box display="flex" pr={2} onClick={onCollaviratorManagementClick}>
              {collaborator.slice(0, 5).map((v, i) => (
                <Tooltip
                  arrow
                  title={collaborator.length > 5 && i === 4 ? `共有${collaborator.length}个协作者` : v.name}
                >
                  <Box ml={1}>
                    {collaborator.length > 5 && i === 4 && (
                      <Avatar
                        size="small"
                        style={{
                          marginRight: 6,
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant="caption">{`+${collaborator.length - 4}`} </Typography>
                      </Avatar>
                    )}
                    {v.type === 2 ? (
                      <Avatar size="small" style={{ marginRight: 6 }}>
                        <PeopleIcon />
                      </Avatar>
                    ) : (
                      (i < 4 || collaborator.length <= 5) && <Avatar user={v} size="small" />
                    )}
                  </Box>
                </Tooltip>
              ))}
              <KeyboardArrowRightIcon style={{ cursor: 'pointer' }} />
            </Box>
          </Box>
        )}
      />
      <Box mt={1} mb={2} mr={2} ml={3}>
        <MemberAutoComplate onChange={onCollaviratorSelected} token={token} onCreateClick={onCreateClick} />
      </Box>
      <Box height={56} display="flex" alignItems="center" justifyContent="space-between" mr={2} ml={3}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" fontWeight={500}>
            分享方式：
          </Typography>
          {/* <Box mr={1}>
            <Tooltip arrow title="微信">
              <IconButton onClick={onWechatShareClick}>
                <WeChatIcon />
              </IconButton>
            </Tooltip>
          </Box> */}
          <Box>
            <Tooltip arrow title="复制链接">
              <IconButton onClick={onCopyLinkClick}>
                <LinkIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {/* <Button>取消</Button> */}
      </Box>
    </Paper>
  );
};

ShareIndex.propTypes = {
  token: PropTypes.string.isRequired,
  onCreateClick: PropTypes.func,
  // onWechatShareClick: PropTypes.func,
  onCopyLinkClick: PropTypes.func,
  onCollaviratorSelected: PropTypes.func,
  onCollaviratorManagementClick: PropTypes.func,
};

ShareIndex.defaultProps = {
  onCreateClick: null,
  // onWechatShareClick: null,
  onCopyLinkClick: null,
  onCollaviratorSelected: null,
  onCollaviratorManagementClick: null,
};

export default ShareIndex;
