import CustomPrompt from '@components/CustomPrompt/CustomPrompt';
import Layout from '@components/Layout/Layout';
import Loading from '@components/Loading';
import PageNotFound from '@components/NotFound';
import { useCallback } from 'react';
import ReactDOM from 'react-dom';
import loadable from 'react-loadable';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ShareLogin from '../views/share/Login';

// Dashboard
const Dashboard = loadable({
  loader: () => import('../views/Dashboard'),
  loading: Loading,
});

// Login
const Login = loadable({
  loader: () => import('../views/login'),
  loading: Loading,
});

const LayoutLogin = loadable({
  loader: () => import('../components/Layout/Login'),
  loading: Loading,
});

const ShareLayout = loadable({
  loader: () => import('../views/share/Layout'),
  loading: Loading,
});

export default function Routes() {
  const userConfirmation = useCallback((message, callback) => {
    const node = document.getElementById('custom-prompt');

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
      callback(answer);
    };

    ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node);
  }, []);
  return (
    <BrowserRouter getUserConfirmation={userConfirmation}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route exact path="/share/login/:interviewToken" component={ShareLogin} />
        <Route path="/share" component={ShareLayout} />
        <Route exact path="/layout/login" component={LayoutLogin} />
        <Route path="/layout" component={Layout} />
        <Route exact component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}
