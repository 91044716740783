import * as Api from '@api/Interviewer';
import { Box, List } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { ReactComponent as RoleEnterprise } from '../../../assets/img/icons/RoleEnterprise.svg';
import { ReactComponent as RoleinteRviewer } from '../../../assets/img/icons/RoleinteRviewer.svg';
import RoleSwitchItem from './RoleSwitchItem';

const RoleSwitch = ({ token, onRoleSwitch }) => {
  const handleClick = useCallback(
    (type) => {
      Api.smsLoginConfirm({ authTokenTmp: token, type }).then((v) => {
        if (v.data.code === 0) {
          onRoleSwitch(v.data?.data?.authToken);
        }
      });
    },
    [token, onRoleSwitch],
  );
  return (
    <List component="nav">
      <RoleSwitchItem
        index={1}
        primary="企业成员"
        secondary="以企业内部账号的身份登录审核"
        avatar={<RoleEnterprise />}
        onClick={handleClick}
      />
      <Box mt={3} />
      <RoleSwitchItem
        index={2}
        primary="协作者"
        secondary="以协作者的身份进行候选人查看"
        avatar={<RoleinteRviewer />}
        onClick={handleClick}
      />
    </List>
  );
};

RoleSwitch.propTypes = {
  token: PropTypes.string.isRequired,
  onRoleSwitch: PropTypes.func.isRequired,
};

RoleSwitch.defaultProps = {};

export default memo(RoleSwitch);
