import PropTypes from 'prop-types';
import styles from '../../CandidateVideo.module.css';

const VideoPlayer = ({ videoURL, autoPlay }) => (
  <video
    className={styles.video}
    src={videoURL}
    controls="controls"
    controlslist="nodownload noremoteplayback"
    disablePictureInPicture
    autoPlay={autoPlay}
  />
);
VideoPlayer.propTypes = {
  videoURL: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
};
VideoPlayer.defaultProps = {
  autoPlay: true,
};
export default VideoPlayer;
