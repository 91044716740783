import * as ACTIONS from '../constants/ActionTypes';

const initialState = {
  checked: '',
};

export default function hrList(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.CHECKED_NAME:
      return { ...state, checked: action.value };
    default:
      return state;
  }
}
