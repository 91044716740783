import axios from 'axios';

// const addErrorLog = (errorInfo) => {
//   const {
//     statusText,
//     status,
//     request: { responseURL },
//   } = errorInfo;
//   const info = {
//     type: 'ajax',
//     code: status,
//     mes: statusText,
//     url: responseURL,
//   };
//   if (!responseURL.includes('save_error_logger')) store.dispatch('addErrorLog', info);
// };

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }

  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        Authorization: `Basic ${localStorage.getItem('id_token')}`,
        'X-Application-Token': localStorage.getItem('x_application_token'),
      },
    };
    return config;
  }

  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }

  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true;
        return config;
      },
      (error) => Promise.reject(error),
    );
    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        this.destroy(url);
        const { data, status } = res;
        return { data, status };
      },
      (error) => {
        this.destroy(url);
        // const errorInfo = error.response;
        // if (!errorInfo) {
        //   const {
        //     request: { statusText, status },
        //     config,
        //   } = JSON.parse(JSON.stringify(error));
        //   errorInfo = {
        //     statusText,
        //     status,
        //     request: { responseURL: config.url },
        //   };
        // }
        // addErrorLog(errorInfo);
        return Promise.reject(error);
      },
    );
  }

  request(options) {
    const instance = axios.create();
    const mergeOptions = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, mergeOptions.url);
    return instance(mergeOptions);
  }
}
export default HttpRequest;
