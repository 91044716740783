import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styles from './CandidateTabsSkeleton.module.css';

const CandidateTabsSkeleton = () => (
  <Box className={styles.contentLeft}>
    <Box className={styles.contentLeftHead} display="flex" alignItems="center" height={48}>
      <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
      <Box ml={2}>
        <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
      </Box>
      <Box ml={2}>
        <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
      </Box>
    </Box>
    <Box className={styles.contentLeftDetail}>
      <Box className={styles.contentLeftDetailBox} mb={3}>
        <Box mb="12px">
          <Skeleton className={styles.resetTransform} width={70} height={8} animation="wave" />
        </Box>
        <Box mb="12px">
          <Skeleton className={styles.resetTransform} width={320} height={8} animation="wave" />
        </Box>
        <Skeleton className={styles.resetTransform} width={240} height={8} animation="wave" />
      </Box>
      <Box className={styles.contentLeftDetailBox} mb={3}>
        <Box mb="12px">
          <Skeleton className={styles.resetTransform} width={70} height={8} animation="wave" />
        </Box>
        <Box mb="12px">
          <Skeleton className={styles.resetTransform} width={320} height={8} animation="wave" />
        </Box>
        <Skeleton className={styles.resetTransform} width={240} height={8} animation="wave" />
      </Box>
    </Box>
  </Box>
);

export default CandidateTabsSkeleton;
