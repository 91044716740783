import Avatar from '@components/Avatar';
import { CheckCircleIcon, MonetizationOnIcon } from '@components/Icon';
import Box from '@material-ui/core/Box';
import moment from '@utils/moment';
import PropTypes from 'prop-types';
import styles from './CandidateReferrals.module.css';
import { useCandidateState } from './context';

function ArrowItem() {
  return (
    <div className={styles.arrow}>
      <div className={styles.circle} />
      <div className={styles.dashed} />
      <div className={styles.triangle} />
    </div>
  );
}

export function ReferralItem({ item, isLastReferrer }) {
  const {
    avatar, name, recommendTime, rewardAmount, rewardTime, rewardType,
  } = item;

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="16px"
        fontSize="13px"
        border="1px solid rgba(237,237,237,1)"
        borderRadius="4px"
      >
        <Box display="flex" alignItems="center">
          <Box>
            <Avatar src={avatar} style={{ width: '32px', height: '32px' }} icon={isLastReferrer ? 'referrer' : ''} />
          </Box>
          <Box ml="16px" color="rgba(0,0,0,0.56)" className={styles.truncate}>
            {name}
          </Box>
        </Box>
        <Box display="flex">
          <Box color="rgba(0,0,0,0.56)">
            {moment(parseInt((recommendTime || rewardTime) * 1000, 10)).format('YYYY-MM-DD HH:mm')}
          </Box>
          <Box ml="32px" color="#4BB051">
            {rewardType === 1 ? `${rewardAmount / 100}方便币` : `${rewardAmount}积分`}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ReferralItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  isLastReferrer: PropTypes.bool,
};

ReferralItem.defaultProps = {
  item: {},
  isLastReferrer: false,
};

function ReferralStep({ icon, title }) {
  return (
    <Box display="flex" alignItems="center" ml="21px">
      <Box width="24px" height="24px">
        {icon}
      </Box>
      <Box ml="12px" fontSize="14px" fontWeight={500} color="rgba(0,0,0,0.56)">
        {title}
      </Box>
    </Box>
  );
}

ReferralStep.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

function CandidateReferrals() {
  const { applicationRecommendRewardRecordsData } = useCandidateState();
  const {
    totalAmount,
    list,
    interviewTime,
    rewardTime,
    rewardType,
    rewardStatus,
  } = applicationRecommendRewardRecordsData;

  return (
    <Box padding="16px">
      {list.map((item, i) => (
        <div key={item.token}>
          <ReferralItem item={item} isLastReferrer={i === list.length - 1} />
          <ArrowItem />
        </div>
      ))}
      <ReferralStep
        icon={<CheckCircleIcon />}
        title={
          interviewTime ? (
            <span>面试者于{moment(parseInt(interviewTime * 1000, 10)).format('YYYY-MM-DD')}进行面试</span>
          ) : (
            <span>面试者进行面试</span>
          )
        }
      />
      <ArrowItem />
      <ReferralStep
        icon={<MonetizationOnIcon on={rewardStatus === 2} />}
        title={
          rewardStatus === 2 ? (
            <span>
              于{moment(parseInt(rewardTime * 1000, 10)).format('YYYY-MM-DD HH:mm')} 发放
              {rewardType === 1 ? `${totalAmount / 100}方便币` : `${totalAmount}积分`}作为奖励
            </span>
          ) : (
            <span>发放{rewardType === 1 ? `${totalAmount / 100}方便币` : `${totalAmount}积分`}作为奖励</span>
          )
        }
      />
    </Box>
  );
}

export default CandidateReferrals;
