const defaultState = {
  count: 0,
  candidateData: {},
  candidateDataLoading: false,
  applicationData: {},
  applicationLogsData: [],
  candidateApplicationData: {},
  applicationFlowData: {},
  applicationEvaluationData: {},
  mineEvaluationData: {},
  candidateToken: '',
  applicationToken: '',
  applicationFlowStepIndex: 0,
  tabName: 'loading',
  applicationFormData: {},
  candidateAssignablePositionsData: [],
  candidateAssignablePositionsDataLoading: false,
  isShowAssignablePositionsList: false,
  hideAISteps: false,
  autoPlay: false,
  isNextButton: true,
  isResumeEditing: false,
  isResumeEdited: false,
  isEditing: false,
  isShowRecord: false,
  applicationRecommendRewardRecordsData: {},
  clickedSendButton: 0,
  applicationInterviewArrangementData: {},
  alertDialog: {},
  isTabsLoading: true,
  hasPermission: true,
  isEditFormItemResultDialogOpen: false,
  itemDataForEditFormItemResultDialog: {},
};

export default defaultState;
