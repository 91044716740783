import * as API from '@api/Department';
import { DepartmentSingleIcon } from '@avocadoui/icons';
import { Box, Button, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { animated, useSpring } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import { ReactComponent as ArrowDropDownIcon } from '../../assets/img/icons/ArrowDropDown.svg';
import { ReactComponent as ArrowDropRightIcon } from '../../assets/img/icons/ArrowRight.svg';
import { ReactComponent as ArrowRightDisabledIcon } from '../../assets/img/icons/ArrowRightDisabled.svg';
import Spin from '../Spin';
import TreeItem from '../Tree/TreeItem';
import TreeView from '../Tree/TreeView';
import styles from './UserDartmentManager.module.css';

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    // eslint-disable-next-line react/destructuring-assignment
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

TransitionComponent.defaultProps = {
  in: true,
};

const StyledTreeItem = withStyles((theme) => ({
  root: {
    paddingLeft: 6,
    '&$selected > $content $label': {
      backgroundColor: '#fff !important',
      color: '#4BB051',
    },
    '&:focus > $content $label': {
      backgroundColor: '#fff !important',
    },
  },
  selected: {
    backgroundColor: 'white',
  },
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px solid ${fade('#4059FE', 1.0)}`,
  },
  content: {
    height: theme.spacing(4.5),
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 400,
    color: 'rgba(0,0,0,0.56)',
    height: 36,
    lineHeight: '36px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

const departmentTree = (departments, handleLableClick, handleIconClick) => {
  if (departments.children.length > 0) {
    return departments.children.map((v) => (
      <StyledTreeItem
        key={v.token}
        nodeId={v.token}
        label={v.name}
        className={styles.treeItem}
        onLabelClick={() => {
          handleLableClick(v, v.name);
        }}
        onSingleDpepartmentClick={() => {
          handleIconClick(v, v.name);
        }}
      >
        {departmentTree(v, handleLableClick, handleIconClick)}
      </StyledTreeItem>
    ));
  }
  return '';
};

const getSelectedDepartmentTokens = (nodes) => nodes.children.reduce(
  (acc, val) => (Array.isArray(val.children) && val.children.length > 0
    ? acc.concat(getSelectedDepartmentTokens(val))
    : acc.concat(val.token)),
  [nodes.token],
);
function UserDartmentManager({
  onDartpmentClick,
  onDartpmentAllClick,
  selectedDepartmentTokens,
  onCreateDartpmentClick,
  user,
}) {
  const classes = useStyles();
  const [userDepartment, setUserDepartment] = useState([]);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    API.getUserDeparmentTree()
      .then((v) => {
        setUserDepartment(v.data.data.list);
        setSelectedAll(v.data.data.selectedAll);
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleLableClick = (department, name) => {
    const tokens = getSelectedDepartmentTokens(department);
    onDartpmentClick(tokens, name);
  };

  const handleIconClick = (department, name) => {
    onDartpmentClick([department.token], name);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spin />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      pl={3}
      pr={3}
      height="calc(100% - 50px)"
      alignItems="center"
      pb={5}
    >
      <Box width={1} style={{ overflow: 'scroll' }} flexGrow="1" mb={3}>
        <Typography className={styles.companyName} onClick={onDartpmentAllClick} style={{ cursor: 'pointer', paddingLeft: 8 }}>
          {user.enterprise.name}
        </Typography>
        <TreeView
          multiSelect
          selected={isSelectedAll ? [] : selectedDepartmentTokens}
          className={classes.root}
          defaultExpanded={[]}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowDropRightIcon />}
          defaultEndIcon={<ArrowRightDisabledIcon />}
          selectSingleIcon={<DepartmentSingleIcon />}
        >
          {userDepartment.map((v) => (
            <StyledTreeItem
              key={v.token}
              nodeId={v.token}
              label={v.name}
              className={styles.treeItem}
              onLabelClick={() => handleLableClick(v, v.name)}
              onSingleDpepartmentClick={() => {
                handleIconClick(v, v.name);
              }}
            >
              {departmentTree(v, handleLableClick, handleIconClick)}
            </StyledTreeItem>
          ))}
        </TreeView>
      </Box>
      {user.user.role === 1 && (
        <Button
          variant="outlined"
          color="primary"
          onClick={onCreateDartpmentClick}
          startIcon={<AddIcon />}
          className={styles.addDepartmentButton}
        >
          新增分部
        </Button>
      )}
    </Box>
  );
}

UserDartmentManager.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  onDartpmentClick: PropTypes.func,
  onCreateDartpmentClick: PropTypes.func,
  onDartpmentAllClick: PropTypes.func,
  selectedDepartmentTokens: PropTypes.arrayOf(PropTypes.any),
};

UserDartmentManager.defaultProps = {
  user: {},
  onDartpmentClick: () => {},
  onDartpmentAllClick: () => {},
  onCreateDartpmentClick: () => {},
  selectedDepartmentTokens: [],
};

function mapStateToProps(state) {
  return {
    user: state.account.userInfo,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(UserDartmentManager));
