import { checkCollaboratorExist } from '@api/ApplicationShare';
import {
  Box, Button, Paper, TextField,
} from '@material-ui/core';
import { MOBILE_REGEX } from '@utils/RegexUtils';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import PaperHeader from './PaperHeader';

const CreateCollaborator = ({ token, onBackClick, onConfirmClick }) => {
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [error, setError] = useState('');

  const handleCreatCollaborator = useCallback(() => {
    if (MOBILE_REGEX.test(contact)) {
      checkCollaboratorExist(token, { name, contact }).then((v) => {
        if (v.data.code === 0) {
          if (v.data.data.exist) {
            setError('该手机号已是协作者，不可重复添加');
          } else {
            onConfirmClick({
              name,
              mobile: contact,
              role: 11,
              roleName: '外部',
            });
          }
        } else {
          setError(v.data.message);
        }
      }).catch(console.log);
    } else {
      setError('请输入正确的手机格式');
    }
  }, [name, contact]);

  const handleFeildChange = useCallback((e) => {
    const { value, name: filedName } = e.target;
    if (filedName === 'name') {
      setName(value);
    } else {
      setContact(value);
    }
  }, []);

  return (
    <Paper style={{ width: 410 }}>
      <PaperHeader title="添加外部协作者" onBackClick={onBackClick} />
      <Box mr={2} ml={3}>
        <Box height={72}>
          <TextField fullWidth placeholder="请输入姓名" variant="outlined" name="name" value={name} onChange={handleFeildChange} />
        </Box>
        <Box height={72}>
          <TextField error={!isEmpty(error)} helperText={error} fullWidth placeholder="请输入手机号" variant="outlined" name="contact" value={contact} onChange={handleFeildChange} />
        </Box>
      </Box>
      <Box height={56} display="flex" justifyContent="flex-end" alignItems="center" mr={2} ml={3}>
        <Button onClick={onBackClick}>取消</Button>
        <Box mr={1} />
        <Button disabled={isEmpty(contact) || isEmpty(name)} variant="contained" color="primary" onClick={handleCreatCollaborator}>
          添加
        </Button>
      </Box>
    </Paper>
  );
};
CreateCollaborator.propTypes = {
  token: PropTypes.string,
  onBackClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
};

CreateCollaborator.defaultProps = {
  token: '',
  onBackClick: null,
  onConfirmClick: null,
};

export default CreateCollaborator;
