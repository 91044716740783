import { getFormWithAnswer, submitFormWithScore } from '@api/Form';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import useGlobalToast from '@utils/GlobalToast';
import PropTypes from 'prop-types';
import { useCandidateDispatch, useCandidateState } from '../context';
import EditFormItemResultDialogContent from './EditFormItemResultDialogContent';

function SaveButton({ formToken }) {
  const GlobalToast = useGlobalToast();
  const dispatch = useCandidateDispatch();
  const itemDataForEditFormItemResultDialog = useCandidateState((state) => state.itemDataForEditFormItemResultDialog);
  const { formTypeId, formItemToken, formAnswer } = itemDataForEditFormItemResultDialog;

  function getData() {
    let formAnswers;

    if (formTypeId === 1) {
      formAnswers = [
        {
          token: formItemToken,
          files: formAnswer.files,
        },
      ];
    } else if ([2, 7, 8].indexOf(formTypeId) > -1) {
      formAnswers = [
        {
          token: formItemToken,
          answer: formAnswer.answer,
        },
      ];
    } else if (formTypeId === 3) {
      formAnswers = [
        {
          token: formItemToken,
          choiceOptions: formAnswer.choiceOptions,
        },
      ];
    } else if (formTypeId === 4) {
      formAnswers = [
        {
          token: formItemToken,
          dateTime: `${formAnswer.dateTime}`,
        },
      ];
    }

    return {
      formUsageToken: formToken,
      formAnswers,
    };
  }

  async function handleSave() {
    const data = getData();

    submitFormWithScore(data).then((result) => {
      if (result.data.code === 0) {
        GlobalToast.success('修改成功');
        getFormWithAnswer(formToken).then((result2) => {
          if (result2.data.code === 0) {
            dispatch({
              type: 'SAVE_TO_APPLICATION_FORM_DATA',
              payload: {
                token: formToken,
                data: result2.data.data,
              },
            });
            dispatch({ type: 'REFRESH_DATA' });
          }
        });
      }
    });

    dispatch({ type: 'CLOSE_EDIT_FORM_ITEM_RESULT_DIALOG' });
  }

  function isDisabled() {
    if (formTypeId === 1) {
      return formAnswer.files.length === 0;
    } if ([2, 7].indexOf(formTypeId) > -1) {
      return !formAnswer.answer?.trim();
    } if (formTypeId === 3) {
      return formAnswer.choiceOptions.length === 0;
    } if (formTypeId === 8) {
      return formAnswer.answer.some((v) => !v?.trim());
    }
    return false;
  }

  return (
    <Button variant="contained" color="primary" onClick={handleSave} disabled={isDisabled()}>
      保存
    </Button>
  );
}

SaveButton.propTypes = {
  formToken: PropTypes.string.isRequired,
};

function EditFormItemResultDialog({ formToken }) {
  const dispatch = useCandidateDispatch();
  const isEditFormItemResultDialogOpen = useCandidateState((state) => state.isEditFormItemResultDialogOpen);

  function handleCancel() {
    dispatch({ type: 'CLOSE_EDIT_FORM_ITEM_RESULT_DIALOG' });
  }

  return (
    <Dialog
      open={isEditFormItemResultDialogOpen}
      PaperProps={{
        style: {
          width: '360px',
          height: 'auto',
        },
      }}
    >
      <DialogTitle>修改答案</DialogTitle>
      <DialogContent>
        <EditFormItemResultDialogContent />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="default" onClick={handleCancel}>
          取消
        </Button>
        <SaveButton formToken={formToken} />
      </DialogActions>
    </Dialog>
  );
}

EditFormItemResultDialog.propTypes = {
  formToken: PropTypes.string.isRequired,
};

export default EditFormItemResultDialog;
