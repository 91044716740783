import { createActions } from 'redux-actions';

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = createActions(
  {
    ENQUEUE_SNACKBAR: (notification) => {
      const key = notification.options && notification.options.key;
      return {
        notification: {
          ...notification,
          key: key || new Date().getTime() + Math.random(),
        },
      };
    },
    CLOSE_SNACKBAR: (key) => ({
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    }),
    REMOVE_SNACKBAR: (key) => ({
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    }),
  },
);

export default {};
