import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import { getSelectionCustomInlineStyle } from 'draftjs-utils';
import { isEmpty } from 'lodash';
import { ReactComponent as FontColorIcon } from '../../assets/img/icons/notify/email/FontColor.svg';

const COLORS = [
  { label: 'color1', style: '#000000' },
  { label: 'color2', style: '#E4352E' },
  { label: 'color3', style: '#FF9900' },
  { label: 'color4', style: '#FFD900' },
  { label: 'color5', style: '#0F8024' },
  { label: 'color6', style: '#39D9F0' },
  { label: 'color7', style: '#308FFA' },
  { label: 'color8', style: '#AA17D0' },

  { label: 'color9', style: '#F5F5F5' },
  { label: 'color10', style: '#FCDBD6' },
  { label: 'color11', style: '#FDEAD0' },
  { label: 'color12', style: '#FEF0CE' },
  { label: 'color13', style: '#D4E9D5' },
  { label: 'color14', style: '#DEF3F3' },
  { label: 'color15', style: '#CEDFEF' },
  { label: 'color16', style: '#DFDBEB' },

  { label: 'color17', style: '#D0D0D0' },
  { label: 'color18', style: '#EE837C' },
  { label: 'color19', style: '#F8C387' },
  { label: 'color20', style: '#FFDA5C' },
  { label: 'color21', style: '#9ABD9C' },
  { label: 'color22', style: '#82CCD1' },
  { label: 'color23', style: '#8AB0CE' },
  { label: 'color24', style: '#9389B1' },

  { label: 'color25', style: '#999896' },
  { label: 'color26', style: '#D41427' },
  { label: 'color27', style: '#CE760D' },
  { label: 'color28', style: '#8D644A' },
  { label: 'color29', style: '#557B5C' },
  { label: 'color30', style: '#03A2B0' },
  { label: 'color31', style: '#3677A6' },
  { label: 'color32', style: '#765C82' },

  { label: 'color33', style: '#666564' },
  { label: 'color34', style: '#A91914' },
  { label: 'color35', style: '#874602' },
  { label: 'color36', style: '#553725' },
  { label: 'color37', style: '#00562E' },
  { label: 'color38', style: '#00767A' },
  { label: 'color39', style: '#184E77' },
  { label: 'color40', style: '#530D6F' },
];

const ELEditorToolbarColor = (props) => {
  const { toggleInlineStyle, editorState } = props;
  const [currentColor, setCurrentColor] = useState('rgba(0,0,0,0.56)');
  const currentColorStyle = !isEmpty(editorState) && getSelectionCustomInlineStyle(editorState, ['COLOR']).COLOR;

  useEffect(() => {
    const cColor = (currentColorStyle && currentColorStyle.replace('color-', '')) || 'rgba(0,0,0,0.56)';
    if (cColor) {
      setCurrentColor(cColor);
    }
  }, [currentColorStyle]);

  return (
    <Box>
      <PopupState variant="popover" disableAutoFocus>
        {(popupState) => (
          <>
            <IconButton {...bindTrigger(popupState)}>
              <FontColorIcon className="MuiSvgIcon-root" style={{ color: currentColor }} />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              variant="menu"
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <Box display="flex" flexWrap="wrap" width={176} height={115} p={1.5}>
                {COLORS.map((type) => (
                  <Box
                    style={{ background: type.style, cursor: 'pointer' }}
                    onClick={() => {
                      toggleInlineStyle(type.style);
                    }}
                    width="15px"
                    height="15px"
                    mr={0.5}
                    mt={0.5}
                    flexShrink={0}
                  />
                ))}
              </Box>
            </Popover>
          </>
        )}
      </PopupState>
    </Box>
  );
};

const colorStyleMap = {
  color1: {
    color: '#000000',
  },
  color2: {
    color: '#E4352E',
  },
  color3: {
    color: '#FF9900',
  },
  color4: {
    color: '#FFD900',
  },
  color5: {
    color: '#0F8024',
  },
  color6: {
    color: '#39D9F0',
  },
  color7: {
    color: '#308FFA',
  },
  color8: {
    color: '#AA17D0',
  },
  color9: {
    color: '#F5F5F5',
  },
  color10: {
    color: '#FCDBD6',
  },
  color11: {
    color: '#FDEAD0',
  },
  color12: {
    color: '#FEF0CE',
  },
  color13: {
    color: '#D4E9D5',
  },
  color14: {
    color: '#DEF3F3',
  },
  color15: {
    color: '#CEDFEF',
  },
  color16: {
    color: '#DFDBEB',
  },
  color17: {
    color: '#D0D0D0',
  },
  color18: {
    color: '#EE837C',
  },
  color19: {
    color: '#F8C387',
  },
  color20: {
    color: '#FFDA5C',
  },
  color21: {
    color: '#9ABD9C',
  },
  color22: {
    color: '#82CCD1',
  },
  color23: {
    color: '#8AB0CE',
  },
  color24: {
    color: '#9389B1',
  },
  color25: {
    color: '#999896',
  },
  color26: {
    color: '#D41427',
  },
  color27: {
    color: '#CE760D',
  },
  color28: {
    color: '#8D644A',
  },
  color29: {
    color: '#557B5C',
  },
  color30: {
    color: '#03A2B0',
  },
  color31: {
    color: '#3677A6',
  },
  color32: {
    color: '#765C82',
  },
  color33: {
    color: '#666564',
  },
  color34: {
    color: '#A91914',
  },
  color35: {
    color: '#874602',
  },
  color36: {
    color: '#553725',
  },
  color37: {
    color: '#00562E',
  },
  color38: {
    color: '#00767A',
  },
  color39: {
    color: '#184E77',
  },
  color40: {
    color: '#530D6F',
  },
};

ELEditorToolbarColor.propTypes = {
  editorState: PropTypes.objectOf(PropTypes.any),
  toggleInlineStyle: PropTypes.objectOf(PropTypes.any),
};

ELEditorToolbarColor.defaultProps = {
  editorState: {},
  toggleInlineStyle: {},
};

export { colorStyleMap };
export default ELEditorToolbarColor;
