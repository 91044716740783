import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import { ReactComponent as TickIcon } from '../../assets/img/icons/TickIcon.svg';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
    color: 'rgba(0,0,0,0.56)',
  },
  titleText: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#333333',
  },
  assistText: {
    fontSize: '13px',
    color: '#9B9A9A',
    '&:before': {
      content: '"• "',
      fontSize: '10px',
    },
  },
});

const ELConfirmDialog = ({
  classes, open, onCancel, examURL, title,
}) => {
  const iframeRef = useRef();

  const [isShowSuccess, setIsShowSuccess] = useState(false);

  const receiveMessage = useCallback((event) => {
    if (event) {
      const { data } = event;
      if (data.name === 'smb' && data.event === 'reviewed') {
        setIsShowSuccess(true);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => window.removeEventListener('message', receiveMessage);
  }, []);

  return (
    <Dialog
      // scroll="body"
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      aria-labelledby="department-delete-dialog-title"
      className={classes.root}
      PaperProps={{
        style: {
          width: '800px',
          // minHeight: '944px',
          height: '100%',
        },
      }}
      open={open}
      onExited={() => {
        setIsShowSuccess(false);
      }}
    >
      <DialogTitle id="customized-dialog-title">
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="subtitle1">{title}</Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {!isShowSuccess && (
          <iframe
            ref={iframeRef}
            src={examURL}
            width="100%"
            // height="100%"
            style={{ height: 'calc(100% - 8px)' }}
            frameBorder="0"
            title="答卷展示"
          />
        )}
        {isShowSuccess && (
          <Box
            width="100%"
            height="100%"
            justifyContent="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <TickIcon />
            <Typography
              variant="body1"
              style={{
                fontWeight: 500, color: '#858585', marginTop: 21, marginBottom: 29,
              }}
            >
              评价完成
            </Typography>
            <Button onClick={onCancel} variant="outlined" color="primary">
              好的
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

ELConfirmDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  examURL: PropTypes.string.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

ELConfirmDialog.defaultProps = {
  title: '答卷展示',
  open: true,
  onCancel: () => {},
};

export default withStyles(styles)(ELConfirmDialog);
