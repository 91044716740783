/* eslint-disable jsx-a11y/anchor-is-valid */
import { postInterviewOneEvaluationRemind } from '@api/Notification';
import { CloseIcon, DoneIcon, WarningIcon } from '@avocadoui/icons';
import Typography from '@components/Avocado/Typography';
import {
  Box, Link, makeStyles, Tooltip,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import useGlobalToast from '@utils/GlobalToast';
import moment from '@utils/moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEvaluationDispatch } from './context';

const useStyle = makeStyles(() => ({
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    '&:hover': {
      '& $visible': {
        display: 'block',
      },
      '& $hidden': {
        display: 'none',
      },
    },
  },
  visible: {
    display: 'none',
  },
  hidden: {
    display: 'block',
  },
}));

export default function EvaluationItem({
  item = {}, selfEvaluationToken, isScoreOpen,
}) {
  const classes = useStyle();
  const dispatch = useEvaluationDispatch();
  const GlobalToast = useGlobalToast();
  const [timer, setTimer] = useState(true);
  const [remindTime, setRemindTime] = useState(null);
  const {
    token: applicationEvaluationToken,
    username,
    level,
    stars,
    score,
    status,
    lastNotifyTime,
    hasSendNotify,
  } = item;
  const isSelf = selfEvaluationToken === applicationEvaluationToken;
  function handleView(token) {
    dispatch({ type: 'SET_SELECTED_EVALUATION_TOKEN', payload: token });
  }
  const getReminder = () => {
    setTimer(false);
    postInterviewOneEvaluationRemind({ applicationEvaluationToken }).then((result) => {
      const { code, lastNotifyTime: resultLastNotifyTime } = result.data.data;
      if (resultLastNotifyTime) {
        if (code === 0) {
          setRemindTime(resultLastNotifyTime);
          GlobalToast.success('提醒成功');
        }
      }
    }).catch(console.log());
  };
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="12px 8px 12px 16px"
        height={56}
        className={classes.root}
        borderBottom="solid 1px rgba(0, 0, 0, 0.08)"
        borderRadius="0 0 3px 3px"
      >
        <Box display="flex" alignItems="center">
          <Typography
            variant="body2"
            color="text.primary"
            className={classes.truncate}
            style={{
              width: '120px',
            }}
          >
            {isSelf ? '我' : username}
          </Typography>
          {status === 1 && level === 3 && (
            <Tooltip title="通过">
              <Box
                mr={8.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="solid 1px #E8F5E9"
                bgcolor="#E8F5E9"
                width={32}
                height={24}
              >
                <DoneIcon style={{ width: 24, height: 24 }} color="primary" />
              </Box>
            </Tooltip>
          )}
          {status === 2 && (
            <Box mr={8.5}>
              <Typography variant="body2" color="rgba(0, 0, 0, 0.26)">
                私密评价
              </Typography>
            </Box>
          )}
          {status === 3 && (
            <Tooltip title="未反馈">
              <Box
                mr={8.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="solid 1px #FFF8E1"
                bgcolor="#FFF8E1"
                width={32}
                height={24}
              >
                <WarningIcon style={{ width: 24, height: 24 }} htmlColor="#F5C441" />
              </Box>
            </Tooltip>
          )}
          {level === 1 && status !== 2 && (
            <Tooltip title="不通过">
              <Box
                mr={8.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="solid 1px #FFEBEE"
                bgcolor="#FFEBEE"
                width={32}
                height={24}
              >
                <CloseIcon style={{ width: 24, height: 24 }} htmlColor="#880E4F" />
              </Box>
            </Tooltip>
          )}
          {status !== 2 && status !== 3 && <Box display="flex" alignItems="center">{stars ? <Rating value={stars} readOnly /> : <Box width="120px" />}</Box>}
        </Box>

        {status === 3 && !hasSendNotify && (
          <Box mr={2} style={{ cursor: 'pointer' }}>
            {timer && (
              <Tooltip title="发送短信、邮件和系统消息提醒填写评价">
                <Link onClick={getReminder}>发送提醒</Link>
              </Tooltip>
            )}
            {!timer && (
              <Typography variant="caption" color="text.secondary">
                { remindTime > 0 ? `已于${moment(parseInt(remindTime * 1000, 10)).format('MM月DD日 HH:mm')}提醒` : ''}
              </Typography>
            )}
          </Box>
        )}
        {status === 3 && hasSendNotify && (
          <Box mr={2} style={{ cursor: 'pointer' }}>
            {timer && (
              <Typography variant="caption" color="text.secondary">
                {lastNotifyTime ? `已于${moment(parseInt(lastNotifyTime * 1000, 10)).format('MM月DD日 HH:mm')}提醒` : ''}
              </Typography>
            )}
          </Box>
        )}
        {level === 3 && status === 1 && (
          <Box display="flex" alignItems="center">
            <Box flexShrink={0} className={classes.visible} mr={2} style={{ cursor: 'pointer' }}>
              <Link onClick={() => handleView(applicationEvaluationToken)}>查看评价</Link>
            </Box>
            {isScoreOpen && (
              <Box display="flex" alignItems="center" mr={2} className={classes.hidden}>
                <Typography variant="caption" color="text.secondary">得分：{score}</Typography>
              </Box>
            )}
          </Box>
        )}
        {level === 1 && status === 1 && (
          <Box display="flex" alignItems="center">
            <Box flexShrink={0} className={classes.visible} mr={2} style={{ cursor: 'pointer' }}>
              <Link onClick={() => handleView(applicationEvaluationToken)}>查看评价</Link>
            </Box>
            {isScoreOpen && (
              <Box display="flex" alignItems="center" mr={2} className={classes.hidden}>
                <Typography variant="caption" color="text.secondary">得分：{score}</Typography>
              </Box>
            )}
          </Box>
        )}
        {level === 0 && status === 1 && (
          <Box display="flex" alignItems="center">
            <Box flexShrink={0} className={classes.visible} mr={2} style={{ cursor: 'pointer' }}>
              <Link onClick={() => handleView(applicationEvaluationToken)}>查看评价</Link>
            </Box>
            {isScoreOpen && (
              <Box display="flex" alignItems="center" mr={2} className={classes.hidden}>
                <Typography variant="caption" color="text.secondary">得分：{score}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

EvaluationItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  selfEvaluationToken: PropTypes.string.isRequired,
  isScoreOpen: PropTypes.bool.isRequired,
};
