import { createActions } from 'redux-actions';

export const {
  fetchCommentFormList,
  setCommentFormList,
  setCommentFormListSelectedIndex,
  setCommentFormNeedSave,
  fetchCommentFormInfo,
  setCommentFormInfo,
  clearCommentFormInfo,
  createCommentForm,
  updateCommentForm,
  deleteCommentForm,
  fetchPositionList,
  setPositionList,
  fetchPositionDetail,
  setPositionDetail,
} = createActions(
  {},
  'FETCH_COMMENT_FORM_LIST',
  'SET_COMMENT_FORM_LIST',
  'SET_COMMENT_FORM_LIST_SELECTED_INDEX',
  'SET_COMMENT_FORM_NEED_SAVE',
  'FETCH_COMMENT_FORM_INFO',
  'SET_COMMENT_FORM_INFO',
  'CLEAR_COMMENT_FORM_INFO',
  'CREATE_COMMENT_FORM',
  'UPDATE_COMMENT_FORM',
  'DELETE_COMMENT_FORM',
  'FETCH_POSITION_LIST',
  'SET_POSITION_LIST',
  'FETCH_POSITION_DETAIL',
  'SET_POSITION_DETAIL',
);

export default {};
