import * as Api from '@api/ApplicationShare';
import { CheckCircleIcon, LarkIcon, SMSIcon } from '@avocadoui/icons';
import Typography from '@components/Avocado/Typography';
import {
  Box, Button, Paper, Tooltip,
} from '@material-ui/core';
import useGlobalToast from '@utils/GlobalToast';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import IconButton from './IconButton';
import MemberAutoComplate from './MemberAutoComplate';
import PaperHeader from './PaperHeader';

const ManagementCollaborator = ({
  token, value, onBackClick, onCreateClick, onChange,
}) => {
  const GloablToast = useGlobalToast();

  const [messagePlatform, setMessagePlatform] = useState([true, true, false, false]);

  const handleCollaboratorChange = useCallback((_, val) => {
    // if (val.length === 0) {
    //   onBackClick();
    // } else {
    onChange(_, val);
    // }
  }, []);

  const handleMessagePlatformChange = useCallback((index) => {
    setMessagePlatform((state) => {
      const temp = [...state];
      temp[index] = !temp[index];
      return temp;
    });
  }, []);

  const handleCancelMessage = useCallback(() => {
    onBackClick();
  }, [onBackClick]);

  const handleSendMessage = useCallback(() => {
    const platform = messagePlatform.reduce((p, v, i) => {
      if (v) {
        return [...p, i + 1];
      }
      return p;
    }, []);
    Api.sendCollaboratorNotification(token, {
      users: value,
      notifyType: platform,
    })
      .then((v) => {
        if (v.data.code === 0) {
          GloablToast.success(platform.length > 1 ? '通知成功' : '添加成功');
        } else {
          GloablToast.warning(v.data.message);
        }
        onBackClick();
      })
      .catch(() => {
        GloablToast.warning('通知失败');
      });
  }, [messagePlatform, value]);

  return (
    <Paper style={{ width: 410 }}>
      <PaperHeader title="添加协作者" onBackClick={onBackClick} />
      <Box mt={1} mb={2} mr={2} ml={3}>
        <MemberAutoComplate
          token={token}
          value={value}
          onChange={handleCollaboratorChange}
          onCreateClick={onCreateClick}
        />
      </Box>
      <Box height={56} display="flex" justifyContent="space-between" alignItems="center" mr={2} ml={3}>
        <Box display="flex" alignItems="center">
          <Typography fontWeight={500} variant="body2">
            发送通知：
          </Typography>
          <Tooltip title="短信" arrow>
            <Box mr={1} position="relative">
              {messagePlatform[0] && (
                <CheckCircleIcon
                  color="primary"
                  style={{
                    position: 'absolute',
                    right: -4,
                    top: -4,
                    width: 15,
                    height: 15,
                    zIndex: 2,
                  }}
                />
              )}
              <IconButton
                size="small"
                onClick={() => {
                  handleMessagePlatformChange(0);
                }}
              >
                <SMSIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title="飞书" arrow>
            <Box mr={1} position="relative">
              {messagePlatform[1] && (
                <CheckCircleIcon
                  color="primary"
                  style={{
                    position: 'absolute',
                    right: -4,
                    top: -4,
                    width: 15,
                    height: 15,
                  }}
                />
              )}
              <IconButton
                size="small"
                onClick={() => {
                  handleMessagePlatformChange(1);
                }}
              >
                <LarkIcon />
              </IconButton>
            </Box>
          </Tooltip>
          {/* <Tooltip title="企业微信" arrow>
            <Box mr={1} position="relative">
              {messagePlatform[2] && (
              <CheckCircleIcon
                color="primary"
                style={{
                  position: 'absolute',
                  right: -4,
                  top: -4,
                  width: 15,
                  height: 15,
                }}
              />
              )}
              <IconButton
                size="small"
                onClick={() => {
                  handleMessagePlatformChange(2);
                }}
              >
                <EWeChatIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title="钉钉" arrow>
            <Box position="relative">
              {messagePlatform[3] && (
              <CheckCircleIcon
                color="primary"
                style={{
                  position: 'absolute',
                  right: -4,
                  top: -4,
                  width: 15,
                  height: 15,
                }}
              />
              )}
              <IconButton
                size="small"
                onClick={() => {
                  handleMessagePlatformChange(3);
                }}
              >
                <DingDingIcon />
              </IconButton>
            </Box>
          </Tooltip> */}
        </Box>
        <Box display="flex">
          <Button onClick={handleCancelMessage}>取消</Button>
          <Box mr={1} />
          <Button variant="contained" color="primary" onClick={handleSendMessage} disabled={value?.length === 0}>
            {messagePlatform.some((v) => v) ? '发送' : '完成'}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

ManagementCollaborator.propTypes = {
  token: PropTypes.string,
  onCreateClick: PropTypes.func,
  onBackClick: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.object),
};

ManagementCollaborator.defaultProps = {
  token: '',
  onCreateClick: null,
  onBackClick: null,
  onChange: null,
  value: [],
};

export default ManagementCollaborator;
