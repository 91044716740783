import * as API from '@api/Resume';
import { withStyles } from '@material-ui/core/';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const LimitedWidthTextField = withStyles(() => ({
  root: {
    // width: 230,
    flexShrink: 0,
  },
}))(TextField);

const MajorSelect = ({
  name, value, label, onChange, size,
}) => {
  const [colleges, setColleges] = useState([]);
  useEffect(() => {
    API.getMajor()
      .then((v) => {
        if (v.data.code === 0) {
          setColleges(v.data.data.list.map((vv) => vv.name));
        }
      })
      .catch(console.log);
  }, []);
  return (
    <Autocomplete
      // disableCloseOnSelect
      freeSolo
      onChange={(_, v) => {
        onChange(name, v);
      }}
      onBlur={(e) => {
        const v = e.target.value;
        onChange(name, v);
      }}
      id="School-select-tags"
      options={colleges}
      getOptionLabel={(option) => option}
      value={value || null}
      renderInput={(params) => (
        <LimitedWidthTextField
          {...params}
          fullWidth
          variant="outlined"
          label={label}
          size={size}
          name={name}
          // value={value}
        />
      )}
    />
  );
};

MajorSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

MajorSelect.defaultProps = {
  name: 'major',
  value: '',
  label: '专业',
  size: 'small',
  onChange: () => {},
};

export default MajorSelect;
