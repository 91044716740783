import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropsTypes from 'prop-types';

function Empty(props) {
  const { emptyTitle, emptyDesc } = props;
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <img
        src="//public-static-assets.oss-cn-beijing.aliyuncs.com/img/Search-rafiki.png"
        height="139"
        width="180"
        alt=""
      />
      {emptyTitle && (
        <>
          <Box m={3} />
          <Typography variant="h6">{emptyTitle}</Typography>
        </>
      )}
      {emptyDesc && (
        <>
          <Box m={1} />
          <Typography variant="body2" color="textSecondary">
            {emptyDesc}
          </Typography>
        </>
      )}
    </Box>
  );
}
Empty.propTypes = {
  emptyTitle: PropsTypes.string.isRequired,
  emptyDesc: PropsTypes.string.isRequired,
};

export default Empty;
