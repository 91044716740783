import { withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

const StyledSnackbar = withStyles({
  root: {},
  anchorOriginTopCenter: {
    top: 68,
  },
})(Snackbar);

export default StyledSnackbar;
