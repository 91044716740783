import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const GenderSelect = ({
  name, value, label, onChange,
}) => (
  <TextField
    fullWidth
    select
    variant="outlined"
    label={label}
    size="small"
    name={name}
    value={value}
    onChange={onChange}
  >
    <MenuItem key="1" value="1">
      男
    </MenuItem>
    <MenuItem key="2" value="2">
      女
    </MenuItem>
  </TextField>
);
GenderSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

GenderSelect.defaultProps = {
  name: 'sex',
  value: '',
  label: '性别',
  onChange: () => {},
};

export default GenderSelect;
