import { newEvaluationDetail } from '@api/Evaluation';
import { EmptyIcon } from '@avocadoui/icons';
import Typography from '@components/Avocado/Typography';
import { Box, Button } from '@material-ui/core';
import useGlobalToast from '@utils/GlobalToast';
import { isEmpty } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_VISIBLE_SWITCH } from '../const';
import { useEvaluationDispatch, useEvaluationState } from './context';
import EvaluationFormEditor from './EvaluationFormEditor';
import EvaluationScores from './EvaluationScores';
import EvaluationVisibleUsersEditor from './EvaluationVisibleUsersEditor';
import useEffectReloadEvaluationTemplate from './hooks/useEffectReloadEvaluationTemplate';
import useMemoFormAnswers from './hooks/useMemoFormAnswers';
import useMemoFormItems from './hooks/useMemoFormItems';
import useMemoShouldDisabledSubmit from './hooks/useMemoShouldDisabledSubmit';
import useMemoTotalScore from './hooks/useMemoTotalScore';
import { removeEvaluation, setEvaluation } from './utils';

function TemplateItemDetail() {
  const GlobalToast = useGlobalToast();
  const reduxDispatch = useDispatch();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const confirm = useConfirm();
  const dispatch = useEvaluationDispatch();
  const formData = useEvaluationState((state) => state.formData);
  const feedbackData = useEvaluationState((state) => state.feedbackData);
  const selectedTemplateToken = useEvaluationState((state) => state.selectedTemplateToken);
  const selectedTemplateDetail = useEvaluationState((state) => state.selectedTemplateDetail);

  const {
    name,
    formItems = [],
    hasFeedback = false,
    visibleUsers = [],
    visibleSwitch = DEFAULT_VISIBLE_SWITCH,
    fullScore,
    isScoreOpen = false,
    loaded = false,
  } = selectedTemplateDetail;

  const CACHE_ID = useMemo(() => (`${applicationToken}${selectedTemplateToken}`), [applicationToken, selectedTemplateToken]);

  useEffectReloadEvaluationTemplate(CACHE_ID);

  const disabledSubmmit = useMemoShouldDisabledSubmit({
    formItems,
    formData,
    feedbackData,
    hasFeedback,
    visibleSwitch,
    visibleUsers,
  });
  const totalScore = useMemoTotalScore(formData);
  const formAnswers = useMemoFormAnswers(formData);
  const modifiedFormItems = useMemoFormItems(formItems, hasFeedback);
  useEffect(() => {
    if (loaded) {
      setEvaluation(CACHE_ID, {
        formItems, formData, feedbackData, hasFeedback, visibleSwitch, visibleUsers,
      });
    }
  }, [formItems, formData, feedbackData, hasFeedback, visibleSwitch, visibleUsers, CACHE_ID, loaded]);
  const EmptyForm = () => {
    confirm({
      title: '',
      description: '确认清空所填评价表吗',
      cancellationText: '取消',
      confirmationText: '清空',
      dialogProps: {
        PaperProps: {
          style: {
            width: 360,
            minHeight: '72px',
          },
        },
      },
      confirmationButtonProps: {
        variant: 'contained',
        color: 'secondary',
      },
    })
      .then(() => {
        try {
          removeEvaluation(CACHE_ID);
          dispatch({ type: 'RESET_TEPLATE_INPUT_DATA' });
        } catch (e) {
          console.log(e);
        }
      })
      .catch();
  };

  if (isEmpty(formItems)) {
    return null;
  }

  function handleSubmit() {
    const data = {
      applicationToken,
      tplToken: selectedTemplateToken,
      visibleSwitch,
      visibleUsers: visibleSwitch === 1 ? visibleUsers.map((u) => u.userToken) : [],
      formAnswers,
      feedback: feedbackData,
    };
    newEvaluationDetail(data)
      .then((result) => {
        if (result.data.code === 0) {
          removeEvaluation(CACHE_ID);
          dispatch({ type: 'BACK_TO_LIST_AND_REFRESH_DATA' });
          reduxDispatch({ type: 'RELOAD_TEMPORARY_DRAWER' });
          GlobalToast.success('提交成功');
        } else {
          GlobalToast.error('提交失败');
        }
      })
      .catch(() => {
        GlobalToast.error('提交失败');
      });
  }

  return (

    <Box
      width={326}
      padding={2}
      height
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box display="flex" justifyContent="space-between" width>
        <Box display="flex" alignItems="center" justifyContent="space-between" width>
          <Box>
            <Typography variant="body1" color="text.primary" fontWeight="500">
              {name}
            </Typography>
          </Box>
          {
            Object.values(formData).length > 0 && (
              <Box>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={EmptyForm}
                  startIcon={<EmptyIcon style={{ width: 20, height: 20 }} />}
                >
                  清空
                </Button>
              </Box>
            )
          }
        </Box>
      </Box>
      <Box flex="1 1 auto" overflow="scroll">
        <Box>
          {isScoreOpen && (
            <Box mt={2} display="flex" justifyContent="flex-start">
              <EvaluationScores totalScore={totalScore} fullScore={fullScore} />
            </Box>
          )}
          {modifiedFormItems.map((item, index) => (
            <EvaluationFormEditor key={item.formItemToken} item={item} index={index} />
          ))}
          <EvaluationVisibleUsersEditor visibleSwitch={visibleSwitch} visibleUsers={visibleUsers} />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mt={2}
        mr={-2}
      >
        <Box mr={1}>
          <Button
            onClick={() => {
              dispatch({ type: 'BACK_TO_LIST_AND_REFRESH_DATA' });
            }}
          >
            取消
          </Button>
        </Box>
        <Box mr={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disabledSubmmit}>
            提交
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(TemplateItemDetail);
