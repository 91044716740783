import MuiIconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';

const IconButton = withStyles(() => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.16)',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  sizeSmall: {
    padding: 3,
  },
  disabled: {
    filter: 'grayscale(100%)',
  },
}))(MuiIconButton);

export default IconButton;
