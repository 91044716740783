import {
  EditorState, Modifier, SelectionState, convertToRaw,
} from 'draft-js';
import Immutable from 'immutable';
import draftToHtml from 'draftjs-to-html';

const { OrderedSet } = Immutable;

export function getEntities(editorState, entityType = null) {
  const content = editorState.getCurrentContent();
  const entities = [];
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType().toLowerCase() === entityType)) {
            selectedEntity = {
              blockText: block.text,
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      },
    );
  });
  return entities;
}
// 基于服务端返回的Variable数据为准替换
export const transitionServerToEditorState = (editorState, templateVariableList) => {
  const metions = [...getEntities(editorState, 'metion'), ...getEntities(editorState, 'mention')];
  let replaceContentState = editorState.getCurrentContent();
  const delta = {};
  metions.forEach((v) => {
    if (delta[v.blockKey] === undefined) delta[v.blockKey] = 0;
    const templateName = v.blockText.substr(v.start, v.end - v.start).trim();

    const serverTemplate = templateVariableList.find((vv) => vv.desc === templateName);

    const serverTemplateName = serverTemplate?.value;
    if (serverTemplateName && serverTemplateName !== '日期时间') {
      const selectionState = SelectionState.createEmpty(v.blockKey);
      const updatedSelection = selectionState.merge({
        anchorOffset: v.start + delta[v.blockKey],
        focusOffset: delta[v.blockKey] + v.end,
      });
      const replaceText = serverTemplateName;

      if (serverTemplate.type === 'link') {
        const contentStateWithEntity = replaceContentState.createEntity('LINK', 'IMMUTABLE', {
          url: replaceText,
          target: '_blank',
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        replaceContentState = Modifier.replaceText(
          replaceContentState,
          updatedSelection,
          replaceText,
          OrderedSet(['GreenSpan']),
          entityKey,
        );
      } else {
        replaceContentState = Modifier.replaceText(
          replaceContentState,
          updatedSelection,
          replaceText,
          OrderedSet(['GreenSpan']),
          v.entityKey,
        );
      }

      delta[v.blockKey] += replaceText.length - (v.end - v.start);
    } else {
      const selectionState = SelectionState.createEmpty(v.blockKey);
      const updatedSelection = selectionState.merge({
        anchorOffset: v.start + delta[v.blockKey],
        focusOffset: delta[v.blockKey] + v.end,
      });

      replaceContentState = Modifier.replaceText(
        replaceContentState,
        updatedSelection,
        templateName,
        OrderedSet(['RedSpan']),
        v.entityKey,
      );
    }
  });
  return replaceContentState;
};

export const convertToString = (editorState, templateVariableList) => {
  const metions = [...getEntities(editorState, 'metion'), ...getEntities(editorState, 'mention')];
  let replaceContentState = editorState.getCurrentContent();
  const delta = {};
  metions.forEach((v) => {
    if (delta[v.blockKey] === undefined) delta[v.blockKey] = 0;
    const templateName = v.blockText.substr(v.start, v.end - v.start).trim();
    const serverTemplateName = templateVariableList[templateName];
    if (serverTemplateName && serverTemplateName !== '日期时间') {
      const selectionState = SelectionState.createEmpty(v.blockKey);
      const updatedSelection = selectionState.merge({
        anchorOffset: v.start + delta[v.blockKey],
        focusOffset: delta[v.blockKey] + v.end,
      });
      const replaceText = serverTemplateName;
      replaceContentState = Modifier.replaceText(
        replaceContentState,
        updatedSelection,
        replaceText,
        OrderedSet(['GreenSpan']),
        v.entityKey,
      );
      delta[v.blockKey] += replaceText.length - (v.end - v.start);
    } else {
      const selectionState = SelectionState.createEmpty(v.blockKey);
      const updatedSelection = selectionState.merge({
        anchorOffset: v.start + delta[v.blockKey],
        focusOffset: delta[v.blockKey] + v.end,
      });

      replaceContentState = Modifier.replaceText(
        replaceContentState,
        updatedSelection,
        templateName,
        OrderedSet(['RedSpan']),
        v.entityKey,
      );
    }
  });
  return replaceContentState;
};

// 编辑器模板变量替换
export const transitionEditorStateToServer = (editorState, templateVariableList, type = 'PlainText') => {
  const contentJson = convertToRaw(editorState.getCurrentContent());
  const serilizeContent = JSON.stringify(contentJson);

  const metions = getEntities(editorState, 'metion');
  let variables = [];
  let replaceContentState = editorState.getCurrentContent();
  const delta = {};
  metions.forEach((v) => {
    if (delta[v.blockKey] === undefined) delta[v.blockKey] = 0;
    const templateName = v.blockText.substr(v.start, v.end - v.start).trim();
    const serverTemplateName = templateVariableList.find((vv) => vv.variableName === templateName);
    if (serverTemplateName) {
      const selectionState = SelectionState.createEmpty(v.blockKey);
      const updatedSelection = selectionState.merge({
        anchorOffset: v.start + delta[v.blockKey],
        focusOffset: delta[v.blockKey] + v.end,
      });
      const replaceText = `{{.${serverTemplateName.variableDesc}}}`;
      replaceContentState = Modifier.replaceText(
        replaceContentState,
        updatedSelection,
        replaceText,
        OrderedSet(['GreenSpan']),
        v.entityKey,
      );
      delta[v.blockKey] += replaceText.length - (v.end - v.start);
      variables = variables.concat({ token: serverTemplateName.token, name: serverTemplateName.variableName });
    }
  });
  variables = variables.flat() || [];
  return {
    template:
      type === 'PlainText' ? replaceContentState.getPlainText() : draftToHtml(convertToRaw(replaceContentState)),
    variables: variables.map((v) => v.token),
    templateRich: serilizeContent,
  };
};

export const moveSelectionToEnd = (editorState) => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const key = blockMap.last().getKey();
  const length = blockMap.last().getLength();
  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });
  return EditorState.acceptSelection(editorState, selection);
};

export const CommentTransitionStateToServer = (editorState) => {
  const plainText = editorState.getCurrentContent().getPlainText();
  const editorData = convertToRaw(editorState.getCurrentContent());
  const mentions = Object.keys(editorData.entityMap).map((v) => editorData.entityMap[v]).filter((v) => v.type === 'mention');
  let comment = plainText;
  mentions.forEach((v) => {
    comment = comment.replace(`@${v.data.mention.name}`, `{{${v.data.mention.userToken}}}`);
  });
  return comment;
};

export const getEditorStateLength = (editorState) => editorState.getCurrentContent().getPlainText().length;

export default {};
