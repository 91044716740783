import { Box } from '@material-ui/core';
import fp from 'lodash/fp';
import { useCandidateState } from '../../context';
import VideoPlayer from './VideoPlayer';

const tabsplit = fp.split('::');

const VideoPlayerWarp = () => {
  const { applicationFormData = {}, ...rest } = useCandidateState();
  const [type, token] = tabsplit(rest.tabName);
  if (type === 'form') {
    const currentForm = applicationFormData[token];
    if (currentForm?.data.videoUrl) {
      return (
        <Box position="relative" m={2}>
          <VideoPlayer videoURL={currentForm?.data.videoUrl} />
        </Box>
      );
    }
  }
  return null;
};

VideoPlayerWarp.propTypes = {};
VideoPlayerWarp.defaultProps = {};
export default VideoPlayerWarp;
