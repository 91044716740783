import { Box, Button, Typography } from '@material-ui/core';
import { Component } from 'react';

class ErrorView extends Component {
  render() {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
        <img src="https://webcdn.fbmms.cn/assets/icons/Ufo.svg" alt="ufo" />
        <Typography variant="h5" style={{ marginTop: 32, marginBottom: 16 }}>
          啊哦…页面被外星人带走啦！
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: 'rgba(0, 0, 0, 0.56)',
            marginBottom: 32,
          }}
        >
          请尝试重新刷新页面
        </Typography>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          刷新当前页
        </Button>
      </Box>
    );
  }
}

export default ErrorView;
