import moment from '@utils/moment';
import _ from 'lodash';

export function isStartOfDay(timestamp) {
  const date = new Date(timestamp);
  const sum = date.getHours() + date.getMinutes() + date.getMinutes();
  return sum === 0;
}

export function startOfMonth(month) {
  const now = month ? moment().month(month - 1) : moment();
  const date = now.startOf('month');
  const timestamp = date.unix();
  return timestamp;
}

export function endOfMonth(month) {
  const now = month ? moment().month(month - 1) : moment();
  const date = now.endOf('month');
  const timestamp = date.unix();
  return timestamp;
}

export function getConflictedPeriod({ conflictedPeriods, interviewMode, isEditPeriodToken }) {
  const isEditingPeriod = isEditPeriodToken != null && isEditPeriodToken !== '';
  // 自动、编辑
  if (interviewMode === 1 && isEditingPeriod) {
    const theEditingPeriod = conflictedPeriods.find((p) => p.id === isEditPeriodToken
      || p.token === isEditPeriodToken) || {};
    return {
      applicationTokens: theEditingPeriod.applicationTokens || [],
      interviewerTokens: theEditingPeriod.interviewerTokens || [],
    };
  }
  // 手动、新增 or 手动、编辑 or 自动、新增
  const applicationTokens = conflictedPeriods.reduce((pre, cur) => [...pre, ...cur.applicationTokens], []);
  const interviewerTokens = conflictedPeriods.reduce((pre, cur) => [...pre, ...cur.interviewerTokens], []);
  return { applicationTokens: _.uniq(applicationTokens), interviewerTokens: _.uniq(interviewerTokens) };
}
