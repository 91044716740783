import { KeyboardArrowLeftIcon } from '@avocadoui/icons';
import Typography from '@components/Avocado/Typography';
import {
  Box, IconButton,
} from '@material-ui/core';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';

const PaperHeader = ({ title, onBackClick }) => (
  <Box height={56} display="flex" alignItems="center" pl={1}>
    {onBackClick && (
      <IconButton onClick={onBackClick}>
        <KeyboardArrowLeftIcon />
      </IconButton>
    )}
    <Typography variant="body1" fontWeight={500} ml={isNull(onBackClick) ? 2 : 0} flexGrow={1}>
      {title}
    </Typography>
  </Box>
);

PaperHeader.propTypes = {
  title: PropTypes.string,
  onBackClick: PropTypes.func,
};

PaperHeader.defaultProps = {
  title: '',
  onBackClick: null,
};

export default PaperHeader;
