import axios from './Api';

export const applicationWechatQRcode = (token) => {
  if (!token) return Promise.reject();
  return axios.request({
    url: `/applicationShare/wechat/qrcode/${token}`,
    method: 'POST',
  });
};

export const applicationCollaborator = (token, data) => {
  if (!token) return Promise.reject();
  return axios.request({
    url: `/applicationShare/collaborator/${token}`,
    method: 'POST',
    data,
  });
};

export const createApplicationCollaborator = (token, data) => {
  if (!token) return Promise.reject();
  return axios.request({
    url: `application/${token}/interviewers`,
    method: 'POST',
    data,
  });
};

export const sendCollaboratorNotification = (token, data) => axios.request({
  url: `/applicationShare/collaborator/notification/${token}`,
  method: 'POST',
  data,
});

export const checkCollaboratorExist = (token, data) => axios.request({
  url: `/applicationShare/collaborator/${token}/exist`,
  method: 'POST',
  data,
});

// export const postApplicationCollaborator = (token) => {
//   if (!token) return Promise.reject();
//   return axios.request({
//     url: `/application/${token}/collaborator/notification`,
//     method: 'POST',
//   });
// };

export const searchCollaborator = (token, data) => {
  if (!token) return Promise.reject();
  return axios.request({
    url: `/applicationShare/collaborator/search/${token}`,
    method: 'POST',
    data,
  });
};

export const deleteCollaborator = (token, data) => {
  if (!token) return Promise.reject();
  return axios.request({
    url: `/applicationShare/collaborator/cancel/${token}`,
    method: 'POST',
    data,
  });
};
