import API_URL from '@config/conf';
import { GET, POST } from './network/http';

export const API = {
  postLogon: 'logon',
  getUserInfo: 'loginUserInfo',
  postDepartmentSelected: 'departmentSwitch',
  getHrUserInfo: 'admin/user/#{token}',
  postAddUser: 'admin/user',
  putEditUserInfo: 'admin/user/#{token}',
  delDropUser: 'admin/user',
  getHrUserList: 'admin/users',
  getInterviewVideoList: 'interview/videos?token=',
  getCandidateGET: 'candidate',
  addCandidatePOST: 'candidate',
  editCandidatePUT: 'candidate',
  editCandidatePATCH: 'candidate',
  deleteCandidateDELETE: 'candidate',
  getCandidatesGET: 'candidates',
  getCandidatesResume: 'candidate/resume/#{token}',
  getCandidatesWithFlowGET: 'candidatesWithApplication',
  getCandidateInfoGET: 'candidate/#{token}',
  getCandidatesStatGET: 'candidate/stat',
  addCandidateGroupPOST: 'candidateGroup',
  editCandidateGroupPOST: 'candidateGroup/#{token}/candidates',
  editCandidateGroupPUT: 'candidateGroup/#{token}',
  getCandidateGroupGET: 'candidateGroups?searchText=',
  deleteCandidateGroupDELETE: 'candidateGroup',
  addPatternPOST: 'pattern',
  getPatternsCommonGET: 'patterns/common?searchText=#{text}&tagName=#{tag}',
  getPatternsCustomGET: 'patterns/custom?searchText=#{text}&type=#{type}',
  getPatternGET: 'pattern/#{token}',
  editPatternPUT: 'pattern/#{token}',
  deletePatternDELETE: 'pattern',
  addSessionPOST: 'applicationSession',
  getProcessStatisticalGET: 'applicationSession/stat',
  editOpenApplicationSessionPUT: 'applicationSession/open',
  editCloseApplicationSessionPUT: 'applicationSession/close',
  getAllReviewListStatisticalGET: 'applicationSession/detailStat',
  getReviewListStatisticalGET: 'applicationSession/#{sessionToken}/stat',
  editApplicationSessionPUT: 'applicationSession/#{sessionToken}',
  getApplicationSessionsGET: 'applicationSessions',
  getApplicationSessionGET: 'applicationSession/#{token}',
  getWelfareListGET: 'tag/treatments',
  postWelfareAddPOST: 'tag/treatment',
  getPatternTagsGET: 'patternTags?searchText=#{text}',
  addToApplicationSessionPOST: 'applicationSession/#{appToken}/candidates',
  deleteFromApplicationSessionDELETE: 'applicationSession/#{appToken}/candidates',
  postApplicationSessionPOST: 'applicationSession/#{appToken}/invitation',
  getAllCandidateOfApplication: 'applicationSession/candidateSearch',
  getCandidateOfApplication: 'applicationSession/#{appToken}/candidateSearch',
  getCandidateApplication: 'applicationSession/candidateSearch',
  getApplicationFlowGET: 'applicationFlow/#{flowToken}',
  getApplicationLogsGET: 'applicationFlow/#{flowToken}/logs',
  addApplicationFlowCommentPOST: 'applicationFlow/#{flowToken}/comment',
  editApplicationFlowCommentPUT: 'applicationFlow/#{flowToken}/comment/#{commentToken}',
  editFlowStepStatusPUT: 'applicationFlow/#{flowToken}/step/#{stepToken}',
  passFlowStatusPOST: 'applicationFlow/approve',
  rejectFLowStatusPOST: 'applicationFlow/reject',
  resumeFlowStatusPOST: 'applicationFlow/reset',
  cancelInterviewPOST: 'applicationFlow/cancel',
  getApplicationUploadAuthPOST: 'applicationSession/uploadAuth',
  getRPAEnterpriseListGET: 'rpa',
  // TODO:解绑平台、绑定平台
  getPlatformPositionListGET: 'rpa/platform/#{token}/positions',
  bindPlatformPositionPOST: 'rpa/positionBinding',
  releasePlatformPositionDELETE: 'rpa/positionBinding',
  postCreateAndUpdateForm: 'form',
  getFormGET: 'form/get?token=#{token}',
  getFormWithAnswer: 'form/getWithAnswer?token=#{token}',
  postJobCollectionPOST: 'positions/collection',
};

export async function login(username, password) {
  try {
    const LOGIN = API_URL + API.postLogon;
    const post = {
      account: username,
      password,
    };
    const result = await POST(LOGIN, JSON.stringify(post));
    const parseResult = JSON.parse(result);
    return parseResult;
  } catch (error) {
    console.log(error);
    return '';
  }
}

export async function getHrList(hrListActions) {
  hrListActions.clearLocalHrList();
  try {
    const getHrListUrl = API_URL + API.getHrUserList;

    const result = await GET(getHrListUrl);
    const parseResult = JSON.parse(result);
    if (parseResult.code === 0) {
      hrListActions.addHrList(parseResult.data.list);
    } else {
      console.log(`HTTP:GET:ERRCODE:${parseResult.code}`);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getCandidates(candidatesActions, filter, notNeedClearSelected) {
  let response;

  try {
    const url = API_URL + API.getCandidatesWithFlowGET;
    const result = await POST(url, JSON.stringify(filter));
    const parseResult = JSON.parse(result);
    response = parseResult;
    if (parseResult.code === 0) {
      candidatesActions.addCandidates(parseResult.data.list, parseResult.data.total);
      if (!notNeedClearSelected) {
        candidatesActions.clearSelectedCandidate();
      }
    } else {
      console.log(`HTTP:GET:ERRCODE:${parseResult.code}`);
    }
  } catch (error) {
    console.log(error);
  }

  return response;
}

export async function getProcesses(processesActions, filter, notNeedClearSelected) {
  // processesActions.clearLocalProcesses();
  let response;

  try {
    const searchText = filter.searchText === '' ? '' : `searchText=${filter.searchText}&`;
    const asc = filter.orderBy === '' ? '' : `asc=${filter.asc}&`;
    const orderBy = filter.orderBy === '' ? '' : `orderBy=${filter.orderBy}&`;
    const page = filter.page === '' ? '' : `page=${filter.page}&`;
    const pageSize = filter.pageSize === '' ? '' : `pageSize=${filter.pageSize}&`;
    const status = filter.status === '' ? '' : `status=${filter.status}&`;
    const editCandidate = `${
      API_URL + "vc1/applicationSessionsNew"
    }?${searchText}${status}${asc}${orderBy}${page}${pageSize}`;

    const result = await GET(editCandidate);
    const parseResult = JSON.parse(result);
    response = parseResult;
    if (parseResult.code === 0) {
      processesActions.addProcesses(parseResult.data.list, parseResult.data.total);
      if (!notNeedClearSelected) {
        processesActions.clearSelectedProcess();
      }
    } else {
      console.log(`HTTP:GET:ERRCODE:${parseResult.code}`);
    }
  } catch (error) {
    console.log(error);
  }

  return response;
}
// 获取当前流程的人员列表
export async function getProcessCandidateList(reviewListActions, workbenchToken, filter) {
  // reviewListActions.clearReviewCandidate();
  try {
    const searchText = filter.searchText === '' ? '' : `searchText=${filter.searchText}&`;
    const processToken = filter.processToken === '' ? '' : `processToken=${filter.processToken}&`;
    const sex = filter.sex === '' ? '' : `sex=${filter.sex}&`;
    const ageFrom = filter.ageFrom === '' ? '' : `ageFrom=${filter.ageFrom}&`;
    const ageTo = filter.ageTo === '' ? '' : `ageTo=${filter.ageTo}&`;
    const degree = filter.degree === '' ? '' : `degree=${filter.degree}&`;
    const source = filter.source === '' ? '' : `source=${filter.source}&`;
    const applyTimeStart = filter.applyTimeStart === '' ? '' : `applyTimeStart=${filter.applyTimeStart}&`;
    const applyTimeEnd = filter.applyTimeEnd === '' ? '' : `applyTimeEnd=${filter.applyTimeEnd}&`;
    const applyStatus = filter.applyStatus === '' ? '' : `applyStatus=${filter.applyStatus}&`;
    const orderBy = filter.orderBy === '' ? '' : `orderBy=${filter.orderBy}&`;
    const asc = filter.asc === '' ? '' : `asc=${filter.asc}&`;
    const page = filter.page === '' ? '' : `page=${filter.page}&`;
    const pageSize = filter.pageSize === '' ? '' : `pageSize=${filter.pageSize}&`;
    const sessionToken = workbenchToken === 'all' ? '' : `sessionToken=${workbenchToken}&`;
    const token = workbenchToken === '' ? '' : sessionToken;

    let GetProcessCandidate = '';
    GetProcessCandidate = `${
      API_URL + API.getCandidateApplication
    }?${token}${searchText}${processToken}${sex}${ageFrom}${ageTo}${degree}${source}${applyTimeStart}${applyTimeEnd}${applyStatus}${orderBy}${asc}${page}${pageSize}`;
    const result = await GET(GetProcessCandidate);
    const parseResult = JSON.parse(result);
    if (parseResult.code === 0) {
      const review = parseResult.data.list;
      if (typeof review !== 'undefined') {
        reviewListActions.addReviewCandidates(review, parseResult.data.total, parseResult.data.showTableHeaders);
      }
    } else {
      console.log(`HTTP:GET:ERRCODE:${parseResult.code}`);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getCandidatesStatistical(candidatesStatisticalActions) {
  let response;

  try {
    const getData = API_URL + API.getCandidatesStatGET;
    const result = await GET(getData);
    const parseResult = JSON.parse(result);
    response = parseResult;
    if (parseResult.code === 0) {
      candidatesStatisticalActions.updateCandidatesStatistical(parseResult.data);
    } else {
      console.log(`HTTP:GET:ERRCODE:${parseResult.code}`);
    }
  } catch (error) {
    console.log(error);
  }

  return response;
}

export async function getProcessStatistical(processStatisticalActions) {
  let response;

  try {
    const getData = API_URL + API.getProcessStatisticalGET;

    const result = await GET(getData);
    const parseResult = JSON.parse(result);
    response = parseResult;
    if (parseResult.code === 0) {
      processStatisticalActions.updateProcessStatistical(parseResult.data);
    } else {
      console.log(`HTTP:GET:ERRCODE:${parseResult.code}`);
    }
  } catch (error) {
    console.log(error);
  }

  return response;
}

export async function getReviewListStatistical(processStatisticalActions, sessionToken) {
  let getData;
  const sesToken = sessionToken === 'all' ? '' : `sessionToken=${sessionToken}&`;
  const Token = sessionToken === '' ? '' : sesToken;
  try {
    getData = `${API_URL + API.getAllReviewListStatisticalGET}?${Token}`;
    const result = await GET(getData);
    const parseResult = JSON.parse(result);
    if (parseResult.code === 0) {
      processStatisticalActions.updateReviewListStatistical(parseResult.data);
    } else {
      console.log(`HTTP:GET:ERRCODE:${parseResult.code}`);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getApplicationUploadAuth() {
  try {
    const UploadAuth = API_URL + API.addPatternPOST;

    const body = {};
    const result = await POST(UploadAuth, JSON.stringify(body));
    const parseResult = JSON.parse(result);
    if (parseResult.code === 0) {
      console.log(`parseResult:${parseResult}`);
    } else {
      console.log(`HTTP:GET:ERRCODE:${parseResult.code}`);
    }
  } catch (error) {
    console.log(error);
  }
}
