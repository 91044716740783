import { withStyles } from '@material-ui/core';
import MuiAvatar from '@material-ui/core/Avatar';

const Avatar = withStyles({
  colorDefault: {
    backgroundColor: 'rgba(75, 176, 81, 0.06);',
  },
})(MuiAvatar);

export default Avatar;
