import ELButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import DateTimePicker from './DateTimePicker';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
    color: 'rgba(0,0,0,0.56)',
  },
  titleText: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#333333',
  },
  assistText: {
    fontSize: '13px',
    color: '#9B9A9A',
    '&:before': {
      content: '"• "',
      fontSize: '10px',
    },
  },
});

const ELConfirmDialog = ({
  value,
  classes,
  open,
  onCancel,
  onConfirm,
  title,
  cancelTitle,
  confirmTitile,
  severity,
  label,
}) => {
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    setTime(value);
  }, [value]);

  return (
    <Dialog
      aria-labelledby="department-delete-dialog-title"
      className={classes.root}
      PaperProps={{
        style: {
          width: '520px',
        },
      }}
      open={open}
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="subtitle1">{title}</Typography>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        }}
      >
        <DateTimePicker
          minDate="1970-01-01"
          label={label}
          keyboardInput
          value={time}
          onChange={(val) => {
            setTime(val);
          }}
        />
      </DialogContent>
      <DialogActions>
        <ELButton onClick={onCancel}> {cancelTitle}</ELButton>
        <ELButton
          variant="contained"
          disableElevation
          disabled={!moment(time).isValid()}
          color={severity === 'success' ? 'primary' : 'secondary'}
          onClick={() => {
            onConfirm(parseInt(time.getTime() / 1000, 10));
          }}
        >
          {confirmTitile}
        </ELButton>
      </DialogActions>
    </Dialog>
  );
};

ELConfirmDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  severity: PropTypes.oneOf(['success', 'warning']),
  value: PropTypes.instanceOf(Date),
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelTitle: PropTypes.string,
  confirmTitile: PropTypes.string,
  label: PropTypes.string,
};

ELConfirmDialog.defaultProps = {
  title: '发起代码笔试',
  severity: 'success',
  value: new Date(),
  open: true,
  onCancel: () => {},
  onConfirm: () => {},
  cancelTitle: '取消',
  confirmTitile: '确认',
  label: '笔试时间',
};

export default withStyles(styles)(ELConfirmDialog);
