import { handleActions } from 'redux-actions';
import {
  setCurrentDepartmentCandidateCount, setCurrentDepartmentPositionCount, setCurrentPosition,
  setCurrentPositionProcessStat, setCurrentPositionProcessStatFirstLoading,
  setFrequentlyUsed,
  setFrequentlyUsedFirstLoading, setIsFirstLoadingPositions, setIsLoadingPositions, setProcessStatLoading,
} from './action';

export const namespace = 'processReview';

export const defaultState = {
  frequentlyUsed: [],
  aiModel: [],
  frequentlyUsedFirstLoading: true,
  currentDepartmentPositionCount: 0,
  currentDepartmentCandidateCount: 0,
  isLoadingPositions: false,
  isFirstLoadingPositions: true,
  currentPosition: {
    assignDepartment: [],
    candidateCount: 0,
    code: '',
    createUserName: '',
    createdAt: 0,
    departmentName: '',
    departmentToken: '',
    isCanAssign: false,
    name: '全部职位',
    patternsCount: 0,
    qrcodeUrl: '',
    recommendStatus: 0,
    referDepartment: [],
    requirement: null,
    source: 0,
    status: 0,
    token: '',
    waitAuditCount: 0,
  },
  processStatLoading: false,
  currentPositionProcessStatFirstLoading: true,
  currentPositionProcessStat: [
    {
      token: 'wON8ZwUCg4YpUPR7SQ9jQ79L7T',
      customName: '所有待面试',
      name: '待面试',
      desc: '',
      type: 2,
      isSystem: true,
    },
    {
      token: 'jeA9idIleSjtFVPSk5ErQ79L7U',
      customName: '所有待面试',
      name: '待审核',
      desc: '',
      type: 1,
      isSystem: true,
    },
    {
      token: 'U4A0YXcShXv0DlLxxg58Q79L7V',
      customName: '所有待面试',
      name: '已通过',
      desc: '',
      type: 1,
      isSystem: true,
    },
    {
      token: 'UCIkPVNu57gO4fl9ykv0Q956X9',
      customName: '所有待面试',
      name: '已入职',
      desc: '',
      type: 1,
      isSystem: true,
    },
    {
      token: 'VjaL28zeAZQTBDSDTijZQ956XY',
      customName: '所有待面试',
      name: '已离职',
      desc: '',
      type: 1,
      isSystem: true,
    },
    {
      token: 'Uv6o0udluRHOuZkVJnVpQGP5FT',
      customName: '所有待面试',
      name: '其他步骤',
      desc: '',
      type: 0,
      isSystem: true,
    },
  ],
};

export const processReviewReducer = handleActions(
  {
    [setCurrentPosition]: (state, action) => {
      const data = action.payload;
      return { ...state, currentPosition: data };
    },
    [setCurrentPositionProcessStat]: (state, action) => {
      const data = action.payload;
      return { ...state, currentPositionProcessStat: data };
    },
    [setCurrentPositionProcessStatFirstLoading]: (state, action) => {
      const data = action.payload;
      return { ...state, currentPositionProcessStatFirstLoading: data };
    },
    [setProcessStatLoading]: (state, action) => {
      const data = action.payload;
      return { ...state, processStatLoading: data };
    },
    [setFrequentlyUsed]: (state, action) => {
      const data = action.payload;
      return { ...state, frequentlyUsed: data };
    },
    [setFrequentlyUsedFirstLoading]: (state, action) => {
      const data = action.payload;
      return { ...state, frequentlyUsedFirstLoading: data };
    },
    [setCurrentDepartmentPositionCount]: (state, action) => {
      const data = action.payload;
      return { ...state, currentDepartmentPositionCount: data };
    },
    [setCurrentDepartmentCandidateCount]: (state, action) => {
      const data = action.payload;
      return { ...state, currentDepartmentCandidateCount: data };
    },
    [setIsLoadingPositions]: (state, action) => {
      const data = action.payload;
      return { ...state, isLoadingPositions: data };
    },
    [setIsFirstLoadingPositions]: (state, action) => {
      const data = action.payload;
      return { ...state, isFirstLoadingPositions: data };
    },
  },
  defaultState,
);
