import * as API from '@api/School';
import { withStyles } from '@material-ui/core/';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const LimitedWidthTextField = withStyles(() => ({
  root: {
    width: 230,
    flexShrink: 0,
  },
}))(TextField);

const EducationSelect = ({
  name, value, label, onChange,
}) => {
  const [colleges, setColleges] = useState([]);
  useEffect(() => {
    API.getCollage()
      .then((v) => {
        setColleges(v.data);
      })
      .catch(console.log);
  }, []);
  return (
    <Autocomplete
      freeSolo
      onChange={(_, v) => {
        onChange(name, v);
      }}
      // onInputChange={(e, v) => {
      //   // console.log(e, v);
      //   onChange(name, v);
      // }}
      onBlur={(e) => {
        const v = e.target.value;
        onChange(name, v);
      }}
      id="School-select-tags"
      options={colleges}
      getOptionLabel={(option) => option}
      value={value || null}
      renderInput={(params) => (
        <LimitedWidthTextField
          {...params}
          fullWidth
          variant="outlined"
          label={label}
          size="small"
          name={name}
          // value={value}
        />
      )}
    />
  );
};

EducationSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

EducationSelect.defaultProps = {
  name: 'education',
  value: '',
  label: '学历',
  onChange: () => {},
};

export default EducationSelect;
