import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  circle: {
    display: 'inline-block',
    width: (props) => props.width,
    height: (props) => props.height,
    borderWidth: (props) => props.borderWidth,
    borderColor: '#4caf50',
    borderStyle: 'solid',
    borderLeftColor: 'transparent',
    borderRadius: '100%',
    animation: '$loading 0.7s infinite linear',
  },
  '@keyframes loading': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
});

const CircularSpin = (props) => {
  const classes = useStyles(props);

  return <div className={classes.circle} />;
};

CircularSpin.defaultProps = {
  width: '40px',
  height: '40px',
  borderWidth: '2px',
};

export default CircularSpin;
