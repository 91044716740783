import API_URL from '@config/conf';
import { Box, Button, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '../../components/Avocado/Typography';
import RoleSwitch from '../../components/Share/Login/RoleSwitch';
import { TransparentButton } from '../components/units/components/colorButton';
import http from '../utils/http/interviewer';
import StyledInterviews from './styledInterviewer';

function InterviewerUI({ positionToken, toCandidateDesc, loginType }) {
  const [showRoleSwitch, setShowRoleSwitch] = useState(false);
  // 测试 token YDCgXKrqF0AHmXJLgGTFApgCG0MmZgBE
  const [authTokenTmp, setAuthTokenTmp] = useState('');

  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const [phoneErrorTips, setPhoneErrorTips] = React.useState('');
  const [phoneCode, setPhoneCode] = React.useState('');
  const [phoneCodeError, setPhoneCodeError] = React.useState(false);
  const [phoneCodeErrorTips, setPhoneCodeErrorTips] = React.useState('');
  const [isShowForm, setIsShowFrom] = React.useState(false);
  const [isShowSend, setIsShowSend] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(60);

  const handlePhoneNumber = (event) => {
    setPhoneNumberError(false);
    setPhoneErrorTips('');
    setPhoneNumber(event.target.value);
  };

  const handlePhoneCode = (event) => {
    setPhoneCodeError(false);
    setPhoneCodeErrorTips('');
    setPhoneCode(event.target.value);
  };

  const getPhoneCode = async () => {
    if (!/^1[3456789]\d{9}$/.test(phoneNumber)) {
      setPhoneNumberError(true);
      setPhoneErrorTips('填写正确格式手机号');
      return;
    }
    const data = {
      applicationToken: positionToken,
      mobile: phoneNumber,
    };
    console.log(data);
    const res = await http.loginpost({ url: `${API_URL}interviewer/sendSmsCode`, data });
    console.log(res);
    if (res.data.code === 0) {
      localStorage.setItem('x_application_token', positionToken);
      setPhoneNumberError(false);
      setPhoneErrorTips('');
      let time = 59;
      setIsShowFrom(true);
      setIsShowSend(true);
      const timer = setInterval(() => {
        setCurrentTime(time);
        time -= 1;
        if (time < 0) {
          clearInterval(timer);
          setIsShowSend(false);
          setCurrentTime(60);
        }
      }, 1000);
    } else if (res.data.code === -4001) {
      setPhoneNumberError(true);
      setPhoneErrorTips('您没有访问权限');
    } else if (res.data.code === -2001) {
      setPhoneNumberError(true);
      setPhoneErrorTips(res.data.message);
    } else {
      setPhoneNumberError(true);
      setPhoneErrorTips(res.data.message);
    }
  };

  const sureLogin = async () => {
    const data = {
      code: phoneCode,
      mobile: phoneNumber,
      type: loginType,
    };
    const res = await http.post({ url: `${API_URL}interviewer/smsLogin`, data });
    if (res.data.code === 0) {
      if (res.data.data.hasManyRoles) {
        setAuthTokenTmp(res.data.data.authTokenTmp);
        setShowRoleSwitch(true);
      } else {
        localStorage.setItem('interViewer_token', res.data.data.authTokenTmp);
        localStorage.setItem('id_token', res.data.data.authTokenTmp);
        localStorage.setItem('x_application_token', positionToken);
        toCandidateDesc(res.data.data.authTokenTmp);
      }
    } else {
      setPhoneCodeError(true);
      setPhoneCodeErrorTips(res.data.message || '验证码输入错误');
    }
  };

  const handleRoleSwitch = useCallback((token) => {
    localStorage.setItem('interViewer_token', token);
    localStorage.setItem('id_token', token);
    localStorage.setItem('x_application_token', positionToken);
    toCandidateDesc(token);
  }, [positionToken]);

  return (
    <StyledInterviews>
      <div className="loginTitle">方便面面试</div>
      {!showRoleSwitch && (
        <Paper className="interViewerInputWrap">
          <Box px={3}>
            <Box height={56} display="flex" alignItems="center">
              <Typography variant="h5" fontWeight={500}>
                审核邀请1
              </Typography>
            </Box>
            <div className="inputWrap">
              <TextField
                fullWidth
                error={phoneNumberError}
                name="phoneNumber"
                label="手机号"
                margin="normal"
                variant="outlined"
                onChange={handlePhoneNumber}
                helperText={phoneErrorTips}
              />
              {isShowForm && (
                <TextField
                  fullWidth
                  error={phoneCodeError}
                  name="phoneCode"
                  label="验证码"
                  margin="normal"
                  variant="outlined"
                  onChange={handlePhoneCode}
                  helperText={phoneCodeErrorTips}
                />
              )}
              <div className="sendTips">
                {isShowForm && isShowSend && <span>{currentTime}秒后重新发送</span>}
                {isShowForm && !isShowSend && (
                  <TransparentButton className="sendButton" onClick={getPhoneCode}>
                    获取验证码
                  </TransparentButton>
                )}
              </div>
              {isShowForm ? (
                <Button color="primary" variant="contained" fullWidth onClick={sureLogin} style={{ height: 40 }}>
                  确认
                </Button>
              ) : (
                <Button color="primary" variant="contained" fullWidth onClick={getPhoneCode} style={{ height: 40 }}>
                  获取验证码
                </Button>
              )}
            </div>
          </Box>
        </Paper>
      )}
      {showRoleSwitch && (
        <Paper className="interViewerInputWrap">
          <Box px={3}>
            <Box height={56} display="flex" alignItems="center">
              <Typography variant="h5" fontWeight={500}>
                身份选择
              </Typography>
            </Box>
            <Box height={314} width>
              <RoleSwitch token={authTokenTmp} onRoleSwitch={handleRoleSwitch} />
            </Box>
          </Box>
        </Paper>
      )}
    </StyledInterviews>
  );
}

InterviewerUI.propTypes = {
  positionToken: PropTypes.string.isRequired,
  toCandidateDesc: PropTypes.func.isRequired,
  loginType: PropTypes.number,
};

InterviewerUI.defaultProps = {
  loginType: 1,
};

export default withRouter(InterviewerUI);
