import { sessionStore } from '@utils/index';
import { handleActions } from 'redux-actions';
import {
  fetchPermissionRoute, setLogin, setLoginOut, setUserSelectedDepartment,
} from './action';

export const namespace = 'account';

const info = sessionStore.fetch();

export const defaultState = {
  storeTips: 'account store module',
  userInfo: info || {},
  loginState: window.localStorage.getItem('id_token') || null ? 1 : 0,
  routeList: [],
};

export const accountReducer = handleActions(
  {
    // 登录(真实情况需要经过saga)
    [setLogin]: (state, action) => {
      const { info: oldInfo } = action.payload;
      let status = 0;
      if (oldInfo && Object.keys(oldInfo).length) {
        sessionStore.save(oldInfo);
        status = 1;
      }
      return { ...state, loginState: status, userInfo: oldInfo };
    },
    // 退出：1.清除用户信息 2.登录状态 3.清除匹配的路由
    [setLoginOut]: (state) => {
      sessionStore.remove();
      return {
        ...state, loginState: 0, userInfo: null, routeList: [],
      };
    },

    [setUserSelectedDepartment]: (state, action) => {
      const selectedDepartmentTokens = action.payload;
      return { ...state, userInfo: { ...state.userInfo, selectedDepartmentTokens } };
    },

    // 获取路由列表
    [fetchPermissionRoute]: (state) => ({ ...state, routeList: [] }),
  },
  defaultState,
);
