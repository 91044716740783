import { Avatar, Chip } from '@material-ui/core';
import { RANDOM_COLOR } from '@utils/constants';
import PropTypes from 'prop-types';

export default function UserAvatarChip({ option }) {
  return option.userAvatar !== '' ? (
    <Chip style={{ marginRight: 8 }} avatar={<Avatar src={option.userAvatar} />} label={option.username} />
  ) : (
    <Chip
      style={{ marginRight: 8 }}
      avatar={(
        <Avatar
          style={{
            color: '#fff',
            backgroundColor: RANDOM_COLOR[option.username.charCodeAt(0) % RANDOM_COLOR.length],
          }}
        >
          {option.username[0]}
        </Avatar>
      )}
      label={option.username}
    />
  );
}

UserAvatarChip.propTypes = {
  option: PropTypes.objectOf(PropTypes.any).isRequired,
};
