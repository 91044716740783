import ELButton from '@components/ELButton/ELButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'rgba(0,0,0,0.56)',
  },
});

const sizes = {
  sm: '360px',
  md: '588px',
  lg: '800px',
  xl: '960px',
};

const ELConfirmDialog = ({
  classes,
  open,
  onCancel,
  onConfirm,
  title,
  desc,
  cancelTitle,
  confirmTitile,
  severity,
  showCancel,
  size,
}) => (
  <Dialog
    className={classes.root}
    PaperProps={{
      style: {
        width: sizes[size],
        minHeight: '72px',
      },
    }}
    open={open}
  >
    {title && (
      <DialogTitle>
        <Typography variant="subtitle1">{title}</Typography>
      </DialogTitle>
    )}
    <DialogContent>
      <Typography variant="body1" color="textSecondary" style={{ marginBottom: 8, marginTop: !title ? 16 : 0 }}>
        {desc}
      </Typography>
    </DialogContent>
    <DialogActions>
      {showCancel && <ELButton onClick={onCancel}> {cancelTitle}</ELButton>}
      <ELButton
        variant="contained"
        disableElevation
        color={severity === 'success' ? 'primary' : 'secondary'}
        onClick={onConfirm}
      >
        {confirmTitile}
      </ELButton>
    </DialogActions>
  </Dialog>
);

ELConfirmDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  desc: PropTypes.string,
  severity: PropTypes.oneOf(['success', 'warning']),
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelTitle: PropTypes.string,
  confirmTitile: PropTypes.string,
  showCancel: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
};

ELConfirmDialog.defaultProps = {
  title: '',
  desc: '',
  severity: 'success',
  open: true,
  onCancel: () => {},
  onConfirm: () => {},
  cancelTitle: '取消',
  confirmTitile: '确定',
  showCancel: true,
  size: 'sm',
};

export default withStyles(styles)(ELConfirmDialog);
