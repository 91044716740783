import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const unknown = '//public-static-assets.oss-cn-beijing.aliyuncs.com/img/default-avatar-unknown.png';

function GetSexOutlineIcon(sex) {
  switch (sex) {
    case 1:
      // 男
      return (
        <svg viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
            <path
              d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 1.714c5.68 0 10.286 4.605 10.286 10.286 0 5.68-4.605 10.286-10.286 10.286C6.32 22.286 1.714 17.68 1.714 12 1.714 6.32 6.32 1.714 12 1.714z"
              fill="#FFF"
              fillRule="nonzero"
            />
            <circle fill="#4B84FF" cx="12" cy="12" r="10.286" />
            <path
              d="M18.366 10.369V5.583H13.58l1.781 1.781-1.801 1.802a5.062 5.062 0 101.317 1.362l1.824-1.824 1.665 1.665zm-7.722 6.398a3.463 3.463 0 110-6.927 3.463 3.463 0 010 6.927z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      );
    case 2:
      // 女
      return (
        <svg viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
            <path
              d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 1.714c5.68 0 10.286 4.605 10.286 10.286 0 5.68-4.605 10.286-10.286 10.286C6.32 22.286 1.714 17.68 1.714 12 1.714 6.32 6.32 1.714 12 1.714z"
              fill="#FFF"
              fillRule="nonzero"
            />
            <circle fill="#FF4F7B" cx="12" cy="12" r="10.286" />
            <g fill="#FFF" fillRule="nonzero">
              <path d="M12 3.429a5.143 5.143 0 110 10.285A5.143 5.143 0 0112 3.43zm0 1.714A3.429 3.429 0 1012 12a3.429 3.429 0 000-6.857z" />
              <path d="M11.143 12.9v6.359c0 .497.384.9.857.9s.857-.403.857-.9V12.9c0-.497-.384-.9-.857-.9s-.857.403-.857.9z" />
              <path d="M15.247 15.429H8.753c-.574 0-1.039.383-1.039.857 0 .473.465.857 1.04.857h6.493c.574 0 1.039-.384 1.039-.857 0-.474-.465-.857-1.04-.857z" />
            </g>
          </g>
        </svg>
      );
    default:
      return '';
  }
}

const Icons = {
  referral: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs />
      <defs>
        <linearGradient id="b" x1="24.228%" x2="91.414%" y1="30.15%" y2="82.374%">
          <stop offset="0%" stopColor="#6A6A6A" stopOpacity=".5" />
          <stop offset="34.606%" stopColor="#D4D4D4" stopOpacity=".543" />
          <stop offset="51.823%" stopColor="#A1A1A1" stopOpacity=".552" />
          <stop offset="100%" stopColor="#D0D0D0" stopOpacity=".69" />
        </linearGradient>
        <path
          id="a"
          d="M9.49 1.315l1.154.44c.655.25 1.384.224 2.02-.07l1.119-.518a1.8 1.8 0 012.259.641l.672 1.027c.382.582.986.986 1.675 1.118l1.213.232c.877.168 1.495.951 1.447 1.834l-.065 1.222a2.539 2.539 0 00.69 1.88l.844.893c.61.646.645 1.639.082 2.325l-.778.95a2.536 2.536 0 00-.557 1.923l.151 1.214a1.772 1.772 0 01-1.314 1.929l-1.194.315a2.576 2.576 0 00-1.592 1.232l-.598 1.07a1.798 1.798 0 01-2.209.796l-1.153-.44a2.604 2.604 0 00-2.02.07l-1.119.518a1.8 1.8 0 01-2.259-.64l-.672-1.027a2.58 2.58 0 00-1.675-1.119l-1.213-.232a1.775 1.775 0 01-1.447-1.834l.065-1.221a2.539 2.539 0 00-.69-1.88l-.844-.894a1.758 1.758 0 01-.082-2.325l.778-.95c.442-.54.643-1.233.557-1.923l-.151-1.214a1.772 1.772 0 011.314-1.929l1.194-.315A2.576 2.576 0 006.684 3.18l.598-1.07a1.798 1.798 0 012.209-.796z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M13.363.26l-1.12.517c-.39.181-.84.197-1.243.043L9.847.38a2.798 2.798 0 00-3.438 1.243l-.598 1.07c-.207.371-.559.643-.975.753l-1.194.316a2.772 2.772 0 00-2.05 3.02l.151 1.213c.052.417-.07.838-.338 1.165l-.779.95a2.758 2.758 0 00.129 3.646l.843.894c.291.308.442.72.42 1.139l-.066 1.221a2.775 2.775 0 002.258 2.87l1.213.232a1.58 1.58 0 011.026.684l.672 1.027a2.8 2.8 0 003.516 1.001l1.12-.518c.39-.18.84-.196 1.243-.043l1.153.44a2.798 2.798 0 003.438-1.243l.598-1.07c.207-.37.559-.643.975-.753l1.194-.315a2.772 2.772 0 002.05-3.02l-.151-1.214c-.052-.417.07-.837.338-1.165l.779-.95a2.758 2.758 0 00-.129-3.645l-.843-.894a1.539 1.539 0 01-.42-1.14l.066-1.221a2.775 2.775 0 00-2.258-2.87l-1.213-.232a1.581 1.581 0 01-1.026-.684l-.672-1.026A2.8 2.8 0 0013.363.259zm2.679 1.548l.672 1.027c.382.582.986.986 1.675 1.118l1.213.232c.877.168 1.495.951 1.447 1.834l-.065 1.222a2.539 2.539 0 00.69 1.88l.844.893c.61.646.645 1.639.082 2.325l-.778.95a2.536 2.536 0 00-.557 1.923l.151 1.214a1.772 1.772 0 01-1.314 1.929l-1.194.315a2.576 2.576 0 00-1.592 1.232l-.598 1.07a1.798 1.798 0 01-2.209.796l-1.153-.44a2.604 2.604 0 00-2.02.07l-1.119.518a1.8 1.8 0 01-2.259-.64l-.672-1.027a2.58 2.58 0 00-1.675-1.119l-1.213-.232a1.775 1.775 0 01-1.447-1.834l.065-1.221a2.539 2.539 0 00-.69-1.88l-.844-.894a1.758 1.758 0 01-.082-2.325l.778-.95c.442-.54.643-1.233.557-1.923l-.151-1.214a1.772 1.772 0 011.314-1.929l1.194-.315A2.576 2.576 0 006.684 3.18l.598-1.07a1.798 1.798 0 012.209-.796l1.153.44c.655.25 1.384.224 2.02-.07l1.119-.518a1.8 1.8 0 012.259.641z"
        />
        <g>
          <use fill="#4BB051" xlinkHref="#a" />
          <use fill="url(#b)" style={{ mixBlendMode: 'overlay' }} xlinkHref="#a" />
        </g>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M12.877 18.503v-.68h6.398v-1.377h-2.632V14.57h2.163v-1.33h-2.163v-1.68h2.163v-1.346h-2.163v-1.74h2.465V7.099h-2.314c-.181-.756-.453-1.588-.786-2.51l-1.377.363c.303.695.575 1.421.817 2.147h-2.314c.303-.695.56-1.421.771-2.178l-1.391-.272c-.484 1.906-1.331 3.645-2.526 5.249l.484 1.512c.348-.423.68-.847.983-1.286v8.38h1.422zm-5.445-.152c1.013 0 1.528-.499 1.528-1.482v-4.114c.423-.181.862-.363 1.285-.56v-1.467c-.423.212-.847.409-1.285.59V8.671h1.406V7.28H8.96V4.618H7.523V7.28H5.708v1.391h1.815v3.177c-.696.211-1.392.393-2.103.544l.363 1.452a38.362 38.362 0 001.74-.544v3.206c0 .363-.197.545-.59.545-.378 0-.772-.03-1.18-.091l.318 1.391h1.361zm7.79-8.137h-2.345v-1.74h2.344v1.74zm0 3.025h-2.345V11.56h2.344v1.68zm0 3.207h-2.345V14.57h2.344v1.876z"
        />
      </g>
    </svg>
  ),
  referrer: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs />
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill="#97B4EC" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M6.346 14.767c1.235-.185 2.546-.54 3.904-1.05v-1.002c-.864.34-1.744.617-2.608.833.895-1.173 2.021-3.163 3.395-5.97l-.972-.356c-.34.71-.664 1.389-.972 2.021a29.57 29.57 0 01-1.99.309c.802-1.173 1.573-2.715 2.329-4.66l-1.034-.4c-.617 1.79-1.327 3.363-2.16 4.751-.123.201-.277.34-.447.402l.262 1.018A73.058 73.058 0 008.6 10.23c-.802 1.512-1.481 2.576-2.052 3.209a1.783 1.783 0 01-.494.309l.293 1.018zm4.768-1.358c1.697-.586 3.178-1.404 4.459-2.468a18.155 18.155 0 003.872 2.283l.571-1.003a18.558 18.558 0 01-3.672-2.006c.926-.94 1.713-2.036 2.376-3.301v-.91h-4.428c.17-.433.324-.895.463-1.374l-1.126-.139a9.157 9.157 0 01-2.716 4.413l.694.91c.463-.432.895-.895 1.281-1.388a15.744 15.744 0 001.836 1.82c-1.203.957-2.577 1.682-4.15 2.176l.54.987zm4.381-3.842c-.74-.601-1.419-1.25-2.02-1.959.092-.17.2-.34.308-.525h3.672a13.005 13.005 0 01-1.96 2.484zm2.207 5.817l.555-.849a23.962 23.962 0 00-4.227-1.743l-.571.849a22.764 22.764 0 014.243 1.743zm.632 3.302l.587-.926c-2.037-.926-4.29-1.697-6.758-2.345l-.617.926a40.16 40.16 0 016.788 2.345zm-12.312-.633c1.805-.34 3.425-.771 4.845-1.296v-1.126c-1.435.524-3.101.956-4.999 1.265l.154 1.157z"
        />
      </g>
    </svg>
  ),
};

const sizes = {
  small: {
    width: '24px',
    height: '24px',
  },
  medium: {
    width: '36px',
    height: '36px',
  },
  large: {
    width: '40px',
    height: '40px',
  },
};

function AvatarComponent(props) {
  const {
    src, sex, icon, size = 'medium', ...rest
  } = props;

  let iconComponent;

  switch (icon) {
    case 'gender': {
      iconComponent = (
        <div style={{
          position: 'absolute', right: 0, bottom: 0, width: '14px', height: '14px',
        }}
        >
          {GetSexOutlineIcon(sex)}
        </div>
      );
      break;
    }
    // 被推荐者
    case 'referral': {
      iconComponent = (
        <div style={{
          position: 'absolute', right: 0, bottom: 0, width: '18px', height: '18px',
        }}
        >{Icons.referral}
        </div>
      );
      break;
    }
    // 最终推荐者
    case 'referrer': {
      iconComponent = (
        <Tooltip title="伯乐" arrow>
          <div style={{
            position: 'absolute', right: 0, bottom: 0, width: '14px', height: '14px',
          }}
          >
            {Icons.referrer}
          </div>
        </Tooltip>
      );
      break;
    }
    default: {
      iconComponent = null;
    }
  }

  if (src && iconComponent) {
    return (
      <div style={{ position: 'relative' }}>
        <Avatar src={src} style={{ ...sizes[size] }} {...rest} />
        {iconComponent}
      </div>
    );
  }

  if (src && !iconComponent) {
    return <Avatar src={src} style={{ ...sizes[size] }} {...rest} />;
  }

  if (!src && iconComponent) {
    return (
      <div style={{ position: 'relative' }}>
        <Avatar src={unknown} style={{ ...sizes[size] }} {...rest} />
        {iconComponent}
      </div>
    );
  }

  return <Avatar src={unknown} style={{ ...sizes[size] }} {...rest} />;
}

AvatarComponent.propTypes = {
  src: PropTypes.string.isRequired,
  sex: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
};

AvatarComponent.defaultProps = {
  size: 'medium',
};
export default AvatarComponent;
