import { refreshApplicationAIReport } from '@api/Application';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import RefreshIcon from '@material-ui/icons/Refresh';
import useGlobalToast from '@utils/GlobalToast';
import moment from '@utils/moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useCandidateDispatch, useCandidateState } from '../context';
import styles from './CandidateResult.module.css';

function AIResultRefresh() {
  const [spin, setSpin] = useState(false);
  const { applicationData = {} } = useCandidateState();
  const { aiReportResult = {} } = applicationData;
  const dispatch = useCandidateDispatch();
  const GlobalToast = useGlobalToast();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    if (event) event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'refresh-airesult-popover' : undefined;

  async function handleRefresh(event) {
    if (event) event.stopPropagation();
    if (spin) return;
    setSpin(true);
    const result = await refreshApplicationAIReport(applicationToken);
    if (result.data.code === 0) {
      dispatch({ type: 'SET_APPLICATION_AI_REPORT', payload: result.data.data });
      GlobalToast.success('刷新成功');
    } else {
      GlobalToast.error('刷新失败');
    }
    handleClose();
    setTimeout(() => {
      setSpin(false);
    }, 2500);
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        role="button"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '6px',
          margin: '-6px',
          cursor: 'pointer',
        }}
        onClick={handleClick}
        aria-describedby={id}
      >
        <RefreshIcon style={{ fontSize: '12px' }} className={spin ? styles.spin : ''} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '16px' }}>
          <div style={{ fontSize: '16px', color: 'rgba(0,0,0,0.56)' }}>刷新后将按最新算分规则计算，是否确认刷新</div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            <Button variant="contained" color="primary" disableElevation onClick={handleRefresh}>
              刷新
            </Button>
          </div>
        </div>
      </Popover>
      <div style={{ margin: '0px 2px' }}>报告生成于</div>
      <div>{moment(parseInt(aiReportResult.reportCreateTime * 1000, 10)).format('YYYY-MM-DD HH:mm')}</div>
    </div>
  );
}

export default AIResultRefresh;
