import Typography from '@components/Avocado/Typography';
import PropTypes from 'prop-types';

function StepDatetime({ children }) {
  return (
    <Typography variant="caption" color="text.secondary">
      {children}
    </Typography>
  );
}

StepDatetime.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StepDatetime;
