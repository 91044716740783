/* eslint-disable */
export function req(conf) {
  const xhr = new XMLHttpRequest();
  let aborted = false;
  xhr.withCredentials = false;
  const result = new Promise((success, failure) => {
    xhr.open(conf.method, conf.url, true);
    xhr.withCredentials = false;
    xhr.addEventListener('load', () => {
      if (aborted) return;
      if (xhr.status < 400) {
        success(xhr.responseText);

        // TODO:这里解析了json就不用在返回函数里面再解析一次，需要修改优化代码
        const parseResult = JSON.parse(xhr.responseText);
        if (parseResult.code === -3001) {
          localStorage.removeItem('id_token');
          window.location.href = '/login';
        }
      } else {
        let text = xhr.responseText;
        if (text && /html/.test(xhr.getResponseHeader('content-type'))) text = makePlain(text);
        failure(xhr.responseText);
      }
    });
    xhr.addEventListener('error', () => {
      if (!aborted) failure(new Error('Network error'));
    });
    // xhr.onreadystatechange = function () {
    //   // if (this.readyState === this.HEADERS_RECEIVED) {
    //   // const tokenNeedRefresh = xhr.getResponseHeader('X-Refresh-Auth-Token');
    //   // if (tokenNeedRefresh) {
    //   //   localStorage.setItem('id_token', tokenNeedRefresh);
    //   // }
    //   // }
    // };

    // eslint-disable-next-line max-len,guard-for-in
    if (conf.headers) {
      for (const header in conf.headers) xhr.setRequestHeader(header, conf.headers[header]);
    }

    xhr.send(conf.body || null);
  });
  result.abort = () => {
    if (!aborted) {
      xhr.abort();
      aborted = true;
    }
  };
  return result;
}

function makePlain(html) {
  const elt = document.createElement('div');
  elt.innerHTML = html;
  return elt.textContent.replace(/\n[^]*|\s+$/g, '');
}

export function GET(url, type = 'application/json') {
  return req({
    url,
    method: 'GET',
    headers: { Accept: `${type}, text/plain, */*`, Authorization: `Basic ${localStorage.getItem('id_token')}` },
  });
}

export function POST(url, body, type = 'application/json') {
  return req({
    url,
    method: 'POST',
    body,
    headers: { 'Content-Type': type, Authorization: `Basic ${localStorage.getItem('id_token')}` },
  });
}

export function PUT(url, body, type = 'application/json') {
  return req({
    url,
    method: 'PUT',
    body,
    headers: { 'Content-Type': type, Authorization: `Basic ${localStorage.getItem('id_token')}` },
  });
}

export function PATCH(url, body, type = 'application/json') {
  return req({
    url,
    method: 'PATCH',
    body,
    headers: { 'Content-Type': type, Authorization: `Basic ${localStorage.getItem('id_token')}` },
  });
}

export function DELETE(url, body, type = 'application/json') {
  return req({
    url,
    method: 'DELETE',
    body,
    headers: { 'Content-Type': type, Authorization: `Basic ${localStorage.getItem('id_token')}` },
  });
}
