import Typography from '@components/Avocado/Typography';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function EvaluationScores({ totalScore, fullScore }) {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Typography variant="body2" color="text.secondary">
        总得分: {totalScore}分
      </Typography>
      <Typography variant="body2" color="text.secondary" ml={1}>
        满分: {fullScore}分
      </Typography>
    </Box>
  );
}

EvaluationScores.propTypes = {
  totalScore: PropTypes.number.isRequired,
  fullScore: PropTypes.number.isRequired,
};
