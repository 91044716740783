import { patchCandidate } from '@api/Candidate';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import useGlobalToast from '@utils/GlobalToast';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useCandidateState } from '../context';
import useDataLoader from '../useDataLoader';
import styles from './CandidateResume.module.css';
import UploadResume from './UploadResume';

function ResumeTools({ hidenResumeReplace = false }) {
  const [showDeleteResumeBtn, setShowDeleteResumeBtn] = useState(false);
  const { candidateData = {} } = useCandidateState();
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const { loadCandidate } = useDataLoader();
  const GlobalToast = useGlobalToast();

  function handleDelete() {
    const data = {
      field: 'resume',
      value: '',
    };
    patchCandidate(candidateToken, data)
      .then((result) => {
        if (result.data.code === 0) {
          GlobalToast.success('简历已删除');
          loadCandidate(candidateToken);
        } else {
          GlobalToast.error('简历删除失败');
        }
      })
      .catch(() => {
        GlobalToast.error('简历删除失败');
      });
  }

  return (
    <div className={styles.wrapper}>
      {!hidenResumeReplace && (
        <Tooltip title="下载简历">
          <Paper className={styles.button}>
            <Link
              style={{ color: 'rgba(0, 0, 0, 0.86)' }}
              href={`${candidateData?.resumeInfo?.content}?response-content-type=application%2Foctet-stream`}
            >
              <CloudDownloadIcon />
            </Link>
          </Paper>
        </Tooltip>
      )}
      {!hidenResumeReplace && (
        <Tooltip title="替换新简历">
          <Paper className={styles.button}>
            <UploadResume button={<InsertDriveFileIcon />} />
          </Paper>
        </Tooltip>
      )}
      {showDeleteResumeBtn ? (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={showDeleteResumeBtn}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">删除简历</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">确认删除该简历吗？该操作无法被撤销。</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteResumeBtn(false)}>取消</Button>
            <Button variant="contained" color="secondary" onClick={handleDelete} autoFocus>
              删除
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        !hidenResumeReplace && (
          <Tooltip title="删除简历">
            <Paper className={styles.button} onClick={() => setShowDeleteResumeBtn(true)}>
              <DeleteIcon />
            </Paper>
          </Tooltip>
        )
      )}
    </div>
  );
}

ResumeTools.propTypes = {
  hidenResumeReplace: PropTypes.bool,
};

ResumeTools.defaultProps = {
  hidenResumeReplace: false,
};

export default ResumeTools;
