import { applicationWechatQRcode } from '@api/ApplicationShare';
import TipTitle from '@components/Avocado/TipTitle';
import { Box, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import PaperHeader from './PaperHeader';

const ShareToWeChat = ({ token, onBackClick }) => {
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    applicationWechatQRcode(token)
      .then((v) => {
        if (v.data.code === 0) {
          setQrCode(v.data.data.qrCode);
        }
      })
      .catch(console.log);
  }, []);

  return (
    <Paper style={{ width: 410, padding: '0px 16px 0px 0px' }}>
      <PaperHeader title="分享到微信" onBackClick={onBackClick} />
      <Box display="flex" flexDirection="column" alignItems="center" height={168} pt={1} pb={3.75} mr={2} ml={3}>
        <img src={qrCode} width={100} height={100} alt="" style={{ objectFit: 'cover', marginBottom: 8 }} />
        <TipTitle>扫码查看候选人</TipTitle>
      </Box>
    </Paper>
  );
};

ShareToWeChat.propTypes = {
  token: PropTypes.string.isRequired,
  onBackClick: PropTypes.func,
};

ShareToWeChat.defaultProps = {
  onBackClick: null,
};

export default ShareToWeChat;
