import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  searchBoxCommon: {
    position: 'relative',
    display: 'flex',
    paddingTop: '1px',
    width: '280px',
    minHeight: '40px',
    backgroundColor: '#F5F5F5',
    border: '1px solid transparent',
    borderRadius: '8px',
    transition: 'width 150ms, box-shadow 150ms',
  },
  searchBoxUnfocused: {
    height: '40px',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #F5F5F5',
    },
  },
  searchBoxFocused: {
    backgroundColor: 'white',
    border: '1px solid transparent',
    // boxShadow: '0 1px 1px 0 rgba(65,69,73,0.3), 0 1px 3px 1px rgba(65,69,73,0.15)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
  },
  searchBoxExpanded: {
    width: '960px',
  },
  searchBoxChipsCommon: {
    flex: 1,
    display: 'flex',
    marginTop: '4px',
    marginBottom: '4.5px',
    maxHeight: '100px',
    overflowY: 'auto',
  },
  searchBoxChipsFocused: {
    flexWrap: 'wrap',
  },
  searchBoxChipsUnfocused: {
    overflow: 'hidden',
  },
  searchBoxInput: {
    flex: 1,
    flexShrink: 0,
    margin: '4px 0px',
    padding: '0px',
    minWidth: '215px',
    fontSize: '14px',
  },
  searchList: {
    position: 'absolute',
    left: 0,
    bottom: -268,
    padding: '4px 0px',
    width: '100%',
    height: 266,
    boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.16)',
    zIndex: 999,
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
