import Avatar from '@components/Avocado/Avatar';
import UserTitleChip from '@components/UserTitleChip/UserTitleChip';
import { Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import styles from './UserPopover.module.css';

class UserPopover extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      user, open, anchorEl, onClose, onLogout, onUserDetail,
    } = this.props;
    return (
      <Popover
        elevation={4}
        id="avater"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          width="320px"
          style={{
            borderRadius: 4,
          }}
        >
          <Box p={2} display="flex" width="100%" onClick={onUserDetail} className={styles.link}>
            <Avatar size="large" user={user} />
            <Box ml={2} flexGrow={1}>
              <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
                <Typography variant="body1" color="textPrimary">
                  {user.name}
                </Typography>
                <UserTitleChip role={user.role} />
              </Box>
              <Box>
                <Typography variant="caption" color="textSecondary">
                  {user.account || user.mobile}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            p={2}
            height="54px"
            onClick={onLogout}
            className={styles.link}
          >
            <Typography variant="body2" color="textPrimary">退出登录</Typography>
          </Box>
        </Box>
      </Popover>
    );
  }
}

UserPopover.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.bool,
  anchorEl: PropTypes.node,
  onClose: PropTypes.func,
  onUserDetail: PropTypes.func,
  onLogout: PropTypes.func,
};

UserPopover.defaultProps = {
  open: true,
  anchorEl: null,
  onUserDetail: () => {},
  onClose: () => {},
  onLogout: () => {},
};

export default UserPopover;
