import CircularSpin from '@components/CircularSpin';
import { Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '400px',
    zIndex: 4,
  },
  spinBlur: {
    userSelect: 'none',
    pointerEvents: 'none',
    opacity: 0.5,
  },
  tip: {
    color: 'rgba(76, 175, 80, 1)',
    fontWeight: 500,
  },
}));

const Spin = ({
  tip, children, spinning, width, height, borderWidth,
}) => {
  const classes = useStyles();

  if (children) {
    return (
      <Box className={classes.wrapper}>
        {spinning && (
          <Box className={classes.content}>
            <Box mb={0.5}>
              <CircularSpin width={width} height={height} borderWidth={borderWidth} />
            </Box>
            {tip && (
              <Typography variant="body1" className={classes.tip}>
                {tip}
              </Typography>
            )}
          </Box>
        )}
        <Box className={clsx([spinning && classes.spinBlur])}>{children}</Box>
      </Box>
    );
  }

  return <CircularSpin width={width} height={height} borderWidth={borderWidth} />;
};

Spin.defaultProps = {
  tip: '',
  children: null,
  spinning: false,
  width: undefined,
  height: undefined,
  borderWidth: undefined,
};

Spin.propTypes = {
  tip: PropTypes.string,
  children: PropTypes.node,
  spinning: PropTypes.bool,
  width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  borderWidth: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

export default Spin;
