import { createActions } from 'redux-actions';

export const {
  fetchTemplateVariableList,
  setTemplateVariableList,
  fetchTemplateList,
  setTemplateList,
  setTemplateListSelectedIndex,
  setTemplateNeedSave,
  fetchTemplateInfo,
  setTemplateInfo,
  setTemplateEmailInfo,
  clearTemplateInfo,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  fetchProcessesList,
  fetchProcessesDetail,
  setProcessesList,
  setProcessesDetail,
  postSendSelfTestSms,
} = createActions(
  {},
  'FETCH_TEMPLATE_VARIABLE_LIST',
  'SET_TEMPLATE_VARIABLE_LIST',
  'FETCH_TEMPLATE_LIST',
  'SET_TEMPLATE_LIST',
  'SET_TEMPLATE_LIST_SELECTED_INDEX',
  'SET_TEMPLATE_NEED_SAVE',
  'FETCH_TEMPLATE_INFO',
  'SET_TEMPLATE_INFO',
  'SET_TEMPLATE_EMAIL_INFO',
  'CLEAR_TEMPLATE_INFO',
  'CREATE_TEMPLATE',
  'UPDATE_TEMPLATE',
  'DELETE_TEMPLATE',
  'FETCH_PROCESSES_LIST',
  'FETCH_PROCESSES_DETAIL',
  'SET_PROCESSES_LIST',
  'SET_PROCESSES_DETAIL',
  'POST_SEND_SELF_TEST_SMS',
);

export default {};
