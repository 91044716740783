import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const KickoutDialog = ({ open, onConfrim }) => (
  <Dialog
    open={open}
    fullWidth
    maxWidth="xs"
  >
    <DialogTitle>下线通知</DialogTitle>
    <DialogContent>
      您的账号在另一地点登录，您被迫下线。如果这不是您本人的操作，那么您的密码可能已泄漏，建议您立即联系管理员协助修改密码。
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" onClick={onConfrim}>好的</Button>
    </DialogActions>
  </Dialog>
);
KickoutDialog.propTypes = {
  open: PropTypes.bool,
  onConfrim: PropTypes.func,
};

KickoutDialog.defaultProps = {
  open: true,
  onConfrim: null,
};

export default KickoutDialog;
