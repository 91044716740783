import { useMemo } from 'react';
import { insertFeedbackToFormItems } from '../utils';

export default function useMemoFormItems(formItems, hasFeedback) {
  return useMemo(() => {
    if (hasFeedback) {
      return insertFeedbackToFormItems([...formItems]);
    }
    return formItems;
  }, [formItems, hasFeedback]);
}
