import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
export const DateFomate = ['YYYY-MM-DD HH:mm', 'YYYY年MM月DD日 HH:mm', 'MM-DD HH:mm', 'MM月DD日 HH:mm', 'HH:mm'];

export function fromNow(timestamp) {
  const currentTimeStamp = moment().unix();
  const timeDiff = currentTimeStamp - timestamp;

  if (timeDiff < 60 * 1) {
    return '刚刚';
  }
  if (timeDiff < 60 * 60) {
    return moment.unix(timestamp).fromNow();
  }
  if (timeDiff < 60 * 60 * 24) {
    return moment.unix(timestamp).fromNow();
  }
  if (timeDiff < 60 * 60 * 24 * 30) {
    return moment.unix(timestamp).fromNow();
  }
  if (timeDiff > 60 * 60 * 24 * 365) {
    return moment.unix(timestamp).format(DateFomate[0]);
  }
  return moment.unix(timestamp).format(DateFomate[2]);
}

export function inboxTimeFormat(timestamp) {
  const momentObject = moment.unix(timestamp);
  if (!momentObject.isBefore(moment(), 'day')) {
    return moment.unix(timestamp).format('H:mm');
  }
  return moment.unix(timestamp).format(DateFomate[1]);
}

export default moment;
