import { KeyboardArrowLeftIcon } from '@avocadoui/icons';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import styles from './CandidateRecord.module.css';
import { useCandidateDispatch } from './context';
import OperatingRecordCandidate from './Tabs/OperatingRecordCandidate';

const CandidateRecord = ({ hasScrolled }) => {
  const dispatch = useCandidateDispatch();

  const handleBack = useCallback(() => {
    dispatch({ type: 'CANDIDATE_RESUME_RECORD', payload: false });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.header, { [styles.sticky]: hasScrolled })}>
        <Box display="flex" alignItems="center" py="6px" pl={1}>
          <IconButton onClick={handleBack}>
            <KeyboardArrowLeftIcon className="MuiSvgIcon-root" />
          </IconButton>
          <Typography variant="body2">操作记录</Typography>
        </Box>
      </div>
      <div className={styles.content}>
        <OperatingRecordCandidate />
      </div>
    </div>
  );
};

CandidateRecord.propTypes = {
  hasScrolled: PropTypes.bool.isRequired,
};

export default memo(CandidateRecord);
