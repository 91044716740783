/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import Autolinker from 'autolinker';
import styles from './index.module.css';

export default function AutoLink({ text }) {
  return <span className={styles.autolink} dangerouslySetInnerHTML={{ __html: Autolinker.link(text) }} />;
}

AutoLink.propTypes = {
  text: PropTypes.string.isRequired,
};
