import PropTypes from 'prop-types';
import { useReducer } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import defaultState from './defaultState';
import reducer from './reducer';

const StateContext = createContext();
const DispatchContext = createContext();

function CandidateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

CandidateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultSelector = (state) => state;

function useCandidateState(selector = defaultSelector) {
  const context = useContextSelector(StateContext, selector);
  if (context === undefined) {
    throw new Error('useCandidateState must be used within a CandidateProvider');
  }
  return context;
}

function useCandidateDispatch(selector = defaultSelector) {
  const context = useContextSelector(DispatchContext, selector);
  if (context === undefined) {
    throw new Error('useCandidateDispatch must be used within a CandidateProvider');
  }
  return context;
}

export { CandidateProvider, useCandidateState, useCandidateDispatch };
