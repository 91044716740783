import Typography from '@components/Avocado/Typography';
import {
  Box,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  FormGroup,
  makeStyles,
  Radio as MuiRadio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { RATING_TEXT } from '../const';
import { useEvaluationState } from './context';

const FormControlLabel = withStyles({
  label: {
    '&.Mui-disabled': {
      color: 'unset',
    },
  },
})(MuiFormControlLabel);

const Radio = withStyles((theme) => ({
  colorPrimary: {
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
    '&.Mui-checked.Mui-disabled': {
      color: theme.palette.primary.main,
    },
  },
}))(MuiRadio);

const Checkbox = withStyles((theme) => ({
  colorPrimary: {
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
    '&.Mui-checked.Mui-disabled': {
      color: theme.palette.primary.main,
    },
  },
}))(MuiCheckbox);

const useStyle = makeStyles(() => ({
  itemWrap: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '16px 16px 16px 36px',
    marginTop: '16px',
  },
  feedbackCommon: {
    position: 'relative',
    display: 'inline-block',
    padding: '4px 15px',
    fontWeight: 500,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: '4px',
  },
  feedbackSelectFail: {
    backgroundColor: '#FFEBEE',
    color: '#611A15',
  },
  feedbackSelectTodo: {
    backgroundColor: '#E3F2FD',
    color: '#0D3C61',
  },
  feedbackSelectSuccess: {
    backgroundColor: '#EDF7ED',
    color: '#1E4620',
  },
}));

function QuestionInput({ item }) {
  if (item.formAnswer?.answer) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        style={{
          whiteSpace: 'pre-line', width: '100%', wordBreak: 'break-word',
        }}
      >
        {item.formAnswer?.answer}
      </Typography>
    );
  }
  return <Box width="200px" height="18px" style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.12)' }} />;
}

QuestionInput.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function RadioSelectInput({ item }) {
  const { formItemOption, formAnswer } = item;
  const { alternatives = [] } = formItemOption || {};
  const { choiceOptions = [] } = formAnswer || {};

  return (
    <RadioGroup value={choiceOptions[0] || null}>
      {alternatives.map((v) => (
        <FormControlLabel
          key={v.key}
          value={v.key}
          control={<Radio disabled color="primary" />}
          label={v.value}
          style={{
            whiteSpace: 'pre-line', width: '100%', wordBreak: 'break-word',
          }}
        />
      ))}
    </RadioGroup>
  );
}

RadioSelectInput.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function CheckboxSelectInput({ item }) {
  const { formItemOption, formAnswer } = item;
  const { alternatives = [] } = formItemOption || {};
  const { choiceOptions = [] } = formAnswer || {};
  return (
    <FormGroup>
      {alternatives.map((v) => (
        <FormControlLabel
          key={v.key}
          control={<Checkbox name={v.key} disabled checked={choiceOptions.indexOf(v.key) > -1} />}
          label={v.value}
          style={{
            whiteSpace: 'pre-line', width: '100%', wordBreak: 'break-word',
          }}
        />
      ))}
    </FormGroup>
  );
}

CheckboxSelectInput.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};
function InputSwitcher({ item }) {
  const { formTypeId } = item;

  if (formTypeId === 2) {
    return <QuestionInput item={item} />;
  }

  if (formTypeId === 3) {
    return <RadioSelectInput item={item} />;
  }

  if (formTypeId === 6) {
    return <CheckboxSelectInput item={item} />;
  }
  if (formTypeId === 999) {
    return <FeedbackInput />;
  }

  return null;
}

InputSwitcher.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default function EvaluationFormViewer({ item, index }) {
  return (
    <Box mt={2} px={2} py={1.5} border="1px solid rgba(0, 0, 0, 0.08)" borderRadius="4px">
      <Box>
        <Box>
          <Typography variant="body2" color="text.primary">
            <Box display="flex">
              {
                item.isNecessary && (
                  <Box ml={-1}>
                    {item.isNecessary && <span style={{ color: '#FF6C6C' }}>*</span>}
                  </Box>
                )
              }
              <Typography variant="body2" color="text.primary" style={{ wordBreak: 'break-word' }}>
                {index + 1}. {item.formTypeKey}
              </Typography>

            </Box>
          </Typography>
        </Box>

        {item.isScoreOpen === 1 && (
          <Box style={{ width: 65 }} mt={0.5}>
            <Typography variant="body2" color="text.secondary">
              {item.score}/{item.total}分
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt={1}>
        <InputSwitcher item={item} />
      </Box>
    </Box>
  );
}

function FeedbackInput() {
  const classes = useStyle();
  const state = useEvaluationState();
  const { selectedEvaluationDetail } = state;
  const { feedback } = selectedEvaluationDetail;
  const { level, stars } = feedback;

  return (
    <>
      <Box mt={2} display="flex" alignItems="center">
        <Typography variant="body2" color="text.secondary">
          面试结果
        </Typography>
        <Box display="flex" alignItems="center" ml={1}>
          <Box className={clsx(classes.feedbackCommon, level === 1 && classes.feedbackSelectFail)}>
            <Typography variant="body2" color="inherit">
              不通过
            </Typography>
          </Box>
          {/* <Box ml={1} className={clsx(classes.feedbackCommon, level === 2 && classes.feedbackSelectTodo)}>
            <Typography variant="body2" color="inherit">
              待定
            </Typography>
          </Box> */}
          <Box ml={1} className={clsx(classes.feedbackCommon, level === 3 && classes.feedbackSelectSuccess)}>
            <Typography variant="body2" color="inherit">
              通过
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <Box mr={1}>
          <Typography variant="body2" color="text.secondary">
            能力评价
          </Typography>
        </Box>
        <Rating size="large" value={stars} readOnly />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2" color="text.primary">
          {RATING_TEXT[stars]}
        </Typography>
      </Box>
    </>
  );
}

EvaluationFormViewer.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};
