/* eslint-disable no-param-reassign */
import { CloseIcon } from '@avocadoui/icons';
import Typography from '@components/Avocado/Typography';
import { Box, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';

const playbackRates = [0.5, 0.75, 1, 1.25, 1.5, 2, 4];

const VideoPlayback = ({
  videoUrl, videoPlaybackRef, selectedVideoId, setSelectedVideoId,
}) => {
  const [isSticky, setIsSticky] = useState(true);
  const [currentRate, setCurrentRate] = useState(2);
  const nextRate = useMemo(() => (currentRate === playbackRates.length - 1 ? 0 : currentRate + 1), [currentRate]);
  const videoHasRendered = useRef(false);
  const url = videoUrl && videoUrl[selectedVideoId];
  const videoUrlLength = videoUrl.length;

  function handleCancelSticky() {
    setIsSticky(false);
  }

  function handleChangePlaybackRate() {
    if (videoPlaybackRef.current) {
      videoPlaybackRef.current.playbackRate = playbackRates[nextRate];
      videoPlaybackRef.current.play();
      setCurrentRate(nextRate);
    }
  }

  useEffect(() => {
    function next() {
      setSelectedVideoId((id) => {
        if (id < videoUrlLength - 1) {
          return id + 1;
        }
        return id;
      });
    }

    videoPlaybackRef.current?.addEventListener('ended', next);

    return () => {
      videoPlaybackRef.current?.removeEventListener('ended', next);
    };
  }, [videoUrlLength]);

  useEffect(() => {
    if (videoPlaybackRef.current) {
      videoPlaybackRef.current.playbackRate = playbackRates[currentRate];
      if (videoHasRendered.current) {
        videoPlaybackRef.current.play();
      } else {
        videoHasRendered.current = true;
      }
    }
  }, [selectedVideoId]);

  if (!url) {
    return null;
  }

  return (
    <Box
      m={2}
      mt={0}
      borderTop="1px dashed rgba(0,0,0,0.08)"
      style={isSticky ? { position: 'sticky', top: 49, zIndex: 1 } : { position: 'relative' }}
    >
      <Box py={2}>
        <Typography variant="body2" color="text.secondary">
          视频回放
        </Typography>
      </Box>
      <video ref={videoPlaybackRef} controls src={url} style={{ width: '100%' }} poster="//webcdn.fbmms.cn/assets/images/video-poster.png" />
      <div
        role="button"
        style={{
          position: 'absolute',
          bottom: 80,
          right: 20,
          color: 'white',
          cursor: 'pointer',
        }}
        onClick={handleChangePlaybackRate}
      >
        {playbackRates[currentRate]}倍速
      </div>
      {isSticky && (
        <IconButton
          style={{
            position: 'absolute',
            top: 60,
            right: 10,
            color: 'white',
          }}
          onClick={handleCancelSticky}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};

VideoPlayback.propTypes = {
  videoUrl: PropTypes.arrayOf(PropTypes.string).isRequired,
  videoPlaybackRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  selectedVideoId: PropTypes.number.isRequired,
  setSelectedVideoId: PropTypes.func.isRequired,
};

export default VideoPlayback;
