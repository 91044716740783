import { DateIcon, OverflowIcon } from '@avocadoui/icons';
import {
  Button, IconButton, TextField, withStyles,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { CANCELED_PROCESS_APPLICATION_STATE } from '@module/CandidateModal/const';
import { useCandidateState } from '@module/CandidateModal/context';
import useGlobalToast from '@utils/GlobalToast';
import moment from '@utils/moment';
import isEmpty from 'lodash/isEmpty';
import PopupState, { bindMenu, bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import ExamPreviewDialog from './ExamPreviewDialog';
import LinkHoverPopover from './LinkHoverPopover';
import QnAList from './WrittenTabCardQnAList';

const ExamStatus = ['', '已安排', '待评分', '已完成', '已放弃', '已失效'];
const ExamStatusColors = ['', '#000000DB', '#F5C441FF', '#4CAF50FF', '#FF6C6CFF', '#00000042'];
const ExamStatusBorderColors = ['', '#0000001A', '#F5C441FF', '#4CAF50FF', '#FF6C6CFF', '#00000042'];

const disabledStatus = (status) => status === 2;

const StatusChip = withStyles(() => ({
  root: {
    height: 24,
    color: 'inherit !important',
    borderColor: 'inherit !important',
  },
}))(Chip);

const StateTypography = (props) => {
  const { style, disabled } = props;
  const color = disabled ? { color: ExamStatusColors[5] } : {};
  return <Typography {...props} style={{ ...style, ...color }} />;
};
StateTypography.propTypes = {
  disabled: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
};

StateTypography.defaultProps = {
  disabled: false,
  style: {},
};

const WrittenTab = ({
  item, onDateChange, onDelete, onInviterChange, onNeedReload,
}) => {
  const linkRef = useRef();
  const GlobalToast = useGlobalToast();
  const [deletePopoverAnChorEl, setDeletePopoverAnChorEl] = useState(false);
  const [tempName, setTempName] = useState('');
  const [showIframeDialog, setShowIframeDialog] = useState(false);
  const qnaListRef = useRef();
  const [videoWrapperRef, inView] = useInView({ rootMargin: '-150px 0px 0px 0px' });
  // const isCanceled = useCandidateState((state) => state.applicationData.applicationState === 2);
  const isCanceled = useCandidateState((state) => CANCELED_PROCESS_APPLICATION_STATE
    .indexOf(state.applicationData.applicationState) > -1);

  useEffect(() => {
    setTempName(item.interviewerName);
  }, [item]);

  const copyLink = () => {
    const linkElement = linkRef.current;
    if (linkElement) {
      linkElement.select();
      document.execCommand('Copy');
      GlobalToast.success('复制成功');
    }
  };

  const timeCost = useMemo(() => {
    const tempTime = moment.duration(item.timeCost * 1000);
    const timeFormat = [];
    if (tempTime.hours()) {
      timeFormat.push(`${tempTime.hours()}小时`);
    }
    if (tempTime.minutes()) {
      timeFormat.push(`${tempTime.minutes()}分钟`);
    }
    if (tempTime.seconds()) {
      timeFormat.push(`${tempTime.seconds()}秒`);
    }
    return timeFormat.join('');
  }, [item.timeCost]);

  const handlePauseVideo = () => {
    // 暂停视频播放
    if (qnaListRef.current && qnaListRef.current.handlePauseVideo) {
      qnaListRef.current.handlePauseVideo();
    }
  };

  useEffect(() => {
    if (!inView) {
      handlePauseVideo();
    }
  }, [inView]);

  return (
    <Box display="flex" width="100%" px={2}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          marginRight: 8,
          backgroundColor: '#4CAF53FF',
          borderRadius: 12,
          width: 24,
          height: 24,
          flexShrink: 0,
        }}
      >
        <DateIcon
          className="MuiSvgIcon-root"
          style={{
            color: 'white',
            fontSize: 16,
            height: 16,
            width: 16,
          }}
        />
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        pb={2}
        mb={2}
        style={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 4,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} height="68px">
          <Box display="flex" alignItems="center">
            <StateTypography variant="body2" style={{ fontWeight: 500 }} disabled={disabledStatus(item.effectStatus)}>
              {moment(parseInt(item.planTime * 1000, 10)).format('YYYY年MM月DD日 HH:mm')}
            </StateTypography>
            {!disabledStatus(item.effectStatus) && (
              <IconButton
                onClick={() => {
                  onDateChange(item.token);
                }}
              >
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="MuiSvgIcon-root">
                  <g fill="rgba(0,0,0,0.56)" fillRule="evenodd">
                    <g transform="rotate(45 2.879 23.778)">
                      <path d="M.2 3h1.6c.11 0 .2.09.2.2V17l-.985 1L0 17V3.2c0-.11.09-.2.2-.2z" />
                      <rect width="2" height="2" rx=".2" />
                    </g>
                    <path d="M10.973 16.35h7.8c.11 0 .2.089.2.2v1.6a.2.2 0 01-.2.2H9l1.973-2z" />
                  </g>
                </svg>
              </IconButton>
            )}
          </Box>
          <Box
            color={ExamStatusColors[disabledStatus(item.effectStatus) ? 5 : item.status]}
            borderColor={ExamStatusBorderColors[disabledStatus(item.effectStatus) ? 5 : item.status]}
          >
            <StatusChip
              label={ExamStatus[disabledStatus(item.effectStatus) ? 5 : item.status]}
              color="inherit"
              variant="outlined"
              size="medium"
            />
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <IconButton {...bindTrigger(popupState)} style={{ marginLeft: 8 }}>
                    <OverflowIcon htmlColor="rgba(0,0,0,0.56)" />
                  </IconButton>
                  <Menu
                    {...bindMenu(popupState)}
                    variant="menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <MenuItem
                      onClick={() => {
                        setDeletePopoverAnChorEl(popupState.anchorEl);
                        handlePauseVideo();
                        popupState.close();
                      }}
                      disabled={isCanceled}
                    >
                      删除笔试安排
                    </MenuItem>
                  </Menu>
                </>
              )}
            </PopupState>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" px={2}>
          <Box display="flex" mt={1.5} alignItems="center">
            <StateTypography
              variant="body2"
              color="textSecondary"
              disabled={disabledStatus(item.effectStatus)}
              style={{
                marginRight: 16,
                width: 84,
                overflow: 'hidden',
                flexShrink: 0,
              }}
            >
              审核人
            </StateTypography>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <StateTypography
                    // {...bindTrigger(popupState)}
                    variant="body2"
                    disabled={disabledStatus(item.effectStatus)}
                    // style={{ color: '#576B95', cursor: 'pointer' }}
                  >
                    {item.interviewerName}
                  </StateTypography>
                  <Popover
                    {...bindPopover(popupState)}
                    variant="menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <Box display="flex" width={385} height={58} px={2} pt={1.25} flexDirection="column">
                      <Typography
                        variant="caption"
                        style={{
                          color: 'rgba(0, 0, 0, 0.26)',
                        }}
                      >
                        将链接发送给面试官：
                      </Typography>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography
                          variant="caption"
                          style={{
                            color: '#576B95FF',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            height: 24,
                            width: 299,
                          }}
                        >
                          {item.consoleLink}
                        </Typography>
                        <input value={item.consoleLink} style={{ position: 'absolute', top: -10000 }} ref={linkRef} />
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            copyLink();
                          }}
                        >
                          复制
                        </Button>
                      </Box>
                    </Box>
                  </Popover>
                </>
              )}
            </PopupState>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  {!disabledStatus(item.effectStatus) && (
                    <IconButton {...bindTrigger(popupState)} size="small">
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="rgba(0,0,0,0.56)" fillRule="evenodd">
                          <g transform="rotate(45 2.879 23.778)">
                            <path d="M.2 3h1.6c.11 0 .2.09.2.2V17l-.985 1L0 17V3.2c0-.11.09-.2.2-.2z" />
                            <rect width="2" height="2" rx=".2" />
                          </g>
                          <path d="M10.973 16.35h7.8c.11 0 .2.089.2.2v1.6a.2.2 0 01-.2.2H9l1.973-2z" />
                        </g>
                      </svg>
                    </IconButton>
                  )}

                  <Popover
                    {...bindPopover(popupState)}
                    variant="menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <Box display="flex" width={378} height={58} px={2} alignItems="center">
                      <TextField
                        value={tempName}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          inputProps: {
                            maxLength: 15,
                          },
                        }}
                        style={{ marginRight: 36, flexShrink: 1, width: 180 }}
                        onChange={(e) => {
                          const val = e.target.value.trim();
                          setTempName(val);
                        }}
                      />
                      <Button
                        size="medium"
                        style={{ marginRight: 8, flexShrink: 0 }}
                        onClick={() => {
                          setTempName(item?.interviewerName);
                          popupState.close();
                        }}
                      >
                        取消
                      </Button>
                      <Button
                        style={{ flexShrink: 0 }}
                        size="medium"
                        variant="contained"
                        color="primary"
                        disabled={tempName.length === 0}
                        onClick={() => {
                          onInviterChange(tempName);
                          setTempName(item?.interviewerName);
                          popupState.close();
                        }}
                      >
                        保存
                      </Button>
                    </Box>
                  </Popover>
                </>
              )}
            </PopupState>
          </Box>
          <Box display="flex" mt={1.5} alignItems="center">
            <StateTypography
              variant="body2"
              color="textSecondary"
              disabled={disabledStatus(item.effectStatus)}
              style={{
                marginRight: 16,
                width: 84,
                overflow: 'hidden',
                flexShrink: 0,
              }}
            >
              候选人
            </StateTypography>
            <LinkHoverPopover
              item={item}
              disabled={disabledStatus(item.effectStatus)}
              onClick={() => {
                if (!disabledStatus(item.effectStatus)) {
                  setShowIframeDialog(true);
                }
              }}
            />
            {/* <PopupState variant="popover" popupId="demo-popup-menu-c">
              {(popupState) => (
                <>
                  <StateTypography
                    {...(disabledStatus(item.effectStatus) ? {} : bindHover(popupState))}
                    variant="body2"
                    style={{ color: '#576B95', cursor: 'pointer' }}
                    disabled={disabledStatus(item.effectStatus)}
                    onClick={() => {
                      if (!disabledStatus(item.effectStatus)) {
                        setShowIframeDialog(true);
                      }
                    }}
                  >
                    {item?.candidateInfo?.name}
                  </StateTypography>
                  <Popover
                    {...bindPopover(popupState)}
                    variant="menu"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    disableRestoreFocus
                  >
                    <Box
                      display="flex"
                      width={385}
                      height={58}
                      px={2}
                      pt={1.25}
                      flexDirection="column"
                      onMouseLeave={popupState.close}
                    >
                      <Typography
                        variant="caption"
                        style={{
                          color: 'rgba(0, 0, 0, 0.26)',
                        }}
                      >
                        将链接发送给候选人：
                      </Typography>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography
                          variant="caption"
                          style={{
                            color: '#576B95FF',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            height: 24,
                            width: 299,
                          }}
                        >
                          {item.candidateLink}
                        </Typography>
                        <input value={item.candidateLink} style={{ position: 'absolute', top: -10000 }} ref={linkRef} />
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            copyLink();
                            popupState.close();
                          }}
                        >
                          复制
                        </Button>
                      </Box>
                    </Box>
                  </Popover>
                </>
              )}
            </PopupState> */}
          </Box>
          <Box display="flex" mt={2} alignItems="center">
            <StateTypography
              variant="body2"
              color="textSecondary"
              disabled={disabledStatus(item.effectStatus)}
              style={{
                marginRight: 16,
                width: 84,
                overflow: 'hidden',
                flexShrink: 0,
              }}
            >
              考卷名
            </StateTypography>
            <StateTypography variant="body2" disabled={disabledStatus(item.effectStatus)}>
              {item?.paper?.name}
            </StateTypography>
          </Box>
          {item.status >= 2 && item.status !== 4 && (
            <Box display="flex" mt={2} alignItems="center">
              <StateTypography
                variant="body2"
                color="textSecondary"
                disabled={disabledStatus(item.effectStatus)}
                style={{
                  marginRight: 16,
                  width: 84,
                  overflow: 'hidden',
                  flexShrink: 0,
                }}
              >
                笔试完成时间
              </StateTypography>
              <StateTypography variant="body2" disabled={disabledStatus(item.effectStatus)}>
                {moment(parseInt(item.completeTime * 1000, 10)).format('YYYY年MM月DD日 HH:mm')}
              </StateTypography>
            </Box>
          )}
          {item.status >= 2 && item.status !== 4 && (
            <Box display="flex" mt={2} alignItems="center">
              <StateTypography
                variant="body2"
                color="textSecondary"
                disabled={disabledStatus(item.effectStatus)}
                style={{
                  marginRight: 16,
                  width: 84,
                  overflow: 'hidden',
                  flexShrink: 0,
                }}
              >
                笔试得分
              </StateTypography>
              <StateTypography variant="body2" disabled={item.score === -1 || disabledStatus(item.effectStatus)}>
                {item.score === -1 ? '暂无得分' : `${item.score}分`}
              </StateTypography>
            </Box>
          )}
          {item.status >= 2 && item.status !== 4 && (
            <Box display="flex" mt={2} alignItems="center">
              <StateTypography
                variant="body2"
                color="textSecondary"
                disabled={disabledStatus(item.effectStatus)}
                style={{
                  marginRight: 16,
                  width: 84,
                  overflow: 'hidden',
                  flexShrink: 0,
                }}
              >
                切屏次数
              </StateTypography>
              <StateTypography variant="body2" disabled={disabledStatus(item.effectStatus)}>
                {item?.switchScreenCount}次
              </StateTypography>
            </Box>
          )}
          {item.status >= 2 && item.status !== 4 && (
            <Box display="flex" mt={2} alignItems="center">
              <StateTypography
                variant="body2"
                color="textSecondary"
                disabled={disabledStatus(item.effectStatus)}
                style={{
                  marginRight: 16,
                  width: 84,
                  overflow: 'hidden',
                  flexShrink: 0,
                }}
              >
                笔试用时
              </StateTypography>
              <StateTypography variant="body2" disabled={disabledStatus(item.effectStatus)}>
                {isEmpty(timeCost) ? '-' : timeCost}
              </StateTypography>
            </Box>
          )}
          {item.status >= 2 && item.status !== 4 && (
            <Box display="flex" mt={2} alignItems="center">
              <StateTypography
                variant="body2"
                color="textSecondary"
                disabled={disabledStatus(item.effectStatus)}
                style={{
                  marginRight: 16,
                  width: 84,
                  overflow: 'hidden',
                  flexShrink: 0,
                }}
              >
                给予评价
              </StateTypography>
              <StateTypography variant="body2" disabled={disabledStatus(item.effectStatus) || !item?.evaluation}>
                {item?.evaluation ? item.evaluation : '暂无评价'}
              </StateTypography>
            </Box>
          )}
        </Box>
        {item.status >= 2 && item.status !== 4 && item.questions?.length > 0 && (
          <Box pb={1}>
            <QnAList list={item.questions} ref={qnaListRef} videoWrapperRef={videoWrapperRef} />
          </Box>
        )}
        {item.status >= 2 && item.status !== 4 && (
          <Box display="flex" justifyContent="flex-end" px={2}>
            <Button
              variant="outlined"
              // disabled={disabledStatus(item.effectStatus)}
              onClick={() => {
                if (item.status === 5) {
                  GlobalToast.warning('查看失败，该答卷已被删');
                } else if (disabledStatus(item.effectStatus)) {
                  GlobalToast.warning('查看失败，该答卷已被删');
                } else {
                  handlePauseVideo();
                  setShowIframeDialog(true);
                }
              }}
            >
              答卷展示
            </Button>
          </Box>
        )}
      </Box>

      <Popover
        open={Boolean(deletePopoverAnChorEl)}
        anchorEl={deletePopoverAnChorEl}
        onClose={() => setDeletePopoverAnChorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper style={{ width: '300px', padding: '24px 16px 12px 16px' }}>
          <Typography
            variant="body1"
            style={{
              color: 'rgba(0, 0, 0, 0.56)',
            }}
          >
            确认删除该场笔试？该操作无法被撤销。
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt="36px">
            <Button onClick={() => setDeletePopoverAnChorEl(null)}>取消</Button>
            <Box mr={1} />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setDeletePopoverAnChorEl(null);
                onDelete(item.token);
              }}
            >
              删除
            </Button>
          </Box>
        </Paper>
      </Popover>

      <ExamPreviewDialog
        open={showIframeDialog}
        examURL={item?.iframeResultLink}
        onCancel={() => {
          onNeedReload();
          setShowIframeDialog(false);
        }}
      />
    </Box>
  );
};

WrittenTab.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  onInviterChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onDelete: PropTypes.func,
  onNeedReload: PropTypes.func,
};

WrittenTab.defaultProps = {
  item: {},
  onInviterChange: () => {},
  onDateChange: () => {},
  onDelete: () => {},
  onNeedReload: () => {},
};

export default WrittenTab;
