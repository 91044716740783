import * as types from '../constants/ActionTypes';

export function addCandidate(name, token, mobile, wxid, sex, age, degree, source, applyTime, createdTime, idNumber) {
  return {
    type: types.ADD_CANDIDATE,
    name,
    token,
    mobile,
    wxid,
    sex,
    age,
    degree,
    source,
    applyTime,
    createdTime,
    idNumber,
  };
}

export function addCandidates(candidates, candidateCount) {
  return { type: types.ADD_CANDIDATES, candidates, candidateCount };
}

export function editCandidate(
  id,
  name,
  token,
  mobile,
  wxid,
  sex,
  age,
  degree,
  source,
  applyTime,
  createdTime,
  idNumber,
) {
  return {
    type: types.EDIT_CANDIDATE,
    id,
    name,
    token,
    mobile,
    wxid,
    sex,
    age,
    degree,
    source,
    applyTime,
    createdTime,
    idNumber,
  };
}

export function deleteCandidate(id) {
  return { type: types.DELETE_CANDIDATE, id };
}

export function clearLocalCandidates() {
  return { type: types.CLEAR_CANDIDATES };
}
export function getCandidates() {
  return { type: types.GET_CANDIDATES };
}

export function clearSelectedCandidate() {
  return { type: types.CLEAR_SELECTED_CANDIDATE };
}

export function addSelectedCandidate(token) {
  return { type: types.ADD_SELECTED_CANDIDATE, token };
}

export function removeSelectedCandidate(token) {
  return { type: types.REMOVE_SELECTED_CANDIDATE, token };
}

export function selectedAllCandidate() {
  return { type: types.SELECTED_ALL_CANDIDATE };
}

export function updateSelectedCandidate(tokens) {
  return { type: types.UPDATE_SELECTED_CANDIDATE, payload: tokens };
}

export function updateCandidatesFilter(filter) {
  return { type: types.UPDATE_CANDIDATES_FILTER, filter };
}

export function clearCandidatesFilter(filter) {
  return { type: types.CLEAR_CANDIDATES_FILTER, filter };
}
