import PropTypes from 'prop-types';

export default function FileIcon({ type }) {
  switch (type) {
    // case 'image/jpeg':
    // case 'image/png':
    // case 'image/gif':
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path
              d="M1.5 0h14.09a1 1 0 0 1 .7.3l5.42 5.4a1 1 0 0 1 .29.71V26.5c0 .83-.67 1.5-1.5 1.5h-19A1.5 1.5 0 0 1 0 26.5v-25C0 .67.67 0 1.5 0z"
              id="fb4e290a4a64160ddb669f9b324a6452-a"
            />
            <path
              d="M16.3.3l5.4 5.4a1 1 0 0 1 .21.3H17.5A1.5 1.5 0 0 1 16 4.5V.09c.1.05.2.12.3.2z"
              id="fb4e290a4a64160ddb669f9b324a6452-b"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(5 2)">
              <use fill="#FFC60A" xlinkHref="#fb4e290a4a64160ddb669f9b324a6452-a" />
              <use fill="#DC9B04" xlinkHref="#fb4e290a4a64160ddb669f9b324a6452-b" />
            </g>
            <path d="M8 11h16.65v16.65H8z" />
            <path
              d="M23.35 14.84c.1.08.15.2.15.33v9.45c0 .21-.17.38-.38.38H8.38a.3.3 0 0 1-.24-.5l4.9-5.83a.76.76 0 0 1 1.15 0l2.43 2.9 6.09-6.7c.17-.19.46-.2.64-.03zM11.75 12c.41 0 .75.34.75.76v1.48c0 .42-.34.76-.76.76h-1.48a.76.76 0 0 1-.76-.76v-1.48c0-.42.34-.76.76-.76h1.48z"
              fill="#FFF"
            />
          </g>
        </svg>
      );
    // case 'application/pdf':
    case 'pdf':
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path
              d="M1.5 0h14.09a1 1 0 0 1 .7.3l5.42 5.4a1 1 0 0 1 .29.71V26.5c0 .83-.67 1.5-1.5 1.5h-19A1.5 1.5 0 0 1 0 26.5v-25C0 .67.67 0 1.5 0z"
              id="760cdab81e24216683636a20b29f7b34-a"
            />
            <path
              d="M16.3.3l5.4 5.4a1 1 0 0 1 .21.3H17.5A1.5 1.5 0 0 1 16 4.5V.09c.1.05.2.12.3.2z"
              id="760cdab81e24216683636a20b29f7b34-b"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(5 2)">
              <use fill="#F54A45" xlinkHref="#760cdab81e24216683636a20b29f7b34-a" />
              <use fill="#D83931" xlinkHref="#760cdab81e24216683636a20b29f7b34-b" />
            </g>
            <path d="M7.65 10.7H24.3v16.65H7.65z" />
            <path
              d="M23.67 21.18c-.34-.4-1.05-.6-2.15-.6-.65 0-1.54.02-2.43.15-2.43-1.76-3-3.64-3-3.64s.41-1.05.44-2.75c.01-1.08-.16-1.89-.6-2.23a1.22 1.22 0 0 0-.72-.27.97.97 0 0 0-.57.18c-1.27.91.11 5.22.15 5.33a38.29 38.29 0 0 1-2.12 4.34c-.26.44-.26.44-.42.64 0 0-2.22 1.1-3.26 2.32-.59.7-.6 1.16-.58 1.52.05.42.6.8 1.14.8h.07a2.77 2.77 0 0 0 1.86-.83c.5-.47 1.05-1.75 1.77-3 2.06-.58 3.87-.99 5.4-1.22 1.1.59 2.76 1.26 3.9 1.26.37 0 .68-.08.9-.23.26-.18.38-.4.45-.82.07-.4-.03-.72-.23-.95zm-2.42.65c1.03 0 1.59.18 1.87.33.1.05.16.1.2.13-.08.06-.24.14-.52.14-.48 0-1.1-.2-1.86-.6h.31zm-5.9-9.08c.16.11.23.95.22 1.43-.02.64-.03.9-.11 1.29a7.65 7.65 0 0 1-.12-2.72zm.04 6.05c.5.83 1.14 1.66 1.86 2.3-1.4.3-2.56.58-3.4.87.9-1.55 1.48-3.02 1.54-3.17zm-5.65 7c.13-.18.48-.55 1.35-1.25-.47 1.09-1 1.25-1.49 1.51l.14-.26z"
              fill="#FFF"
            />
          </g>
        </svg>
      );
    // case 'application/msword':
    // case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'doc':
    case 'docx':
    case 'wps':
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path
              d="M1.5 0h14.09a1 1 0 0 1 .7.3l5.42 5.4a1 1 0 0 1 .29.71V26.5c0 .83-.67 1.5-1.5 1.5h-19A1.5 1.5 0 0 1 0 26.5v-25C0 .67.67 0 1.5 0z"
              id="6a0f12e96b9633e7499221b63d9f33ed-a"
            />
            <path
              d="M16.3.3l5.4 5.4a1 1 0 0 1 .21.3H17.5A1.5 1.5 0 0 1 16 4.5V.09c.1.05.2.12.3.2z"
              id="6a0f12e96b9633e7499221b63d9f33ed-b"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(5 2)">
              <use fill="#3370FF" xlinkHref="#6a0f12e96b9633e7499221b63d9f33ed-a" />
              <use fill="#245BDB" xlinkHref="#6a0f12e96b9633e7499221b63d9f33ed-b" />
            </g>
            <path d="M7.65 10.7H24.3v16.65H7.65z" />
            <path
              d="M15.98 16.6l-2.05 7.57c-.02.1-.1.15-.2.15h-1.2a.21.21 0 0 1-.2-.15l-2.78-9.8a.21.21 0 0 1 .2-.26h1.21c.1 0 .18.06.2.16l1.98 7.63 2.05-7.64c.02-.09.1-.15.2-.15h1.18c.1 0 .18.06.2.15l2.04 7.64 1.97-7.63c.03-.1.11-.16.2-.16h1.21a.21.21 0 0 1 .2.27l-2.77 9.8c-.02.08-.1.14-.2.14h-1.2a.21.21 0 0 1-.2-.15l-2.04-7.57z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      );
    // case 'application/vnd.ms-excel':
    // case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'xls':
    case 'xlsx':
    case 'csv':
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path
              d="M1.5 0h14.09a1 1 0 0 1 .7.3l5.42 5.4a1 1 0 0 1 .29.71V26.5c0 .83-.67 1.5-1.5 1.5h-19A1.5 1.5 0 0 1 0 26.5v-25C0 .67.67 0 1.5 0z"
              id="38f4318524c3c71a65281b26579212f6-a"
            />
            <path
              d="M16.3.3l5.4 5.4a1 1 0 0 1 .21.3H17.5A1.5 1.5 0 0 1 16 4.5V.09c.1.05.2.12.3.2z"
              id="38f4318524c3c71a65281b26579212f6-b"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(5 2)">
              <use fill="#34C724" xlinkHref="#38f4318524c3c71a65281b26579212f6-a" />
              <use fill="#2EA121" xlinkHref="#38f4318524c3c71a65281b26579212f6-b" />
            </g>
            <path d="M7.65 10H24.3v16.65H7.65z" />
            <path
              d="M11.18 12.65h1.52a.2.2 0 0 1 .16.09l2.92 4.22 2.92-4.22a.2.2 0 0 1 .17-.1h1.52a.2.2 0 0 1 .16.33l-3.79 5.32 4.1 5.77a.2.2 0 0 1-.17.32h-1.52a.2.2 0 0 1-.17-.09l-3.22-4.67-3.22 4.67a.2.2 0 0 1-.16.09h-1.52a.2.2 0 0 1-.17-.32l4.07-5.77L11 12.97a.2.2 0 0 1 .17-.32z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      );
    // case 'application/vnd.ms-powerpoint':
    // case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'ppt':
    case 'pptx':
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path
              d="M1.5 0h14.09a1 1 0 0 1 .7.3l5.42 5.4a1 1 0 0 1 .29.71V26.5c0 .83-.67 1.5-1.5 1.5h-19A1.5 1.5 0 0 1 0 26.5v-25C0 .67.67 0 1.5 0z"
              id="746bdb79015ef7584b590331fd970c7a-a"
            />
            <path
              d="M16.3.3l5.4 5.4a1 1 0 0 1 .21.3H17.5A1.5 1.5 0 0 1 16 4.5V.09c.1.05.2.12.3.2z"
              id="746bdb79015ef7584b590331fd970c7a-b"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(5 2)">
              <use fill="#F80" xlinkHref="#746bdb79015ef7584b590331fd970c7a-a" />
              <use fill="#DE7802" xlinkHref="#746bdb79015ef7584b590331fd970c7a-b" />
            </g>
            <path d="M7.65 10H24.3v16.65H7.65z" />
            <path
              d="M13.73 19.58v4.98c0 .1-.09.2-.2.2H12.4a.2.2 0 0 1-.2-.2V12.84c0-.1.09-.2.2-.2h4.38c.8 0 1.47.1 2 .28.53.18.96.44 1.27.77.32.32.54.7.67 1.13.12.41.18.84.18 1.3 0 .68-.14 1.26-.44 1.71-.29.45-.66.8-1.13 1.07-.45.26-.96.43-1.54.54-.56.1-1.12.14-1.69.14h-2.36zm2.4-1.34c.52 0 .98-.03 1.4-.1.4-.05.72-.17 1-.33.26-.16.46-.38.6-.65.15-.28.22-.65.22-1.12 0-.68-.22-1.18-.66-1.52-.45-.35-1.2-.53-2.23-.53h-2.73v4.25h2.4z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      );

    case 'html':
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path
              d="M1.5 0h14.09a1 1 0 0 1 .7.3l5.42 5.4a1 1 0 0 1 .29.71V26.5c0 .83-.67 1.5-1.5 1.5h-19A1.5 1.5 0 0 1 0 26.5v-25C0 .67.67 0 1.5 0z"
              id="2eefa9e36f7a65ca205bb04b21bdfa07-a"
            />
            <path
              d="M16.3.3l5.4 5.4a1 1 0 0 1 .21.3H17.5A1.5 1.5 0 0 1 16 4.5V.09c.1.05.2.12.3.2z"
              id="2eefa9e36f7a65ca205bb04b21bdfa07-b"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(5 2)">
              <use fill="#3370FF" xlinkHref="#2eefa9e36f7a65ca205bb04b21bdfa07-a" />
              <use fill="#245BDB" xlinkHref="#2eefa9e36f7a65ca205bb04b21bdfa07-b" />
            </g>
            <path d="M7.65 10.7H24.3v16.65H7.65z" />
            <path
              d="M13.61 15.53l-3.5 3.69 3.5 3.69c.13.13.12.34 0 .47l-.48.47a.32.32 0 0 1-.46 0l-4.17-4.4a.34.34 0 0 1 0-.47l4.17-4.4a.32.32 0 0 1 .46 0l.47.47a.34.34 0 0 1 .01.48zm8.35 3.69l-3.25-3.7a.36.36 0 0 1 0-.47l.44-.47a.28.28 0 0 1 .43 0l3.87 4.4c.12.13.12.34 0 .47l-3.87 4.4a.28.28 0 0 1-.43 0l-.44-.47a.36.36 0 0 1 0-.47l3.25-3.7zm-5.11-7l.68.07c.18.02.32.18.3.35L16.2 25.56c-.02.17-.2.3-.38.28l-.68-.08c-.19-.02-.32-.17-.3-.35l1.63-12.92c.02-.17.19-.3.38-.27z"
              fill="#FFF"
            />
          </g>
        </svg>
      );
    default:
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path
              d="M1.5 0h14.09a1 1 0 0 1 .7.3l5.42 5.4a1 1 0 0 1 .29.71V26.5c0 .83-.67 1.5-1.5 1.5h-19A1.5 1.5 0 0 1 0 26.5v-25C0 .67.67 0 1.5 0z"
              id="9473b47b4177ab8b649170543691a1bd-a"
            />
            <path
              d="M16.3.3l5.4 5.4a1 1 0 0 1 .21.3H17.5A1.5 1.5 0 0 1 16 4.5V.09c.1.05.2.12.3.2z"
              id="9473b47b4177ab8b649170543691a1bd-b"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(5 2)">
              <use fill="#9CA3AD" xlinkHref="#9473b47b4177ab8b649170543691a1bd-a" />
              <use fill="#646A73" xlinkHref="#9473b47b4177ab8b649170543691a1bd-b" />
            </g>
            <path d="M7.65 10.7H24.3v16.65H7.65z" />
            <path
              d="M16.16 12.22c1.24 0 2.23.35 2.99 1.07a3.6 3.6 0 0 1 1.08 2.75c0 .83-.26 1.59-.76 2.25-.21.27-.68.72-1.52 1.48a3.8 3.8 0 0 0-.85.94c-.25.43-.37.87-.37 1.36v.55c0 .1-.08.2-.19.2H15.4a.19.19 0 0 1-.2-.2v-.55c0-.58.13-1.12.37-1.6.28-.6.83-1.24 1.72-2.01.56-.56.73-.74.85-.9a2.5 2.5 0 0 0-.08-3.29c-.44-.43-1.09-.65-1.95-.65-.96 0-1.66.31-2.14.95-.38.47-.59.9-.63 1.7v.3c0 .1-.09.19-.2.19H12a.19.19 0 0 1-.19-.2v-.29a4.05 4.05 0 0 1 1.09-2.7 4.1 4.1 0 0 1 3.26-1.35zm-.75 12.1h1.13c.1 0 .19.09.19.2v1.13c0 .1-.08.19-.19.19h-1.13a.19.19 0 0 1-.2-.2v-1.13c0-.1.1-.19.2-.19z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      );
  }
}

FileIcon.propTypes = {
  type: PropTypes.string.isRequired,
};
