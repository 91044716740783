/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCandidateDispatch, useCandidateState } from './context';
import useDataLoader from './useDataLoader';

export default function DataLoader() {
  const isOpen = useSelector(({ candidateModal }) => candidateModal.isOpen);
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);

  const { count } = useCandidateState();
  const dispatch = useCandidateDispatch();
  const {
    loadCandidate,
    loadCandidateApplication,
    loadApplication,
    loadCandidateAssignablePositions,
  } = useDataLoader();

  useEffect(() => {
    if (!isOpen || !candidateToken) return;
    loadCandidate(candidateToken, true);
    loadCandidateAssignablePositions(candidateToken);
  }, [isOpen, candidateToken]);

  // Fix 解决日志统计刷新不计入统计
  useEffect(() => {
    if (!isOpen || !candidateToken) return;
    loadCandidate(candidateToken);
    loadCandidateAssignablePositions(candidateToken);
  }, [count]);

  useEffect(() => {
    if (!isOpen || !candidateToken) return;
    loadCandidateApplication(candidateToken, applicationToken);
  }, [isOpen, count, candidateToken, applicationToken]);

  useEffect(() => {
    if (!isOpen || !applicationToken) return;
    const source = loadApplication(applicationToken);

    return () => {
      if (source && source.cancel) {
        // console.log('axios canceled:', source.token);
        source.cancel();
      }
    };
  }, [isOpen, count, applicationToken]);

  useEffect(() => {
    if (!isOpen) return;
    dispatch({ type: 'INIT_APPLICATION_FLOW_STEP' });
  }, [isOpen, count, candidateToken, applicationToken]);

  return null;
}
