import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import RoleSwitchListItem from './RoleSwitchListItem';
import Avatar from './Avatar';

const RoleSwitch = ({
  index, avatar, primary, secondary, selected, onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick(index);
  }, [index]);
  return (
    <RoleSwitchListItem selected={selected} button onClick={handleClick}>
      <ListItemAvatar>
        <Avatar>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
    </RoleSwitchListItem>
  );
};

RoleSwitch.propTypes = {
  index: PropTypes.number,
  selected: PropTypes.bool,
  avatar: PropTypes.node,
  primary: PropTypes.node,
  secondary: PropTypes.node,
  onClick: PropTypes.func,
};

RoleSwitch.defaultProps = {
  index: 0,
  selected: false,
  avatar: null,
  primary: null,
  secondary: null,
  onClick: null,
};

export default RoleSwitch;
