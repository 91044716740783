import AddIcon from '@material-ui/icons/Add';
import { useCandidateDispatch } from '../context';
import styles from './CandidateAttachments.module.css';

function CreateAttachementFolder() {
  const dispatch = useCandidateDispatch();

  return (
    <div role="button" className={styles.createFolder} onClick={() => dispatch({ type: 'ADD_ATTACHMENT_FOLDER' })}>
      <AddIcon />
    </div>
  );
}

export default CreateAttachementFolder;
