import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import ErrorView from './ErrorView';

const ENVIRONMENT_FLAG = process.env.NODE_ENV;

Bugsnag.start({
  apiKey: 'cad8c2fe79fe58d6f5cfbe9071a1fe1f',
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
  user: {
    name: window.localStorage.getItem('username'),
  },
  releaseStage: ENVIRONMENT_FLAG,
  enabledReleaseStages: ['production'], // 只在线上环境产生bug时发送报告给bugsnag
});

const BugSnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

class ErrorBoundary extends React.Component {
  render() {
    const { children } = this.props;

    return <BugSnagErrorBoundary FallbackComponent={ErrorView}>{children}</BugSnagErrorBoundary>;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
