import { fork, takeLatest } from 'redux-saga/effects';

import { setCurrentPositionProcessStat } from './action';

function* refreshPostFilterUseResult() {
  // const state = yield select();
  // const result = yield http.post({
  //   url: `${API_URL}applicationSession/applications`,
  //   data: state.process.filters,
  // });
  // if (result.data.code === 0) {
  //   yield put(addReviewCandidates(result.data.data.list, result.data.data.total));
  // }
}

function* watch() {
  yield takeLatest(setCurrentPositionProcessStat, refreshPostFilterUseResult);
}

export default [fork(watch)];
