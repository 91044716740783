import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CandidateOriginalResume from '@module/CandidateModal/CandidateResume/CandidateOriginalResume';
import { useContext } from 'react';
import { ResumeContext } from './ResumeContext';

const ResumeOriginal = () => {
  const { uploadStatus = 0, resumeURL = '' } = useContext(ResumeContext);

  return (
    <Box style={{ backgroundColor: 'white' }} width="100%" height="100%">
      <CandidateOriginalResume resumeURL={resumeURL} hidenResumeReplace />
      {uploadStatus !== 0 && (
        <Box
          position="absolute"
          zIndex={1}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          style={{ backgroundColor: 'white', top: 0, left: 0 }}
        >
          <Box mt={40} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <CircularProgress />
            <Typography>
              {uploadStatus === 1 && '上传中'}
              {uploadStatus === 2 && '解析中'}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

ResumeOriginal.propTypes = {};

ResumeOriginal.defaultProps = {};

export default ResumeOriginal;
