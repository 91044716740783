import { deleteEvaluationDetail, updateEvaluationDetail } from '@api/Evaluation';
import { CloseIcon, DeleteIcon, EditIcon } from '@avocadoui/icons';
import Typography from '@components/Avocado/Typography';
import {
  Box, Button, IconButton, Paper, Popover,
} from '@material-ui/core';
import useGlobalToast from '@utils/GlobalToast';
import { isEmpty } from 'lodash';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { CANCELED_PROCESS_APPLICATION_STATE, DEFAULT_VISIBLE_SWITCH } from '../const';
import { useCandidateState } from '../context';
import { useEvaluationDispatch, useEvaluationState } from './context';
import EvaluationFormEditor from './EvaluationFormEditor';
import EvaluationFormViewer from './EvaluationFormViewer';
import EvaluationScores from './EvaluationScores';
import EvaluationVisibleUsersEditor from './EvaluationVisibleUsersEditor';
import EvaluationVisibleUsersViewer from './EvaluationVisibleUsersViewer';
import useEffectReloadEvaluationDetail from './hooks/useEffectReloadEvaluationDetail';
import useMemoFormAnswers from './hooks/useMemoFormAnswers';
import useMemoFormItems from './hooks/useMemoFormItems';
import useMemoShouldDisabledSubmit from './hooks/useMemoShouldDisabledSubmit';
import useMemoTotalScore from './hooks/useMemoTotalScore';

function EvaluationItemDetail() {
  const GlobalToast = useGlobalToast();
  const reduxDispatch = useDispatch();
  const dispatch = useEvaluationDispatch();
  const editMode = useEvaluationState((state) => state.editMode);
  const formData = useEvaluationState((state) => state.formData);
  const feedbackData = useEvaluationState((state) => state.feedbackData);
  const selectedEvaluationToken = useEvaluationState((state) => state.selectedEvaluationToken);
  const selectedEvaluationDetail = useEvaluationState((state) => state.selectedEvaluationDetail);
  const {
    name = '',
    formItems = [],
    hasFeedback,
    feedback,
    isScoreOpen = false,
    score: totalScore,
    fullScore,
    visibleUsers = [],
    visibleSwitch = DEFAULT_VISIBLE_SWITCH,
    canEditable = false,
    canDeletable = false,
  } = selectedEvaluationDetail;
  // const isCanceled = useCandidateState((state) => state.applicationData.applicationState === 2);
  const isCanceled = useCandidateState(
    (state) => CANCELED_PROCESS_APPLICATION_STATE.indexOf(state.applicationData.applicationState) > -1,
  );

  // 将 formItems 转换为 formData
  useEffect(() => {
    if (!canEditable) return;

    if (hasFeedback) {
      const payload = { key: 'feedbackData', value: feedback };
      dispatch({ type: 'SET_STATE', payload });
    }

    if (formItems && formItems.length > 0) {
      const value = {};
      formItems.forEach(({
        formItemToken, formTypeId, formAnswer, score, isScoreOpen: isScoreOpenInt,
      }) => {
        if (formTypeId === 2) {
          value[formItemToken] = {
            type: 'question',
            value: formAnswer?.answer || '',
            score,
          };
        }

        if (formTypeId === 3) {
          value[formItemToken] = {
            type: 'radio',
            value: formAnswer?.choiceOptions || [],
            score,
          };
        }

        if (formTypeId === 6) {
          value[formItemToken] = {
            type: 'checkbox',
            value: formAnswer?.choiceOptions || [],
            score,
          };
        }

        if (isScoreOpenInt === 2) {
          delete value[formItemToken].score;
        }
      });
      const payload = { key: 'formData', value };
      dispatch({ type: 'SET_STATE', payload });
    }
  }, [formItems, hasFeedback, feedback, canEditable]);

  useEffectReloadEvaluationDetail();

  const disabledSubmmit = useMemoShouldDisabledSubmit({
    formItems,
    formData,
    feedbackData,
    hasFeedback,
    visibleSwitch,
    visibleUsers,
  });
  const computedTotalScore = useMemoTotalScore(formData);
  const formAnswers = useMemoFormAnswers(formData);
  const modifiedFormItems = useMemoFormItems(formItems, hasFeedback);
  if (isEmpty(formItems)) {
    return null;
  }

  function handleSubmit() {
    const data = {
      visibleSwitch,
      visibleUsers: visibleSwitch === 1 ? visibleUsers.map((u) => u.userToken) : [],
      formAnswers,
      feedback: feedbackData,
    };

    updateEvaluationDetail(selectedEvaluationToken, data)
      .then((result) => {
        if (result.data.code === 0) {
          dispatch({ type: 'GO_TO_VIEWING_AND_REFRESH_DATA' });
          reduxDispatch({ type: 'RELOAD_TEMPORARY_DRAWER' });
          GlobalToast.success('保存成功');
        } else {
          GlobalToast.error('保存失败');
        }
      })
      .catch(() => {
        GlobalToast.error('保存失败');
      });
  }

  function handleDelete() {
    const data = { tokens: [selectedEvaluationToken] };
    deleteEvaluationDetail(data).then((result) => {
      if (result.data.code === 0) {
        dispatch({ type: 'BACK_TO_LIST_AND_REFRESH_DATA' });
      }
    });
  }

  if (editMode === 2) {
    return (
      <Box width={326} padding={2} height display="flex" flexDirection="column" justifyContent="space-between">
        <Box key="edit" display="flex" justifyContent="space-between" alignItems="center" minHeight="36px">
          <Box>
            <Typography variant="body1" color="text.primary" fontWeight="500">
              {name}
            </Typography>
          </Box>
        </Box>
        <Box flex="1 1 auto" overflow="scroll">
          {isScoreOpen && (
            <Box mt={2} display="flex" justifyContent="flex-start">
              <EvaluationScores totalScore={computedTotalScore} fullScore={fullScore} />
            </Box>
          )}
          {modifiedFormItems.map((item, index) => (
            <EvaluationFormEditor item={item} index={index} />
          ))}

          <EvaluationVisibleUsersEditor visibleSwitch={visibleSwitch} visibleUsers={visibleUsers} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2} mr={-2}>
          <Box>
            <Button
              onClick={() => {
                dispatch({ type: 'GO_TO_VIEWING_AND_REFRESH_DATA' });
              }}
            >
              取消
            </Button>
          </Box>
          <Box ml={1} mr={2}>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disabledSubmmit}>
              保存
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  const showActionBtns = (canEditable || canDeletable) && !isCanceled;

  return (
    <Box width={326} padding={2}>
      <Box key="view" display="flex" justifyContent="space-between" alignItems="center" minHeight="36px">
        <Box display="flex" alignItems="center" justifyContent="space-between" width>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" color="text.primary" fontWeight="500">
              {name}
            </Typography>
          </Box>
          {!canDeletable && (
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => {
                  dispatch({ type: 'BACK_TO_LIST_AND_REFRESH_DATA' });
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {showActionBtns && (
          <Box display="flex">
            {canEditable && (
              <Box>
                <IconButton
                  onClick={() => {
                    dispatch({ type: 'GO_TO_EDITING' });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            )}
            {canDeletable && (
              <Box ml={1}>
                <PopupState variant="popover" popupId="delete-comment-popup-popover">
                  {(popupState) => (
                    <>
                      <IconButton {...bindTrigger(popupState)}>
                        <DeleteIcon />
                      </IconButton>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <Paper style={{ width: 300, padding: '24px 16px 12px 16px' }}>
                          <Typography variant="body1" color="text.secondary">
                            确认删除所填评价表吗？
                          </Typography>
                          <Box display="flex" justifyContent="flex-end" mt="36px">
                            <Button onClick={popupState.close}>取消</Button>
                            <Box mr={1} />
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                popupState.close();
                                handleDelete();
                              }}
                            >
                              删除
                            </Button>
                          </Box>
                        </Paper>
                      </Popover>
                    </>
                  )}
                </PopupState>
              </Box>
            )}
            {canDeletable && (
              <Box display="flex" alignItems="center" ml={1} mr={0.5}>
                <IconButton
                  onClick={() => {
                    dispatch({ type: 'BACK_TO_LIST_AND_REFRESH_DATA' });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {isScoreOpen && (
        <Box mt={2} display="flex" justifyContent="flex-start">
          <EvaluationScores totalScore={totalScore} fullScore={fullScore} />
        </Box>
      )}
      {modifiedFormItems.map((item, index) => (
        <EvaluationFormViewer item={item} index={index} />
      ))}
      <EvaluationVisibleUsersViewer visibleUsers={visibleUsers} />
    </Box>
  );
}

export default memo(EvaluationItemDetail);
