import { cancelInterview, deleteInterviewRecord } from '@api/Interview';
import { OverflowIcon } from '@avocadoui/icons';
import Typography from '@components/Avocado/Typography';
import ELConfirmDialog from '@components/Dialog/ELConfirmDialog';
import ELDialog from '@components/Dialog/ELDialog';
import { InterviewTypeIcon } from '@components/Icon';
import AutoLink from '@components/InterviewArrangement/AutoLink';
import {
  INTERVIEW_APPLICATION_STATUS,
  INTERVIEW_STATUS,
  INTERVIEW_TYPES_OBJECT,
} from '@components/InterviewArrangement/const';
import { InterviewProvider } from '@components/InterviewArrangement/context';
import { SendNotificationToCandidatesDialog } from '@components/InterviewArrangement/Dialog';
import {
  IconButton, ListItemText, Menu, MenuItem,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import * as interviewActions from '@redux/interviewArrangement/action';
import useGlobalToast from '@utils/GlobalToast';
import moment from '@utils/moment';
import { useActions } from '@utils/useActions';
import clsx from 'clsx';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import VideoSection from '../CandidateInterview/VideoSection';
import { CANCELED_PROCESS_APPLICATION_STATE, DAY_OF_WEEK_NAMES } from '../const';
import { useCandidateDispatch, useCandidateState } from '../context';
import styles from './CandidateInterviewArrangement.module.css';

const CANCEL_REASONS = ['受邀人爽约', '面试官忙', '其他原因'];
const CANCEL_REASONS_INDEX = 0;
const LABEL_WIDTH = 100;

function InterviewArrangmentItem({ item, setHasOpenedArrangmentModal, processToken }) {
  const {
    token,
    interviewToken,
    periodToken,
    name,
    startTime,
    endTime,
    type,
    status,
    address,
    createUser = {},
    cancelInfo = {},
    canSendNotification,
    showSendNotification,
    bookingTime,
    checkinTime,
    isFinish = false,
    interviewMode,
    notifyTime,
    periodStatus,
    rtcUsers = [],
    videoUrl,
    roomToken,
    interviewUserToken,
  } = item;
  const weekday = moment(startTime * 1000).day();
  const day = moment(startTime * 1000).format('M[月]D日');
  const time = moment(startTime * 1000).format('HH:mm');

  const [sendModalIsOpen, setSendModalIsOpen] = useState(false);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const [cancelReasonIndex, setCancelReasonIndex] = useState(CANCEL_REASONS_INDEX);
  const [cancelReason, setCancelReason] = useState(CANCEL_REASONS[CANCEL_REASONS_INDEX]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [rtcCandidateToken, setRtcCandidateToken] = useState('');
  const { applicationData } = useCandidateState();
  const isCanceled = useCandidateState(
    (state) => CANCELED_PROCESS_APPLICATION_STATE.indexOf(state.applicationData.applicationState) > -1,
  );
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const { positionToken } = applicationData;
  const dispatch = useCandidateDispatch();
  const GlobalToast = useGlobalToast();
  const { openArrangementModal } = useActions(interviewActions);
  const currentAccountInfo = useSelector((state) => state.account?.userInfo?.user || {});
  const { role = 5, token: currentAccountToken } = currentAccountInfo;
  const isHidden = role === 5; // 面试官账号
  const popupState = usePopupState({ variant: 'popover', popupId: 'interview-card' });

  useEffect(() => {
    if (cancelReasonIndex === CANCEL_REASONS.length - 1) {
      setCancelReason('');
    } else {
      setCancelReason(CANCEL_REASONS[cancelReasonIndex]);
    }
  }, [cancelReasonIndex]);

  useEffect(() => {
    if (cancelModalIsOpen) return;
    setCancelReason(CANCEL_REASONS[CANCEL_REASONS_INDEX]);
    setCancelReasonIndex(CANCEL_REASONS_INDEX);
  }, [cancelModalIsOpen]);

  function handleClose() {
    if (cancelModalIsOpen) setCancelModalIsOpen(false);
    if (sendModalIsOpen) setSendModalIsOpen(false);
    if (rtcCandidateToken) setRtcCandidateToken('');
  }

  // 已签到、已取消、已结束
  let statusColor = '#9B9B9B';
  const statusTxt = INTERVIEW_APPLICATION_STATUS[status];
  if (status === 1 || status === 7) {
    // 未接受、申请改时间
    statusColor = '#F5C441';
  }
  if (status === 2) {
    // 已接受
    statusColor = '#4CAF50';
  }
  if (status === 5) {
    // 已拒绝
    statusColor = '#FF6C6C';
  }

  const isFinished = isFinish || [4, 5, 6].indexOf(status) > -1;

  if (isFinish) {
    statusColor = '#9B9B9B';
  }

  const showActionBtns = !isCanceled && !isHidden;
  const rtcInterviewer = rtcUsers.filter((u) => u.userType === 1);
  const rtcInterviewee = rtcUsers.filter((u) => u.userType === 2);

  return (
    <>
      <Box display="flex" margin="16px">
        <Box width="26px" flexShrink={0}>
          <InterviewTypeIcon type={type} />
        </Box>
        <Box ml="8px" width="100%" border="1px solid #EDEDED" borderRadius="4px">
          <Box display="flex" justifyContent="space-between" padding="16px" style={isFinished ? { opacity: 0.5 } : {}}>
            <Box>
              <Typography variant="body2" color="text.primary" fontWeight="fontWeightMedium">
                {DAY_OF_WEEK_NAMES[weekday]}/{day}
              </Typography>
              <Box display="flex" mt="2px">
                <Typography variant="caption" color="text.secondary">
                  <span>{name}</span>
                  <span> · </span>
                  <span
                    className={clsx({
                      [styles.type1]: type === 1,
                      [styles.type2]: type === 2,
                      [styles.type3]: type === 3,
                    })}
                  >
                    {INTERVIEW_TYPES_OBJECT[type]}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box fontSize="12px" color={statusColor}>
                {isFinish ? INTERVIEW_STATUS[periodStatus] : statusTxt}
              </Box>
              {showActionBtns && (
                <Box ml={2}>
                  <IconButton {...bindTrigger(popupState)}>
                    <OverflowIcon />
                  </IconButton>
                  <Menu
                    {...bindMenu(popupState)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    {status === 7 && (
                      <MenuItem
                        onClick={(e) => {
                          openArrangementModal({
                            entryType: 2,
                            positionToken,
                            processToken,
                            applicationTokens: [applicationToken],
                            isRearranging: true,
                            applicationPeriodToken: periodToken,
                          });
                          setHasOpenedArrangmentModal(true);
                          popupState.close(e);
                        }}
                      >
                        <ListItemText primary="重新安排邀约" />
                      </MenuItem>
                    )}
                    {!isFinished && status !== 7 && (
                      <MenuItem
                        onClick={(e) => {
                          // 检查登陆者是否为创建者本人
                          if (createUser.token === currentAccountToken) {
                            // 可以成功进行编辑
                            if (!interviewMode) {
                              GlobalToast.warning('已知问题：后端未传 interviewMode 字段');
                              return;
                            }
                            openArrangementModal({
                              entryType: 4,
                              periodToken,
                              interviewToken,
                              interviewMode,
                            });
                            setHasOpenedArrangmentModal(true);
                          } else {
                            GlobalToast.warning(`只能创建者${createUser.name}操作`);
                          }
                          popupState.close(e);
                        }}
                        disabled={isFinished}
                      >
                        <ListItemText primary="修改邀约" />
                      </MenuItem>
                    )}
                    {!isFinished && (
                      <MenuItem
                        onClick={(e) => {
                          // 检查登陆者是否为创建者本人
                          if (createUser.token === currentAccountToken) {
                            setCancelModalIsOpen(true);
                          } else {
                            GlobalToast.warning(`只能创建者${createUser.name}操作`);
                          }
                          popupState.close(e);
                        }}
                        disabled={isFinished}
                      >
                        <ListItemText primary="取消邀约" />
                      </MenuItem>
                    )}
                    <>
                      <MenuItem
                        onClick={(e) => {
                          // 检查登陆者是否为创建者本人
                          if (createUser.token === currentAccountToken) {
                            setDeleteModalIsOpen(true);
                          } else {
                            GlobalToast.warning(`只能创建者${createUser.name}操作`);
                          }
                          popupState.close(e);
                        }}
                      >
                        <ListItemText primary="删除邀约" />
                      </MenuItem>
                    </>
                  </Menu>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            padding="28px 16px"
            borderTop="1px solid #EDEDED"
            borderBottom={showSendNotification ? '1px solid #EDEDED' : '0'}
            style={isFinished ? { opacity: 0.5 } : {}}
          >
            <Box display="flex">
              <Box width={LABEL_WIDTH} flexShrink={0}>
                <Typography variant="body2" color="text.secondary">
                  {type === 2 ? '线上地址' : '地点'}
                </Typography>
              </Box>
              {[1, 3].indexOf(type) > -1 && <Box>{address}</Box>}
              {type === 2 && (
                <Box>
                  <AutoLink text={address} />
                </Box>
              )}
            </Box>
            {rtcInterviewer && rtcInterviewer.length === 0 && (
              <Box display="flex" mt="24px">
                <Box width={LABEL_WIDTH} flexShrink={0}>
                  <Typography variant="body2" color="text.secondary">
                    负责人
                  </Typography>
                </Box>
                <Box>{createUser.name}</Box>
              </Box>
            )}
            {rtcInterviewer && rtcInterviewer.length > 0 && (
              <Box display="flex" mt="24px">
                <Box width={LABEL_WIDTH} flexShrink={0}>
                  <Typography variant="body2" color="text.secondary">
                    负责人
                  </Typography>
                </Box>
                {periodStatus === 1 ? (
                  <Box flex={1}>
                    {rtcInterviewer.map((u) => (
                      <Box
                        key={u.userToken}
                        display="flex"
                        justifyContent="space-between"
                        mb={1}
                        minHeight="25px"
                        className={styles.parent}
                      >
                        <Box>
                          <span>{u.userName}</span>
                          {u.onlineStatus === 2 ? (
                            <Typography variant="caption" color="primary.main" style={{ marginLeft: '8px' }}>
                              已上线
                            </Typography>
                          ) : (
                            <Typography variant="caption" color="text.disabled" style={{ marginLeft: '8px' }}>
                              未上线
                            </Typography>
                          )}
                        </Box>
                        {/* TODO: 改为负责人接口 */}
                        {/* <Box className={styles.child}>
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={!u.canSendNotification}
                            onClick={() => {
                              setRtcCandidateToken(u.userToken);
                              setSendModalIsOpen(true);
                            }}
                          >
                            发送提醒
                          </Button>
                        </Box> */}
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box>
                    {rtcInterviewer.map((u) => (
                      <span key={u.userToken} style={{ marginRight: '8px' }}>
                        {u.userName}
                      </span>
                    ))}
                  </Box>
                )}
              </Box>
            )}
            {rtcInterviewee && rtcInterviewee.length > 0 && (
              <Box display="flex" mt="24px">
                <Box width={LABEL_WIDTH} flexShrink={0}>
                  <Typography variant="body2" color="text.secondary">
                    候选人
                  </Typography>
                </Box>
                {periodStatus === 1 ? (
                  <Box flex={1}>
                    {rtcInterviewee.map((u) => (
                      <Box
                        key={u.userToken}
                        display="flex"
                        justifyContent="space-between"
                        mb={1}
                        minHeight="25px"
                        className={styles.parent}
                      >
                        <Box>
                          <span>{u.userName}</span>
                          {u.onlineStatus === 2 ? (
                            <Typography variant="caption" color="primary.main" style={{ marginLeft: '8px' }}>
                              已上线
                            </Typography>
                          ) : (
                            <Typography variant="caption" color="text.disabled" style={{ marginLeft: '8px' }}>
                              未上线
                            </Typography>
                          )}
                        </Box>
                        <Box className={styles.child}>
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={!u.canSendNotification}
                            onClick={() => {
                              setRtcCandidateToken(u.userToken);
                              setSendModalIsOpen(true);
                            }}
                          >
                            发送提醒
                          </Button>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box>
                    {rtcInterviewee.map((u) => (
                      <span key={u.userToken} style={{ marginRight: '8px' }}>
                        {u.userName}
                      </span>
                    ))}
                  </Box>
                )}
              </Box>
            )}
            <Box display="flex" mt="24px">
              <Box width={LABEL_WIDTH} flexShrink={0}>
                <Typography variant="body2" color="text.secondary">
                  开始时间
                </Typography>
              </Box>
              <Box>
                {time} 时长{(endTime - startTime) / 60}分钟
              </Box>
            </Box>
            {bookingTime !== 0 && status !== 7 && (
              <Box display="flex" mt="24px">
                <Box width={LABEL_WIDTH} flexShrink={0}>
                  <Typography variant="body2" color="text.secondary">
                    报名时间
                  </Typography>
                </Box>
                <Box>{moment(bookingTime * 1000).format('YYYY-MM-DD HH:mm')}</Box>
              </Box>
            )}
            {checkinTime !== 0 && (
              <Box display="flex" mt="24px">
                <Box width={LABEL_WIDTH} flexShrink={0}>
                  <Typography variant="body2" color="text.secondary">
                    签到时间
                  </Typography>
                </Box>
                <Box>{moment(checkinTime * 1000).format('YYYY-MM-DD HH:mm')}</Box>
              </Box>
            )}
            {cancelInfo.cancelTime && status !== 7 && (
              <Box display="flex" mt="24px">
                <Box width={LABEL_WIDTH} flexShrink={0}>
                  <Typography variant="body2" color="text.secondary">
                    取消原因
                  </Typography>
                </Box>
                <Box flex={1} display="flex" justifyContent="space-between">
                  <Box>{cancelInfo.info}</Box>
                  <Box>{moment(cancelInfo.cancelTime * 1000).format('MM/DD HH:mm')}</Box>
                </Box>
              </Box>
            )}
            {cancelInfo.remark && status !== 7 && (
              <Box display="flex" mt="24px">
                <Box width={LABEL_WIDTH} flexShrink={0}>
                  <Typography variant="body2" color="text.secondary">
                    备注
                  </Typography>
                </Box>
                <Box>{cancelInfo.remark}</Box>
              </Box>
            )}
            {status === 7 && (
              <Box display="flex" mt="24px">
                <Box width={LABEL_WIDTH} flexShrink={0}>
                  <Typography variant="body2" color="text.secondary">
                    申请原因
                  </Typography>
                </Box>
                <Box flex={1} display="flex" justifyContent="space-between">
                  <Box>{cancelInfo.remark}</Box>
                  <Box>{moment(cancelInfo.cancelTime * 1000).format('MM/DD HH:mm')}</Box>
                </Box>
              </Box>
            )}
          </Box>
          <VideoSection videoUrl={videoUrl} roomToken={roomToken} interviewUserToken={interviewUserToken} />
          {showSendNotification ? (
            <InterviewProvider>
              <Box display="flex" justifyContent="flex-end" alignItems="center" padding="12px 16px">
                {!canSendNotification && notifyTime ? (
                  <Typography variant="caption" color="text.disabled" mr={1.5}>
                    提醒时间：{moment(notifyTime).format('YYYY-MM-DD HH:mm')}
                  </Typography>
                ) : null}
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!canSendNotification}
                  onClick={() => {
                    setSendModalIsOpen(true);
                  }}
                >
                  {canSendNotification ? '提醒候选人' : '已提醒'}
                </Button>
              </Box>
              <SendNotificationToCandidatesDialog
                open={sendModalIsOpen}
                onClose={handleClose}
                periodToken={periodToken}
                interviewToken={interviewToken}
                candidateToken={candidateToken}
                onSuccess={() => dispatch({ type: 'REFRESH_DATA' })}
              />
            </InterviewProvider>
          ) : null}
        </Box>
      </Box>
      <ELDialog
        open={cancelModalIsOpen}
        title="取消邀约"
        onClose={handleClose}
        content={(
          <>
            <RadioGroup
              value={cancelReasonIndex}
              onChange={(e) => {
                setCancelReasonIndex(+e.target.value);
              }}
            >
              {CANCEL_REASONS.map((reason, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <FormControlLabel key={i} value={i} control={<Radio color="primary" />} label={reason} />
              ))}
            </RadioGroup>
            {cancelReasonIndex === CANCEL_REASONS.length - 1 && (
              <Box mt="8px">
                <TextField
                  value={cancelReason}
                  onChange={(e) => {
                    setCancelReason(e.target.value);
                  }}
                  label="取消原因"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                />
              </Box>
            )}
          </>
        )}
        actions={(
          <Box display="flex" alignItems="center" mr="16px">
            <Button onClick={handleClose}>取消</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const data = {
                  interviewToken,
                  periodToken,
                  applicationTokens: [applicationToken],
                  remark: cancelReason,
                };
                cancelInterview(data)
                  .then((result) => {
                    if (result.data.code === 0) {
                      GlobalToast.success('取消成功');
                    } else if (result.data.code === -4001) {
                      GlobalToast.error('暂无权限，申请开通请联系管理员');
                    } else {
                      GlobalToast.error('取消失败');
                    }
                    dispatch({ type: 'REFRESH_DATA' });
                    handleClose();
                  })
                  .catch(() => {
                    GlobalToast.error('取消失败');
                  });
              }}
              disabled={cancelReason === ''}
            >
              确定
            </Button>
          </Box>
        )}
      />
      <ELConfirmDialog
        open={deleteModalIsOpen}
        title="删除邀约"
        desc="确认删除本场邀约吗？该操作无法被撤销，删除本场邀约后将同时取消对应邀约安排。"
        confirmTitile="删除邀约"
        severity="warning"
        onCancel={() => {
          setDeleteModalIsOpen(false);
        }}
        onClose={() => {
          setDeleteModalIsOpen(false);
        }}
        onConfirm={() => {
          const data = { tokens: [token] };
          deleteInterviewRecord(data)
            .then((result) => {
              if (result.data.code === 0) {
                GlobalToast.success('删除邀约成功');
              } else {
                GlobalToast.error('删除邀约失败');
              }
              dispatch({ type: 'REFRESH_DATA' });
            })
            .catch((e) => {
              console.log('deleteInterviewRecord err:', e);
              GlobalToast.error('删除邀约失败');
            });
          setDeleteModalIsOpen(false);
        }}
      />
    </>
  );
}

InterviewArrangmentItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  setHasOpenedArrangmentModal: PropTypes.bool,
  processToken: PropTypes.string,
};

InterviewArrangmentItem.defaultProps = {
  item: {},
  setHasOpenedArrangmentModal: false,
  processToken: '',
};

export default InterviewArrangmentItem;
