import axios from './Api';

export const getApplication = (applicationToken) => {
  if (!applicationToken) return Promise.reject();
  return axios.request({
    url: `/vc1/application/${applicationToken}`,
    method: 'GET',
  });
};

export const getApplicationFlow = (flowToken) => axios.request({
  url: `/applicationFlow/${flowToken}`,
  method: 'GET',
});

export const getApplicationLogs = (applicationToken) => axios.request({
  url: `/vc1/application/${applicationToken}/logs`,
  method: 'GET',
});

export const callApplication = (applicationToken) => axios.request({
  url: `/application/${applicationToken}/call`,
  method: 'POST',
});

export const deleteApplicationInterviewer = (applicationToken, data) => axios.request({
  url: `/application/${applicationToken}/interviewers`,
  method: 'DELETE',
  data,
});

export const editStepAuditStatus = (flowToken, stepToken, data) => axios.request({
  url: `/vc1/applicationFlow/${flowToken}/step/${stepToken}`,
  method: 'POST',
  data,
});

export const assignApplicationToDepartment = (departmentToken, data) => axios.request({
  url: `/application/${departmentToken}/assignDepartment`,
  method: 'POST',
  data,
});

export const getApplicationAITestReport = (applicationToken) => axios.request({
  url: `/application/${applicationToken}/aiTest/testReport`,
  method: 'POST',
});

export const saveApplicationAttachmentInfo = (applicationToken, data) => axios.request({
  url: `/vc1/application/${applicationToken}/attachmentInfo`,
  method: 'POST',
  data,
});

export const refreshApplicationAIReport = (applicationToken) => axios.request({
  url: `/application/${applicationToken}/aiReport`,
  method: 'GET',
});

export const moveApplication = (data) => axios.request({
  url: '/vc1/application/moveTo',
  method: 'POST',
  data,
});

export const rejectApplication = (data) => axios.request({
  url: '/application/reject',
  method: 'POST',
  data,
});

export const cancelRejectApplication = (data) => axios.request({
  url: '/application/cancelReject',
  method: 'POST',
  data,
});

export const cancelApplication = (data) => axios.request({
  url: '/application/cancel',
  method: 'POST',
  data,
});

export const layinApplication = (data) => axios.request({
  url: '/application/layin',
  method: 'POST',
  data,
});

export const changeShowTableHeaders = (positionToken, processToken, data) => axios.request({
  url: `/application/${positionToken}/${processToken}/showTableHeaders`,
  method: 'POST',
  data,
});

export const recoverApplication = (data) => axios.request({
  url: '/application/recover',
  method: 'POST',
  data,
});
// at提醒任务卡片
export const getReminderTaskCard = (data) => axios.request({
  url: '/application/comment/at/card',
  method: 'POST',
  data,
});

/**
   * @description 招聘流程岗位过滤页面
   * @author Jeakin
   * @date 06/05/2021
   * @param {*} data
   */
export const getApplicationPositionFilter = (data) => axios.request({
  url: '/application/position/filter',
  method: 'POST',
  data,
});

/**
   * @description 招聘流程岗位过滤页面
   * @author Jeakin
   * @date 20/05/2021
   * @param {*} data
   */
export const getVc1ApplicationPositionFilter = (data) => axios.request({
  url: '/vc1/application/position/filter',
  method: 'POST',
  data,
});

export const exportApplicationSummary = (data) => axios.request({
  url: '/application/exportWithSummary',
  method: 'POST',
  responseType: 'blob',
  data,
});

export const getApplicationAiReport = (applicationToken, applicationFlowToken) => axios.request({
  url: '/aiReport',
  method: 'POST',
  params: {
    applicationToken, applicationFlowToken,
  },
});
