import Typography from '@components/Avocado/Typography';

function HasNoPermission() {
  return (
    <div style={{
      height: '100%', display: 'flex', justifyContent: 'center', position: 'relative', overflowY: 'hidden',
    }}
    >
      <img
        src="//webcdn.fbmms.cn/img/hasNoPermission/hasNoPermissionBackground.png"
        alt=""
        style={{
          position: 'absolute', top: 0, width: '100%',
        }}
      />
      <div style={{ position: 'relative', textAlign: 'center', top: 200 }}>
        <img src="//webcdn.fbmms.cn/img/hasNoPermission/SafeBro.svg" alt="" style={{ width: '100px' }} />
        <Typography variant="body1" color="text.primary" fontWeight="fontWeightMedium" mt={2}>暂无权限</Typography>
        <Typography variant="body2" color="text.secondary">你没有该职位候选人在本步骤的查看权限</Typography>
      </div>
    </div>
  );
}

export default HasNoPermission;
