/* eslint-disable no-shadow */
import {
  cancelApplication,
  layinApplication,
  moveApplication,
  recoverApplication,
  rejectApplication,
} from '@api/Application';
import * as CodeInterviewsAPI from '@api/CodeInterviews';
import * as ExamsAPI from '@api/Exams';
import { postNotificationCustomMessage, postNotificationMessage } from '@api/Notification';
import useGlobalToast from '@utils/GlobalToast';
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCandidateDispatch, useCandidateState } from '../context';
import useDataLoader from '../useDataLoader';
import ActionButtonsOnly from './ActionButtonsOnly';
import ActionDialogsOnly from './ActionDialogsOnly';

const YI_RU_ZHI_PROCESS_TOKEN = 'UCIkPVNu57gO4fl9ykv0Q956X9';
const YI_LIZHI_PROCESS_TOKEN = 'VjaL28zeAZQTBDSDTijZQ956XY';

export default function ActionButtonsAndDialogs({
  index,
  isActive = false,
  isFailed = false,
  isCanceled = false,
  stage = 1,
}) {
  const GlobalToast = useGlobalToast();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);

  const { loadApplication, loadCandidateApplication } = useDataLoader();
  const dispatch = useCandidateDispatch();
  const state = useCandidateState();
  const { clickedSendButton, applicationData } = state;
  const { applicationState } = applicationData;
  const isLayined = [3, 10].indexOf(applicationState) > -1;

  const [templateFillOpen, setTemplateFillOpen] = useState(false);
  const [template, setTemplate] = useState('');
  const [templateRich, setTemplateRich] = useState('');
  const [variables, setVariables] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState({});
  const [templateSms, setTemplateSms] = useState('');
  const [richSms, setRichSms] = useState('');
  const [variablesSms, setVariablesSms] = useState([]);
  const [templateEmail, setTemplateEmail] = useState('');
  const [richEmail, setRichEmail] = useState('');
  const [variablesEmail, setVariablesEmail] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [mailToAddress, setMailToAddress] = useState('');
  const [candidateTokens, setCandidateTokens] = useState([]);
  const [auditStatus, setAuditStatus] = useState([]);
  const [needFeedbackHidden, setNeedFeedbackHidden] = useState(false);
  const [processStatus, setProcessStatus] = useState('');
  const [isShowCreateDialog, setShowCreateDialog] = useState(false);
  const [isShowCreateCodeInterViewDialog, setShowCreateCodeInterViewDialog] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);

  const openTemplateFill = (
    template,
    templateRich,
    variables,
    templateSms,
    richSms,
    variablesSms,
    templateEmail,
    richEmail,
    variablesEmail,
    emailSubject,
    mailToAddress,
    emailTemplate,
    candidateTokens,
    auditStatus,
    needFeedbackHidden,
  ) => {
    setTemplate(template);
    setTemplateRich(templateRich);
    setVariables(variables);
    setTemplateSms(templateSms);
    setRichSms(richSms);
    setVariablesSms(variablesSms);
    setAuditStatus(auditStatus);
    setEmailTemplate(emailTemplate);
    setTemplateEmail(templateEmail);
    setRichEmail(richEmail);
    setVariablesEmail(variablesEmail);
    setEmailSubject(emailSubject);
    setMailToAddress(mailToAddress);
    setCandidateTokens(candidateTokens);
    setTemplateFillOpen(true);
    setNeedFeedbackHidden(needFeedbackHidden);
  };

  const checkTemplateMsg = async () => {
    setTemplateLoading(true);
    // 判断即将跳转的步骤是否有模板
    const data = {
      applicationTokens: [applicationToken],
    };
    postNotificationMessage(data)
      .then((res) => {
        if (res.data.code === 0) {
          switch (res.data.data.status) {
            case 0: // 没有模板
              break;
            case 1: // 有模板发送成功
              GlobalToast.success('发送提醒成功');
              dispatch({ type: 'SET_CAN_SEND_INVITATION', payload: false });
              break;
            case 2: // 需要用户填写数据
              openTemplateFill(
                res.data.data.messageTemplate.template,
                res.data.data.messageTemplate.rich,
                res.data.data.messageTemplate.variables,
                res.data.data.smsTemplate.template,
                res.data.data.smsTemplate.rich,
                res.data.data.smsTemplate.variables,
                res.data.data.emailTemplate.template,
                res.data.data.emailTemplate.rich,
                res.data.data.emailTemplate.variables,
                res.data.data.emailTemplate.emailSubject,
                res.data.data.emailTemplate.mailToAddress,
                res.data.data.emailTemplate,
                res.data.data.candidateTokens,
                res.data.data.smsTemplate.auditStatus, // 1审核中 2 审核通过 3 审核拒绝
                !res.data.data.hasMailFeedback,
              );
              break;
            default:
              break;
          }
        } else {
          GlobalToast.error('发送提醒失败');
        }
      })
      .catch(() => {
        GlobalToast.error('发送提醒失败');
      })
      .finally(() => {
        setTemplateLoading(false);
      });
  };

  const createWritten = (data) => {
    ExamsAPI.addBatchExams({ ...data, applicationTokens: [applicationToken] })
      .then((v) => {
        if (v.data.code === 0) {
          console.log(v);
        } else {
          GlobalToast.error('创建失败');
        }
        checkTemplateMsg();
      })
      .catch(console.log);
  };

  const createCodeInterViews = useCallback(
    (data) => {
      CodeInterviewsAPI.createCodeInterivews([applicationToken], data.planTime)
        .then((v) => {
          if (v.data.code === 0) {
            console.log(v);
          } else {
            GlobalToast.error('创建失败');
          }
          checkTemplateMsg();
        })
        .catch(console.log);
    },
    [applicationToken],
  );

  const rejectInterview = (reason) => {
    const data = {
      applicationTokens: [applicationToken],
      reason,
    };
    rejectApplication(data)
      .then((result) => {
        if (result.data.code === 0) {
          GlobalToast.success('淘汰成功');
          checkTemplateMsg();
          loadApplication(applicationToken);
          loadCandidateApplication(candidateToken, applicationToken);
        } else {
          GlobalToast.error('淘汰失败');
        }
      })
      .catch(() => {
        GlobalToast.error('淘汰失败');
      });
  };

  const handleLayinApplication = (reason) => {
    const data = {
      tokens: [applicationToken],
      reason,
    };
    layinApplication(data)
      .then((result) => {
        if (result.data.code === 0) {
          GlobalToast.success('加入成功');
          checkTemplateMsg();
          loadApplication(applicationToken);
          loadCandidateApplication(candidateToken, applicationToken);
        } else if (result.data.code === -6002) {
          const { message } = result.data;
          dispatch({
            type: 'SET_ALERT_DIALOG',
            payload: { desc: message },
          });
        } else {
          GlobalToast.error('加入失败');
        }
      })
      .catch(() => {
        GlobalToast.error('加入失败');
      });
  };

  const closeTemplateFill = () => {
    setTemplateFillOpen(false);
  };

  const confirmNotifyMsg = async (msg, msgSms, emailTemplate, emailSubject, attachments, feedbackSwitch) => {
    const data = {
      applicationTokens: [applicationToken],
      messageTemplate: msg,
      smsTemplate: msgSms,
      emailTemplate: {
        template: emailTemplate,
        subject: emailSubject,
        attachments,
        feedbackSwitch,
      },
    };
    postNotificationCustomMessage(data).then((result) => {
      if (result.data.code === 0) {
        GlobalToast.success('发送提醒成功');
        dispatch({ type: 'SET_CAN_SEND_INVITATION', payload: false });
      } else {
        GlobalToast.error('发送提醒失败');
      }
    });
    setTemplateFillOpen(false);
  };

  const sureMoveToStep = async (_currentProcessToken, type, reason) => {
    // 以下是实际跳转的逻辑
    const data = {
      applicationTokens: [applicationToken],
      processToken: _currentProcessToken,
      processStatus,
    };
    if (reason) data.reason = reason;
    moveApplication(data)
      .then((result) => {
        if (result.data.code === 0) {
          if (type === 6 && result.data.data.assignExam) {
            setShowCreateCodeInterViewDialog(true);
          } else if (type === 7 && result.data.data.assignExam) {
            setShowCreateDialog(true);
          } else {
            if (_currentProcessToken === YI_RU_ZHI_PROCESS_TOKEN) {
              GlobalToast.success('入职成功');
            }
            if (_currentProcessToken === YI_LIZHI_PROCESS_TOKEN) {
              GlobalToast.success('离职成功');
            }
            checkTemplateMsg();
          }
          loadApplication(applicationToken);
          loadCandidateApplication(candidateToken, applicationToken);
        } else if (result.data.code === -5020) {
          const { departmentName, positionName } = result.data.data;
          const html = `入职部门: ${departmentName}<br />入职职位：${positionName}<br />如有需求可以和相关部门争取。`;
          dispatch({
            type: 'SET_ALERT_DIALOG',
            // eslint-disable-next-line react/no-danger
            payload: { title: '该候选人已入职其他职位', desc: <div dangerouslySetInnerHTML={{ __html: html }} /> },
          });
        } else if (result.data.code === -6002) {
          const { message } = result.data;
          dispatch({
            type: 'SET_ALERT_DIALOG',
            payload: { desc: message },
          });
        } else {
          if (_currentProcessToken === YI_RU_ZHI_PROCESS_TOKEN) {
            GlobalToast.success('入职失败');
          }
          if (_currentProcessToken === YI_LIZHI_PROCESS_TOKEN) {
            GlobalToast.success('离职失败');
          }
        }
      })
      .catch(() => {
        GlobalToast.error('操作失败');
      });
  };

  const handleRecoverApplication = (state) => {
    const data = { tokens: [applicationToken] };
    recoverApplication(data)
      .then((result) => {
        if (result.data.code === 0) {
          let text = '撤回成功';
          switch (
            state // 2:取消 3:储备 4:已入职 5:已离职 6:已淘汰
          ) {
            case 2:
              text = '撤回取消流程成功';
              break;
            case 3:
              text = '撤回储备成功';
              break;
            case 4:
              text = '撤回入职成功';
              break;
            case 5:
              text = '撤回离职成功';
              break;
            case 6:
              text = '撤回淘汰成功';
              break;
            default:
          }
          GlobalToast.success(text);
          checkTemplateMsg();
          loadApplication(applicationToken);
          loadCandidateApplication(candidateToken, applicationToken);
        }
        if (result.data.code === -5019) {
          dispatch({
            type: 'SET_ALERT_DIALOG',
            payload: { desc: result.data.message },
          });
        }
      })
      .catch(() => {
        GlobalToast.error('撤回失败');
      });
  };

  function handleCancelApplication() {
    const data = { tokens: [applicationToken] };
    cancelApplication(data)
      .then((result) => {
        if (result.data.code === 0) {
          GlobalToast.success('取消流程成功');
          loadApplication(applicationToken);
          loadCandidateApplication(candidateToken, applicationToken);
        } else {
          GlobalToast.error('取消流程失败');
        }
      })
      .catch(() => {
        GlobalToast.error('取消流程失败');
      });
  }

  useEffect(() => {
    if (isActive && clickedSendButton !== 0) {
      checkTemplateMsg();
      dispatch({ type: 'RESET_SEND_BUTTON' });
    }
  }, [clickedSendButton, isActive]);

  return (
    <>
      <ActionButtonsOnly
        index={index}
        stage={stage}
        isActive={isActive}
        isLayined={isLayined}
        isCanceled={isCanceled}
        isFailed={isFailed}
        sureMoveToStep={sureMoveToStep}
        handleRecoverApplication={handleRecoverApplication}
        handleLayinApplication={handleLayinApplication}
        checkTemplateMsg={checkTemplateMsg}
        handleCancelApplication={handleCancelApplication}
        setProcessStatus={setProcessStatus}
        rejectInterview={rejectInterview}
      />
      <ActionDialogsOnly
        isShowCreateDialog={isShowCreateDialog}
        isShowCreateCodeInterViewDialog={isShowCreateCodeInterViewDialog}
        templateFillOpen={templateFillOpen}
        templateLoading={templateLoading}
        emailTemplate={emailTemplate}
        template={template}
        templateRich={templateRich}
        variables={variables}
        templateSms={templateSms}
        richSms={richSms}
        variablesSms={variablesSms}
        templateEmail={templateEmail}
        richEmail={richEmail}
        variablesEmail={variablesEmail}
        emailSubject={emailSubject}
        mailToAddress={mailToAddress}
        candidateTokens={candidateTokens}
        auditStatus={auditStatus}
        needFeedbackHidden={needFeedbackHidden}
        setShowCreateDialog={setShowCreateDialog}
        setShowCreateCodeInterViewDialog={setShowCreateCodeInterViewDialog}
        createWritten={createWritten}
        createCodeInterViews={createCodeInterViews}
        closeTemplateFill={closeTemplateFill}
        confirmNotifyMsg={confirmNotifyMsg}
      />
    </>
  );
}

ActionButtonsAndDialogs.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool,
  isCanceled: PropTypes.bool,
  stage: PropTypes.number.isRequired,
};

ActionButtonsAndDialogs.defaultProps = {
  isFailed: false,
  isCanceled: false,
};
