import { InfoIcon } from '@avocadoui/icons';
import Chip from '@components/Avocado/Chip';
import { Tooltip, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

const InterviewersSelect = ({ interviewers, handleRemoveInertviewer }) => {
  const onDeleteInterviewer = useCallback(
    (token) => () => handleRemoveInertviewer(token),
    [interviewers],
  );
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          将要提醒的企业参与成员
        </Typography>
        <Tooltip title="每场邀约仅能给候选人发送一条提醒/天" arrow>
          <Box ml="6px" display="flex" alignItems="center">
            <InfoIcon fontSize="small" htmlColor="rgb(188, 188, 188)" style={{ width: 18, height: 18 }} />
          </Box>
        </Tooltip>
      </Box>
      <Box mt={2} maxHeight="98px">
        {interviewers.map((v) => (
          <Chip
            key={v.token}
            name={v.token}
            mr={1}
            mb={1.5}
            variant="outlined"
            customSize="sizeMedium"
            color="primary"
            label={v.name}
            onDelete={onDeleteInterviewer(v.token)}
          />
        ))}
      </Box>
    </Box>
  );
};

InterviewersSelect.propTypes = {
  handleRemoveInertviewer: PropTypes.func,
  interviewers: PropTypes.arrayOf(PropTypes.object),
};
InterviewersSelect.defaultProps = {
  handleRemoveInertviewer: null,
  interviewers: [],
};

export default InterviewersSelect;
