import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const NotFound = (props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <img src="//public-static-assets.oss-cn-beijing.aliyuncs.com/img/404.png" alt="" />
    </div>
  );
};

NotFound.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(useStyles)(NotFound);
