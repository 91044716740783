import axios from './Api';

export const getCurrentUserInfo = () => axios.request({
  url: '/loginUserInfo',
  method: 'GET',
});
export const getLoginUser = () => axios.request({
  url: '/loginUserInfo',
  method: 'GET',
});

export const getUserkickout = () => axios.request({
  url: '/kickout/notify',
  method: 'GET',
});

export const editUserInfo = (data) => axios.request({
  url: '/userInfo',
  method: 'PATCH',
  data: { data },
});

export const uploadAvater = (file) => axios.request({
  url: '/user/avatar/upload',
  method: 'POST',
  data: file,
});

export const editPassword = (file) => axios.request({
  url: '/reset/password/account',
  method: 'POST',
  data: file,
});

export const sendBindMobileCode = (mobile) => axios.request({
  url: '/moblie/bind/sendSmsCode',
  method: 'POST',
  data: { mobile },
});
export const sendVerifyMobileCode = (mobile) => axios.request({
  url: '/sms/verifyCode',
  method: 'POST',
  data: { event: 5, mobile },
});

export const bindMobileOriginalVerify = (data) => axios.request({
  url: '/reset/mobile/change/verify',
  method: 'POST',
  data,
});

export const bindMobileVerify = (data) => axios.request({
  url: '/reset/mobile/mobile',
  method: 'POST',
  data,
});

export const bindMobile = (data) => axios.request({
  url: '/account/mobileBind',
  method: 'POST',
  data,
});

export const unbindThirdparty = (platform) => axios.request({
  url: `/account/unBind?platform=${platform}`,
  method: 'DELETE',
});

export const departmentSwitch = (data) => axios.request({
  url: '/departmentSwitch',
  method: 'POST',
  data,
});

export const getDepartmentUsers = (applicationToken, keyword) => axios.request({
  url: '/admin/users',
  method: 'GET',
  data: {
    applicationToken,
    s: keyword,
  },
});

export const getDepartmentUser = (token) => axios.request({
  url: `/admin/user/${token}`,
  method: 'GET',
});

export const delDepartmentUsers = (tokens) => axios.request({
  url: '/admin/user',
  method: 'DELETE',
  data: { tokens },
});

export const editDepartmentUser = (token, data) => axios.request({
  url: `/admin/user/${token}`,
  method: 'PATCH',
  data: { data },
});

export const editDepartmentUserDepartment = (token, departmentTokens) => axios.request({
  url: `/admin/user/${token}/departments`,
  method: 'POST',
  data: { departmentTokens },
});

export const setUserkickout = (confirmToken) => axios.request({
  url: 'kickout/confirm',
  method: 'POST',
  data: { confirmToken },
});

export const setUserLogout = () => axios.request({
  url: 'loginout',
  method: 'GET',
});

export const getGuideInfo = () => axios.request({
  url: '/guide/department',
  method: 'GET',
});

export const getRtmToken = (params) => axios.request({
  url: '/user/getRtmToken',
  method: 'GET',
  params,
});
