import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const InterviewerUserChip = withStyles({
  root: {
    width: 51,
    height: 18,
    borderRadius: 9,
    backgroundColor: 'transparent',
    cursor: 'inherit',
  },
  outlined: {
    border: '1px solid #9C27B0FF',
  },
  label: {
    padding: 0,
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#9C27B0FF',
  },
})(Chip);

const EnterpriseUserChip = withStyles({
  root: {
    width: 36,
    height: 18,
    borderRadius: 9,
    backgroundColor: 'transparent',
    cursor: 'inherit',
  },
  outlined: {
    border: '1px solid #FD8C17',
  },
  label: {
    padding: 0,
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#F5A623',
  },
})(Chip);

const StationUserChip = withStyles({
  root: {
    width: 36,
    height: 18,
    borderRadius: 9,
    backgroundColor: 'transparent',
    cursor: 'inherit',
  },
  outlined: {
    border: '1px solid #00BCB8',
  },
  label: {
    padding: 0,
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#00BCB8',
  },
})(Chip);

const MemberUserChip = withStyles({
  root: {
    width: 36,
    height: 18,
    backgroundColor: 'transparent',
    cursor: 'inherit',
  },
  outlined: {
    borderRadius: 9,
    border: '1px solid #5072FF',
  },
  label: {
    padding: 0,
    // height: 16,
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#5072FF',
  },
})(Chip);

const AgentUserChip = withStyles({
  root: {
    height: 18,
    backgroundColor: 'transparent',
    cursor: 'inherit',
  },
  outlined: {
    borderRadius: 9,
    border: '1px solid #5072FF',
  },
  label: {
    padding: ' 0 5px',
    // height: 16,
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#5072FF',
  },
})(Chip);

const ExtendUserChip = withStyles({
  root: {
    height: 18,
    backgroundColor: 'transparent',
    cursor: 'inherit',
  },
  outlined: {
    borderRadius: 9,
    border: '1px solid #FFBD2B',
  },
  label: {
    padding: ' 0 5px',
    // height: 16,
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#FFBD2B',
  },
})(Chip);

const UserTitleChip = ({ role }) => {
  if (role === 1) {
    return <AgentUserChip label="管理员" variant="outlined" />;
  }
  if (role === 2) {
    return <EnterpriseUserChip label="企业" variant="outlined" />;
  }
  if (role === 3) {
    return <MemberUserChip label="普通" variant="outlined" />;
  }
  if (role === 4) {
    return <StationUserChip label="站长" variant="outlined" />;
  }
  if (role === 5) {
    return <InterviewerUserChip label="面试官" variant="outlined" />;
  }
  if (role === 11) {
    return <ExtendUserChip label="外部" variant="outlined" />;
  }
  return <></>;
};

UserTitleChip.propTypes = {
  role: PropTypes.number,
};

UserTitleChip.defaultProps = {
  role: 3,
};

export default UserTitleChip;
