import { addCandidateToPosition } from '@api/Position';
import { DoneIcon } from '@avocadoui/icons';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { setApplicationToken } from '@redux/candidateModal/action';
import useGlobalToast from '@utils/GlobalToast';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';
import styles from './CandidateAssignablePositions.module.css';
import { useCandidateDispatch, useCandidateState } from './context';
import { setUrlQuery } from './utils';

export default function CandidateAssignablePositions() {
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const [selectedPositionToken, setSelectedPositionToken] = useState('');
  const {
    candidateAssignablePositionsData,
    candidateApplicationData,
    applicationFlowData,
    applicationData,
  } = useCandidateState();
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const dispatch = useCandidateDispatch();
  const GlobalToast = useGlobalToast();
  const location = useLocation();
  const isAtApplicationListRoute = useRouteMatch('/dashboard/processReview');

  function handleGoToAddPostion() {
    history.push('/dashboard/processesList');
  }

  function handleSelectPosition(positionToken) {
    setSelectedPositionToken(positionToken);
  }

  function handleCancel() {
    dispatch({ type: 'SET_IS_SHOW_ASSIGNABLE_POSITIONS_LIST', payload: false });

    const notAssigned = candidateApplicationData.flows == null
      || (candidateApplicationData.flows && candidateApplicationData.flows.length === 0);
    const hasVideoSteps = applicationFlowData.steps && applicationFlowData.steps.length > 0;
    const hasAIResults = applicationData.aiReportResult && applicationData.aiReportResult.status !== 0;
    const hasStarted = hasVideoSteps || hasAIResults;

    if (notAssigned) {
      dispatch({ type: 'SET_TAB_NAME', payload: 'no_position' });
    } else if (!notAssigned && !hasStarted) {
      dispatch({ type: 'SET_TAB_NAME', payload: 'no_interview' });
    } else {
      dispatch({ type: 'SET_TAB_NAME', payload: 'result' });
    }
  }

  function handleAssignPosition() {
    const data = {
      candidateTokens: [candidateToken],
    };
    addCandidateToPosition(selectedPositionToken, data).then((result) => {
      if (result.data.code === 0) {
        const { successCount = 0, failedCount = 0 } = result.data.data || {};
        if (failedCount) {
          GlobalToast.warning(`${successCount}名候选人分配成功，${failedCount}名候选人分配失败`);
        } else {
          GlobalToast.success('分配职位成功');
        }
        handleCancel();
        const applicationToken = result.data.data && result.data.data[0] && result.data.data[0].applicationToken;
        if (applicationToken) {
          if (isAtApplicationListRoute) {
            const query = setUrlQuery(location, { candidate: candidateToken, application: applicationToken });
            history.push(`${location.pathname}?${query.toString()}`);
          }
          dispatchRedux(setApplicationToken(applicationToken));
        }
      } else if (result.data.code === -4001) {
        GlobalToast.error('暂无权限，申请开通请联系管理员');
      } else {
        GlobalToast.error('分配职位失败');
      }
    }).catch(() => {
      GlobalToast.error('分配职位失败');
    });
  }

  return (
    <div className={styles.wrapper}>
      {candidateAssignablePositionsData.length > 0 && (
        <div className={styles.header}>
          <div>请选择待分配的职位面试</div>
          <Box display="flex">
            <Box>
              <Button onClick={handleCancel}>取消</Button>
            </Box>
            <Box ml="8px">
              <Button
                color="primary"
                variant="contained"
                disabled={!selectedPositionToken}
                onClick={handleAssignPosition}
              >
                确认
              </Button>
            </Box>
          </Box>
        </div>
      )}
      <div>
        {candidateAssignablePositionsData.length === 0 && (
          <Box display="flex" flexDirection="column" alignItems="center" marginTop="72px">
            <Box>
              <img
                alt="添加职位 - 引导图"
                src="//public-static-assets.oss-cn-beijing.aliyuncs.com/img/how_to_add_position.png"
                style={{ maxWidth: '537px' }}
              />
            </Box>
            <Box mt="24px">
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                您目前暂无职位可安排
              </Typography>
            </Box>
            <Box mt="2px">
              <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
                您可以在职位管理页面添加职位
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
                后续可在此处给候选人安排相应的职位
              </Typography>
            </Box>
            <Box mt="16px">
              <Button size="large" color="primary" variant="contained" onClick={handleGoToAddPostion}>
                添加职位
              </Button>
            </Box>
          </Box>
        )}
        {candidateAssignablePositionsData.map((v) => (
          <div
            role="button"
            key={v.positionToken}
            className={v.positionToken === selectedPositionToken ? `${styles.item} ${styles.active}` : styles.item}
            onClick={() => handleSelectPosition(v.positionToken)}
          >
            <div>
              <Typography
                variant="body2"
                color={v.positionToken === selectedPositionToken ? 'primary' : ''}
                style={v.positionToken === selectedPositionToken ? { fontWeight: 500 } : {}}
              >
                {v.positionName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {v.departmentName}
              </Typography>
            </div>
            <div className={styles.positionID}>
              {v.positionToken === selectedPositionToken && <DoneIcon color="primary" />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
