import { handleActions } from 'redux-actions';
import {
  initRoleModule, setRoles, setRolesPage, setShowRoleDrawer,
} from './action';

export const namespace = 'role';

export const defaultState = {
  isShowRoleDrawer: false,
  total: 0,
  roles: [],
  filters: {
    page: 1,
    pageSize: 30,
  },
};
export const roleReducer = handleActions(
  {
    [initRoleModule]: (state) => ({
      ...state,
      filters: {
        page: 1,
        pageSize: 30,
      },
    }),
    [setRoles]: (state, action) => {
      const { roles, total } = action.payload;
      return { ...state, roles, total };
    },
    [setShowRoleDrawer]: (state, action) => {
      const isShowRoleDrawer = action.payload;
      return { ...state, isShowRoleDrawer };
    },
    [setRolesPage]: (state, action) => {
      const page = action.payload;
      return { ...state, filters: { ...state.filters, page } };
    },
  },
  defaultState,
);
