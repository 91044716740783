import { patchCandidate } from '@api/Candidate';
import { uploadResume as uploadResumeApi } from '@api/Resume';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as UpdateDashboardSwitchActions from '@reduxold/componentSwitch';
import { CANDIDATE_RESUME_UPLOAD_FILE_TYPE } from '@utils/constants';
import useGlobalToast from '@utils/GlobalToast';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import useDataLoader from '../useDataLoader';

function UploadResume(props) {
  const { button } = props;
  const [isLoading, setLoading] = useState(false);
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const { loadCandidate } = useDataLoader();
  const GlobalToast = useGlobalToast();

  function uploadResume(file) {
    const formData = new FormData();
    formData.append('file', file);
    uploadResumeApi(candidateToken, formData, () => {}).then((result) => {
      if (result.data.code === 0) {
        const data = {
          field: 'resume',
          value: result.data?.data?.resumeUrl,
        };
        patchCandidate(candidateToken, data).then((result2) => {
          if (result2.data.code === 0) {
            GlobalToast.success('简历上传成功');
            setLoading(false);
            loadCandidate(candidateToken);
          } else {
            GlobalToast.error('简历上传失败');
          }
        });
      }
    }).catch(() => {
      GlobalToast.error('简历上传失败');
    });
  }

  function handleChange(e) {
    setLoading(true);
    const file = e.target.files[0];
    if (file.size) {
      if (file.size > 9961472) {
        props.updateDashboardSwitchActions.updateSnackbarErrorMessageSwitch('上传文件不能超过10M！');
        props.updateDashboardSwitchActions.updateSnackbarOpenSwitch(true);
        return;
      }
    }
    uploadResume(file);
  }

  if (isLoading) {
    return <CircularProgress size="14px" />;
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label style={{ cursor: 'pointer' }}>
      <input
        type="file"
        name="file"
        accept={CANDIDATE_RESUME_UPLOAD_FILE_TYPE}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      {button}
    </label>
  );
}

UploadResume.propTypes = {
  button: PropTypes.element.isRequired,
  updateDashboardSwitchActions: PropTypes.objectOf(PropTypes.any),
};

UploadResume.defaultProps = {
  updateDashboardSwitchActions: {},
};

export default connect(
  () => ({}),
  (dispatch) => ({
    updateDashboardSwitchActions: bindActionCreators(UpdateDashboardSwitchActions, dispatch),
  }),
)(UploadResume);
