/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import candidates from '../v1/reducers/candidates';
import candidatesStatistical from '../v1/reducers/candidatesStatistical';
import componentSwitch from '../v1/reducers/componentSwitch';
import hrList from '../v1/reducers/hrList';
import managedata from '../v1/reducers/managedata';
import patterns from '../v1/reducers/patterns';
import processes from '../v1/reducers/processes';
import processStatistical from '../v1/reducers/processesStatistical';
import resumeType from '../v1/reducers/resumetype';
import reviewList from '../v1/reducers/reviewList';
import { accountReducer, defaultState as accountState, namespace as accountNameSpace } from './modules/account/reducer';
import accountSaga from './modules/account/saga';
import {
  atmessageReducer,
  defaultState as atmessageState,
  namespace as atmessageNameSpace,
} from './modules/atmessage/reducer';
import atmessageSaga from './modules/atmessage/saga';
import {
  defaultState as candidateModalState,
  namespace as candidateModalNamespace,
  reducer as candidateModalReducer,
} from './modules/candidateModal/reducer';
import {
  commentFormReducer,
  defaultState as commentFormState,
  namespace as commentFormNameSpace,
} from './modules/commentForm/reducer';
import commentFormSaga from './modules/commentForm/saga';
// 其他需要异步
import { commonReducer, defaultState, namespace as commonNamespace } from './modules/common/reducer';
import {
  defaultState as interviewArrangementState,
  interviewArrangementReducer,
  namespace as interviewArrangementNameSpace,
} from './modules/interviewArrangement/reducer';
import { defaultState as processDefault, namespace as processSpace, processReducer } from './modules/process/reducer';
import processSaga from './modules/process/saga';
import {
  defaultState as processReviewState,
  namespace as processReviewNameSpace,
  processReviewReducer,
} from './modules/processReview/reducer';
import processReviewSaga from './modules/processReview/saga';
import { defaultState as roleState, namespace as roleNameSpace, roleReducer } from './modules/role/reducer';
import roleSaga from './modules/role/saga';
import {
  defaultState as searchState,
  namespace as searchNamespace,
  reducer as searchReducer,
} from './modules/search/reducer';
import { defaultState as settingState, namespace as settingNameSpace, settingReducer } from './modules/setting/reducer';
import settingSaga from './modules/setting/saga';
import { defaultState as talentState, namespace as talentNameSpace, talentReducer } from './modules/talent/reducer';
import talentSaga from './modules/talent/saga';
import themeSaga from './modules/theme/saga';
import {
  defaultState as toastState,
  namespace as toastNameSpace,
  toastReducer,
} from './modules/toast/reducer';

export { commonReducer, namespace as commonNamespace } from './modules/common/reducer';

const appReducer = combineReducers({
  [accountNameSpace]: accountReducer,
  [commonNamespace]: commonReducer,
  // [themeNameSpace]: themeReducer,
  // [loginNameSpace]: loginReducer,
  [talentNameSpace]: talentReducer,
  [atmessageNameSpace]: atmessageReducer,
  [processSpace]: processReducer,
  [processReviewNameSpace]: processReviewReducer,
  [settingNameSpace]: settingReducer,
  [commentFormNameSpace]: commentFormReducer,
  [interviewArrangementNameSpace]: interviewArrangementReducer,
  [searchNamespace]: searchReducer,
  [candidateModalNamespace]: candidateModalReducer,
  [toastNameSpace]: toastReducer,
  [roleNameSpace]: roleReducer,

  hrList,
  candidates,
  processes,
  reviewList,
  patterns,
  componentSwitch,
  candidatesStatistical,
  processStatistical,
  resumeType,
  managedata,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([
    ...accountSaga,
    ...themeSaga,
    ...processSaga,
    ...processReviewSaga,
    ...settingSaga,
    ...commentFormSaga,
    ...atmessageSaga,
    ...talentSaga,
    ...roleSaga,
  ]);
}

export const sagaMiddleware = createSagaMiddleware();

export default (receivedState) => {
  const initialState = receivedState;
  initialState[accountNameSpace] = {
    ...accountState,
    ...initialState[accountNameSpace],
  };

  initialState[talentNameSpace] = {
    ...talentState,
    ...initialState[talentNameSpace],
  };

  initialState[commonNamespace] = {
    ...defaultState,
    ...initialState[commonNamespace],
  };

  initialState[settingNameSpace] = {
    ...settingState,
    ...initialState[settingNameSpace],
  };

  initialState[commentFormNameSpace] = {
    ...commentFormState,
    ...initialState[commentFormNameSpace],
  };

  initialState[processReviewNameSpace] = {
    ...processReviewState,
    ...initialState[processReviewNameSpace],
  };

  initialState[atmessageNameSpace] = {
    ...atmessageState,
    ...initialState[atmessageNameSpace],
  };

  initialState[processSpace] = {
    ...processDefault,
    ...initialState[processSpace],
  };

  initialState[interviewArrangementNameSpace] = {
    ...interviewArrangementState,
    ...initialState[interviewArrangementNameSpace],
  };

  initialState[searchNamespace] = {
    ...searchState,
    ...initialState[searchNamespace],
  };

  initialState[candidateModalNamespace] = {
    ...candidateModalState,
    ...initialState[candidateModalNamespace],
  };
  initialState[toastNameSpace] = {
    ...toastState,
    ...initialState[toastNameSpace],
  };

  initialState[roleNameSpace] = {
    ...roleState,
    ...initialState[roleNameSpace],
  };

  // 浏览器redux查看插件
  const reduxDebug = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
    : (f) => f;

  const store = createStore(rootReducer, initialState, compose(applyMiddleware(sagaMiddleware), reduxDebug));
  return store;
};
