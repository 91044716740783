import axios from './Api';

export const rejectReasonsProcess = (keyword) => axios.request({
  url: '/application/comment/rejectReasons',
  method: 'GET',
  params: { reason: keyword },
});

export const addProcess = (data) => axios.request({
  url: '/process',
  method: 'POST',
  data,
});

export const getProcess = (token) => axios.request({
  url: `/process/${token}`,
  method: 'GET',
});

export const getProcessEditURL = () => axios.request({
  url: '/process/editUrl',
  method: 'GET',
});

export const editProcess = (token, data) => axios.request({
  url: `/process/${token}`,
  method: 'PUT',
  data,
});

export const getPositionProcess = (sessionToken) => axios.request({
  url: '/vc1/applicationSession/detailStat',
  method: 'GET',
  params: { sessionToken },
});

export const addAppointmentProcess = (data) => axios.request({
  url: '/customInterview/process',
  method: 'POST',
  data,
});

export const editAppointmentProcess = (token, data) => axios.request({
  url: `/customInterview/process/${token}`,
  method: 'PUT',
  data,
});

export const getAppointmentProcess = (patternToken) => axios.request({
  url: `/customInterview/process/${patternToken}`,
  method: 'GET',
});

export const getPositionAndProcess = () => axios.request({
  url: '/inbox/notifyTemplate/positions',
  method: 'GET',
});

export const postProcessStatus = (processToken, applicationTokens, processStatus) => axios.request({
  url: `/application/${processToken}/status`,
  method: 'POST',
  data: { applicationTokens, processStatus },
});

export const deleteComment = (token) => axios.request({
  url: `/application/comment/${token}`,
  method: 'DELETE',
});

export default {};
