import * as UserAPI from '@api/User';
import UserPopover from '@components/Header/UserPopover';
import Inbox from '@components/Inbox';
import InterviewArrangementOpenButton from '@components/InterviewArrangement/OpenButton';
import KickoutNotify from '@components/KickoutNotify';
import UserDartmentManager from '@components/UserDartmentManager/UserDartmentManager';
import { Avatar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { RANDOM_COLOR } from '@utils/constants';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Eli from '../../assets/img/eli.png';
import { ReactComponent as AppBarNotice } from '../../assets/img/icons/appBar/AppBarNotice.svg';
import { ReactComponent as DropdownMenu } from '../../assets/img/icons/DropdownMenu.svg';
import GlobalSearch from '../../v1/components/units/components/globalSearch';

const UserAvatar = ({ user, onClick }) => (
  <Avatar
    style={{
      width: 28,
      height: 28,
      cursor: 'pointer',
      backgroundColor: RANDOM_COLOR[user?.name?.charCodeAt(0) % RANDOM_COLOR.length],
    }}
    src={user.avatar}
    onClick={onClick}
  >
    {user?.name?.charAt(user.name.length - 1)}
  </Avatar>
);

UserAvatar.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired,
};

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // const [offLineWarning, setOffLineWarning] = useState(false);
  const [showDepartmentPopover, setShowDepartmentPopover] = useState(false);

  const {
    userInfo: { user = {}, selectedDepartmentTokens = [], departments = [] },
  } = useSelector((state) => state.account);

  const { messageListUnReadCount = 0 } = useSelector((state) => state.atmessage);
  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const showUserPopover = useCallback((e) => {
    setAvatarAnchorEl(e.currentTarget);
  }, []);
  const hideUserPopover = useCallback(() => {
    setAvatarAnchorEl(null);
  }, []);

  const goUserProfile = useCallback(() => {
    history.replace('/layout/profile');
    setAvatarAnchorEl(null);
  }, []);

  const goInterview = useCallback(() => {
    history.replace('/layout/interview');
  }, []);

  const userLogout = useCallback(() => {
    UserAPI.setUserLogout().then(console.log);
    localStorage.clear();
    sessionStorage.clear();
    history.replace('/layout/login');
  });

  useEffect(() => {
    UserAPI.getLoginUser()
      .then((v) => {
        if (v.data.code === 0) {
          dispatch({ type: 'SET_LOGIN', payload: { info: v.data.data } });
        } else if (v.data.code === -3001) {
          userLogout();
        }
      })
      .catch(console.log);
  }, []);

  const departmentSwitch = useCallback((token = '') => {
    UserAPI.departmentSwitch({ tokens: token })
      .then((v) => {
        if (v.data.code === 0) {
          history.go(0);
        }
      })
      .catch(console.log);
  }, []);

  const selectedAllDepartments = useCallback(() => {
    UserAPI.departmentSwitch({ tokens: departments.map((v) => v.token) })
      .then((v) => {
        if (v.data.code === 0) {
          history.go(0);
        }
      })
      .catch(console.log);
  }, [departmentSwitch]);

  const selectedDepartment = useMemo(() => {
    if (departments.length !== 1 && departments.length === selectedDepartmentTokens.length) {
      return { name: '所有分部', token: '' };
    }
    const department = departments.find((v) => v.token === selectedDepartmentTokens[0]);
    return department !== -1 ? department : {};
  }, [selectedDepartmentTokens]);

  const isHasMultipleDepartment = useMemo(() => departments.length > 1, [departments]);

  return (
    <Box bgcolor="white" borderBottom="1px solid #f4f4f4">
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        style={{ boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.02)', borderBottom: '1px solid #f4f4f4' }}
      >
        <Toolbar display="flex" style={{ minHeight: 62, paddingLeft: 12 }}>
          <Box display="flex" alignItems="center" fontWeight={500} width="300px">
            <img
              src={Eli}
              style={{ height: '32px', objectFit: 'cover' }}
              alt=""
            />
            <Box mr={2} flexShrink={0} />
            <Divider orientation="vertical" style={{ height: 16 }} />
            <Box mr={2} />
            {isHasMultipleDepartment && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  cursor: 'pointer',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 0,
                  padding: '12px 8px',
                }}
                onClick={() => {
                  setShowDepartmentPopover(true);
                }}
              >
                {selectedDepartment?.name}
                <DropdownMenu className="MuiSvgIcon-root" />
              </Typography>
            )}
            {!isHasMultipleDepartment && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  cursor: 'default',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 0,
                  padding: '12px 8px',
                }}
              >
                {selectedDepartment?.name}
              </Typography>
            )}
            {showDepartmentPopover && (
              <ClickAwayListener
                onClickAway={() => {
                  setShowDepartmentPopover(false);
                }}
              >
                <Box
                  style={{
                    position: 'fixed',
                    width: 358,
                    height: '100%',
                    top: 50,
                    left: 0,
                    zIndex: 1203,
                    boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.16)',
                    border: '1px solid rgba(237,237,237,1)',
                    boxSizing: 'border-box',
                    background: 'white',
                  }}
                >
                  <UserDartmentManager
                    onDartpmentAllClick={selectedAllDepartments}
                    onDartpmentClick={departmentSwitch}
                    selectedDepartmentTokens={selectedDepartmentTokens}
                    onCreateDartpmentClick={() => {
                      setShowDepartmentPopover(false);
                    }}
                  />
                </Box>
              </ClickAwayListener>
            )}
          </Box>
          <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
            <GlobalSearch />
          </Box>
          <Box display="flex" alignItems="center" width="300px" justifyContent="flex-end">
            <Box onClick={goInterview} flexShrink={0}>
              <InterviewArrangementOpenButton />
            </Box>
            <Box mx={3} flexShrink={0} width={28} height={28}>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <Badge badgeContent={messageListUnReadCount} color="secondary">
                      <AppBarNotice
                        className="MuiSvgIcon-root"
                        style={{
                          cursor: 'pointer', color: '#707070', width: 28, height: 28,
                        }}
                        {...bindTrigger(popupState)}
                      />
                    </Badge>
                    <Popover
                      {...bindPopover(popupState)}
                      variant="menu"
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      <Inbox />
                    </Popover>
                  </>
                )}
              </PopupState>
            </Box>
            <UserAvatar user={user} onClick={showUserPopover} />
            <UserPopover
              elevation={8}
              id="avater"
              user={user}
              onUserDetail={goUserProfile}
              open={Boolean(avatarAnchorEl)}
              anchorEl={avatarAnchorEl}
              onClose={hideUserPopover}
              onLogout={userLogout}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <KickoutNotify />
    </Box>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
