import { getApplicationAiReport, getApplicationFlow } from '@api/Application';
import { ArrowDropDownIcon, DoneIcon } from '@avocadoui/icons';
import {
  Box, ListItemText, Popover, Typography,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import moment from '@utils/moment';
import { get, isEmpty, last } from 'lodash';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useCandidateDispatch, useCandidateState } from '../context';
import ListItem from './ListItem';

const VersionTitle = ['上一次AI测评报告(已作废)', '本次AI测评报告'];

const AITabs = ({ title, onClick, className }) => {
  const { applicationData = {} } = useCandidateState();
  const dispatch = useCandidateDispatch();
  const popupState = usePopupState({ variant: 'popover', popupId: 'ai-report-menus' });
  const [selectedToken, setSelectedToken] = useState('');

  const {
    aiReportResult: { versions = [] },
  } = applicationData;

  const [hasVersions, defaultSelectedToken] = useMemo(() => [
    !isEmpty(versions) && versions.length > 1,
    get(last(versions), 'token'),
  ], [versions]);

  useEffect(() => {
    setSelectedToken(defaultSelectedToken);
  }, [defaultSelectedToken]);

  const handleToogleToken = useCallback((token) => {
    const { applicationToken } = applicationData;

    getApplicationFlow(token).then((result2) => {
      if (result2.data.code === 0) {
        dispatch({ type: 'SET_APPLICATION_FLOW_DATA', payload: result2.data.data });
      }
    });

    getApplicationAiReport(applicationToken, token).then((result) => {
      setSelectedToken(token);
      const { code, data } = result.data;
      if (code === 0) {
        dispatch({ type: 'SET_APPLICATION_AI_REPORT', payload: data });
        onClick();
      }
    }).catch(console.log);
  });
  return (
    <Box>
      <Typography
        className={className}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        variant="body2"
        onClick={onClick}
        {...(hasVersions ? bindTrigger(popupState) : {})}
      >
        {title}
        {hasVersions && <ArrowDropDownIcon />}
      </Typography>
      <Popover
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List>
          {versions.map((v, i) => (
            <ListItem
              key={v.token}
              button
              selected={v.token === selectedToken}
              onClick={() => {
                handleToogleToken(v.token);
                popupState.close();
              }}
            >
              <ListItemText
                primary={VersionTitle[i]}
                secondary={(
                  <>
                    <Typography component="span" variant="body2">
                      {moment(parseInt(v.versionTime * 1000, 10)).format('YYYY年MM月DD日 HH:mm')}
                    </Typography>
                  </>
                )}
              />
              <Box ml={8}> {v.token === selectedToken && <DoneIcon color="primary" /> }</Box>
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

AITabs.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default AITabs;
