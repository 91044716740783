import Typography from '@components/Avocado/Typography';
import {
  Box, Button, Divider, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEvaluationDispatch } from './context';
import EvaluationItem from './EvaluationItem';
import InviteInterviewer from './InviteInterviewer';

const useStyle = makeStyles(() => ({
  root: {
    marginTop: '16px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    borderBottom: 'none',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 8px 5px 16px',
    backgroundColor: '#FAFAFA',
  },
}));

export default function EvaluationCard({ card = {} }) {
  const classes = useStyle();
  const {
    tplToken, tplName, hasEvaluation, evaluationToken, applicationEvaluations, score, isScoreOpen,
  } = card;
  const applicationTokens = useSelector((candidateModal) => candidateModal.candidateModal.applicationToken);
  const dispatch = useEvaluationDispatch();

  const [lists, setLists] = useState([]);

  function handleNew(token) {
    dispatch({ type: 'SET_SELECTED_TEMPLATE_TOKEN', payload: token });
  }

  const getList = (list) => {
    setLists(list);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header} height={40} style={{ borderRadius: '3px 3px 0 0 ' }}>
        <Box display="flex">
          <Typography variant="body2" color="text.primary" fontWeight="fontWeightMedium">
            {tplName}
          </Typography>
          <Box ml={2} fontSize="12px">
            {isScoreOpen && (
              <Box>
                <Typography variant="caption" color="text.disabled">
                  满分: {score}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {applicationEvaluations.length > 0 && (
            <Box mr={2}>
              <InviteInterviewer
                tplToken={tplToken}
                applicationEvaluations={applicationEvaluations}
                applicationToken={applicationTokens}
                getList={getList}
              />
            </Box>
          )}
          {!hasEvaluation && (
            <Box mr={1}>
              <Button
                variant="outlined"
                onClick={() => handleNew(tplToken)}
                color="primary"
                size="small"
              >
                立即评价
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Divider />
      {applicationEvaluations.length === 0 && (
        <Box p={2} borderBottom="solid 1px rgba(0, 0, 0, 0.08)" borderRadius="0 0 3px 3px">
          <Typography variant="body2" color="text.primary" textAlign="center">
            暂无评价，快去邀请面试官填写
          </Typography>
          {/* 通知填写 */}
          <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
            <InviteInterviewer
              tplToken={tplToken}
              applicationEvaluations={applicationEvaluations}
              applicationToken={applicationTokens}
              getList={getList}
            />
          </Box>
        </Box>
      )}
      {applicationEvaluations.length > 0
        && applicationEvaluations.map((item) => (
          <EvaluationItem
            key={item.token}
            item={item}
            selfEvaluationToken={evaluationToken}
            isScoreOpen={isScoreOpen}
          />
        ))}
      {lists.length > 0
        && tplToken === lists.tplToken
        && lists.applicationEvaluations((item) => (
          <EvaluationItem
            key={item.token}
            item={item}
            selfEvaluationToken={evaluationToken}
            isScoreOpen={isScoreOpen}
          />
        ))}
    </Box>
  );
}

EvaluationCard.propTypes = {
  card: PropTypes.objectOf(PropTypes.any).isRequired,
};
