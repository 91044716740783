import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import { renderTag } from '@utils/StringUtils';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  sizeMedium: {
    height: 24,
    '& .MuiChip-deleteIconSmall': {
      color: 'inherit',
    },
  },
  blue: {
    color: 'rgba(75,132,255,1)',
    borderColor: 'rgba(75,132,255,1)',
  },
  green: {
    color: 'rgba(0,188,184,1)',
    borderColor: 'rgba(0,188,184,1)',
  },
  red: {
    color: 'rgba(255,108,108,1)',
    borderColor: 'rgba(255,108,108,1)',
  },
  greenHover: {
    '&:hover': {
      backgroundColor: 'rgba(0,188,184,0.14)',
    },
  },
  deletable: {
    cursor: 'pointer',
    '& .hidden': {
      display: 'none',
    },
    '&:hover .hidden': {
      display: 'block',
    },
  },
}));

export default function CandidateTag(props) {
  const classes = useStyles();
  const {
    name, type, sentiment, deletable, onClick, onDelete, size, ...otherProps
  } = props;

  const couldDelete = deletable && type !== 2;

  const button = (
    <Chip
      size="small"
      variant="outlined"
      className={clsx({
        [classes.label]: true,
        [classes.blue]: type === 2,
        [classes.red]: type === 2 && sentiment === 3,
        [classes.green]: type === 1,
        [classes.deletable]: couldDelete,
        [classes.greenHover]: couldDelete,
        [classes.sizeMedium]: size === 'medium',
      })}
      onClick={onClick}
      {...otherProps}
      label={renderTag(name)}
      onDelete={couldDelete ? onDelete : null}
    >
      {couldDelete && <ClearIcon className="hidden" style={{ width: '16px', height: '16px', marginLeft: '4px' }} />}
    </Chip>
  );

  if (name.length > 7) {
    return (
      <Tooltip title={name} arrow>
        {button}
      </Tooltip>
    );
  }

  return button;
}

CandidateTag.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  sentiment: PropTypes.number.isRequired,
  deletable: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'small', 'lage']),
};

CandidateTag.defaultProps = {
  deletable: false,
  onClick: () => {},
};
