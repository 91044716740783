/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-autofocus */
import { saveApplicationAttachmentInfo } from '@api/Application';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useCandidateDispatch, useCandidateState } from '../context';
import AttachmentFolder from './AttachmentFolder';
import styles from './CandidateAttachments.module.css';
import CreateAttachementFolder from './CreateAttachementFolder';

const updateAttachmentInfo = debounce((applicationToken, attachmentInfo) => {
  console.log('saving:', applicationToken, attachmentInfo);
  const data = { attachmentInfo };
  saveApplicationAttachmentInfo(applicationToken, data).then((result) => {
    if (result.data.code === 0) {
      console.log('saved:', applicationToken, attachmentInfo);
    }
  });
}, 1000);

export default function CandidateAttachments() {
  const { applicationToken = '' } = useSelector((state) => state.candidateModal);
  const { applicationData } = useCandidateState();
  const dispatch = useCandidateDispatch();
  const { attachmentInfo = [], applicationState } = applicationData;
  const isCanceled = applicationState === 2;

  const isRendered = useRef(false);

  useEffect(() => {
    // 首次渲染时自动加入缺省文件夹
    if (!isRendered.current && attachmentInfo.length === 0) {
      dispatch({ type: 'ADD_ATTACHMENT_FOLDER' });
    }
  }, [attachmentInfo]);

  useEffect(() => {
    if (isRendered.current) {
      updateAttachmentInfo(applicationToken, attachmentInfo);
    } else {
      isRendered.current = true;
    }
  }, [attachmentInfo]);

  const showCreateAttachmentFolderBtn = !isCanceled;

  return (
    <div className={styles.wrapper}>
      {attachmentInfo.length > 0
        && attachmentInfo.map((item, i) => <AttachmentFolder key={item.token || i} item={item} index={i} />)}
      {showCreateAttachmentFolderBtn && <CreateAttachementFolder />}
    </div>
  );
}
