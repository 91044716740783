import PropTypes from 'prop-types';
import Chart from './Chart';
import colors from './RosePieColors';

const options = {
  legend: {
    show: false,
  },
  tooltip: {
    show: false,
  },
  series: [
    {
      type: 'pie',
      dimensions: ['type', 'weight', 'processIndex'],
      radius: [45, 100],
      roseType: 'radius',
      label: {
        show: false,
      },
      encode: {
        value: 'weight',
        weight: 'weight',
      },
      hoverAnimation: false,
      itemStyle: {
        color: (params) => {
          const type = params.data[0];
          if (type === 2) {
            return 'rgba(62, 202, 120, 0.2)';
          }
          const processIndex = params.data[2];
          const color = colors[processIndex];
          return color[0];
        },
      },
    },
  ],
};

function RosePieComputing({ source = [] }) {
  return <Chart options={{ ...options, dataset: { source } }} />;
}

RosePieComputing.propTypes = {
  source: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default RosePieComputing;
