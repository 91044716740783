import { Box } from '@material-ui/core';
import { ResumeProvider } from './ResumeContext';
import ResumenHeader from './ResumeHeader';
import ResumeLeft from './ResumeLeft';
import ResumeRightLayout from './ResumeRightLayout';

const ResumeLayout = () => (
  <ResumeProvider>
    <Box height="100%">
      <ResumenHeader />
      <Box display="flex" p={2} minHeight="calc(100% - 136px)">
        <ResumeRightLayout />
        <ResumeLeft />
      </Box>
    </Box>
  </ResumeProvider>
);

ResumeLayout.propTypes = {};

ResumeLayout.defaultProps = {};

export default ResumeLayout;
