import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';

const QnAItemVideo = ({ src }) => {
  const videoRef = useRef();

  const handlePlay = useCallback((e) => {
    Array.from(document.getElementsByTagName('video')).forEach((item) => {
      if (item.played && item !== e.target) {
        item.pause();
      }
    });

    // e.target.play()
  }, []);
  return (
    <Box
      width={123}
      height={164}
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="black"
      color="white"
      borderRadius={4}
    >
      <Typography variant="body2" style={{ fontWeight: 500 }}>无视频</Typography>
      {src && (
        <video
          onPlay={handlePlay}
          ref={videoRef}
          src={src}
          width={123}
          height={164}
          style={{ objectFit: 'cover', borderRadius: 4, position: 'absolute' }}
          controls
          controlsList="nodownload nofullscreen noremoteplayback"
        />
      )}
      {/* {videoRef?.current?.paused && (
        <Box zIndex={99}>
          <IconButton>
            <PlayIcon htmlColor="white" />
          </IconButton>
        </Box>
      )} */}
    </Box>
  );
};

QnAItemVideo.propTypes = {
  src: PropTypes.string.isRequired,
};
export default QnAItemVideo;
