import { fetchCommonWords } from '@api/Common';
import Typography from '@components/Avocado/Typography';
import {
  Box, Button, Popover as MuiPopover, TextField, withStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import {
  useState, useMemo, useEffect, cloneElement,
} from 'react';

const REASON_SCENES = {
  TAO_TAI: 1, // 淘汰原因
  CHU_BEI: 2, // 储备原因
  LI_ZHI: 3, // 离职原因
};

const genRandomId = () => `popupId_${Math.random().toString(36).substr(2, 9)}`;

const Popover = withStyles(() => ({
  paper: {
    minWidth: 360,
  },
}))(MuiPopover);

function ReasonTextField({ placeholder, scene, handleChange }) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () => debounce((input, callback) => {
      const data = { scene: REASON_SCENES[scene], reason: input };
      fetchCommonWords(data).then((v) => {
        if (v.data.code === 0) {
          callback(v.data.data.list);
        }
      });
    }, 500),
    [],
  );

  useEffect(() => {
    let active = true;
    handleChange(inputValue);

    fetch(inputValue, (results) => {
      if (active) {
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      freeSolo
      getOptionLabel={(option) => (typeof option === 'string' ? option : '')}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      onChange={(_, newValue) => {
        handleChange(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder={placeholder} fullWidth />}
    />
  );
}

ReasonTextField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  scene: PropTypes.oneOf(['TAO_TAI', 'CHU_BEI', 'LI_ZHI']).isRequired,
  handleChange: PropTypes.func.isRequired,
};

function ReasonPopover({
  children,
  popupId = genRandomId(),
  scene,
  handleSubmit,
  title = '确认提交？',
  desc,
  placeholder = '提交原因',
  actionText = '提交',
  actionColor = 'secondary',
  parentPopupState,
}) {
  const popupState = usePopupState({ variant: 'popover', popupId });
  const [reason, setReason] = useState('');

  return (
    <>
      {cloneElement(children, { ...bindTrigger(popupState) })}
      <Popover
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box pt={2} px={3} pb={1.5}>
          <Box>
            <Typography variant="body1" color="text.primary" fontWeight="fontWeightMedium">
              {title}
            </Typography>
          </Box>
          <Box mt={3}>
            {desc && (
              <Box mb={2}>
                <Typography variant="body1" color="text.secondary">
                  {desc}
                </Typography>
              </Box>
            )}
            <ReasonTextField placeholder={placeholder} scene={scene} handleChange={setReason} />
          </Box>
          <Box mt={3.5} display="flex" justifyContent="flex-end">
            <Button
              onClick={(e) => {
                popupState.close(e);
                if (parentPopupState) parentPopupState.close(e);
              }}
            >
              取消
            </Button>
            <Box mr={1} />
            <Button
              variant="contained"
              color={actionColor}
              onClick={(e) => {
                popupState.close(e);
                if (parentPopupState) parentPopupState.close(e);
                handleSubmit(reason);
              }}
            >
              {actionText}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

ReasonPopover.propTypes = {
  children: PropTypes.string.isRequired,
  popupId: PropTypes.string.isRequired,
  scene: PropTypes.oneOf(['TAO_TAI', 'CHU_BEI', 'LI_ZHI']).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  actionColor: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  parentPopupState: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ReasonPopover;
