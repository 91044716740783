import { useEffect } from 'react';
import { getEvaluationDetail } from '../../../../api/Evaluation';
import { useEvaluationDispatch, useEvaluationState } from '../context';

export default function useEffectReloadEvaluationDetail() {
  const dispatch = useEvaluationDispatch();
  const count = useEvaluationState((state) => state.count);
  const selectedEvaluationToken = useEvaluationState((state) => state.selectedEvaluationToken);

  useEffect(() => {
    getEvaluationDetail(selectedEvaluationToken).then((result) => {
      const value = {
        ...result.data.data,
        visibleUsers: result.data.data.visibleUsers
          ? result.data.data.visibleUsers.map((user) => ({
            username: user.name,
            userToken: user.token,
            userAvatar: user.avatar,
          }))
          : [],
      };
      const payload = { key: 'selectedEvaluationDetail', value };
      dispatch({ type: 'SET_STATE', payload });
    });
  }, [count, selectedEvaluationToken]);
}
