import Chip from '@components/Avocado/Chip';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

function KeywordChip({
  text, idx, isNotMatched, handleDeleteChip,
}) {
  const color = isNotMatched ? { color: 'secondary' } : {};

  return (
    <Box mr={0.5} my={0.25}>
      <Chip variant="outlined" customSize="sizeMedium" label={text} onDelete={() => handleDeleteChip(idx)} {...color} />
    </Box>
  );
}

KeywordChip.propTypes = {
  text: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  isNotMatched: PropTypes.bool.isRequired,
  handleDeleteChip: PropTypes.func.isRequired,
};

export default KeywordChip;
