import ELSnackbar from '@components/ELSnackbar/ELSnackbar';
import { Fade } from '@material-ui/core';
import CandidateModal from '@module/CandidateModal/CandidateModal';
import { updateSnackbarOpenSwitch } from '@reduxold/componentSwitch';
import { useDispatch, useSelector } from 'react-redux';

const Common = () => {
  const dispatch = useDispatch();
  const { snackbarOpenSwitch, snackbarSeverity, snackbarErrorMessage } = useSelector(
    ({ componentSwitch }) => componentSwitch,
  );

  return (
    <>
      <CandidateModal />
      <ELSnackbar
        open={snackbarOpenSwitch}
        onClose={() => dispatch(updateSnackbarOpenSwitch(false))}
        ContentProps={{ 'aria-describedby': 'message-id' }}
        TransitionComponent={Fade}
        autoHideDuration={2000}
        severity={snackbarSeverity}
        title={snackbarErrorMessage}
      />
    </>
  );
};

export default Common;
