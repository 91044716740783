import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

const EmailSendDialog = ({ open, onCancel, onSend }) => {
  const [email, setEmail] = useState('');
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      onExiting={() => {
        setEmail('');
      }}
    >
      <DialogTitle id="alert-dialog-title">发给自己</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center">
          <TextField
            color="primary"
            variant="outlined"
            value={email}
            onChange={(e) => {
              const { value } = e.target;
              setEmail(value.trim());
            }}
            style={{ width: '460px', margin: '20px 0px' }}
            placeholder="输入邮箱"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>取消</Button>
        <Button
          disabled={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
          color="primary"
          variant="contained"
          onClick={() => {
            onSend(email);
          }}
        >
          发送
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EmailSendDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSend: PropTypes.func,
};

EmailSendDialog.defaultProps = {
  open: false,
  onCancel: () => {},
  onSend: () => {},
};

export default memo(EmailSendDialog);
