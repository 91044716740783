/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import { upload } from '@api/Common';
import { getSchool } from '@api/School';
import { AddIcon, DeleteIcon } from '@avocadoui/icons';
import {
  Box, Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useGlobalToast from '@utils/GlobalToast';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useCandidateDispatch, useCandidateState } from '../context';
import styles from './CandidateFormWithAnswer.module.css';

const FILE_SIZE_LIMIT = 50; // 单位为MB

function ResultType1() {
  const GlobaToast = useGlobalToast();
  const dispatch = useCandidateDispatch();
  const itemDataForEditFormItemResultDialog = useCandidateState((state) => state.itemDataForEditFormItemResultDialog);
  const {
    formAnswer: { files }, formItemOption: { maximumNum },
  } = itemDataForEditFormItemResultDialog;
  const inputRef = useRef();

  function handleChange(url) {
    dispatch({ type: 'SET_RESULT_FOR_EDIT_FORM_ITEM_RESULT_DIALOG', payload: url });
  }

  function handleUpload() {
    inputRef.current.click();
  }

  return (
    <Box display="flex" flexWrap="wrap">
      {files && files.length > 0 && files.map((url) => (
        <Box
          className={styles.imgWrapper}
          style={{
            position: 'relative',
            margin: '0px 16px 16px 0px',
          }}
          onClick={() => handleChange(url)}
        >
          <img
            alt=""
            src={url}
            style={{
              display: 'block',
              width: '90px',
              height: '90px',
              objectFit: 'cover',
            }}
          />
          <Box
            className={styles.deleteIconWrapper}
            justifyContent="center"
            alignItems="center"
            style={{
              position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, cursor: 'pointer', color: 'white',
            }}
          >
            <Box><DeleteIcon /></Box>
          </Box>
        </Box>
      ))}
      <Button
        variant="outlined"
        style={{
          width: '90px',
          height: '90px',
        }}
        onClick={handleUpload}
      >
        <Box>
          <Box>
            <AddIcon />
          </Box>
          <Box>添加图片</Box>
        </Box>
      </Button>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        multiple
        style={{ display: 'none' }}
        onChange={(e) => {
          if (e.target.files.length > maximumNum - files.length) {
            GlobaToast.warning('文件数量已达上限');
            return;
          }
          for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            if (file.size && file.size > FILE_SIZE_LIMIT * 1024 * 1024) {
              GlobaToast.warning('文件过大，请上传50M以内的文件');
              return;
            }
          }
          for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            const formData = new FormData();
            formData.append('files', file);
            upload(formData).then((result) => {
              if (result?.data?.code === 0) {
                const { url } = result.data.data[0];
                handleChange(url);
              }
            });
          }
          // eslint-disable-next-line no-param-reassign
          e.target.value = null;
        }}
      />
    </Box>
  );
}

function ResultType2() {
  const dispatch = useCandidateDispatch();
  const itemDataForEditFormItemResultDialog = useCandidateState((state) => state.itemDataForEditFormItemResultDialog);
  const {
    formAnswer: { answer = '' },
  } = itemDataForEditFormItemResultDialog;

  function handleChange(event) {
    dispatch({ type: 'SET_RESULT_FOR_EDIT_FORM_ITEM_RESULT_DIALOG', payload: event.target.value });
  }

  return (
    <Box>
      <TextField variant="outlined" value={answer} fullWidth onChange={handleChange} />
    </Box>
  );
}

function ResultType3() {
  const dispatch = useCandidateDispatch();
  const itemDataForEditFormItemResultDialog = useCandidateState((state) => state.itemDataForEditFormItemResultDialog);
  const {
    formAnswer: { choiceOptions = [''] },
    formItemOption: { alternatives },
  } = itemDataForEditFormItemResultDialog;

  function handleChange(event) {
    dispatch({ type: 'SET_RESULT_FOR_EDIT_FORM_ITEM_RESULT_DIALOG', payload: event.target.value });
  }

  return (
    <Box>
      <RadioGroup value={choiceOptions[0]} onChange={handleChange}>
        {alternatives.map((item) => <FormControlLabel key={item.key} value={item.key} control={<Radio color="primary" />} label={item.value} />)}
      </RadioGroup>
    </Box>
  );
}

function ResultType3p2() {
  const dispatch = useCandidateDispatch();
  const itemDataForEditFormItemResultDialog = useCandidateState((state) => state.itemDataForEditFormItemResultDialog);
  const {
    formAnswer: { choiceOptions = [''] },
    formItemOption: { alternatives },
  } = itemDataForEditFormItemResultDialog;

  function handleChange(event) {
    dispatch({ type: 'SET_RESULT_FOR_EDIT_FORM_ITEM_RESULT_DIALOG', payload: [event.target.name, event.target.checked] });
  }

  return (
    <Box>
      <RadioGroup>
        {alternatives.map((item) => (
          <FormControlLabel
            key={item.key}
            control={<Checkbox checked={choiceOptions.includes(item.key)} onChange={handleChange} name={item.key} />}
            label={item.value}
          />
        ))}
      </RadioGroup>
    </Box>
  );
}

function ResultType4() {
  const dispatch = useCandidateDispatch();
  const itemDataForEditFormItemResultDialog = useCandidateState((state) => state.itemDataForEditFormItemResultDialog);
  const {
    formAnswer: { dateTime },
  } = itemDataForEditFormItemResultDialog;
  const value = (new Date(dateTime * 1000)).toISOString().substr(0, 10);

  function handleChange(event) {
    dispatch({ type: 'SET_RESULT_FOR_EDIT_FORM_ITEM_RESULT_DIALOG', payload: event.target.value });
  }

  return (
    <Box>
      <TextField
        type="date"
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
}

const fetchSchoolDebounced = debounce((fetchSchool, searchText) => {
  fetchSchool(searchText);
}, 500);

function ResultType7() {
  const dispatch = useCandidateDispatch();
  const itemDataForEditFormItemResultDialog = useCandidateState((state) => state.itemDataForEditFormItemResultDialog);
  const {
    formAnswer: { answer = '' },
  } = itemDataForEditFormItemResultDialog;

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  function handleInputChange(_, value) {
    setInputValue(value);
    dispatch({ type: 'SET_RESULT_FOR_EDIT_FORM_ITEM_RESULT_DIALOG', payload: value });
  }

  function handleChange(_, value) {
    dispatch({ type: 'SET_RESULT_FOR_EDIT_FORM_ITEM_RESULT_DIALOG', payload: value });
  }

  const fetchSchool = async (searchText) => {
    try {
      const resp = await getSchool({ searchText });
      setOptions(resp.data.data.list.map((n) => n.name));
    } catch (error) {
      console.error('fetchSchool:', error);
    }
  };

  useEffect(() => {
    if (inputValue) {
      fetchSchoolDebounced(fetchSchool, inputValue);
    }
  }, [inputValue]);

  return (
    <Box>
      <Autocomplete
        freeSolo
        options={options}
        value={answer}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </Box>
  );
}

function ResultType8() {
  const dispatch = useCandidateDispatch();
  const itemDataForEditFormItemResultDialog = useCandidateState((state) => state.itemDataForEditFormItemResultDialog);
  const {
    formAnswer: { answer = '' },
  } = itemDataForEditFormItemResultDialog;

  function handleChange(idx, event) {
    dispatch({ type: 'SET_RESULT_FOR_EDIT_FORM_ITEM_RESULT_DIALOG', payload: [idx, event.target.value] });
  }

  return (
    <Box>
      {answer.map((item, idx) => <Box key={idx} mb={idx + 1 === answer.length ? 0 : 3}><TextField variant="outlined" label={`回答${idx + 1}`} value={item} fullWidth onChange={(e) => handleChange(idx, e)} /></Box>)}
    </Box>
  );
}

function EditFormItemResultDialogContent() {
  const itemDataForEditFormItemResultDialog = useCandidateState((state) => state.itemDataForEditFormItemResultDialog);
  const {
    formTypeId,
  } = itemDataForEditFormItemResultDialog;

  // 图片题
  if (formTypeId === 1) {
    return <ResultType1 />;
  }
  // 问答题
  if (formTypeId === 2) {
    return <ResultType2 />;
  }
  // 单选题 & 多选题
  if (formTypeId === 3) {
    const { formItemOption: { allowMultiple } } = itemDataForEditFormItemResultDialog;
    if (allowMultiple) {
      return <ResultType3p2 />;
    }
    return <ResultType3 />;
  }
  // 时间题
  if (formTypeId === 4) {
    return <ResultType4 />;
  }
  // 高校题
  if (formTypeId === 7) {
    return <ResultType7 />;
  }
  // 填空题
  if (formTypeId === 8) {
    return <ResultType8 />;
  }
  return null;
}

export default EditFormItemResultDialogContent;
