export function byteLength(s) {
  let count = 0;
  for (let i = 0, l = s.length; i < l; i += 1) {
    count += s.charCodeAt(i) <= 128 ? 1 : 2;
  }
  return count;
}

export function renderTag(tag, max = 7) {
  if (tag && tag.length > max) {
    return `${tag.slice(0, max)}...`;
  }
  return tag;
}

export function chineseLength(str) {
  let count = 0;
  for (let i = 0, len = str.length; i < len; i += 1) {
    count += str.charCodeAt(i) < 256 ? 1 : 2;
  }
  return Math.ceil(count / 2);
}

export default {};
