import MuiAvatar from '@material-ui/core/Avatar';
import { RANDOM_COLOR } from '@utils/constants';
import PropTypes from 'prop-types';

const AVATAR_SIZES = {
  tiny: 14,
  small: 24,
  medium: 36,
  large: 48,
};

const FONT_SIZES = {
  tiny: 14,
  small: 14,
  medium: 14,
  large: 20,
};

const Avatar = ({
  user, onClick, children, size, backgroundColor,
}) => {
  const style = user?.avatar ? {
    width: AVATAR_SIZES[size],
    height: AVATAR_SIZES[size],
    fontSize: FONT_SIZES[size],
    cursor: 'pointer',
  } : {
    width: AVATAR_SIZES[size],
    height: AVATAR_SIZES[size],
    fontSize: FONT_SIZES[size],
    cursor: 'pointer',
    backgroundColor: backgroundColor || RANDOM_COLOR[user?.name?.charCodeAt(0) % RANDOM_COLOR.length],
  };
  return (
    <MuiAvatar style={style} src={user?.avatar || user?.userAvatar} onClick={onClick}>
      {children || user?.name?.charAt(user.name.length - 1) || user?.useName?.charAt(user.useName.length - 1) }
    </MuiAvatar>
  );
};

Avatar.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.element,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'tiny']),
};

Avatar.defaultProps = {
  size: 'medium',
  children: null,
  onClick: null,
  backgroundColor: null,
};

export default Avatar;
