import CustomPrompt from '@components/CustomPrompt/CustomPrompt';
import ELConfirmDialog from '@components/Dialog/ELConfirmDialog';
import ResumeLayout from '@components/Resume/ResumeLayout';
import {
  Backdrop, Box, Modal, Slide,
} from '@material-ui/core';
import { closeCandidateModal, destroyCandidateModal } from '@redux/candidateModal/action';
import { setPostFilterReloadUse } from '@redux/process/action';
import { setSearchStat } from '@redux/search/action';
import { getTalents } from '@redux/talent/action';
import * as CandidatesActions from '@reduxold/candidates';
import { reloadProcesses } from '@reduxold/processes';
import usePreventReload from '@utils/UsePreventReload';
import { useElementScroll, useTransform } from 'framer-motion';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as API from '../../v1/api';
import CandidateInfo from './CandidateInfo/CandidateInfo';
import CandidateMain from './CandidateMain';
import styles from './CandidateModal.module.css';
import CandidateQuickAction from './CandidateQuickAction';
import FormVideoPlayerWarp from './Compents/Form/VideoPlayerWarp';
import { CandidateProvider, useCandidateDispatch, useCandidateState } from './context';
import DataLoader from './DataLoader';

const CandidateContent = ({ handleClose }) => {
  const contentRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const { scrollY } = useElementScroll(contentRef);

  const distance = 100;

  const padding = useTransform(scrollY, [0, distance], [16, 4]);
  const opacity0 = useTransform(scrollY, [0, distance], [1, 0]);
  const opacity1 = useTransform(scrollY, [0, distance], [0, 1]);
  const translateX = useTransform(scrollY, [0, distance], [0, -50]);
  const translateY = useTransform(scrollY, [0, distance], [0, -8]);
  const hasScrolled = progress !== 0;
  const shouldDisabledBtn = progress > 20;

  useEffect(() => {
    const unsubScrollY = scrollY.onChange(setProgress);

    return () => {
      unsubScrollY();
    };
  }, []);

  const { isResumeEditing } = useCandidateState();

  return (
    <Box height>
      {isResumeEditing && (
        <Box className={styles.contentWrapper} style={{ backgroundColor: '#F9FAF9' }}>
          <ResumeLayout />
        </Box>
      )}
      <Box hidden={isResumeEditing} height>
        <div ref={contentRef} className={styles.contentWrapper} style={{ backgroundColor: '#F9FAF9' }}>
          <CandidateInfo
            onClose={handleClose}
            rpaCandidate={false}
            padding={padding}
            opacity0={opacity0}
            opacity1={opacity1}
            translateX={translateX}
            translateY={translateY}
            contentRef={contentRef}
            hasScrolled={hasScrolled}
            shouldDisabledBtn={shouldDisabledBtn}
          />
          <CandidateMain
            onClose={handleClose}
            hasScrolled={hasScrolled}
          />
        </div>
      </Box>
    </Box>
  );
};

CandidateContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

const CandidateModal = ({ candidatesActions }) => {
  const isOpen = useSelector(({ candidateModal }) => candidateModal.isOpen);
  const showBackdrop = useSelector(({ candidateModal }) => candidateModal.showBackdrop);
  const hideSwitchButton = useSelector(({ candidateModal }) => candidateModal.hideSwitchButton);
  const disabledClose = useSelector(({ candidateModal }) => candidateModal.disabledClose);
  const candidates = useSelector((state) => state.candidates);
  const talentFilters = useSelector((state) => state.talent.filters);

  const [showPrompt, setShowPrompt] = useState(false);
  const {
    isEditing,
    editingType = 0,
    editingTitle = '',
    isResumeEditing = false,
    isResumeEdited,
    alertDialog,
  } = useCandidateState();
  const dispatch = useCandidateDispatch();
  const history = useHistory();
  const location = useLocation();
  const dispatchRedux = useDispatch();

  const isAtCandidateListRoute = useRouteMatch('/dashboard/candidatesList/:candidateToken');
  const isAtSearchRoute = useRouteMatch('/dashboard/search/:candidateToken');
  const isAtTalentRoute = useRouteMatch('/dashboard/talent') || useRouteMatch('/dashboard/talent/:candidateToken');

  usePreventReload(isEditing);

  function reloadListData() {
    if (isAtCandidateListRoute || isAtSearchRoute) {
      API.getCandidates(candidatesActions, candidates.filter).then((result) => {
        const { searchStat, mobileSearchStat, nameSearchStat } = result.data;
        dispatchRedux(setSearchStat({ searchStat, mobileSearchStat, nameSearchStat }));
      });
    } else if (isAtTalentRoute) {
      dispatchRedux(getTalents({ filters: talentFilters, isShowLaoding: false }));
    } else {
      dispatchRedux(reloadProcesses()); // 更新 ProcessToolbar 数据
      dispatchRedux(setPostFilterReloadUse(true)); // 更新 ProcessTable 数据
    }
  }

  function resetRoute() {
    if (location.pathname.indexOf('/dashboard/processReview') > -1) {
      const query = new URLSearchParams(history.location.search);
      if (query.has('candidate')) query.delete('candidate');
      if (query.has('application')) query.delete('application');
      history.push(`${location.pathname}?${query.toString()}`);
    }
    if (location.pathname.indexOf('/dashboard/candidatesList') > -1) {
      // history.replace('/dashboard/candidatesList/candidateToken');
      history.push('/dashboard/talent');
    }
    if (location.pathname.indexOf('/dashboard/search') > -1) {
      history.replace('/dashboard/search/candidateToken');
    }
  }

  function resetAndCloseModal({ shouldRefreshData, shouldResetRoute }) {
    dispatch({ type: 'RESET_TO_DEFAULT_STATE' });
    dispatchRedux(closeCandidateModal());
    if (shouldRefreshData) reloadListData();
    if (shouldResetRoute) resetRoute();
    setTimeout(() => {
      dispatchRedux(destroyCandidateModal());
    }, 0);
  }

  function handleClose({ shouldRefreshData = true, forceClose = false, shouldResetRoute = true } = {}) {
    if (disabledClose) return;

    if (forceClose) {
      resetAndCloseModal({ shouldRefreshData, shouldResetRoute });
    } else if (isEditing || (isResumeEditing && isResumeEdited)) {
      setShowPrompt(true);
    } else {
      resetAndCloseModal({ shouldRefreshData, shouldResetRoute });
    }
  }

  return (
    <>
      <Modal
        aria-labelledby="candidate-modal-title"
        aria-describedby="candidate-modal-description"
        className={styles.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          open: showBackdrop,
        }}
      >
        <Slide direction="left" in={isOpen}>
          <div className={styles.wrapper}>
            <div className={styles.backdrop} onClick={handleClose} role="button">
              close
            </div>
            <div className={styles.actionWrapper}>
              <div className={styles.videoPlayer}>
                {/* <CandidateVideo /> */}
                <FormVideoPlayerWarp />
              </div>
              {!hideSwitchButton && !isEditing && (
                <div className={styles.buttonWrapper}>
                  <CandidateQuickAction />
                </div>
              )}
            </div>
            <CandidateContent
              handleClose={handleClose}
            />
          </div>
        </Slide>
      </Modal>
      {showPrompt && (
        <CustomPrompt
          message={
            !isResumeEditing && editingType === 1
              ? `您还未保存面试评价表 【${editingTitle}】，确定离开吗？`
              : '您还未保存当前修改，确定离开吗？'
          }
          cleanUp={(ok) => {
            if (ok) {
              if (isResumeEditing && isEditing) {
                dispatch({ type: 'SET_IS_RESUME_EDITING', payload: false });
              } else {
                handleClose({ forceClose: true });
              }
            }
            setShowPrompt(false);
          }}
        />
      )}
      <ELConfirmDialog
        open={!isEmpty(alertDialog)}
        title={alertDialog.title}
        desc={alertDialog.desc}
        confirmTitile="好的"
        severity="success"
        showCancel={false}
        onConfirm={() => {
          dispatch({ type: 'SET_ALERT_DIALOG', payload: {} });
        }}
      />
    </>
  );
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    candidatesActions: bindActionCreators(CandidatesActions, dispatch),
  };
}

CandidateModal.propTypes = {
  candidatesActions: PropTypes.objectOf(PropTypes.any).isRequired,
};

const CandidateModalWithRedux = connect(mapStateToProps, mapDispatchToProps)(CandidateModal);

const CandidateModalWrapper = () => {
  const isNull = useSelector(({ candidateModal }) => candidateModal.isNull);

  if (isNull) {
    return null;
  }

  return (
    <CandidateProvider>
      <DataLoader />
      <CandidateModalWithRedux />
    </CandidateProvider>
  );
};

export default CandidateModalWrapper;
