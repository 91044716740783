import * as API from '@api/Common';
import { AttachmentIcon } from '@avocadoui/icons';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { getSuffix } from '@utils/FileUtils';
import useGlobalToast from '@utils/GlobalToast';
import filesize from 'filesize';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';
import Menu from 'material-ui-popup-state/HoverMenu';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import styles from './ELEditorAttachment.module.css';

const FILE_SIZE_MAX = 15728640;

const FILE_TYPE_COLOR = {
  pdf: '#F6584D',
  doc: '#298DED',
  txt: '#9468D8',
  xls: '#0CD8A8',
  ppt: '#FF9819',
  mp4: '#6F81CA',
};

const ELEditorAttachment = ({ attachments = [], onAttachmentsChange }) => {
  const input = useRef();
  const GlobalToast = useGlobalToast();

  const totalSize = useMemo(() => attachments.reduce((p, c) => c.size + p, 0), [attachments]);

  const handleIconClick = () => {
    if (input.current) {
      input.current.click();
    }
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleInputChange = async () => {
    const files = input.current.files || [];
    // console.log(files);
    if (files.length > 0) {
      const file = files[0];
      if (totalSize + file.size < FILE_SIZE_MAX) {
        const formData = new FormData();
        formData.append('files', file);
        API.upload(formData, () => {}, { type: 'normal' })
          .then((v) => {
            // console.log(v.data);
            if (v.data.code === 0) {
              onAttachmentsChange([...attachments, { name: file.name, size: file.size, url: v.data.data[0].url }]);
            }
          })
          .catch(() => {});
      } else {
        GlobalToast.warning('附件总大小不能超过15M');
      }
    }
  };
  return (
    <Box minHeight="58px" display="flex" justifyContent="center" ml={1.5} flexDirection="column">
      <Box display="flex" width="100%" alignItems="center">
        <Typography
          variant="body2"
          style={{
            color: 'rgba(0, 0, 0, 0.56)',
            marginRight: 8,
          }}
        >
          附件
        </Typography>
        <Box flexGrow={1}>
          <Divider />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" width="100%" alignContent="flex-start">
        {attachments.map((v, i) => {
          const fileType = getSuffix(v.name);
          return (
            <Box
              key={v.name + v.size}
              flexShrink={0}
              display="flex"
              width="257px"
              height="48px"
              style={{
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, 0.04)',
                cursor: 'pointer',
              }}
              alignItems="center"
              mr={1.5}
              mt={1.5}
              overflow="hidden"
              className={styles.attachmentItem}
            >
              <Box width="60px" mr={2} display="flex" justifyItems="center" justifyContent="center" flexShrink={0}>
                <Typography
                  style={{
                    color: FILE_TYPE_COLOR[fileType] || 'rgba(0,0,0,0.56)',
                    fontSize: 22,
                    fontWeight: 500,
                    textTransform: 'Uppercase',
                  }}
                >
                  {fileType}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Typography
                  variant="body2"
                  style={{
                    overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: 180,
                  }}
                >
                  {v.name}
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="caption"
                    style={{
                      color: 'rgba(0, 0, 0, 0.56)',
                    }}
                  >
                    {filesize(v.size, { unix: true })}
                  </Typography>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <>
                        <MoreHorizIcon className={styles.other} {...bindHover(popupState)} />
                        <Menu
                          {...bindMenu(popupState)}
                          variant="menu"
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                          <MenuItem
                            onClick={() => {
                              onAttachmentsChange(attachments.filter((vv, ii) => ii !== i));
                              popupState.close();
                            }}
                            style={{ fontSize: v.fontSize }}
                          >
                            删除
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </PopupState>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box display="flex" alignItems="center" height="58px">
        <Button
          startIcon={<AttachmentIcon />}
          onClick={handleIconClick}
          variant="contained"
          style={{ marginRight: 10 }}
        >
          上传附件
        </Button>
        <input
          hidden
          ref={input}
          type="file"
          onClick={handleInputClick}
          onChange={handleInputChange}
          multiple={false}
        />
        <Typography variant="caption">({filesize(totalSize, { unix: true })} /15M)</Typography>
      </Box>
    </Box>
  );
};

ELEditorAttachment.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object),
  onAttachmentsChange: PropTypes.func,
};
ELEditorAttachment.defaultProps = {
  attachments: [],
  onAttachmentsChange: () => {},
};
export default ELEditorAttachment;
