import { AddIcon } from '@avocadoui/icons';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import styles from './CandidateTag.module.css';

class CandidateTag extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
  }

  componentDidMount() {
    const { getNearTagsData } = this.props;

    getNearTagsData();
  }

  handleCheckboxChange = (event, tag) => {
    const { directAddTag, directDeleteTag } = this.props;

    if (event.target.checked) {
      directAddTag(tag.name);
    } else {
      directDeleteTag(tag.name);
    }
  };

  handleInputAdd = () => {
    const { inputValue } = this.state;
    const { directAddTag } = this.props;

    this.setState(
      {
        inputValue: '',
      },
      () => {
        directAddTag(inputValue);
      },
    );
  };

  render() {
    const { inputValue } = this.state;
    const {
      nearTags, sureAddTag, handleChangeTag, selectedTags,
    } = this.props;

    return (
      <Paper className={styles.addTagDialog} elevation={2}>
        <TextField
          className={styles.editTagName}
          size="small"
          variant="outlined"
          placeholder="查找或创建标签"
          onKeyUp={sureAddTag}
          value={inputValue}
          label=""
          onChange={(e) => {
            this.setState({ inputValue: e.target.value });
            handleChangeTag(e);
          }}
        />
        <div className={styles.tagListWrap}>
          {inputValue && inputValue.trim() && nearTags.filter((v) => v.name === inputValue).length === 0 && (
            <Box className={styles.tagListTitle} onClick={this.handleInputAdd}>
              <IconButton>
                <AddIcon style={{ marginRight: 4 }} />
              </IconButton>
              <Typography className={styles.ellipsis} variant="body2">
                添加＂{inputValue}＂标签
              </Typography>
            </Box>
          )}
          <ul>
            {nearTags.map((value) => {
              const checkboxItem = (
                <li className={styles.addTagItem}>
                  <FormControlLabel
                    style={{ marginRight: 0, marginLeft: 0, width: '100%' }}
                    classes={{ root: styles.ellipsis, label: styles.ellipsis }}
                    control={(
                      <Checkbox
                        color="primary"
                        onChange={(e) => this.handleCheckboxChange(e, value)}
                        checked={selectedTags.filter((item) => item.token === value.token).length > 0}
                        style={{ marginRight: 4 }}
                      />
                    )}
                    label={value.name}
                  />
                </li>
              );
              if (value?.name?.length > 14) {
                return (
                  <Tooltip title={value.name} arrow>
                    {checkboxItem}
                  </Tooltip>
                );
              }
              return checkboxItem;
            })}
          </ul>
        </div>
      </Paper>
    );
  }
}

CandidateTag.propTypes = {
  sureAddTag: PropTypes.func,
  directDeleteTag: PropTypes.func,
  handleChangeTag: PropTypes.func,
  directAddTag: PropTypes.func,
  getNearTagsData: PropTypes.func,
  nearTags: PropTypes.arrayOf(PropTypes.any),
  selectedTags: PropTypes.arrayOf(PropTypes.any),
};

CandidateTag.defaultProps = {
  sureAddTag: () => {},
  directDeleteTag: () => {},
  handleChangeTag: () => {},
  directAddTag: () => {},
  getNearTagsData: () => {},
  nearTags: [],
  selectedTags: [],
};

export default CandidateTag;
