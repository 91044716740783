import { Box } from '@material-ui/core';
import moment from '@utils/moment';
import { useCandidateState } from '../context';
import styles from './CandidateResume.module.css';

function transformToFormData(value, type, options) {
  if (type === 2) {
    return value;
  }
  if (type === 3) {
    return options.find((v) => v.key === value.toString())?.value || '';
  }
  if (type === 4) {
    return moment(value * 1000).format('YYYY-MM-DD');
  }
  return '';
}

const AttachmentResume = () => {
  const { candidateData = {} } = useCandidateState();
  const { attachResume = {} } = candidateData;
  const { formItems = [] } = attachResume;

  if (formItems?.length > 0) {
    const odd = formItems.filter((_, index) => index % 2 === 0);
    const even = formItems.filter((_, index) => index % 2 === 1);
    return (
      <Box>
        <div className={styles.attachmentResume}>
          <div className={styles.head}>附加简历</div>
          <div className={styles.body}>
            {[odd, even].map((set) => (
              <div className={styles.bodyHalf}>
                {set.map((item) => (
                  <div className={styles.form}>
                    <div className={styles.title}>{item.formTypeKey}</div>
                    <div className={styles.desc}>
                      {item.originFormItemAnswer === ''
                        ? '-'
                        : transformToFormData(
                          item.originFormItemAnswer,
                          item.formTypeId,
                          item?.formItemOption?.alternatives,
                        )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Box>
    );
  }

  return null;
};

export default AttachmentResume;
