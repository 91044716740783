import Typography from '@components/Avocado/Typography';
import { Box, Stepper as MuiStepper, withStyles } from '@material-ui/core';
import { useCandidateState } from '../context';
import Panel from './Panel';
import PositionStep from './PositionStep';

const Stepper = withStyles({
  root: {
    padding: 0,
    '& > .MuiStepConnector-vertical': {
      padding: '0 0 4px',
      marginLeft: '11px',
    },
  },
})(MuiStepper);

function Header() {
  return (
    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="body2" color="text.primary">
        职位流程
      </Typography>
    </Box>
  );
}

function Body() {
  const applicationLogsData = useCandidateState((state) => state.applicationLogsData);
  const list = applicationLogsData.filter(({ stage }) => stage === 2);

  return (
    <Box width="100%">
      <Stepper activeStep={-1} orientation="vertical">
        {list.map((step, index) => (
          <PositionStep key={step.processToken} item={step} index={index} />
        ))}
      </Stepper>
    </Box>
  );
}

export default function PositionPanel() {
  const allSticky = { position: 'sticky', top: '56px' };

  const shouldShowPositionPanel = useCandidateState((state) => {
    const {
      applicationData: { applicationState },
    } = state;
    return [4, 5, 7, 8, 9, 10].indexOf(applicationState) > -1;
  });

  if (!shouldShowPositionPanel) {
    allSticky.visibility = 'hidden';
  }

  return (
    <Box mt={2} style={allSticky}>
      <Panel header={<Header />} body={<Body />} defaultExpanded />
    </Box>
  );
}
