import * as ACTIONS from '../constants/ActionTypes';

const initialState = {
  // 这个变量好像没用到
  menuSwitch: true,
  // 创建候选人Dialog是否显示
  addCandidateSwitch: false,
  // 导入候选人Dialog是否显示
  importCandidateSwitch: false,
  importPositionSwitch: false,
  importStaffSwitch: false,
  addPatternSwitch: false,
  editPatternSwitch: false,
  // 添加流程Dialog是否显示
  addProcessSwitch: false,
  // 创建群组Dialog是否显示
  addGroupSwitch: false,
  // 资料收集弹出框
  formCreateSwitch: false,
  // 添加人员到面试流程Dialog是否显示
  addCandidateToProcessSwitch: false,
  // 编辑候选人信息Dialog是否显示
  editCandidateSwitch: false,
  // 候选人列表详细信息展示Drawer
  candidateInfoSwitch: false,
  // 候选人列表详细信息Drawer的索引
  candidateInfoToken: '',
  // 添加HRDialog是否显示
  addHrSwitch: false,
  // 编辑HRDialog是否显示
  editHrSwitch: false,
  // Dashboard当前显示的页面
  mainPageIndex: 0,
  // Dashboard左侧菜单展示与收回
  mainPageMenuStatus: false,
  // 发送流程&面试审核-标题栏
  workbenchTitle: '',
  // 双击流程-打开流程编辑-Token
  workbenchToken: '',
  // 最上方搜索框默认显示的文字
  headSearchPlaceholder: '',
  // 创建候选人-按钮-是否显示
  btnCreateCandidateSwitch: false,
  // 创建群组-按钮-是否显示
  btnCreateGroupSwitch: false,
  // 删除候选人-按钮-是否显示
  btnDropCandidateSwitch: false,
  // 删除群组-按钮-是否显示
  btnDropGroupSwitch: false,
  // 发送面试-按钮-是否显示
  btnSendInterviewSwitch: false,
  // 全局Alert显示标志
  globalAlertSwitch: false,
  // 下方中央黑色错误提示框开关
  snackbarOpenSwitch: false,
  // 下方中央黑色错误提示信息
  snackbarErrorMessage: '',
  // 当前账号可见的分部tokens
  departmentTokens: [],
  // 当前账号已选择的分部tokens
  selectedDepartmentTokens: [],
  enterPrise: {},
  user: {},
  // 消息总数定时器
  TimerCandidateCount: null,
  // 提醒下线定时器
  TimerKickout: null,
  // 设置索引值
  tabIndex: 0,
  // 全局候选人弹窗
  isGlobalCandidateModalOpen: false,
  applicationTokenForGlobalModal: null,
  //控制到第几层页面
  step: 0,
  taskId: '',
  candidate: [],
  candidateResumeExtra: [],
  resume: [],
};

export default function componentSwitch(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_MENU_SWITCH:
      return { ...state, menuSwitch: action.value };
    case ACTIONS.UPDATE_ADD_GROUP_SWITCH:
      return { ...state, addGroupSwitch: action.value };
    case ACTIONS.UPDATE_FORM_CREATE_SWITCH:
      return { ...state, formCreateSwitch: action.value };
    case ACTIONS.UPDATE_ADD_CANDIDATE_SWITCH:
      return { ...state, addCandidateSwitch: action.value };
    case ACTIONS.UPDATE_IMPORT_CANDIDATE_SWITCH:
      return { ...state, importCandidateSwitch: action.value };
    case ACTIONS.UPDATE_IMPORT_POSITION_SWITCH:
      return { ...state, importPositionSwitch: action.value };
    case ACTIONS.UPDATE_IMPORT_STAFF_SWITCH:
      return { ...state, importStaffSwitch: action.value };
    case ACTIONS.UPDATE_EXPORT_CANDIDATE_SWITCH:
      return {
        ...state,
        exportCandidateSwitch: action.value.value,
        step: action.value.step,
        taskId: action.value.taskId,
        candidate: action.value.candidate,
        candidateResumeExtra: action.value.candidateResumeExtra,
        resume: action.value.resume,
      };
    case ACTIONS.UPDATE_ASSIGN_DEPARTMENT_SWITCH:
      return { ...state, assignDepartmentSwitch: action.value };
    case ACTIONS.UPDATE_ADD_PATTERN_SWITCH:
      return { ...state, addPatternSwitch: action.value };
    case ACTIONS.UPDATE_EDIT_PATTERN_SWITCH:
      return { ...state, editPatternSwitch: action.value };
    case ACTIONS.UPDATE_ADD_PROCESS_SWITCH:
      return { ...state, addProcessSwitch: action.value };
    case ACTIONS.UPDATE_ADD_CANDIDATE_TO_PROCESS_SWITCH:
      return { ...state, addCandidateToProcessSwitch: action.value };
    case ACTIONS.UPDATE_EDIT_CANDIDATE_SWITCH:
      return { ...state, editCandidateSwitch: action.value };
    case ACTIONS.UPDATE_CANDIDATE_INFO_SWITCH:
      return { ...state, candidateInfoSwitch: action.value };
    case ACTIONS.UPDATE_CANDIDATE_INFO_TOKEN:
      return { ...state, candidateInfoToken: action.value };
    case ACTIONS.ADD_HR_SWITCH:
      return { ...state, addHrSwitch: action.value };
    case ACTIONS.EDIT_HR_INFO_SWITCH:
      return { ...state, editHrSwitch: action.value };
    case ACTIONS.UPDATE_MAIN_PAGE_INDEX:
      return { ...state, mainPageIndex: action.value };
    case ACTIONS.UPDATE_MAIN_PAGE_MENU_STATUS:
      return { ...state, mainPageMenuStatus: action.value };
    case ACTIONS.UPDATE_WORKBENCH_TITLE:
      return { ...state, workbenchTitle: action.value };
    case ACTIONS.UPDATE_WORKBENCH_TOKEN:
      return { ...state, workbenchToken: action.value };
    case ACTIONS.UPDATE_HEAD_SEARCH_PLACEHOLDER:
      return { ...state, headSearchPlaceholder: action.value };
    case ACTIONS.UPDATE_BTN_ADD_CANDIDATE_SWITCH:
      return { ...state, btnCreateCandidateSwitch: action.value };
    case ACTIONS.UPDATE_BTN_ADD_GROUP_SWITCH:
      return { ...state, btnCreateGroupSwitch: action.value };
    case ACTIONS.UPDATE_BTN_DROP_CANDIDATE_SWITCH:
      return { ...state, btnDropCandidateSwitch: action.value };
    case ACTIONS.UPDATE_BTN_DROP_GROUP_SWITCH:
      return { ...state, btnDropGroupSwitch: action.value };
    case ACTIONS.UPDATE_BTN_SEND_INTERVIEW_SWITCH:
      return { ...state, btnSendInterviewSwitch: action.value };
    case ACTIONS.UPDATE_GLOBAL_ALERT_SWITCH:
      return { ...state, globalAlertSwitch: action.value };
    case ACTIONS.UPDATE_SNACKBAR_OPEN_SWITCH:
      if (action.value) {
        return { ...state, snackbarOpenSwitch: action.value, snackbarSeverity: 'success' };
      }
      return { ...state, snackbarOpenSwitch: action.value };
    case ACTIONS.UPDATE_REJECT_REASON_DIALOG_SWITCH: {
      const { open, tokens } = action.value;
      return { ...state, updateRejectReasonDialogSwitch: open, updateRejectReasonDialogTokens: tokens };
    }
    case ACTIONS.UPDATE_SNACKBAR_ERROR_MESSAGE:
      return { ...state, snackbarErrorMessage: action.value };
    case ACTIONS.UPDATE_DEPARTMENT_ARRAY:
      return { ...state, departmentTokens: action.value };
    case ACTIONS.UPDATE_SELECTED_DEPARTMENT_ARRAY:
      return { ...state, selectedDepartmentTokens: action.value };
    case ACTIONS.UPDATE_ENTERPRISE:
      return { ...state, enterPrise: action.value };
    case ACTIONS.UPDATE_USER:
      return { ...state, user: action.value };
    case ACTIONS.CHANGE_KICKOUT:
      return { ...state, TimerKickout: action.value };
    case ACTIONS.CHANGE_CANDIDATECOUNT:
      return { ...state, TimerCandidateCount: action.value };
    case ACTIONS.UPDATA_SETTABINDEX:
      return { ...state, tabIndex: action.value };
    case ACTIONS.UPDATE_ADD_TAG_TO_CANDIDATE_SWITCH:
      return { ...state, addTagToCandidate: action.value };
    case ACTIONS.SHOW_GLOBAL_TOAST:
      console.log(action);
      return {
        ...state,
        snackbarErrorMessage: action.value.message,
        snackbarOpenSwitch: true,
        snackbarSeverity: action.value.severity,
      };
    case ACTIONS.TOGGLE_TEMPLATE_FILL_DIALOG:
      return { ...state, isTemplateFillDialogOpen: !state.isTemplateFillDialogOpen };
    case ACTIONS.OPEN_GLOCAL_CANDIDATE_MODAL:
      console.log('OPEN_GLOCAL_CANDIDATE_MODAL');
      return { ...state, isGlobalCandidateModalOpen: true, applicationTokenForGlobalModal: action.value };
    case ACTIONS.CLOSE_GLOBAL_CANDIDATE_MODAL:
      return { ...state, isGlobalCandidateModalOpen: false, applicationTokenForGlobalModal: null };
    default:
      return state;
  }
}
