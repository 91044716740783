import { cancelInterview, sendInterviewCandidateWithInterviewer } from '@api/Interview';
import { InfoIcon } from '@avocadoui/icons';
import Chip from '@components/Avocado/Chip';
import ELConfirmDialog from '@components/Dialog/ELConfirmDialog';
import NotificatIoninterviewPreview from '@components/Notification/NotificatIoninterviewPreview';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useGlobalToast from '@utils/GlobalToast';
import * as NotificationUtil from '@utils/NotificationUtil';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useInterviewDispatch, useInterviewState } from './context';
import styles from './index.module.css';

const SendNotificationToCandidatesDialog = ({
  open,
  onClose,
  periodToken,
  interviewToken,
  candidateToken,
  onSuccess,
}) => {
  const dispatch = useInterviewDispatch();
  const { selectedCandidates = [], newArrangementData } = useInterviewState();
  const {
    smsTemplate = {},
    messageTemplate = {},
    emailTemplate = {},
    interviewerTokens = [],
    enterpriseSmsTemplate = {},
    enterpriseMessageTemplate = {},
    enterpriseEmailTemplate = {},
  } = newArrangementData;
  const GlobalToast = useGlobalToast();
  const [hasActiveCandidateNotification, setHasActiveCandidateNotification] = useState(false);
  const [hasActiveInterviewerNotification, setHasActiveInterviewerNotification] = useState(false);
  const [hasSelectedInterviewers, setHasSelectedInterviewers] = useState(false);

  function handleRemoveCandidate(applicationToken) {
    dispatch({ type: 'SET_SELECTED_CANDIDATES', payload: applicationToken });
  }

  const hasSelectedCandidates = !isEmpty(candidateToken)
    || selectedCandidates.filter((c) => c.canSendNotification).length > 0;
  const canSubmitCandidate = hasSelectedCandidates && hasActiveCandidateNotification;
  const candSubmitInterviewer = hasSelectedInterviewers && hasActiveInterviewerNotification;
  const canSubmit = canSubmitCandidate || candSubmitInterviewer;

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>
        <Box>发送提醒</Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          {isEmpty(candidateToken) && (
            <>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  将要提醒的候选人
                </Typography>
                <Tooltip title="每场邀约仅能给候选人发送一条提醒/天" arrow>
                  <Box width="14px" ml="6px" mr="8px" color="rgba(188, 188, 188, 1)" className={styles.icon}>
                    <InfoIcon />
                  </Box>
                </Tooltip>
              </Box>
              <Box maxHeight="98px" mt={2}>
                {selectedCandidates.map((c) => {
                  if (c.canSendNotification) {
                    return (
                      <Chip
                        mr={1}
                        mb={1.5}
                        variant="outlined"
                        customSize="sizeMedium"
                        color="primary"
                        label={c.name}
                        onDelete={() => handleRemoveCandidate(c.applicationToken)}
                      />
                    );
                  }
                  return (
                    <Chip
                      variant="outlined"
                      customSize="sizeMedium"
                      color="primary"
                      label={c.name}
                      mr={1}
                      mb={1.5}
                      disabled
                    />
                  );
                })}
              </Box>
            </>
          )}
          <Box>
            <NotificatIoninterviewPreview
              periodToken={periodToken}
              interviewHidden={!isEmpty(candidateToken)}
              onInterviewerChange={(data) => {
                dispatch({
                  type: 'SET_NEW_ARRANGEMENT_DATA',
                  payload: {
                    key: 'interviewerTokens',
                    value: data,
                  },
                });
              }}
              interviewToken={interviewToken}
              onCandidateTemplateChange={(t, data) => {
                let key = '';
                if (t === NotificationUtil.NOTIFICATION_TYPE_SMS) {
                  key = 'smsTemplate';
                } else if (t === NotificationUtil.NOTIFICATION_TYPE_WECHAT) {
                  key = 'messageTemplate';
                } else if (t === NotificationUtil.NOTIFICATION_TYPE_EMAIL) {
                  key = 'emailTemplate';
                }
                if (!isEmpty(key)) {
                  dispatch({
                    type: 'SET_NEW_ARRANGEMENT_DATA',
                    payload: { key, value: data },
                  });
                }
              }}
              onInterviewTemplateChange={(t, data) => {
                let key = '';
                if (t === NotificationUtil.NOTIFICATION_TYPE_SMS) {
                  key = 'enterpriseSmsTemplate';
                } else if (t === NotificationUtil.NOTIFICATION_TYPE_WECHAT) {
                  key = 'enterpriseMessageTemplate';
                } else if (t === NotificationUtil.NOTIFICATION_TYPE_EMAIL) {
                  key = 'enterpriseEmailTemplate';
                }
                if (!isEmpty(key)) {
                  dispatch({
                    type: 'SET_NEW_ARRANGEMENT_DATA',
                    payload: { key, value: data },
                  });
                }
              }}
              onAttachmentChange={(data) => {
                dispatch({
                  type: 'SET_NEW_ARRANGEMENT_DATA',
                  payload: { key: 'emailTemplate', value: { ...emailTemplate, attachments: data } },
                });
              }}
              setHasActiveCandidateNotification={setHasActiveCandidateNotification}
              setHasActiveInterviewerNotification={setHasActiveInterviewerNotification}
              setHasSelectedInterviewers={setHasSelectedInterviewers}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const data = {
              periodToken,
              candidateTokens: isEmpty(candidateToken)
                ? selectedCandidates.filter((c) => c.canSendNotification).map((c) => c.candidateToken)
                : [candidateToken],
              candidateTemplate: {
                messageTemplate,
                smsTemplate,
                emailTemplate,
              },
              interviewerTokens: interviewerTokens.map((v) => v.token),
              interviewerTemplate: {
                messageTemplate: enterpriseSmsTemplate,
                smsTemplate: enterpriseMessageTemplate,
                emailTemplate: enterpriseEmailTemplate,
              },
            };
            if (!isEmpty(candidateToken)) {
              delete data.interviewerTokens;
              delete data.interviewerTemplate;
            }

            sendInterviewCandidateWithInterviewer(data).then((result) => {
              if (result.data.code === 0) {
                GlobalToast.success('发送成功');
              } else {
                GlobalToast.warning('发送失败');
              }
              if (onSuccess) onSuccess();
              dispatch({ type: 'REFRESH_DATA' });
              onClose();
            });
          }}
          disabled={!canSubmit}
        >
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SendNotificationToCandidatesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  interviewToken: PropTypes.string,
  periodToken: PropTypes.string,
  candidateToken: PropTypes.string,
  onSuccess: PropTypes.func,
};

SendNotificationToCandidatesDialog.defaultProps = {
  open: false,
  onClose: null,
  interviewToken: '',
  periodToken: '',
  candidateToken: '',
  onSuccess: null,
};

const CancelInterviewDialog = ({
  open, onClose, interviewToken, periodToken,
}) => {
  const dispatch = useInterviewDispatch();
  const GlobalToast = useGlobalToast();

  return (
    <ELConfirmDialog
      open={open}
      title="取消邀约"
      severity="warning"
      desc="是否确定取消本次邀约安排，若只取消个人安排，请点击进个人详情取消"
      confirmTitile="取消邀约"
      onCancel={onClose}
      onConfirm={() => {
        const data = {
          interviewToken,
          periodToken,
          applicationTokens: [],
        };
        cancelInterview(data)
          .then((result) => {
            if (result.data.code === 0) {
              GlobalToast.success('取消邀约成功');
            } else if (result.data.code === -4001) {
              GlobalToast.error('暂无权限，申请开通请联系管理员');
            } else {
              GlobalToast.error('取消邀约失败');
            }
            dispatch({ type: 'REFRESH_DATA' });
            onClose();
          })
          .catch(() => {
            GlobalToast.error('取消邀约失败');
          });
      }}
    />
  );
};

CancelInterviewDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  interviewToken: PropTypes.string,
  periodToken: PropTypes.string,
};

CancelInterviewDialog.defaultProps = {
  open: false,
  onClose: null,
  interviewToken: '',
  periodToken: '',
};

export { CancelInterviewDialog, SendNotificationToCandidatesDialog };
