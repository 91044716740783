import Bar from '@components/Charts/Bar';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styles from './CandidateResult.module.css';
import formatScore from './formatScore';

function mapBar(item) {
  const {
    name,
    scores: { originScore, weight, finalScore },
  } = item;
  return [name, formatScore(originScore), formatScore(weight), formatScore(finalScore)];
}

function AIResultCardItem({ item = {}, moduleWeight }) {
  const abilitiesScores = item.abilities
    .map(mapBar)
    .filter((v) => v[1] !== -1)
    .slice()
    .reverse();

  const gameScores = item.gameScores
    .map(mapBar)
    .filter((v) => v[1] !== -1)
    .slice()
    .reverse();

  if (item.status === 0) {
    return null;
  }

  if (item.status === 1) {
    return (
      <div className={styles.aiResultCardItem}>
        <div style={{ color: '#333333' }}>{item.name}</div>
        <div style={{ marginTop: '2px', fontSize: '12px', color: '#87a0a9' }}>候选人未作答</div>
      </div>
    );
  }

  if (item.status === 2) {
    return (
      <div className={styles.aiResultCardItem}>
        <div style={{ color: '#333333' }}>{item.name}</div>
        <div style={{ marginTop: '2px', fontSize: '12px', color: '#87a0a9' }}>得分计算中</div>
      </div>
    );
  }

  const barHeight = (abilitiesScores.length + 1) * 40;
  const gameBarHeight = (gameScores.length + 1) * 40;

  return (
    <div className={styles.aiResultCardItem}>
      <div style={{ color: '#333333' }}>{item.name}</div>
      <div style={{ marginTop: '2px', fontSize: '12px', color: '#87a0a9' }}>
        得分：{formatScore(item.scores.finalScore)}分 = {formatScore(item.scores.originScore)}(原分值) ×{' '}
        {formatScore(item.scores.weight)}%(单题权重) × {formatScore(moduleWeight)}%(模块权重)
      </div>
      {!isEmpty(abilitiesScores) && (
        <div style={{ height: `${barHeight}px`, display: 'flex' }}>
          <Bar source={abilitiesScores} />
        </div>
      )}
      {!isEmpty(gameScores) && (
        <div style={{ height: `${gameBarHeight}px`, display: 'flex' }}>
          <Bar source={gameScores} />
        </div>
      )}
    </div>
  );
}

AIResultCardItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  moduleWeight: PropTypes.number.isRequired,
};

export default AIResultCardItem;
