import { Box, InputBase, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { AtomicBlockUtils, EditorState, RichUtils } from 'draft-js';
import {
  getEntityRange, getSelectionEntity, setBlockData, toggleCustomInlineStyle,
} from 'draftjs-utils';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import getLinkDecorator from '../ELEditor/decorators/Link';
import '../ELEditor/ELEditor.css';
import ELEditorAttachment from '../ELEditor/ELEditorAttachment';
import ELEditorToolbar, { customStyleMap } from '../ELEditor/ELEditorToolbar';
import MediaRenderer from '../ELEditor/Media';

const ELEditor = (props) => {
  const {
    onSubjectChange,
    subject,
    editorState,
    onEditorStateChange,
    customStyleMap: defaultCustomStyleMap,
    attachments,
    onAttachmentsChange,
  } = props;
  const editorRef = useRef();
  const toggleBlockType = (blockType) => {
    onEditorStateChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    onEditorStateChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const toggleColor = (toggledColor) => {
    const nextEditorState = toggleCustomInlineStyle(editorState, 'color', toggledColor);
    onEditorStateChange(nextEditorState);
  };
  const togglebackgroundColor = (toggledColor) => {
    const nextEditorState = toggleCustomInlineStyle(editorState, 'bgcolor', toggledColor);
    onEditorStateChange(nextEditorState);
  };

  const toggleFontSize = (toggledSize) => {
    const nextEditorState = toggleCustomInlineStyle(editorState, 'fontSize', toggledSize);
    onEditorStateChange(nextEditorState);
  };

  const confirmLink = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    onEditorStateChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
  };

  const removeLink = () => {
    const currentEntity = getSelectionEntity(editorState);
    let selection = editorState.getSelection();
    if (currentEntity) {
      const entityRange = getEntityRange(editorState, currentEntity);
      const isBackward = selection.getIsBackward();
      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start,
        });
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end,
        });
      }
      onEditorStateChange(RichUtils.toggleLink(editorState, selection, null));
    }
  };

  const confirmMedia = (url) => {
    const entityKey = editorState
      .getCurrentContent()
      .createEntity('IMAGE', 'MUTABLE', {
        src: url,
        width: 'auto',
        height: 'auto',
      })
      .getLastCreatedEntityKey();
    onEditorStateChange(AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' '));
  };

  return (
    <Box border="1px solid rgba(0, 0, 0, 0.08)" width="100%" display="flex" flexDirection="column">
      <Box ml={1.5} height="49px">
        <InputBase
          placeholder="请添加邮件主题(必填）"
          fullWidth
          style={{ height: 48 }}
          value={subject}
          onChange={(e) => {
            onSubjectChange(e.target.value.trim());
          }}
          inputProps={{ maxLength: 50 }}
          endAdornment={<Typography style={{ marginRight: 16 }}>{subject.length}/50</Typography>}
        />
        <Divider />
      </Box>
      <ELEditorToolbar
        editorState={editorState}
        toggleColor={toggleColor}
        togglebackgroundColor={togglebackgroundColor}
        toggleFontSize={toggleFontSize}
        toggleBlockType={toggleBlockType}
        toggleInlineStyle={toggleInlineStyle}
        confirmMedia={confirmMedia}
        confirmLink={confirmLink}
        removeLink={removeLink}
        onAlignment={(align) => {
          onEditorStateChange(setBlockData(editorState, { 'text-align': align }));
        }}
      />
      <Box overflow="scroll" height="400px" flexGrow={1} flexShrink={0} display="flex">
        <Box height="100%" width="100%" ml={1.5} mr={1.5}>
          <Editor
            style={{ height: '100%' }}
            customDecorators={[getLinkDecorator({})]}
            showOpenOptionOnHover={false}
            stripPastedStyles
            ref={editorRef}
            blockRendererFn={MediaRenderer}
            toolbarHidden
            editorState={editorState}
            customStyleMap={{ ...customStyleMap, ...defaultCustomStyleMap }}
            onEditorStateChange={(state) => {
              onEditorStateChange(state);
            }}
            localization={{
              locale: 'zh',
            }}
            toolbar={{
              link: {
                showOpenOptionOnHover: false,
              },
            }}
          />
        </Box>
      </Box>
      <ELEditorAttachment
        attachments={attachments}
        onAttachmentsChange={(attachs) => {
          onAttachmentsChange(attachs);
        }}
      />
    </Box>
  );
};

ELEditor.propTypes = {
  editorState: PropTypes.objectOf(PropTypes.any),
  customStyleMap: PropTypes.objectOf(PropTypes.any),
  onEditorStateChange: PropTypes.func,
  onSubjectChange: PropTypes.func,
  subject: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.any),
  onAttachmentsChange: PropTypes.func,
};

ELEditor.defaultProps = {
  editorState: EditorState.createEmpty(),
  customStyleMap: {},
  onSubjectChange: () => {},
  onEditorStateChange: () => {},
  subject: '',
  attachments: [],
  onAttachmentsChange: () => {},
};

export default ELEditor;
