import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useGlobalToast from '@utils/GlobalToast';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import Popover from 'material-ui-popup-state/HoverPopover';
import PropTypes from 'prop-types';
import { useRef } from 'react';

const ExamStatusColors = ['', '#000000DB', '#F5C441FF', '#4CAF50FF', '#FF6C6CFF', '#00000042'];

const StateTypography = (props) => {
  const { style, disabled } = props;
  const color = disabled ? { color: ExamStatusColors[5] } : {};
  return <Typography {...props} style={{ ...style, ...color }} />;
};

StateTypography.propTypes = {
  disabled: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
};

StateTypography.defaultProps = {
  disabled: false,
  style: {},
};

const LinkHoverPopover = ({ disabled, item, onClick }) => {
  const GlobalToast = useGlobalToast();
  const linkRef = useRef();
  const copyLink = () => {
    const linkElement = linkRef.current;
    if (linkElement) {
      linkElement.select();
      document.execCommand('Copy');
      GlobalToast.success('复制成功');
    }
  };
  return (
    <PopupState variant="popover" popupId="demo-popup-menu-c">
      {(popupState) => (
        <>
          <StateTypography
            {...(disabled ? {} : bindHover(popupState))}
            variant="body2"
            style={{ color: '#576B95', cursor: 'pointer' }}
            // disabled={disabled}
            onClick={onClick}
          >
            {item?.candidateInfo?.name}
          </StateTypography>
          <Popover
            {...bindPopover(popupState)}
            variant="menu"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            disableRestoreFocus
          >
            <Box
              display="flex"
              width={385}
              height={58}
              px={2}
              pt={1.25}
              flexDirection="column"
              onMouseLeave={popupState.close}
            >
              <Typography
                variant="caption"
                style={{
                  color: 'rgba(0, 0, 0, 0.26)',
                }}
              >
                将链接发送给候选人：
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography
                  variant="caption"
                  style={{
                    color: '#576B95FF',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    height: 24,
                    width: 299,
                  }}
                >
                  {item.candidateLink}
                </Typography>
                <input
                  value={item.candidateLink}
                  style={{ position: 'absolute', top: -10000 }}
                  ref={linkRef}
                  readOnly
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    copyLink();
                    popupState.close();
                  }}
                >
                  复制
                </Button>
              </Box>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

LinkHoverPopover.propTypes = {
  disabled: PropTypes.bool,
  item: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
};

LinkHoverPopover.defaultProps = {
  disabled: false,
  item: {},
  onClick: () => {},
};

export default LinkHoverPopover;
