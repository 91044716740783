// eslint-disable-next-line import/prefer-default-export
export const DAY_OF_WEEK_NAMES = {
  0: '星期日',
  1: '星期一',
  2: '星期二',
  3: '星期三',
  4: '星期四',
  5: '星期五',
  6: '星期六',
};

export const DEFAULT_VISIBLE_SWITCH = 2;

export const RATING_TEXT = {
  1: '能力远低于岗位要求',
  2: '能力略低于岗位要求',
  3: '能力匹配岗位要求',
  4: '能力略高于岗位要求',
  5: '能力远超岗位要求',
};

export const CANCELED_PROCESS_APPLICATION_STATE = [
  2, // 取消
  3, // 储备
  4, // 已入职
  5, // 已离职
  7, // 已入职 且 取消
  8, // 已离职 且 取消
  9, // 已入职 且 储备
  10, // 已离职 且 储备
];

export const INTERVIEW_WEB_APP_URL = 'https://s-iv.elihr.cn/room';
