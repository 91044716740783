import PropTypes from 'prop-types';
import Chart from './Chart';
import colors from './RosePieColors';

const options = {
  legend: {
    show: false,
  },
  tooltip: {
    position: 'right',
    formatter: (params) => {
      const { data } = params;
      let title = data[1];
      if (title.length > 15) {
        title = title.split(/(.{15})/).filter((x) => x);
      } else {
        title = [title];
      }
      const scoreTxt = data[4] === -1 ? '候选人未作答' : `得分: ${data[4]}分`;
      return `<div>
                <div>${title.map((t) => `${t}<br />`).join('')}</div>
                <div style="margin-top: 4px; font-size: 12px; color: rgba(255,255,255,0.96)">
                  ${scoreTxt}
                </div>
              </div>`;
    },
  },
  series: [
    {
      type: 'pie',
      dimensions: ['type', 'title', 'origin', 'weight', 'final', 'finalInt', 'processIndex'],
      radius: [45, 100],
      roseType: 'radius',
      label: {
        // formatter: '{@final}',
        formatter: (params) => {
          const final = params.data[4];
          if (final <= 8) return '';
          return final.toString();
        },
        position: 'inside',
        fontWeight: 'bold',
        fontSize: 14,
        fontFamily: 'DIN Alternate Bold',
      },
      minShowLabelAngle: 20,
      stillShowZeroSum: false,
      hoverAnimation: false,
      encode: {
        value: 'finalInt',
        weight: 'weight',
      },
      itemStyle: {
        color: (params) => {
          const type = params.data[0];
          const final = params.data[4];
          if (final === -1) {
            return '#fbfbfb';
          }
          if (type === 2) {
            if (final < 8) {
              return 'rgba(59, 203, 120, 0.2)';
            }
            if (final < 20) {
              return 'rgba(59, 203, 120, 0.4)';
            }
            return 'rgba(59, 203, 120, 0.6)';
          }
          const processIndex = params.data[6];
          const color = colors[processIndex];
          if (final < 8) {
            return color[0];
          }
          if (final < 20) {
            return color[1];
          }
          return color[2];
        },
        borderColor: '#ffffff',
        borderWidth: 1,
      },
    },
  ],
};

function RosePie({ source = [] }) {
  return <Chart options={{ ...options, dataset: { source } }} />;
}

RosePie.propTypes = {
  source: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default RosePie;
