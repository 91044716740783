import PropTypes from 'prop-types';
import { Component } from 'react';

class MediaComponent extends Component {
  render() {
    const { block, contentState } = this.props;
    if (block.getEntityAt(0)) {
      const data = contentState.getEntity(block.getEntityAt(0)).getData();
      return (
        <div>
          <img
            src={data.src}
            alt={data.alt || ''}
            style={{ height: data.height || 'auto', width: data.width || 'auto' }}
          />
        </div>
      );
    }

    return '';
  }
}

MediaComponent.propTypes = {
  block: PropTypes.objectOf(PropTypes.any).isRequired,
  contentState: PropTypes.objectOf(PropTypes.any).isRequired,
};

const MediaRenderer = (block) => {
  try {
    const type = block.getType();
    if (type === 'atomic') {
      return {
        component: MediaComponent,
        editable: false,
        props: {},
      };
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

MediaRenderer.propTypes = {
  block: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MediaRenderer;
