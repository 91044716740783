import axios from './Api';

export const getStatusFilterOpts = (positionToken, processToken) => axios.request({
  url: 'applicationSession/candidateStatus/processes',
  method: 'POST',
  data: {
    positionToken,
    processToken,
  },
});

export const getQuestionOpts = (token) => axios.request({
  url: `/applicationSession/${token}/patterns`,
  method: 'GET',
});

export const getApplicationSessionVideo = (token) => {
  if (!token) return Promise.reject();
  return axios.request({
    url: '/applicationSession/videos',
    method: 'POST',
    data: { token },
  });
};

export const getApplicationsList = (data) => axios.request({
  url: '/vc1/applicationSession/applications',
  method: 'POST',
  data,
});

export const getVc1ApplicationSession = (token) => axios.request({
  url: `/vc1/applicationSession/${token}`,
  method: 'GET',
});

export const getTalentPositions = (params) => axios.request({
  url: 'vc1/applicationSessions',
  method: 'GET',
  params,
});

export const getApplicationSession = (token) => axios.request({
  url: `/applicationSession/${token}`,
  method: 'GET',
});

export const getApplicationsPositions = (params) => axios.request({
  url: '/applicationSession/positions',
  method: 'GET',
  params,
});

export const getApplicationsAiModel = (token, data) => axios.request({
  url: `/applicationSession/${token}/aiModel/configs`,
  method: 'POST',
  data,
});

export const getApplicationsHistoryModel = (token, data) => axios.request({
  url: `/applicationSession/${token}/aiModel/history`,
  method: 'POST',
  data,
});

export const getApplicationsUpdateModel = (token) => axios.request({
  url: `/applicationSession/${token}/aiModel/new`,
  method: 'GET',
});
export const postApplicationsUseModel = (token, data) => axios.request({
  url: `/applicationSession/${token}/aiModel`,
  method: 'POST',
  data,
});
export const postApplicationsSyncModel = (token) => axios.request({
  url: `/applicationSession/${token}/aiModel/sync`,
  method: 'POST',
});

export const getApplicationsAiModels = (token) => axios.request({
  url: `/applicationSession/${token}/aiModel/configs`,
  method: 'GET',
});
