import PropTypes from 'prop-types';
import { Component } from 'react';

const isLogin = () => localStorage.getItem('id_token');

export default function withAuth(AuthComponent) {
  class AuthWrapped extends Component {
    componentDidMount() {
      const { history } = this.props;
      if (!isLogin()) {
        const state = history.location;
        history.replace('/layout/login', state);
      }
    }

    render() {
      const { history } = this.props;
      if (isLogin()) {
        return <AuthComponent history={history} />;
      }

      return null;
    }
  }

  AuthWrapped.propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  return AuthWrapped;
}
