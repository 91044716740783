/* eslint-disable react/no-array-index-key */
import { EditIcon } from '@avocadoui/icons';
import {
  Box, Button, makeStyles, Typography,
} from '@material-ui/core';
import CheckBox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import * as CONST_FUNCTION from '@utilsold/ConstFunction';
import PropTypes from 'prop-types';
import Zmage from 'react-zmage';
import { useCandidateDispatch } from '../context';
import styles from './CandidateFormWithAnswer.module.css';
import FormItemNPS from './FormItemNPS';
import ItemHeader from './ItemHeader';
import ItemHeaderWithScore from './ItemHeaderWithScore';

const useStyles = makeStyles({
  label: {
    '& > .MuiFormControlLabel-label.Mui-disabled': {
      color: 'rgba(0,0,0,0.86)',
    },
  },
});

const FormItem = ({ item, formToken, isEnabledScore }) => {
  const {
    formTypeId, formAnswer, formItemToken, isNecessary, formTypeKey, formItemOption,
  } = item;
  const classes = useStyles();
  const dispatch = useCandidateDispatch();

  function handleEditFormItemResult() {
    dispatch({ type: 'OPEN_EDIT_FORM_ITEM_RESULT_DIALOG', payload: item });
  }

  if (!item.formAnswer) {
    return (
      <div className={styles.panel}>
        <ItemHeader isNecessary={isNecessary} item={item}>
          {formTypeKey}
        </ItemHeader>
        <div className={styles.body}>暂未填写</div>
        <div className={styles.editResultBtn}>
          {item.formTypeId !== 9 && <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEditFormItemResult}>修改</Button>}
        </div>
      </div>
    );
  }

  switch (formTypeId) {
    case 1: {
      const hasResult = formAnswer.files && formAnswer.files.filter((url) => !!url).length > 0;
      return (
        <div className={styles.panel}>
          <ItemHeader isNecessary={isNecessary} item={item}>
            {formTypeKey}
          </ItemHeader>
          <div className={styles.body}>
            <div className={styles.images}>
              {hasResult
                ? formAnswer.files.map((answer) => (
                  <Zmage
                    key={answer}
                    backdrop="#00000000"
                    zIndex={99999}
                    className={styles.image}
                    src={answer}
                    controller={{
                      // 关闭按钮
                      close: false,
                      // 缩放按钮
                      zoom: false,
                      // 下载按钮
                      download: false,
                      // 旋转按钮
                      rotate: false,
                      // 翻页按钮
                      flip: false,
                      // 多页指示
                      pagination: false,
                    }}
                  />
                )) : '暂未填写'}
            </div>
            <div className={styles.editResultBtn}>
              <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEditFormItemResult}>修改</Button>
            </div>
          </div>
        </div>
      );
    }
    case 2:
    case 7: {
      return (
        <div className={styles.panel}>
          <ItemHeaderWithScore
            isNecessary={isNecessary}
            item={item}
            formToken={formToken}
            isEnabledScore={isEnabledScore}
            canEdit
          >
            {formTypeKey}
          </ItemHeaderWithScore>
          <div className={styles.body}>{formAnswer.answer}</div>
          <div className={styles.editResultBtn}>
            <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEditFormItemResult}>修改</Button>
          </div>
        </div>
      );
    }
    case 3: {
      return (
        <div className={styles.panel}>
          <ItemHeaderWithScore isNecessary={isNecessary} item={item} isEnabledScore={isEnabledScore}>
            {formTypeKey}
          </ItemHeaderWithScore>
          <div className={styles.body}>
            {formItemOption.alternatives.map((unit) => {
              const CHECKED = formAnswer ? formAnswer.choiceOptions.join().indexOf(unit.key.toString()) !== -1 : false;
              return (
                <FormControlLabel
                  key={unit.key}
                  className={classes.label}
                  disabled
                  control={
                    formItemOption.allowMultiple ? (
                      <CheckBox
                        disabled={!CHECKED}
                        checked={CHECKED}
                        value="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    ) : (
                      <Radio
                        disabled={!CHECKED}
                        checked={CHECKED}
                        value="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    )
                  }
                  label={unit.value}
                />
              );
            })}
          </div>
          <div className={styles.editResultBtn}>
            <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEditFormItemResult}>修改</Button>
          </div>
        </div>
      );
    }
    case 4: {
      return (
        <div className={styles.panel}>
          <ItemHeader isNecessary={isNecessary} item={item}>
            {formTypeKey}
          </ItemHeader>
          <div className={styles.body}>{CONST_FUNCTION.timestampToDateNoTime(formAnswer.dateTime)}</div>
          <div className={styles.editResultBtn}>
            <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEditFormItemResult}>修改</Button>
          </div>
        </div>
      );
    }
    case 8: {
      return (
        <div className={styles.panel}>
          <ItemHeaderWithScore
            isNecessary={isNecessary}
            item={item}
            formToken={formToken}
            isEnabledScore={isEnabledScore}
          >
            {formTypeKey}
          </ItemHeaderWithScore>
          <Box mx={2} mb={2}>
            {formAnswer.answer?.map((v, i) => (
              <Box key={i} display="flex" mt={2}>
                <Typography variant="body2" color="textSecondary" style={{ width: 64, flexShrink: 0 }}>
                  {`回答${i + 1}: `}
                </Typography>
                <Typography variant="body2">{v}</Typography>
              </Box>
            ))}
          </Box>
          <div className={styles.editResultBtn}>
            <Button variant="outlined" startIcon={<EditIcon />} onClick={handleEditFormItemResult}>修改</Button>
          </div>
        </div>
      );
    }
    case 9: {
      return (
        <div className={styles.panel}>
          <ItemHeader isNecessary={isNecessary} item={item}>
            {formTypeKey}
          </ItemHeader>
          <div className={styles.body}>
            <FormItemNPS formItemOption={formItemOption} formAnswer={formAnswer} />
          </div>
        </div>
      );
    }
    default: {
      return (
        <div className={styles.panel}>
          <ItemHeader isNecessary={isNecessary} item={item}>
            {formTypeKey}
          </ItemHeader>
          <div className={styles.body}>{formItemToken}</div>
        </div>
      );
    }
  }
};

FormItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  formToken: PropTypes.string.isRequired,
  isEnabledScore: PropTypes.bool.isRequired,
};

export default FormItem;
