import { createActions } from 'redux-actions';

export const {
  fetchAtList,
  setAtList,
  fetchAtMessageList,
  setAtMessageList,
  setAtMessageReaded,
  sendCommentMessage,
  deleteCommentMessage,
  setInboxMessageList,
  reloadTemporaryDrawer,
} = createActions(
  'FETCH_AT_LIST',
  'SET_AT_LIST',
  'FETCH_AT_MESSAGE_LIST',
  'SET_AT_MESSAGE_LIST',
  'SET_AT_MESSAGE_READED',
  'SEND_COMMENT_MESSAGE',
  'DELETE_COMMENT_MESSAGE',
  'SET_INBOX_MESSAGE_LIST',
  'RELOAD_TEMPORARY_DRAWER',
);

export default {};
