import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: 'white',
    borderTopLeftRadius: '8px',
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  basicInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  basicInfo: {
    display: 'flex',
    backgroundColor: 'white',
  },
  basicAvatar: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
    marginTop: -8,
    backgroundColor: 'white',
  },
  basicInfoLineWrapper: {
    position: 'relative',
    width: '100%',
    '& div:first-child': {
      margin: 0,
    },
    backgroundColor: 'white',
  },
  basicInfoLine: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4px',
    height: 36,
    backgroundColor: 'white',
    '& > div': {
      marginLeft: '16px',
    },
    '& div:first-child': {
      margin: 0,
    },
  },
  infoName: {
    fontWeight: 'medium',
    backgroundColor: 'white',
  },
  actionButtons: {
    display: 'flex',
    '& .MuiIconButton-root': {},
  },
  tags: {
    position: 'relative',
    marginTop: '1px',
  },
  tagList: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagItem: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    marginRight: '0.5rem',
    '&:last-child': {
      marginRight: 0,
    },
    marginBottom: 4,
  },
  addTagWrap: {
    position: 'relative',
    padding: '2px 6px',
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.12)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addIcon: {
    width: '14px',
    height: '14px',
    color: 'rgba(0,0,0,0.54)',
  },
  addTag: {
    fontSize: '12px',
  },
  deleteDialog: {
    width: 300,
    padding: '24px 16px 12px 16px',
  },
  deleteTips: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  form: {
    '& .MuiTextField-root': {
      marginLeft: '1rem',
      width: '10ch',
      '&:first-child': {
        marginLeft: 0,
      },
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
    },
  },
}));

export default useStyles;
