import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

const TipTitle = ({ children }) => (
  <Box display="flex" alignItems="center">
    <Box width={5} height={5} style={{ background: '#D8D8D8', borderRadius: 2.5 }} mr={1} />
    <Typography variant="body2" color="textSecondary">{children}</Typography>
    <Box width={5} height={5} style={{ background: '#D8D8D8', borderRadius: 2.5 }} ml={1} />
  </Box>
);

TipTitle.propTypes = {
  children: PropTypes.node,
};

TipTitle.defaultProps = {
  children: null,
};

export default TipTitle;
