import styles from './CandidateResume.module.css';
import UploadResume from './UploadResume';

const FoundNoResume = () => (
  <div className={styles.foundNoResume} style={{ marginTop: '20%' }}>
    <img src="//webcdn.fbmms.cn/img/waitExam.png" alt="简历" className={styles.image} />
    <div className={styles.title}>该面试者尚无简历</div>
    <div className={styles.subtitle}>您可以从本地上传其简历</div>
    <UploadResume button={<span className={styles.uploadButton}>上传简历</span>} />
  </div>
);

export default FoundNoResume;
