import { Box, makeStyles, Typography } from '@material-ui/core';
import { blueGrey, green } from '@material-ui/core/colors';
import clsx from 'clsx';
import { range } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  selected: {
    backgroundColor: green[400],
    color: 'white',
  },
  nomarl: {
    backgroundColor: blueGrey[50],
  },
});

const FormItemNPS = ({ formItemOption, formAnswer }) => {
  const classes = useStyles();

  const { from = {}, to = {} } = formItemOption;
  const { answer = 0 } = formAnswer;

  return (
    <Box>
      <Box width display="flex">
        {range(from.score, to.score + 1).map((v) => (
          <Box
            flexGrow={1}
            border="1px solid white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={30}
            className={clsx({
              [classes.selected]: answer >= v,
              [classes.nomarl]: answer < v,
            })}
          >
            <Typography variant="body2" color="inherit" style={{ fontWeight: 500 }}>
              {v}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box width display="flex" justifyContent="space-between" mt={1}>
        <Typography variant="body2" color="textSecondary">
          {from.remark}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {to.remark}
        </Typography>
      </Box>
    </Box>
  );
};

FormItemNPS.propTypes = {
  formItemOption: PropTypes.objectOf(PropTypes.any).isRequired,
  formAnswer: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default FormItemNPS;
