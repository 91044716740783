import * as types from '../constants/ActionTypes';

export function addProcess(token, name, status, createUserName, createdAt) {
  return {
    type: types.ADD_SESSION,
    token,
    name,
    status,
    createUserName,
    createdAt,
  };
}
export function addProcesses(processes, processesCount) {
  return { type: types.ADD_SESSIONS, processes, processesCount };
}
export function editProcess(id, name, token, positionName, positionDesc, patternTokens) {
  return {
    type: types.EDIT_APPLICATION_SESSION,
    id,
    name,
    token,
    positionName,
    positionDesc,
    patternTokens,
  };
}
export function clearLocalProcesses() {
  return { type: types.CLEAR_LOCAL_SESSION };
}
export function addSelectedProcess(token) {
  return { type: types.ADD_SELECTED_PROCESS, token };
}
export function clearSelectedProcess() {
  return { type: types.CLEAR_SELECTED_PROCESS };
}
export function removeSelectedProcess(token) {
  return { type: types.REMOVE_SELECTED_PROCESS, token };
}
export function selectedAllProcess() {
  return { type: types.SELECTED_ALL_PROCESS };
}
export function updateProcessFilter(filter) {
  return { type: types.UPDATE_PROCESS_FILTER, filter };
}

export function clearProcessFilter() {
  return { type: types.CLEAR_PROCESS_FILTER };
}
export function reloadProcesses() {
  return { type: types.RELOAD_PROCESSES };
}
