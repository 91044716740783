import { createActions } from 'redux-actions';

export const {
  setIsFocused,
  inputClickAway,
  cancelSearch,
  addToChipList,
  changeInput,
  removeTailFromChipList,
  removeIndexFromChipList,
  leaveSearch,
  setSearchStat,
  setIsSearching,
  openCandidateModal,
} = createActions(
  {},
  'SET_IS_FOCUSED',
  'INPUT_CLICK_AWAY',
  'CANCEL_SEARCH',
  'ADD_TO_CHIP_LIST',
  'CHANGE_INPUT',
  'REMOVE_TAIL_FROM_CHIP_LIST',
  'REMOVE_INDEX_FROM_CHIP_LIST',
  'LEAVE_SEARCH',
  'SET_SEARCH_STAT',
  'SET_IS_SEARCHING',
  'OPEN_CANDIDATE_MODAL',
);

export default {};
