import Button from '@material-ui/core/Button';
import { useEffect, useState } from 'react';
import { CANCELED_PROCESS_APPLICATION_STATE } from '../const';
import { useCandidateDispatch, useCandidateState } from '../context';
import { EmptyStatus } from '../Placeholder';
import styles from './CandidateTabs.module.css';

function FoundNoInterview() {
  const [canSend, setCanSend] = useState(false);
  const { applicationData = {} } = useCandidateState();
  const dispatch = useCandidateDispatch();
  const isCanceled = useCandidateState((state) => CANCELED_PROCESS_APPLICATION_STATE
    .indexOf(state.applicationData.applicationState) > -1);

  useEffect(() => {
    setCanSend(applicationData.canSendInvitation);
  }, [applicationData.canSendInvitation]);

  function handleSend() {
    dispatch({ type: 'CLICKED_SEND_BUTTON' });
  }

  if (applicationData.currentProcessToken === 'DhjxBr1CFM3PnmbO3PeoQ79L7W') {
    return (
      <div className={styles.full}>
        <div>
          <EmptyStatus style={{ width: '100px' }} />
        </div>
        <div style={{ margin: '12px 0', textAlign: 'center' }}>
          <div>暂无面试内容</div>
        </div>
      </div>
    );
  }

  const disabledSendBtn = !canSend || isCanceled;

  return (
    <div className={styles.full}>
      <div>
        <EmptyStatus style={{ width: '100px' }} />
      </div>
      <div style={{ margin: '12px 0', textAlign: 'center' }}>
        <div>该面试者尚未开始面试</div>
        <div>若长时间未开始，建议你给面试者</div>
        <div>发送短信提醒</div>
      </div>
      {applicationData.hasNotification && (
        <Button size="large" color="primary" variant="outlined" disabled={disabledSendBtn} onClick={handleSend}>
          {canSend ? '发送提醒' : '已提醒'}
        </Button>
      )}
    </div>
  );
}

export default FoundNoInterview;
