import CodeInterviewTab from '@components/ShowMeBug/CodeInterviewTab';
import WrittenTab from '@components/ShowMeBug/WrittenTab';
import PropTypes from 'prop-types';
import CandidateAssignablePositions from '../CandidateAssignablePositions';
import CandidateAttachments from '../CandidateAttachments/CandidateAttachments';
import CandidateEvaluation from '../CandidateEvaluation/CandidateEvaluation';
import CandidateFormWithAnswer from '../CandidateFormWithAnswer/CandidateFormWithAnswer';
import CandidateInterviewArrangement from '../CandidateInterviewArrangement/CandidateInterviewArrangement';
import CandidateReferrals from '../CandidateReferrals';
import CandidateResult from '../CandidateResult/CandidateResult';
import CandidateResume from '../CandidateResume/CandidateResume';
import { useCandidateState } from '../context';
import FoundNoInterview from './FoundNoInterview';
import FoundNoPosition from './FoundNoPosition';

function Loading() {
  return null;
}

const TabSwitcher = ({ tabName, onClose, checkTemplateMsg }) => {
  const { applicationData } = useCandidateState();
  let theNameOfTab = tabName;

  let formToken;
  if (tabName?.indexOf('form') > -1) {
    [theNameOfTab, formToken] = tabName.split('::');
  }

  let processToken;
  if (tabName?.indexOf('interview') > -1) {
    [theNameOfTab, processToken] = tabName.split('::');
  }

  let applicationProcessToken;
  if (tabName?.indexOf('exam') > -1) {
    [theNameOfTab, applicationProcessToken] = tabName.split('::');
  }

  if (tabName?.indexOf('codeReview') > -1) {
    [theNameOfTab, applicationProcessToken] = tabName.split('::');
  }

  let el;
  switch (theNameOfTab) {
    case 'loading':
      el = <Loading />;
      break;
    case 'result':
      el = <CandidateResult onClose={onClose} />;
      break;
    case 'form':
      el = <CandidateFormWithAnswer formToken={formToken} />;
      break;
    case 'resume':
      el = <CandidateResume />;
      break;
    case 'position':
      el = <CandidateAssignablePositions />;
      break;
    case 'no_position':
      el = <FoundNoPosition />;
      break;
    case 'no_interview':
      el = <FoundNoInterview />;
      break;
    case 'attachments':
      el = <CandidateAttachments />;
      break;
    case 'referrals':
      el = <CandidateReferrals />;
      break;
    case 'interview':
      el = <CandidateInterviewArrangement processToken={processToken} />;
      break;
    case 'exam':
      el = <WrittenTab applicationProcessToken={applicationProcessToken} checkTemplateMsg={checkTemplateMsg} />;
      break;
    case 'codeReview':
      el = <CodeInterviewTab applicationProcessToken={applicationProcessToken} checkTemplateMsg={checkTemplateMsg} />;
      break;
    default: {
      el = <div />;
    }
  }

  return (
    <>
      {el}
      {applicationData.applicationToken && (
        <CandidateEvaluation />
      )}
    </>
  );
};

TabSwitcher.propTypes = {
  onClose: PropTypes.func,
  tabName: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  checkTemplateMsg: PropTypes.func,
};

TabSwitcher.defaultProps = {
  onClose: () => {},
  tabName: '',
  checkTemplateMsg: () => {},
};

export default TabSwitcher;
