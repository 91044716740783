import { getApplicationLogs } from '@api/Application';
import * as API from '@api/Process';
import { KeyboardArrowDownIcon } from '@avocadoui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  TextField,
  withStyles,
} from '@material-ui/core';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCandidateDispatch, useCandidateState } from '../context';
import ConfirmPopover from './ConfirmPopover';
import ReasonPopover from './ReasonPopover';

const Menu = withStyles(() => ({
  paper: {
    minWidth: 200,
  },
}))(MuiMenu);

const MoreButtonMenu = withStyles(() => ({
  paper: {
    minWidth: 140,
  },
}))(MuiMenu);

const YI_TONG_GUO_PROCESS_TOKEN = 'U4A0YXcShXv0DlLxxg58Q79L7V';
const YI_RU_ZHI_PROCESS_TOKEN = 'UCIkPVNu57gO4fl9ykv0Q956X9';
const YI_LIZHI_PROCESS_TOKEN = 'VjaL28zeAZQTBDSDTijZQ956XY';

const ActionButtonsOnly = ({
  index,
  stage,
  isActive,
  isLayined,
  isCanceled,
  isFailed,
  sureMoveToStep,
  handleRecoverApplication,
  handleLayinApplication,
  checkTemplateMsg,
  handleCancelApplication,
  setProcessStatus,
  rejectInterview,
}) => {
  const dispatch = useCandidateDispatch();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const state = useCandidateState();
  const { applicationData, applicationLogsData } = state;
  const {
    currentProcess, currentProcessToken, hasNotification, canSendInvitation,
  } = applicationData;
  const popupStateNextStep = usePopupState({ variant: 'popover', popupId: 'next-step' });
  const popupStateMoreBtn = usePopupState({ variant: 'popover', popupId: 'more-btn' });
  const isYiTongGuo = currentProcessToken === YI_TONG_GUO_PROCESS_TOKEN;

  function getInterComment(_applicationToken) {
    getApplicationLogs(_applicationToken).then((result) => {
      if (result.data.code === 0) {
        dispatch({
          type: 'SET_APPLICATION_LOGS_DATA',
          payload: result.data.data.list,
        });
      }
    });
  }

  useEffect(() => {
    if (currentProcess) {
      const defaultStatus = currentProcess.processStatus.find((v) => v.isDefault === 1);
      if (defaultStatus) {
        setProcessStatus(defaultStatus.token);
      }
    }
  }, [currentProcess]);

  if (stage === 2) {
    if (isActive && index === 0) {
      return (
        <Box>
          <ReasonPopover
            scene="LI_ZHI"
            title="确认离职？"
            placeholder="离职原因（不会告知候选人）"
            actionText="离职"
            actionColor="secondary"
            handleSubmit={(reason) => sureMoveToStep(YI_LIZHI_PROCESS_TOKEN, undefined, reason)}
            parentPopupState={popupStateMoreBtn}
          >
            <Button variant="outlined" fullWidth>
              离职
            </Button>
          </ReasonPopover>
          <Box mt={1.5}>
            <ConfirmPopover
              parentPopupState={popupStateMoreBtn}
              title="确认撤回？"
              actionColor="primary"
              actionText="撤回"
              handleSubmit={() => handleRecoverApplication(4)}
            >
              <Button variant="outlined" fullWidth>
                撤回入职
              </Button>
            </ConfirmPopover>
          </Box>
        </Box>
      );
    }
    if (isActive && index === 1) {
      if (isLayined) {
        return (
          <ConfirmPopover
            parentPopupState={popupStateMoreBtn}
            title="确认撤回？"
            desc="撤回后，流程里候选人相关的资料可能被覆盖掉，确认撤回吗？"
            actionColor="primary"
            actionText="确认"
            handleSubmit={() => handleRecoverApplication(3)}
          >
            <Button variant="outlined" fullWidth>
              撤回人才储备
            </Button>
          </ConfirmPopover>
        );
      }
      return (
        <Box>
          <ConfirmPopover
            parentPopupState={popupStateMoreBtn}
            title="确认撤回？"
            desc="撤回后，流程里候选人相关的资料可能被覆盖掉，确认撤回吗？"
            actionColor="primary"
            actionText="撤回"
            handleSubmit={() => handleRecoverApplication(5)}
          >
            <Button variant="outlined" fullWidth>
              撤回离职
            </Button>
          </ConfirmPopover>
          <Box mt={1.5}>
            <ReasonPopover
              scene="CHU_BEI"
              title="确认加入人才储备？"
              desc="这将结束该候选人招聘流程。"
              placeholder="加入原因"
              actionText="加入"
              actionColor="primary"
              handleSubmit={handleLayinApplication}
              parentPopupState={popupStateMoreBtn}
            >
              <Button variant="outlined" fullWidth>
                人才储备
              </Button>
            </ReasonPopover>
          </Box>
        </Box>
      );
    }
  }

  if (isActive) {
    if (isCanceled) {
      return (
        <ConfirmPopover
          parentPopupState={popupStateMoreBtn}
          title="确认撤回？"
          desc="撤回后，流程里候选人相关的资料可能被覆盖掉，确认撤回吗？"
          actionColor="primary"
          actionText="撤回"
          handleSubmit={() => handleRecoverApplication(2)}
        >
          <Button variant="outlined" fullWidth>
            撤回取消流程
          </Button>
        </ConfirmPopover>
      );
    }

    if (isFailed) {
      return (
        <Box key="failed" display="flex">
          <Box flex={1}>
            <Button variant="outlined" fullWidth onClick={() => handleRecoverApplication(6)}>
              撤回淘汰
            </Button>
          </Box>
          <Box ml={1}>
            <Button variant="outlined" {...bindTrigger(popupStateMoreBtn)}>
              ...
            </Button>
            <MoreButtonMenu
              {...bindMenu(popupStateMoreBtn)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem disabled={!canSendInvitation}>
                <ListItemText
                  primary={canSendInvitation ? '发送提醒' : '已提醒'}
                  onClick={(e) => {
                    if (popupStateMoreBtn) popupStateMoreBtn.close(e);
                    checkTemplateMsg();
                  }}
                />
              </MenuItem>
              <MenuItem>
                <ConfirmPopover
                  parentPopupState={popupStateMoreBtn}
                  desc="这将结束该候选人招聘流程。"
                  actionColor="primary"
                  actionText="确认"
                  handleSubmit={handleCancelApplication}
                >
                  <ListItemText primary="取消流程" />
                </ConfirmPopover>
              </MenuItem>
            </MoreButtonMenu>
          </Box>
        </Box>
      );
    }
    if (isLayined) {
      return (
        <ConfirmPopover
          parentPopupState={popupStateMoreBtn}
          title="确认撤回？"
          desc="撤回后，流程里候选人相关的资料可能被覆盖掉，确认撤回吗？"
          actionColor="primary"
          actionText="确认"
          handleSubmit={() => handleRecoverApplication(3)}
        >
          <Button variant="outlined" fullWidth>
            撤回人才储备
          </Button>
        </ConfirmPopover>
      );
    }
    const steps = applicationLogsData.filter(({ stage: stageType }) => stageType === 1);
    return (
      <Box>
        <ButtonGroup variant="contained" color="primary" fullWidth>
          {isYiTongGuo ? (
            <ConfirmPopover
              parentPopupState={popupStateMoreBtn}
              desc="这将结束该候选人招聘流程。"
              actionColor="primary"
              actionText="确认"
              handleSubmit={() => sureMoveToStep(YI_RU_ZHI_PROCESS_TOKEN)}
            >
              <Button color="primary">进入 已入职</Button>
            </ConfirmPopover>
          ) : (
            <Button
              color="primary"
              onClick={() => sureMoveToStep(steps[index + 1]?.processToken, steps[index + 1]?.processType)}
            >
              进入 {steps[index + 1]?.processName}
            </Button>
          )}
          <Button size="small" style={{ width: '48px' }} {...bindTrigger(popupStateNextStep)}>
            <KeyboardArrowDownIcon />
          </Button>
          <Menu
            {...bindMenu(popupStateNextStep)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            style={{ width: 200 }}
          >
            {steps.map((step) => (
              <MenuItem
                key={step.processToken}
                onClick={(e) => {
                  popupStateNextStep.close(e);
                  sureMoveToStep(step.processToken, step.processType);
                }}
              >
                <ListItemText primary={step.processName} />
              </MenuItem>
            ))}
          </Menu>
        </ButtonGroup>

        {currentProcess?.processStatus && currentProcess.processStatus?.length > 0 && (
          <Box mt={2}>
            <TextField
              select
              variant="outlined"
              displayEmpty
              label={
                applicationLogsData.find((v) => v.processToken === currentProcessToken).processStatus ? '' : '标记为'
              }
              fullWidth
              value={applicationLogsData.find((v) => v.processToken === currentProcessToken).processStatus}
              onChange={(e) => {
                const value = e.target.value === 'reset' ? '' : e.target.value;
                setProcessStatus(value);
                API.postProcessStatus(currentProcessToken, [applicationToken], value)
                  .then((result) => {
                    if (result.data.code === 0) {
                      getInterComment(applicationToken);
                    }
                  })
                  .catch(console.log);
              }}
              size="small"
            >
              <MenuItem value="reset">重置</MenuItem>
              {currentProcess.processStatus.map((option) => (
                <MenuItem key={option.token} value={option.token}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}

        <Box mt={2} display="flex">
          {hasNotification && (
            <Box mr={1} flex={1}>
              <Button variant="outlined" fullWidth onClick={checkTemplateMsg} disabled={!canSendInvitation}>
                {canSendInvitation ? '发送提醒' : '已提醒'}
              </Button>
            </Box>
          )}
          <Box mr={1} flex={1}>
            <ReasonPopover
              scene="TAO_TAI"
              title="确认淘汰？"
              placeholder="淘汰原因(不会在通知短信里展示)"
              actionText="淘汰"
              handleSubmit={rejectInterview}
            >
              <Button variant="outlined" fullWidth>
                淘汰
              </Button>
            </ReasonPopover>
          </Box>
          <Box>
            <Button variant="outlined" {...bindTrigger(popupStateMoreBtn)}>
              ...
            </Button>
            <MoreButtonMenu
              {...bindMenu(popupStateMoreBtn)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem>
                <ConfirmPopover
                  parentPopupState={popupStateMoreBtn}
                  desc="这将结束该候选人招聘流程。"
                  actionColor="primary"
                  actionText="确认"
                  handleSubmit={handleCancelApplication}
                >
                  <ListItemText primary="取消流程" />
                </ConfirmPopover>
              </MenuItem>
              <MenuItem>
                <ReasonPopover
                  scene="CHU_BEI"
                  title="确认加入人才储备？"
                  desc="这将结束该候选人招聘流程。"
                  placeholder="加入原因"
                  actionText="加入"
                  actionColor="primary"
                  handleSubmit={handleLayinApplication}
                  parentPopupState={popupStateMoreBtn}
                >
                  <ListItemText primary="人才储备" />
                </ReasonPopover>
              </MenuItem>
            </MoreButtonMenu>
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};

ActionButtonsOnly.propTypes = {
  index: PropTypes.number.isRequired,
  stage: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  isLayined: PropTypes.bool.isRequired,
  isCanceled: PropTypes.bool,
  isFailed: PropTypes.bool,
  sureMoveToStep: PropTypes.func.isRequired,
  handleRecoverApplication: PropTypes.func.isRequired,
  handleLayinApplication: PropTypes.func.isRequired,
  checkTemplateMsg: PropTypes.func.isRequired,
  handleCancelApplication: PropTypes.func.isRequired,
  setProcessStatus: PropTypes.func.isRequired,
  rejectInterview: PropTypes.func.isRequired,
};

ActionButtonsOnly.defaultProps = {
  isCanceled: false,
  isFailed: false,
};

export default ActionButtonsOnly;
