const conf = {
  development: {
    config: {
      staging: 'http://10.10.7.169:10001/',
      test: 'https://b-test000.elihr.cn/api/',
      test1: 'https://b-test001.elihr.cn/api/',
      prod: 'https://b.elihr.cn/api/',
    },
  },
  production: {
    config: {
      staging: 'https://f.fbmms.cn/api/',
      test: 'https://b-test000.elihr.cn/api/',
      test1: 'https://b-test001.elihr.cn/api/',
      prod: 'https://b.elihr.cn/',
    },
  },
};

// 注意⚠️：process.env.NODE_ENV 这个变量不能自定义
const API_URL = conf[process.env.NODE_ENV].config[process.env.REACT_APP_ENV];
export default API_URL;

// 部署的时候需要在生成服务器上设置如下环境变量
// $vi /etc/profile
// export REACT_APP_ENV=prod

// 部署的时候需要在staging服务器上设置如下环境变量
// $vi /etc/profile
// export REACT_APP_ENV=staging

// 部署的时候需要在test服务器上设置如下环境变量
// $vi /etc/profile
// export REACT_APP_ENV=test
