import {
  CheckIcon, ErrorIcon, InfoIcon, WarningIcon,
} from '@avocadoui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

const iconStyles = {
  fontSize: 24,
  marginInlineEnd: 16,
};

const iconVariants = {
  error: <ErrorIcon style={{ ...iconStyles, color: '#FF6C6C' }} />,
  info: <InfoIcon style={{ ...iconStyles, color: '#3DA0F5' }} />,
  success: <CheckIcon style={{ ...iconStyles, color: '#00D99D' }} />,
  warning: <WarningIcon style={{ ...iconStyles, color: '#F5C441' }} />,
};

const useStyles = makeStyles(() => ({
  root: {
    top: 48,
  },
  // default variant
  contentRoot: {
    backgroundColor: '#fff !important',
    color: 'rgba(0, 0, 0, 0.86) !important',
    padding: '4px 36px 4px 16px',
    boxShadow:
      '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
  },
}));

export default function ToastProvider({ children }) {
  const classes = useStyles();

  return (
    <SnackbarProvider maxSnack={3} classes={classes} iconVariant={iconVariants} autoHideDuration={3000}>
      {children}
    </SnackbarProvider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
