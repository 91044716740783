import Typography from '@components/Avocado/Typography';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Radio,
  RadioGroup,
  TextField, withStyles,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { RATING_TEXT } from '../const';
import { useEvaluationDispatch, useEvaluationState } from './context';

const useStyle = makeStyles(() => ({
  itemWrap: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '16px 16px 16px 36px',
    marginTop: '16px',
  },
  feedbackCommon: {
    position: 'relative',
    display: 'inline-block',
    padding: '4px 15px',
    fontWeight: 500,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.04)',
      opacity: 0,
    },
    '&:hover:after': {
      opacity: 1,
    },
  },
  feedbackSelectFail: {
    backgroundColor: '#FFEBEE',
    color: '#611A15',
  },
  feedbackSelectTodo: {
    backgroundColor: '#E3F2FD',
    color: '#0D3C61',
  },
  feedbackSelectSuccess: {
    backgroundColor: '#EDF7ED',
    color: '#1E4620',
  },
}));

const ScoreField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: 0,
      maxWidth: '40px',
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 500,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4CAF50',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4CAF50',
    },
  },
})(TextField);

function QuestionScoreInput({ item }) {
  const [localScore, setLocalScore] = useState('');
  const { formItemToken, total } = item;
  const state = useEvaluationState();
  const dispatch = useEvaluationDispatch();
  const { formData } = state;
  const { score } = formData[formItemToken] || {};

  useEffect(() => {
    setLocalScore(score || '');
  }, [score]);

  return (
    <Box display="flex" alignItems="flex-start" flexShrink={0} width={100}>
      <ScoreField
        variant="outlined"
        value={localScore}
        placeholder="请输入"
        onChange={(e) => {
          const hasDot = e.target.value.indexOf('.') > -1;
          if (hasDot) return;
          const numberValue = Number(e.target.value);
          if (!Number.isNaN(numberValue) && numberValue >= 0 && numberValue <= total) {
            setLocalScore(e.target.value);
          } else {
            setLocalScore('');
          }
        }}
        onBlur={(e) => {
          if (e.target.value === '' || (Number(e.target.value) >= 0 && Number(e.target.value) <= total)) {
            dispatch({
              type: 'SET_TEMPLATE_FORM_DATA_QUESTION_SCORE',
              payload: { token: formItemToken, score: e.target.value },
            });
          }
        }}
      />
      <Box>
        <Typography variant="body2" color="text.secondary" ml="2px">
          /{total}分
        </Typography>
      </Box>
    </Box>
  );
}

QuestionScoreInput.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function RadioSelectScoreView({ item }) {
  const { formItemToken, total } = item;
  const state = useEvaluationState();
  const { formData } = state;
  const { score = 0 } = formData[formItemToken] || {};

  return (
    <Box display="flex">
      <Typography variant="body2" color="text.secondary">
        {score}/{total}分
      </Typography>
    </Box>
  );
}

RadioSelectScoreView.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function CheckboxScoreView({ item }) {
  const { formItemToken, total } = item;
  const state = useEvaluationState();
  const { formData } = state;
  const { score = 0 } = formData[formItemToken] || {};

  return (
    <Box display="flex">
      <Typography variant="body2" color="text.secondary">
        {score}/{total}分
      </Typography>
    </Box>
  );
}

CheckboxScoreView.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function ScoreSwitcher({ item }) {
  const { formTypeId, isScoreOpen } = item;

  if (isScoreOpen === 2) {
    return null;
  }

  if (formTypeId === 2) {
    return <QuestionScoreInput item={item} />;
  }

  if (formTypeId === 3) {
    return <RadioSelectScoreView item={item} />;
  }

  if (formTypeId === 6) {
    return <CheckboxScoreView item={item} />;
  }

  return null;
}

ScoreSwitcher.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function QuestionInput({ item }) {
  const { formItemToken } = item;
  const formData = useEvaluationState((state) => state.formData);
  const dispatch = useEvaluationDispatch();
  const value = formData[formItemToken]?.value || '';

  const handleChange = useCallback((e) => {
    const payload = { token: formItemToken, value: e.target.value };
    dispatch({ type: 'SET_TEMPLATE_FORM_DATA_QUESTION', payload });
  }, [formItemToken]);

  return useMemo(() => (
    <TextField
      value={value}
      onChange={handleChange}
      fullWidth
      multiline
      inputProps={{ maxLength: 750 }}
      placeholder="用户回答"
    />
  ), [value, handleChange]);
}
const MemoQuestionInput = memo(QuestionInput);

QuestionInput.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function RadioSelectInput({ item }) {
  const { formItemToken, formItemOption = {} } = item;
  const { alternatives = [] } = formItemOption;
  const formData = useEvaluationState((state) => state.formData);
  const dispatch = useEvaluationDispatch();
  const value = formData[formItemToken]?.value || [];

  return (
    <RadioGroup
      value={value[0] || []}
      onChange={(e) => {
        dispatch({
          type: 'SET_TEMPLATE_FORM_DATA_RADIO',
          payload: {
            token: formItemToken,
            value: e.target.value,
          },
        });
      }}
    >
      {alternatives.map((v) => (
        <FormControlLabel key={v.key} value={v.key} control={<Radio color="primary" />} label={v.value} />
      ))}
    </RadioGroup>
  );
}
const MemoRadioSelectInput = memo(RadioSelectInput);

RadioSelectInput.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function CheckboxSelectInput({ item }) {
  const { formItemToken, formItemOption = {} } = item;
  const { alternatives = [] } = formItemOption;
  const formData = useEvaluationState((state) => state.formData);
  const dispatch = useEvaluationDispatch();
  const value = formData[formItemToken]?.value || [];
  return (
    <FormGroup>
      {alternatives.map((v) => (
        <FormControlLabel
          key={v.key}
          control={(
            <Checkbox
              name={v.key}
              checked={value.indexOf(v.key) > -1}
              onChange={(e) => {
                dispatch({
                  type: 'SET_TEMPLATE_FORM_DATA_CHECKBOX',
                  payload: {
                    token: formItemToken,
                    name: e.target.name,
                    checked: e.target.checked,
                  },
                });
              }}
            />
          )}
          label={v.value}
        />
      ))}
    </FormGroup>
  );
}
const MemoCheckboxSelectInput = memo(CheckboxSelectInput);

CheckboxSelectInput.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function InputSwitcher({ item }) {
  const { formTypeId } = item;

  if (formTypeId === 2) {
    return <MemoQuestionInput item={item} />;
  }

  if (formTypeId === 3) {
    return <MemoRadioSelectInput item={item} />;
  }

  if (formTypeId === 6) {
    return <MemoCheckboxSelectInput item={item} />;
  }

  if (formTypeId === 999) {
    return <MemoFeedbackInput />;
  }

  return null;
}

InputSwitcher.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function EvaluationFormEditor({ item, index }) {
  return (
    <Box
      mt={2}
      px={2}
      py={1.5}
      border="1px solid rgba(0, 0, 0, 0.08)"
      borderRadius="4px"
    >
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Typography variant="body2" color="text.primary">
          <Box display="flex" alignItems="center">
            <Box display="flex">
              {
                item.isNecessary && (
                  <Box ml={-1}>
                    {item.isNecessary && <span style={{ color: '#FF6C6C' }}>*</span>}
                  </Box>
                )
              }
              <Typography variant="body2" color="text.primary" style={{ wordBreak: 'break-word' }}>{index + 1}. {item.formTypeKey}</Typography>
            </Box>

          </Box>
        </Typography>
        <Box style={{ width: 85 }} mt={0.5}>
          <ScoreSwitcher item={item} index={index} />
        </Box>
      </Box>
      <Box mt={1}>
        <InputSwitcher item={item} index={index} />
      </Box>
    </Box>
  );
}

export default memo(EvaluationFormEditor);

function FeedbackInput() {
  const classes = useStyle();
  const [selectValue, setSelectValue] = useState(null);
  const [activeValue, setActiveValue] = useState(null);
  const [hoverValue, setHoverValue] = useState(-1);
  const feedbackData = useEvaluationState((state) => state.feedbackData);
  const dispatch = useEvaluationDispatch();

  useEffect(() => {
    setSelectValue(feedbackData?.level);
    setActiveValue(feedbackData?.stars);
  }, [feedbackData]);

  function handleClick(value) {
    if (value === selectValue) {
      setSelectValue(null);
      dispatch({ type: 'SET_TEMPLATE_FORM_DATA_FEEDBACK', payload: { key: 'level', value: null } });
    } else {
      setSelectValue(value);
      dispatch({ type: 'SET_TEMPLATE_FORM_DATA_FEEDBACK', payload: { key: 'level', value } });
    }
  }

  function handleChange(_, value) {
    setActiveValue(value);
    dispatch({ type: 'SET_TEMPLATE_FORM_DATA_FEEDBACK', payload: { key: 'stars', value } });
  }

  function handleChangeActive(_, value) {
    setHoverValue(value);
  }

  return (
    <>
      <Box mt={2} display="flex" alignItems="center">
        <Typography variant="body2" color="text.secondary">
          面试结果
        </Typography>
        <Box display="flex" alignItems="center" ml={1}>
          <Box
            onClick={() => handleClick(1)}
            className={clsx(classes.feedbackCommon, selectValue === 1 && classes.feedbackSelectFail)}
          >
            <Typography variant="body2" color="inherit">
              不通过
            </Typography>
          </Box>
          {/* <Box
            onClick={() => handleClick(2)}
            ml={1}
            className={clsx(classes.feedbackCommon, selectValue === 2 && classes.feedbackSelectTodo)}
          >
            <Typography variant="body2" color="inherit">
              待定
            </Typography>
          </Box> */}
          <Box
            onClick={() => handleClick(3)}
            ml={1}
            className={clsx(classes.feedbackCommon, selectValue === 3 && classes.feedbackSelectSuccess)}
          >
            <Typography variant="body2" color="inherit">
              通过
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Typography variant="body2" color="text.secondary">
              能力评价
            </Typography>
          </Box>
          <Rating size="large" value={activeValue} onChange={handleChange} onChangeActive={handleChangeActive} />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        {hoverValue !== -1 && (
          <Typography variant="body2" color="text.primary">
            {RATING_TEXT[hoverValue]}
          </Typography>
        )}
        {hoverValue === -1 && activeValue && (
          <Typography variant="body2" color="text.primary">
            {RATING_TEXT[activeValue]}
          </Typography>
        )}
      </Box>
    </>
  );
}
const MemoFeedbackInput = memo(FeedbackInput);
EvaluationFormEditor.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};
