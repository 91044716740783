import withStyles from '@material-ui/core/styles/withStyles';
import TableRow from '@material-ui/core/TableRow';

export const GreenTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    '&$selected': {
      backgroundColor: '#e8f5e9',
      '&:hover': {
        backgroundColor: '#ECF7ED !important',
      },
    },
    '&:hover': {
      backgroundColor: '#f4f4f4 !important',
    },
    '& .tableCellActions': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '1rem',
      visibility: 'hidden',
    },
    '&:hover .tableCellActions': {
      visibility: 'visible',
    },
    '& .tableCellTime': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '1rem',
      visibility: 'visible',
    },
    '&:hover .tableCellTime': {
      visibility: 'hidden',
    },
    '& .tableCellTimeAlwaysShow': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '1rem',
    },
  },
  selected: {
    backgroundColor: '#e8f5e9',
  },
}))(TableRow);

export default {};
