import PropTypes from 'prop-types';

export function AlipayIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs />
      <g fill="#4BB051" fillRule="nonzero">
        <path fillOpacity=".06" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" />
        <path d="M12.626 6.594v1.55h3.502v.683l-3.502.023v.964l2.893.023s-.052.541-.42 1.524c-.366.984-.71 1.486-.71 1.486L20 14.687s-.201.527-.444.989c-.242.462-.58.958-.58.958l-5.361-2.541s-1.014 1.121-2.049 1.615c-1.034.493-2.222.801-3.507.497-1.287-.303-2.199-1.125-2.041-2.644.157-1.518 1.78-2.041 3.114-2.041 1.335 0 3.869.865 3.869.865s.213-.477.42-1.022a4.95 4.95 0 00.266-.984H8.293v-.541h2.621V8.803l-3.267.024v-.682h3.264V6.594h1.715zm-3.883 5.75l-.174.007c-1.61.103-2.004.81-2.004 1.463 0 .652.398 1.558 2.29 1.558s3.498-1.908 3.498-1.908-2.174-1.215-3.784-1.113z" />
      </g>
    </svg>
  );
}

export function BankIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs />
      <g fill="#4BB051" fillRule="nonzero">
        <path fillOpacity=".06" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" />
        <path d="M17.854 9.53l-.545-.46-1.281-1.085-1.502-1.27-1.206-1.022-.33-.279-.07-.059a1.46 1.46 0 00-.505-.285 1.44 1.44 0 00-.88.006 1.485 1.485 0 00-.486.28l-.21.178-1.054.891L8.302 7.68 6.921 8.85l-.77.65-.035.03c-.123.105-.143.307-.085.445.063.15.21.248.372.25h10.71c.145 0 .29.002.434 0h.02c.212 0 .416-.187.406-.407a.415.415 0 00-.407-.406H6.857c-.145 0-.29-.003-.434 0h-.02l.288.694.51-.433L8.413 8.65l1.454-1.23 1.228-1.04c.179-.151.36-.3.537-.455.025-.02.05-.023-.037.028l.032-.022a.985.985 0 01.151-.083l-.097.04c.089-.036.182-.062.277-.074l-.108.014c.09-.01.18-.01.27 0l-.108-.014c.095.012.189.038.277.075l-.097-.041a.98.98 0 01.183.105c-.002-.001-.083-.068-.037-.028l.014.012.079.066.334.284 1.166.986L15.38 8.5l1.269 1.074c.206.174.412.35.62.524l.008.007c.084.071.174.12.288.12.1 0 .219-.045.287-.12.14-.15.175-.427.002-.574zM6.572 16.675h10.4c.142 0 .284.002.425 0h.018c.213 0 .417-.188.407-.407a.415.415 0 00-.407-.407H6.572c-.212 0-.416.188-.406.407.01.22.178.407.406.407z" />
        <path d="M13.326 15.86l.002-.49v-5.145h.815l-.002.441v5.194M16.744 16.136v-5.47c0-.251.005-.503 0-.754V9.9c0-.213-.187-.417-.406-.407a.415.415 0 00-.407.407v5.47c0 .25-.005.503 0 .754v.01c0 .213.187.417.407.407a.414.414 0 00.406-.406zM9.844 15.86l.002-.49v-5.144h.815l-.002.44v5.194M8.07 16.136v-5.47c0-.251.005-.503 0-.754V9.9c0-.213-.188-.417-.407-.407a.415.415 0 00-.406.407v5.47c0 .25-.005.503 0 .754v.01c0 .213.187.417.406.407a.414.414 0 00.407-.406z" />
      </g>
    </svg>
  );
}

export function CopyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M21.6 19.2h-2.4v2.4c0 1.32-1.08 2.4-2.4 2.4H2.4C1.08 24 0 22.92 0 21.6V7.2c0-1.32 1.08-2.4 2.4-2.4h2.4V2.4C4.8 1.08 5.88 0 7.2 0h14.4C22.92 0 24 1.08 24 2.4v14.4c0 1.32-1.08 2.4-2.4 2.4zm-18-12c-.72 0-1.2.48-1.2 1.2v12c0 .72.48 1.2 1.2 1.2h12c.72 0 1.2-.48 1.2-1.2v-12c0-.72-.48-1.2-1.2-1.2h-12zm18-3.6c0-.72-.48-1.2-1.2-1.2h-12c-.72 0-1.2.48-1.2 1.2v1.2h9.6c1.32 0 2.4 1.08 2.4 2.4v9.6h1.2c.72 0 1.2-.48 1.2-1.2v-12z"
      />
    </svg>
  );
}

export function UncheckedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5z"
      />
    </svg>
  );
}

export function CheckedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <linearGradient id="CheckedIcon" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="#4BB051" />
          <stop offset="100%" stopColor="#5FC766" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill="url(#CheckedIcon)" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M8.21 10.425l3.496 3.498L17.54 8.09l1.414 1.415-7.246 7.246-4.912-4.911z"
        />
      </g>
    </svg>
  );
}

export function DateRangeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="currentColor" fillRule="evenodd">
        <path d="M2.2 13h20.6a.2.2 0 00.2-.2v-2.6a.2.2 0 00-.2-.2H2.2a.2.2 0 00-.2.2v2.6c0 .11.09.2.2.2z" />
        <path
          fillRule="nonzero"
          d="M2.667 5.625v15.75h18.666V5.625H2.667zM2.133 3h19.734C23.045 3 24 3.94 24 5.1v16.8c0 1.16-.955 2.1-2.133 2.1H2.133C.955 24 0 23.06 0 21.9V5.1C0 3.94.955 3 2.133 3z"
        />
        <path d="M7 0h3v8H7zM15 0h3v8h-3z" />
      </g>
    </svg>
  );
}

export function InfoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 0c6.627 0 12 5.363 12 11.978 0 6.615-5.373 11.978-12 11.978S0 18.593 0 11.978C0 5.363 5.373 0 12 0zm0 9.483a1.5 1.5 0 00-1.5 1.5v6.981a1.5 1.5 0 003 0v-6.981a1.5 1.5 0 00-1.5-1.5zm0-4.991c-.828 0-1.5.67-1.5 1.497 0 .827.672 1.497 1.5 1.497s1.5-.67 1.5-1.497c0-.827-.672-1.497-1.5-1.497z"
      />
    </svg>
  );
}

export function CheckCircleIcon() {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="CheckCircleIcon">
          <stop stopColor="#4BB051" offset="0%" />
          <stop stopColor="#5FC766" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill="url(#CheckCircleIcon)" cx="12" cy="12" r="12" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M8.21 10.425l3.497 3.497L17.54 8.09l1.414 1.414-7.247 7.247-4.91-4.911z"
        />
      </g>
    </svg>
  );
}

export function MonetizationOnIcon({ on = false }) {
  if (on) {
    return (
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="MonetizationOnIcon">
            <stop stopColor="#4BB051" offset="0%" />
            <stop stopColor="#5FC766" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M6.346 14.767c1.235-.185 2.546-.54 3.904-1.05v-1.002c-.864.34-1.744.617-2.608.833.895-1.173 2.021-3.163 3.395-5.97l-.972-.356c-.34.71-.664 1.389-.972 2.021a29.57 29.57 0 01-1.99.309c.802-1.173 1.573-2.715 2.329-4.66l-1.034-.4c-.617 1.79-1.327 3.363-2.16 4.751-.123.201-.277.34-.447.402l.262 1.018A73.058 73.058 0 008.6 10.23c-.802 1.512-1.481 2.576-2.052 3.209a1.783 1.783 0 01-.494.309l.293 1.018zm4.768-1.358c1.697-.586 3.178-1.404 4.459-2.468a18.155 18.155 0 003.872 2.283l.571-1.003a18.558 18.558 0 01-3.672-2.006c.926-.94 1.713-2.036 2.376-3.301v-.91h-4.428c.17-.433.324-.895.463-1.374l-1.126-.139a9.157 9.157 0 01-2.716 4.413l.694.91c.463-.432.895-.895 1.281-1.388a15.744 15.744 0 001.836 1.82c-1.203.957-2.577 1.682-4.15 2.176l.54.987zm4.381-3.842c-.74-.601-1.419-1.25-2.02-1.959.092-.17.2-.34.308-.525h3.672a13.005 13.005 0 01-1.96 2.484zm2.207 5.817l.555-.849a23.962 23.962 0 00-4.227-1.743l-.571.849a22.764 22.764 0 014.243 1.743zm.632 3.302l.587-.926c-2.037-.926-4.29-1.697-6.758-2.345l-.617.926a40.16 40.16 0 016.788 2.345zm-12.312-.633c1.805-.34 3.425-.771 4.845-1.296v-1.126c-1.435.524-3.101.956-4.999 1.265l.154 1.157z"
            fill="#FFF"
            fillRule="nonzero"
          />
          <rect fill="url(#MonetizationOnIcon)" transform="matrix(-1 0 0 1 24 0)" width="24" height="24" rx="12" />
          <path
            d="M16.203 12.38a.94.94 0 000-1.88h-2.551l2.591-3.897a.94.94 0 10-1.564-1.041l-2.73 4.105-2.73-4.105a.94.94 0 10-1.564 1.04l2.592 3.898h-2.45a.94.94 0 000 1.88h3.263v1.582H7.797a.94.94 0 000 1.879h3.263v2.417a.94.94 0 001.88 0v-2.417h3.263a.94.94 0 000-1.88H12.94V12.38h3.264z"
            fill="#FFF"
          />
        </g>
      </svg>
    );
  }

  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="2.293%" id="MonetizationOnIcon">
          <stop stopColor="#CBCBCB" offset="0%" />
          <stop stopColor="#E9E9E9" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.346 14.767c1.235-.185 2.546-.54 3.904-1.05v-1.002c-.864.34-1.744.617-2.608.833.895-1.173 2.021-3.163 3.395-5.97l-.972-.356c-.34.71-.664 1.389-.972 2.021a29.57 29.57 0 01-1.99.309c.802-1.173 1.573-2.715 2.329-4.66l-1.034-.4c-.617 1.79-1.327 3.363-2.16 4.751-.123.201-.277.34-.447.402l.262 1.018A73.058 73.058 0 008.6 10.23c-.802 1.512-1.481 2.576-2.052 3.209a1.783 1.783 0 01-.494.309l.293 1.018zm4.768-1.358c1.697-.586 3.178-1.404 4.459-2.468a18.155 18.155 0 003.872 2.283l.571-1.003a18.558 18.558 0 01-3.672-2.006c.926-.94 1.713-2.036 2.376-3.301v-.91h-4.428c.17-.433.324-.895.463-1.374l-1.126-.139a9.157 9.157 0 01-2.716 4.413l.694.91c.463-.432.895-.895 1.281-1.388a15.744 15.744 0 001.836 1.82c-1.203.957-2.577 1.682-4.15 2.176l.54.987zm4.381-3.842c-.74-.601-1.419-1.25-2.02-1.959.092-.17.2-.34.308-.525h3.672a13.005 13.005 0 01-1.96 2.484zm2.207 5.817l.555-.849a23.962 23.962 0 00-4.227-1.743l-.571.849a22.764 22.764 0 014.243 1.743zm.632 3.302l.587-.926c-2.037-.926-4.29-1.697-6.758-2.345l-.617.926a40.16 40.16 0 016.788 2.345zm-12.312-.633c1.805-.34 3.425-.771 4.845-1.296v-1.126c-1.435.524-3.101.956-4.999 1.265l.154 1.157z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <rect fill="url(#MonetizationOnIcon)" transform="matrix(-1 0 0 1 24 0)" width="24" height="24" rx="12" />
        <path
          d="M16.203 12.38a.94.94 0 000-1.88h-2.551l2.591-3.897a.94.94 0 10-1.564-1.041l-2.73 4.105-2.73-4.105a.94.94 0 10-1.564 1.04l2.592 3.898h-2.45a.94.94 0 000 1.88h3.263v1.582H7.797a.94.94 0 000 1.879h3.263v2.417a.94.94 0 001.88 0v-2.417h3.263a.94.94 0 000-1.88H12.94V12.38h3.264z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

MonetizationOnIcon.propTypes = {
  on: PropTypes.bool.isRequired,
};

export function WarningIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ width: '100%' }}>
      <path
        d="M24 0c13.255 0 24 10.745 24 24S37.255 48 24 48 0 37.255 0 24 10.745 0 24 0zm-.047 32.458c-.826 0-1.525.295-2.096.884-.571.589-.857 1.303-.857 2.142 0 .962.299 1.71.896 2.245a3.043 3.043 0 002.097.802c.787 0 1.476-.27 2.067-.812.59-.542.886-1.287.886-2.235a2.93 2.93 0 00-.876-2.142 2.871 2.871 0 00-2.117-.884zm.158-23.04c-.932 0-1.683.29-2.254.869-.571.578-.857 1.384-.857 2.417 0 .76.06 2.01.178 3.752l.63 8.94c.118 1.158.314 2.02.59 2.586.276.566.768.85 1.477.85.695 0 1.194-.293 1.496-.878.302-.585.499-1.425.59-2.52l.847-9.202c.092-.846.138-1.68.138-2.501 0-1.394-.19-2.461-.571-3.202-.38-.74-1.135-1.11-2.264-1.11z"
        fill="#F5C441"
        fillRule="evenodd"
      />
    </svg>
  );
}

export function EmptyPaymentIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 139">
      <defs>
        <path
          d="M51.213 97.038c-.004 0-.009 0-.013-.002-1.477-.303-2.772-1.455-3.298-2.933-.199-.565-.737-1.19-1.5-1.345-.763-.156-1.301.25-1.495.722-.535 1.272-1.83 1.895-3.306 1.595l-.006-.001c-1.478-.302-2.773-1.455-3.3-2.937-.194-.548-.732-1.172-1.494-1.327-.762-.155-1.301.252-1.496.723-.526 1.27-1.824 1.895-3.305 1.595-1.48-.3-2.777-1.453-3.305-2.937-.194-.548-.732-1.174-1.494-1.328-.762-.154-1.301.252-1.496.723-.526 1.268-1.824 1.895-3.305 1.595-1.48-.3-2.777-1.453-3.305-2.937-.194-.55-.733-1.174-1.495-1.328-.762-.154-1.3.252-1.495.722-.526 1.266-1.821 1.894-3.299 1.597H12.8c-1.476-.3-2.771-1.449-3.301-2.928-.199-.559-.737-1.183-1.499-1.338-.578-.118-1.097.077-1.387.518-.81 1.225-2.448 1.57-3.995.839C1.076 85.599 0 83.978 0 82.383V6.085C0 1.98 3.35-.682 7.467.153l49.066 9.952C60.65 10.94 64 14.96 64 19.066v77.13c0 1.62-1.158 2.787-2.755 2.773-1.59-.015-3.19-1.2-3.8-2.822-.019-.051-.473-1.242-1.258-1.401-.745-.151-1.438.149-1.686.73-.53 1.245-1.82 1.86-3.288 1.562"
          id="a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fill="#EDEDED">
          <path
            d="M51.751 95.227c1.947.393 3.8 1.424 5.294 2.857 1.531-.818 3.423-1.09 5.41-.688.54.109 1.055.266 1.545.46V20.385c0-.641-.523-1.268-1.167-1.397L9.167 8.131C8.523 8 8 8.415 8 9.055v77.498c2.424-.005 5.055 1.148 7.001 2.993 1.488-.81 3.324-1.082 5.249-.692 1.926.39 3.762 1.403 5.251 2.816 1.488-.81 3.324-1.082 5.25-.692 1.926.39 3.763 1.406 5.25 2.816 1.488-.81 3.324-1.082 5.25-.692 1.925.39 3.762 1.404 5.249 2.815 1.487-.809 3.324-1.08 5.251-.69m-23.33.867a.056.056 0 010 0m10.5 2.124a.056.056 0 010 0m10.5 2.123a.08.08 0 010 0M12.08 92.79l-.004.007.004-.007m10.5 2.123a.019.019 0 010 0m10.5 2.125l-.004.007.004-.007m21.02 4.246l-.007.015.007-.015m2.915 5.576l-.014-.002c-1.616-.331-3.032-1.588-3.607-3.2-.217-.616-.806-1.298-1.642-1.468-.833-.17-1.422.274-1.634.788-.585 1.388-2.001 2.067-3.616 1.74l-.007-.001c-1.616-.329-3.032-1.587-3.608-3.204-.213-.597-.802-1.278-1.635-1.448-.833-.168-1.423.275-1.637.79-.575 1.384-1.995 2.066-3.614 1.739-1.618-.327-3.037-1.585-3.614-3.204-.213-.598-.802-1.28-1.635-1.449-.833-.168-1.423.275-1.637.79-.575 1.383-1.995 2.066-3.614 1.74-1.618-.328-3.037-1.586-3.614-3.205-.213-.599-.803-1.28-1.636-1.448-.833-.169-1.422.275-1.636.788-.575 1.38-1.991 2.065-3.607 1.742L15 98.346c-1.615-.327-3.031-1.58-3.61-3.193-.218-.61-.807-1.291-1.64-1.46-.632-.128-1.2.084-1.517.566-.885 1.336-2.677 1.713-4.37.914C2.177 94.381 1 92.613 1 90.873V7.637c0-4.48 3.665-7.382 8.167-6.47l53.666 10.856C67.336 12.934 71 17.32 71 21.8v84.142c0 1.767-1.267 3.04-3.013 3.026-1.74-.017-3.489-1.31-4.156-3.08-.021-.055-.518-1.354-1.377-1.528-.814-.165-1.572.163-1.843.796-.58 1.36-1.992 2.03-3.597 1.705"
            stroke="#EDEDED"
            strokeWidth="1.094"
          />
          <path
            d="M47.941 40.508l-23.883-4.831C22.922 35.447 22 34.343 22 33.212c0-1.13.922-1.862 2.058-1.63l23.883 4.83c1.138.23 2.06 1.333 2.06 2.464 0 1.13-.922 1.862-2.06 1.632M47.53 51.074L24.47 46.41c-1.364-.275-2.47-1.6-2.47-2.956 0-1.358 1.106-2.235 2.47-1.958l23.06 4.664c1.363.275 2.47 1.6 2.47 2.957 0 1.357-1.107 2.234-2.47 1.958"
            stroke="#EDEDED"
            strokeWidth="1.094"
          />
          <path
            d="M45.809 32.086l-7.762 6.151c-.986.781-2.584.457-3.57-.723l-.2-.239c-.986-1.18-.986-2.77 0-3.551l7.761-6.151c.986-.781 2.584-.459 3.571.722l.2.239c.986 1.181.986 2.771 0 3.552"
            stroke="#EDEDED"
            strokeWidth="1.094"
          />
          <path
            d="M26.13 28.258l7.454 8.922c1.071 1.283 2.807 1.635 3.878.786 1.071-.85 1.071-2.575 0-3.857l-7.453-8.924c-1.071-1.281-2.809-1.633-3.878-.784-1.071.848-1.071 2.576 0 3.857"
            stroke="#EDEDED"
            strokeWidth="1.094"
          />
          <path
            d="M33.53 54.126v-19.96c0-1.275 1.039-2.098 2.32-1.839l.3.062c1.282.259 2.32 1.501 2.32 2.777v19.959c0 1.275-1.038 2.098-2.32 1.84l-.3-.061c-1.281-.26-2.32-1.503-2.32-2.778"
            stroke="#EDEDED"
            strokeWidth="1.094"
          />
          <path d="M6.833 76.13L4.5 75.659c-1.933-.39-3.5-2.266-3.5-4.19 0-1.922 1.567-3.164 3.5-2.773l2.333.472c1.933.391 3.5 2.267 3.5 4.19 0 1.923-1.567 3.165-3.5 2.774M22.538 79.307L18.05 78.4c-1.933-.391-3.5-2.267-3.5-4.19 0-1.923 1.567-3.165 3.5-2.773l4.487.907c1.933.391 3.5 2.267 3.5 4.19 0 1.923-1.567 3.164-3.5 2.773m15.705 3.178l-4.487-.908c-1.933-.39-3.5-2.267-3.5-4.19 0-1.923 1.567-3.164 3.5-2.773l4.487.908c1.934.39 3.5 2.265 3.5 4.19 0 1.922-1.566 3.164-3.5 2.773M53.95 85.66l-4.488-.907c-1.933-.391-3.5-2.267-3.5-4.19 0-1.923 1.567-3.165 3.5-2.773l4.488.907c1.933.391 3.5 2.267 3.5 4.19 0 1.924-1.567 3.164-3.5 2.773M67.5 88.403l-2.333-.472c-1.934-.391-3.5-2.267-3.5-4.19 0-1.923 1.566-3.165 3.5-2.774l2.333.473c1.933.39 3.5 2.266 3.5 4.19 0 1.922-1.567 3.164-3.5 2.773" />
        </g>
        <path
          d="M63.414 129.605c.35 4-12.071 6.035-27.745 4.548-15.673-1.487-28.662-5.935-29.012-9.934-.35-3.999 12.072-6.035 27.745-4.548 15.674 1.488 28.662 5.935 29.012 9.934"
          fill="#F6F6F6"
        />
      </g>
    </svg>
  );
}

export function RecommendStatusIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <g fill="none" fillRule="evenodd">
        <rect
          stroke="currentColor"
          strokeWidth="1.5"
          transform="matrix(-1 0 0 1 24 0)"
          x=".75"
          y=".75"
          width="22.5"
          height="22.5"
          rx="11.25"
        />
        <path
          d="M16.203 12.38a.94.94 0 000-1.88h-2.551l2.591-3.897a.94.94 0 10-1.564-1.041l-2.73 4.105-2.73-4.105a.94.94 0 10-1.564 1.04l2.592 3.898h-2.45a.94.94 0 000 1.88h3.263v1.582H7.797a.94.94 0 000 1.879h3.263v2.417a.94.94 0 001.88 0v-2.417h3.263a.94.94 0 000-1.88H12.94V12.38h3.264z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export function WechatIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <g fill="#4BB051" fillRule="evenodd">
        <path
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
          fillOpacity=".06"
          fillRule="nonzero"
        />
        <path d="M11.8 7.888c-.398 0-.797.265-.797.668 0 .401.399.67.796.67.4 0 .665-.269.665-.67 0-.403-.265-.668-.665-.668m-3.715 0c-.398 0-.8.265-.8.668 0 .401.402.67.8.67s.663-.269.663-.67c0-.403-.265-.668-.663-.668m2.057 5.688c0 .389.06.764.163 1.122a6.17 6.17 0 01-.495.021c-.663 0-1.196-.136-1.86-.268l-1.856.938.531-1.61c-1.33-.937-2.124-2.145-2.124-3.615 0-2.547 2.39-4.553 5.309-4.553 2.61 0 4.897 1.603 5.357 3.76a4.655 4.655 0 00-.512-.032c-2.522 0-4.513 1.898-4.513 4.237m6.306-1.805c-.263 0-.528.269-.528.535 0 .271.265.536.528.536.398 0 .664-.265.664-.536 0-.266-.266-.535-.664-.535m-2.92 0c-.265 0-.531.269-.531.535 0 .271.266.536.53.536.402 0 .664-.265.664-.536 0-.266-.262-.535-.664-.535m4.114 4.823l.4 1.339-1.457-.805c-.53.134-1.064.268-1.593.268-2.526 0-4.516-1.74-4.516-3.884 0-2.14 1.99-3.886 4.516-3.886 2.386 0 4.51 1.745 4.51 3.886 0 1.207-.794 2.276-1.86 3.082" />
      </g>
    </svg>
  );
}

export function WechatIcon2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <path
        d="M11.733 7.036c-.53 0-1.062.353-1.062.89 0 .535.531.894 1.062.894.533 0 .885-.359.885-.894 0-.537-.352-.89-.885-.89m-4.955 0c-.53 0-1.065.353-1.065.89 0 .535.535.894 1.065.894.531 0 .884-.359.884-.894 0-.537-.353-.89-.884-.89m2.743 7.584c0 .518.08 1.018.218 1.496a8.226 8.226 0 01-.66.028c-.884 0-1.595-.182-2.48-.358l-2.475 1.25.708-2.146C3.06 13.641 2 12.03 2 10.071 2 6.675 5.187 4 9.078 4c3.481 0 6.53 2.137 7.143 5.012a6.206 6.206 0 00-.682-.041c-3.363 0-6.018 2.53-6.018 5.65m8.408-2.408c-.35 0-.704.359-.704.714 0 .36.354.714.704.714.53 0 .886-.353.886-.714 0-.355-.356-.714-.886-.714m-3.894 0c-.352 0-.708.359-.708.714 0 .36.356.714.708.714.536 0 .886-.353.886-.714 0-.355-.35-.714-.886-.714m5.485 6.43l.533 1.786-1.942-1.073c-.708.18-1.42.357-2.124.357-3.369 0-6.021-2.32-6.021-5.178 0-2.855 2.652-5.181 6.021-5.181 3.18 0 6.013 2.326 6.013 5.18 0 1.61-1.059 3.035-2.48 4.11"
        fill="#4BB051"
        fillRule="evenodd"
      />
    </svg>
  );
}

export function CalendarIcon({ outline = false }) {
  if (outline) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
        <g fill="currentColor" fillRule="evenodd">
          <path d="M19 11v2H4v-2h15zM9.6 3c.22 0 .4.18.4.4v5.2a.4.4 0 01-.4.4H8.4a.4.4 0 01-.4-.4V3.4c0-.22.18-.4.4-.4h1.2zm6 0c.22 0 .4.18.4.4v5.2a.4.4 0 01-.4.4h-1.2a.4.4 0 01-.4-.4V3.4c0-.22.18-.4.4-.4h1.2z" />
          <path
            d="M19 5a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h14zm0 2H5v12h14V7z"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <path
          id="prefix__calendar-a"
          d="M8.92596558,4 C9.01898196,4 9.09438664,4.07540467 9.09438664,4.16842105 L9.09389474,5.68421053 L14.9473684,5.68421053 L14.9473684,4.16842105 C14.9473684,4.07540467 15.0227731,4 15.1157895,4 L16.8842105,4 C16.9772269,4 17.0526316,4.07540467 17.0526316,4.16842105 L17.0526316,5.68421053 L18.6526316,5.68421053 C19.3967626,5.68421053 20,6.28744792 20,7.03157896 L20,18.6526316 C20,19.3967626 19.3967626,20 18.6526316,20 L5.34736844,20 C4.6032374,20 4,19.3967626 4,18.6526316 L4,7.03157896 C4,6.28744792 4.6032374,5.68421053 5.34736844,5.68421053 L6.98863158,5.68421053 L6.98912348,4.16842105 C6.98912348,4.07540467 7.06452815,4 7.15754453,4 L8.92596558,4 Z M15.0315789,13.2631579 L6.44210526,13.2631579 C6.2793266,13.2631579 6.14351586,13.3786213 6.11210659,13.5321145 L6.10526316,13.6 L6.10526316,14.6105263 C6.10526316,14.773305 6.22072657,14.9091157 6.37421983,14.940525 L6.44210526,14.9473684 L15.0315789,14.9473684 C15.1943576,14.9473684 15.3301683,14.831905 15.3615776,14.6784118 L15.3684211,14.6105263 L15.3684211,13.6 C15.3684211,13.4139672 15.2176117,13.2631579 15.0315789,13.2631579 Z M11.6631579,9.05263158 L6.44210526,9.05263158 C6.2793266,9.05263158 6.14351586,9.16809499 6.11210659,9.32158825 L6.10526316,9.38947368 L6.10526316,10.4 C6.10526316,10.5627787 6.22072657,10.6985894 6.37421983,10.7299987 L6.44210526,10.7368421 L11.6631579,10.7368421 C11.8259366,10.7368421 11.9617473,10.6213787 11.9931566,10.4678854 L12,10.4 L12,9.38947368 C12,9.20344093 11.8491907,9.05263158 11.6631579,9.05263158 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__calendar-b" fill="#fff">
          <use xlinkHref="#prefix__calendar-a" />
        </mask>
        <g fill="currentColor" fillOpacity=".86" mask="url(#prefix__calendar-b)">
          <rect width="24" height="24" />
        </g>
      </g>
    </svg>
  );
}

CalendarIcon.propTypes = {
  outline: PropTypes.bool.isRequired,
};

export function EmptyInterviewsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80" style={{ width: '100%' }}>
      <g fill="currentColor" fillRule="nonzero">
        <path d="M61.742 49.448c5.415-3.509 9.005-9.605 9.005-16.525 0-10.851-8.825-19.677-19.677-19.677a19.582 19.582 0 00-6.454 1.088c.591-.94.52-2.152-.177-3.016a19.622 19.622 0 00-15.285-7.284c-10.852 0-19.678 8.828-19.678 19.678a19.652 19.652 0 009.009 16.528c-5.1 2.013-9.492 5.41-12.768 9.877a28.779 28.779 0 00-5.624 17.16 2.592 2.592 0 005.184-.022c0-13.163 10.712-23.866 23.866-23.866a2.605 2.605 0 002.592-2.614 2.594 2.594 0 00-2.592-2.592c-7.98 0-14.482-6.491-14.482-14.482 0-7.98 6.492-14.483 14.482-14.483 4.393 0 8.494 1.96 11.257 5.367.385.477.927.802 1.529.917-6.259 3.296-10.537 9.867-10.537 17.42 0 6.926 3.597 13.027 9.022 16.536C29.657 53.72 22.03 64.23 22.03 76.488a2.594 2.594 0 002.592 2.592 2.595 2.595 0 002.592-2.592c0-13.166 10.713-23.867 23.867-23.867 13.154 0 23.866 10.711 23.866 23.867a2.594 2.594 0 002.592 2.592 2.628 2.628 0 002.614-2.592c-.001-12.267-7.64-22.782-18.411-27.04zM51.07 18.442c7.99 0 14.483 6.49 14.483 14.481 0 7.982-6.492 14.483-14.483 14.483-7.981 0-14.483-6.49-14.483-14.483 0-7.98 6.492-14.481 14.483-14.481z" />
        <path d="M97.002.016H50.128a2.594 2.594 0 00-2.592 2.592v7.434a2.593 2.593 0 005.183 0V5.2h41.668v28.098h-9.875a2.605 2.605 0 00-1.864.793l-4.253 4.402.31-2.154c.11-.75-.117-1.51-.62-2.077a2.575 2.575 0 00-1.95-.89l-2.226.022a2.555 2.555 0 00-1.833.771 2.535 2.535 0 00-.75 1.843 2.62 2.62 0 001.833 2.464l-1.028 7.22c-.108.744.115 1.5.61 2.066a2.57 2.57 0 001.96.9c.695 0 1.371-.289 1.865-.792l9.05-9.362h11.387a2.594 2.594 0 002.592-2.592V2.608A2.597 2.597 0 0097.002.016z" />
      </g>
    </svg>
  );
}

export function PositionIcon({ outlined = false }) {
  if (outlined) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" style={{ width: '100%' }}>
        <defs>
          <path id="PositionIcon1" d="M0 0h14v14H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="PositionIcon2" fill="#fff">
            <use xlinkHref="#PositionIcon1" />
          </mask>
          <path
            d="M8.929 1c.473 0 .857.384.857.857l-.001.857h1.93C12.424 2.714 13 3.29 13 4v6.857c0 .71-.576 1.286-1.286 1.286H2.286c-.71 0-1.286-.576-1.286-1.286V4c0-.71.576-1.286 1.286-1.286h1.928v-.857c0-.473.384-.857.857-.857H8.93zM1.857 7v3.857c0 .21.152.386.352.422l.077.007h9.428c.21 0 .386-.152.422-.352l.007-.077L12.142 7H1.857zM8.93 1.857H5.07v.857H8.93v-.857zM1.857 6.143l10.285-.001V4a.429.429 0 00-.35-.422l-.078-.007H2.286a.429.429 0 00-.422.352L1.857 4v2.143z"
            fill="currentColor"
            fillRule="nonzero"
            mask="url(#PositionIcon2)"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <path
        d="M21.903 17.746l-.001 1.546a3 3 0 01-3 3h-13.8a3 3 0 01-3-3v-1.545a3.988 3.988 0 002.673 1.239l.227.006h14a3.989 3.989 0 002.901-1.246zM16 1a1.5 1.5 0 011.5 1.5v1.799L21 4.3a2 2 0 012 2v7.2a4 4 0 01-4 4h-5.9v-2.3a1 1 0 00-.883-.993L12.1 14.2h-.2a1 1 0 00-1 1v2.3H5a4 4 0 01-4-4V6.3a2 2 0 012-2l3.5-.001V2.5A1.5 1.5 0 018 1h8zm-.75 1.65h-6.5a.6.6 0 00-.592.503l-.008.097v1.049h7.7V3.25a.6.6 0 00-.503-.592l-.097-.008z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

PositionIcon.propTypes = {
  outlined: PropTypes.bool.isRequired,
};

export function ClockIcon({ outline = false }) {
  if (outline) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
        <g fill="currentColor" fillRule="nonzero">
          <path d="M12 20.556a8.556 8.556 0 100-17.112 8.556 8.556 0 000 17.112zM12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11z" />
          <path d="M14.736 13.384a1.173 1.173 0 11-1.659 1.659l-2.69-2.69v-5.18a1.173 1.173 0 112.346 0v4.208l2.003 2.003z" />
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <path
        d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 5.381c-.65 0-1.179.528-1.179 1.179V12l.015.185c.039.244.154.472.33.648l2.75 2.75.133.114a1.179 1.179 0 001.534-.114l.114-.132a1.179 1.179 0 00-.114-1.534L13.18 11.51V7.56l-.011-.16A1.179 1.179 0 0012 6.381z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

ClockIcon.propTypes = {
  outline: PropTypes.bool.isRequired,
};

export function LocationIcon({ outline = false }) {
  if (outline) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
        <g fill="currentColor" fillRule="evenodd">
          <path
            d="M12 .857c5.68 0 10.286 4.606 10.286 10.286 0 4.805-7 10.3-9.628 11.8l-.244.134c-.39.202-.515.157-1.072-.135l-.308-.169c-2.862-1.636-9.32-7-9.32-11.63C1.714 5.463 6.321.857 12 .857zm0 2a8.287 8.287 0 00-8.286 8.286c0 2.868 4.455 7.663 8.078 9.763l.194.111.2-.12c3.742-2.311 7.906-6.723 8.093-9.559l.007-.195A8.287 8.287 0 0012 2.857z"
            fillRule="nonzero"
          />
          <circle cx="12" cy="11" r="3" />
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M12 .857c5.68 0 10.286 4.606 10.286 10.286 0 4.805-7 10.3-9.628 11.8-.604.344-.658.344-1.316 0-2.673-1.4-9.628-6.995-9.628-11.8C1.714 5.463 6.321.857 12 .857zm0 5.688a3.857 3.857 0 100 7.715 3.857 3.857 0 000-7.715z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

LocationIcon.propTypes = {
  outline: PropTypes.bool.isRequired,
};

export function UserIcon({ outline }) {
  if (outline) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" style={{ width: '100%' }}>
        <path
          d="M7.008 6.865c2.68 0 4.863 1.58 4.955 4.364l.003.233c0 .734-.515 1.348-1.202 1.448-1.123.162-2.331.251-3.591.251-1.393 0-2.724-.108-3.945-.304-.677-.11-1.179-.72-1.179-1.445 0-2.898 2.22-4.547 4.959-4.547zm0 1.285c-2.222 0-3.673 1.253-3.673 3.262 0 .103.053.169.097.176a23.73 23.73 0 003.741.288c1.178 0 2.325-.081 3.407-.238.037-.006.08-.049.095-.119l.006-.057v-.05c0-2.01-1.452-3.262-3.673-3.262zM6.997.428c1.611 0 2.917 1.278 2.917 2.855S8.608 6.261 6.997 6.261c-1.61 0-2.917-1.4-2.917-2.978C4.08 1.706 5.386.428 6.997.428zm0 1c-1.063 0-1.917.835-1.917 1.855 0 1.056.89 1.978 1.917 1.978 1.028 0 1.917-.922 1.917-1.978 0-1.02-.854-1.855-1.917-1.855z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M3 20.135c0 1.312.913 2.419 2.145 2.616 2.223.355 4.645.551 7.18.551 2.294 0 4.493-.16 6.536-.455 1.251-.18 2.189-1.292 2.189-2.621v-.09c0-5.247-4.04-8.234-9.025-8.234C7.041 11.902 3 14.889 3 20.135zM12.025.291c2.886 0 5.225 2.29 5.225 5.115s-2.34 5.335-5.225 5.335C9.139 10.74 6.8 8.23 6.8 5.406 6.8 2.58 9.14.29 12.025.29z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

UserIcon.propTypes = {
  outline: PropTypes.bool.isRequired,
};

export function UsersIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <g fill="currentColor" fillRule="nonzero">
        <path d="M17.086 4.087c1.84 0 3.333 1.463 3.333 3.267 0 1.805-1.492 3.407-3.333 3.407-1.841 0-3.334-1.602-3.334-3.407 0-1.804 1.493-3.267 3.334-3.267zM17.113 11.763c2.87 0 5.21 1.66 5.328 4.586l.005.287c0 .778-.554 1.43-1.293 1.535a27.439 27.439 0 01-3.862.266c-.442 0-.877-.01-1.305-.03l.009-.124.005-.256c0-2.216-.795-4.069-2.131-5.393.898-.57 2.023-.871 3.244-.871zM8.007 11.902c3.506 0 6.363 2.125 6.489 5.867l.004.325c0 .99-.675 1.817-1.575 1.95a32.03 32.03 0 01-4.701.339c-1.824 0-3.567-.146-5.167-.41-.886-.147-1.543-.97-1.543-1.946 0-3.903 2.907-6.125 6.493-6.125zM8.017 2.617c2.152 0 3.896 1.756 3.896 3.923 0 2.166-1.744 4.09-3.896 4.09S4.121 8.707 4.121 6.54c0-2.167 1.744-3.923 3.896-3.923z" />
      </g>
    </svg>
  );
}

export function TagIcon() {
  // outline
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <g transform="rotate(45 8.883 7.944)" fill="currentColor" fillRule="evenodd">
        <path
          d="M20.16 0A3.84 3.84 0 0124 3.84v9.6a3.84 3.84 0 01-3.84 3.84H9.398a3.84 3.84 0 01-2.863-1.281l-5.398-6.04a1.92 1.92 0 01-.007-2.551l5.404-6.111A3.84 3.84 0 019.41 0H20.16zm0 1.92H9.41a1.92 1.92 0 00-1.324.53l-.114.118L2.57 8.68l5.398 6.04a1.92 1.92 0 001.268.633l.163.007H20.16a1.92 1.92 0 001.915-1.777l.005-.143v-9.6a1.92 1.92 0 00-1.777-1.915l-.143-.005z"
          fillRule="nonzero"
        />
        <circle cx="9.6" cy="8.64" r="1.92" />
      </g>
    </svg>
  );
}

export function InterviewTypeIcon({ type }) {
  if (type === 3) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
        <defs>
          <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="InterviewType3Icon">
            <stop stopColor="#834DEA" offset="0%" />
            <stop stopColor="#B28AFF" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <circle fill="url(#InterviewType3Icon)" cx="12" cy="12" r="12" />
          <path
            d="M10.244 6c.11 0 .2.09.2.2l-.001 1.063h3.135V6.2c0-.11.09-.2.2-.2h.864c.11 0 .2.09.2.2v1.063h2.147a1 1 0 011.011.99v9.163a1 1 0 01-1.01.99H7.01a1 1 0 01-1.01-.99V8.254a1 1 0 011.01-.99l2.147-.001V6.2c0-.11.09-.2.2-.2h.887zm6.492 6.315H7.263v4.853h9.474l-.001-4.853zM9.157 8.501H7.263v2.551h9.473v-2.55L14.843 8.5v1.088a.2.2 0 01-.2.2h-.863a.2.2 0 01-.2-.2l-.001-1.088h-3.135v1.088a.2.2 0 01-.2.2h-.885a.2.2 0 01-.2-.2l-.001-1.088z"
            fill="#FFF"
          />
        </g>
      </svg>
    );
  }
  if (type === 2) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
        <defs>
          <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="InterviewType2Icon">
            <stop stopColor="#5FBABA" offset="0%" />
            <stop stopColor="#86E1E1" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <circle fill="url(#InterviewType2Icon)" cx="12" cy="12" r="12" />
          <g fill="#FFF">
            <path d="M6.2 12.316h10.968a.2.2 0 00.2-.2v-.863a.2.2 0 00-.2-.2H6.2a.2.2 0 00-.2.2v.863c0 .11.09.2.2.2z" />
            <path
              d="M16.99 7.263a1 1 0 011.01.99v9.163a1 1 0 01-1.01.99H7.01a1 1 0 01-1.01-.99V8.254a1 1 0 011.01-.99h9.98zm-.253 1.238H7.263v8.667h9.474V8.5z"
              fillRule="nonzero"
            />
            <path d="M9.158 6h1.286v3.789H9.158zM13.579 6h1.263v3.789h-1.263z" />
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="InterviewType1Icon">
          <stop stopColor="#4B84FF" offset="0%" />
          <stop stopColor="#90B3FF" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle stroke="#FFF" strokeWidth=".5" fill="url(#InterviewType1Icon)" cx="12" cy="12" r="11.75" />
        <g fill="#FFF">
          <path d="M6.2 12.316h10.968a.2.2 0 00.2-.2v-.863a.2.2 0 00-.2-.2H6.2a.2.2 0 00-.2.2v.863c0 .11.09.2.2.2z" />
          <path
            d="M16.99 7.263a1 1 0 011.01.99v9.163a1 1 0 01-1.01.99H7.01a1 1 0 01-1.01-.99V8.254a1 1 0 011.01-.99h9.98zm-.253 1.238H7.263v8.667h9.474V8.5z"
            fillRule="nonzero"
          />
          <path d="M9.158 6h1.286v3.789H9.158zM13.579 6h1.263v3.789h-1.263z" />
        </g>
      </g>
    </svg>
  );
}

InterviewTypeIcon.propTypes = {
  type: PropTypes.number.isRequired,
};

export function TitleIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" style={{ width: '100%' }}>
      <path
        d="M12.143 1c.473 0 .857.384.857.857v10.286a.857.857 0 01-.857.857H1.857A.857.857 0 011 12.143V1.857C1 1.384 1.384 1 1.857 1h10.286zm-.172 1.029H2.03v9.942h9.942V2.03zM8.863 5c.11 0 .2.09.2.2V6a.2.2 0 01-.2.2H4.2A.2.2 0 014 6v-.8c0-.11.09-.2.2-.2h4.663z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export function RemarkIcon({ outline = false }) {
  if (outline) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" style={{ width: '100%' }}>
        <path
          d="M12.143.5c.473 0 .857.416.857.929V12.57c0 .513-.384.929-.857.929H1.857c-.473 0-.857-.416-.857-.929V1.43C1 .916 1.384.5 1.857.5h10.286zM11.8 1.7H2.2v10.6h9.6V1.7zM9.3 9c.11 0 .2.09.2.2v.8a.2.2 0 01-.2.2H3.7a.2.2 0 01-.2-.2v-.8c0-.11.09-.2.2-.2h5.6zm0-3c.11 0 .2.09.2.2V7a.2.2 0 01-.2.2H3.7a.2.2 0 01-.2-.2v-.8c0-.11.09-.2.2-.2h5.6zm-3-3c.11 0 .2.09.2.2V4a.2.2 0 01-.2.2H3.7a.2.2 0 01-.2-.2v-.8c0-.11.09-.2.2-.2h2.6z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '100%' }}>
      <path
        d="M20.571 1C21.361 1 22 1.704 22 2.571V21.43c0 .867-.64 1.571-1.429 1.571H3.43C2.639 23 2 22.296 2 21.429V2.57C2 1.704 2.64 1 3.429 1H20.57zM16.8 17H6.2a.2.2 0 00-.2.2v1.6c0 .11.09.2.2.2h10.6a.2.2 0 00.2-.2v-1.6a.2.2 0 00-.2-.2zm0-6H6.2a.2.2 0 00-.2.2v1.6c0 .11.09.2.2.2h10.6a.2.2 0 00.2-.2v-1.6a.2.2 0 00-.2-.2zm-5-6H6.2a.2.2 0 00-.2.2v1.6c0 .11.09.2.2.2h5.6a.2.2 0 00.2-.2V5.2a.2.2 0 00-.2-.2z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

RemarkIcon.propTypes = {
  outline: PropTypes.bool.isRequired,
};

export function StopwatchIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" style={{ width: '100%' }}>
      <path
        d="M10.09 0a.6.6 0 01.098 1.192l-.097.008H7.6v.93a6 6 0 11-1.2 0V1.2H3.91A.6.6 0 013.813.008L3.911 0h6.18zM7 3.3a4.8 4.8 0 100 9.6 4.8 4.8 0 000-9.6zm0 2.253a.6.6 0 01.593.502l.008.098v2.96a.6.6 0 01-1.193.097l-.007-.097v-2.96a.6.6 0 01.6-.6z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export function QRCodeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
      />
    </svg>
  );
}

export function EditIcon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fillRule="evenodd">
        <g transform="rotate(45 2.879 23.778)">
          <path d="M.2 3h1.6c.11 0 .2.09.2.2V17l-.985 1L0 17V3.2c0-.11.09-.2.2-.2z" />
          <rect width="2" height="2" rx=".2" />
        </g>
        <path d="M10.973 16.35h7.8c.11 0 .2.089.2.2v1.6a.2.2 0 01-.2.2H9l1.973-2z" />
      </g>
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(4.5 4.5)" fill="currentColor" fillRule="nonzero">
        <rect y="2.5" width="15" height="1.667" rx=".2" />
        <path d="M11.833 2.5a1.5 1.5 0 011.5 1.5v9.5a1.5 1.5 0 01-1.5 1.5H3.167a1.5 1.5 0 01-1.5-1.5V4a1.5 1.5 0 011.5-1.5h8.666zm-.5 2H3.666V13h7.667V4.5zM9.8 0c.11 0 .2.09.2.2v1.267a.2.2 0 01-.2.2H5.2a.2.2 0 01-.2-.2V.2c0-.11.09-.2.2-.2h4.6z" />
        <path d="M6.467 5.833c.11 0 .2.09.2.2v5.434a.2.2 0 01-.2.2H5.2a.2.2 0 01-.2-.2V6.033c0-.11.09-.2.2-.2h1.267zM9.8 5.833c.11 0 .2.09.2.2v5.434a.2.2 0 01-.2.2H8.533a.2.2 0 01-.2-.2V6.033c0-.11.09-.2.2-.2H9.8z" />
      </g>
    </svg>
  );
}
