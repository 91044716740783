import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { leaveSearch } from '../../../store/modules/search/action';

function useEffectSearchRoute() {
  const dispatch = useDispatch();
  const isAdvancedMode = useSelector(({ search }) => search.isAdvancedMode);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const isAtSearchRoute = pathname.indexOf('/search') > -1;
  const hasEnteredSearchRoute = useRef(false);

  useEffect(() => {
    if (isAdvancedMode && !isAtSearchRoute) {
      if (hasEnteredSearchRoute.current) {
        dispatch(leaveSearch());
        hasEnteredSearchRoute.current = false;
      } else {
        setTimeout(() => history.push('/dashboard/search/candidateToken'), 150);
        hasEnteredSearchRoute.current = true;
      }
    }
  }, [isAdvancedMode, isAtSearchRoute]);
}

export default useEffectSearchRoute;
