import Typography from '@components/Avocado/Typography';
import {
  Box, Button, Popover as MuiPopover, withStyles,
} from '@material-ui/core';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { cloneElement } from 'react';

const genRandomId = () => `popupId_${Math.random().toString(36).substr(2, 9)}`;

const Popover = withStyles(() => ({
  paper: {
    width: 360,
  },
}))(MuiPopover);

function ConfirmPopover({
  children,
  popupId = genRandomId(),
  parentPopupState,
  title,
  desc,
  actionText,
  actionColor = 'primary',
  handleSubmit,
  ...others
}) {
  const popupState = usePopupState({ variant: 'popover', popupId });

  return (
    <>
      {cloneElement(children, { ...others, ...bindTrigger(popupState) })}
      <Popover
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box pt={2} px={3} pb={1.5}>
          {title && (
            <Typography mb={3} variant="body1" color="text.primary" fontWeight="fontWeightMedium">
              {title}
            </Typography>
          )}
          {desc && (
            <Typography variant="body1" color="text.secondary">
              {desc}
            </Typography>
          )}
          <Box mt={3.5} display="flex" justifyContent="flex-end">
            <Button
              onClick={(e) => {
                popupState.close(e);
                if (parentPopupState) parentPopupState.close(e);
              }}
            >
              取消
            </Button>
            <Box mr={1} />
            <Button
              variant="contained"
              color={actionColor}
              onClick={(e) => {
                popupState.close(e);
                if (parentPopupState) parentPopupState.close(e);
                handleSubmit();
              }}
            >
              {actionText}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

ConfirmPopover.propTypes = {
  children: PropTypes.string.isRequired,
  popupId: PropTypes.string.isRequired,
  parentPopupState: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  actionColor: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ConfirmPopover;
