import { CalendarIcon } from '@avocadoui/icons';
import { IconButton } from '@material-ui/core';
import * as interviewActions from '@redux/interviewArrangement/action';
import { useActions } from '@utils/useActions';

export default function OpenButton() {
  const { openInterviewModal } = useActions(interviewActions);

  function handleClick() {
    openInterviewModal();
  }

  return (
    <IconButton onClick={handleClick}>
      <CalendarIcon style={{ color: '#707070' }} />
    </IconButton>
  );
}
