import Typography from '@components/Avocado/Typography';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import UserAvatarChip from './UserAvatarChip';

export default function EvaluationVisibleUsersViewer({ visibleUsers = [] }) {
  if (visibleUsers.length === 0) {
    return null;
  }

  return (
    <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
      <Box>
        <Typography variant="body1" color="text.primary">
          可看人员：
        </Typography>
      </Box>
      {visibleUsers.map((user) => (
        <UserAvatarChip key={user.userToken} option={user} />
      ))}
    </Box>
  );
}

EvaluationVisibleUsersViewer.propTypes = {
  visibleUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
};
