import ELButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { parseInt } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import DateTimePicker from './DateTimePicker';
import WaittenMultiplePaperList from './WaittenMultiplePaperList';
import WaittenPaperList from './WaittenPaperList';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
    color: 'rgba(0,0,0,0.56)',
  },
  titleText: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#333333',
  },
  assistText: {
    fontSize: '13px',
    color: '#9B9A9A',
    '&:before': {
      content: '"• "',
      fontSize: '10px',
    },
  },
});

const WrittenCreate = ({
  classes,
  open,
  onCancel,
  onConfirm,
  title,
  cancelTitle,
  confirmTitile,
  severity,
  examMultiple,
}) => {
  const [time, setTime] = useState(new Date());
  const [papers, setPapers] = useState([]);
  return (
    <Dialog
      aria-labelledby="department-delete-dialog-title"
      className={classes.root}
      PaperProps={{
        style: {
          width: '520px',
        },
      }}
      open={open}
      disableEnforceFocus
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="subtitle1">{title}</Typography>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        }}
      >
        <DateTimePicker
          minDate="1970-01-01"
          keyboardInput
          value={time}
          onChange={(val) => {
            setTime(val);
          }}
        />
        {!examMultiple && (
          <WaittenPaperList
            onChange={(_, v) => {
              setPapers([v]);
            }}
          />
        )}

        {examMultiple && (
          <WaittenMultiplePaperList
            onChange={(_, v) => {
              setPapers(v);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ELButton onClick={onCancel}> {cancelTitle}</ELButton>
        <ELButton
          variant="contained"
          disableElevation
          disabled={papers.length === 0 || !moment(time).isValid()}
          color={severity === 'success' ? 'primary' : 'secondary'}
          onClick={() => {
            onConfirm({ planTime: parseInt(time.getTime() / 1000, 0), paperTokens: papers.map((v) => v.token) });
          }}
        >
          {confirmTitile}
        </ELButton>
      </DialogActions>
    </Dialog>
  );
};

WrittenCreate.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  severity: PropTypes.oneOf(['success', 'warning']),
  open: PropTypes.bool,
  examMultiple: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelTitle: PropTypes.string,
  confirmTitile: PropTypes.string,
};

WrittenCreate.defaultProps = {
  title: '发起代码笔试',
  severity: 'success',
  open: true,
  examMultiple: false,
  onCancel: () => {},
  onConfirm: () => {},
  cancelTitle: '取消',
  confirmTitile: '确认',
};

export default withStyles(styles)(WrittenCreate);
