import axios from './Api';
/**
 *获得分部列表树形结构
 *
 * @param {*} isShowStat
 * @returns Object
 */
export const getDeparmentTree = (isShowStat) => axios.request({
  url: '/admin/departmentTree',
  method: 'GET',
  params: { withStat: isShowStat },
});
/**
 *获取当前用户对应得分部信息
 *
 * @param {*} isShowStat
 * @returns
 */
export const getUserDeparmentTree = (isShowStat) => axios.request({
  url: '/loginUser/departments',
  method: 'GET',
  params: { withStat: isShowStat },
});
/**
 *创建分部信息
 *
 * @param {*} data
 * @returns
 */
export const addDepartment = (data) => axios.request({
  url: '/admin/department',
  method: 'POST',
  data,
});

/**
 *创建分部信息
 *
 * @param {*} data
 * @returns
 */
export const delDepartment = (tokens) => axios.request({
  url: '/admin/department',
  method: 'DELETE',
  data: { tokens },
});
/**
 *获取分部详情
 *
 * @param {*} token
 * @returns
 */
export const getDepartment = (token) => axios.request({
  url: `/admin/v2/department/${token}`,
  method: 'GET',
});
/**
 *获得当前可以管理的分部用户
 * @param {*} deparmentToken
 * @returns
 */
export const getDepartmentUser = (departmentToken) => axios.request({
  url: 'admin/usersPop',
  method: 'GET',
  params: { selectedDepartmentToken: departmentToken },
});
/**
 *获得当前可以管理的分部用户
 *
 * @param {*} deparmentToken
 * @param {*} [userTokens=[]]
 * @returns
 */
export const setDepartmentUser = (deparmentToken, userTokens = []) => axios.request({
  url: `/admin/department/${deparmentToken}/users`,
  method: 'POST',
  data: { userTokens },
});

/**
 *获得当前可以管理的分部用户
 *
 * @param {*} deparmentToken
 * @param {*} [userTokens=[]]
 * @returns
 */
export const removeDepartmentUser = (deparmentToken, userTokens = []) => axios.request({
  url: `/admin/department/${deparmentToken}/users`,
  method: 'DELETE',
  data: { userTokens },
});

/**
 *编辑分部
 *
 * @param {*} deparmentToken
 * @param {*} [data=[]]
 * @returns
 */
export const editDepartment = (deparmentToken, data = []) => axios.request({
  url: `/admin/department/${deparmentToken}`,
  method: 'PATCH',
  data: { data },
});

/**
 *新增HR
 *
 * @param {*} [data={}]
 * @returns
 */
export const newDepartmentUser = (data = {}) => axios.request({
  url: '/admin/user',
  method: 'POST',
  data,
});

export const newProductPay = (data = {}) => axios.request({
  url: '/department/product/pay',
  method: 'POST',
  data,
});

export const checkTrade = (data = {}) => axios.request({
  url: '/department/trade/query',
  method: 'POST',
  data,
});

export const newPublicTransfer = (data = {}) => axios.request({
  url: '/department/public/transfer',
  method: 'POST',
  data,
});

export const auditCreditRecommend = (data = {}) => axios.request({
  url: '/department/jifen/recommend/reward/audit',
  method: 'POST',
  data,
});

export const auditCreditShare = (data = {}) => axios.request({
  url: '/department/jifen/share/reward/audit',
  method: 'POST',
  data,
});
