import * as ACTIONS from '../constants/ActionTypes';

const initialState = {
  candidateCount: 0,
  candidates: [],
  selected: [],
  filter: {
    searchText: '',
    sex: '',
    ageFrom: 0,
    ageTo: 0,
    degree: '',
    source: '',
    applyTimeStart: '',
    applyTimeEnd: '',
    applyStatus: 0,
    orderBy: '',
    asc: false,
    page: '1',
    pageSize: '30',
  },
};

export default function candidates(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.ADD_CANDIDATES: {
      return {
        ...state,
        candidateCount: action.candidateCount,
        candidates: [...action.candidates],
      };
    }
    case ACTIONS.EDIT_CANDIDATE:
      return {
        candidateCount: state.candidateCount,
        candidates: state.candidates.map((candidate) => (candidate.token === action.token
          ? {
            ...candidate,
            token: candidate.token,
            flowToken: candidate.flowToken,
            name: candidate.name,
            mobile: candidate.mobile,
            wxid: candidate.wxid,
            sex: candidate.sex,
            birthday: candidate.birthday,
            age: candidate.age,
            degree: candidate.degree,
            source: candidate.source,
            flowStatus: candidate.flowStatus,
            updateTime: candidate.updateTime,
            positionName: candidate.positionName,
          }
          : candidate)),
        selected: [...state.selected],
        filter: state.filter,
      };
    case ACTIONS.DELETE_CANDIDATE:
      return {
        candidateCount: state.candidateCount,
        candidates: state.candidates.filter((candidate) => candidate.token !== action.token),
        selected: [...state.selected],
        filter: state.filter,
      };
    case ACTIONS.CLEAR_CANDIDATES:
      return {
        candidateCount: state.candidateCount,
        candidates: [...initialState.candidates],
        selected: [],
        filter: state.filter,
      };
    case ACTIONS.GET_CANDIDATES:
      return state.candidates;
    case ACTIONS.ADD_SELECTED_CANDIDATE:
      return {
        candidateCount: state.candidateCount,
        candidates: [...state.candidates],
        selected: [...state.selected, action.token],
        filter: state.filter,
      };
    case ACTIONS.CLEAR_SELECTED_CANDIDATE:
      return {
        candidateCount: state.candidateCount,
        candidates: [...state.candidates],
        selected: [],
        filter: state.filter,
      };
    case ACTIONS.REMOVE_SELECTED_CANDIDATE:
      return {
        candidateCount: state.candidateCount,
        candidates: [...state.candidates],
        selected: state.selected.filter((token) => token !== action.token),
        filter: state.filter,
      };
    case ACTIONS.SELECTED_ALL_CANDIDATE:
      return {
        candidateCount: state.candidateCount,
        candidates: [...state.candidates],
        selected: state.candidates.map((candidate) => candidate.token),
        filter: state.filter,
      };
    case ACTIONS.UPDATE_SELECTED_CANDIDATE: {
      const { payload } = action;
      return {
        ...state,
        selected: payload,
      };
    }
    case ACTIONS.UPDATE_CANDIDATES_FILTER:
      return {
        candidateCount: state.candidateCount,
        candidates: [...state.candidates],
        selected: [...state.selected],
        filter: action.filter,
      };
    case ACTIONS.CLEAR_CANDIDATES_FILTER:
      return {
        candidateCount: state.candidateCount,
        candidates: [...state.candidates],
        selected: [...state.selected],
        filter: {
          searchText: typeof action.searchText === 'undefined' ? '' : action.searchText,
          sex: typeof action.sex === 'undefined' ? '' : action.sex,
          ageFrom: typeof action.ageFrom === 'undefined' ? '' : action.ageFrom,
          ageTo: typeof action.ageTo === 'undefined' ? '' : action.ageTo,
          degree: typeof action.degree === 'undefined' ? '' : action.degree,
          source: typeof action.source === 'undefined' ? '' : action.source,
          applyTimeStart: typeof action.applyTimeStart === 'undefined' ? '' : action.applyTimeStart,
          applyTimeEnd: typeof action.applyTimeEnd === 'undefined' ? '' : action.applyTimeEnd,
          applyStatus: typeof action.applyStatus === 'undefined' ? 0 : action.applyStatus,
          orderBy: typeof action.orderBy === 'undefined' ? '' : action.orderBy,
          asc: false,
          page: '1',
          pageSize: '30',
        },
      };
    default:
      return state;
  }
}
