import axios from './Api';

export const getInboxMessage = (departmentToken) => axios.request({
  url: '/inbox/messages/notify',
  method: 'GET',
  params: { departmentToken },
});

export const getInboxMessageRecent = (data) => axios.request({
  url: '/inbox/messages/recent',
  method: 'POST',
  data,
});

export const getInboxMessageAtList = () => axios.request({
  url: '/application/comment/at/list',
  method: 'POST',
});

export const postAtMessageRead = (data) => axios.request({
  url: '/at/message/read',
  method: 'POST',
  data,
});

export default {};
