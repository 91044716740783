import { CancelIcon } from '@avocadoui/icons';
import API_URL from '@config/conf';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactComponent as AppBarSearch } from '../../../../assets/img/icons/appBar/AppBarSearch.svg';
import http from '../../../utils/http/http';


const useStyles = (theme) => ({
  root: {
    padding: '8px',
    paddingRight: '0px',
    display: 'flex',
    alignItems: 'center',
    width: '280px',
    height: '40px',
    borderRadius: '8px',
    position: 'relative',
    backgroundColor: '#F5F5F5',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #F5F5F5',
    },
  },
  searchFocusRoot: {
    padding: '8px',
    paddingRight: '0px',
    display: 'flex',
    alignItems: 'center',
    width: '280px',
    height: '40px',
    borderRadius: '8px',
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid transparent',
    boxShadow: '0 1px 1px 0 rgba(65,69,73,0.3),0 1px 3px 1px rgba(65,69,73,0.15)',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    marginLeft: 4,
    marginRight: 10,
    color: '#5f6367',
    height: 16,
    width: 16,
  },
  divider: {
    height: '1.75rem',
    margin: '0.25rem',
  },
  closeIcon: {
  },
  searchWrap: {
    display: 'flex',
    jusityContent: 'center',
    alignItems: 'center',
  },
  candidateListWrap: {
    position: 'absolute',
    width: '100%',
    height: '266px',
    zIndex: 999,
    top: '40px',
    left: -1,
    padding: '4px 0px',
    boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.16)',
    border: '1px solid rgba(237,237,237,1)',
  },
  candidateList: {
    width: '100%',
    height: '258px',
    marginBlockStart: '0em',
    marginBlockEnd: '0em',
    paddingInlineStart: '0',
    margin: 0,
    padding: 0,
    overflow: 'scroll',
  },
  candidateItem: {
    listStyle: 'none',
    width: '100%',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
  },
  candidateName: {
    marginLeft: theme.spacing(2),
    fontSize: 14,
    // color: 'rgba(0,0,0,0.87)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: theme.spacing(21),
  },
  candidateContact: {
    position: 'absolute',
    right: theme.spacing(2),
    fontSize: '0.875rem',
    color: 'rgba(0,0,0,0.6)',
  },
});

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowClose: false,
      isShowCandidateList: false,
      lock: false,
      open: false,
      candidateArray: [],
      candidateToken: '',
      inputValue: false,
      isFocus: false,
    };
  }

  handleChange = async (event) => {
    this.setState({
      inputValue: event.target.value,
    });
    const { lock } = this.state;
    if (!lock) {
      if (!event.target.value) {
        this.setState({
          isShowCandidateList: false,
          isShowClose: false,
          inputValue: false,
        });
        return;
      }
      try {
        if (event.target.value == '') {
          this.setState({
            inputValue: false,
          });
          return;
        }
        const candidateData = await http.get({
          url: `${API_URL}candidatesWithApplication?searchText=${event.target.value}`,
        });
        this.setState({
          candidateArray: candidateData.data.data.list,
        });
      } catch (error) {
        console.log(error);
      }
      this.setState({
        isShowClose: true,
        isShowCandidateList: true,
      });
    }
  };

  handleStart = () => {
    this.setState({
      lock: true,
    });
  };

  handleEnd = async (event) => {
    try {
      const candidateData = await http.get({
        url: `${API_URL}candidatesWithApplication?searchText=${event.target.value}`,
      });
      this.setState({
        candidateArray: candidateData.data.data.list,
      });
    } catch (error) {
      console.log(error);
    }
    this.setState({
      isShowClose: true,
      isShowCandidateList: true,
      lock: false,
    });
  };

  closeSearch = () => {
    this.setState({
      isShowCandidateList: true,
    });
    this.setState({
      isShowClose: false,
      isShowCandidateList: false,
      inputValue: false,
    });
    document.getElementById('input').value = '';
    document.getElementById('input').focus();
  };

  closecandidateList = () => {
    this.setState({
      isShowCandidateList: false,
    });
  };

  // 打开候选人详情弹框
  handleClickOpen = (token, applicationToken) => {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('candidate', token);
    params.set('application', applicationToken);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
    this.setState({
      candidateToken: token,
      open: true,
      applicationToken,
    });
    this.closeSearch();
  };

  // 关闭候选人详情弹框
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleClickInp = async () => {
  };

  handleChangeStyle = () => {
  
  };

  nofocus = () => {
  };

  // 顶部栏-搜索框
  render() {
    const {
      isShowClose, isShowCandidateList, candidateArray, open, candidateToken, isFocus,
    } = this.state;
    const { classes, hidden } = this.props;
    return (
      <div rapidpositioning="cut7ln" className={classes.searchWrap}>
        <ClickAwayListener onClickAway={this.nofocus}>
          <Paper
            hidden={hidden}
            className={isFocus ? classes.searchFocusRoot : classes.root}
            onClick={(event) => this.handleChangeStyle(event)}
            id="activeInput"
            autoComplete="off"
            elevation={0}
          >
            <AppBarSearch className={classes.iconButton} />
            <InputBase
              style={{ fontSize: 14 }}
              className={classes.input}
              id="input"
              defaultValue=""
              onChange={this.handleChange}
              onClick={(event) => this.handleClickInp(event)}
              placeholder="姓名或手机号"
              onCompositionStart={this.handleStart}
              onCompositionEnd={this.handleEnd}
              inputProps={{ autoComplete: 'off' }}
              onFocus={() => {
                this.setState({ isFocus: true });
              }}
              onBlur={() => {
                this.setState({ isFocus: false });
              }}
            />
            {isShowClose && (
              <IconButton
                onClick={(event) => this.closeSearch(event)}
              >
                <CancelIcon style={{ color: 'rgba(0,0,0,0.26)' }} />
              </IconButton>
            )}
            {isShowCandidateList && (
              <ClickAwayListener onClickAway={this.closecandidateList}>
                <Paper elevation={0} className={classes.candidateListWrap}>
                  {candidateArray && candidateArray.length === 0 && (
                    <Box
                      height="100%"
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <img
                        src="//public-static-assets.oss-cn-beijing.aliyuncs.com/img/AppBarSearchEmpty.png"
                        alt=""
                        width="132"
                        height="132"
                      />
                      <Box height={24} />
                      <Typography variant="body2" color="textSecondary">
                        无相关搜索结果
                      </Typography>
                    </Box>
                  )}
                  {candidateArray && candidateArray.length > 0 && (
                    <ul className={classes.candidateList}>
                      {candidateArray
                        && candidateArray.map((value) => (
                          <li
                            role="presentation"
                            key={value.token}
                            className={classes.candidateItem}
                            onClick={() => this.handleClickOpen(value.token, value.applicationToken)}
                          >
                            <div className={classes.candidateName}>{value.name}</div>
                            <span className={classes.candidateContact}>{value.mobile}</span>
                          </li>
                        ))}
                    </ul>
                  )}
                </Paper>
              </ClickAwayListener>
            )}
          </Paper>
        </ClickAwayListener>
      </div>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  hidden: PropTypes.bool,
};
Search.defaultProps = {
  hidden: false,
};

export default withRouter(withStyles(useStyles)(Search));
