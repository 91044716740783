function Loading() {
  return (
    <div style={{
      minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
    }}
    >
      <img src="/asset/icons/logo-loading.svg" alt="logo" style={{ width: '100px' }} />
    </div>
  );
}

export default Loading;
