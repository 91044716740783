import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PanoTheme from '../../theme/theme';

const DialogActions = withStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    height: 52,
  },
}))(MuiDialogActions);

const CustomPrompt = ({ message, cleanUp }) => {
  const cancel = () => cleanUp(false);
  const ok = () => cleanUp(true);

  return (
    <MuiThemeProvider theme={PanoTheme}>
      <CssBaseline />
      <Dialog
        open
        onClose={cancel}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            width: '280px',
            height: '132px',
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>取消</Button>
          <Button onClick={ok} color="secondary" autoFocus variant="contained">
            离开
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

CustomPrompt.propTypes = {
  message: PropTypes.string.isRequired,
  cleanUp: PropTypes.func.isRequired,
};

CustomPrompt.defaultProps = {};

export default CustomPrompt;
