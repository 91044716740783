import { OverflowIcon } from '@avocadoui/icons';
import Avatar from '@components/Avatar';
import {
  ClockIcon,
  EmptyInterviewsIcon,
  LocationIcon,
  PositionIcon,
  RemarkIcon,
  StopwatchIcon,
  TagIcon,
  UserIcon,
} from '@components/Icon';
import {
  Button, InputBase, ListItemAvatar, Menu,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Select from '@material-ui/core/Select';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import { openCandidateModal } from '@redux/candidateModal/action';
import * as interviewActions from '@redux/interviewArrangement/action';
import useGlobalToast from '@utils/GlobalToast';
import moment from '@utils/moment';
import { useActions } from '@utils/useActions';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoLink from './AutoLink';
import { INTERVIEW_APPLICATION_STATUS, INTERVIEW_TYPES_OBJECT } from './const';
import { useInterviewState } from './context';
import { CancelInterviewDialog, SendNotificationToCandidatesDialog } from './Dialog';
import styles from './index.module.css';
import { StatusChip } from './Table';

function CandidateItem({ item, processToken }) {
  const {
    applicationToken, candidate, status,
  } = item;
  const dispatchRedux = useDispatch();

  function handleClick() {
    dispatchRedux(
      openCandidateModal({
        candidateToken: candidate?.token,
        applicationToken,
        processTokenFromInterviewArrangement: processToken,
        hideSwitchButton: true,
      }),
    );
  }

  return (
    <ListItem button onClick={handleClick}>
      <ListItemAvatar>
        <Avatar src={candidate.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={candidate.name}
        secondary={(
          <Box display="flex" alignItems="center">
            <Box>{candidate.mobile}</Box>
            <div
              style={{
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                margin: '0px 8px',
                backgroundColor: 'rgba(0, 0, 0, 0.24)',
              }}
            />
            <Box>{INTERVIEW_APPLICATION_STATUS[status]}</Box>
          </Box>
        )}
      />
      <ListItemSecondaryAction>
        <ChevronRightIcon />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

CandidateItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  processToken: PropTypes.string.isRequired,
};

function FilteredInterviewerList({
  candidates, interviewMode, type, processToken,
}) {
  const [status, setStatus] = useState('0');
  const list = status === '0' ? candidates.list : candidates.list.filter((item) => item.status === +status);
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p="0px 16px"
        fontSize="12px"
        color="#7C7C7C"
        style={{ backgroundColor: '#F9F9F9' }}
      >
        <Box>人数：{list.length}</Box>
        <Box>
          <Select
            input={<InputBase style={{ color: 'rgb(124, 124, 124)', fontSize: '12px' }} />}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="0">全部</MenuItem>
            {interviewMode === 2 && <MenuItem value="1">未接受</MenuItem>}
            <MenuItem value="2">已接受</MenuItem>
            {(type === 1 || type === 3) && <MenuItem value="3">已签到</MenuItem>}
            <MenuItem value="5">已拒绝</MenuItem>
            <MenuItem value="4">已取消</MenuItem>
            <MenuItem value="6">已结束</MenuItem>
            <MenuItem value="7">申请修改时间</MenuItem>
          </Select>
        </Box>
      </Box>
      {list.length > 0 ? (
        <List
          component="div"
          classes={{
            root: styles.cardList,
          }}
        >
          {list.map((item) => (
            <CandidateItem key={item.applicationToken} item={item} processToken={processToken} />
          ))}
        </List>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="32px"
          color="#c3c3c3"
        >
          <Box style={{ width: '70px', color: '#EDEDED' }}>
            <EmptyInterviewsIcon />
          </Box>
          <Box mt="8px">暂无{INTERVIEW_APPLICATION_STATUS[status]}</Box>
        </Box>
      )}
    </Box>
  );
}

FilteredInterviewerList.propTypes = {
  candidates: PropTypes.arrayOf(PropTypes.any).isRequired,
  interviewMode: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  processToken: PropTypes.string.isRequired,
};

function PeriodItem({ handleClose, item = {}, entryType = 3 }) {
  const {
    position,
    status,
    name,
    process,
    type,
    startTime,
    duration,
    deadline,
    address,
    remark,
    candidates,
    interviewMode,
    interviewers,
    periodToken,
    canNotifyInterviewer,
    interviewToken,
    createUser,
  } = item;
  const { openArrangementModal } = useActions(interviewActions);
  const GlobalToast = useGlobalToast();
  const [sendModalIsOpen, setSendModalIsOpen] = useState(false);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const currentAccountInfo = useSelector((state) => state.account?.userInfo?.user);
  const { role, token: currentAccountToken } = currentAccountInfo;
  const isHidden = role === 5; // 面试官账号
  const popupState = usePopupState({ variant: 'popover', popupId: 'interview-card' });

  function handleEdit(e) {
    // 检查登陆者是否为创建者本人
    if (createUser.token === currentAccountToken) {
      // 可以成功进行编辑
      openArrangementModal({
        entryType,
        periodToken,
        interviewToken,
        interviewMode,
      });
      if (handleClose) handleClose();
    } else {
      GlobalToast.warning(`只能创建者${createUser.name}操作`);
    }
    popupState.close(e);
  }

  function handleCancel(e) {
    // 检查登陆者是否为创建者本人
    if (createUser.token === currentAccountToken) {
      setCancelModalIsOpen(true);
    } else {
      GlobalToast.warning(`只能创建者${createUser.name}操作`);
    }
    popupState.close(e);
  }

  function handleCloseModal() {
    if (sendModalIsOpen) setSendModalIsOpen(false);
    if (cancelModalIsOpen) setCancelModalIsOpen(false);
    if (handleClose) handleClose();
  }

  function handleSendNotificationToInterviewer() {
    setSendModalIsOpen(true);
  }

  const isFinished = [2, 4].indexOf(status) > -1;

  return (
    <>
      <Paper elevation={3}>
        <Box minWidth="350px" color="#4a4a4a">
          <Box padding="4px 4px 0px 16px" paddingBottom="0px" display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box fontWeight="bold">{name}</Box>
              <Box ml={1} display="flex" alignItems="center">
                <StatusChip value={status} />
              </Box>
            </Box>
            <Box>
              {!isFinished && !isHidden && (
                <>
                  <IconButton {...bindTrigger(popupState)}>
                    <OverflowIcon />
                  </IconButton>
                  <Menu
                    {...bindMenu(popupState)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    <MenuItem onClick={handleEdit} disabled={isFinished}>
                      <ListItemText primary="修改邀约" />
                    </MenuItem>
                    <MenuItem onClick={handleCancel} disabled={isFinished}>
                      <ListItemText primary="取消邀约" />
                    </MenuItem>
                  </Menu>
                </>
              )}
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box padding="4px 16px 16px 16px" fontSize="12px">
            <Box display="flex" alignItems="center">
              <Box width="12px" display="flex" alignItems="center" color="#c3c3c3">
                <PositionIcon outlined />
              </Box>
              <Box ml="8px">{position.name}</Box>
            </Box>
            <Box display="flex" alignItems="center" mt="4px">
              <Box width="12px" display="flex" alignItems="center" color="#c3c3c3">
                <TagIcon outline />
              </Box>
              <Box ml="8px">
                {process.name} - {INTERVIEW_TYPES_OBJECT[type]}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mt="4px">
              <Box width="12px" display="flex" alignItems="center" color="#c3c3c3">
                <ClockIcon outline />
              </Box>
              <Box ml="8px">
                {moment(startTime * 1000).format('YYYY-MM-DD HH:mm')} 时长{duration / 60}分钟
              </Box>
            </Box>
            {interviewMode === 1 && (
              <Box display="flex" alignItems="center" mt="4px">
                <Box width="12px" display="flex" alignItems="center" color="#c3c3c3">
                  <StopwatchIcon />
                </Box>
                <Box ml="8px">{moment(deadline * 1000).format('YYYY-MM-DD HH:mm')} 截止报名</Box>
              </Box>
            )}
            {address && (
              <Box display="flex" alignItems="center" mt="4px">
                <Box width="12px" display="flex" alignItems="center" color="#c3c3c3">
                  <LocationIcon outline />
                </Box>
                <Box ml="8px">{type === 2 ? <AutoLink text={address} /> : address}</Box>
              </Box>
            )}
            {interviewers.total > 0 && (
              <Box display="flex" alignItems="center" justifyContent="space-between" mt="4px">
                <Box display="flex" alignItems="center">
                  <Box width="12px" display="flex" alignItems="center" color="#c3c3c3">
                    <UserIcon outline />
                  </Box>
                  {interviewers.total === 1 && <Box ml="8px">{interviewers.list[0].name}</Box>}
                  {(interviewers.total === 2 || interviewers.total === 3) && (
                    <Box ml="8px">{interviewers.list.map((p) => p.name).join('、')}</Box>
                  )}
                  {interviewers.total > 3 && (
                    <Box ml="8px">
                      {interviewers.list
                        .slice(0, 3)
                        .map((p) => p.name)
                        .join('、')}
                      等{interviewers.total}位企业参与成员
                    </Box>
                  )}
                </Box>
                {!isHidden && (
                  <Box ml="8px">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleSendNotificationToInterviewer}
                      disabled={!canNotifyInterviewer || isFinished}
                    >
                      发送提醒
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {remark && (
              <Box display="flex" alignItems="center" mt="4px">
                <Box width="12px" display="flex" alignItems="center" color="#c3c3c3">
                  <RemarkIcon outline />
                </Box>
                <Box ml="8px">{remark}</Box>
              </Box>
            )}
          </Box>
          <Box>
            {candidates.list && candidates.list.length > 0 ? (
              <FilteredInterviewerList
                candidates={candidates}
                interviewMode={interviewMode}
                type={type}
                processToken={process.token}
              />
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding="32px"
                color="#c3c3c3"
                borderTop="1px solid #F9F9F9"
              >
                <Box style={{ width: '70px', color: '#EDEDED' }}>
                  <EmptyInterviewsIcon />
                </Box>
                <Box mt="8px">暂无候选人</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
      <CancelInterviewDialog
        open={cancelModalIsOpen}
        onClose={handleCloseModal}
        interviewToken={interviewToken}
        periodToken={periodToken}
      />
      <SendNotificationToCandidatesDialog
        open={sendModalIsOpen}
        onClose={handleCloseModal}
        periodToken={periodToken}
        interviewToken={interviewToken}
      />
    </>
  );
}

PeriodItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  entryType: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default function PeriodItemPopper({
  open, anchorEl, handleClose, item, entryType,
}) {
  const tabIndex = useInterviewState((state) => state.tabIndex);
  const page = useInterviewState((state) => state.page);
  const hasRendered = useRef(false);
  useEffect(() => {
    if (hasRendered.current) {
      handleClose();
    }
    hasRendered.current = true;
  }, [tabIndex, page]);

  if (!open) {
    return null;
  }

  return (
    <Popper open={open} anchorEl={anchorEl} transition style={{ zIndex: 1300 }}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <PeriodItem handleClose={handleClose} item={item} entryType={entryType} />
        </Fade>
      )}
    </Popper>
  );
}

PeriodItemPopper.propTypes = {
  open: PropTypes.bool.isRequired, // 控制 Popper 开关
  anchorEl: PropTypes.objectOf(PropTypes.any).isRequired, // 一般为 event.currentTarget
  handleClose: PropTypes.func.isRequired, // 将 open 置为 false
  item: PropTypes.objectOf(PropTypes.any).isRequired, // 场次详情数据
  entryType: PropTypes.number.isRequired, // 来源类型
};
