import { getEvaluationTemplate } from '@api/Evaluation';
import { useEffect } from 'react';
import { useEvaluationDispatch, useEvaluationState } from '../context';
import { getEvaluation } from '../utils';

export default function useEffectReloadEvaluationTemplate(cacheID) {
  const dispatch = useEvaluationDispatch();
  const count = useEvaluationState((state) => state.count);
  const selectedTemplateToken = useEvaluationState((state) => state.selectedTemplateToken);

  useEffect(() => {
    const CACHE_DATA = getEvaluation(cacheID) || {};
    getEvaluationTemplate(selectedTemplateToken).then((result) => {
      const value = {
        ...result.data.data,
        visibleSwitch: CACHE_DATA?.visibleSwitch || 2,
        loaded: true,
        visibleUsers: CACHE_DATA?.visibleUsers || (result.data.data.visibleUsers
          ? result.data.data.visibleUsers.map((user) => ({
            username: user.name,
            userToken: user.token,
            userAvatar: user.avatar,
          }))
          : []),
      };
      const payload = { key: 'selectedTemplateDetail', value };
      dispatch({ type: 'SET_STATE', payload });
      dispatch({ type: 'SET_STATE', payload: { key: 'formData', value: CACHE_DATA?.formData || {} } });
      dispatch({ type: 'SET_STATE', payload: { key: 'feedbackData', value: CACHE_DATA?.feedbackData || {} } });
    });
  }, [count, selectedTemplateToken, cacheID]);
}
