import { Box, Button, withStyles } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import { useCandidateDispatch, useCandidateState } from '../context';

const Alert = withStyles(() => ({
  root: {
    margin: 0,
  },
}))(MuiAlert);

function OtherEntryAlert() {
  const dispatch = useCandidateDispatch();
  const otherEntry = useCandidateState((state) => state.applicationData.otherEntry || {});
  const { has, positionName, departmentName } = otherEntry;

  if (!has) return null;

  const html = `入职部门: ${departmentName}<br />入职职位：${positionName}<br />如有需求可以和相关部门争取。`;

  return (
    <Box mb={2}>
      <Alert
        severity="warning"
        action={(
          <Button
            size="small"
            style={{ color: '#576B95' }}
            onClick={() => {
              dispatch({
                type: 'SET_ALERT_DIALOG',
                // eslint-disable-next-line react/no-danger
                payload: { title: '该候选人已入职其他职位', desc: <div dangerouslySetInnerHTML={{ __html: html }} /> },
              });
            }}
          >
            详情
          </Button>
        )}
      >
        该候选人已入职其他职位
      </Alert>
    </Box>
  );
}

export default OtherEntryAlert;
