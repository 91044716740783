import createMentionPlugin, { defaultTheme } from '@avocadoui/draft-js-plugins-mention';
import '@avocadoui/draft-js-plugins-mention/lib/plugin.css';
import Editor from '@draft-js-plugins/editor';
import { Box, Button } from '@material-ui/core';
import { fetchAtList, sendCommentMessage } from '@redux/atmessage/action';
import { CommentTransitionStateToServer } from '@utils/DraftUtils';
import { ContentState, EditorState } from 'draft-js';
import PinyinMatch from 'pinyin-match';
import PropTypes from 'prop-types';
import {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mentionsStyles from './index.module.css';
import editorStyles from './MentionEditor.module.css';
import MentionsItem from './MentionsItem';

const Index = ({
  applicationToken, applicationProcessToken, onUpdateCommentList, setShowActionButtons,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.atmessage.users);
  const mentions = useMemo(() => users.map((v) => ({
    ...v, id: v.userToken, name: v.username, avatar: v.userAvatar,
  })), [users]);
  const ref = useRef();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mentions);
  const [isShowActions, setShowActions] = useState(false);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: {
        ...defaultTheme,
        ...mentionsStyles,
        mentionSuggestions: `${defaultTheme.mentionSuggestions} ${mentionsStyles.mentionSuggestionsLimitedHeight}`,
      },
      mentionPrefix: '@',
      // positionSuggestions,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }) => {
      if (value) {
        setSuggestions(mentions.filter((v) => PinyinMatch.match(v.username, value)));
      } else {
        setSuggestions(mentions);
      }
    },
    [mentions],
  );

  useEffect(() => {
    const data = { keyword: '', applicationToken };
    dispatch(fetchAtList(data));
  }, []);

  const handleCommentCancel = useCallback(() => {
    const editorStateEmpty = EditorState.push(editorState, ContentState.createFromText(''));
    setEditorState(editorStateEmpty);
  });

  const handleCommentSubmit = useCallback(() => {
    const data = {
      applicationProcessToken,
      comment: CommentTransitionStateToServer(editorState),
    };

    dispatch(sendCommentMessage(data));

    const editorStateEmpty = EditorState.push(editorState, ContentState.createFromText(''));
    setEditorState(editorStateEmpty);

    setTimeout(() => {
      onUpdateCommentList();
    }, 200);
  }, [applicationProcessToken, editorState]);

  return (
    <Box
      className={editorStyles.editor}
      // onClick={() => {
      //   ref.current.focus();
      // }}
    >
      <Editor
        editorKey="editor"
        editorState={editorState}
        placeholder="评论"
        onChange={setEditorState}
        plugins={plugins}
        ref={ref}
        onFocus={() => {
          setShowActions(true);
          setShowActionButtons(false);
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowActions(false);
            setShowActionButtons(true);
          }, 500);
        }}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={() => {}}
        entryComponent={MentionsItem}
      />
      {isShowActions && (
        <Box display="flex" justifyContent="flex-end">
          <Button size="small" onClick={handleCommentCancel}>取消</Button>
          <Box mr={1} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={editorState.getCurrentContent().getPlainText().length === 0}
            onClick={handleCommentSubmit}
          >
            发布
          </Button>
        </Box>
      )}
    </Box>
  );
};

Index.propTypes = {
  applicationToken: PropTypes.string.isRequired,
  applicationProcessToken: PropTypes.string.isRequired,
  onUpdateCommentList: PropTypes.func.isRequired,
  setShowActionButtons: PropTypes.func.isRequired,
};

export default memo(Index);
