import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  resetCandidateAndApplicationToken,
  setCandidateAndApplicationToken,
} from '@redux/candidateModal/action';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styles from './CandidateQuickAction.module.css';
import { useCandidateDispatch } from './context';
import { setUrlQuery } from './utils';

const CandidateQuickAction = () => {
  const reviewList = useSelector((state) => state.reviewList);
  const candidates = useSelector((state) => state.candidates);
  const { talents } = useSelector((state) => state.talent);
  const candidateToken = useSelector(({ candidateModal }) => candidateModal.candidateToken);
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const dispatch = useCandidateDispatch();
  const isAtCandidateListRoute = useRouteMatch('/dashboard/candidatesList/:candidateToken');
  const isAtSearchRoute = useRouteMatch('/dashboard/search/:candidateToken');
  const isAtTalentRoute = useRouteMatch('/dashboard/talent') || useRouteMatch('/dashboard/talent/:candidateToken');

  const location = useLocation();
  const history = useHistory();
  const dispatchRedux = useDispatch();

  const talentList = useMemo(() => {
    if (isAtCandidateListRoute) return candidates.candidates;
    if (isAtTalentRoute) return talents;
    return reviewList.reviewList;
  }, [isAtCandidateListRoute, isAtSearchRoute, isAtTalentRoute]);

  function handleSwitchApplication(diff) {
    const currentIndex = reviewList.reviewList.findIndex((v) => v.token === applicationToken);
    const newApplicationToken = reviewList.reviewList[currentIndex + diff]
      && reviewList.reviewList[currentIndex + diff].token;
    const newCandidateToken = reviewList.reviewList[currentIndex + diff]
      && reviewList.reviewList[currentIndex + diff].candidate.token;
    const isDeleted = reviewList.reviewList[currentIndex + diff]
      && reviewList.reviewList[currentIndex + diff].candidate.isDeleted;

    // candidate.token 为空，则候选人库里没有该候选人
    // candidate.isDeleted 为 true，则该候选人已被删除
    if (!newCandidateToken || isDeleted) {
      const diff2 = diff > 0 ? 1 : -1;
      handleSwitchApplication(diff + diff2);
      return;
    }

    dispatchRedux(resetCandidateAndApplicationToken());
    dispatch({ type: 'RESET_TO_DEFAULT_STATE' });
    if (newApplicationToken && newCandidateToken) {
      const query = setUrlQuery(location, { candidate: newCandidateToken, application: newApplicationToken });
      history.push(`${location.pathname}?${query.toString()}`);
      dispatchRedux(
        setCandidateAndApplicationToken({ candidateToken: newCandidateToken, applicationToken: newApplicationToken }),
      );
    }
  }

  function handleSwitchCandidate(diff) {
    const currentIndex = talentList.findIndex((v) => v.token === candidateToken);
    const newCandidateToken = talentList[currentIndex + diff] && talentList[currentIndex + diff].token;
    dispatchRedux(resetCandidateAndApplicationToken());
    dispatch({ type: 'RESET_TO_DEFAULT_STATE' });
    if (newCandidateToken) {
      if (isAtSearchRoute) {
        history.replace(`/dashboard/search/${newCandidateToken}`);
      } else {
        // history.push(`/dashboard/candidatesList/${newCandidateToken}`);
      }
      dispatchRedux(setCandidateAndApplicationToken({ candidateToken: newCandidateToken, applicationToken: '' }));
    }
  }

  function shouldDisabledPrev() {
    let currentIndex = -1;
    if (isAtCandidateListRoute) {
      currentIndex = talentList.findIndex((v) => v.token === candidateToken);
    } else if (isAtTalentRoute) {
      currentIndex = talentList.findIndex((v) => v.token === candidateToken);
    } else {
      currentIndex = talentList.findIndex((v) => v.token === applicationToken);
    }

    if (currentIndex === -1) return true;

    if (!isAtCandidateListRoute && !isAtTalentRoute) {
      const foundPrev = talentList
        .slice(0, currentIndex)
        .reverse()
        .some((v) => v.candidate.token && !v.candidate.isDeleted);

      if (!foundPrev) return true;
    }

    return currentIndex === 0;
  }

  function shouldDisabledNext() {
    let currentIndex = -1;
    if (isAtCandidateListRoute) {
      currentIndex = talentList.findIndex((v) => v.token === candidateToken);
    } else if (isAtTalentRoute) {
      currentIndex = talentList.findIndex((v) => v.token === candidateToken);
    } else {
      currentIndex = talentList.findIndex((v) => v.token === applicationToken);
    }
    if (currentIndex === -1) return true;

    if (!isAtCandidateListRoute && !isAtTalentRoute) {
      const foundNext = talentList.slice(currentIndex + 1).some((v) => v.candidate.token && !v.candidate.isDeleted);
      if (!foundNext) return true;
    }

    return currentIndex === talentList.length - 1;
  }

  return (
    <div className={styles.buttonGroup}>
      <IconButton
        disabled={shouldDisabledPrev()}
        onClick={() => (isAtCandidateListRoute || isAtTalentRoute
          ? handleSwitchCandidate(-1) : handleSwitchApplication(-1))}
      >
        <ExpandLessIcon />
      </IconButton>
      <IconButton
        disabled={shouldDisabledNext()}
        onClick={() => (isAtCandidateListRoute || isAtTalentRoute
          ? handleSwitchCandidate(1) : handleSwitchApplication(1))}
      >
        <ExpandMoreIcon />
      </IconButton>
    </div>
  );
};

export default CandidateQuickAction;
