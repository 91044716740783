import axios from './Api';

export const createReferral = (data) => axios.request({
  url: '/applicationSession/recommend/rule',
  method: 'POST',
  data,
});

export const getReferral = (token) => axios.request({
  url: `/applicationSession/recommend/rule/${token}`,
  method: 'GET',
});

export const editReferral = (token, data) => axios.request({
  url: `/applicationSession/recommend/rule/${token}`,
  method: 'PUT',
  data,
});

export const delReferral = (token) => axios.request({
  url: `/applicationSession/recommend/rule/${token}`,
  method: 'DELETE',
});

export const getReferralPostions = (token, positionToken) => axios.request({
  url: '/applicationSession/recommend/positions',
  method: 'GET',
  params: { ruleToken: token, positionToken },
});

export const getReferrals = (params) => axios.request({
  url: '/applicationSession/recommend/rules',
  method: 'GET',
  params,
});

export const addStaff = (data) => axios.request({
  url: '/staff',
  method: 'PUT',
  data,
});

export const shareStaff = (data) => axios.request({
  url: '/staff/share',
  method: 'GET',
  data,
});

export const getStaffs = (params) => axios.request({
  url: '/staffs',
  method: 'GET',
  params,
});

export const importStaffs = (data) => axios.request({
  url: '/staff/csv/import',
  method: 'GET',
  data,
});

export const getTokenRecommendList = (data) => axios.request({
  url: '/department/money/recommend/reward/list',
  method: 'POST',
  data,
});

export const getTokenShareList = (data) => axios.request({
  url: '/department/money/share/reward/list',
  method: 'POST',
  data,
});

export const getTokenRechargeList = (data) => axios.request({
  url: '/department/money/recharge/list',
  method: 'POST',
  data,
});

export const getCreditRecommendList = (data) => axios.request({
  url: '/department/jifen/recommend/reward/list',
  method: 'POST',
  data,
});

export const getCreditShareList = (data) => axios.request({
  url: '/department/jifen/share/reward/list',
  method: 'POST',
  data,
});

export const getTokenLockedList = (data) => axios.request({
  url: '/department/freeze/reward/list',
  method: 'POST',
  data,
});

export const getRecommendRewardRecords = (token) => axios.request({
  url: `/applicationSession/recommend/reward/${token}/records`,
  method: 'GET',
});

export const getCreditOverview = () => axios.request({
  url: '/department/jifen/account',
  method: 'GET',
});

export const getTokenOverview = () => axios.request({
  url: '/department/money/account',
  method: 'GET',
});

export const requestContract = (data) => axios.request({
  url: '/department/contract/application',
  method: 'POST',
  data,
});

export const requestInvoice = (data) => axios.request({
  url: '/department/invoice/application',
  method: 'POST',
  data,
});

export const getExportTaskId = (data) => axios.request({
  url: '/department/csv/export',
  method: 'POST',
  data,
});

export const exportTokenRecommendList = ({ taskId }) => axios.request({
  url: `/department/money/recommend/csv/export?taskId=${taskId}`,
  method: 'GET',
  headers: {
    'Content-Type': 'application/octet-stream',
  },
});

export default {};
