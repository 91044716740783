import { getCandidatesWithApplication } from '@api/Candidate';
import Typography from '@components/Avocado/Typography';
import { CircularProgress, Paper } from '@material-ui/core';
import { openCandidateModal } from '@redux/candidateModal/action';
import { addToChipList } from '@redux/search/action';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import {
  useState, useRef, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './ResultMiniList.style';

function ResultMiniList({ searchListRef, searchInputRef }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchText = useSelector(({ search }) => search.inputValue);
  const isAdvancedMode = useSelector(({ search }) => search.isAdvancedMode);
  const [loading, setLoading] = useState(true);
  const [resultList, setResultList] = useState([]);
  const [focusedItemToken, setFocusedItemToken] = useState('');

  const focusedItemRef = useRef();

  const fetchData = useCallback(
    debounce((data) => {
      setLoading(true);
      getCandidatesWithApplication(data).then((result) => {
        if (result.data.code === 0) {
          setResultList(result.data.data.list);
          setLoading(false);
        }
      });
    }, 500),
    [],
  );

  useEffect(() => {
    const data = { searchText };
    fetchData(data);
  }, [searchText]);

  useEffect(() => {
    if (resultList && resultList.length > 0) {
      setFocusedItemToken(resultList[0].token);
    }
  }, [resultList]);

  if (loading) {
    return (
      <Paper elevation={0} className={classes.searchList}>
        <CircularProgress />
      </Paper>
    );
  }

  if (resultList.length === 0) {
    return (
      <Paper elevation={0} className={classes.searchList}>
        <img
          src="//public-static-assets.oss-cn-beijing.aliyuncs.com/img/AppBarSearchEmpty.png"
          alt=""
          width="132"
          height="132"
        />
        <Typography mt={2} variant="body2" color="text.secondary">
          无相关搜索结果
        </Typography>
      </Paper>
    );
  }

  function handleOpenCandidateModal(candidateToken, applicationToken) {
    dispatch(openCandidateModal({ candidateToken, applicationToken, hideSwitchButton: true }));
  }

  function handleClick(value) {
    if (isAdvancedMode) {
      dispatch(addToChipList(value.name));
      if (searchInputRef.current) searchInputRef.current.focus();
    } else {
      handleOpenCandidateModal(value.token, value.applicationToken);
    }
  }

  // function handleFocus() {
  //   focusedItemRef.current.focus();
  // }

  function handleKeyDown(e) {
    const index = resultList.findIndex(({ token }) => token === focusedItemToken);
    // 40: 下箭头
    if (e.keyCode === 40) {
      const newIndex = index === resultList.length - 1 ? 0 : index + 1;
      setFocusedItemToken(resultList[newIndex].token);
      setTimeout(() => focusedItemRef.current?.focus(), 0);
      e.preventDefault();
    }
    // 38: 上箭头
    if (e.keyCode === 38) {
      const newIndex = index === 0 ? resultList.length - 1 : index - 1;
      setFocusedItemToken(resultList[newIndex].token);
      setTimeout(() => focusedItemRef.current?.focus(), 0);
      e.preventDefault();
    }
    // 13: 回车
    if (e.keyCode === 13) {
      const value = resultList[index];
      if (value) handleClick(value);
      e.preventDefault();
    }
  }

  return (
    <Paper elevation={0} className={classes.searchList}>
      <ul
        className={classes.candidateList}
        ref={searchListRef}
        // onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        role="listbox"
        tabIndex={0}
      >
        {resultList
          && resultList.map((value) => (
            <li
              key={value.token}
              className={classes.candidateItem}
              onClick={() => handleClick(value)}
              role="option"
              tabIndex={value.token === focusedItemToken ? -1 : 0}
              aria-selected={value.token === focusedItemToken}
              ref={value.token === focusedItemToken ? focusedItemRef : null}
            >
              <div className={classes.candidateName}>{value.name}</div>
              <span className={classes.candidateContact}>{value.mobile}</span>
            </li>
          ))}
      </ul>
    </Paper>
  );
}

ResultMiniList.propTypes = {
  searchListRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
  searchInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
};

export default ResultMiniList;
