/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { postAtMessageRead } from '@api/Messages';
import { CalendarIcon } from '@avocadoui/icons';
import Avatar from '@components/Avocado/Avatar';
import { Box, Link } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { fetchAtMessageList } from '@redux/atmessage/action';
import { openCandidateModal } from '@redux/candidateModal/action';
import { fromNow } from '@utils/moment';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AtMessageListEmpty from './AtMessageListEmpty';
import AtMessagePeriod from './AtMessagePeriod';

const RedBadge = withStyles(() => ({
  badge: {
    right: 7,
    top: 7,
    width: 12,
    minWidth: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: '#f00',
    border: '1px solid transparent',
  },
}))(Badge);

const useStyles = () => ({
  root: {
    width: '100%',
  },
  cardDiv: {
    width: 'calc(100% - 20px)',
    margin: '4px 12px 8px 8px',
    padding: '8px 12px 8px 12px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  avatar: {
    height: 56,
    width: 56,
  },
  readed: {
    '&:before': {
      backgroundColor: '#f00',
      borderRadius: '50%',
      widght: 5,
      height: 5,
    },
  },
});

class AtMessageList extends Component {
  constructor() {
    super();
    this.state = { periodToken: '', anchorEl: null };
  }

  handleShowCandidateDialog = (candidateToken, applicationToken, processToken) => {
    const { openCandidateModalAction } = this.props;
    openCandidateModalAction({
      candidateToken,
      applicationToken,
      processTokenFromInterviewArrangement: processToken,
      hideSwitchButton: true,
    });
    this.setState({ periodToken: '' });
  };

  handelShowPeriod = (periodToken, anchorEl, message) => {
    this.setState({ periodToken, anchorEl, message });
  };

  render() {
    const { periodToken, anchorEl, message = {} } = this.state;
    const {
      classes, AtMessage, onEvaluateItemClick, fetchAtMessageListAction,
    } = this.props;
    const itemList = AtMessage.messageList;

    if (!itemList?.length) return <AtMessageListEmpty />;

    return (
      <List dense className={classes.root}>
        {itemList.map((item) => {
          const isPeriod = !isEmpty(item.periodToken);
          let source = item.comment;
          const reg = /\{\{.*?\}\}/g;
          const wordTokens = source.split(reg);
          const atTokens = [];
          let result = reg.exec(source);
          while (result !== null) {
            atTokens.push(result[0]);
            const username = result[0];
            source = source.replace(item, `{{${username.substr(2, username.length - 4)}}}`);
            result = reg.exec(source);
          }
          const name = item.candidateName;
          const userName = item.username;
          return (
            <ListItem
              onClick={(e) => {
                if (isPeriod) {
                  this.handelShowPeriod(item.periodToken, e.currentTarget, item);
                } else {
                  if (!item.isRead) {
                    const data = { tokens: [item.token] };
                    postAtMessageRead(data);
                    fetchAtMessageListAction();
                  }
                  if (item.eventType === 4) {
                    onEvaluateItemClick(item.token);
                  } else {
                    this.handleShowCandidateDialog(item.candidateToken, item.applicationToken);
                  }
                }
              }}
              key={item.token}
              button
              className={classes.cardDiv}
            >
              <Box width={56} height={56} margin="0px 16px 0px 0px">
                <RedBadge color="secondary" overlap="circle" badgeContent=" " variant="dot" invisible={item.isRead}>
                  {isPeriod || item.eventType === 3 || item.eventType === 4 ? (
                    <Avatar size="large" backgroundColor="#4caf50">
                      <CalendarIcon size="large" />
                    </Avatar>
                  ) : (
                    <Avatar size="large" user={{ avatar: item.createUserAvatar, name: userName }} />
                  )}
                </RedBadge>
              </Box>
              <Box>
                {!isPeriod && item.username && item.eventType !== 4 && item.eventType !== 5 && (

                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" style={{ fontWeight: 500 }}>{`${item.username}`}</Typography>
                    <Typography variant="body2">{`在${name}中提到了你`}</Typography>
                  </Box>

                )}
                {wordTokens.map((word, wordIndex) => (
                  <Fragment key={word + wordIndex}>
                    {
                      item.eventType !== 4 && item.eventType !== 5 && (
                        <Typography variant="caption" display="inline">
                          {word}
                        </Typography>
                      )
                    }
                    {
                      item.eventType === 1 || item.eventType === 2 || item.eventType === 3 || (
                        <Typography variant="body2" display="inline">
                          {word}
                        </Typography>
                      )
                    }

                    <Link component="span" variant="caption" display="inline">
                      {atTokens[wordIndex] ? `@${atTokens[wordIndex].substr(2, atTokens[wordIndex].length - 4)} ` : ''}
                    </Link>
                    <Box component="span" className={classes.readed} />
                  </Fragment>
                ))}
                <Typography display="block" variant="caption" color="textSecondary">
                  {fromNow(item.timestamp)}
                </Typography>
              </Box>
            </ListItem>
          );
        })}
        {!isEmpty(periodToken) && (
          <AtMessagePeriod
            periodToken={periodToken}
            anchorEl={anchorEl}
            message={message}
            onCancel={() => {
              this.setState({ periodToken: '' });
            }}
            onShowCandidateDialog={this.handleShowCandidateDialog}
          />
        )}
      </List>
    );
  }
}

AtMessageList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  AtMessage: PropTypes.objectOf(PropTypes.any).isRequired,
  openCandidateModalAction: PropTypes.func.isRequired,
  onEvaluateItemClick: PropTypes.func.isRequired,
  fetchAtMessageListAction: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return { AtMessage: state.atmessage };
}

function mapDispatchToProps(dispatch) {
  return {
    openCandidateModalAction: (payload) => dispatch(openCandidateModal(payload)),
    fetchAtMessageListAction: (payload) => dispatch(fetchAtMessageList(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(AtMessageList));
