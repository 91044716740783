import * as API from '@api/Exams';
import { AddIcon } from '@avocadoui/icons';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PAGE_SIZE_PRE, START_PAGE } from '@utils/constants';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"]': {
    },
  },
}));

const WaittenPaperList = ({ onChange, marginTop }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [papers, setPapers] = useState([]);
  const [currentPaper, setCurrentPaper] = useState(null);
  const [consoleLink, setConsoleLink] = useState(START_PAGE);
  const loading = open;

  // && papers.length === 0;
  // const [page, setPage] = useState(1);

  const loadPaper = (page) => {
    API.getExamPaper(page, PAGE_SIZE_PRE)
      .then((v) => {
        if (v.data.code === 0) {
          setConsoleLink(v.data.data.consoleLink);
          setPapers([...papers, ...v.data.data.list]);
        }
      })
      .catch(console.log);
  };

  const reloadData = () => {
    API.getExamPaper(START_PAGE, PAGE_SIZE_PRE)
      .then((v) => {
        if (v.data.code === 0) {
          setConsoleLink(v.data.data.consoleLink);
          setPapers([{ token: '0', name: '添加考卷' }, ...v.data.data.list]);
          if (v.data.data.list.length === 50) {
            loadPaper(2);
          }
        }
      })
      .catch(console.log);
  };

  // const loadNextPage = () => {
  //   API.getExamPaper(page, PAGE_SIZE_PRE)
  //     .then((v) => {
  //       if (v.data.code === 0) {
  //         setConsoleLink(v.data.data.consoleLink);
  //         setPapers([...papers, ...v.data.data.list]);
  //       }
  //     })
  //     .catch(console.log);
  // };

  // useEffect(() => {
  //   console.log(page);
  //   if (page === 1) {
  //     reloadData();
  //   } else {
  //     loadNextPage();
  //   }
  // }, [page]);

  useEffect(() => {
    if (loading) {
      reloadData();
    }
  }, [loading]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      classes={{ inputRoot: classes.inputRoot }}
      onChange={(e, v) => {
        if (v?.token === '0') {
          window.open(consoleLink);
        } else {
          onChange(e, v);
          setCurrentPaper(v);
        }
      }}
      fullWidth
      size="medium"
      options={papers}
      value={currentPaper}
      noOptionsText="无结果"
      getOptionLabel={(option) => option.name}
      style={{ marginTop }}
      renderOption={(option) => (
        <Box
          height="36px"
          width="100%"
          overflow="hidden"
          display="flex"
          alignItems="center"
          style={{ lineHeight: '36px', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {option.token === '0' && <AddIcon />}
          {option.name}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="笔试考卷" variant="outlined" />}
    />
  );
};

WaittenPaperList.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  marginTop: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium']),
};

WaittenPaperList.defaultProps = {
  name: 'time',
  value: '',
  label: '笔试时间',
  size: 'medium',
  marginTop: 24,
  onChange: () => {},
};

export default WaittenPaperList;
