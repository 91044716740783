import {
  CloseIcon, EditIcon,
} from '@avocadoui/icons';
import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiIconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useState,
} from 'react';
import { CANCELED_PROCESS_APPLICATION_STATE } from '../const';
import { useCandidateDispatch, useCandidateState } from '../context';
import AttachmentFileInput from './AttachmentFileInput';
import AttachmentItem from './AttachmentItem';
import styles from './CandidateAttachments.module.css';

const FILE_SIZE_LIMIT = 50; // 单位为MB

const IconButton = withStyles({
  root: {
    padding: '6px',
    color: 'rgba(0, 0, 0, 0.56)',
  },
  sizeSmall: {
    padding: '5px',

    '& .MuiSvgIcon-root': {
      width: '14px',
      height: '14px',
    },
  },
})(MuiIconButton);

const DialogTitle = withStyles(() => ({
  root: {
    backgroundColor: 'white',
  },
}))(MuiDialogTitle);

const DialogActions = withStyles(() => ({
  root: {
    backgroundColor: 'white',
  },
}))(MuiDialogActions);

function AttachmentFolder({ item, index }) {
  const { group, files = [] } = item;

  const dispatch = useCandidateDispatch();
  const isEditingFromContext = useCandidateState((state) => state.isEditing);
  const isCanceled = useCandidateState((state) => CANCELED_PROCESS_APPLICATION_STATE
    .indexOf(state.applicationData.applicationState) > -1);
  const [isEditing, setIsEditing] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [groupName, setGroupName] = useState(group);

  const [link, setLink] = useState('');

  const onLinkChange = useCallback((e) => {
    const { value } = e.target;
    setLink(value.trim());
  }, []);

  const addApplicationAttachmentLink = useCallback(() => {
    if (link !== '') {
      const regex = /(http|ftp|https):\/{2}/i;
      const httpURL = regex.test(link) ? link : `http://${link}`;
      const attachmentLink = {
        name: '',
        length: 0,
        uploadedUrl: httpURL,
        mineType: 'text/link',
        type: 1,
      };
      dispatch({
        type: 'SET_UPLOADING_ATTACHMENT',
        payload: {
          folderIndex: index,
          fileIndex: item.files.length,
          file: attachmentLink,
        },
      });
      setLink('');
    }
  }, [link]);

  useEffect(() => {
    if (group == null) setIsEditing(true);
  }, [group]);

  useEffect(() => {
    if (!isEditing) {
      if (groupName.trim()) {
        dispatch({ type: 'SET_ATTACHMENT_FOLDER_NAME', payload: { index, name: groupName.trim() } });
      } else {
        setGroupName(group);
      }
    }
  }, [isEditing, groupName, group]);

  const disabledDeleteBtn = isCanceled || isEditing || isEditingFromContext;

  return (
    <div className={styles.folder}>
      <div className={styles.header}>
        <div className={isEditing ? `${styles.left} ${styles.flex1}` : styles.left}>
          {isEditing ? (
            <input
              type="text"
              value={groupName}
              className={styles.input}
              onChange={(e) => {
                setGroupName(e.currentTarget.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') setIsEditing(false);
              }}
              onBlur={() => setIsEditing(false)}
            />
          ) : (
            <>
              <div className={styles.title}>
                <Typography variant="body2" style={{ fontWeight: 500 }}>
                  {group}
                </Typography>
              </div>
              {!isCanceled && (
                <IconButton onClick={() => setIsEditing(true)}>
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
        </div>
        {!isCanceled && (
          <div className={styles.right}>
            <IconButton
              disabled={disabledDeleteBtn}
              onClick={() => {
                setShowPrompt(true);
              }}
            >
              <CloseIcon />
            </IconButton>
            <Dialog open={showPrompt} onClose={() => setShowPrompt(false)}>
              <DialogTitle>删除分组</DialogTitle>
              <DialogContent>
                <DialogContentText>确认删除该分组及其所有附件吗？该操作无法被撤销。</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowPrompt(false)}>取消</Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    dispatch({ type: 'DELETE_ATTACHMENT_FOLDER', payload: index });
                    setShowPrompt(false);
                  }}
                >
                  删除
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
      <div className={styles.body}>
        {files.length > 0
          // eslint-disable-next-line react/no-array-index-key
          && files.map((file, i) => <AttachmentItem key={i} item={file} itemIndex={i} folderIndex={index} />)}
        {files.length < 10 && (
          <div
            role="button"
            className={clsx({
              [styles.upload]: true,
              [styles.uploadDisableHover]: true,
              [styles.border_t]: files.length > 0,
              [styles.disabled]: isEditingFromContext,
            })}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                <div className={styles.size}>
                  <Typography variant="caption" color="textSecondary" component="p">
                    你可以添加附件或链接，附件大小不超过{FILE_SIZE_LIMIT}M<br />
                    支持PNG，JPEG，PDF，PPT，Word，Excel，TXT
                  </Typography>
                </div>
              </Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <AttachmentFileInput files={files} folderIndex={index} />
                </Box>
                <Box mr={2} />
                <Box>
                  <PopupState variant="popover" popupId="delete-comment-popup-popover">
                    {(popupState) => (
                      <>
                        <Button variant="outlined" disabled={isCanceled} {...bindTrigger(popupState)}>
                          添加链接
                        </Button>
                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <Box width={360} height={128} py={2} pl={3} pr={2}>
                            <TextField
                              placeholder="请输入 URL"
                              variant="outlined"
                              fullWidth
                              value={link}
                              onChange={onLinkChange}
                            />
                            <Box pt={2} textAlign="right">
                              <Button style={{ marginRight: 8 }} onClick={popupState.toggle}>
                                取消
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => {
                                  popupState.toggle();
                                  addApplicationAttachmentLink(e);
                                }}
                              >
                                添加
                              </Button>
                            </Box>
                          </Box>
                        </Popover>
                      </>
                    )}
                  </PopupState>
                </Box>
              </Box>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
}

AttachmentFolder.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};
export default AttachmentFolder;
