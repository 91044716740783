import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import styles from './CandidateResult.module.css';
import formatScore from './formatScore';

const colors = ['#4ea3fd', '#52cedc', '#c486fc', '#8b97fc', '#ff95ef', '#ffb581', '#fdd257', '#3fd6f5', '#a073ff'];

function ScoreLegend({ item, processIndex }) {
  const {
    status,
    type,
    name,
    scores: { weight, originScore, finalScore },
  } = item;

  let color = '#3bcb79'; // 默认为视频面试
  if (type !== 2) {
    color = colors[processIndex];
  }

  const title = `${type === 2 ? '视频面试' : name}得分`;

  if (status === 0) {
    return null;
  }

  if (status === 1) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '12px',
          color,
        }}
      >
        <div style={{ width: '8px', height: '8px', backgroundColor: color }} />
        <div style={{ margin: '0px 4px', fontSize: '12px' }}>
          {title}：<span style={{ color: 'rgba(0,0,0,0.26)' }}>候选人未作答</span>
        </div>
      </div>
    );
  }

  if (status === 2) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '12px',
          color,
        }}
      >
        <div style={{ width: '8px', height: '8px', backgroundColor: color }} />
        <div style={{ margin: '0px 4px', fontSize: '12px' }}>{title}：计算中...</div>
        <Tooltip title={<div>原分值计算中，权重{formatScore(weight)}%</div>} arrow>
          <InfoIcon style={{ fontSize: '14px' }} />
        </Tooltip>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
        color,
      }}
    >
      <div style={{ width: '8px', height: '8px', backgroundColor: color }} />
      <div
        style={{
          margin: '0px 4px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '12px',
        }}
      >
        <span>{title}：</span>
        <span>
          <span className={styles.scoreInChart}>{formatScore(finalScore)}</span>分
        </span>
        <span style={{ display: 'inline-block', margin: '0px 4px' }}>/</span>
        <span>
          <span>(满分{formatScore(weight)}分)</span>
        </span>
      </div>
      <Tooltip
        title={(
          <div>
            原分值{formatScore(originScore)}，原满分{formatScore(100)} ，权重{weight}% <br />
            如需手动调整，请在职位详情页面设置
          </div>
        )}
        arrow
      >
        <InfoIcon style={{ fontSize: '14px' }} />
      </Tooltip>
    </div>
  );
}

ScoreLegend.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  processIndex: PropTypes.number.isRequired,
};

export default ScoreLegend;
