import { getFormWithAnswer, submitFormWithScore } from '@api/Form';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useGlobalToast from '@utils/GlobalToast';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useCandidateDispatch } from '../context';
import styles from './CandidateFormWithAnswer.module.css';

function ItemHeaderWithScore({
  children, isNecessary = false, item = {}, formToken, isEnabledScore, canEdit,
}) {
  const { score, total, formItemToken } = item;
  const [inputScore, setInputScore] = useState(score);
  const dispatch = useCandidateDispatch();
  const GlobalToast = useGlobalToast();

  function handleChange(e) {
    const num = Math.floor(+e.target.value);
    if (num > Math.floor(total)) return;
    setInputScore(num);
  }

  function handleSave() {
    const data = {
      formUsageToken: formToken,
      formAnswers: [
        {
          token: formItemToken,
          score: Number(inputScore || 0),
        },
      ],
    };
    if (inputScore === '') setInputScore(0);
    submitFormWithScore(data).then((result) => {
      if (result.data.code === 0) {
        GlobalToast.success('评分成功');
        getFormWithAnswer(formToken).then((result2) => {
          if (result2.data.code === 0) {
            dispatch({
              type: 'SAVE_TO_APPLICATION_FORM_DATA',
              payload: {
                token: formToken,
                data: result2.data.data,
              },
            });
            dispatch({ type: 'REFRESH_DATA' });
          }
        });
      }
    });
  }

  const inputValue = inputScore === '' ? '' : Math.floor(+inputScore);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" className={styles.header}>
      <Box className={isNecessary ? styles.isNecessary : ''}>
        {children}
        {item.isHiddenForCandidate && (
          <Typography component="span" display="inline" variant="body2" color="textSecondary" style={{ marginLeft: 4, color: 'rgba(0,0,0,0.26)' }}>
            候选人不可见
          </Typography>
        )}
      </Box>
      {isEnabledScore && (
        <Box display="flex" alignItems="center" flexShrink={0}>
          <Box color="#6c6d6f">
            {canEdit ? (
              <input
                type="number"
                min={0}
                max={Math.floor(total)}
                step={1}
                value={inputValue}
                onChange={handleChange}
                onBlur={handleSave}
                className={styles.scoreInput}
              />
            ) : (
              Math.floor(score)
            )}
          </Box>
          <Box fontSize="12px" color="#6c6d6f">
            /{Math.floor(total)}分
          </Box>
        </Box>
      )}
    </Box>
  );
}

ItemHeaderWithScore.propTypes = {
  children: PropTypes.node.isRequired,
  isNecessary: PropTypes.bool.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  formToken: PropTypes.string,
  isEnabledScore: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool,
};

ItemHeaderWithScore.defaultProps = {
  formToken: '',
  canEdit: false,
};

export default ItemHeaderWithScore;
