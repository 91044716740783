import { getEvaluationList } from '@api/Evaluation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCandidateDispatch } from '../../context';
import { useEvaluationState } from '../context';

export default function useEffectReloadEvaluationList() {
  const candidateDispatch = useCandidateDispatch();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);

  const count = useEvaluationState((state) => state.count);

  useEffect(() => {
    if (count > 0 && applicationToken) {
      getEvaluationList(applicationToken).then((result) => {
        candidateDispatch({ type: 'SET_APPLICATION_EVALUATION_DATA', payload: result.data.data });
      });
    }
  }, [count, applicationToken]);
}
