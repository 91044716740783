import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import styles from './ResumeSectoinBox.module.css';

const ResumeSectoinBox = ({
  title, rightAction, children, mt,
}) => (
  <Box mt={mt}>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="body1" className={styles.title} color="textPrimary">
        {title}
      </Typography>
      {rightAction}
    </Box>
    {children}
  </Box>
);

ResumeSectoinBox.propTypes = {
  title: PropTypes.string.isRequired,
  rightAction: PropTypes.element,
  children: PropTypes.element,
  mt: PropTypes.number,
};

ResumeSectoinBox.defaultProps = {
  rightAction: null,
  children: null,
  mt: 3,
};

export default ResumeSectoinBox;
