import { isArray, isString } from 'lodash';

import { handleActions } from '../../../utils/redux-actions';
import {
  setIsFocused,
  inputClickAway,
  cancelSearch,
  addToChipList,
  changeInput,
  removeTailFromChipList,
  removeIndexFromChipList,
  leaveSearch,
  setSearchStat,
  setIsSearching,
  openCandidateModal,
} from './action';
import { MAX_LENGTH_CHIP_LIST } from '../../../components/GlobalSearch/GlobalSearch';

export const namespace = 'search';

export const defaultState = {
  inputValue: '',
  chipList: [],
  notMatchedChipList: [],
  isFocused: false,
  showList: false,
  showCancelIcon: false,
  isAdvancedMode: false,
  isCandidateModalOpen: false,
  selectedCandidateToken: '',
  searchStat: {},
  mobileSearchStat: {},
  nameSearchStat: {},
  isSearching: false,
};

export const reducer = handleActions(
  {
    [setIsFocused]: (draft, action) => {
      draft.isFocused = action.payload;
    },
    [inputClickAway]: (draft) => {
      draft.showList = false;
      draft.isFocused = false;

      const inputValue = draft.inputValue.trim();
      if (draft.isAdvancedMode && inputValue) {
        draft.chipList.push(inputValue);
        draft.inputValue = '';
        draft.showCancelIcon = true;
      }
    },
    [cancelSearch]: (draft) => {
      draft.inputValue = '';
      draft.chipList = [];
      draft.showList = false;
      draft.showCancelIcon = false;
    },
    [addToChipList]: (draft, action) => {
      const { payload } = action;
      if (isArray(payload)) {
        draft.chipList = [...draft.chipList, ...action.payload].filter((v) => v);
      }
      if (isString(payload)) {
        draft.chipList.push(payload);
      }
      draft.chipList.splice(MAX_LENGTH_CHIP_LIST);
      draft.inputValue = '';
      draft.showCancelIcon = true;
      draft.showList = false;
      draft.isAdvancedMode = true;
    },
    [changeInput]: (draft, action) => {
      const { payload = '' } = action;
      const inputValue = payload.trim();
      draft.inputValue = inputValue;
      draft.showCancelIcon = true;

      if (inputValue === '' && draft.chipList.length === 0) {
        draft.showCancelIcon = false;
      }

      if (draft.inputValue === '') {
        draft.showList = false;
      } else {
        draft.showList = true;
      }
    },
    [removeTailFromChipList]: (draft) => {
      draft.chipList.pop();

      if (draft.chipList.length === 0) {
        draft.showCancelIcon = false;
      }
    },
    [removeIndexFromChipList]: (draft, action) => {
      const { payload } = action;
      draft.chipList.splice(payload, 1);
    },
    [leaveSearch]: () => defaultState,
    [setSearchStat]: (draft, action) => {
      const { searchStat, mobileSearchStat, nameSearchStat } = action.payload;
      if (searchStat) draft.searchStat = searchStat;
      if (mobileSearchStat) draft.mobileSearchStat = mobileSearchStat;
      if (nameSearchStat) draft.nameSearchStat = nameSearchStat;
    },
    [setIsSearching]: (draft, action) => {
      draft.isSearching = action.payload;
    },
    [openCandidateModal]: (draft) => {
      if (!draft.isAdvancedMode) {
        draft.inputValue = '';
        draft.chipList = [];
        draft.showList = false;
        draft.showCancelIcon = false;
      }
    },
  },
  defaultState,
);
