import { createActions } from 'redux-actions';

export const {
  initRoleModule, setRoles, setShowRoleDrawer, setRolesPage,
} = createActions(
  'INIT_ROLE_MODULE',
  'SET_ROLES',
  'SET_SHOW_ROLE_DRAWER',
  'SET_ROLES_PAGE',
);

export default {};
