import Typography from '@components/Avocado/Typography';
import { Box, Stepper as MuiStepper, withStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useCandidateState } from '../context';
import Share from '../Share';
import OtherEntryAlert from './OtherEntryAlert';
import Panel from './Panel';
import ProcessStep from './ProcessStep';

const Stepper = withStyles({
  root: {
    padding: 0,
    '& > .MuiStepConnector-vertical': {
      padding: '0 0 4px',
      marginLeft: '11px',
    },
  },
})(MuiStepper);

function Header() {
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const { applicationState } = useCandidateState((state) => state.applicationData);
  const isCanceled = applicationState === 2;

  return (
    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center">
        <Typography variant="body2" color="text.primary">
          招聘流程
        </Typography>
        {isCanceled && (
          <Typography ml={1} variant="body2" color="text.disabled">
            (流程已取消)
          </Typography>
        )}
      </Box>
      <Box onClick={(e) => e.stopPropagation()}>
        <Share token={applicationToken} />
      </Box>
    </Box>
  );
}

function Body() {
  const applicationLogsData = useCandidateState((state) => state.applicationLogsData);
  const list = applicationLogsData.filter(({ stage }) => stage === 1);

  return (
    <Box width="100%">
      <OtherEntryAlert />
      <Stepper activeStep={-1} orientation="vertical">
        {list.map((step, index) => (
          <ProcessStep key={step.processToken} item={step} index={index} />
        ))}
      </Stepper>
    </Box>
  );
}
// 招聘流程
export default function ProcessPanel({ hasScrolled = false }) {
  const isStageEmpty = useCandidateState((state) => {
    const { applicationLogsData } = state;
    return isEmpty(applicationLogsData);
  });

  const shouldShowPositionPanel = useCandidateState((state) => {
    const {
      applicationData: { applicationState },
    } = state;
    return [4, 5, 7, 8, 9, 10].indexOf(applicationState) > -1;
  });

  if (isStageEmpty) return null;

  return (
    <Box>
      <Panel
        header={<Header />}
        body={<Body />}
        defaultExpanded={!shouldShowPositionPanel}
        disabled={!shouldShowPositionPanel}
        hasScrolled={hasScrolled}
      />
    </Box>
  );
}

ProcessPanel.propTypes = {
  hasScrolled: PropTypes.bool,
};

ProcessPanel.defaultProps = {
  hasScrolled: false,
};
