import { getAdminUsersAt } from '@api/Admin';
import { getEvaluationList } from '@api/Evaluation';
import { postInterviewEvaluationRemind } from '@api/Notification';
import { AddPersonIcon } from '@avocadoui/icons';
import {
  Avatar, Box, Chip, IconButton, TextField, Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { RANDOM_COLOR } from '@utils/constants';
import useGlobalToast from '@utils/GlobalToast';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useCandidateDispatch } from '../context';

const InviteInterviewer = ({
  applicationEvaluations, tplToken, applicationToken,
}) => {
  const dispatch = useCandidateDispatch();
  const [open, setOpen] = useState(false);
  const [interviewers, setInterviewers] = useState([]);
  const [value, setValue] = useState([]);
  const [error, setError] = useState(false);
  const GlobalToast = useGlobalToast();
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    getAdminUsersAt().then((result) => {
      setInterviewers(result.data?.data?.list);
    });
  }, []);

  const handleOnClose = () => {
    setOpen(false);
    setError(false);
    setValue([]);
  };

  const handleClose = () => {
    const data = {
      applicationTokens: [applicationToken],
      applicationEvaluationTpl: tplToken,
      notifyUsers: value.map((item) => item.userToken),
    };
    if (value.length > 0) {
      setError(false);
      setOpen(false);
      GlobalToast.success('通知成功');
    } else {
      setError(true);
      setOpen(true);
    }
    postInterviewEvaluationRemind(data).then((result) => {
      if (result.data.code === 0) {
        getEvaluationList(applicationToken).then((res) => {
          dispatch({ type: 'SET_APPLICATION_EVALUATION_DATA', payload: res.data.data });
        });
      }
    });
  };

  const handleChange = (_, e) => {
    setValue(e);
    if (e.length > 0) {
      setError(false);
    }
  };

  return (
    <Box>
      {
        applicationEvaluations.length > 0 && (
          <Box display="flex" justifyContent="center" alignItems="center"><IconButton onClick={handleClickOpen}><AddPersonIcon htmlColor="rgba(0, 0, 0, 0.56)" /></IconButton></Box>
        )
      }
      {
        applicationEvaluations.length === 0 && (

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              onClick={handleClickOpen}
              variant="outlined"
              startIcon={<AddPersonIcon htmlColor="rgba(0, 0, 0, 0.86)" />}
            ><Typography variant="body2">邀请</Typography>
            </Button>
          </Box>
        )
      }
      <Dialog
        open={open}
        onClose={handleOnClose}
      >
        <DialogTitle>通知填写</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Autocomplete
              fullWidth
              multiple
              onChange={handleChange}
              options={interviewers}
              value={value}
              style={{ width: 360 }}
              disableCloseOnSelect
              getOptionLabel={(option) => option.username}
              noOptionsText="暂无更多"
              renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <Chip label={option.username} {...getTagProps({ index })} variant="outlined" />)}
              renderOption={(option) => (
                <>
                  {option.userAvatar === '' ? (
                    <Avatar
                      style={{
                        width: '24px',
                        height: '24px',
                        margin: '4px 8px 4px 0px',
                        fontSize: '12px',
                        backgroundColor: RANDOM_COLOR[option.username.charCodeAt(0) % RANDOM_COLOR.length],
                      }}
                    >
                      {option.username.substr(option.username.length - 1)}
                    </Avatar>
                  ) : (
                    <Avatar
                      style={{
                        width: '24px',
                        height: '24px',
                        margin: '4px 8px 4px 0px',
                        fontSize: '12px',
                      }}
                      alt={option.username}
                      src={option.userAvatar}
                    />
                  )}
                  {option.username}
                </>
              )}
              renderInput={(params) => <TextField variant="outlined" fullWidth label="通知的面试官*" {...params} error={error} helperText={error ? '请选择面试官' : ''} />}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose} color="textSecondary">
            取消
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained">
            通知
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

InviteInterviewer.propTypes = {
  applicationEvaluations: PropTypes.bool.isRequired,
  tplToken: PropTypes.string.isRequired,
  selectedTokens: PropTypes.string.isRequired,
  applicationToken: PropTypes.string.isRequired,
};

export default InviteInterviewer;
