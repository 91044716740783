import { getRtmToken } from '@api/User';
import agora from '@config/agora';
import AgoraRTM from 'agora-rtm-sdk';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

const client = AgoraRTM.createInstance(agora.appid, { enableLogUpload: false });

const AgoraRtmProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  const [open, setOpen] = useState(false);

  const reloadData = useCallback((uid) => {
    getRtmToken({ rtmUserId: uid })
      .then((result) => {
        const {
          data: { rtmToken, rtmUserId },
          code,
        } = result.data;
        if (code === 0) {
          setUserInfo({
            token: rtmToken,
            uid: rtmUserId,
          });
        }
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      client
        .login(userInfo)
        .then(() => {
          console.log('im login success');
        })
        .catch(console.log);

      client.on('MessageFromPeer', async (message) => {
        if (message.messageType === 'TEXT') {
          const content = JSON.parse(message.text);
          if (content.type === 2 && content.content === 'logout') {
            client.logout();
            setOpen(true);
          }
        }
      });
      client.on('TokenExpired', async () => {
        reloadData(userInfo.rtmUserId);
      });
    }
  }, [userInfo, reloadData]);

  return <>{children(open)}</>;
};

AgoraRtmProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

AgoraRtmProvider.defaultProps = {
  children: null,
};

export default AgoraRtmProvider;
