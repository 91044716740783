import axios from './Api';

export const upload = (formData, callback, params = {}) => axios.request({
  url: '/common/upload',
  method: 'POST',
  params,
  data: formData,
  onUploadProgress: (progress) => {
    if (callback) {
      callback(progress);
    }
  },
});

export const fetchCommonWords = (data) => axios.request({
  url: '/commonWords',
  method: 'POST',
  data,
});
