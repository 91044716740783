import { getCandidate } from '@api/Candidate';
import CandidateModalSkeleton from '@module/CandidateModal/CandidateModalSkeleton';
import { useCandidateState } from '@module/CandidateModal/context';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {
  createContext, useEffect, useReducer, useState,
} from 'react';

const defaultExperience = {
  employStatus: 0,
  startTime: 0,
  endTime: 0,
  company: '',
  workContent: '',
  position: '',
};

const defaultLanguage = {
  name: '',
};

const defaultEducation = {
  startTime: 0,
  endTime: 0,
  school: '',
  major: '',
  schoolName: '',
  degree: 0,
};

const ResumeContext = createContext();
const ResumeDispatchContext = createContext();

const ResumeProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const {
    candidateData: { token: candidateToken = '' },
  } = useCandidateState();

  const [state, dispatch] = useReducer((reducer, action) => {
    switch (action.type) {
      case 'initial':
        return action.payload;
      case 'save':
        return { ...reducer, ...action.payload };
      case 'changeName':
        return { ...reducer, ...action.payload };
      case 'changeExperience':
        return { ...reducer, editCandidate: { ...reducer.editCandidate, experience: [...action.payload] } };
      case 'changeEducations':
        return { ...reducer, editCandidate: { ...reducer.editCandidate, educations: [...action.payload] } };
      case 'changeLanguages':
        return { ...reducer, editCandidate: { ...reducer.editCandidate, languages: [...action.payload] } };
      case 'changeCandidateIntention':
        return { ...reducer, editCandidate: { ...reducer.editCandidate, candidateIntention: { ...action.payload } } };
      case 'changeCandidateBasic':
        return { ...reducer, editCandidate: { ...reducer.editCandidate, candidateBasic: { ...action.payload } } };
      case 'updateUploadStatus':
        return { ...reducer, uploadStatus: action.payload };
      case 'updateResumeUrl': {
        return { ...reducer, resumeURL: action.payload };
      }
      case 'changeAttachResume': {
        return {
          ...reducer,
          editCandidate: { ...reducer.editCandidate, attachResume: { formItems: [...action.payload] } },
        };
      }
      default:
        return reducer;
    }
  }, {});

  useEffect(() => {
    setLoading(true);
    getCandidate(candidateToken)
      .then((v) => {
        if (v.data.code === 0) {
          const candidate = v.data.data;
          const educations = isEmpty(candidate.education) ? [{ ...defaultEducation }] : candidate.education;
          const experience = isEmpty(candidate.workExperience) ? [{ ...defaultExperience }] : candidate.workExperience;
          const languages = isEmpty(candidate.languageAbility) ? [{ ...defaultLanguage }] : candidate.languageAbility;
          const candidateIntention = candidate.candidateResume;
          const candidateBasic = {
            name: candidate.name,
            age: candidate.age,
            sex: candidate.sex,
            email: candidate.email,
            mobile: candidate.mobile,
            degree: candidate.degree,
          };
          const { attachResume } = candidate;
          dispatch({
            type: 'initial',
            payload: {
              resumeURL: '',
              candidate,
              editCandidate: {
                candidateBasic,
                candidateIntention,
                experience,
                educations,
                languages,
                attachResume,
              },
              uploadStatus: 0,
            },
          });
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <CandidateModalSkeleton showStepper={false} />;
  }

  return (
    <ResumeContext.Provider value={state}>
      <ResumeDispatchContext.Provider value={dispatch}>{children}</ResumeDispatchContext.Provider>
    </ResumeContext.Provider>
  );
};

ResumeProvider.propTypes = {
  children: PropTypes.element,
};

ResumeProvider.defaultProps = {
  children: null,
};

export {
  ResumeContext, ResumeProvider, ResumeDispatchContext, defaultExperience, defaultLanguage, defaultEducation,
};
