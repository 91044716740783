import ResumeStandardPreview from '@components/Resume/ResumeStandardPreview';
import { Box, withStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { parseInt } from 'lodash';
import { useState } from 'react';
import { useCandidateState } from '../context';
import AttachmentResume from './AttachmentResume';
import CandidateOriginalResume from './CandidateOriginalResume';

const ResumeToggleButton = withStyles(() => ({
  root: {
    color: 'rgba(0, 0, 0, 0.86)',
    borderRadius: 0,
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.86) !important',
    color: '#FFFFFF !important',
  },
  label: {},
  sizeSmall: {
    padding: '3px 16px',
  },
}))(ToggleButton);

const ResumeToggleGroup = withStyles(() => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.86)',
  },
}))(ToggleButtonGroup);

const CandidateResume = () => {
  const { candidateData = {} } = useCandidateState();
  const ResumeTab = parseInt(localStorage.getItem('ResumeTab'), 0);

  const [index, setIndex] = useState(!candidateData?.resumeFlag || Number.isNaN(ResumeTab) ? 1 : ResumeTab);
  const { attachResume = {} } = candidateData;
  const { formItems = [] } = attachResume;
  return (
    <>
      {(candidateData?.resumeFlag || formItems?.length > 0) && (
        <ResumeToggleGroup
          size="small"
          value={index}
          exclusive
          onChange={(_, value) => {
            if (value !== null) {
              localStorage.setItem('ResumeTab', value);
              setIndex(value);
            }
          }}
          aria-label="text alignment"
          style={{ marginLeft: 24, marginTop: 16, marginBottom: 16 }}
        >
          <ResumeToggleButton value={1} aria-label="left aligned">
            原始简历
          </ResumeToggleButton>
          <ResumeToggleButton value={2} aria-label="centered">
            标准简历
          </ResumeToggleButton>
        </ResumeToggleGroup>
      )}
      <CandidateOriginalResume hidden={index !== 1} />
      <Box pl={3} pr={3} style={{ backgroundColor: 'withe' }} hidden={index !== 2}>
        <ResumeStandardPreview>
          <AttachmentResume />
        </ResumeStandardPreview>
      </Box>
    </>
  );
};

export default CandidateResume;
