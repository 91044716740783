import echarts from '@avocadoui/echarts';
import { ResizeObserver } from '@juggle/resize-observer';

export default new ResizeObserver((entries) => {
  entries.forEach(({ target }) => {
    const instance = echarts.getInstanceByDom(target);
    if (instance) {
      instance.resize();
    }
  });
});
