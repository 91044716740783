import PropTypes from 'prop-types';
import styles from './CandidateResult.module.css';
import formatScore from './formatScore';

function ScoreBigBall({ score, status }) {
  return (
    <>
      <div
        className={styles.scoreBigBall}
        style={{ backgroundColor: status === 1 ? '#fbfbfb' : 'rgba(59, 203, 120, 0.1)' }}
      >
        {status !== 1 && (
          <svg
            className={styles.waves}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className={styles.parallax}>
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(59, 203, 120, 0.2)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(59, 203, 120, 0.2)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(59, 203, 120, 0.2)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(59, 203, 120, 0.2)" />
            </g>
          </svg>
        )}
        {status === 1 && (
          <div style={{ color: 'rgba(0,0,0,0.26)', fontSize: '12px' }}>
            <span>候选人未作答</span>
          </div>
        )}
        {status === 2 && (
          <div style={{ color: '#40be47' }}>
            <span>计算中...</span>
          </div>
        )}
        {status === 3 && (
          <div style={{ color: '#40be47' }}>
            <span className={styles.score}>{formatScore(score)}</span>
            <span style={{ marginLeft: '2px', fontSize: '12px' }}>分</span>
          </div>
        )}
      </div>
      {status === 2 && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
      )}
    </>
  );
}

ScoreBigBall.propTypes = {
  score: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
};

export default ScoreBigBall;
