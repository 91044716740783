import { ShareIcon } from '@avocadoui/icons';
import API_URL from '@config/conf';
import { Box, IconButton, Popover } from '@material-ui/core';
import useGlobalToast from '@utils/GlobalToast';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import AddCollaborator from './AddCollaborator';
import CreateCollaborator from './CreateCollaborator';
import ManagementCollaborator from './ManagementCollaborator';
import ShareIndex from './ShareIndex';
import ShareToWeChat from './ShareToWeChat';

const Index = ({ token }) => {
  const GlobalToast = useGlobalToast();

  const [collaborators, setCollaborators] = useState([]);
  const [showWechatQRcode, setShowWechatQRcode] = useState(false);
  const [showCreateCollaborator, setShowCreateCollaborator] = useState(false);
  const [showManagementCollaborator, setShowManagementCollaborator] = useState(false);
  const [showAddCollaborator, setShowAddCollaborator] = useState(false);

  const handleShowAddCollaborator = useCallback(() => {
    setShowAddCollaborator(true);
  }, []);

  const handleCollaboratorsClear = useCallback(() => {
    setShowAddCollaborator(false);
    setCollaborators([]);
  }, []);

  const handleCollaviratorSelected = useCallback((_, val) => {
    setCollaborators(val);
  }, []);

  const handleCopyLink = useCallback(() => {
    const obj = document.getElementById('shoreUrl');
    obj.select();
    document.execCommand('Copy');
    GlobalToast.success('链接已复制');
  }, []);

  const handleConfirmCreateCollaborator = useCallback(
    (collaborator) => {
      setCollaborators([...collaborators, collaborator]);
      setShowCreateCollaborator(false);
    },
    [collaborators],
  );

  const handleShowCreateCollaborator = useCallback(() => {
    setShowCreateCollaborator(true);
  }, []);

  const handleHiddenCreateCollaborator = useCallback(() => {
    setShowCreateCollaborator(false);
  }, []);

  const handleHiddenManagementCollaborator = useCallback(() => {
    setShowManagementCollaborator(false);
  }, []);

  const handleShowWechatQRcode = useCallback(() => {
    setShowWechatQRcode(true);
  }, []);

  const handleHiddenWechatQRcode = useCallback(() => {
    setShowWechatQRcode(false);
  }, []);

  const handleCollaviratorManagementClick = useCallback(() => {
    setShowManagementCollaborator(true);
  }, []);

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          <IconButton {...bindTrigger(popupState)}>
            <ShareIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {showWechatQRcode && <ShareToWeChat token={token} onBackClick={handleHiddenWechatQRcode} />}
            {showManagementCollaborator && !showCreateCollaborator && !showAddCollaborator && (
              <ManagementCollaborator
                token={token}
                onBackClick={handleHiddenManagementCollaborator}
                onCreateClick={handleShowAddCollaborator}
              />
            )}
            {(collaborators.length > 0 || showAddCollaborator) && !showCreateCollaborator && (
              <AddCollaborator
                onCreateClick={handleShowCreateCollaborator}
                token={token}
                value={collaborators}
                onBackClick={handleCollaboratorsClear}
                onChange={handleCollaviratorSelected}
              />
            )}
            {!showWechatQRcode
              && !showCreateCollaborator
              && !showManagementCollaborator
              && collaborators.length === 0 && (
              <ShareIndex
                token={token}
                onCreateClick={handleShowCreateCollaborator}
                onCopyLinkClick={handleCopyLink}
                onWechatShareClick={handleShowWechatQRcode}
                onCollaviratorSelected={handleCollaviratorSelected}
                onCollaviratorManagementClick={handleCollaviratorManagementClick}
              />
            )}
            {showCreateCollaborator && (
              <CreateCollaborator
                token={token}
                onBackClick={handleHiddenCreateCollaborator}
                onConfirmClick={handleConfirmCreateCollaborator}
              />
            )}

            <Box style={{ zIndex: -999, position: 'fixed', top: -10000 }}>
              <input type="text" id="shoreUrl" readOnly value={`${API_URL}share/application/${token}?type=1`} />
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

Index.propTypes = {
  token: PropTypes.string,
};

Index.defaultProps = {
  token: 'nMlpBzS4VWDp3isDleX2QPDS34',
};

export default Index;
