import * as ACTIONS from '../constants/ActionTypes';

const initialState = {
  data: {
    total: '',
    todayIncrease: '',
    weekIncrease: '',
  },
};

export default function candidatesStatistical(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_CANDIDATES_STATISTICAL:
      return {
        data: action.data,
      };
    default:
      return state;
  }
}
