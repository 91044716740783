import axios from './Api';

export const getExams = (token) => axios.request({
  url: `/applicationProcess/${token}/exams`,
  method: 'GET',
});

export const getExamPaper = (page, pageSize) => axios.request({
  url: '/exam/papers',
  method: 'GET',
  params: { page, pageSize },
});

export const createExam = (data) => axios.request({
  url: '/vc1/exam',
  method: 'POST',
  data,
});

export const editExam = (token, data) => axios.request({
  url: `/vc1/exam/update/${token}`,
  method: 'POST',
  data,
});

export const deleteExam = (tokens) => axios.request({
  url: '/vc1/exam/delete',
  method: 'POST',
  data: { tokens },
});

export const addBatchExams = (data) => axios.request({
  url: '/exam/batch',
  method: 'POST',
  data,
});

export default {};
