import { createActions } from 'redux-actions';

export const {
  setLogin, setUserSelectedDepartment, setLoginOut, fetchUserInfo, fetchPermissionRoute,
} = createActions(
  {
    SET_LOGIN: (info) => ({ info }),
    SET_USER_SELECTED_DEPARTMENT: (departments) => departments,
    FETCH_PERMISSION_ROUTE: (permissionId) => ({ permissionId }),
  },
  'FETCH_USER_INFO',
  'SET_LOGIN_OUT',
);

export default {};
