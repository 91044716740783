import moment from '@utils/moment';
import { useSelector } from 'react-redux';
import { useCandidateState } from '../context';
import styles from './CandidateInfo.module.css';

function CandidateApplicationWithoutSelector() {
  const { candidateApplicationData } = useCandidateState();
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);

  if (candidateApplicationData && candidateApplicationData.flows && candidateApplicationData.flows.length > 0) {
    const flow = candidateApplicationData.flows.find((v) => v.applicationToken === applicationToken) || {};
    return (
      <div className={styles.selectorButton}>
        <div className={styles.positionName}>
          <div className={styles.text}>{flow.positionName}</div>
          {flow.startTime && (
            <div className={styles.startTime}>{moment(parseInt(flow.startTime * 1000, 10)).format('YYYY.MM.DD')}</div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles.selectorButton}
      style={{ backgroundColor: 'rgba(0,0,0,0.04)', color: 'rgba(0,0,0,0.26)', border: '1px solid rgba(0,0,0,0.04)' }}
    >
      <div className={styles.positionName}>
        <div>未分配</div>
      </div>
    </div>
  );
}

export default CandidateApplicationWithoutSelector;
