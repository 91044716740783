import { editStepAuditStatus } from '@api/Application';
import { Box, makeStyles, Typography } from '@material-ui/core';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useCandidateDispatch, useCandidateState } from './context';
import styles from './QnAItem.module.css';
import QnAItemVideo from './QnAItemVideo';
import useDataLoader from './useDataLoader';

const useStyles = makeStyles({
  graySpan: {
    position: 'relative',
    cursor: 'pointer',
    borderBottom: 'solid 1px transparent',
    '&:hover': {
      '&:before': {
        width: '100%',
        height: '100%',
        left: 0,
        right: 0,
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 0,
      background: 'transparent',
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',
      transition: 'all .3s',
    },
  },
  redSpan: {
    position: 'relative',
    cursor: 'pointer',
    borderBottom: 'solid 1px #ff7f6199',
    '&:hover': {
      '&:before': {
        width: '100%',
        height: '100%',
        left: 0,
        right: 0,
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 0,
      background: '#ff7f6199',
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',
      transition: 'all .3s',
    },
  },
  greenSpan: {
    position: 'relative',
    cursor: 'pointer',
    borderBottom: 'solid 1px #4caf5099',
    '&:hover': {
      '&:before': {
        width: '100%',
        height: '100%',
        left: 0,
        right: 0,
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 0,
      background: '#4caf5099',
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',
      transition: 'all .3s',
    },
  },
});

const LanguageAnalysis = ({ item, onClick, isActive }) => {
  const classes = useStyles();

  const margin = useCallback(() => {
    if (item.language === 1) return 1;
    if (item.language === 3) return 3;
    return 0;
  }, [item]);

  return (
    <>
      {item.analysisResult?.words?.map((word, index) => {
        if (word.word === '*') {
          return <></>;
        }
        return (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index + word.word}
            variant="caption"
            style={{ marginRight: margin() }}
            className={clsx({
              [classes.greenSpan]: word.pronAccuracy > 70 && isActive,
              [classes.redSpan]: word.pronAccuracy < 70 && word.pronAccuracy > 0 && isActive,
              [classes.graySpan]: !isActive,
            })}
            onClick={() => {
              onClick(word);
            }}
          >
            {word.word}
          </Typography>
        );
      })}
    </>
  );
};

LanguageAnalysis.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};
LanguageAnalysis.defaultProps = {
  item: {},
  onClick: null,
  isActive: false,
};

function renderAlaphabet(item) {
  return (
    <div>
      <div>正确答案：{item.analysisResult.letter}</div>
      <div>回答：{item.analysisResult.answer}</div>
    </div>
  );
}

function renderColorBlind(item) {
  return (
    <div>
      <img src={item.analysisResult.img} style={{ width: '100px' }} alt="回答" />
      <span>回答：{item.analysisResult.answer || '未作答'}</span>
    </div>
  );
}

function renderMediaText(item, isActive) {
  if (item.media && item.media.text) {
    if (
      (item.type === 5 && item.evaluateType === 1 && item.language === 1)
      || (item.type === 5 && item.evaluateType === 1 && item.language === 3)
      || (item.type === 1 && item.aiShowType === 2 && (item.language === 3 || item.language === 1))
    ) {
      return (
        <LanguageAnalysis
          item={item}
          isActive={isActive}
          onClick={(word) => {
            document.getElementsByTagName('video').item(0).currentTime = word.memBeginTime / 1000;
            document.getElementsByTagName('video')[0].play();
          }}
        />
      );
    }
    return <span>{item.media.text}</span>;
  }
  return null;
}

function renderAnswer(item, isActive) {
  switch (item.type) {
    case 4:
      return renderAlaphabet(item);
    case 2:
      return renderColorBlind(item);
    case 1:
    case 3:
    case 5:
    default:
      return renderMediaText(item, isActive);
  }
}

function ToggleButton({ item }) {
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const { loadApplication } = useDataLoader();
  const { applicationFlowData = {} } = useCandidateState();
  const flowToken = applicationFlowData.token;
  const { stepToken } = item;

  function handleAuditStatus(isGood = 1) {
    const data = { auditStatus: isGood };
    editStepAuditStatus(flowToken, stepToken, data).then((result) => {
      if (result.data.code === 0) {
        loadApplication(applicationToken);
      }
    });
  }

  if (item.auditStatus === 1) {
    return (
      <div role="button" className={styles.goodStatus} onClick={() => handleAuditStatus(2)}>
        <div className={styles.label}>符合</div>
        <div className={styles.icon}>
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <circle fill="#FFF" cx="10" cy="10" r="10" />
              <path
                d="M12.705 11c.152 0 .283.131.268.284A2.998 2.998 0 019.987 14c-1.07 0-2.844-1.192-2.986-2.716-.015-.153.116-.284.268-.284zM7.5 7a1 1 0 110 2 1 1 0 010-2zm5 0a1 1 0 110 2 1 1 0 010-2z"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
      </div>
    );
  }

  if (item.auditStatus === 2) {
    return (
      <div role="button" className={styles.badStatus} onClick={() => handleAuditStatus(1)}>
        <div className={styles.icon}>
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <circle fill="#FFF" cx="10" cy="10" r="10" />
              <path
                d="M10 10.5c1.505 0 2.827.959 3.31 2.36a.5.5 0 11-.946.325 2.501 2.501 0 00-4.722-.018.5.5 0 01-.943-.333A3.501 3.501 0 0110 10.5zM7.5 7a1 1 0 110 2 1 1 0 010-2zm5 0a1 1 0 110 2 1 1 0 010-2z"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
        <div className={styles.label}>不符</div>
      </div>
    );
  }

  return (
    <div role="button" className={styles.badStatus} onClick={() => handleAuditStatus(1)}>
      <div className={styles.icon}>
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <circle fill="#FFF" cx="10" cy="10" r="10" />
          </g>
        </svg>
      </div>
      <div className={styles.label}>评价</div>
    </div>
  );
}

ToggleButton.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

function formatScore(num) {
  return Math.round(num * 100) / 100;
}

function renderBasedOnType(item) {
  if (item.scoreStatus === 1) {
    return <div className={styles.gameScore}>AI评分：计算中</div>;
  }
  if (item.scoreStatus === 2 && item.score !== -1) {
    return <div className={styles.gameScore}>AI评分：{formatScore(item.score)}</div>;
  }
  return <div />;
}

function QnAItem({ item, index }) {
  const { applicationFlowStepIndex } = useCandidateState();
  const dispatch = useCandidateDispatch();

  const isActive = index === applicationFlowStepIndex;
  const isAIGame = [7, 8, 9].indexOf(item.type) > -1;

  if (item.status === 9 && item.type !== 2) {
    return (
      <div className={`${styles.wrapper} ${styles.disabled}`}>
        <div className={styles.question}>
          <div>
            {index + 1}. {item.desc || item.name}
          </div>
        </div>
        <div className={styles.answer}>该题无面试视频</div>
      </div>
    );
  }

  if (item.status === 5) {
    return (
      <div className={`${styles.wrapper} ${styles.disabled}`}>
        <div className={styles.question}>
          <div>
            {index + 1}. {item.desc || item.name}
          </div>
        </div>
        <div className={styles.answer}>
          <div>答题中断超过十次，防作弊系统判定此题无效</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderTop: '1px dotted rgba(0,0,0,0.08)',
              padding: '8px',
            }}
          >
            {renderBasedOnType(item)}
            <div style={{ flexShrink: 0 }}>
              <ToggleButton item={item} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if ((item.status === 3 || (item.media && item.media.sourceUrl === '')) && item.type !== 2) {
    return (
      <div className={`${styles.wrapper} ${styles.disabled}`}>
        <div className={styles.question}>
          <div>
            {index + 1}. {item.desc || item.name}
          </div>
        </div>
        <div className={styles.answer}>视频处理中，请稍候</div>
      </div>
    );
  }

  return (
    <Box display="flex" ml={1.5}>
      <Box mt={2}>
        <QnAItemVideo src={item.media && item.media.sourceUrl} />
      </Box>
      <Box
        role="button"
        flexGrow={1}
        className={styles.wrapper}
        style={(item.media && item.media.sourceUrl === '') ? { cursor: 'unset' } : {}}
        onClick={() => ((item.media && item.media.sourceUrl === '') ? '' : dispatch({ type: 'SET_APPLICATION_FLOW_STEP_INDEX', payload: index }))}
      >
        <div className={styles.question}>
          <div>
            {index + 1}. {item.desc || item.name}
          </div>
        </div>
        {!isAIGame && (
          <div className={styles.answer}>
            <FormatQuoteIcon className={styles.quoteIcon} />
            <div
              style={{
                padding: '8px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {renderAnswer(item, isActive)}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: '1px dotted rgba(0,0,0,0.08)',
                padding: '8px',
              }}
            >
              {renderBasedOnType(item)}
              <div style={{ flexShrink: 0 }}>
                <ToggleButton item={item} />
              </div>
            </div>
          </div>
        )}
        {isAIGame && (
          <div className={styles.answer}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: '1px dotted rgba(0,0,0,0.08)',
                padding: '8px',
              }}
            >
              {renderBasedOnType(item)}
              <div style={{ flexShrink: 0 }}>
                <ToggleButton item={item} />
              </div>
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
}
QnAItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number,
};
QnAItem.defaultProps = {
  index: 0,
};

export default memo(QnAItem);
