import * as ACTIONS from '../constants/ActionTypes';

// const initialState = {
//   common: [{
//     id: 0,
//     token: '',
//     name: '',
//     duration: 0,
//   }],
//   custom: [{
//     id: 0,
//     token: '',
//     name: '',
//     duration: 0,
//   }],
// };

export default function patterns(state = {}, action) {
  switch (action.type) {
    case ACTIONS.GET_PATTERNS_COMMON:
      return {
        common: [...(state.common),
          {
            id: state.common.reduce((maxId, process) => Math.max(process.id, maxId), -1) + 1,
            token: action.token,
            name: action.name,
            duration: action.duration,
          }],
        custom: [...(state.custom)],
      };
    case ACTIONS.GET_PATTERNS_CUSTOM:
      return {
        common: [...(state.common)],
        custom: [...(state.custom),
          {
            id: state.common.reduce((maxId, process) => Math.max(process.id, maxId), -1) + 1,
            token: action.token,
            name: action.name,
            duration: action.duration,
          }],
      };
    case ACTIONS.DELETE_PATTERN:
      return {
        common: [...(state.common)],
        custom: [state.custom.filter((cus) => cus.token !== action.token)],
      };
    default:
      return state;
  }
}
