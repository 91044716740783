import PropTypes from 'prop-types';
import { memo } from 'react';
import { useCandidateState } from '../context';
import CandidateStepsSkeleton from './CandidateStepsSkeleton';
import PositionPanel from './PositionPanel';
import ProcessPanel from './ProcessPanel';

function CandidateSidebar({ hasScrolled = false }) {
  const isTabsLoading = useCandidateState((state) => state.isTabsLoading);
  if (isTabsLoading) {
    return <CandidateStepsSkeleton />;
  }

  return (
    <>
      <ProcessPanel hasScrolled={hasScrolled} />
      <PositionPanel />
    </>
  );
}

CandidateSidebar.propTypes = {
  hasScrolled: PropTypes.bool,
};

CandidateSidebar.defaultProps = {
  hasScrolled: false,
};

export default memo(CandidateSidebar);
