import * as API from '@api/Common';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { ReactComponent as ImageIcon } from '../../assets/img/icons/notify/email/Image.svg';

const ELEditorToolbarImage = (props) => {
  const input = useRef();
  const { confirmMedia } = props;

  const handleIconClick = () => {
    if (input.current) {
      input.current.click();
    }
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleInputChange = async () => {
    const files = input.current.files || [];
    // console.log(files);
    if (files.length > 0) {
      const file = files[0];
      const formData = new FormData();
      formData.append('files', file);
      API.upload(formData, () => {})
        .then((v) => {
          console.log(v.data);
          if (v.data.code === 0) {
            console.log(v.data);
            confirmMedia(v.data.data[0].url);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <>
      <IconButton onClick={handleIconClick}>
        <ImageIcon className="MuiSvgIcon-root" />
      </IconButton>
      <input
        hidden
        ref={input}
        type="file"
        accept="image/*"
        onClick={handleInputClick}
        onChange={handleInputChange}
        multiple={false}
      />
    </>
  );
};

ELEditorToolbarImage.propTypes = {
  confirmMedia: PropTypes.func,
};

ELEditorToolbarImage.defaultProps = {
  confirmMedia: null,
};

export default ELEditorToolbarImage;
