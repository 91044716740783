import { createActions } from 'redux-actions';

export const {
  setUserSelectedDepartments,
  setShowDepartmentDialog,
  setShowDepartmentConfirmDialog,
  setShowDepartmentToDialog,
  setShowDepartmentSwitchToDialog,
  setTemplateDateNotRemind,
  setProcessManage,
} = createActions(
  'SET_USER_SELECTED_DEPARTMENTS',
  'SET_SHOW_DEPARTMENT_DIALOG',
  'SET_SHOW_DEPARTMENT_CONFIRM_DIALOG',
  'SET_SHOW_DEPARTMENT_TO_DIALOG',
  'SET_SHOW_DEPARTMENT_SWITCH_TO_DIALOG',
  'SET_TEMPLATE_DATE_NOT_REMIND',
  'SET_PROCESS_MANAGE',
);

export default {};
