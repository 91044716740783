import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styles from './CandidateStepsSkeleton.module.css';

const CandidateStepsSkeleton = () => (
  <Box className={styles.contentRight}>
    <Box className={styles.contentRightHead} display="flex" alignItems="center" height={48}>
      <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
    </Box>
    <Box className={styles.contentRightDetail} display="flex">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box mb={1}>
          <Skeleton
            className={styles.resetTransform}
            variant="circle"
            width={16}
            height={16}
            animation="wave"
          />
        </Box>
        <Box mb={1}>
          <Skeleton className={styles.resetTransform} width={2} height={24} animation="wave" />
        </Box>
        <Box mb={1}>
          <Skeleton
            className={styles.resetTransform}
            variant="circle"
            width={16}
            height={16}
            animation="wave"
          />
        </Box>
        <Box mb={1}>
          <Skeleton className={styles.resetTransform} width={2} height={24} animation="wave" />
        </Box>
        <Skeleton
          className={styles.resetTransform}
          variant="circle"
          width={16}
          height={16}
          animation="wave"
        />
      </Box>
      <Box width="100%" ml={1}>
        <Box mb="34px">
          <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
        </Box>
        <Box mb="34px">
          <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
        </Box>
        <Box>
          <Skeleton className={styles.resetTransform} width={81} height={22} animation="wave" />
        </Box>
      </Box>
    </Box>
  </Box>
);

export default CandidateStepsSkeleton;
