import {
  Box, InputBase, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AtomicBlockUtils, EditorState, RichUtils } from 'draft-js';
import { setBlockData, toggleCustomInlineStyle } from 'draftjs-utils';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import getLinkDecorator from './decorators/Link';
import ELEditorAttachment from './ELEditorAttachment';
import ELEditorToolbar, { customStyleMap } from './ELEditorToolbar';
import MediaRenderer from './Media';

const ELEditor = (props) => {
  const {
    onSubjectChange,
    subject,
    onNeedFeedbackChange,
    needFeedback,
    attachments,
    onAttachmentsChange,
    editorState,
    setEditorState,
    needFeedbackHidden,
    customStyleMap: customStyleMapGreen,
  } = props;

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const toggleColor = (toggledColor) => {
    const nextEditorState = toggleCustomInlineStyle(editorState, 'color', toggledColor);
    setEditorState(nextEditorState);
  };
  const togglebackgroundColor = (toggledColor) => {
    const nextEditorState = toggleCustomInlineStyle(editorState, 'bgcolor', toggledColor);
    setEditorState(nextEditorState);
  };

  const confirmLink = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'IMMUTABLE', { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
  };

  const toggleFontSize = (toggledSize) => {
    const nextEditorState = toggleCustomInlineStyle(editorState, 'fontSize', toggledSize);
    setEditorState(nextEditorState);
  };

  const confirmMedia = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', {
      src: url,
      width: 'auto',
      height: 'auto',
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
  };

  return (
    <Box border="1px solid rgba(0, 0, 0, 0.08)" width="100%" display="flex" flexDirection="column">
      <Box ml={1.5} height="49px">
        <InputBase
          placeholder="请添加邮件主题(必填）"
          fullWidth
          style={{ height: 48 }}
          value={subject}
          onChange={(e) => {
            onSubjectChange(e.target.value.trim());
          }}
          inputProps={{ maxLength: 50 }}
          endAdornment={<Typography style={{ marginRight: 16 }}>{subject.length}/50</Typography>}
        />
        <Divider />
      </Box>
      <ELEditorToolbar
        editorState={editorState}
        toggleColor={toggleColor}
        togglebackgroundColor={togglebackgroundColor}
        toggleBlockType={toggleBlockType}
        toggleFontSize={toggleFontSize}
        toggleInlineStyle={toggleInlineStyle}
        confirmMedia={confirmMedia}
        confirmLink={confirmLink}
        onAlignment={(align) => {
          setEditorState(setBlockData(editorState, { 'text-align': align }));
        }}
      />
      <Box overflow="scroll" height="400px" flexGrow={1} flexShrink={0} display="flex">
        <Box height="100%" width="100%" ml={1.5} mr={1.5}>
          <Editor
            customDecorators={[getLinkDecorator({})]}
            stripPastedStyles
            toolbarHidden
            blockRendererFn={MediaRenderer}
            editorState={editorState}
            customStyleMap={customStyleMapGreen || customStyleMap}
            onEditorStateChange={(state) => {
              setEditorState(state);
            }}
            localization={{
              locale: 'zh',
            }}
          />
        </Box>
      </Box>
      <ELEditorAttachment
        attachments={attachments}
        onAttachmentsChange={(atts) => {
          onAttachmentsChange(atts);
        }}
      />
      {!needFeedbackHidden && (
        <>
          <Divider />
          <Box height="52px" display="flex" alignItems="center">
            <Typography variant="body2" style={{ marginLeft: 12, marginRight: 60 }}>
              是否需要收件人确认反馈
            </Typography>
            <RadioGroup
              style={{ flexDirection: 'row' }}
              aria-label="gender"
              name="needFeedback"
              value={needFeedback}
              onChange={(_, v) => {
                onNeedFeedbackChange(parseInt(v, 10));
              }}
            >
              <FormControlLabel value={1} control={<Radio color="primary" />} label="需要" />
              <FormControlLabel value={2} control={<Radio color="primary" />} label="不需要" />
            </RadioGroup>
          </Box>
        </>
      )}
    </Box>
  );
};

ELEditor.propTypes = {
  onSubjectChange: PropTypes.func,
  needFeedbackHidden: PropTypes.bool,
  subject: PropTypes.string,
  onNeedFeedbackChange: PropTypes.func,
  needFeedback: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.any),
  onAttachmentsChange: PropTypes.func,
  editorState: PropTypes.objectOf(PropTypes.any),
  setEditorState: PropTypes.func,
  customStyleMap: PropTypes.objectOf(PropTypes.any),
};

ELEditor.defaultProps = {
  onSubjectChange: () => {},
  subject: '',
  needFeedbackHidden: false,
  onNeedFeedbackChange: () => {},
  needFeedback: '',
  attachments: [],
  onAttachmentsChange: () => {},
  editorState: {},
  setEditorState: () => {},
  customStyleMap: {},
};

export default ELEditor;
