import { Box, MenuItem, TextField } from '@material-ui/core';
import moment from '@utils/moment';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import styles from './ResumeAttachEdit.module.css';
import { ResumeContext, ResumeDispatchContext } from './ResumeContext';
import ResumeDatePicker from './ResumeDatePicker';

function QnAItem({ item, onChange }) {
  const { formTypeKey, formAnswer: { answer = '' } } = item;
  return <TextField data-type={2} name={item.formItemToken} variant="outlined" size="small" fullWidth label={formTypeKey} value={answer || ''} onChange={onChange} />;
}

QnAItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

function SelectItem({ item, onChange }) {
  const { formTypeKey, formItemOption = {}, formAnswer: { choiceOptions = [] } } = item;
  const { alternatives } = formItemOption;
  return (
    <TextField name={item.formItemToken} variant="outlined" size="small" fullWidth select label={formTypeKey} placeholder={formTypeKey} value={choiceOptions.toString()} onChange={onChange}>
      {alternatives.map((option) => (
        <MenuItem key={option.key} value={option.key}>
          {option.value}
        </MenuItem>
      ))}
    </TextField>
  );
}

SelectItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

function TimeItem({ item, onChange }) {
  const { formTypeKey, formAnswer: { dateTime = 0 } } = item;
  return (
    <ResumeDatePicker name={item.formItemToken} value={isEmpty(dateTime) ? null : moment(dateTime * 1000).format('YYYY-MM-DD')} label={formTypeKey} onChange={onChange} />
  );
}

TimeItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

function ItemSwitcher({ item, onChange }) {
  const { formTypeId } = item;

  switch (formTypeId) {
    case 2: {
      return <QnAItem item={item} onChange={onChange} />;
    }
    case 3: {
      return <SelectItem item={item} onChange={onChange} />;
    }
    case 4: {
      return <TimeItem item={item} onChange={onChange} />;
    }
    default: {
      return <QnAItem item={item} onChange={onChange} />;
    }
  }
}

ItemSwitcher.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

function ResumeAttachEdit() {
  const { editCandidate = {} } = useContext(ResumeContext);
  const dispatch = useContext(ResumeDispatchContext);
  if (isEmpty(editCandidate?.attachResume?.formItems)) {
    return null;
  }
  return (
    <Box>
      <div className={styles.attachmentResume}>
        <div className={styles.head}>附加简历</div>
        <div className={styles.body}>
          {editCandidate?.attachResume?.formItems?.map((item) => (
            <div key={item.formItemToken} className={styles.bodyHalf}>
              <ItemSwitcher
                item={item}
                onChange={(e) => {
                  const { name, value } = e.target;
                  dispatch({
                    type: 'changeAttachResume',
                    payload: [...editCandidate.attachResume.formItems.map((v) => {
                      if (v.formItemToken === name) {
                        if (v.formTypeId === 2) {
                          return {
                            ...v,
                            formAnswer: {
                              answer: value,
                            },
                          };
                        }
                        if (v.formTypeId === 3) {
                          return {
                            ...v,
                            formAnswer: {
                              choiceOptions: [value],
                            },
                          };
                        }
                        if (v.formTypeId === 4) {
                          return {
                            ...v,
                            formAnswer: {
                              dateTime: Number((value.getTime() / 1000)).toFixed(0),
                            },
                          };
                        }
                      }
                      return v;
                    })],
                  });
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
}

export default ResumeAttachEdit;
