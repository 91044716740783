import TemplateFill from '@components/Notification/TemplateFill';
import CodeInterviewCreate from '@components/ShowMeBug/CodeInterviewCreate';
import WrittenCreate from '@components/ShowMeBug/WrittenCreate';
import PropTypes from 'prop-types';

const ActionDialogsOnly = ({
  isShowCreateDialog,
  isShowCreateCodeInterViewDialog,
  templateFillOpen,
  templateLoading,
  emailTemplate,
  template,
  templateRich,
  variables,
  templateSms,
  richSms,
  variablesSms,
  templateEmail,
  richEmail,
  variablesEmail,
  emailSubject,
  mailToAddress,
  candidateTokens,
  auditStatus,
  needFeedbackHidden,
  setShowCreateDialog,
  setShowCreateCodeInterViewDialog,
  createWritten,
  createCodeInterViews,
  closeTemplateFill,
  confirmNotifyMsg,
}) => (
  <>
    {isShowCreateDialog && (
      <WrittenCreate
        open={isShowCreateDialog}
        onCancel={() => {
          setShowCreateDialog(false);
        }}
        onConfirm={(data) => {
          createWritten(data);
          setShowCreateDialog(false);
        }}
      />
    )}
    {isShowCreateCodeInterViewDialog && (
      <CodeInterviewCreate
        open={isShowCreateCodeInterViewDialog}
        onCancel={() => {
          setShowCreateCodeInterViewDialog(false);
        }}
        onConfirm={(data) => {
          createCodeInterViews(data);
          setShowCreateCodeInterViewDialog(false);
        }}
      />
    )}
    {templateFillOpen && (
      <TemplateFill
        open
        templateLoading={templateLoading}
        emailTemplate={emailTemplate}
        template={template}
        templateRich={templateRich}
        variables={variables}
        templateSms={templateSms}
        richSms={richSms}
        variablesSms={variablesSms}
        templateEmail={templateEmail}
        richEmail={richEmail}
        variablesEmail={variablesEmail}
        emailSubject={emailSubject}
        mailToAddress={mailToAddress}
        candidateTokens={candidateTokens}
        auditStatus={auditStatus}
        handleClose={closeTemplateFill}
        needFeedbackHidden={needFeedbackHidden}
        handleConfirmNotifyMsg={confirmNotifyMsg}
      />
    )}
  </>
);

ActionDialogsOnly.propTypes = {
  isShowCreateDialog: PropTypes.bool.isRequired,
  isShowCreateCodeInterViewDialog: PropTypes.bool.isRequired,
  templateFillOpen: PropTypes.bool.isRequired,
  templateLoading: PropTypes.func.isRequired,
  emailTemplate: PropTypes.objectOf(PropTypes.any).isRequired,
  template: PropTypes.string.isRequired,
  templateRich: PropTypes.string.isRequired,
  variables: PropTypes.arrayOf(PropTypes.any).isRequired,
  templateSms: PropTypes.string.isRequired,
  richSms: PropTypes.string.isRequired,
  variablesSms: PropTypes.arrayOf(PropTypes.any).isRequired,
  templateEmail: PropTypes.string.isRequired,
  richEmail: PropTypes.string.isRequired,
  variablesEmail: PropTypes.string.isRequired,
  emailSubject: PropTypes.string.isRequired,
  mailToAddress: PropTypes.string.isRequired,
  candidateTokens: PropTypes.arrayOf(PropTypes.any).isRequired,
  auditStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  needFeedbackHidden: PropTypes.bool.isRequired,
  setShowCreateDialog: PropTypes.func.isRequired,
  setShowCreateCodeInterViewDialog: PropTypes.func.isRequired,
  createWritten: PropTypes.func.isRequired,
  createCodeInterViews: PropTypes.func.isRequired,
  closeTemplateFill: PropTypes.func.isRequired,
  confirmNotifyMsg: PropTypes.func.isRequired,
};

export default ActionDialogsOnly;
