import {
  Avatar, Box, FormControlLabel, Switch, TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as atmessageActions from '@redux/atmessage/action';
import { RANDOM_COLOR } from '@utils/constants';
import { useActions } from '@utils/useActions';
import PropTypes from 'prop-types';
import { useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { useEvaluationDispatch } from './context';
import UserAvatarChip from './UserAvatarChip';

function EvaluationVisibleUsersEditor({ visibleSwitch, visibleUsers = [] }) {
  const applicationToken = useSelector(({ candidateModal }) => candidateModal.applicationToken);
  const { users = [] } = useSelector((state) => state.atmessage);
  const { fetchAtList } = useActions(atmessageActions);
  const dispatch = useEvaluationDispatch();

  useEffect(() => {
    const data = { keyword: '', applicationToken };
    fetchAtList(data);
  }, []);

  function handleToggle(e) {
    const payload = e.target.checked ? 1 : 2;
    dispatch({ type: 'SET_TEMPLATE_DETAIL_VISIBLE_SWITCH', payload });
  }

  function handleChange(_, value) {
    dispatch({ type: 'SET_TEMPLATE_DETAIL_VISIBLE_USERS', payload: value });
  }

  const checked = visibleSwitch === 1;
  const selectedTokens = visibleUsers.map((user) => user.userToken);
  const options = users.filter((user) => selectedTokens.indexOf(user.userToken) === -1);

  return (
    <Box mt={2} ml={1.5}>
      <FormControlLabel
        control={<Switch color="primary" checked={checked} onChange={handleToggle} name="visibleSwitch" />}
        label="设为私密，仅选中人员可看"
      />
      {checked && (
        <Box mt={1}>
          <Autocomplete
            fullWidth
            multiple
            onChange={handleChange}
            options={options}
            limitTags={1}
            value={visibleUsers}
            disableCloseOnSelect
            getOptionLabel={(option) => option.username}
            noOptionsText="暂无更多"
            renderTags={(tags) => tags.map((option) => <UserAvatarChip option={option} />)}
            renderOption={(option) => (
              <>
                {option.userAvatar === '' ? (
                  <Avatar
                    style={{
                      width: '24px',
                      height: '24px',
                      margin: '4px 8px 4px 0px',
                      fontSize: '12px',
                      backgroundColor: RANDOM_COLOR[option.username.charCodeAt(0) % RANDOM_COLOR.length],
                    }}
                  >
                    {option.username.substr(option.username.length - 1)}
                  </Avatar>
                ) : (
                  <Avatar
                    style={{
                      width: '24px',
                      height: '24px',
                      margin: '4px 8px 4px 0px',
                      fontSize: '12px',
                    }}
                    alt={option.username}
                    src={option.userAvatar}
                  />
                )}
                {option.username}
              </>
            )}
            renderInput={(params) => <TextField variant="outlined" fullWidth placeholder="请选择" {...params} />}
          />
        </Box>
      )}
    </Box>
  );
}

export default memo(EvaluationVisibleUsersEditor);

EvaluationVisibleUsersEditor.propTypes = {
  visibleSwitch: PropTypes.bool.isRequired,
  visibleUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
};
