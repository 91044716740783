/* eslint-disable */
import API_URL from '@config/conf';
import { debounce, fork, put, select, takeLatest } from 'redux-saga/effects';
import { addReviewCandidates } from '../../../v1/actions/reviewList';
import http from '../../../v1/utils/http/http';
import { setSearchStat } from '../search/action';
import {
  getPostFilterAvailable,
  getPostFilterSelect,
  // showPostFilterUseResult,
  setPostFilterCandidateExtraUse,
  setPostFilterCandidateResumeExtraUse,
  setPostFilterProcessUse,
  setPostFilterReloadUse,
  setPostFilterSelect,
  setPostFilterTagsUse,
  setPostFilterUse,
  setPostFilterUseDefault
} from './action';

function* getPostFilterAvailableResult() {}

function* setPostFilterSelectResult() {}

function* getPostFilterSelectResult() {}

/**
 * 1 鳄梨跳动
 * 2 展示骨架图，接口在600ms请求完成，骨架图也要在600ms后消失。请求未在600ms内完成，接口请求结束后骨架图消失。
 * 3 正常展示页面
 */
// function* setPostFilterUseResult() {
//   yield put({ type: 'SET_FIRST_LOADING_STATE', payload: true });
//   yield put({ type: 'SET_LOADING_STATE', payload: true });

//   const state = yield select();
//   // const result = yield http.post({
//   //   url: `${API_URL}applicationSession/applications`,
//   //   data: state.process.filters,
//   // });
//   try {
//     const [result, skeleton] = yield [
//       yield http.post({
//         url: `${API_URL}applicationSession/applications`,
//         data: state.process.filters,
//       }),

//       yield delay(600),
//     ];
//     console.log(result, skeleton);
//     if (result.data.code === 0) {
//       yield put(addReviewCandidates(result.data.data.list, result.data.data.total));
//     }
//   } catch (error) {
//     console.log(error);
//   }

//   yield put({ type: 'SET_FIRST_LOADING_STATE', payload: false });
//   yield put({ type: 'SET_LOADING_STATE', payload: false });
// }

/**
 * 1 鳄梨跳动
 * 2 100ms内请求完成正常展示，未请求完成无任何操作
 * 3 100ms之后请求接口，展示骨架图。接口在600ms请求完成，骨架图也要在600ms后消失。请求未在600ms内完成，接口请求结束后骨架图消失。
 * 4 接口请求完成，正常展示页面
 */
// function* setPostFilterUseResult() {
//   const state = yield select();

//   try {
//     const { posts, timeout } = yield race({
//       posts: http.post({
//         url: `${API_URL}applicationSession/applications`,
//         data: state.process.filters,
//       }),
//       timeout: delay(100),
//     });
//     if (posts) {
//       console.log(posts);
//       if (posts.data.code === 0) {
//         yield put(addReviewCandidates(posts.data.data.list, posts.data.data.total));
//       }
//     } else {
//       console.log('timeout');
//       yield put({ type: 'SET_FIRST_LOADING_STATE', payload: true });
//       yield put({ type: 'SET_LOADING_STATE', payload: true });
//       const [result, skeleton] = yield [
//         yield http.post({
//           url: `${API_URL}applicationSession/applications`,
//           data: state.process.filters,
//         }),

//         yield delay(600),
//       ];
//       console.log(result, skeleton);
//       if (result.data.code === 0) {
//         yield put(addReviewCandidates(result.data.data.list, result.data.data.total));
//       }
//     }
//   } catch (error) {
//     console.log(error);
//   }

/**
 * 1 鳄梨跳动
 * 2 100ms内请求完成正常展示，未请求完成无任何操作
 * 3 100ms之后请求接口，展示骨架图
 * 4 请求完成骨架图消失，正常展示
 */
// function* setPostFilterUseResult() {
//   const state = yield select();

//   try {
//     const { posts, timeout } = yield race({
//       posts: http.post({
//         url: `${API_URL}applicationSession/applications`,
//         data: state.process.filters,
//       }),
//       timeout: delay(100),
//     });
//     if (posts) {
//       console.log(posts);
//       if (posts.data.code === 0) {
//         yield put(addReviewCandidates(posts.data.data.list, posts.data.data.total));
//       }
//     } else {
//       console.log('timeout');
//       yield put({ type: 'SET_FIRST_LOADING_STATE', payload: true });
//       yield put({ type: 'SET_LOADING_STATE', payload: true });
//       const result = yield http.post({
//         url: `${API_URL}applicationSession/applications`,
//         data: state.process.filters,
//       });
//       if (result.data.code === 0) {
//         yield put(addReviewCandidates(result.data.data.list, result.data.data.total));
//       }
//     }
//   } catch (error) {
//     console.log(error);
//   }

//   yield put({ type: 'SET_FIRST_LOADING_STATE', payload: false });
//   yield put({ type: 'SET_LOADING_STATE', payload: false });
// }
/**
 * 1 鳄梨跳动
 * 2 600ms之内空白
 * 3 600ms之后是骨架图
 */
// function* setPostFilterUseResult() {
//   const state = yield select();

//   yield put({ type: 'SET_BLANK_STATE', payload: true });

//   try {
//     const { posts } = yield race({
//       posts: Promise.all([
//         http.post({
//           url: `${API_URL}applicationSession/applications`,
//           data: state.process.filters,
//         }),
//         new Promise((resolve) => {
//           setTimeout(() => {
//             resolve();
//           }, 590);
//         }),
//       ]).then((resp) => resp[0]),
//       timeout: delay(600),
//     });

//     yield put({ type: 'SET_BLANK_STATE', payload: false });

//     if (posts) {
//       if (posts.data.code === 0) {
//         yield put(addReviewCandidates(posts.data.data.list, posts.data.data.total));
//       }
//     } else {
//       yield put({ type: 'SET_FIRST_LOADING_STATE', payload: true });
//       yield put({ type: 'SET_LOADING_STATE', payload: true });
//       const result = yield http.post({
//         url: `${API_URL}applicationSession/applications`,
//         data: state.process.filters,
//       });
//       if (result.data.code === 0) {
//         yield put(addReviewCandidates(result.data.data.list, result.data.data.total));
//       }
//     }
//   } catch (error) {
//     console.log(error);
//   }

//   yield put({ type: 'SET_FIRST_LOADING_STATE', payload: false });
//   yield put({ type: 'SET_LOADING_STATE', payload: false });
// }
function* setPostFilterUseResult() {
  const state = yield select();
  const { process } = state;
  const { notShowLoading, filters, cachedReviewCandidate } = process;
  const { positionToken, processToken, page } = filters;
  const cacheKey = `${positionToken || 'all'}-${processToken || 'first'}`;

  yield put({ type: 'SET_SHOW_ERROR', payload: false });
  yield put({ type: 'SET_FIRST_LOADING_STATE', payload: false });

  if (!notShowLoading) {
    yield put({ type: 'SET_LOADING_STATE', payload: true });
  }

  if (!cachedReviewCandidate[cacheKey]) {
    yield put({ type: 'SET_FIRST_LOADING_STATE', payload: true });
  }

  if (cachedReviewCandidate[cacheKey] && page === 1 && !notShowLoading) {
    const cachedData = cachedReviewCandidate[cacheKey];
    yield put(addReviewCandidates(cachedData.list, cachedData.total, cachedData.showTableHeaders));
  }

  try {
    const result = yield http.post({
      url: `${API_URL}vc1/applicationSession/applications`,
      data: filters,
      timeout: 15000,
    });
    if (result?.data?.code === -4001) {
      const showTableHeaders = [
        {
          id: 'profile',
          title: '候选人资料',
          canModify: false,
          selected: 1,
        },
        {
          id: 'source',
          title: '渠道来源',
          canModify: true,
          selected: 1,
        },
        {
          id: 'arriveTime',
          title: '日期/时间',
          canModify: true,
          selected: 1,
        },
      ];
      yield put(addReviewCandidates([], 0, showTableHeaders));
    }
    if (result?.data?.code === 0) {
      const { list, total, showTableHeaders } = result.data.data;
      yield put(addReviewCandidates(list, total, showTableHeaders));
      if (page === 1 && !notShowLoading) {
        yield put({
          type: 'SET_CACHED_REVIEW_CANDIDATE',
          payload: {
            key: cacheKey,
            list: list.slice(0, 10),
            total,
            showTableHeaders,
          },
        });
      }
      const { mobileSearchStat, nameSearchStat } = result.data.data;
      yield put(setSearchStat({ mobileSearchStat, nameSearchStat }));
    }
  } catch (error) {
    console.log(error);
    yield put({ type: 'SET_SHOW_ERROR', payload: true });
  }

  yield put({ type: 'SET_LOADING_STATE', payload: false });
}

function* refreshPostFilterUseResult() {
  yield put({ type: 'SET_SHOW_ERROR', payload: false });
  yield put({ type: 'SET_FIRST_LOADING_STATE', payload: false });
  yield put({ type: 'SET_LOADING_STATE', payload: true });

  const state = yield select();
  try {
    const result = yield http.post({
      url: `${API_URL}vc1/applicationSession/applications`,
      data: state.process.filters,
      timeout: 15000,
    });
    if (result.data.code === 0) {
      yield put(addReviewCandidates(result.data.data.list, result.data.data.total, result.data.data.showTableHeaders));

      const { mobileSearchStat, nameSearchStat } = result.data.data;
      yield put(setSearchStat({ mobileSearchStat, nameSearchStat }));
    }
  } catch (error) {
    yield put({ type: 'SET_SHOW_ERROR', payload: true });
  }

  yield put({ type: 'SET_LOADING_STATE', payload: false });
}

function* watch() {
  yield takeLatest(getPostFilterAvailable, getPostFilterAvailableResult);
  yield takeLatest(setPostFilterSelect, setPostFilterSelectResult);
  yield takeLatest(getPostFilterSelect, getPostFilterSelectResult);
  yield takeLatest(setPostFilterUse, setPostFilterUseResult);
  yield takeLatest(setPostFilterReloadUse, setPostFilterUseResult);
  yield takeLatest(setPostFilterTagsUse, refreshPostFilterUseResult);
  yield takeLatest(setPostFilterProcessUse, refreshPostFilterUseResult);
  yield takeLatest(setPostFilterUseDefault, setPostFilterUseResult);
  yield debounce(500, setPostFilterCandidateExtraUse, refreshPostFilterUseResult);
  yield debounce(500, setPostFilterCandidateResumeExtraUse, refreshPostFilterUseResult);
}

export default [fork(watch)];
